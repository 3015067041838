import { Button, Card, Col, Form, Input, Select, Space, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleError, hexToRGBA } from '../../lib/utils'
import {
  createCinicalNotesTemplates,
  createClinicalNote,
  readClinicalNotesTemplates,
  updateCinicalNotesTemplates,
  updateClinicalNote
} from '../examination-utils/examination-lib'
import CustomRow from '../../reusable-components/CustomRow'
import ReactQuill from 'react-quill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit, faRefresh, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { readClinicDataPoints } from '../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { useSelector } from 'react-redux'

const ClinicalNotesTemplates = ({ setValue, setModalOpen, debouncedNoteCapture, setUpdatingNotes, noteId }) => {
  const authState = useSelector((state) => state.auth)
  const [dataPoints, setDataPoints] = useState([])
  const [newTemplateContent, setNewTemplateContent] = useState('')
  const [isNew, setIsNew] = useState(false)
  const [templates, setTemplates] = useState([])
  const [dataPointsLoading, setDataPointsLoading] = useState(false)
  const [editTarget, setEditTarget] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    handleFetchTemplates()
    handleFetchDataPoints()
  }, [])

  const handleFetchDataPoints = async () => {
    let tmpData = []
    setDataPointsLoading(true)
    try {
      tmpData = await readClinicDataPoints()
      setDataPoints(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setDataPointsLoading(false)
  }

  const handleFetchTemplates = async () => {
    let tmpData = []
    setLoading(true)
    try {
      tmpData = await readClinicalNotesTemplates({ createdBy: authState.agiliteUser._id })
      setTemplates(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const handleCreateNotesTemplate = async (data) => {
    const payload = {}
    payload.name = data.name
    payload.description = data.description
    payload.dataPoints = data.dataPoints
    payload.content = editTarget ? editTarget.content : newTemplateContent
    setLoading(true)
    try {
      if (!payload.content) {
        setLoading(false)
        return message.error('Template requires content. Please revise.')
      }
      if (editTarget) {
        await updateCinicalNotesTemplates(editTarget._id, payload)
      } else {
        await createCinicalNotesTemplates(payload)
      }
      handleFetchTemplates()
      handleReset()
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const handleReset = () => {
    setIsNew(false)
    setEditTarget(false)
    setNewTemplateContent('')
  }
  const handleSelection = (template) => {
    debouncedNoteCapture(noteId, template.content)
    setUpdatingNotes(true)
    setValue(template.content)
    setModalOpen(false)
  }

  const { token } = theme.useToken()

  return (
    <>
      <FontAwesomeIcon
        onClick={() => setModalOpen(false)}
        color={token.colorError}
        style={{ fontSize: 20, position: 'absolute', right: 5, top: 5, cursor: 'pointer' }}
        icon={faXmarkCircle}
      />
      <Card
        headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
        style={{ marginTop: 12 }}
        size='small'
        title={<h2>Clinical Notes Templates: {isNew ? 'New Template' : editTarget ? 'Editing' : undefined}</h2>}
        extra={
          <Space>
            <Button
              type='primary'
              style={isNew || editTarget ? { background: token.colorError } : { background: token.colorPrimary }}
              onClick={() => {
                if (isNew || editTarget) {
                  setNewTemplateContent('')
                  setIsNew(false)
                  setEditTarget(false)
                } else {
                  setIsNew(true)
                }
              }}
            >
              {isNew || editTarget ? 'Cancel' : 'New Template'}
            </Button>
            {isNew || editTarget ? undefined : (
              <Button
                type='primary'
                style={{ background: token.colorSuccess }}
                onClick={() => {
                  handleFetchTemplates()
                }}
              >
                <FontAwesomeIcon icon={faRefresh} />
              </Button>
            )}
          </Space>
        }
      >
        {isNew || editTarget ? (
          <>
            <Form initialValues={editTarget} layout='vertical' onFinish={handleCreateNotesTemplate}>
              <Form.Item
                rules={[{ required: true, message: 'Template requires a Name. Please revise.' }]}
                label={<p>Name</p>}
                name='name'
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Template requires a description. Please revise.' }]}
                label={<p>Description</p>}
                name='description'
              >
                <Input />
              </Form.Item>
              <ReactQuill
                value={editTarget ? editTarget.content : newTemplateContent}
                onChange={(html) => {
                  if (editTarget) {
                    setEditTarget({ ...editTarget, content: html })
                  } else {
                    setNewTemplateContent(html)
                  }
                }}
              />
              <Form.Item
                style={{ marginTop: 12 }}
                label={
                  <Space direction='vertical'>
                    <p>Data Points:</p>
                    <small>Select data points for reference.</small>
                  </Space>
                }
                name='dataPoints'
              >
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  optionFilterProp='label'
                  options={dataPoints.map((dataPoint) => ({ value: dataPoint.key, label: dataPoint.label }))}
                  mode='multiple'
                />
              </Form.Item>

              <Form.Item noStyle>
                <Button
                  type='primary'
                  style={{ float: 'right', marginTop: 1, background: token.colorSuccess }}
                  loading={loading}
                  htmlType='submit'
                >
                  {isNew ? 'Finalise Template' : 'Save Changes'}
                </Button>{' '}
              </Form.Item>
            </Form>
          </>
        ) : (
          <CustomRow>
            {loading || dataPointsLoading ? (
              <Col span={24}>
                <AgiliteSkeleton skActive spinnerTip='' />
              </Col>
            ) : (
              <>
                {templates.length > 0 ? (
                  <>
                    {templates.map((template) => {
                      return (
                        <Col span={24}>
                          <Card
                            headStyle={{ background: hexToRGBA(token.colorSecondary, 0.2) }}
                            extra={
                              <Space>
                                <Button
                                  type='primary'
                                  onClick={() => {
                                    setEditTarget(template)
                                  }}
                                >
                                  Edit <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button
                                  type='primary'
                                  style={{ background: token.colorSuccess }}
                                  onClick={() => {
                                    handleSelection(template)
                                  }}
                                >
                                  Select <FontAwesomeIcon icon={faCheckCircle} />
                                </Button>
                              </Space>
                            }
                            size='small'
                            title={template.name}
                          >
                            {template.description}
                          </Card>
                        </Col>
                      )
                    })}
                  </>
                ) : (
                  <Col span={24}>
                    <p>No Templates found.</p>
                  </Col>
                )}
              </>
            )}
          </CustomRow>
        )}
      </Card>
    </>
  )
}

export default ClinicalNotesTemplates
