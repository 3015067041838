import { faClose, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, Space } from 'antd'
import React, { useRef } from 'react'

const FileUploadComponent = ({
  label,
  placeholder,
  token,
  fileName,
  file,
  setState,
  form,
  fileNameFieldKey,
  dataFieldKey
}) => {
  const fileInputRef = useRef()

  return (
    <div
      style={{
        border: '1px dashed',
        borderColor: '#ccc',
        padding: 10,
        marginBottom: 15,
        marginLeft: 50,
        marginRight: 50
      }}
    >
      <Row justify='center'>
        <Col span={24}>
          <Space size='large' wrap>
            <label
              style={{
                display: 'inline-block',
                borderRadius: 5,
                backgroundColor: token.colorPrimary,
                color: '#fff',
                padding: '10px 20px',
                cursor: 'pointer'
              }}
            >
              <FontAwesomeIcon icon={faUpload} /> {label ? label : 'Upload File'}
              <input
                ref={fileInputRef}
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    const data = e.target.files[0]
                    const reader = new FileReader()

                    reader.onloadend = () => {
                      form.setFieldValue(fileNameFieldKey, data.name)
                      form.setFieldValue(dataFieldKey, reader.result)
                      setState(data.name, reader.result)
                    }

                    if (data) {
                      reader.readAsDataURL(data)
                    }
                  }
                }}
                type='file'
                style={{ display: 'none' }}
              />
            </label>
            <small style={{ color: '#aaa', wordBreak: 'break-word' }}>
              File:{' '}
              {fileName ? (
                <a href={file} download={fileName}>
                  {fileName}
                </a>
              ) : placeholder ? (
                placeholder
              ) : (
                'No File Uploaded'
              )}
            </small>
            {fileName ? (
              <small
                style={{ color: token.colorError, cursor: 'pointer' }}
                onClick={() => {
                  form.setFieldValue(fileNameFieldKey, '')
                  form.setFieldValue(dataFieldKey, '')
                  setState('', '')
                  fileInputRef.current.value = null
                }}
              >
                <FontAwesomeIcon icon={faClose} /> Delete File
              </small>
            ) : undefined}
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default FileUploadComponent
