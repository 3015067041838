import React from 'react'
import './index.css'
import { theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const FeatureButton = ({ text, onClick, background, backgroundHover }) => {
  const { token } = theme.useToken()
  return (
    <div
      style={{
        background: background ? background : `linear-gradient(45deg, ${token.colorPrimary}, ${token.colorSecondary})`
      }}
      className='feature-button-container'
    >
      <button
        style={{ transition: 'background 350ms ease' }}
        onClick={() => onClick()}
        className='feature-button'
        onMouseEnter={(e) => {
          e.currentTarget.style.background = backgroundHover
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.background = background
        }}
      >
        {text}
        <FontAwesomeIcon icon={faChevronRight} style={{ float: 'right' }} />
      </button>
    </div>
  )
}

export default FeatureButton
