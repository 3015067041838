import { Card, Col, message } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import CustomButton from '../../reusable-components/CustomButton'
import { VirtualVisit } from '../../Bookings/components/Examination Components/virtual-visit'
import { handleError } from '../../lib/utils'
import { generateAgoraRtcToken } from '../../Bookings/utils/utils'

const VirtualCheckin = ({
  MetaLogo,
  bookingDetails,
  setView,
  setBookingId,
  setBookingService,
  setCurrentSelection,
  setPatientInput,
  setPatientSelection,
  setOtp
}) => {
  const [joined, setJoined] = useState(false)
  const [loading, setLoading] = useState(false)
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)
  const [leftCall, setLeftCall] = useState(false)

  const joinVirtualConsult = async () => {
    let agoraCreds = null

    setLoading(true)

    try {
      agoraCreds = await generateAgoraRtcToken(bookingDetails._id, 2)

      setAgoraToken(agoraCreds.token)
      setAgoraChannel(agoraCreds.channelName)
      setAgoraUid(agoraCreds.uid)
      setJoined(true)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  useEffect(() => {
    if (leftCall) {
      setView()
      setBookingId(null)
      setBookingService(null)
      setCurrentSelection(null)
      setPatientInput(null)
      setPatientSelection([])
      setView(null)
      setOtp(null)
    }

    // eslint-disable-next-line
  }, [leftCall])

  return (
    <>
      {!joined ? (
        <CustomRow
          gutter={[12, 12]}
          style={{ maxWidth: '100%', alignItems: 'center', minHeight: '100vh' }}
          justify='center'
        >
          <Col style={{ width: 1050, maxWidth: '90%' }}>
            <CustomRow className='basic-card'>
              <Col span={24}>
                <center>
                  <img src={MetaLogo} alt='Meta Logo' style={{ width: 341, maxWidth: '100%' }} />
                </center>
              </Col>
              <Col span={24}>
                <center>
                  <h2 style={{ width: 450, textAlign: 'center' }}>You have successfully checked in.</h2>
                </center>
              </Col>
              <Col span={24}>
                <center>
                  <h2 style={{ width: 450, textAlign: 'center' }}>You can now join the Virtual Consultation.</h2>
                </center>
              </Col>
              <Col span={24}>
                <center>
                  <CustomButton
                    type='secondary'
                    style={{ width: '50%' }}
                    loading={loading}
                    onClick={() => joinVirtualConsult()}
                    text={'Join Virtual Consultation'}
                  />
                </center>
              </Col>
            </CustomRow>
          </Col>
        </CustomRow>
      ) : (
        <CustomRow
          gutter={[12, 12]}
          style={{ maxWidth: '100%', alignItems: 'center', minHeight: '100vh' }}
          justify='center'
        >
          <Col span={18}>
            <Card>
              <VirtualVisit
                setLeftCall={setLeftCall}
                uid={agoraUid}
                token={agoraToken}
                channel={agoraChannel}
                patient={true}
                setJoined={setJoined}
                joined={joined}
              />
            </Card>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default VirtualCheckin
