import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Card, Col } from 'antd'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'
import CustomLoadingIcon from '../../reusable-components/CustomLoadingIcon'

const MacroGraphs = ({ trackerData, loading }) => {
  // Function to filter data based on date range

  const renderHistoricalMacroLineChart = () => {
    if (trackerData.length === 0)
      return (
        <Card style={{ marginTop: '20px' }}>
          <h3>Historical Macro Data</h3>
          {/* <RangePicker onChange={handleMacroDateChange} style={{ marginBottom: '20px' }} /> */}

          <p>No data available for the selected date range.</p>
        </Card>
      )

    const customTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div
            className='custom-tooltip'
            style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}
          >
            <p className='label' style={{ marginBottom: '5px' }}>
              {new Date(label).toLocaleDateString()}
            </p>
            {payload.map((entry, index) => (
              <div
                key={`item-${index}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: entry.color,
                  padding: '2px 0',
                  whiteSpace: 'nowrap' // Prevents text from wrapping
                }}
              >
                <span style={{ flex: '1 1 auto', textAlign: 'left', paddingRight: '4px' }}>{entry.name}</span>
                <span style={{ flex: '0 0 auto', width: '10px', textAlign: 'center' }}>:</span>{' '}
                <span style={{ flex: '0 1 auto', textAlign: 'right', paddingLeft: '4px' }}>{entry.value}</span>
              </div>
            ))}
          </div>
        )
      }

      return null
    }

    return (
      <Card style={{ marginTop: '10px', marginBottom: '5px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
          <h3 style={{ marginBottom: '10px' }}>Historical Macro Data</h3>
        </div>
        <CustomLoadingIcon
          loading={loading}
          loadingText='Fetching Macro Data...'
          content={
            <ResponsiveContainer width='100%' height={300}>
              <LineChart data={trackerData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='createdAt' tickFormatter={(tick) => new Date(tick).toLocaleDateString()} />
                <YAxis />
                <Tooltip content={customTooltip} />
                <Legend />
                <Line type='monotone' dataKey='macros.prot' stroke='#8884d8' name='Protein (g)' />
                <Line type='monotone' dataKey='macros.fat' stroke='#82ca9d' name='Fat (g)' />
                <Line type='monotone' dataKey='macros.carb' stroke='#ffc658' name='Carbs (g)' />
                <Line type='monotone' dataKey='macros.sug' stroke='#ff7300' name='Sugar (g)' />
              </LineChart>
            </ResponsiveContainer>
          }
        />
      </Card>
    )
  }

  const renderCaloriesLineChart = () => {
    if (trackerData.length === 0)
      return (
        <Card style={{ marginTop: '20px' }}>
          <h3>Calories Over Time</h3>

          <p>No data available for the selected date range.</p>
        </Card>
      )

    return (
      <Card style={{ marginTop: '10px', marginBottom: '5px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
          <h3 style={{ marginBottom: '10px' }}>Calories Over Time</h3>
        </div>
        <CustomLoadingIcon
          loading={loading}
          loadingText='Fetching Calorie Data...'
          content={
            <ResponsiveContainer width='100%' height={300}>
              <LineChart data={trackerData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='createdAt' tickFormatter={(tick) => new Date(tick).toLocaleDateString()} />
                <YAxis />
                <Tooltip labelFormatter={(label) => new Date(label).toLocaleDateString()} />
                <Legend />
                <Line type='monotone' dataKey='macros.cal' stroke='#ff0000' name='Calories' />
              </LineChart>
            </ResponsiveContainer>
          }
        />
      </Card>
    )
  }

  return (
    <CustomRow>
      {/* For desktop: 50% width for each graph, for mobile: 100% width stacked */}
      <Col xs={24} sm={24} md={12}>
        {renderHistoricalMacroLineChart()}
      </Col>
      <Col xs={24} sm={24} md={12}>
        {renderCaloriesLineChart()}
      </Col>
    </CustomRow>
  )
}

export default MacroGraphs
