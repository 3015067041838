import React, { useEffect, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Card, Col, Space, Steps, message, theme } from 'antd'
import { CarePlanTemplatesNewRecordTemplate } from '../../utils/templates'
import { handleError } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChevronLeft, faChevronRight, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { createCarePlanTemplate, updateCarePlanTemplate } from '../../utils/utils'
import { useDispatch } from 'react-redux'
import CoreEnums from '../../../../core/utils/enums'
import coreReducer from '../../../../core/utils/reducer'
import GeneralForm from './Steps/GeneralForm'
import DataTrackerForm from './Steps/DataTrackerForm'
import VisitTrackers from './Steps/VisitTrackers'
import Summary from './Steps/Summary'

const CarePlanForm = ({ isNew, recordData, dataPoints, refreshView, isMonitoring }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [data, setData] = useState(recordData ? recordData.tracking.filter((i) => i.trackerType === 'data') : [])
  const [visits, setVisits] = useState(recordData ? recordData.tracking.filter((i) => i.trackerType === 'visits') : [])
  const [general, setGeneral] = useState({
    name: recordData ? recordData.name : '',
    description: recordData ? recordData.name : ''
  })
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleAddTracking = (key, selection) => {
    let tmpData = []
    let tmpVisits = []
    const parsedSelection = JSON.parse(selection)
    switch (key) {
      case 'data':
        tmpData = JSON.parse(JSON.stringify(data))
        tmpData.push(CarePlanTemplatesNewRecordTemplate(key, parsedSelection.key, parsedSelection.label))
        setData(tmpData)
        break
      case 'visits':
        tmpVisits = JSON.parse(JSON.stringify(visits))
        tmpVisits.push(CarePlanTemplatesNewRecordTemplate(key, parsedSelection.key, parsedSelection.label))
        setVisits(tmpVisits)
        break
    }
  }

  const handleUpdateTracker = (value, index, property, key) => {
    let tmpData = []
    let tmpVisits = []
    switch (key) {
      case 'data':
        tmpData = JSON.parse(JSON.stringify(data))
        tmpData[index][property] = value
        setData(tmpData)
        break
      case 'visits':
        tmpVisits = JSON.parse(JSON.stringify(visits))
        tmpVisits[index][property] = value
        setVisits(tmpVisits)
        break
    }
  }

  const { token } = theme.useToken()

  const checkGeneralStatus = () => {
    let valid = true
    if (!general.name) {
      valid = false
    }
    return valid
  }

  const handleRemoveTracker = (index, key) => {
    let tmpData = []
    let tmpVisits = []
    switch (key) {
      case 'data':
        tmpData = JSON.parse(JSON.stringify(data))
        tmpData.splice(index, 1)
        setData(tmpData)
        break
      case 'visits':
        tmpVisits = JSON.parse(JSON.stringify(visits))
        tmpVisits.splice(index, 1)
        setVisits(tmpVisits)
        break
    }
  }

  const handleFinalizeCarePlan = async () => {
    let payload = {}

    payload.tracking = [...data, ...visits]
    if (!payload.tracking || payload.tracking?.length === 0) {
      return message.error('Please provide atleast 1 tracker.')
    }
    payload.name = general.name
    payload.description = general.description
    if (!payload.name) {
      return message.error(`Please provide a name for your ${isMonitoring ? 'monitoring' : 'care'} plan.`)
    }

    try {
      await createCarePlanTemplate(payload)
      refreshView()
      closeTab()
    } catch (e) {
      message.error(handleError(e))
    }
  }

  const handleUpdateCarePlan = async () => {
    let payload = {}

    payload.tracking = [...data, ...visits]
    if (!payload.tracking || payload.tracking?.length === 0) {
      return message.error('Please provide atleast 1 tracker.')
    }
    payload.name = general.name
    payload.description = general.description
    if (!payload.name) {
      return message.error(`Please provide a name for your ${isMonitoring ? 'monitoring' : 'care'} plan.`)
    }

    try {
      await updateCarePlanTemplate({ ...recordData, ...payload })
      refreshView()
      closeTab()
    } catch (e) {
      message.error(handleError(e))
    }
  }

  const closeTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `${CoreEnums.tabKeys.CARE_PLANS}_${recordData._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const steps = [
    {
      title: (
        <>
          General{' '}
          {checkGeneralStatus() ? (
            <FontAwesomeIcon color={token.colorSuccess} icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon color={token.colorError} icon={faXmarkCircle} />
          )}
        </>
      ),
      component: (
        <Col span={24}>
          <GeneralForm data={general} setData={setGeneral} />
        </Col>
      )
    },
    {
      title: `Health Trackers (${data.length})`,
      component: (
        <Col span={24}>
          <DataTrackerForm
            handleAddTracking={handleAddTracking}
            handleUpdateTracker={handleUpdateTracker}
            dataPoints={dataPoints}
            data={data}
            handleRemoveTracker={handleRemoveTracker}
          />
        </Col>
      )
    },
    {
      title: `Visit Trackers (${visits.length})`,
      component: (
        <Col span={24}>
          <VisitTrackers
            handleAddTracking={handleAddTracking}
            handleRemoveTracker={handleRemoveTracker}
            handleUpdateTracker={handleUpdateTracker}
            visits={visits}
          />
        </Col>
      ),
      hidden: isMonitoring
    },
    {
      title: 'Summary',
      component: (
        <>
          <Col span={24}>
            <Summary
              setCurrentStep={setCurrentStep}
              general={general}
              visits={visits}
              data={data}
              isMonitoring={isMonitoring}
            />
          </Col>
        </>
      )
    }
  ]

  const filteredSteps = steps.filter((step) => !step.hidden)
  return (
    <CustomRow>
      {/* Steps Indicator */}
      <Col span={24}>
        <Card style={{ marginTop: 24 }}>
          <Steps
            current={currentStep}
            items={steps
              .filter((i) => !i.hidden)
              .map((step, index) => ({
                ...step,
                onClick: () => {
                  setCurrentStep(index)
                }
              }))}
          />
        </Card>
      </Col>

      {/* Component Rendering */}
      {filteredSteps[currentStep].component}

      {/* Controls */}
      <Col span={24}>
        <Card>
          <Space style={{ width: '100%', justifyContent: currentStep === 0 ? 'right' : 'space-between' }}>
            {currentStep !== 0 ? (
              <Button type='primary' onClick={() => setCurrentStep(currentStep - 1)}>
                <FontAwesomeIcon icon={faChevronLeft} /> {steps[currentStep].title}
              </Button>
            ) : undefined}
            {currentStep !== filteredSteps.length - 1 ? (
              <Button type='primary' onClick={() => setCurrentStep(currentStep + 1)}>
                Continue <FontAwesomeIcon icon={faChevronRight} />{' '}
              </Button>
            ) : undefined}
            {currentStep === filteredSteps.length - 1 ? (
              <Button
                type='primary'
                style={{ background: token.colorSuccess }}
                onClick={() => (isNew ? handleFinalizeCarePlan() : handleUpdateCarePlan())}
              >
                {isNew ? (
                  <>
                    Finalize <FontAwesomeIcon icon={faCheckCircle} />
                  </>
                ) : (
                  'Save Changes'
                )}
              </Button>
            ) : undefined}
          </Space>
        </Card>
      </Col>
    </CustomRow>
  )
}

export default CarePlanForm
