import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleCancelBooking, updateAllBookingsState } from '../../lib/booking-utils'
import { updateBooking } from '../../Bookings/utils/utils'
import CoreEnums from '../../../core/utils/enums'
import { Card, Col, DatePicker, Divider, Select, Space, Tooltip, message, theme } from 'antd'
import CustomButton from '../../reusable-components/CustomButton'
import CustomRow from '../../reusable-components/CustomRow'
import dayjs from 'dayjs'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { isDoctor, isNurse, isReception } from '../../lib/profile-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { handleOpenBooking, handleSkipScreening } from '../utils/appointments-calendar-lib'
import { handleError } from '../../lib/utils'

const ViewingRecord = ({ data, setData, setModalOpen, refreshFunction, medicalProfessionals }) => {
  const { token } = theme.useToken()
  const authState = useSelector((state) => state.auth)
  const serviceState = useSelector((state) => state.services.data)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState(data ? data : null)
  const [reassigning, setReassigning] = useState(false)
  const [rescheduling, setRescheduling] = useState(false)
  const [rescheduleDate, setRescheduleDate] = useState(null)
  const [rescheduleTime, setRescheduleTime] = useState(null)
  const [updatingBooking, setUpdatingBooking] = useState(false)

  function calculateDuration(startTime, endTime) {
    const start = dayjs(startTime, 'HH:mm')
    const end = dayjs(endTime, 'HH:mm')

    // Calculate duration in minutes
    const durationMinutes = end.diff(start, 'minute')

    // Convert duration to hours and minutes
    const hours = Math.floor(durationMinutes / 60)
    const minutes = durationMinutes % 60

    return { hours, minutes }
  }
  const onAfterCancel = () => {
    setData(null)
    refreshFunction()
  }

  const viewRecordText = (status) => {
    if (isDoctor() && status === CoreEnums.bookingStatuses.diagnosis) {
      return 'EXAMINE'
    }
    if (isNurse() && status === CoreEnums.bookingStatuses.screening) {
      return 'EXAMINE'
    }
    if (isReception() && status === CoreEnums.bookingStatuses.billing) {
      return 'BILL PATIENT'
    }
    return 'VIEW RECORD'
  }

  const onSuccess = () => {
    setLoading(false)
    setModalOpen(false)
    setData(null)
    refreshFunction()
  }
  const onFail = () => {
    setLoading(false)
  }

  const handleReassignment = async (value, target) => {
    let payload = {}
    let newBooking = null
    let newMedProfName = medicalProfessionals.find((i) => i._id === value)
    payload[target] = value
    setReassigning(true)
    try {
      newBooking = await updateBooking(payload, { _id: recordData._id })
      updateAllBookingsState({
        ...newBooking,
        professionalName: `${newMedProfName.firstName} ${newMedProfName.lastName}`
      })
      setRecordData({ ...recordData, ...newBooking })
      refreshFunction()
    } catch (e) {
      message.error(handleError(e))
    }
    setReassigning(false)
  }
  const handleReschedule = async () => {
    const serviceInfo = serviceState.find((i) => i._id === data.service)
    if (!serviceInfo) {
      return message.error('Error')
    }
    let newBooking = null
    setUpdatingBooking(true)

    try {
      newBooking = await updateBooking(
        {
          bookingDate: dayjs(rescheduleDate).format('YYYY-MM-DD'),
          startTime: rescheduleTime,
          endTime: dayjs(rescheduleTime, 'HH:mm').add(serviceInfo.timeslotInterval, 'minutes').format('HH:mm')
        },
        { _id: recordData._id }
      )
      updateAllBookingsState({
        ...newBooking
      })
      setRecordData({ ...recordData, ...newBooking })
      refreshFunction()
      resetRescheduleData()
    } catch (e) {
      message.error(handleError(e))
    }
    setUpdatingBooking(false)
  }
  const resetRescheduleData = () => {
    setRescheduleTime(null)
    setRescheduleDate(null)
    setRescheduling(false)
  }

  const generateTimeSlots = () => {
    const timeSlots = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        timeSlots.push(`${formattedHour}:${formattedMinute}`)
      }
    }
    return timeSlots
  }

  if (recordData) {
    return (
      <CustomRow>
        <Col span={24}>
          <Card
            headStyle={{
              borderRadius: 0
            }}
            style={{ borderRadius: 0 }}
            bodyStyle={{ padding: 0 }}
            type='inner'
            size='small'
            title={
              <center>
                {`${dayjs(recordData.bookingDate).format('DD MMMM YYYY')}`.toUpperCase()} - {recordData.startTime}
              </center>
            }
            extra={
              <FontAwesomeIcon
                fontSize={24}
                style={{ cursor: 'pointer' }}
                icon={faXmarkCircle}
                color={token.colorError}
                onClick={() => {
                  setData(null)
                }}
              />
            }
          >
            <CustomRow gutter={[0, 0]}>
              {loading ? (
                <Col span={24} style={{ padding: 12 }}>
                  <AgiliteSkeleton skActive spinnerTip='' />
                </Col>
              ) : (
                <>
                  {' '}
                  <Col span={16}>
                    <Card
                      headStyle={{ borderRadius: 0 }}
                      style={{ borderRadius: 0, borderBottom: 'none' }}
                      type='inner'
                      size='small'
                      title={<center>BOOKING SUMMARY</center>}
                    >
                      <CustomRow>
                        <Col span={24}>
                          <Space direction='vertical' style={{ width: '100%' }}>
                            <Space>
                              <p style={{ width: 100 }}>
                                <b>Doctor:</b>
                              </p>
                              <Select
                                showSearch
                                optionFilterProp='search'
                                loading={reassigning}
                                onChange={(value) => handleReassignment(value, 'medicalProfRef')}
                                style={{ width: 300, maxWidth: '100%' }}
                                value={recordData.medicalProfRef}
                                options={medicalProfessionals.map((medProf) => {
                                  return {
                                    search: `${medProf.firstName} ${medProf.lastName}`,
                                    label: (
                                      <p>
                                        {`${
                                          medProf.extraData.profession === 'doctor' ? 'Dr. ' : ''
                                        }${medProf.firstName.charAt(0)} ${medProf.lastName}`}{' '}
                                        {medProf.onDuty ? (
                                          <sup style={{ color: token.colorSuccess, fontSize: 8 }}>ON DUTY</sup>
                                        ) : undefined}
                                      </p>
                                    ),
                                    value: medProf._id
                                  }
                                })}
                              />
                            </Space>
                            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                            <Space>
                              <p style={{ width: 100 }}>
                                <b>Patient:</b>
                              </p>
                              <p>{recordData.patientName}</p>
                            </Space>
                            {rescheduling ? undefined : (
                              <>
                                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                                <Space>
                                  <p style={{ width: 100 }}>
                                    <b>Status:</b>
                                  </p>
                                  <p>{recordData.status}</p>
                                </Space>
                                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                                <Space>
                                  <p style={{ width: 100 }}>
                                    <b>Contact:</b>
                                  </p>
                                  <p>
                                    {recordData.patientRecord.phoneNumber
                                      ? recordData.patientRecord.phoneNumber
                                      : recordData.patientRecord.tmpPhoneNumber}
                                  </p>
                                </Space>
                                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                                <Space>
                                  <p style={{ width: 100 }}>
                                    <b>OTP:</b>
                                  </p>
                                  <p>
                                    {recordData.otp}{' '}
                                    <Tooltip title='Copy'>
                                      <FontAwesomeIcon
                                        style={{ cursor: 'copy' }}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          navigator.clipboard.writeText(recordData.otp)
                                          message.info('OTP Copied to clipboard.')
                                        }}
                                        icon={faCopy}
                                      />
                                    </Tooltip>
                                  </p>
                                </Space>
                                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                                <Space>
                                  <p style={{ width: 100 }}>
                                    <b>Duration:</b>
                                  </p>
                                  <p>
                                    {calculateDuration(recordData.startTime, recordData.endTime).hours
                                      ? `${calculateDuration(recordData.startTime, recordData.endTime).hours}h`
                                      : undefined}{' '}
                                    {calculateDuration(recordData.startTime, recordData.endTime).minutes
                                      ? `${calculateDuration(recordData.startTime, recordData.endTime).minutes}m`
                                      : undefined}
                                  </p>
                                </Space>
                              </>
                            )}
                          </Space>
                        </Col>
                      </CustomRow>
                    </Card>
                    {rescheduling ? undefined : (
                      <Card
                        headStyle={{ borderRadius: 0 }}
                        style={{ borderRadius: 0, borderBottom: 'none' }}
                        type='inner'
                        size='small'
                        title={<center>MEDICAL AID</center>}
                      >
                        <CustomRow>
                          <Col span={24}>
                            <Space direction='vertical' style={{ width: '100%' }}>
                              <Space>
                                <p style={{ width: 120 }}>
                                  <b>Scheme:</b>
                                </p>
                                <p>{recordData.patientRecord.medicalAid.name}</p>
                              </Space>
                              <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                              <Space>
                                <p style={{ width: 120 }}>
                                  <b>Plan:</b>
                                </p>
                                <p>{recordData.patientRecord.medicalAid.plan}</p>
                              </Space>
                              <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                              <Space>
                                <p style={{ width: 120 }}>
                                  <b>Option:</b>
                                </p>
                                <p>{recordData.patientRecord.medicalAid.option}</p>
                              </Space>
                              <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                              <Space>
                                <p style={{ width: 120 }}>
                                  <b>Number:</b>
                                </p>
                                <p>{recordData.patientRecord.medicalAid.number}</p>
                              </Space>
                              <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                              <Space>
                                <p style={{ width: 120 }}>
                                  <b>Dep Number:</b>
                                </p>
                                <p>{recordData.patientRecord.medicalAid.dependantNumber}</p>
                              </Space>
                            </Space>
                          </Col>
                        </CustomRow>
                      </Card>
                    )}
                    {rescheduling ? (
                      <Card
                        headStyle={{ borderRadius: 0 }}
                        style={{ borderRadius: 0, borderBottom: 'none' }}
                        type='inner'
                        size='small'
                        title={<center>RESCHEDULE</center>}
                      >
                        <CustomRow>
                          <Col span={24}>
                            <DatePicker
                              disabledDate={(current) => current && current < dayjs().startOf('day')}
                              style={{ width: '100%' }}
                              onChange={(date) => {
                                setRescheduleDate(date)
                              }}
                              value={rescheduleDate}
                            />
                          </Col>
                          <Col span={24}>
                            <Select
                              disabled={!rescheduleDate}
                              placeholder='- Select Timeslot -'
                              style={{ width: '100%' }}
                              onChange={(time) => {
                                setRescheduleTime(time)
                              }}
                              showSearch
                              value={rescheduleTime}
                              options={generateTimeSlots().map((timeslot) => ({ value: timeslot, label: timeslot }))}
                            />
                          </Col>
                        </CustomRow>
                      </Card>
                    ) : undefined}
                  </Col>
                  <Col span={8}>
                    <Card
                      headStyle={{ borderRadius: 0 }}
                      style={{ borderRadius: 0 }}
                      bodyStyle={{ padding: 0 }}
                      type='inner'
                      size='small'
                      title={<center>ACTIONS</center>}
                    >
                      {rescheduling ? (
                        <>
                          <CustomButton
                            type='success'
                            style={{
                              width: '100%',
                              borderRadius: 0
                            }}
                            loading={updatingBooking}
                            disabled={!rescheduleDate || !rescheduleTime}
                            text='CONFIRM RESCHEDULE'
                            onClick={() => {
                              handleReschedule()
                            }}
                          />
                          <CustomButton
                            type='danger'
                            style={{
                              width: '100%',
                              borderRadius: 0
                            }}
                            text='CANCEL RESCHEDULE'
                            onClick={() => {
                              resetRescheduleData()
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <CustomButton
                            type='success'
                            style={{ width: '100%', borderRadius: 0 }}
                            text={viewRecordText(data.status)}
                            onClick={() => {
                              setLoading(true)
                              handleOpenBooking(recordData, dispatch, onFail, onSuccess)
                            }}
                          />
                          {recordData.status !== CoreEnums.bookingStatuses.completed ? (
                            <CustomButton
                              type='secondary'
                              style={{
                                width: '100%',
                                borderRadius: 0
                              }}
                              text='RESCHEDULE'
                              onClick={() => {
                                setRescheduling(true)
                              }}
                            />
                          ) : undefined}{' '}
                          {isDoctor() && recordData.status === CoreEnums.bookingStatuses.screening ? (
                            <CustomButton
                              type='warning'
                              style={{ width: '100%', borderRadius: 0 }}
                              text='SKIP SCREENING'
                              hasPopconfirm
                              popConfirmTitle='Confirm Action - Skip Screening'
                              popConfirmDescription='Are you sure you want to skip the screening step? This action can not be undone.'
                              onClick={() => {
                                setLoading(true)
                                handleSkipScreening(recordData, dispatch, onFail, onSuccess)
                              }}
                            />
                          ) : undefined}
                          <CustomButton
                            type='danger'
                            style={{ width: '100%', borderRadius: 0 }}
                            text='CANCEL BOOKING'
                            disabled={
                              recordData.status !== CoreEnums.bookingStatuses.checkin &&
                              recordData.status !== CoreEnums.bookingStatuses.screening
                            }
                            onClick={() => {
                              handleCancelBooking(authState, recordData, updateBooking, onAfterCancel, token)
                            }}
                          />
                        </>
                      )}
                    </Card>
                  </Col>
                </>
              )}
            </CustomRow>
          </Card>
        </Col>
      </CustomRow>
    )
  } else {
    return <></>
  }
}

export default ViewingRecord
