import { Col, Divider, Empty, Space, Tag, theme } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { deviceDetect } from 'react-device-detect'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import CustomRow from '../../../reusable-components/CustomRow'
import { bloodPressureMethod, filterByDateRange } from '../utils'
import BloodPressureLegend from './blood-pressure-legend'
import { generateXAxisLabel } from '../../lib'
const BloodPressurePlotting = ({ data }) => {
  const [graphData, setGraphData] = useState([])
  // eslint-disable-next-line
  const [dateRange, setDateRange] = useState()

  useEffect(() => {
    handleSetGraphData()
    // eslint-disable-next-line
  }, [data, dateRange])

  const handleSetGraphData = async () => {
    const tmpGraphData = []
    let tmpData = []
    if (dateRange) {
      tmpData = filterByDateRange(data, dateRange[0], dateRange[1])
    } else {
      tmpData = data.concat()
    }

    tmpData.forEach((entry) => {
      if (
        entry.examinationData?.checkinData?.bloodPressure?.systolic &&
        entry.examinationData?.checkinData?.bloodPressure?.diastolic
      )
        tmpGraphData.push({
          name: dayjs(entry.bookingDate).format('DD MMM YYYY'),
          systolic: entry.examinationData.checkinData.bloodPressure.systolic,
          diastolic: entry.examinationData.checkinData.bloodPressure.diastolic
        })
    })

    setGraphData(tmpGraphData)
  }

  const customizedDotSystolic = (props) => {
    let value = [props.payload.systolic, props.payload.diastolic]
    let color = null
    const { cx, cy } = props
    Object.keys(bloodPressureMethod(value)).forEach((key) => {
      const object = bloodPressureMethod(value)[key]
      if (object.systolicTest()) {
        color = object.color
      }
    })

    return <circle cx={cx} cy={cy} r={6} strokeWidth={3} fill={color} />
  }
  const customizedDotDiastolic = (props) => {
    let value = [props.payload.systolic, props.payload.diastolic]
    let color = null
    const { cx, cy } = props
    Object.keys(bloodPressureMethod(value)).forEach((key) => {
      const object = bloodPressureMethod(value)[key]
      if (object.diastolicTest()) {
        color = object.color
      }
    })

    return <circle cx={cx} cy={cy} r={6} strokeWidth={3} fill={color} />
  }
  const { token } = theme.useToken()

  const generateTooltipLabel = (key) => {
    switch (key) {
      case 'systolic':
        return 'Systolic'
      case 'diastolic':
        return 'Diastolic'
      default:
        return 'BP'
    }
  }

  return (
    <CustomRow gutter={[0, 24]}>
      {graphData.length > 0 ? (
        <Col span={24}>
          <ResponsiveContainer width='100%' height={300}>
            <LineChart
              data={graphData}
              margin={{
                top: 5,
                right: 10,
                left: deviceDetect().isMobile ? -20 : 0,
                bottom: 15
              }}
            >
              <CartesianGrid stroke={token.colorBorderSecondary} />
              <XAxis
                dataKey='name'
                fontSize={14}
                stroke={token.colorTextBase}
                label={{ value: generateXAxisLabel(graphData), dy: 20, style: { fontSize: 14 } }}
                tick={false}
              />
              <YAxis
                stroke={token.colorTextBase}
                label={{ value: deviceDetect().isMobile ? '' : 'Value', angle: -90, dx: -20, style: { fontSize: 14 } }}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  const bp = []
                  return (
                    <>
                      {deviceDetect().isMobile ? (
                        <Space
                          style={{
                            width: '100%',
                            justifyContent: 'space-between',
                            background: 'rgba(255,255,255,1)',
                            boxShadow: '0 0 5px 0 rgba(0,0,0,0.155)',
                            padding: 4,
                            borderRadius: 8
                          }}
                        >
                          {/* eslint-disable-next-line */}
                          {payload.map((item) => {
                            bp.push(item.value)
                          })}
                          {bp[0]}/{bp[1]}
                          <span style={{ opacity: 0.455 }}>mmHg</span>
                        </Space>
                      ) : (
                        <CustomRow
                          className='basic-card'
                          style={{
                            background: 'rgba(255,255,255,0.95)',
                            boxShadow: '0 0 10px 0 rgba(0,0,0,0.25)'
                          }}
                        >
                          <Col span={24}>
                            <center>
                              <p span={24}>{label}</p>
                            </center>
                            <Divider style={{ margin: '4px 0px' }} />
                            {payload.map((item) => {
                              bp.push(item.value)

                              return (
                                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                  <p>{generateTooltipLabel(item.dataKey)}:</p> <p>{item.value}</p>
                                </Space>
                              )
                            })}
                            {Object.keys(bloodPressureMethod(bp)).map((key) => {
                              const object = bloodPressureMethod(bp)[key]
                              if (object.statusTest()) {
                                return (
                                  <center style={{ marginTop: 8 }}>
                                    <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                                      {object.label}
                                    </Tag>
                                  </center>
                                )
                              } else {
                                return null
                              }
                            })}
                          </Col>
                        </CustomRow>
                      )}
                    </>
                  )
                }}
                contentStyle={{
                  color: token.colorTextBase,
                  background: token.colorBgContainer,
                  border: `2px ${token.colorBorderSecondary} solid`
                }}
                itemStyle={{ color: token.colorTextBase }}
              />
              <Line type='monotone' dataKey='systolic' stroke={token.colorPrimary} dot={customizedDotSystolic} />
              <Line type='monotone' dataKey='diastolic' stroke={token.colorSecondary} dot={customizedDotDiastolic} />
              {/* <Customized component={CustomizedRectangle} /> */}
            </LineChart>
          </ResponsiveContainer>
        </Col>
      ) : (
        <Col span={24}>
          <Empty description='No Blood Pressure readings captured to date.' />
        </Col>
      )}
      <Col span={24}>
        <BloodPressureLegend />
      </Col>
    </CustomRow>
  )
}

export default BloodPressurePlotting
