import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, message, Modal, Radio, Row, Select, Space, theme } from 'antd'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'

import { createAgiliteUser, findAgiliteUser, getCountries, updateAgiliteUser } from '../../utils/utils'
import { firebaseAuth } from '../../../..'
import authReducer from '../../utils/reducer'
import { allTrim, handleCalculateGender, handleError, specialFieldValidation } from '../../../lib/utils'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import dayjs from 'dayjs'
import coreReducer from '../../../../core/utils/reducer'
import DatePickerModal from '../../../reusable-components/DatePickerModal'
import { MobileTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSignIn } from '@fortawesome/free-solid-svg-icons'
import CoreEnums from '../../../../core/utils/enums'

const PhoneSignUpForm = ({ isLogin, setIsLogin, setFacet }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const coreState = useSelector((state) => state.core)
  const [loading, setLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmationCode, setConfirmationCode] = useState(null)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [forgotOpen, setForgotOpen] = useState(false)
  const [idNo, setIdNo] = useState('')
  const [isForgotAction, setIsForgotAction] = useState(null)
  const [idType, setIdType] = useState('id')
  const [record, setRecord] = useState([])
  const [resendTimeout, setResendTimeout] = useState(0)
  const [residentVisitor, setResidentVisitor] = useState()
  const [country, setCountry] = useState('ZA')
  const [countries, setCountries] = useState([])
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countryObject, setCountryObject] = useState()

  useEffect(() => {
    async function handleLoginPhoneRecaptcha() {
      window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          setSubmitDisabled(false)
        },
        'expired-callback': (response) => {
          setSubmitDisabled(true)
          setErrorMessage('Verification Expired. Please reload the page')
        }
      })
    }

    handleGetCountries()

    handleLoginPhoneRecaptcha()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let tmpIndex = -1

    tmpIndex = countries.findIndex((e) => e.code === country)

    if (tmpIndex > -1) {
      setCountryObject(countries[tmpIndex])
    } else {
      setCountryObject(null)
    }

    // eslint-disable-next-line
  }, [country])

  const handleGetCountries = async () => {
    let tmpCountries = []

    setCountriesLoading(true)

    try {
      tmpCountries = await getCountries()
      setCountries(tmpCountries)
    } catch (e) {
      message.error(e)
    }

    setCountriesLoading(false)
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber.slice(1, phoneNumber.length)}`
    } else {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber}`
    }

    return phoneNumber
  }

  const handleSubmit = async (data) => {
    let agiliteUser = null
    setLoading(true)

    try {
      // Log In
      if (isLogin) {
        agiliteUser = await findAgiliteUser({
          phoneNumber: formatNumber(data.phoneNumber)
        })

        if (!agiliteUser) {
          setLoading(false)
          return message.error('An account with this Cellphone Number could not be found. Please Sign Up.')
        } else {
          if (
            agiliteUser.extraData.role.type !== 'patient' &&
            agiliteUser.extraData.role.type !== 'super_admin' &&
            !agiliteUser.extraData.entities.includes(coreState.entity._id)
          ) {
            setLoading(false)
            return message.error(CoreEnums.errorMessages.ENTITY_NOT_AUTH)
          }
        }
      }

      data.phoneNumber = formatNumber(data.phoneNumber)
      await handleLoginPhone(data.phoneNumber)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSubmitExtended = async (data) => {
    const pathArray = window.location.pathname.split('/').filter((i) => i !== '')

    try {
      let agiliteUser = await findAgiliteUser({ phoneNumber: data.phoneNumber })

      if (!isLogin && !agiliteUser) {
        const tmpData = JSON.parse(JSON.stringify(allTrim(data)))

        if (idType === 'id') {
          tmpData.gender = handleCalculateGender(data.idNo)
        }

        tmpData.dateOfBirth = dayjs(tmpData.dateOfBirth).format('YYYY-MM-DD')
        agiliteUser = await createAgiliteUser({ ...tmpData, extraData: { entities: [] } })

        batch(() => {
          dispatch(authReducer.actions.setCompleteEmailSignUp(false))
          dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
          // dispatch(coreReducer.actions.enableDisableLeftMenu(true))
          dispatch(coreReducer.actions.hideTabs(true))
        })

        navigate(`/${pathArray.length > 0 ? pathArray[0] : ''}`)
      } else {
        if (!agiliteUser) {
          setIsLogin(false)
          throw new Error('Account not found. Please Sign Up.')
        } else {
          if (agiliteUser.extraData.inviteStatus === false) {
            // When we get here we know the user has been invited by cell but has not completed the sign up process
            dispatch(authReducer.actions.setCompleteEmailSignUp(true))
          } else {
            dispatch(authReducer.actions.setCompleteEmailSignUp(false))

            let tmpEntities = agiliteUser.extraData.entities

            if (!tmpEntities.includes(coreState.entity._id)) {
              tmpEntities.push(coreState.entity._id)
              agiliteUser = await updateAgiliteUser(agiliteUser._id, { 'extraData.entities': tmpEntities })
            }
          }

          dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
          navigate(`/${pathArray.length > 0 ? pathArray[0] : ''}`)
        }
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhone = async (phoneNumber) => {
    let confirmationResult = null

    try {
      const appVerifier = window.recaptchaVerifier
      confirmationResult = await signInWithPhoneNumber(firebaseAuth, phoneNumber, appVerifier)

      window.confirmationResult = confirmationResult
      setConfirmationOpen(true)
      message.success('OTP Sent successfully')
      setResendTimeout(30)
    } catch (e) {
      // window.recaptchaVerifier.clear()
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhoneCode = async () => {
    const data = form.getFieldsValue()

    setLoading(true)

    try {
      setConfirmationOpen(false)
      data.phoneNumber = formatNumber(data.phoneNumber)

      await window.confirmationResult.confirm(confirmationCode)
      await handleSubmitExtended(data)
    } catch (e) {
      setConfirmationOpen(true)
      message.error(handleError(e, true))
    }

    setIsForgotAction(null)
    setConfirmationCode('')
    setLoading(false)
  }

  const handleForgotPhone = async () => {
    let agiliteUser = null

    setLoading(true)
    setForgotOpen(false)

    try {
      agiliteUser = await findAgiliteUser({ idNo })

      if (agiliteUser && agiliteUser.phoneNumber) {
        setIsForgotAction(agiliteUser.phoneNumber)

        await handleLoginPhone(agiliteUser.phoneNumber)

        setConfirmationOpen(true)
      } else {
        message.error('No account found based on ID No provided')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setIdNo('')
    setLoading(false)
  }

  const [form] = Form.useForm()
  const { token } = theme.useToken()

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendTimeout > 0) {
        setResendTimeout(resendTimeout - 1)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [resendTimeout])

  return (
    <>
      {loading ? (
        <Row>
          <Col span={24}>
            <AgiliteSkeleton spinnerTip='loading...' />
          </Col>
        </Row>
      ) : (
        <Card
          size='small'
          type='inner'
          style={{ border: 'none', background: 'none ' }}
          bodyStyle={{ background: 'none' }}
        >
          <Form
            form={form}
            name='completeSignUp'
            initialValues={{
              firstName: '',
              lastName: '',
              idNo: '',
              race: '',
              gender: '',
              dateOfBirth: '',
              residentialAddress: '',
              phoneNumber: '',
              email: ''
            }}
            onFinishFailed={(e) => {
              message.error(e.errorFields[0].errors[0])
            }}
            onFinish={handleSubmit}
            autoComplete='off'
            layout='vertical'
            style={{ marginTop: 10 }}
          >
            <center style={{ marginBottom: 20 }}>
              <MobileTwoTone style={{ fontSize: 50 }} />
            </center>
            <center>
              <b style={{ marginBottom: 0 }}>CELLPHONE {isLogin ? ' SIGN-IN' : 'REGISTRATION'} </b>
            </center>
            <center>
              A OTP (One-time PIN) will be sent to your cellphone
              {isLogin ? (
                `, which will allow you to sign into ${coreState.entity.name}`
              ) : (
                <>
                  {' '}
                  after selecting the <b>Register</b> button below, which will allow you to sign into the{' '}
                  {coreState.entity.name} Portal
                </>
              )}
              .
            </center>
            <Row gutter={[12, 12]} style={{ marginTop: 24 }}>
              {!isLogin ? (
                <>
                  <Col span={24}>
                    <center>
                      <b>PERSONAL DETAILS</b>
                    </center>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label='First Name'
                      name='firstName'
                      rules={[{ required: true, message: 'Please provide your First Name' }]}
                    >
                      <Input
                        placeholder='e.g. Jane'
                        maxLength={50}
                        onChange={(e) => {
                          specialFieldValidation(form, record, setRecord, {
                            key: 'firstName',
                            event: e,
                            validationConfig: {
                              letters: { allowed: true, onlyCaps: false },
                              numbers: false,
                              spaces: true
                            }
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label='Last Name'
                      name='lastName'
                      rules={[{ required: true, message: 'Please provide your Last Name' }]}
                    >
                      <Input
                        placeholder='e.g. Doe'
                        maxLength={50}
                        onChange={(e) => {
                          specialFieldValidation(form, record, setRecord, {
                            key: 'lastName',
                            event: e,
                            validationConfig: {
                              letters: { allowed: true, onlyCaps: false },
                              numbers: false,
                              spaces: true
                            }
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Radio.Group
                      style={{ marginBottom: 8 }}
                      onChange={(e) => {
                        setIdType(e.target.value)
                        setCountry('ZA')
                        setResidentVisitor(null)
                        form.setFieldValue('idNo', null)
                      }}
                      value={idType}
                    >
                      <Radio value={'id'}>South African ID (RSA Resident)</Radio>
                      <Radio value={'pass'}>Passport</Radio>
                    </Radio.Group>

                    {idType === 'id' ? (
                      <Form.Item
                        name='idNo'
                        rules={[
                          { required: true, message: 'Please provide your ID Number.' },
                          { min: 13, message: 'Invalid South African ID Number' }
                        ]}
                      >
                        <Input
                          placeholder='e.g. 790725*******'
                          maxLength={13}
                          onChange={(e) => {
                            specialFieldValidation(form, record, setRecord, {
                              key: 'idNo',
                              event: e,
                              validationConfig: { letters: { allowed: false, onlyCaps: false }, numbers: true }
                            })
                          }}
                        />
                      </Form.Item>
                    ) : (
                      <>
                        <Form.Item
                          name='idNo'
                          rules={[{ required: true, message: 'Please provide your Passport Number.' }]}
                          onChange={(e) => {
                            specialFieldValidation(form, record, setRecord, {
                              key: 'idNo',
                              event: e,
                              validationConfig: {
                                letters: { allowed: true, onlyCaps: true },
                                numbers: true,
                                space: false
                              }
                            })
                          }}
                        >
                          <Input placeholder='e.g. AZ330B*******' maxLength={35} />
                        </Form.Item>
                        <Form.Item
                          label='Gender'
                          name='gender'
                          rules={[{ required: true, message: 'Please select your Gender.' }]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              form.setFieldValue('gender', e.target.value)
                            }}
                            buttonStyle='solid'
                          >
                            <Space wrap>
                              <Radio.Button value='female'>Female</Radio.Button>
                              <Radio.Button value='male'>Male</Radio.Button>
                              <Radio.Button value='other'>Other</Radio.Button>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </>
                    )}
                  </Col>
                  {idType === 'pass' ? (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name='residentVisitor'
                        label='RSA Resident / Visitor'
                        rules={[{ required: true, message: 'Please if you are a RSA Resident or Visitor' }]}
                      >
                        <Select
                          placeholder='RSA Resident or Visitor'
                          options={[
                            { label: 'RSA Resident', value: 'resident' },
                            { label: 'Visiting From Abroad', value: 'visitor' }
                          ]}
                          onChange={(value) => {
                            if (value === 'resident') {
                              setCountry('ZA')
                            }

                            setResidentVisitor(value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  ) : undefined}
                  {residentVisitor === 'visitor' ? (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name='country'
                        label='Country Of Residence'
                        rules={[{ required: true, message: 'Please provide your Country Of Residence' }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp='label'
                          loading={countriesLoading}
                          placeholder='e.g South Africa'
                          options={countries.map((e) => {
                            return {
                              label: e.name,
                              value: e.code
                            }
                          })}
                          onChange={(value) => {
                            setCountry(value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  ) : undefined}
                  {country === 'ZA' ? (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        label='Race'
                        name='race'
                        extra='Collected for Medical Purposes only'
                        rules={[{ required: true, message: 'Please select your Race.' }]}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            form.setFieldValue('race', e.target.value)
                          }}
                          buttonStyle='solid'
                        >
                          <Space wrap>
                            <Radio.Button value='African'>African</Radio.Button>
                            <Radio.Button value='Coloured'>Coloured</Radio.Button>
                            <Radio.Button value='Indian'>Indian</Radio.Button>
                            <Radio.Button value='White'>White</Radio.Button>
                            <Radio.Button value='Other'>Other</Radio.Button>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  ) : undefined}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label='Date Of Birth'
                      name='dateOfBirth'
                      rules={[{ required: true, message: 'Please specify your Date Of Birth.' }]}
                    >
                      <DatePickerModal
                        form={form}
                        key={'dateOfBirth'}
                        initialValue={form.getFieldValue('dateOfBirth')}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : undefined}
              <Col
                xs={24}
                sm={24}
                md={isLogin ? 24 : 12}
                lg={isLogin ? 24 : 12}
                xl={isLogin ? 24 : 12}
                xxl={isLogin ? 24 : 12}
              >
                <div style={isLogin ? { display: 'flex', justifyContent: 'center' } : {}}>
                  <Form.Item
                    label={isLogin ? false : 'Cellphone'}
                    style={{ marginTop: isLogin ? 20 : 0 }}
                    name='phoneNumber'
                    rules={[{ required: true, message: 'Please provide your Cellphone Number.' }]}
                  >
                    <Input
                      placeholder='e.g. 81714****'
                      addonBefore={`${countryObject ? countryObject.phoneCode : '+27'}`}
                      maxLength={15}
                      minLength={9}
                      onChange={(e) => {
                        specialFieldValidation(form, record, setRecord, {
                          key: 'phoneNumber',
                          event: e,
                          validationConfig: { letters: { allowed: false, onlyCaps: true }, numbers: true }
                        })
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>

              {!isLogin ? (
                <>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label='Physical Address'
                      name='residentialAddress'
                      rules={[{ required: true, message: 'Please provide a Physical Address.' }]}
                    >
                      <GoogleAddress
                        selectProps={{
                          placeholder: form.getFieldValue('residentialAddress')
                            ? form.getFieldValue('residentialAddress')
                            : 'e.g. 5 Doe Street, Gqeberha, South Africa',
                          onChange: (e) => {
                            form.setFieldValue('residentialAddress', e.label)
                            form.validateFields(['residentialAddress'])
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <center>
                      <b>{`${
                        !residentVisitor || residentVisitor === 'resident' ? 'MEDICAL AID' : 'TRAVEL INSURANCE'
                      }`}</b>
                    </center>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      requiredMark='optional'
                      label={`${
                        !residentVisitor || residentVisitor === 'resident'
                          ? 'Medical Aid Name'
                          : 'Travel Insurance Provider'
                      }`}
                      name={['medicalAid', 'name']}
                    >
                      <Input placeholder='e.g. Discovery Health Scheme' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      requiredMark='optional'
                      label={`${
                        !residentVisitor || residentVisitor === 'resident'
                          ? 'Medical Aid Plan'
                          : 'Travel Insurance Plan'
                      }`}
                      name={['medicalAid', 'plan']}
                    >
                      <Input placeholder='e.g. Coastal Saver' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      requiredMark='optional'
                      label={`${
                        !residentVisitor || residentVisitor === 'resident'
                          ? 'Medical Aid Number'
                          : 'Travel Insurance Number'
                      }`}
                      name={['medicalAid', 'number']}
                    >
                      <Input placeholder='e.g. 335***' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      requiredMark='optional'
                      label='Dependant Number'
                      name={['medicalAid', 'dependantNumber']}
                    >
                      <Input placeholder='e.g. 03' />
                    </Form.Item>
                  </Col>
                </>
              ) : undefined}
            </Row>
            <Row style={{ marginTop: 20 }} justify='center' gutter={[12, 12]}>
              <Col>
                <Button
                  disabled={submitDisabled}
                  onClick={() => setFacet('options')}
                  style={{ backgroundColor: token.colorSecondary }}
                  type='primary'
                >
                  <Space>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span>Back</span>
                  </Space>
                </Button>
              </Col>
              <Col>
                <Button disabled={submitDisabled} htmlType='submit' type='primary'>
                  <Space>
                    <span>{isLogin ? 'Sign In' : 'Register'}</span>
                    <FontAwesomeIcon icon={faSignIn} />
                  </Space>
                </Button>
              </Col>
            </Row>
            {isLogin ? (
              <center style={{ marginTop: 20 }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  onClick={() => {
                    setForgotOpen(true)
                  }}
                  style={{ color: token.colorPrimary }}
                >
                  Forgot Cellphone Number?
                </a>
              </center>
            ) : undefined}
            <center>{errorMessage ? errorMessage : undefined}</center>
          </Form>
        </Card>
      )}
      <div id='recaptcha-container'></div>
      <Modal
        title='Confirmation Code'
        footer={null}
        open={confirmationOpen}
        onCancel={() => setConfirmationOpen(false)}
      >
        <Row justify='center'>
          <Col>
            Please provide the Confirmation Code sent to your Cellphone
            {isForgotAction ? <b>: {isForgotAction}</b> : undefined}
            <Input
              maxLength={6}
              controls={false}
              value={confirmationCode}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value) || e.target.value === '') {
                  setConfirmationCode(e.target.value)
                } else {
                  setConfirmationCode(confirmationCode)
                }
              }}
              placeholder='e.g. 087654'
            />
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginTop: 20 }}>
          <Col>
            <Button
              type='primary'
              disabled={submitDisabled || resendTimeout > 0}
              onClick={() => {
                const data = form.getFieldsValue()
                handleSubmit(data)
              }}
            >
              Resend Code {resendTimeout > 0 ? `(${resendTimeout})` : null}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={submitDisabled}
              type='primary'
              style={{ background: token.colorSuccess }}
              onClick={() => handleLoginPhoneCode()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title='Requesting Cellphone Number'
        open={forgotOpen}
        closable={false}
        okText='Submit'
        cancelButtonProps={{ danger: true }}
        onOk={() => handleForgotPhone()}
        onCancel={() => {
          setForgotOpen(false)
          setIdNo('')
        }}
      >
        Please enter your ID/Passport Number
        <Input
          value={idNo}
          maxLength={35}
          onChange={(e) => {
            if (/[^A-Za-z0-9]+/g.test(e.target.value) === false) {
              setIdNo(e.target.value.toUpperCase())
            }
          }}
        />
      </Modal>
    </>
  )
}

export default PhoneSignUpForm
