import { createSlice } from '@reduxjs/toolkit'
import state from './state'

const SubscriptionsReducer = createSlice({
  name: 'subscriptions',
  initialState: state,
  reducers: {
    setSubscriptions: (state, action) => {
      state.data = action.payload
    }
  }
})

export default SubscriptionsReducer
