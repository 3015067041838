// src/components/Detection/BGDetection.js
import React, { useContext, useEffect, useState, useRef } from 'react'
import BluetoothContext from '../../BluetoothContext'
import { Col, Space, Spin, theme } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import CustomButton from '../../../../reusable-components/CustomButton'

const Detection = {
  BG: 'Blood Glucose'
}

const BGDetection = ({ setBusy }) => {
  const { engine, bleCommandCharacteristic } = useContext(BluetoothContext)
  const [glucose, setGlucose] = useState(null)
  const [error, setError] = useState(null)
  const [isDetecting, setIsDetecting] = useState(false)

  // Use useRef for writeQueue to maintain state across renders
  const writeQueue = useRef(Promise.resolve())

  useEffect(() => {
    if (!engine || !bleCommandCharacteristic) {
      console.warn('Engine or bleCommandCharacteristic not available for BGDetection')
      return
    }

    if (isDetecting) {
      console.log('Registering BG Listener')
      engine.registBGListener({
        onProcess: (type, data) => {
          console.log('BG onProcess:', { type, data })
          if (bleCommandCharacteristic) {
            writeQueue.current = writeQueue.current
              .then(() => bleCommandCharacteristic.writeValue(data))
              .catch((err) => {
                console.error('Error writing to bleCommandCharacteristic:', err)
              })
          }
        },
        onResult: (result) => {
          console.log('BG onResult:', result)
          if (result && result.glucose !== undefined) {
            setGlucose(result.glucose.toFixed(2))
            setError(null)
          } else {
            console.warn('Invalid BG result received:', result)
            setError('Invalid result received from device.')
          }
          // Stop detection after receiving result
          if (engine && engine.stopDetect) {
            engine.stopDetect(Detection.BG)
          }
          setBusy(false)
          setIsDetecting(false)
        },
        onException: (err) => {
          console.error('BG Detection Error:', err)
          setError(`BG Detection Error: ${err.message || 'Unknown error'}`)
          // Stop detection after exception
          if (engine && engine.stopDetect) {
            engine.stopDetect(Detection.BG)
          }
          setBusy(false)
          setIsDetecting(false)
        }
      })
    }

    return () => {
      console.log('Unregistering BG Listener')
      if (engine && engine.unregistBGListener) {
        engine.unregistBGListener()
      } else {
        console.warn('Engine or unregistBGListener method not available')
      }
    }
    // eslint-disable-next-line
  }, [engine, bleCommandCharacteristic, isDetecting])

  const startDetection = () => {
    if (isDetecting) {
      console.warn('Detection is already running')
      return
    }
    if (engine && bleCommandCharacteristic) {
      setError(null)
      setGlucose(null)
      setBusy(true)
      setIsDetecting(true)
      engine.startDetect(Detection.BG)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
      setError('Device is not ready. Please wait and try again.')
    }
  }

  const stopDetection = () => {
    if (engine && bleCommandCharacteristic) {
      engine.stopDetect(Detection.BG)
      setBusy(false)
      setIsDetecting(false)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }
  const { token } = theme.useToken()
  return (
    <>
      {!engine || !bleCommandCharacteristic ? (
        <p>Device is not ready. Please wait...</p>
      ) : (
        <CustomRow>
          <Col span={24}>{error && <p style={{ color: 'red' }}>{error}</p>}</Col>
          <Col span={24}>
            <p>Glucose Level:: {isDetecting ? <Spin size='small' /> : glucose !== null ? `${glucose} mmol/L` : '--'}</p>
          </Col>
          <Col span={24}>
            <Space>
              <CustomButton type='primary' onClick={startDetection} text='Start' disabled={isDetecting} size='small' />
              <CustomButton
                text='Stop'
                type='primary'
                style={{ background: !isDetecting ? '' : token.colorError }}
                onClick={stopDetection}
                disabled={!isDetecting}
                size='small'
              />
            </Space>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default BGDetection
