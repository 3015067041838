import React, { useEffect, useState } from 'react'
import { Card, message, DatePicker, Col, Modal, Space, theme } from 'antd'

import { readMacroTrackerMetaData, readMacroTrackers, readSavedMacroTrackerMeals } from './utils/macro-tracker-utils'
import { useSelector } from 'react-redux'
import { handleError, hexToRGBA } from '../lib/utils'
import CustomRow from '../reusable-components/CustomRow'
import NewImageUpload from './components/NewImageUpload'
import MacroGraphs from './components/MacroGraphs'
import DayView from './components/DayView'
import dayjs from 'dayjs'
import { ContainerCard } from '../reusable-components/AgiliteCards'
import MacroRings from './components/MacroRings'
import './style/macro-tracker.css'

const AIMacroTrackerWrapper = ({ userRef, isDoctor }) => {
  const RangePicker = DatePicker.RangePicker

  // Auth State
  const authState = useSelector((state) => state.auth)
  const [limits, setLimits] = useState(null)

  const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false)

  // Loading trackers
  const [limitsLoading, setLimitsLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  // Array of all entries captured to date
  const [trackerData, setTrackerData] = useState([])
  const [todaysTrackers, setTodaysTrackers] = useState([])

  // Filtering
  // Starts a week range to today
  const [dateRange, setDateRange] = useState(dayjs().subtract(7, 'days'), dayjs())

  const [savedMeals, setSavedMeals] = useState([])

  useEffect(() => {
    handleFetchSavedMeals()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleFetchMetaData()
    // eslint-disable-next-line
  }, [])

  const handleFetchMetaData = async () => {
    let tmpData = null

    setLimitsLoading(true)
    try {
      tmpData = await readMacroTrackerMetaData({ userRef: userRef ? userRef : authState.agiliteUser._id })
      setLimits(tmpData[0])
    } catch (error) {
      message.error(handleError(error))
    }
    setLimitsLoading(false)
  }

  const handleFetchSavedMeals = async () => {
    let tmpData = []
    try {
      tmpData = await readSavedMacroTrackerMeals({ userRef: userRef ? userRef : authState.agiliteUser._id })
      setSavedMeals(tmpData)
    } catch (error) {
      message.error(handleError(error))
    }
  }

  const handleSetTodaysTrackers = (tmpTrackers) => {
    tmpTrackers = tmpTrackers.filter(
      (tracker) => dayjs(tracker.createdAt).format('DD MM YYYY') === dayjs().format('DD MM YYYY')
    )
    // Filter todays trackers

    setTodaysTrackers(tmpTrackers)
  }

  useEffect(() => {
    // Fetch existing data
    handleReadMacroData()

    // eslint-disable-next-line
  }, [dateRange])

  const handleReadMacroData = async () => {
    let tmpTrackerData = []
    const filter = {}
    setLoading(true)

    try {
      filter.userRef = userRef ? userRef : authState.agiliteUser._id

      // Only set date filters id date range is set
      // Todays trackers will be fetched regardless as all data will be fetched when no date range is specified
      if (dateRange[0] && dateRange[[1]]) {
        filter.$or = [
          {
            createdAt: {
              $gte: new Date(dayjs().format('DD MMM YYYY')),
              $lt: new Date(dayjs().add(1, 'day').format('DD MMM YYYY'))
            }
          }
        ]

        filter.$or.push({
          createdAt: {
            $gte: new Date(dayjs(dateRange[0]).format('DD MMM YYYY')),
            $lte: new Date(dayjs(dateRange[1]).format('DD MMM YYYY'))
          }
        })
      }

      // Reads macros
      tmpTrackerData = await readMacroTrackers(filter)
      handleSetTodaysTrackers(tmpTrackerData)

      tmpTrackerData = tmpTrackerData.filter((i) => {
        if (dayjs(dateRange[1]).format('DD MMM YYYY') < dayjs(i.createdAt).format('DD MMM YYYY')) {
          return false
        }
        if (dayjs(dateRange[0]).format('DD MMM YYYY') > dayjs(i.createdAt).format('DD MMM YYYY')) {
          return false
        }

        return true
      })

      // Fetch macro data
      setTrackerData(tmpTrackerData)
    } catch (e) {
      message.error(e)
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  // Get the most recent tracker entry for the ring graphs
  const latestMacroData = todaysTrackers.length > 0 ? todaysTrackers[todaysTrackers.length - 1] : null

  return (
    <ContainerCard>
      <CustomRow>
        <Col span={24}>
          <Card title='Overview' size='small' headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}>
            <DayView
              limits={limits}
              setLimits={setLimits}
              limitsLoading={limitsLoading}
              trackerData={todaysTrackers}
              todaysTrackers={todaysTrackers}
              setImageUploadModalOpen={setImageUploadModalOpen}
              isDoctor={isDoctor}
              userRef={userRef}
            />
          </Card>
        </Col>

        {latestMacroData && (
          <Col span={24}>
            <Card
              size='small'
              title='Latest Meal'
              bodyStyle={{ padding: 10 }}
              headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            >
              {/* Pass unadjusted macroData to MacroRings, and pass servings for adjustments */}
              <MacroRings
                servings={latestMacroData.servings}
                limits={limits}
                macroData={latestMacroData}
                style={{ maxWidth: '300px' }}
              />
            </Card>
          </Col>
        )}
        <Col span={24}>
          <Card
            headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            size='small'
            type='inner'
            bodyStyle={{ padding: 0 }}
            title={
              <Space>
                <p>Date Range:</p>
                <RangePicker
                  value={dateRange}
                  onChange={(dates) => {
                    if (dates) {
                      setDateRange(dates)
                    } else {
                      setDateRange([null, null])
                    }
                  }} // Set the date range for macros
                  style={{ maxWidth: '300px' }}
                />
              </Space>
            }
          >
            <MacroGraphs trackerData={trackerData} loading={loading} />
          </Card>
        </Col>

        <Modal footer={false} open={imageUploadModalOpen} closable={false} maskClosable={false}>
          <NewImageUpload
            handleRefresh={handleReadMacroData}
            savedMeals={savedMeals}
            setSavedMeals={setSavedMeals}
            setModalOpen={setImageUploadModalOpen}
            trackerData={trackerData}
            setTrackerData={setTrackerData}
            limits={limits}
          />
        </Modal>
      </CustomRow>
    </ContainerCard>
  )
}

export default AIMacroTrackerWrapper
