import React, { useState, memo, useEffect } from 'react'
import { Col, Form, Button, message, theme, Modal, Card, Table, Space, Tooltip, Spin } from 'antd'
import dayjs from 'dayjs'
import Templates from '../utils/templates'
import { getMedHistProgress, updatePatient } from '../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import patientsCRUDReducer from '../utils/medical-history-reducer'
import { handleError, hexToRGBA } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import PatientMedicalHistory from './patient-medical-history-summary'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import MedHistAllergies from './sections/allergies'
import MedHistChronicIllnesses from './sections/chronic-illnesses'
import MedHistCovid from './sections/covid-information'
import MedHistFamily from './sections/family-history'
import MedHistLifestyle from './sections/life-style'
import MedHistMentalHealth from './sections/mental-health'
import MedHistSurgeries from './sections/surgeries'
import MedHistGeneralMeds from './sections/general-medications'
import { handleGenerateOldMedHist } from '../../../Bookings/components/Examination Components/utils/additional-screening-lib'
import { isDoctor, isNurse } from '../../../lib/profile-utils'

const MedicalHistoryForm = ({
  data,
  setHandleSubmit,
  setNotSaved,
  readOnly,
  wrapperLoad,
  isNurseExamination,
  isDependant,
  userRef,
  summary,
  setSummary,
  closeButton,
  onClose,
  setDependantMedHist,
  isPreviousBooking,
  refreshView,
  isPatient
}) => {
  const [clientForm] = Form.useForm()
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const [fieldValues, setFieldValues] = useState(Templates.dataTemplate)
  const [loading, setLoading] = useState(false)
  const [facet, setFacet] = useState('')
  const [oldMedHistLoading, setOldMedHistLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [facet])

  useEffect(() => {
    // Format Data
    const tmpData = JSON.parse(JSON.stringify(data ? data : fieldValues))

    if (!tmpData?.covidInfo?.lastVaccinatedDate) {
      tmpData.covidInfo = {
        ...tmpData.covidInfo,
        lastVaccinatedDate: dayjs(new Date())
      }
    } else {
      tmpData.covidInfo.lastVaccinatedDate = dayjs(new Date(tmpData.covidInfo.lastVaccinatedDate))
    }

    if (tmpData.surgicalHistory.list.length > 0) {
      tmpData.surgicalHistory.list.forEach((entry) => {
        entry.date = dayjs(entry.date)
      })
    }

    setFieldValues(tmpData)
    clientForm.setFieldsValue(tmpData)
    // eslint-disable-next-line
  }, [data])

  const handleSubmit = async (sectionData) => {
    let response = null
    let tmpSectionData = { ...sectionData }
    const nextIndex = medHistSections.findIndex((i) => i.key === facet) + 1
    const sectionInfo = medHistSections.find((i) => i.key === facet)
    tmpSectionData[facet].updatedAt = dayjs()

    setLoading(true)

    try {
      response = await updatePatient(sectionData, {
        userRef: isNurseExamination || isDependant ? userRef : state.auth.agiliteUser._id
      })
      if (!isDependant && !isNurseExamination) {
        dispatch(patientsCRUDReducer.actions.setPatient(response))
      }
      if (setDependantMedHist) {
        setDependantMedHist(response)
      }

      message.success(`${sectionInfo.title} saved successfully`)

      if (setNotSaved) {
        setNotSaved(false)
      }
      if (isNurseExamination || isDependant) {
        refreshView()
      }
      if (medHistSections[nextIndex]?.key) {
        setFacet(medHistSections[nextIndex].key)
      } else {
        setFacet('')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    setLoading(false)
  }

  if (setHandleSubmit) {
    setHandleSubmit(() => (x) => handleSubmit())
  }

  const { token } = theme.useToken()

  const medHistSections = [
    {
      title: 'Allergies',
      key: 'allergies',
      component: <MedHistAllergies clientForm={clientForm} setFacet={setFacet} />
    },
    {
      title: 'Chronic Illnesses and Medication',
      key: 'chronicIllnesses',
      component: (
        <MedHistChronicIllnesses
          clientForm={clientForm}
          saveFunction={handleSubmit}
          loading={loading}
          isDependant={isDependant || isNurseExamination}
          dependantData={data}
          setFacet={setFacet}
        />
      ),
      hideSave: true
    },
    {
      title: 'General Medications',
      key: 'meds',
      component: (
        <MedHistGeneralMeds
          setFacet={setFacet}
          clientForm={clientForm}
          saveFunction={handleSubmit}
          loading={loading}
          isDependant={isDependant || isNurseExamination}
          dependantData={data}
        />
      ),
      hideSave: true
    },
    {
      title: 'Covid Information',
      key: 'covidInfo',
      component: <MedHistCovid clientForm={clientForm} setFacet={setFacet} />
    },
    {
      title: 'Family History',
      key: 'familyHistory',
      component: <MedHistFamily clientForm={clientForm} setFacet={setFacet} />
    },
    {
      title: 'Lifestyle',
      key: 'lifestyle',
      component: <MedHistLifestyle clientForm={clientForm} setFacet={setFacet} />
    },
    {
      title: 'Mental Health',
      key: 'mentalHealth',
      component: <MedHistMentalHealth clientForm={clientForm} setFacet={setFacet} />
    },
    // {
    //   title: 'Renal Care Specific',
    //   key: 'renalCare',
    //   component: (
    //     <MedHistRenalCareSpecific
    //       clientForm={clientForm}
    //       fieldValues={fieldValues}
    //       setFieldValues={setFieldValues}
    //       setFacet={setFacet}
    //     />
    //   )
    // },
    {
      title: 'Surgical History',
      key: 'surgicalHistory',
      hideSave: true,
      component: (
        <MedHistSurgeries
          loading={loading}
          saveFunction={handleSubmit}
          clientForm={clientForm}
          dependantData={data}
          isDependant={isDependant || isNurseExamination}
          setFacet={setFacet}
        />
      )
    }
  ]

  const generateFacetContent = () => {
    let component = undefined
    const item = medHistSections.find((i) => i.key === facet)
    component = item?.component
    if (component) {
      return component
    }
  }
  const getSectionTitle = () => {
    let section = medHistSections.find((i) => i.key === facet)
    return section.title
  }

  return (
    <>
      {!isPatient ? (
        <>
          <>
            <Spin spinning={wrapperLoad} tip={'Please wait...'}>
              <PatientMedicalHistory isPreviousBooking={isPreviousBooking} data={data} setSummary={setSummary} />
            </Spin>
          </>
          <Modal open={!summary} footer={false} width={650}>
            {!wrapperLoad ? (
              <>
                {facet ? (
                  <>
                    <Form
                      onKeyDown={(e) => {
                        if (e.target.type === 'submit') e.preventDefault()
                      }}
                      onFinishFailed={(e) => {
                        message.error(e.errorFields[0].errors[0])
                      }}
                      onFieldsChange={() => {
                        setFieldValues(clientForm.getFieldValue())
                        if (setNotSaved) {
                          setNotSaved(true)
                        }
                      }}
                      disabled={readOnly}
                      onFinish={handleSubmit}
                      name='form'
                      form={clientForm}
                      initialValues={fieldValues}
                      layout='vertical'
                    >
                      <Card
                        title={
                          <center>
                            {medHistSections.findIndex((i) => i.key === facet) + 1}/8 {getSectionTitle().toUpperCase()}
                          </center>
                        }
                        type='inner'
                        bodyStyle={{ padding: 0 }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: 35,
                            border: '1px rgba(0,0,0,0.25) solid',
                            alignItems: 'center',
                            display: 'flex',
                            position: 'relative',
                            justifyContent: 'center'
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              display: 'flex',
                              justifyContent: 'center',
                              height: '100%',
                              left: 0,
                              color: 'black',
                              alignItems: 'center'
                            }}
                          ></div>
                          <div style={{ position: 'absolute' }}>
                            {data[medHistSections.find((i) => i.key === facet).key]?.updatedAt
                              ? `Updated: ${dayjs(
                                  data[medHistSections.find((i) => i.key === facet).key]?.updatedAt
                                ).format('DD MMMM YYYY')}`
                              : 'Updated: Never'}
                          </div>
                        </div>
                        {medHistSections.find((i) => i.key === facet)?.hideSave ? undefined : (
                          <div
                            style={{
                              width: '100%',
                              padding: 8,
                              background: hexToRGBA(token.colorWarning, 0.1),
                              marginTop: 1
                            }}
                          >
                            <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                              <Button
                                style={{
                                  backgroundColor: token.colorError,
                                  color: 'white'
                                }}
                                onClick={() => {
                                  setFacet('')
                                }}
                                loading={loading}
                              >
                                Close
                              </Button>
                              <Form.Item noStyle>
                                <Button
                                  style={{
                                    backgroundColor: token.colorSuccess,
                                    color: 'white'
                                  }}
                                  htmlType='submit'
                                  loading={loading}
                                >
                                  Save Changes
                                </Button>
                              </Form.Item>
                            </Space>
                          </div>
                        )}

                        {generateFacetContent()}
                        {medHistSections.find((i) => i.key === facet)?.hideSave ? undefined : (
                          <center>
                            <Form.Item noStyle>
                              <Button
                                style={{
                                  backgroundColor: token.colorSuccess,
                                  color: 'white',
                                  marginBottom: 24
                                }}
                                htmlType='submit'
                                loading={loading}
                              >
                                Save Changes
                              </Button>
                            </Form.Item>
                          </center>
                        )}
                      </Card>

                      {readOnly ? null : (
                        <CustomRow justify='center' style={{ marginTop: 24 }} gutter={[18, 18]}>
                          {closeButton && !readOnly ? (
                            <Col>
                              <Button
                                disabled={loading}
                                danger
                                onClick={() => {
                                  Modal.confirm({
                                    title: 'Confirmation',
                                    content:
                                      'Are you sure you want to close this form? Any unsaved changes will be lost.',
                                    onOk: onClose,
                                    okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
                                    okText: 'Yes',
                                    className: token.themeControl,
                                    cancelButtonProps: { danger: true }
                                  })
                                }}
                              >
                                Close
                              </Button>
                            </Col>
                          ) : null}
                        </CustomRow>
                      )}
                    </Form>
                  </>
                ) : (
                  <CustomRow>
                    <Col span={24}>
                      <Card
                        size='small'
                        headStyle={{ padding: 0, minHeight: 0, overflow: 'hidden' }}
                        title={
                          <div
                            style={{
                              width: '100%',
                              height: 35,
                              border: '1px rgba(0,0,0,0.25) solid',
                              alignItems: 'center',
                              display: 'flex',
                              position: 'relative',
                              justifyContent: 'center'
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                width: getMedHistProgress(data) + '%',
                                background: token.colorSuccess,
                                height: '100%',
                                left: 0,
                                color: 'black',
                                alignItems: 'center'
                              }}
                            ></div>
                            <div style={{ position: 'absolute' }}>
                              {getMedHistProgress(data) === '100' ? 'COMPLETE' : `${getMedHistProgress(data)}%`}
                            </div>
                          </div>
                        }
                      >
                        {isDoctor() || isNurse() ? (
                          <div style={{ padding: '8px 12px 12px 8px', background: '#f5f5f5' }}>
                            <center>
                              <Space>
                                <Button
                                  type='success'
                                  style={{ background: token.colorSuccess, color: '#fff' }}
                                  onClick={() => {
                                    setSummary(true)
                                  }}
                                >
                                  Finish Editing
                                </Button>
                              </Space>
                            </center>
                          </div>
                        ) : undefined}
                        <Table
                          pagination={false}
                          showHeader={false}
                          dataSource={medHistSections}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                setFacet(record.key)
                              }
                            }
                          }}
                          columns={[
                            {
                              title: '',
                              render: (record) => {
                                let updatedAt = fieldValues[record.key]?.updatedAt
                                return (
                                  <>
                                    {record.title}
                                    <br />
                                    <p>
                                      Updated:{' '}
                                      <small>{updatedAt ? dayjs(updatedAt).format('DD MMM YYYY') : 'Never'}</small>
                                    </p>
                                  </>
                                )
                              }
                            },
                            {
                              title: 'status',
                              key: 'updatedAt',
                              dataIndex: 'key',
                              render: (key) => {
                                let dataSource = fieldValues[key]
                                if (dataSource?.updatedAt) {
                                  return (
                                    <Space>
                                      <Tooltip title='Edit'>
                                        <FontAwesomeIcon
                                          style={{ fontSize: 20 }}
                                          color={token.colorPrimary}
                                          icon={faEdit}
                                        />
                                      </Tooltip>

                                      <Tooltip title='Complete'>
                                        <FontAwesomeIcon
                                          style={{ fontSize: 20 }}
                                          color={token.colorSuccess}
                                          icon={faCheckCircle}
                                        />{' '}
                                      </Tooltip>
                                    </Space>
                                  )
                                } else {
                                  return (
                                    <Space>
                                      <Tooltip title='Edit'>
                                        <FontAwesomeIcon
                                          style={{ fontSize: 20 }}
                                          color={token.colorPrimary}
                                          icon={faEdit}
                                        />
                                      </Tooltip>

                                      <Tooltip title='Complete'>
                                        <FontAwesomeIcon
                                          style={{ fontSize: 20 }}
                                          color={token.colorError}
                                          icon={faXmarkCircle}
                                        />{' '}
                                      </Tooltip>
                                    </Space>
                                  )
                                }
                              }
                            }
                          ]}
                        />
                      </Card>
                    </Col>
                  </CustomRow>
                )}
              </>
            ) : (
              <CustomRow className='basic-card'>
                <Col span={24}>
                  <AgiliteSkeleton
                    skActive
                    spinnerTip={`Loading ${isNurseExamination ? 'patient' : 'your'} medical history...`}
                  />
                </Col>
              </CustomRow>
            )}
          </Modal>{' '}
        </>
      ) : (
        <>
          {!wrapperLoad ? (
            <>
              {facet ? (
                <>
                  <Form
                    onKeyDown={(e) => {
                      if (e.target.type === 'submit') e.preventDefault()
                    }}
                    onFinishFailed={(e) => {
                      message.error(e.errorFields[0].errors[0])
                    }}
                    onFieldsChange={() => {
                      setFieldValues(clientForm.getFieldValue())
                      if (setNotSaved) {
                        setNotSaved(true)
                      }
                    }}
                    disabled={readOnly}
                    onFinish={handleSubmit}
                    name='form'
                    form={clientForm}
                    initialValues={fieldValues}
                    layout='vertical'
                  >
                    <Card
                      title={
                        <center>
                          {medHistSections.findIndex((i) => i.key === facet) + 1}/8 {getSectionTitle().toUpperCase()}
                        </center>
                      }
                      type='inner'
                      bodyStyle={{ padding: 0 }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 35,
                          border: '1px rgba(0,0,0,0.25) solid',
                          alignItems: 'center',
                          display: 'flex',
                          position: 'relative',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                            left: 0,
                            color: 'black',
                            alignItems: 'center'
                          }}
                        ></div>
                        <div style={{ position: 'absolute' }}>
                          {data[medHistSections.find((i) => i.key === facet).key]?.updatedAt
                            ? `Updated: ${dayjs(
                                data[medHistSections.find((i) => i.key === facet).key]?.updatedAt
                              ).format('DD MMMM YYYY')}`
                            : 'Updated: Never'}
                        </div>
                      </div>
                      {medHistSections.find((i) => i.key === facet)?.hideSave ? undefined : (
                        <div
                          style={{
                            width: '100%',
                            padding: 8,
                            background: hexToRGBA(token.colorWarning, 0.1),
                            marginTop: 1
                          }}
                        >
                          <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                            <Button
                              style={{
                                backgroundColor: token.colorError,
                                color: 'white'
                              }}
                              onClick={() => {
                                setFacet('')
                              }}
                              loading={loading}
                            >
                              Close
                            </Button>
                            <Form.Item noStyle>
                              <Button
                                style={{
                                  backgroundColor: token.colorSuccess,
                                  color: 'white'
                                }}
                                htmlType='submit'
                                loading={loading}
                              >
                                Save Changes
                              </Button>
                            </Form.Item>
                          </Space>
                        </div>
                      )}

                      {generateFacetContent()}
                      {medHistSections.find((i) => i.key === facet)?.hideSave ? undefined : (
                        <center>
                          <Form.Item noStyle>
                            <Button
                              style={{
                                backgroundColor: token.colorSuccess,
                                color: 'white',
                                marginBottom: 24
                              }}
                              htmlType='submit'
                              loading={loading}
                            >
                              Save Changes
                            </Button>
                          </Form.Item>
                        </center>
                      )}
                    </Card>

                    {readOnly ? null : (
                      <CustomRow justify='center' style={{ marginTop: 24 }} gutter={[18, 18]}>
                        {closeButton && !readOnly ? (
                          <Col>
                            <Button
                              disabled={loading}
                              danger
                              onClick={() => {
                                Modal.confirm({
                                  title: 'Confirmation',
                                  content:
                                    'Are you sure you want to close this form? Any unsaved changes will be lost.',
                                  onOk: onClose,
                                  okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
                                  okText: 'Yes',
                                  className: token.themeControl,
                                  cancelButtonProps: { danger: true }
                                })
                              }}
                            >
                              Close
                            </Button>
                          </Col>
                        ) : null}
                      </CustomRow>
                    )}
                  </Form>
                </>
              ) : (
                <CustomRow>
                  <Col span={24}>
                    <Card
                      size='small'
                      headStyle={{ padding: 0, minHeight: 0, overflow: 'hidden' }}
                      title={
                        <div
                          style={{
                            width: '100%',
                            height: 35,
                            border: '1px rgba(0,0,0,0.25) solid',
                            alignItems: 'center',
                            display: 'flex',
                            position: 'relative',
                            justifyContent: 'center'
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              display: 'flex',
                              justifyContent: 'center',
                              width: getMedHistProgress(data) + '%',
                              background: token.colorSuccess,
                              height: '100%',
                              left: 0,
                              color: 'black',
                              alignItems: 'center'
                            }}
                          ></div>
                          <div style={{ position: 'absolute' }}>
                            {getMedHistProgress(data) === '100' ? 'COMPLETE' : `${getMedHistProgress(data)}%`}
                          </div>
                        </div>
                      }
                    >
                      {isDoctor() || isNurse() ? (
                        <div style={{ padding: '8px 12px 12px 8px', background: '#f5f5f5' }}>
                          <center>
                            <Space>
                              <Button
                                loading={oldMedHistLoading}
                                type='primary'
                                onClick={() => {
                                  handleGenerateOldMedHist(userRef, setOldMedHistLoading)
                                }}
                              >
                                View Old Medical History
                              </Button>
                              <Button
                                type='success'
                                style={{ background: token.colorSuccess, color: '#fff' }}
                                onClick={() => {
                                  setSummary(true)
                                }}
                              >
                                Finish Editing
                              </Button>
                            </Space>
                          </center>
                        </div>
                      ) : undefined}
                      <Table
                        pagination={false}
                        showHeader={false}
                        dataSource={medHistSections}
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              setFacet(record.key)
                            }
                          }
                        }}
                        columns={[
                          {
                            title: '',
                            render: (record) => {
                              let updatedAt = fieldValues[record.key]?.updatedAt
                              return (
                                <>
                                  {record.title}
                                  <br />
                                  <p>
                                    Updated:{' '}
                                    <small>{updatedAt ? dayjs(updatedAt).format('DD MMM YYYY') : 'Never'}</small>
                                  </p>
                                </>
                              )
                            }
                          },
                          {
                            title: 'status',
                            key: 'updatedAt',
                            dataIndex: 'key',
                            render: (key) => {
                              let dataSource = fieldValues[key]
                              if (dataSource?.updatedAt) {
                                return (
                                  <Space>
                                    <Tooltip title='Edit'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorPrimary}
                                        icon={faEdit}
                                      />
                                    </Tooltip>

                                    <Tooltip title='Complete'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorSuccess}
                                        icon={faCheckCircle}
                                      />{' '}
                                    </Tooltip>
                                  </Space>
                                )
                              } else {
                                return (
                                  <Space>
                                    <Tooltip title='Edit'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorPrimary}
                                        icon={faEdit}
                                      />
                                    </Tooltip>

                                    <Tooltip title='Complete'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorError}
                                        icon={faXmarkCircle}
                                      />{' '}
                                    </Tooltip>
                                  </Space>
                                )
                              }
                            }
                          }
                        ]}
                      />
                    </Card>
                  </Col>
                </CustomRow>
              )}
            </>
          ) : (
            <CustomRow className='basic-card'>
              <Col span={24}>
                <AgiliteSkeleton
                  skActive
                  spinnerTip={`Loading ${isNurseExamination ? 'patient' : 'your'} medical history...`}
                />
              </Col>
            </CustomRow>
          )}
        </>
      )}
    </>
  )
}

const BasicForm = memo(MedicalHistoryForm)

export default BasicForm
