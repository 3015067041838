const LibEnums = {
  LOG_PROFILE_KEY: 'error_logs',
  META_CLINIC: 'Meta-Clinic',
  META_CLINIC_PORTAL: 'Portal',
  errorCodes: {
    400: 400
  },
  contentTypes: {
    APPLICATION_JSON: 'application/json'
  }
}

export default LibEnums
