import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useState, useRef } from 'react'

const MobileCameraCapture = ({ onAfterCapture }) => {
  const [imageData, setImageData] = useState(null)
  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      // Convert the file to a base64 data URL
      const reader = new FileReader()
      reader.onload = () => {
        const base64Data = reader.result
        setImageData(base64Data)

        // Call the onAfterCapture callback with the base64 data and the original file
        if (onAfterCapture) {
          onAfterCapture(base64Data, file)
        }
      }
      reader.onerror = (error) => {
        console.error('Error reading file:', error)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <>
      <input
        type='file'
        accept='image/*'
        capture='environment' // Use "user" for the front camera, "environment" for the back camera
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        id='cameraInput'
      />
      <Button style={{ width: '100%' }} type='primary' onClick={handleButtonClick}>
        Take Picture <FontAwesomeIcon icon={faCamera} />
      </Button>

      {imageData && (
        <div style={{ marginTop: '20px' }}>
          <h2>Preview:</h2>
          <img src={imageData} alt='Captured' style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
        </div>
      )}
    </>
  )
}

export default MobileCameraCapture
