import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Col, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import NewBooking from '../../Bookings/components/booking-search-wrapper'

const ScheduleVirtualConsult = ({ targetPatient, setModalOpen, bookingData, setBookingData }) => {
  return (
    <CustomRow>
      <Col span={24}>
        <FontAwesomeIcon
          icon={faXmarkCircle}
          onClick={() => {
            setModalOpen(false)
          }}
        />
      </Col>
      <Col span={24}>
        <NewBooking
          isVirtualVisit
          targetPatient={targetPatient}
          targetBooking={bookingData._id}
          onAfterSuccess={(info) => {
            message.success('Virtual consult scheduled successfully.')
            setBookingData({ ...bookingData, ...info })
            setModalOpen(false)
          }}
          onAfterFail={() => {}}
        />
      </Col>
    </CustomRow>
  )
}

export default ScheduleVirtualConsult
