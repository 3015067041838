import React, { useEffect, useState } from 'react'
import { Col, Empty, Radio, Space, Table, Tooltip, message, theme } from 'antd'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { countBookings, readAllBookings } from '../../bookings/utils/utils'
import { handleCancelBooking } from '../../../lib/booking-utils'
import { updateBooking } from '../../../Bookings/utils/utils'
import templates from '../../bookings/utils/templates'
import CustomButton from '../../../reusable-components/CustomButton'
import CustomRow from '../../../reusable-components/CustomRow'
import { handleError } from '../../../lib/utils'
import PatientExaminationForm from '../../../Bookings/components/booking-examination-wrapper'
import CoreEnums from '../../../../core/utils/enums'
import coreReducer from '../../../../core/utils/reducer'
import { getBookingState } from '../../../Bookings/utils/bpm-utils'

const EmployeeBookingsView = ({ userRef }) => {
  const authState = useSelector((state) => state.auth)
  const [patientBookings, setPatientBookings] = useState([])
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [rangeFilter, setRangeFilter] = useState('today')

  useEffect(() => {
    handleGetAllBookings()
    // eslint-disable-next-line
  }, [page, pageSize])

  useEffect(() => {
    handleGetAllBookings()
    // eslint-disable-next-line
  }, [rangeFilter, userRef])

  const handleGetAllBookings = async () => {
    let tmpBookings = []
    let qry = null
    let tmpTotal = 0

    setLoading(true)

    try {
      if (rangeFilter === 'upcoming') {
        qry = {
          bookingDate: {
            $gte: dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD')
          }
        }
      } else if (rangeFilter === 'previous') {
        qry = {
          bookingDate: {
            $lt: dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z')
          }
        }
      } else if (rangeFilter === 'today') {
        qry = {
          bookingDate: {
            $gt: dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z'),
            $lt: dayjs(new Date()).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z')
          }
        }
      } else {
        qry = {}
      }

      qry.userRef = userRef

      tmpTotal = await countBookings(qry)
      tmpBookings = await readAllBookings(qry, page, pageSize, {
        sort: {
          bookingDate: -1
        }
      })
      setPatientBookings(tmpBookings)
      setTotal(tmpTotal)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleOpenBooking = async (record) => {
    let workflowHistory = {}

    setLoading(true)

    try {
      if (record.processRef) {
        workflowHistory = await getBookingState([record.processRef])
        workflowHistory.history.splice(0, 1)
      }

      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          label: `Booking: ${record.patientName}`,
          children: (
            <PatientExaminationForm
              data={record}
              refreshView={handleGetAllBookings}
              workflowHistory={workflowHistory}
            />
          )
        })
      )
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  return (
    <CustomRow className='basic-card'>
      <Col span={24}>
        <Space wrap>
          <Tooltip>
            <CustomButton
              size='small'
              type='primary'
              text='Refresh'
              icon={faRefresh}
              onClick={() => handleGetAllBookings()}
              disabled={loading}
            />
          </Tooltip>

          <Radio.Group disabled={loading} value={rangeFilter} onChange={(e) => setRangeFilter(e.target.value)}>
            <Radio.Button value='previous'>Previous</Radio.Button>
            <Radio.Button value='today'>Today</Radio.Button>
            <Radio.Button value='upcoming'>Upcoming</Radio.Button>
            <Radio.Button value='all'>All</Radio.Button>
          </Radio.Group>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          bordered
          size='small'
          loading={loading}
          dataSource={patientBookings}
          locale={{
            emptyText: <Empty description='No Bookings found' />
          }}
          columns={templates
            .columnTemplate(
              token,
              patientBookings,
              false,
              (record) => handleCancelBooking(authState, record, updateBooking, handleGetAllBookings, token),
              authState,
              handleOpenBooking
            )
            .filter((record) => record.hidden !== true)}
          pagination={{
            current: page,
            pageSize,
            total: total,
            onChange: (page, pageSize) => {
              setPage(page)
              setPageSize(pageSize)
            },
            showSizeChanger: true,
            hideOnSinglePage: true,
            position: ['bottomCenter']
          }}
          scroll={{ x: 1000 }}
        />
      </Col>
    </CustomRow>
  )
}

export default EmployeeBookingsView
