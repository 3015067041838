import dayjs from 'dayjs'
import { convertDateTimeSAST } from '../../../lib/utils'

export const calculateMonthStart = () => {
  let todaysDate = convertDateTimeSAST(new Date(), true)
  let month = dayjs(todaysDate).month()
  let returnDate = dayjs(todaysDate).set('month', month).set('date', 1).format('YYYY-MM-DD')
  return returnDate
}

export const calculateMonthEnd = () => {
  let todaysDate = convertDateTimeSAST(new Date(), true)
  let month = dayjs(todaysDate).month()
  let returnDate = dayjs(todaysDate).set('month', month).set('date', 31).format('YYYY-MM-DD')
  return returnDate
}
