import React from 'react'

import { Button, Card, Space, Table, message, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import CoreEnums from '../../../core/utils/enums'
import coreReducer from '../../../core/utils/reducer'
import ClinicalReportingTemplatesForm from './clinical-reports-form'
import { deleteDataPointTemplate } from './utils/clinical-reporting-utils'
import { handleError } from '../../lib/utils'
import { columnTemplate, dataTemplate } from './utils/clinical-reporting-template'

const ClinicalReportingTemplatesListView = ({ data, loading, handleGetData }) => {
  const dispatch = useDispatch()

  const { token } = theme.useToken()

  const handleOpenForm = (isNew, record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: isNew
          ? `${CoreEnums.tabKeys.CLINICAL_REPORTING_TEMPLATES}_new`
          : `${CoreEnums.tabKeys.CLINICAL_REPORTING_TEMPLATES}_${record._id}`,
        closable: true,
        label: isNew ? 'New Data Point Template' : `Data Point Template: ${record.name}`,
        children: (
          <ClinicalReportingTemplatesForm
            isNew={isNew}
            record={isNew ? dataTemplate() : record}
            handleCloseForm={handleCloseForm}
          />
        )
      })
    )
  }

  const handleCloseForm = (isNew, record) => {
    handleGetData()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNew
          ? `${CoreEnums.tabKeys.CLINICAL_REPORTING_TEMPLATES}_new`
          : `${CoreEnums.tabKeys.CLINICAL_REPORTING_TEMPLATES}_${record._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const handleDelete = async (record) => {
    try {
      await deleteDataPointTemplate(record._id)
      handleGetData()
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <Card
      title={
        <Button
          type='primary'
          onClick={() => {
            handleOpenForm(true, {})
          }}
        >
          <Space>
            <FontAwesomeIcon icon={faAdd} />
            Create
          </Space>
        </Button>
      }
      extra={
        <Button onClick={() => handleGetData()}>
          <Space>
            <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
            Refresh
          </Space>
        </Button>
      }
    >
      <Table
        size='small'
        loading={loading}
        dataSource={data}
        columns={columnTemplate(token, handleDelete)}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              handleOpenForm(false, record)
            }
          }
        }}
      />
    </Card>
  )
}

export default ClinicalReportingTemplatesListView
