import React, { useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Col, Row, theme, Button, InputNumber, Modal, message, Card, Empty } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCamera } from '@fortawesome/free-solid-svg-icons'
import { handleError, hexToRGBA } from '../../lib/utils'
import { updateDailyLimits } from '../utils/macro-tracker-utils'
import { useSelector } from 'react-redux'
import HealthyAlternativesList from './HealthyAlternativesList'

const DayView = ({ todaysTrackers, setImageUploadModalOpen, limits, setLimits, isDoctor, userRef }) => {
  const authState = useSelector((state) => state.auth)
  const [HAModalOpen, setHAModelOpen] = useState(false)

  // State to hold temporary limits for setting daily nutrient limits
  const [tmpLimits, setTmpLimits] = useState({
    carb: 0,
    prot: 0,
    sug: 0,
    fat: 0,
    cal: 0
  })

  const [isLimitModalVisible, setIsLimitModalVisible] = useState(false)

  const { token } = theme.useToken()

  // Calculate the cumulative totals based on trackerData

  // Handler for setting daily limits
  const handleSetLimits = async () => {
    const payload = {}

    payload.macros = tmpLimits
    payload.createdBy = authState.agiliteUser._id
    payload.createdAt = new Date()
    payload.userRef = isDoctor ? userRef : authState.agiliteUser._id
    try {
      // Update daily limits using backend API
      await updateDailyLimits({ userRef: authState.agiliteUser._id }, payload)
    } catch (e) {
      message.error(handleError(e))
    }
    // Update limits state after successful API call
    setLimits({ ...limits, macros: tmpLimits })
    setIsLimitModalVisible(false)
  }

  // Cap progress bar values at 100%

  const calculateBarWidth = (key) => {
    let total = 0
    // eslint-disable-next-line
    let keyReferences = todaysTrackers.map((tracker) => {
      if (tracker.macros[key]) {
        return tracker.macros[key]
      }
    })

    keyReferences.forEach((ref) => {
      total = total + ref
    })
    if (!total) {
      return 0
    }

    if (!limits?.macros[key]) {
      return 0
    }

    let percent = (total / limits?.macros[key]) * 100

    if (percent > 100) {
      return 100
    } else {
      return percent
    }
  }

  return (
    <CustomRow>
      <Col xs={24} sm={24} md={10} lg={8} xl={6} style={{ minHeight: '100%' }}>
        <Button
          type='primary'
          onClick={() => {
            setHAModelOpen(true)
          }}
          style={{ width: '100%' }}
        >
          View Recipes <FontAwesomeIcon icon={faBook} />
        </Button>
        {/* New Meal Entry Block */}
        {isDoctor ? undefined : (
          <Button
            type='primary'
            style={{ marginTop: 12, width: '100%', background: token.colorSuccess }}
            onClick={() => setImageUploadModalOpen(true)}
          >
            Take Picture <FontAwesomeIcon icon={faCamera} />
          </Button>
        )}
        <Button
          type='primary'
          onClick={() => {
            setTmpLimits({ ...limits?.macros })
            setIsLimitModalVisible(true)
          }}
          style={{ marginTop: 12, width: '100%', background: token.colorSecondary }}
        >
          Set Daily Nutrient Limits
        </Button>

        {/* Daily Nutrient Limits Card */}
      </Col>
      <Col xs={24} sm={24} md={14} lg={16} xl={18}>
        <Card
          size='small'
          title='Daily Limits'
          headStyle={{ background: hexToRGBA(token.colorSecondary, 0.2) }}
          style={{ width: '100%', flex: window.innerWidth >= 992 ? '1.4' : '1' }}
        >
          {limits ? (
            <>
              <div style={{ display: 'grid', gridTemplateColumns: '75px 1fr 65px', gap: 12, alignItems: 'center' }}>
                <small>PROTEIN</small>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `${calculateBarWidth('prot')}% 1fr`,
                    border: '1px #ccc solid'
                  }}
                >
                  <div style={{ height: 10, background: '#ff8000' }}></div>
                  <div style={{ height: 10 }}></div>
                </div>
                <small>{limits?.macros?.prot}g</small>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '75px 1fr 65px', gap: 12, alignItems: 'center' }}>
                <small>FATS</small>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `${calculateBarWidth('fat')}% 1fr`,
                    border: '1px #ccc solid'
                  }}
                >
                  <div style={{ height: 10, background: '#2ecc71' }}></div>
                  <div style={{ height: 10 }}></div>
                </div>
                <small>{limits?.macros?.fat}g</small>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '75px 1fr 65px', gap: 12, alignItems: 'center' }}>
                <small>CARBS</small>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `${calculateBarWidth('carb')}% 1fr`,
                    border: '1px #ccc solid'
                  }}
                >
                  <div style={{ height: 10, background: '#3498db' }}></div>
                  <div style={{ height: 10 }}></div>
                </div>
                <small>{limits?.macros?.carb}g</small>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '75px 1fr 65px', gap: 12, alignItems: 'center' }}>
                <small>SUGAR</small>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `${calculateBarWidth('sug')}% 1fr`,
                    border: '1px #ccc solid'
                  }}
                >
                  <div style={{ height: 10, background: '#ff1493' }}></div>
                  <div style={{ height: 10 }}></div>
                </div>
                <small>{limits?.macros?.sug}g</small>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '75px 1fr 65px', gap: 12, alignItems: 'center' }}>
                <small>CAL</small>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `${calculateBarWidth('cal')}% 1fr`,
                    border: '1px #ccc solid'
                  }}
                >
                  <div style={{ background: '#f1c40f' }}></div>
                  <div style={{ height: 10 }}></div>
                </div>
                <small>{limits?.macros?.cal} kcal</small>
              </div>
            </>
          ) : (
            <Empty description='No Limits Set' />
          )}

          {/* Modal for setting daily nutrient limits */}
          <Modal
            title='Set Daily Nutrient Limits'
            visible={isLimitModalVisible}
            onOk={handleSetLimits}
            onCancel={() => setIsLimitModalVisible(false)}
            okText='Set Limits'
            cancelText='Cancel'
          >
            <Card style={{ width: '100%', backgroundColor: '#f9f9f9', borderColor: '#d9d9d9' }}>
              {' '}
              <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={12}>
                  <label style={{ fontWeight: 'bold' }}>Fat (g): </label>
                  <InputNumber
                    min={0}
                    value={tmpLimits?.fat}
                    onChange={(value) => setTmpLimits({ ...tmpLimits, fat: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col span={12}>
                  <label style={{ fontWeight: 'bold' }}>Carbs (g): </label>
                  <InputNumber
                    min={0}
                    value={tmpLimits?.carb}
                    onChange={(value) => setTmpLimits({ ...tmpLimits, carb: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={12}>
                  <label style={{ fontWeight: 'bold' }}>Sugar (g): </label>
                  <InputNumber
                    min={0}
                    value={tmpLimits?.sug}
                    onChange={(value) => setTmpLimits({ ...tmpLimits, sug: value })}
                    style={{ width: '100%' }}
                  />
                </Col>

                <Col span={12}>
                  <label style={{ fontWeight: 'bold' }}>Protein (g): </label>
                  <InputNumber
                    min={0}
                    value={tmpLimits?.prot}
                    onChange={(value) => setTmpLimits({ ...tmpLimits, prot: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={12}>
                  <label style={{ fontWeight: 'bold' }}>Calories (kcal): </label>
                  <InputNumber
                    min={0}
                    value={tmpLimits?.cal}
                    onChange={(value) => setTmpLimits({ ...tmpLimits, cal: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </Card>
          </Modal>
          <Modal open={HAModalOpen} footer={false} destroyOnClose maskClosable={false} closable={false}>
            <HealthyAlternativesList isDoctor userRef={userRef} setModalOpen={setHAModelOpen} />
          </Modal>
        </Card>
      </Col>
    </CustomRow>
  )
}

export default DayView
