import { Space } from 'antd'
import CustomButton from '../../../reusable-components/CustomButton'
import { convertDateTimeSAST } from '../../../lib/utils'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faMinus, faPlus, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import store from '../../../../store'

export const handleGetQuantity = (activity) => {
  if (activity) {
    let stockIn = activity.filter((stockItem) => stockItem?.type === 'in').map((i) => i.quantity)
    let stockOut = activity.filter((stockItem) => stockItem?.type === 'out').map((i) => i.quantity)
    let stockInTotal = stockIn.reduce((a, b) => a + b, 0) - stockOut.reduce((a, b) => a + b, 0)
    return stockInTotal
  } else {
    return 0
  }
}
export const InventoryTemplates = {
  columnTemplate: (handleUpdateItem, handleStockAdjustment, setCurrentInventoryItem, setLocation, filter) => [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Min | Max',
      render: (record) => `${record.min} | ${record.max}`
    },
    {
      title: 'Quantity',
      render: (item) => {
        let quantity = handleGetQuantity(item.activity)
        return <b style={{ color: quantity > 0 ? 'green' : quantity < 0 ? 'red' : undefined }}>{quantity}</b>
      }
    },
    {
      title: 'Actions',
      render: (item) => {
        return (
          <Space wrap>
            <CustomButton
              size='small'
              type='primary'
              text='Edit'
              onClick={() => {
                handleUpdateItem(item)
              }}
            />
            <CustomButton
              size='small'
              type='primary'
              text='View'
              onClick={() => {
                setCurrentInventoryItem(item)
                if (filter.clinicQuery) {
                  let tmpLocation = store.getState().clinics.data.find((clinic) => clinic._id === filter.clinicQuery)
                  setCurrentInventoryItem(item)
                  setLocation({ clinic: tmpLocation, activity: item.activity })
                }
              }}
            />
            <CustomButton
              size='small'
              type='success'
              text={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => {
                handleStockAdjustment(item, 'in')
              }}
            />
            <CustomButton
              size='small'
              type='danger'
              text={<FontAwesomeIcon icon={faMinus} />}
              onClick={() => {
                handleStockAdjustment(item, 'out')
              }}
            />
          </Space>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (isActive) => {
        return (
          <FontAwesomeIcon
            style={{ fontSize: 20 }}
            color={isActive ? 'green' : 'red'}
            icon={isActive ? faCheckCircle : faXmarkCircle}
          />
        )
      }
    }
  ]
}

export const locationTemplates = {
  columnTemplate: (setLocation) => [
    {
      title: 'Location',
      dataIndex: 'clinic',
      key: 'clinic',
      render: (clinic) => {
        return clinic.name
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'activity',
      key: 'activity',
      render: (activity) => {
        let quantity = handleGetQuantity(activity)
        return <b style={{ color: quantity > 0 ? 'green' : quantity < 0 ? 'red' : undefined }}>{quantity}</b>
      }
    },
    {
      title: 'Actions',
      render: (item) => (
        <CustomButton
          text='View Activity'
          size='small'
          type='primary'
          onClick={() => {
            setLocation(item)
          }}
        />
      )
    }
  ]
}

export const activityHistoryTemplates = {
  columnTemplate: (setLocation) => [
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      key: 'createAt',
      render: (date) => {
        return dayjs(convertDateTimeSAST(date)).format('DD/MM/YYYY - HH:mm')
      }
    },
    {
      title: 'Responsibility Person',
      dataIndex: 'user',
      key: 'user',
      render: (user) => {
        return user ? user.firstName + ' ' + user?.lastName : <b style={{ color: 'red' }}>User not found</b>
      }
    },
    {
      title: 'Change',
      render: (entry) => {
        switch (entry.type) {
          case 'in':
            return <b style={{ color: 'green' }}>+{entry.quantity}</b>
          case 'out':
            return <b style={{ color: 'red' }}>-{entry.quantity}</b>
          default:
            return <b>{entry.quantity}</b>
        }
      }
    }
  ]
}
