import React, { memo } from 'react'
import { Col, Button, Table, Empty, Input, Space } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'

import Templates from '../utils/templates'

import _ from 'lodash'
import CustomButton from '../../../reusable-components/CustomButton'
import CustomRow from '../../../reusable-components/CustomRow'

const _ListView = ({ data, refreshView, createRecord, editRecord, loading, handleSearch }) => {
  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <Space wrap>
              <CustomButton
                size='small'
                text='Refresh'
                icon={faRefresh}
                onClick={() => {
                  refreshView()
                }}
                type='primary'
                disabled={loading}
              />
              <Button type='primary' onClick={createRecord} disabled={loading}>
                Add Entity
              </Button>
              <Input
                placeholder='Search for Entity Name'
                suffix={<FontAwesomeIcon icon={faSearch} />}
                allowClear
                onChange={(e) => {
                  handleSearch(e.target.value)
                }}
                style={{ width: 500, maxWidth: '100%' }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Table
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={_.sortBy(data, 'name')}
              pagination={false}
              scroll={{ x: 950 }}
              columns={Templates.columnTemplate(editRecord, null)}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => editRecord(record)
                }
              }}
              locale={{ emptyText: <Empty description='There are currently no Entities to show.' /> }}
            />
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

const ListView = memo(_ListView)

export default ListView
