import React, { useEffect, useState } from 'react'
import { readMedicalProfessionalUsers } from '../Admin/medical-professionals/utils/utils'
import EHRCalendar from './full-calendar'
import { message } from 'antd'
import { handleError } from '../lib/utils'
import { isAdmin, userClinics } from '../lib/profile-utils'
import { FullCalendarEnums } from './full-calendar-utils/full-calendar-enums'
import { useSelector } from 'react-redux'
import CalendarLoadPlaceholder from './calendar-load-placeholder'

const FullCalendarWrapper = () => {
  // REDUX STATES
  const clinicsState = useSelector((state) => state.clinics.data)

  // USESTATES
  const [professionalsListed, setProfessionalsListed] = useState([])
  const [medProfsOnDuty, setMedProfsOnDuty] = useState([])

  // NEW STATES
  const [medicalProfessionalsLoading, setMedicalProfessionalsLoading] = useState(true)
  const [currentView, setCurrentView] = useState(FullCalendarEnums.viewTypeKeys.DAY_VIEW)
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    fetchMedicalProfessionals()
    // eslint-disable-next-line
  }, [])

  const fetchMedicalProfessionals = async () => {
    setMedicalProfessionalsLoading(true)
    try {
      let tmpListedProfessionals = []
      let tmpmedProfsOnDuty = []
      let qry = {}
      if (isAdmin()) {
        qry = {
          'extraData.clinics': { $in: clinicsState.map((clinic) => clinic._id) }
        }
      } else {
        qry = {
          'extraData.clinics': { $in: userClinics() }
        }
      }

      // FETCH MED PROFS
      tmpListedProfessionals = await readMedicalProfessionalUsers(qry)

      setProfessionalsListed(tmpListedProfessionals)
      setMedProfsOnDuty(tmpmedProfsOnDuty)
    } catch (e) {
      message.error(handleError(e))
    }
    setMedicalProfessionalsLoading(false)
  }

  return (
    <>
      {medicalProfessionalsLoading ? (
        <CalendarLoadPlaceholder
          medicalProfessionalsLoading={medicalProfessionalsLoading}
          medicalProfessionals={professionalsListed}
          medProfsOnDuty={medProfsOnDuty}
          currentView={currentView}
          setCurrentView={setCurrentView}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
        />
      ) : (
        <EHRCalendar
          medicalProfessionalsLoading={medicalProfessionalsLoading}
          medicalProfessionals={professionalsListed}
          medProfsOnDuty={medProfsOnDuty}
          currentView={currentView}
          setCurrentView={setCurrentView}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
        />
      )}
    </>
  )
}

export default FullCalendarWrapper
