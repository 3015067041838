import { FullCalendarEnums } from './full-calendar-enums'

export const diaryViewOptions = [
  {
    label: FullCalendarEnums.viewTypeLabels.DAY_VIEW,
    value: FullCalendarEnums.viewTypeKeys.DAY_VIEW
  },
  {
    label: FullCalendarEnums.viewTypeLabels.WEEK_VIEW,
    value: FullCalendarEnums.viewTypeKeys.WEEK_VIEW
  },
  {
    label: FullCalendarEnums.viewTypeLabels.MONTH_VIEW,
    value: FullCalendarEnums.viewTypeKeys.MONTH_VIEW
  }
]
