import { Button, Card, Col, Divider, Empty, Input, Modal, Row, Select, Skeleton, Space, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { eachHalfHour, handleError } from '../../lib/utils'
import dayjs from 'dayjs'
import AvailabilityCalendar from './availability-calendar'
import AvailabilitySchedule from './availability-schedule'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { readMedicalProfessionalUsers, readProfessions } from '../../Admin/medical-professionals/utils/utils'
import { createAvailability, readAvailability, updateAvailability } from './utils/utils'
import { uniqBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faSearch,
  faUser,
  faUserDoctor,
  faUserNurse,
  faUtensils
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import CustomRow from '../../reusable-components/CustomRow'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const Availability = ({ isManager }) => {
  const clinicState = useSelector((state) => state.clinics)
  const authState = useSelector((state) => state.auth)
  const isMedProf = authState.agiliteUser && authState.agiliteUser.extraData.role.type === 'medical_professional'
  const [facet, setFacet] = useState('clinic-select')
  const [medicalProfessionals, setMedicalProfessionals] = useState([])
  const [selectedProfessional, setSelectedProfessional] = useState()
  const [availability, setAvailability] = useState([])
  const [rangePicker, setRangePicker] = useState(false)
  const [rangeFrom, setRangeFrom] = useState(null)
  const [date, setDate] = useState()
  const [rangeTo, setRangeTo] = useState(null)
  const [currentDay, setCurrentDay] = useState({
    date: null,
    timeSlots: []
  })
  const [clinics, setClinics] = useState([])
  const [professions, setProfessions] = useState([])
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [selectedClinicName, setSelectedClinicName] = useState(null)
  const [selectedProfession, setSelectedProfession] = useState(
    isMedProf ? authState.agiliteUser.extraData.profession : null
  )
  const [loading, setLoading] = useState(false)
  const [selectedProfDetails, setSelectedProfDetails] = useState()
  const [searchQry, setSearchQry] = useState('')
  const [clinicsLoading, setClinicsLoading] = useState(false)
  const [panelValue, setPanelValue] = useState(dayjs())
  // const [isSaved, setIsSaved] = useState()

  const onPanelClick = (value) => {
    setDate(value)

    if (isMedProf && !isManager) {
      const medProf = authState.agiliteUser
      const medProfId = authState.agiliteUser._id

      setSelectedProfDetails(medProf)
      viewTimeslots(medProfId)
      handleGenerateTimeslots(value)
    } else {
      setFacet('med-prof-select')
    }
  }

  const viewTimeslots = (value) => {
    setSelectedProfessional(value)
    setFacet('schedule')
  }

  useEffect(() => {
    handleGenerateTimeslots(date)
    // eslint-disable-next-line
  }, [selectedProfessional])

  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line
  }, [selectedProfession])

  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line
  }, [selectedClinic, clinicState.data])

  const handleGetData = async () => {
    let tmpClinics = []
    let tmpProfessions = []
    let tmpMedicalProfessionals = []
    let tmpAvailability = []
    setClinicsLoading(true)
    setLoading(true)

    try {
      tmpClinics = handleReturnPromise(readClinics)

      if (!isMedProf) {
        tmpProfessions = handleReturnPromise(readProfessions)
      }

      if (selectedClinic) {
        tmpMedicalProfessionals = handleReturnPromise(readMedicalProfessionalUsers, {
          'extraData.profession': selectedProfession,
          'extraData.clinics': { $in: [selectedClinic] }
        })
      }

      tmpAvailability = await handleReturnPromise(readAvailability, { profession: selectedProfession })

      Promise.all([tmpClinics, tmpProfessions, tmpMedicalProfessionals, tmpAvailability]).then((results) => {
        if (isMedProf) {
          setClinics(results[0].filter((i) => authState.agiliteUser.extraData.clinics.includes(i._id)))
        } else {
          setClinics(results[0])
        }
        setProfessions(results[1])
        setMedicalProfessionals(results[2])
        setAvailability(results[3])
      })
    } catch (e) {
      message.error(handleError(e))
    }
    setClinicsLoading(false)
    setLoading(false)
  }

  const handleReturnPromise = (func, qry) => {
    return new Promise((resolve, reject) => {
      let result = null

      ;(async () => {
        try {
          result = await func(qry)
          resolve(result)
        } catch (e) {
          reject(e)
        }
      })()
    })
  }

  const handleGenerateTimeslots = (value) => {
    const tmpEntry = availability.concat().find((i) => i.clinicRef === selectedClinic)
    const tmpTimeslots = []
    const existingTimeslots =
      tmpEntry?.availability.find((i) => i.date === dayjs(value).format('YYYY-MM-DD'))?.timeSlots || []

    // Generate slots for specified range
    eachHalfHour('06:00', '22:00').forEach((entry) => {
      let tmpEntry = entry

      // Format time (e.g 6:00 -> 06:00)
      if (tmpEntry.length === 4) {
        tmpEntry = '0' + tmpEntry
      }

      tmpTimeslots.push({
        label: tmpEntry,
        time: tmpEntry,
        linkedProfessional: null
      })
    })

    existingTimeslots
      .filter((i) => i.linkedProfessional === selectedProfessional)
      .forEach((timeslot) => {
        const itemIndex = tmpTimeslots.findIndex((i) => i.time === timeslot.time)
        if (itemIndex !== -1) {
          tmpTimeslots[itemIndex] = timeslot
        }
      })

    setCurrentDay({ date: dayjs(value).format('YYYY-MM-DD'), timeSlots: tmpTimeslots })
  }

  const handleTimeSlotChange = (time, value) => {
    const tmpTimeslots = currentDay.timeSlots.concat()
    const itemIndex = tmpTimeslots.findIndex((i) => i.time === time)

    if (value) {
      tmpTimeslots[itemIndex].linkedProfessional = selectedProfessional
    } else {
      tmpTimeslots[itemIndex].linkedProfessional = null
    }
    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
  }

  const saveDaySchedule = async () => {
    setLoading(true)
    try {
      const tmpAvailability = availability.concat()
      const tmpCurrentDay = JSON.parse(JSON.stringify(currentDay))

      let itemIndex = null
      let daysIndex = null

      // Check to see if a entry exists for the selected clinic
      itemIndex = tmpAvailability.findIndex((i) => i.clinicRef === selectedClinic)

      // Entry exists for selected clinic
      if (itemIndex !== -1) {
        // Check if an entry exists for the current selected day
        daysIndex = tmpAvailability[itemIndex].availability.findIndex((i) => i.date === tmpCurrentDay.date)

        let timeSlots = []
        if (tmpAvailability[itemIndex]?.availability[daysIndex]?.timeSlots) {
          timeSlots = JSON.parse(JSON.stringify(tmpAvailability[itemIndex].availability[daysIndex].timeSlots))
        }

        tmpCurrentDay.timeSlots.forEach((slot) => {
          const itemIndex = timeSlots.findIndex(
            (i) => i.time === slot.time && i.linkedProfessional === selectedProfessional
          )
          if (itemIndex !== -1) {
            timeSlots[itemIndex] = slot
          } else {
            timeSlots.push(slot)
          }
        })

        if (daysIndex !== -1) {
          // Update the entry for the current day
          tmpAvailability[itemIndex].availability[daysIndex] = {
            ...tmpCurrentDay,
            timeSlots: timeSlots
          }
        } else {
          // Create a new entry for the current day
          tmpAvailability[itemIndex].availability.push(tmpCurrentDay)
        }

        tmpAvailability[itemIndex].availability.map((entry) => {
          return (entry.timeSlots = filterNoAvailability(entry.timeSlots))
        })
        await updateAvailability(
          { clinicRef: selectedClinic, profession: selectedProfession },
          {
            availability: tmpAvailability[itemIndex].availability
          }
        )
      } else {
        // Entry doesn't exist for selected clinic, create instead
        tmpCurrentDay.timeSlots = filterNoAvailability(tmpCurrentDay.timeSlots)
        await createAvailability({
          clinicRef: selectedClinic,
          profession: selectedProfession,
          availability: [tmpCurrentDay]
        })
      }

      message.success('Schedule saved successfully')
      setSelectedProfessional(null)
      setFacet('calendar')
      setSearchQry('')
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const filterNoAvailability = (arr) => {
    const tmpArr = arr.concat()
    return tmpArr.filter((i) => i.linkedProfessional !== null)
  }

  const handleRangeSelect = (isClear) => {
    const tmpTimeslots = currentDay.timeSlots.concat()
    let timeSlots = null

    if (!isClear) {
      if (!rangeFrom || !rangeTo) {
        return message.error('Please select a valid range')
      }

      timeSlots = eachHalfHour(rangeFrom, rangeTo)

      tmpTimeslots.forEach((slot) => {
        slot.linkedProfessional = null
      })

      timeSlots.forEach((slot) => {
        if (slot.length === 4) {
          slot = '0' + slot
        }

        const itemIndex = tmpTimeslots.findIndex((i) => i.time === slot)

        if (itemIndex !== -1) {
          tmpTimeslots[itemIndex].linkedProfessional = selectedProfessional
        }
      })
    } else {
      tmpTimeslots.forEach((slot) => {
        slot.linkedProfessional = null
      })
    }

    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
    setRangeFrom()
    setRangeTo()
    setRangePicker()
  }

  const dateCellRender = (value) => {
    const tmpEntry = availability.find((i) => i.clinicRef === selectedClinic)
    let cellData = tmpEntry?.availability.find((i) => i.date === dayjs(value).format('YYYY-MM-DD'))
    let professionals = []

    if (cellData && cellData.timeSlots.find((i) => i.linkedProfessional !== null)) {
      cellData.timeSlots.forEach((timeslot) => {
        const itemIndex = professionals.findIndex((i) => i === timeslot.linkedProfessional)

        if (itemIndex === -1) {
          const profTimeslots = cellData.timeSlots
            .filter((i) => i.linkedProfessional === timeslot.linkedProfessional)
            .sort((a, b) => {
              return a.time.localeCompare(b.time)
            })
          professionals.push({ _id: timeslot.linkedProfessional, name: null, timeSlots: profTimeslots })
        }
      })

      professionals = uniqBy(professionals, '_id')

      professionals.forEach((prof) => {
        medicalProfessionals.forEach((medProf) => {
          if (medProf._id === prof._id) {
            prof.name = medProf.firstName + ' ' + medProf.lastName
          }
        })
      })

      return (
        <Row
          justify='center'
          style={{
            height: '100%',
            width: '100%',
            padding: '3px',
            alignItems: 'center',
            borderRadius: 5
          }}
          onClick={() => {
            onPanelClick(value)
          }}
          key={value}
        >
          <Col span={24}>
            {loading ? (
              <Card
                title={dayjs(value).format('DD')}
                size='small'
                style={{ height: 150 }}
                bodyStyle={{ height: 100, overflow: 'scroll' }}
                headStyle={{
                  backgroundColor:
                    dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? token.colorPrimary : '',
                  color: dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? 'white' : ''
                }}
              >
                <Skeleton />
              </Card>
            ) : (
              <>
                <Card
                  title={dayjs(value).format('DD')}
                  size='small'
                  style={{ height: 150 }}
                  bodyStyle={{ height: 100, overflowY: 'auto' }}
                  headStyle={{
                    backgroundColor:
                      dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? token.colorPrimary : '',
                    color: dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? 'white' : ''
                  }}
                >
                  {isMedProf && !isManager ? (
                    <>
                      {professionals.filter((i) => i._id === authState.agiliteUser._id).length > 0 ? (
                        <>
                          {professionals
                            .filter((i) => i._id === authState.agiliteUser._id)
                            .map((entry) => {
                              if (cellData && cellData.timeSlots.find((i) => i.linkedProfessional === entry._id)) {
                                return (
                                  <Row
                                    key={entry._id}
                                    justify='center'
                                    style={{ alignItems: 'center', minHeight: '100%' }}
                                  >
                                    <Col span={24}>
                                      <center style={{ fontSize: 20 }}>
                                        {entry.timeSlots[0].time} - {entry.timeSlots[entry.timeSlots.length - 1].time}
                                      </center>
                                    </Col>
                                  </Row>
                                )
                              }
                              return <>test</>
                            })}
                        </>
                      ) : (
                        <div
                          style={{
                            color: token.colorError,
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                          }}
                        >
                          No Availability
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {professionals.map((entry) => {
                        if (entry.name) {
                          return (
                            <Row key={entry._id} justify='center'>
                              <Col span={24}>
                                <center>
                                  {entry.name} <br />({entry.timeSlots[0].time} -{' '}
                                  {entry.timeSlots[entry.timeSlots.length - 1].time})
                                </center>
                                <Divider style={{ borderColor: '#cccccc', margin: 4 }} />
                              </Col>
                            </Row>
                          )
                        } else {
                          return <></>
                        }
                      })}
                    </>
                  )}
                </Card>
              </>
            )}
          </Col>
        </Row>
      )
    } else {
      return (
        <Row
          justify='center'
          style={{
            height: '100%',
            width: '100%',
            padding: '3px',
            alignItems: 'center',
            borderRadius: 5
          }}
          onClick={() => {
            onPanelClick(value)
          }}
          key={value}
        >
          <Col span={24}>
            <Card
              style={{ height: 150 }}
              bodyStyle={{ height: 100, overflowY: 'auto' }}
              headStyle={{
                backgroundColor:
                  dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? token.colorPrimary : '',
                color: dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? 'white' : ''
              }}
              title={dayjs(value).format('DD')}
              size='small'
            >
              <div
                style={{
                  color: token.colorError,
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}
              >
                No Availability
              </div>
            </Card>
          </Col>
        </Row>
      )
    }
  }

  const handleBlockOut = (time) => {
    const tmpTimeslots = JSON.parse(JSON.stringify(currentDay.timeSlots))
    const itemIndex = tmpTimeslots.findIndex((i) => i.time === time && i.linkedProfessional === selectedProfessional)
    if (itemIndex !== -1) {
      tmpTimeslots[itemIndex].blocked = true
    } else {
      return
    }
    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
  }

  const handleDelete = (time) => {
    const tmpTimeslots = JSON.parse(JSON.stringify(currentDay.timeSlots))
    const itemIndex = tmpTimeslots.findIndex((i) => i.time === time && i.linkedProfessional === selectedProfessional)
    if (itemIndex !== -1) {
      tmpTimeslots[itemIndex].linkedProfessional = null
    } else {
      return
    }
    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
  }

  const { token } = theme.useToken()

  const handleBack = () => {
    handleGetData()

    switch (facet) {
      case 'clinic-select':
        //close tsab
        break
      case 'profession-select':
        setFacet('clinic-select')
        break
      case 'calendar':
        if (isMedProf) {
          setFacet('clinic-select')
        } else {
          setFacet('profession-select')
        }
        break
      case 'med-prof-select':
        setFacet('calendar')
        break
      case 'schedule':
        if (isMedProf && !isManager) {
          setFacet('calendar')
        } else {
          setFacet('med-prof-select')
          setSelectedProfessional(null)
        }
        break
      default:
    }
  }

  const handleGenerateProfessionIcon = (value) => {
    let icon = null

    switch (value) {
      case 'doctor':
        icon = faUserDoctor
        break
      case 'nurse':
        icon = faUserNurse
        break
      case 'dietitian':
        icon = faUtensils
        break
      default:
        break
    }

    if (!icon) {
      icon = faUser
    }

    return <FontAwesomeIcon style={{ fontSize: 100 }} icon={icon} />
  }

  return (
    <>
      {medicalProfessionals ? (
        <Modal
          onCancel={() => {
            setRangePicker(false)
            setRangeFrom()
            setRangeTo()
          }}
          open={rangePicker}
          onOk={() => {
            handleRangeSelect(false)
          }}
          okText='Submit'
          closable={false}
          maskClosable={false}
          okButtonProps={{ style: { backgroundColor: token.colorSuccess } }}
          cancelButtonProps={{ danger: true }}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              From: <br />
              <Select
                value={rangeFrom}
                placeholder='-Select From Time-'
                options={eachHalfHour('06:00', '22:00').map((time) => {
                  if (time.length === 4) {
                    time = '0' + time
                  }
                  return {
                    value: time,
                    label: time
                  }
                })}
                onChange={(value) => {
                  setRangeFrom(value)
                }}
              />
            </Col>
            <Col span={24}>
              To: <br />
              <Select
                placeholder='-Select To Time-'
                value={rangeTo}
                options={eachHalfHour('06:00', '22:00').map((time) => {
                  if (time.length === 4) {
                    time = '0' + time
                  }
                  return {
                    value: time,
                    label: time
                  }
                })}
                onChange={(value) => {
                  setRangeTo(value)
                }}
              />
            </Col>
          </Row>
        </Modal>
      ) : null}

      <CustomRow>
        {facet === 'clinic-select' ? (
          <Col span={24}>
            <CustomRow className='basic-card' justifyContent='left'>
              {clinicsLoading || clinics.length === 0 ? (
                <Col span={24}>
                  <AgiliteSkeleton skActive spinnerTip='Loading clinics...' />
                </Col>
              ) : (
                <>
                  {clinics.length > 0 ? (
                    <>
                      {clinics.map((entry) => {
                        return (
                          <Col
                            style={{ width: 400, maxWidth: '100%' }}
                            onClick={() => {
                              setSelectedClinic(entry._id)
                              setSelectedClinicName(entry.name)
                              if (isMedProf) {
                                setFacet('calendar')
                              } else {
                                setFacet('profession-select')
                              }
                            }}
                            key={entry._id}
                          >
                            <Card
                              hoverable
                              bodyStyle={{ color: '#ffffff', background: token.colorPrimary }}
                              cover={
                                <center style={{ marginTop: 20, marginBottom: 20 }}>
                                  <img
                                    style={{
                                      width: 140,
                                      padding: 20,
                                      border: '1px #ccc solid',
                                      borderRadius: '50%',
                                      boxSizing: 'border-box'
                                    }}
                                    alt='example'
                                    src={entry.clinicLogo}
                                  />
                                </center>
                              }
                            >
                              <center>
                                <h2>{entry.name}</h2>
                              </center>
                            </Card>
                          </Col>
                        )
                      })}
                    </>
                  ) : (
                    <Empty description='No Clinics Found. Navigate to the clinics tab to add your first clinic.' />
                  )}
                </>
              )}
            </CustomRow>
          </Col>
        ) : undefined}

        {facet === 'profession-select' ? (
          <Col span={24}>
            <Card
              size='small'
              style={{ marginTop: 12 }}
              title={
                <Space>
                  <Button type='primary' onClick={() => handleBack()}>
                    <Space>
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <p>Back</p>
                    </Space>
                  </Button>
                  <h1 style={{ marginLeft: 24 }}>
                    {isMedProf ? (
                      <>
                        Your availability for <b style={{ color: token.colorPrimary }}>{selectedClinicName}</b>
                      </>
                    ) : (
                      <>
                        <b style={{ color: token.colorPrimary }}> {selectedClinicName}</b> availability for{' '}
                        <b style={{ color: token.colorPrimary }}> {selectedProfession}s</b>
                      </>
                    )}
                  </h1>
                </Space>
              }
            >
              <CustomRow justify={'center'}>
                <Col span={24}>
                  <CustomRow className='basic-card' justify='center'>
                    {professions.map((entry) => {
                      return (
                        <Col
                          style={{ width: 400, maxWidth: '100%' }}
                          onClick={() => {
                            setSelectedProfession(entry.value)
                            setFacet('calendar')
                          }}
                          key={entry.value}
                        >
                          <Card
                            hoverable
                            cover={
                              <center style={{ marginTop: 20, marginBottom: 20 }}>
                                {handleGenerateProfessionIcon(entry.value)}
                              </center>
                            }
                            bodyStyle={{ color: '#ffffff', background: token.colorPrimary }}
                          >
                            <center>{entry.label}</center>
                          </Card>
                        </Col>
                      )
                    })}
                  </CustomRow>
                </Col>
              </CustomRow>
            </Card>
          </Col>
        ) : undefined}
        {facet === 'calendar' ? (
          <Col span={24}>
            <Card
              size='small'
              style={{ marginTop: 12 }}
              title={
                <Space>
                  <Button type='primary' onClick={() => handleBack()}>
                    <Space>
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <p>Back</p>
                    </Space>
                  </Button>
                  <h1 style={{ marginLeft: 24 }}>
                    {isMedProf ? (
                      <>
                        Your availability for <b style={{ color: token.colorPrimary }}>{selectedClinicName}</b>
                      </>
                    ) : (
                      <>
                        <b style={{ color: token.colorPrimary }}> {selectedClinicName}</b> availability for{' '}
                        <b style={{ color: token.colorPrimary }}> {selectedProfession}s</b> -{' '}
                        <b>{dayjs(panelValue).format('MMMM YYYY')}</b>
                      </>
                    )}
                  </h1>
                </Space>
              }
            >
              <CustomRow justify={'center'}>
                <AvailabilityCalendar
                  loading={loading}
                  dateCellRender={dateCellRender}
                  onPanelClick={onPanelClick}
                  panelValue={panelValue}
                  setPanelValue={setPanelValue}
                />
              </CustomRow>
            </Card>
          </Col>
        ) : undefined}
        {facet === 'med-prof-select' ? (
          <Col span={24}>
            <Card
              size='small'
              style={{ marginTop: 12 }}
              title={
                <Space>
                  <Button
                    type='primary'
                    onClick={() => {
                      setSearchQry('')
                      handleBack()
                    }}
                  >
                    <Space>
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <p>Back</p>
                    </Space>
                  </Button>
                  <h1 style={{ marginLeft: 24 }}>
                    {isMedProf ? (
                      <>
                        Your availability for <b style={{ color: token.colorPrimary }}>{selectedClinicName}</b>
                      </>
                    ) : (
                      <>
                        <b style={{ color: token.colorPrimary }}> {selectedClinicName}</b> availability for{' '}
                        <b style={{ color: token.colorPrimary }}> {selectedProfession}s</b>
                      </>
                    )}
                  </h1>
                </Space>
              }
            >
              <CustomRow justify={'center'}>
                <Col span={24}>
                  <CustomRow className='basic-card' justify='center'>
                    {medicalProfessionals.length === 0 ? (
                      <Col span={24}>
                        <Empty description='No Medical Professionals Found' />
                      </Col>
                    ) : (
                      <>
                        {/* TODO: This is where */}
                        <CustomRow justify='center'>
                          <Col span={12}>
                            <Input
                              value={searchQry}
                              placeholder='Search for a Medical Professional'
                              suffix={<FontAwesomeIcon icon={faSearch} />}
                              onChange={(e) => {
                                setSearchQry(e.target.value)
                              }}
                            />
                          </Col>
                        </CustomRow>
                        {medicalProfessionals
                          .filter((i) => {
                            if (searchQry) {
                              return (
                                i.firstName.toLowerCase().includes(searchQry.toLowerCase()) ||
                                i.lastName.toLowerCase().includes(searchQry.toLowerCase())
                              )
                            } else {
                              return true
                            }
                          })
                          .filter((i) => i.extraData.profession === selectedProfession)
                          .map((entry) => {
                            return (
                              <Col
                                style={{ width: 400, maxWidth: '100%' }}
                                onClick={() => {
                                  setSelectedProfDetails(entry)
                                  viewTimeslots(entry._id)
                                }}
                                key={entry._id}
                              >
                                <Card
                                  hoverable
                                  cover={
                                    <center style={{ marginTop: 20, marginBottom: 20 }}>
                                      {handleGenerateProfessionIcon(selectedProfession)}
                                    </center>
                                  }
                                  bodyStyle={{ color: '#ffffff', background: token.colorPrimary }}
                                >
                                  <center>
                                    {entry.firstName} {entry.lastName}
                                  </center>
                                </Card>
                              </Col>
                            )
                          })}
                      </>
                    )}
                  </CustomRow>
                </Col>
              </CustomRow>
            </Card>
          </Col>
        ) : undefined}
        {facet === 'schedule' ? (
          <Col span={24}>
            <Card
              size='small'
              style={{ marginTop: 12 }}
              title={
                <Space>
                  <Button
                    type='primary'
                    onClick={() => {
                      handleBack()
                    }}
                  >
                    <Space>
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <p>Back</p>
                    </Space>
                  </Button>
                  <h1 style={{ marginLeft: 24 }}>
                    Setting availability for {dayjs(currentDay.date).format('DD MMMM YYYY')}
                  </h1>
                </Space>
              }
            >
              <CustomRow>
                <Col span={24}>
                  <AvailabilitySchedule
                    professionalDetails={selectedProfDetails}
                    handleBack={handleBack}
                    selectedProfession={selectedProfession}
                    medicalProfessionals={medicalProfessionals}
                    setFacet={setFacet}
                    loading={loading}
                    saveDaySchedule={saveDaySchedule}
                    handleBlockOut={handleBlockOut}
                    handleDelete={handleDelete}
                    handleTimeslotChange={handleTimeSlotChange}
                    setRangePicker={setRangePicker}
                    currentDay={currentDay}
                    handleRangeSelect={handleRangeSelect}
                    isMedProf={isMedProf}
                  />
                </Col>
              </CustomRow>
            </Card>
          </Col>
        ) : undefined}
      </CustomRow>
    </>
  )
}

export default Availability
