import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Card, Col, Row, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { readMedicalHistory, readPatients } from '../../Admin/patients/utils/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
// import coreReducer from '../../../core/utils/reducer'
// import CoreEnums from '../../../core/utils/enums'
// import Dependants from '../../Profile/components/dependants/dependants-list-view'
import { getMedHistProgress } from '../../Medical Vault/medical-history/utils/utils'

const MedicalHistoryDetails = ({ subscription, token }) => {
  const state = useSelector((state) => state)
  const dependantState = useSelector((state) => state.dependents.data)
  const [medHistoryComplete, setMedHistoryComplete] = useState(false)
  // eslint-disable-next-line
  const [dependants, setDependants] = useState([])
  const [dependantPatientRecords, setDependantPatientRecords] = useState([])
  const [loading, setLoading] = useState(true)
  // const dispatch = useDispatch()

  useEffect(() => {
    if (state.medicalHistory?.patient) {
      if (getMedHistProgress(state.medicalHistory?.patient) === '100') {
        setMedHistoryComplete(true)
      } else {
        setMedHistoryComplete(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetDependants()
    // eslint-disable-next-line
  }, [dependantState])

  const handleGetDependants = async () => {
    setLoading(true)

    try {
      let tmpDependants = []

      tmpDependants = await readPatients({ mainMemberId: state.auth.agiliteUser._id })
      setDependants(tmpDependants)

      await handleGetPatientRecords(tmpDependants)
    } catch (e) {
      message.error(e)
    }

    setLoading(false)
  }

  const handleGetPatientRecords = async (tmpDependants) => {
    try {
      let tmpRecords = []
      let dependantIds = tmpDependants.map((item) => item._id)

      if (tmpDependants.length > 0) {
        tmpRecords = await readMedicalHistory({ userRef: { $in: dependantIds } })
        setDependantPatientRecords(tmpRecords)
      }
    } catch (e) {
      message.error(e)
    }
  }

  const checkDependantMedHis = () => {
    let counter = 0

    for (const entry of dependantPatientRecords) {
      if (getMedHistProgress(entry) !== '100') {
        counter++
      }
    }

    return counter
  }

  // const handleMedHistory = () => {
  //   dispatch(coreReducer.actions.menuItemClick({ key: CoreEnums.tabKeys.MEDICAL_HISTORY }))
  // }

  // const handleFamilyAccounts = () => {
  //   dispatch(
  //     coreReducer.actions.addTab({
  //       key: CoreEnums.tabKeys.DEPENDANTS,
  //       closable: true,
  //       label: CoreEnums.tabTitles.DEPENDANTS,
  //       children: <Dependants />
  //     })
  //   )
  // }

  return (
    <>
      <Row justify='center' style={{ marginBottom: 10 }}>
        <Col span={24}>
          <center>
            <b>Medical History Details</b>
          </center>
          <center>
            <small>
              Please ensure the medical history of all members is up to date, to ensure the best possible outcome
            </small>
          </center>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Loading...' />
          </Col>
        </Row>
      ) : (
        <Row justify='center' gutter={[12, 12]}>
          <Col>
            <Card
              style={{
                border: medHistoryComplete ? `2px solid ${token.colorSuccess}` : `2px solid ${token.colorError}`
              }}
            >
              <Row justify='center'>
                <Col>
                  <center>
                    {medHistoryComplete ? (
                      <span style={{ color: token.colorSuccess }}>
                        <FontAwesomeIcon style={{ fontSize: 30 }} icon={faCheckCircle} />
                      </span>
                    ) : (
                      <span style={{ color: token.colorError }}>
                        <FontAwesomeIcon style={{ fontSize: 30 }} icon={faWarning} />
                      </span>
                    )}
                  </center>
                  <center style={{ marginTop: 10 }}>
                    {medHistoryComplete ? (
                      <>
                        <span style={{ color: token.colorSuccess }}>Medical History Completed</span>
                      </>
                    ) : (
                      <span style={{ color: token.colorError }}>Medical History Incomplete</span>
                    )}
                  </center>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* {subscription.extraData.plan && subscription.extraData.plan !== 'individual' ? (
            <Col>
              <Card
                style={{
                  border:
                    subscription.extraData.plan === 'individual' || checkDependantMedHis() === 0
                      ? `2px solid ${token.colorSuccess}`
                      : `2px solid ${token.colorError}`
                }}
              >
                <Row justify='center'>
                  <Col>
                    <center>
                      {subscription.extraData.plan === 'individual' || checkDependantMedHis() === 0 ? (
                        <span style={{ color: token.colorSuccess }}>
                          <FontAwesomeIcon style={{ fontSize: 30 }} icon={faCheckCircle} />
                        </span>
                      ) : (
                        <span style={{ color: token.colorError }}>
                          <FontAwesomeIcon style={{ fontSize: 30 }} icon={faWarning} />
                        </span>
                      )}
                    </center>
                    <center style={{ marginTop: 10 }}>Family Members: {dependants.length}</center>
                  </Col>
                </Row>
              </Card>
            </Col>
          ) : undefined} */}
        </Row>
      )}
      {subscription.extraData.plan && subscription.extraData.plan !== 'individual' && checkDependantMedHis() > 0 ? (
        <Row justify='center' style={{ marginTop: 12 }}>
          <Col>
            <Alert
              type='error'
              message={
                <span style={{ textAlign: 'center' }}>
                  <Space>
                    <FontAwesomeIcon icon={faWarning} style={{ color: token.colorError }} />
                    <span>{checkDependantMedHis()} family member(s) with incomplete medical history details.</span>
                  </Space>
                </span>
              }
            />
          </Col>
        </Row>
      ) : undefined}
    </>
  )
}

export default MedicalHistoryDetails
