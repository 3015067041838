import { Table, theme } from 'antd'
import React from 'react'
import { DataPointColumnTemplate } from './data-points-utils/data-point-templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { deleteClinicDataPoint } from './data-points-utils/data-point-utils'

const DataPointsListView = ({ dataPoints, refreshView, loading, handleOpenForm }) => {
  const { token } = theme.useToken()

  const handleDelete = (id) => {
    deleteClinicDataPoint(id)
    refreshView()
  }

  return (
    <Table
      loading={loading}
      size='small'
      bordered
      dataSource={dataPoints}
      columns={[
        ...DataPointColumnTemplate(),
        {
          title: 'Actions',
          width: '5%',
          render: (text, record, index) => (
            <center>
              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation()
                  handleDelete(record._id)
                }}
                color={token.colorError}
                icon={faTrash}
              />
            </center>
          )
        }
      ]}
      pagination={false}
      onRow={(record) => {
        return {
          onClick: () => {
            handleOpenForm(false, record)
          }
        }
      }}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Table
              loading={loading}
              size='small'
              showHeader={false}
              bordered
              dataSource={record.subFields}
              columns={DataPointColumnTemplate()}
              pagination={false}
            />
          )
        }
      }}
    />
  )
}

export default DataPointsListView
