export const AdditionalScreeningDataModel = () => {
  return {
    ohaInsulinList: {
      dependency: null,
      type: 'textArea',
      key: ['checkinData', 'diabetesScreening', 'ohaInsulin', 'list'],
      label: 'OHA/Insulin List',
      required: false,
      validationMsg: ''
    },
    lipidMedsListed: {
      dependency: ['checkinData', 'diabetesScreening', 'lipidMeds', 'applicable'],
      key: ['checkinData', 'diabetesScreening', 'lipidMeds', 'list'],
      label: 'Lipid Meds List',
      required: false,
      validationMsg: ''
    },
    aspirinMedsListed: {
      dependency: ['checkinData', 'diabetesScreening', 'aspirinMeds', 'applicable'],
      key: ['checkinData', 'diabetesScreening', 'aspirinMeds', 'list'],
      label: 'Aspirin Meds List',
      required: false,
      validationMsg: ''
    },
    bpMedsListed: {
      dependency: ['checkinData', 'diabetesScreening', 'bpMeds', 'applicable'],
      type: 'textArea',
      key: ['checkinData', 'diabetesScreening', 'bpMeds', 'list'],
      label: 'BP Meds List',
      required: false,
      validationMsg: ''
    },
    otherMedsListed: {
      dependency: null,
      type: 'textArea',
      key: ['checkinData', 'diabetesScreening', 'otherMeds', 'list'],
      label: 'BP Meds List',
      required: false,
      validationMsg: ''
    },
    ecgApplicable: {
      dependency: null,
      type: 'switch',
      key: ['checkinData', 'diabetesScreening', 'ecg', 'applicable'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Applicable',
      required: false,
      validationMsg: ''
    },
    ecgDate: {
      dependency: ['checkinData', 'diabetesScreening', 'ecg', 'applicable'],
      type: 'datePicker',
      key: ['checkinData', 'diabetesScreening', 'ecg', 'date'],
      label: 'Date',
      required: false,
      validationMsg: ''
    },
    ecgStatus: {
      dependency: ['checkinData', 'diabetesScreening', 'ecg', 'applicable'],
      type: 'radioGroupButtons',
      key: ['checkinData', 'diabetesScreening', 'ecg', 'status'],
      props: {
        buttonStyle: 'solid'
      },
      label: 'Status',
      options: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Abnormal', label: 'Abnormal' }
      ],
      required: false,
      validationMsg: ''
    },
    ecgInfarct: {
      dependency: ['checkinData', 'diabetesScreening', 'ecg', 'applicable'],
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'ecg', 'infarct'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Infarct',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    ecgAngina: {
      dependency: ['checkinData', 'diabetesScreening', 'ecg', 'applicable'],
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'ecg', 'angina'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Angina',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    ecgArthy: {
      dependency: ['checkinData', 'diabetesScreening', 'ecg', 'applicable'],
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'ecg', 'arthy'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Arthy',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    neuroNeuropathy: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'neuro', 'neuropathy'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Neuropathy',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    neuroPain: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'neuro', 'pain'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Pain',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    kidneyNephropathy: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'kidney', 'nephropathy'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Nephropathy',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    eyesRetinopathyLeft: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'eye', 'retinopathy', 'left'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Retinopathy (Left)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    eyesRetinopathyRight: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'eye', 'retinopathy', 'right'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Retinopathy (Right)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    feetPulsesL: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'pulse'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Pulses (L)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    feetSensationL: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'sensation'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Sensations (L)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    feetVibrationL: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'vibration'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Vibration (L)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    feetCallusL: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'callus'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Callus (L)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },

    feetPulsesR: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'pulseRight'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Pulses (R)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    feetSensationR: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'sensationRight'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Sensations (R)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    feetVibrationR: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'vibrationRight'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Vibration (R)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },
    feetCallusR: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'feet', 'callusRight'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Callus (R)',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: false,
      validationMsg: ''
    },

    pvd: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'pvd'],
      label: 'PVD',
      required: false,
      validationMsg: ''
    },
    lungsStatus: {
      dependency: null,
      type: 'radioGroupButtons',
      props: {
        buttonStyle: 'solid'
      },
      key: ['checkinData', 'diabetesScreening', 'lungs', 'status'],
      label: <h5>Status:</h5>,
      options: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Abnormal', label: 'Abnormal' }
      ],
      required: false,
      validationMsg: ''
    },
    skinStatus: {
      dependency: null,
      type: 'radioGroupButtons',
      key: ['checkinData', 'diabetesScreening', 'skin', 'status'],
      label: <h5>Status:</h5>,
      props: {
        buttonStyle: 'solid'
      },
      options: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Abnormal', label: 'Abnormal' }
      ],
      required: false,
      validationMsg: ''
    },

    skinInsInjSightHealthy: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'skin', 'injSightHealthy'],
      label: 'Insulin Injection Site Healthy',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      required: false,
      validationMsg: ''
    },
    skinOedema: {
      dependency: null,
      type: 'checkbox',
      key: ['checkinData', 'diabetesScreening', 'skin', 'oedema'],
      label: 'Oedema',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      required: false,
      validationMsg: ''
    },
    skinNotes: {
      type: 'checkboxGroup',
      key: ['checkinData', 'diabetesScreening', 'skin', 'notes'],
      options: [
        {
          label: 'Lipids',
          value: 'Lipids'
        },
        {
          label: 'Vitiligo',
          value: 'Vitiligo'
        },
        {
          label: 'Gout Tophi',
          value: 'Gout Tophi'
        },
        {
          label: 'Pigmentation  ',
          value: 'Pigmentation  '
        }
      ],
      label: '',
      required: false,
      validationMsg: ''
    },
    comorbiditiesHistory: {
      type: 'checkboxGroup',
      dependency: null,
      key: ['checkinData', 'diabetesScreening', 'comorbiditiesHistory'],
      label: <h5>History:</h5>,
      props: {
        colSpan: 'auto'
      },

      options: [
        {
          label: 'Celiac Disease',
          value: 'Celiac Disease'
        },
        {
          label: 'Chronic Kidney Disease',
          value: 'Chronic Kidney Disease'
        },
        {
          label: 'Cystic Fibroses',
          value: 'Cystic Fibroses'
        },
        {
          label: 'ED',
          value: 'ED'
        },
        {
          label: 'Hormone Therapy',
          value: 'Hormone Therapy'
        },
        {
          label: 'Hyperuricaemia',
          value: 'Hyperuricaemia'
        },
        {
          label: 'Hypothyroidsm',
          value: 'Hypothyroidsm'
        },
        {
          label: 'IHD',
          value: 'IHD'
        },
        {
          label: 'Menstruation',
          value: 'Menstruation'
        },
        {
          label: 'PCO',
          value: 'PCO'
        },
        {
          label: 'OSA',
          value: 'OSA'
        },
        {
          label: 'PVD',
          value: 'PVD'
        }
      ],
      validationMsg: ''
    },
    comorbiditiesHistoryVacc: {
      dependency: null,
      type: 'switch',
      props: {
        buttonStyle: 'solid'
      },
      key: ['checkinData', 'diabetesScreening', 'comorbiditiesHistoryVacc', 'applicable'],
      label: 'Vaccinated',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      required: false,
      validationMsg: ''
    },
    comorbiditiesHistoryVaccList: {
      dependency: ['checkinData', 'diabetesScreening', 'comorbiditiesHistoryVacc', 'applicable'],
      type: 'textArea',
      key: ['checkinData', 'diabetesScreening', 'comorbiditiesHistoryVacc', 'list'],
      label: <h5>Vaccinations List:</h5>,
      placeholder: 'List vaccinations',
      options: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Abnormal', label: 'Abnormal' }
      ],
      required: false,
      validationMsg: ''
    },
    urinary: {
      type: 'checkboxGroup',
      key: ['checkinData', 'diabetesScreening', 'urinary'],
      options: [
        { value: 'Infection', label: 'Infection' },
        { value: 'Flow Normal', label: 'Flow Normal' }
      ],
      label: '',
      placeholder: '',

      required: false,
      validationMsg: ''
    },
    urineTesting: {
      type: 'checkboxGroup',
      key: ['checkinData', 'diabetesScreening', 'urineTesting'],
      label: <h4>Urine Testing:</h4>,
      placeholder: '',
      options: [
        { value: 'Prot', label: 'Prot' },
        { value: 'Leucocytes', label: 'Leucocytes' },
        { value: 'Ketones', label: 'Ketones' },
        { value: 'Micro Albumin', label: 'Micro Albumin' }
      ],
      required: false,
      validationMsg: ''
    },

    dental: {
      type: 'checkboxGroup',
      key: ['checkinData', 'diabetesScreening', 'dental'],
      label: '',
      options: [
        { value: 'Dental Carries', label: 'Dental Carries' },
        { value: 'Broken Tooth', label: 'Broken Tooth' }
      ],
      placeholder: '',
      required: false,
      validationMsg: ''
    },

    autonomicNeuropath: {
      type: 'checkboxGroup',
      key: ['checkinData', 'diabetesScreening', 'autonomicNeuropath'],
      label: '',
      options: [
        { value: 'Nocturnal Diarrhea', label: 'Nocturnal Diarrhea' },
        { value: 'PP Sweating', label: 'PP Sweating' },
        { value: 'No Ortho BP', label: 'No Ortho BP' },
        { value: 'Palpitations', label: 'Palpitations' }
      ],
      placeholder: '',
      required: false,
      validationMsg: ''
    },
    visitsAttended: {
      type: 'textArea',
      key: ['checkinData', 'diabetesScreening', 'visitsAttended'],
      label: <h5>Notes:</h5>,
      placeholder: '',
      required: false,
      validationMsg: ''
    },
    assessment: {
      type: 'textArea',
      key: ['checkinData', 'diabetesScreening', 'assessment'],
      label: <h5>Notes:</h5>,
      placeholder: '',
      required: false,
      validationMsg: ''
    },
    plan: {
      type: 'textArea',
      key: ['checkinData', 'diabetesScreening', 'plan'],
      label: <h5>Notes:</h5>,
      placeholder: '',
      required: false,
      validationMsg: ''
    }
  }
}

export const AdditionalScreeningDataTemplate = () => {
  return {
    qualification: 'N/A',
    practiceNo: 'N/A',
    docName: 'N/A',
    practiceName: 'N/A',
    address: 'N/A',
    clinicPhone: 'N/A',
    fax: 'N/A',
    phone: 'N/A',
    patientName: 'N/A',
    dateOfBirth: 'N/A',
    visitDate: 'N/A',
    diagnosis: 'N/A',
    ohaInsulin: [
      {
        medication: 'N/A'
      }
    ],
    lipidMeds: [
      {
        medication: 'N/A'
      }
    ],
    aspirinMeds: [
      {
        medication: 'N/A'
      }
    ],
    bpMeds: [
      {
        medication: 'N/A'
      }
    ],
    smoking: 'N/A',
    dietCompliance: 'N/A',
    habits: 'N/A',
    hypoglycemia: 'N/A',
    celiacDisease: 'N/A',
    hyperuricaemia: 'N/A',
    cysticFibroses: 'N/A',
    pco: 'N/A',
    hypothyroidsm: 'N/A',
    osa: 'N/A',
    ed: 'N/A',
    vacc: 'N/A',
    vaccList: 'N/A',
    pvd: 'N/A',
    menstruation: 'N/A',
    hormoneTherapy: 'N/A',
    ihd: 'N/A',
    autonomicNeuropath: 'N/A',
    urinary: 'N/A',
    dental: 'N/A',
    visits: 'N/A',
    ecgDate: 'N/A',
    normalAbnormal: 'N/A',
    infarct: 'N/A',
    angina: 'N/A',
    arrthy: 'N/A',
    neuroNeuropathy: 'N/A',
    neuroPain: 'N/A',
    nephropathy: 'N/A',
    retinopathyRight: 'N/A',
    retinopathyLeft: 'N/A',
    pulsesLeft: 'N/A',
    pulsesRight: 'N/A',
    sensationsLeft: 'N/A',
    sensationsRight: 'N/A',
    vibrationRight: 'N/A',
    vibrationLeft: 'N/A',
    callusRight: 'N/A',
    callusLeft: 'N/A',
    lungsStatus: 'N/A',
    skinStatus: 'N/A',
    skinNotes: 'N/A',
    oedema: 'N/A',
    injSightHealth: 'N/A',
    weight: 'N/A',
    bloodPressure: 'N/A',
    pulse: 'N/A',
    hgt: 'N/A',
    prot: 'N/A',
    leucocytes: 'N/A',
    ketones: 'N/A',
    microAlbumin: 'N/A',
    assessment: 'N/A',
    plan: 'N/A'
  }
}
