const ConfigEnums = {
  profileKeys: {
    CONFIG: 'config'
  },
  routeKeys: {
    READ: 'read',
    UPDATE: 'update'
  }
}

export default ConfigEnums
