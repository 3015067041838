import { Col, DatePicker, Form, Input, Radio, Row, Select, Switch, Table, Tabs, Tooltip, message, theme } from 'antd'
import React, { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faCalendarAlt, faChevronRight, faTrash, faWarning } from '@fortawesome/free-solid-svg-icons'
import { batch, useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getDateOfBirthFromID, handleError, hexToRGBA, specialFieldValidation } from '../../../lib/utils'
import { createAgiliteUser, findAgiliteUser } from '../../../Auth/utils/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import coreReducer from '../../../../core/utils/reducer'
import systemUsersReducer from '../utils/reducer'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import {
  MedPraxSchemePlanOptionSearch,
  MedPraxSchemePlanSearch,
  MedPraxSchemeSearch
} from '../../../Scripting/utils/utils'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import debounce from 'lodash/debounce'

const AddPatient = ({
  refreshView,
  type,
  isBooking,
  setBookingPatient,
  setAddingForBooking,
  isDependant,
  mainPatientInfo,
  resetFunction
}) => {
  const activeRecords = useSelector((state) => state.systemUsers.activeRecords)
  const authState = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [specialFormState, setSpecialFormState] = useState([])
  const [dependants, setDependants] = useState([
    { key: new Date().toISOString(), firstName: '', lastName: '', gender: '', dateOfBirth: '' }
  ])
  const [idType, setIdType] = useState('id')

  const dependantTemplate = () => {
    return { key: new Date().toISOString(), firstName: '', lastName: '', gender: '', dateOfBirth: '' }
  }

  const residentVisitor = null
  // const [residentVisitor, setResidentVisitor] = useState()
  //userData?.country === 'ZA' ? 'resident' : 'visitor'
  // Medical aid capturing
  const [schemeLoading, setSchemeLoading] = useState(false)
  const [schemeData, setSchemeData] = useState([])
  const [plansLoading, setPlansLoading] = useState(false)
  const [plansData, setPlansData] = useState([])
  const [planOptionsLoading, setPlanOptionsLoading] = useState(false)
  const [planOptionData, setPlanOptionData] = useState([])

  // Dependant Handling
  const [dependantSameAddress, setDependantSameAddress] = useState(isDependant)
  const [dependantSameContact, setDependantSameContact] = useState(isDependant)

  // eslint-disable-next-line

  const handleDepChange = (value, index, field) => {
    let tmpDependants = dependants.concat()
    tmpDependants[index][field] = value
    setDependants(tmpDependants)
  }

  const handleSubmit = async () => {
    let patient = null
    let agiliteUser = null
    let tmpPhone = null
    let tmpDependants = dependants.concat()
    let qry = {}
    let error = null

    setLoading(true)

    // New Patient
    try {
      patient = patientForm.getFieldsValue(true)

      if (!patient.phoneNumber && !dependantSameContact) {
        error = 'Please provide a Cellphone Number'
      }
      if (patient.email) {
        qry['email'] = patient.email
      }

      if (patient.phoneNumber) {
        tmpPhone = formatNumber(patient.phoneNumber)
        qry['phoneNumber'] = tmpPhone
      }

      if (patient.idNo) {
        qry['idNo'] = patient.idNo
      }

      // if (idType === 'id') {
      //   patient.dateOfBirth = parseIdToDateString(patient.idNo)
      // }

      // Format Dependants
      tmpDependants.forEach((dep, index) => {
        if (!dep.firstName && !dep.lastName && !dep.dateOfBirth && !dep.gender) {
          tmpDependants.splice(index, 1)
        } else {
          if (!dep.firstName || !dep.lastName || !dep.dateOfBirth || !dep.gender) {
            error = 'Please make sure all fields for dependants are filled in'
          }
        }
      })

      if (error) {
        setLoading(false)
        return message.error(error)
      }

      agiliteUser = await findAgiliteUser(qry)

      if (agiliteUser) {
        setLoading(false)
        return message.error('User already exists. Please search for the user instead.')
      } else {
        if (patient.phoneNumber) {
          patient.phoneNumber = formatNumber(patient.phoneNumber)
        }

        agiliteUser = await createAgiliteUser(
          {
            ...patient,
            extraData: {
              role: {
                type: 'patient'
              }
            },
            mainMemberId: mainPatientInfo?._id
          },
          false,
          authState.agiliteUser._id
        )

        for (const dep of tmpDependants) {
          await createAgiliteUser({
            ...dep,
            extraData: {
              role: {
                type: 'patient'
              }
            },
            mainMemberId: agiliteUser._id
          })
        }
      }

      message.success(isDependant ? 'Dependant created successfully' : 'Patient created successfully')

      if (isDependant) {
        refreshView()
        resetFunction()
      } else {
        handleReset()
        if (isBooking) {
          setAddingForBooking(false)
          setBookingPatient(agiliteUser)
        } else {
          closeTab()
        }
      }
    } catch (e) {}

    setLoading(false)
  }

  const handleReset = () => {
    setDependants([dependantTemplate()])
    setDependants([dependantTemplate()])
    patientForm.resetFields()
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `+27${phoneNumber.slice(1, 10)}`
    } else {
      phoneNumber = `+27${phoneNumber}`
    }

    return phoneNumber
  }

  const handleAddDep = () => {
    const tmpDependants = dependants.concat()
    tmpDependants.push(dependantTemplate())
    setDependants(tmpDependants)
  }

  const handleRemoveDep = (index) => {
    let tmpDependants = dependants.concat()

    if (index === 0) {
      tmpDependants[index].firstName = ''
      tmpDependants[index].lastName = ''
      tmpDependants[index].gender = ''
      tmpDependants[index].dateOfBirth = ''
    } else {
      tmpDependants = tmpDependants.filter((dep, i) => i !== index)
    }

    setDependants(tmpDependants)
  }

  const [patientForm] = Form.useForm()
  const { token } = theme.useToken()

  const closeTab = () => {
    if (isBooking) {
      setAddingForBooking(false)
    } else {
      let tmpActiveRecords = activeRecords.concat()
      tmpActiveRecords = tmpActiveRecords.filter((i) => !i.isNew)
      refreshView()
      batch(() => {
        dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
        dispatch(
          coreReducer.actions.closeTab({
            targetKey: `patients_new_${type}_user`,
            removeBreadcrumb: true
          })
        )
      })
    }
  }

  const handleGetMedicalAids = async (query) => {
    let tmpData = []

    setSchemeLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemeSearch(query)
        setSchemeData(tmpData)
      } else {
        setSchemeData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSchemeLoading(false)
  }

  const handleGetMedicalAidPlans = async (query) => {
    let tmpData = []

    setPlansLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanSearch(query)
        setPlansData(tmpData)
      } else {
        setPlansData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlansLoading(false)
  }

  const handleGetMedicalAidPlanOptions = async (query) => {
    let tmpData = []

    setPlanOptionsLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanOptionSearch(query)
        setPlanOptionData(tmpData)
      } else {
        setPlanOptionData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlanOptionsLoading(false)
  }

  // eslint-disable-next-line
  const debouncedSchemeFilter = useCallback(
    debounce((query) => {
      if (query) {
        handleGetMedicalAids(query)
      } else {
        setSchemeLoading(false)
      }
    }, 1000),
    []
  )

  return (
    <ContainerCard
      headStyle={isDependant ? { display: 'none' } : {}}
      bodyStyle={isDependant ? { padding: 0 } : {}}
      title='New Patient Record'
      style={{ paddingBottom: 12 }}
    >
      <CustomRow justify='center'>
        <>
          <Col span={24}>
            <Tabs type='card'>
              <Tabs.TabPane tab={isDependant ? 'New Dependant Profile' : 'Main Patient'} key='1'>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form
                      initialValues={{
                        firstName: '',
                        lastName: '',
                        idNo: '',
                        email: '',
                        phoneNumber: '',
                        residentialAddress: '',
                        medicalAid: mainPatientInfo
                          ? {
                              ...mainPatientInfo?.medicalAid,
                              dependantNumber: null
                            }
                          : {}
                      }}
                      layout='vertical'
                      form={patientForm}
                      onFinish={handleSubmit}
                      onFinishFailed={(e) => {
                        message.error('Patient information missing.')
                      }}
                      onErrorCapture={(e) => {
                        console.log(e)
                      }}
                    >
                      <CustomRow gutter={[8, 8]}>
                        <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                          <CustomRow className='basic-card' gutter={[8, 8]}>
                            <Col span={24}>
                              <h1 style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
                                Patient Details
                              </h1>
                            </Col>
                            <Col span={24}>
                              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                                <Form.Item
                                  style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                                  name='firstName'
                                  label='First Name'
                                  rules={[{ required: true, message: 'This field is required' }]}
                                >
                                  <Input
                                    placeholder='e.g John'
                                    onChange={(e) => {
                                      specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                        key: 'firstName',
                                        event: e,
                                        validationConfig: {
                                          letters: { allowed: true, onlyCaps: false },
                                          numbers: false,
                                          spaces: true
                                        }
                                      })
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                                  name='lastName'
                                  label='Last Name'
                                >
                                  <Input
                                    placeholder='e.g Doe'
                                    onChange={(e) => {
                                      specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                        key: 'lastName',
                                        event: e,
                                        validationConfig: {
                                          letters: { allowed: true, onlyCaps: false },
                                          numbers: false,
                                          spaces: true
                                        }
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col span={24}>
                              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                                <Form.Item label='ID Type' style={{ marginTop: 0, marginBottom: 0 }}>
                                  <Radio.Group
                                    onChange={(e) => {
                                      patientForm.setFieldValue('idNo', null)
                                      setIdType(e.target.value)
                                    }}
                                    value={idType}
                                  >
                                    <Radio.Button value={'id'}>ID</Radio.Button>
                                    <Radio.Button value={'pass'}>Passport</Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                                {idType === 'id' ? (
                                  <Form.Item
                                    label='ID Number'
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                    name='idNo'
                                    rules={[
                                      { required: true, message: 'This field is required' },
                                      { min: 13, message: 'Invalid South African ID' }
                                    ]}
                                  >
                                    <Input
                                      placeholder='e.g. 790725*******'
                                      maxLength={13}
                                      onChange={(e) => {
                                        let idValue = specialFieldValidation(
                                          patientForm,
                                          specialFormState,
                                          setSpecialFormState,
                                          {
                                            key: 'idNo',
                                            event: e,
                                            validationConfig: {
                                              letters: { allowed: false, onlyCaps: false },
                                              numbers: true,
                                              spaces: false
                                            }
                                          }
                                        )

                                        let dateOfBirth = getDateOfBirthFromID(idValue)
                                        if (dateOfBirth) {
                                          patientForm.setFieldValue('dateOfBirth', dayjs(dateOfBirth))
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      label='Passport Number'
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                      name='idNo'
                                      rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                      <Input
                                        placeholder='e.g. AZ330B*******'
                                        maxLength={35}
                                        onChange={(e) => {
                                          specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                            key: 'idNo',
                                            event: e,
                                            validationConfig: {
                                              letters: { allowed: true, onlyCaps: true },
                                              numbers: true,
                                              spaces: false
                                            }
                                          })
                                        }}
                                      />
                                    </Form.Item>
                                  </>
                                )}

                                <Form.Item
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  label='Date of Birth'
                                  name='dateOfBirth'
                                  rules={[{ required: true, message: 'This field is required' }]}
                                >
                                  <DatePicker
                                    style={{ width: 200 }}
                                    suffixIcon={<FontAwesomeIcon icon={faCalendarAlt} color={token.colorPrimary} />}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  label='Gender'
                                  name='gender'
                                  rules={[{ required: true, message: 'This field is required' }]}
                                >
                                  <Radio.Group
                                    onChange={(e) => {
                                      patientForm.setFieldValue('gender', e.target.value)
                                    }}
                                  >
                                    <Radio.Button value='female'>Female</Radio.Button>
                                    <Radio.Button value='male'>Male</Radio.Button>
                                    <Radio.Button value='other'>Other</Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </Col>
                            <Col span={24}>
                              <h1 style={{ background: hexToRGBA(token.colorPrimary, 0.2), marginTop: 12, padding: 8 }}>
                                Contact Details
                              </h1>
                            </Col>
                            {isDependant ? (
                              <Col span={24}>
                                Same as Main Member ?{' '}
                                <Switch
                                  checked={dependantSameContact}
                                  checkedChildren='Yes'
                                  unCheckedChildren='No'
                                  onChange={(boolean) => {
                                    setDependantSameContact(boolean)
                                  }}
                                />
                              </Col>
                            ) : undefined}
                            {dependantSameContact ? undefined : (
                              <Col span={24}>
                                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                                  <Form.Item
                                    style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                                    name='email'
                                    label='Email Address'
                                    rules={[{ type: 'email', message: 'Invalid Email' }]}
                                  >
                                    <Input placeholder='e.g jane.doe@acme.com' maxLength={70} />
                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }}
                                    name='phoneNumber'
                                    label='Cellphone Number'
                                    rules={[{ required: !isDependant, message: 'This field is required' }]}
                                  >
                                    <Input
                                      addonBefore='+27'
                                      placeholder='e.g 8465*****'
                                      onChange={(e) => {
                                        specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                          key: 'phoneNumber',
                                          event: e,
                                          validationConfig: {
                                            letters: { allowed: false, onlyCaps: false },
                                            numbers: true
                                          }
                                        })
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            )}
                            <Col span={24}>
                              <h1 style={{ background: hexToRGBA(token.colorPrimary, 0.2), marginTop: 12, padding: 8 }}>
                                Address Details
                              </h1>
                            </Col>
                            {isDependant ? (
                              <Col span={24}>
                                Same as Main Member ?{' '}
                                <Switch
                                  checked={dependantSameAddress}
                                  checkedChildren='Yes'
                                  unCheckedChildren='No'
                                  onChange={(boolean) => {
                                    setDependantSameAddress(boolean)
                                  }}
                                />
                              </Col>
                            ) : undefined}
                            {dependantSameAddress ? undefined : (
                              <Col span={24}>
                                <Form.Item
                                  name='residentialAddress'
                                  label='Physical Address'
                                  rules={[{ message: 'Please provide a Physical Address' }]}
                                >
                                  <GoogleAddress
                                    selectProps={{
                                      placeholder: patientForm.getFieldValue('residentialAddress')
                                        ? patientForm.getFieldValue('residentialAddress')
                                        : 'e.g. 5 Doe Street, Gqeberha, South Africa',
                                      onChange: (e) => {
                                        patientForm.setFieldValue('residentialAddress', e.label)
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          </CustomRow>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                          <CustomRow className='basic-card' gutter={[8, 8]}>
                            <Col span={24}>
                              <h1 style={{ background: hexToRGBA(token.colorPrimary, 0.2), padding: 8 }}>
                                Medical Aid Details
                              </h1>
                            </Col>
                            <Col span={24}>
                              <Form.Item name={['medicalAid', 'schemeCode']} style={{ display: 'none' }}>
                                <Input />
                              </Form.Item>
                              <Form.Item name={['medicalAid', 'planCode']} style={{ display: 'none' }}>
                                <Input />
                              </Form.Item>
                              <Form.Item name={['medicalAid', 'planOptionCode']} style={{ display: 'none' }}>
                                <Input />
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Name'
                                    : 'Travel Insurance Provider'
                                }`}
                                name={['medicalAid', 'name']}
                              >
                                {!residentVisitor || residentVisitor === 'resident' ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    placeholder='Search for a Medical Aid...'
                                    disabled={false}
                                    onSearch={(value) => {
                                      debouncedSchemeFilter(value)
                                    }}
                                    onChange={(e, option) => {
                                      patientForm.setFieldValue(
                                        ['medicalAid', 'schemeCode'],
                                        option?.data ? JSON.parse(option.data).code : ''
                                      )
                                      setSchemeData([])
                                    }}
                                    options={schemeData.map((i) => {
                                      return {
                                        label: i.name,
                                        value: i.name,
                                        data: JSON.stringify(i)
                                      }
                                    })}
                                    notFoundContent={
                                      schemeLoading ? (
                                        <AgiliteSkeleton skActive spinnerTip='Searching Schemes...' />
                                      ) : undefined
                                    }
                                  />
                                ) : (
                                  <Input placeholder='e.g. Discovery Health Scheme' />
                                )}
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Plan'
                                    : 'Travel Insurance Plan'
                                }`}
                                name={['medicalAid', 'plan']}
                              >
                                {!residentVisitor || residentVisitor === 'resident' ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    placeholder='Select a Medical Aid Plan...'
                                    disabled={patientForm.getFieldValue(['medicalAid', 'schemeCode']) ? false : true}
                                    onChange={(e, option) => {
                                      patientForm.setFieldValue(
                                        ['medicalAid', 'planCode'],
                                        option?.data ? JSON.parse(option.data).code : ''
                                      )
                                      setPlansData([])
                                    }}
                                    onClick={() => {
                                      handleGetMedicalAidPlans(patientForm.getFieldValue(['medicalAid', 'schemeCode']))
                                    }}
                                    options={plansData.map((i) => {
                                      return {
                                        label: i.option,
                                        value: i.option,
                                        data: JSON.stringify(i)
                                      }
                                    })}
                                    notFoundContent={
                                      plansLoading ? (
                                        <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plans...' />
                                      ) : undefined
                                    }
                                  />
                                ) : (
                                  <Input placeholder='e.g. Coastal Saver' />
                                )}
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Plan Option'
                                    : 'Travel Insurance Plan Option'
                                }`}
                                name={['medicalAid', 'planOption']}
                              >
                                {!residentVisitor || residentVisitor === 'resident' ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    placeholder='Select a Medical Aid Plan Option...'
                                    disabled={patientForm.getFieldValue(['medicalAid', 'planCode']) ? false : true}
                                    onChange={(e, option) => {
                                      patientForm.setFieldValue(
                                        ['medicalAid', 'planOptionCode'],
                                        option?.data ? JSON.parse(option.data).code : ''
                                      )
                                      setPlanOptionData([])
                                    }}
                                    onClick={() => {
                                      handleGetMedicalAidPlanOptions(
                                        patientForm.getFieldValue(['medicalAid', 'planCode'])
                                      )
                                    }}
                                    options={planOptionData.map((i) => {
                                      return {
                                        label: i.option,
                                        value: i.option,
                                        data: JSON.stringify(i)
                                      }
                                    })}
                                    notFoundContent={
                                      planOptionsLoading ? (
                                        <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plan Options...' />
                                      ) : undefined
                                    }
                                  />
                                ) : (
                                  <Input placeholder='e.g. Coastal Saver' />
                                )}
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Number'
                                    : 'Travel Insurance Number'
                                }`}
                                name={['medicalAid', 'number']}
                              >
                                <Input placeholder='e.g. 335***' />
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label='Dependant Number'
                                name={['medicalAid', 'dependantNumber']}
                              >
                                <Input placeholder='e.g. 01' />
                              </Form.Item>
                            </Col>
                          </CustomRow>
                        </Col>
                      </CustomRow>
                    </Form>
                  </Col>
                </Row>
              </Tabs.TabPane>
              {isDependant ? undefined : (
                <Tabs.TabPane tab='Dependants' key='2'>
                  <Row justify='center'>
                    <Col span={24}>
                      <center>
                        <FontAwesomeIcon style={{ color: token.colorWarning }} icon={faWarning} /> Please make sure to
                        add all dependants <FontAwesomeIcon style={{ color: token.colorWarning }} icon={faWarning} />
                      </center>
                      <Table
                        size='small'
                        bordered
                        dataSource={dependants}
                        rowClassName='table-row-no-cursor'
                        style={{ marginBottom: 20 }}
                        rowKey={(record) => record.key}
                        columns={[
                          {
                            title: 'First Name',
                            key: 'firstName',
                            dataIndex: 'firstName',
                            render: (text, record, index) => {
                              return (
                                <Input
                                  value={record.firstName}
                                  onChange={(e) => handleDepChange(e.target.value, index, 'firstName')}
                                  placeholder='e.g Jane'
                                  allowClear
                                />
                              )
                            }
                          },
                          {
                            title: 'Last Name',
                            key: 'lastName',
                            dataIndex: 'lastName',
                            render: (text, record, index) => {
                              return (
                                <Input
                                  value={record.lastName}
                                  onChange={(e) => handleDepChange(e.target.value, index, 'lastName')}
                                  placeholder='e.g Jane'
                                  allowClear
                                />
                              )
                            }
                          },
                          {
                            title: 'Gender',
                            key: 'gender',
                            dataIndex: 'gender',
                            render: (text, record, index) => {
                              return (
                                <center>
                                  <Radio.Group
                                    value={record.gender}
                                    onChange={(e) => handleDepChange(e.target.value, index, 'gender')}
                                  >
                                    <Radio value='female'>Female</Radio>
                                    <Radio value='male'>Male</Radio>
                                    <Radio value='other'>Other</Radio>
                                  </Radio.Group>
                                </center>
                              )
                            }
                          },
                          {
                            title: 'Date Of Birth',
                            key: 'dateOfBirth',
                            dataIndex: 'dateOfBirth',
                            render: (text, record, index) => {
                              return (
                                <DatePicker
                                  value={record.dateOfBirth ? dayjs(record.dateOfBirth) : ''}
                                  onChange={(value) =>
                                    handleDepChange(dayjs(value).format('YYYY-MM-DD'), index, 'dateOfBirth')
                                  }
                                  allowClear={false}
                                />
                              )
                            }
                          },
                          {
                            title: 'Actions',
                            key: 'actions',
                            render: (value, record, index) => {
                              return (
                                <Row justify='space-around'>
                                  <Col>
                                    <Tooltip title='Delete Dependant'>
                                      <FontAwesomeIcon
                                        style={{
                                          color: token.colorError,
                                          cursor: 'pointer',
                                          fontSize: 20
                                        }}
                                        icon={faTrash}
                                        onClick={() => {
                                          handleRemoveDep(index)
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col>
                                    <Tooltip title='Add Family Account'>
                                      <FontAwesomeIcon
                                        style={{ color: token.colorSuccess, cursor: 'pointer', fontSize: 20 }}
                                        icon={faAdd}
                                        onClick={() => handleAddDep()}
                                      />
                                    </Tooltip>
                                  </Col>
                                </Row>
                              )
                            }
                          }
                        ]}
                        pagination={false}
                      />
                    </Col>
                  </Row>
                </Tabs.TabPane>
              )}
            </Tabs>
          </Col>
          <Col span={24}>
            <CustomRow justify='end' gutter={[24, 24]}>
              <Col>
                <CustomButton
                  disabled={loading}
                  onClick={() => {
                    if (isDependant) {
                      resetFunction()
                    } else {
                      closeTab()
                    }
                  }}
                  text='CANCEL'
                  type='accent'
                />
              </Col>
              <Col>
                <CustomButton
                  loading={loading}
                  type='secondary'
                  onClick={() => {
                    patientForm.submit()
                  }}
                  iconPosition='end'
                  icon={faChevronRight}
                  text='CONFIRM ACCOUNT DETAILS'
                />
              </Col>
            </CustomRow>
          </Col>
        </>
      </CustomRow>
    </ContainerCard>
  )
}

export default AddPatient
