import React from 'react'
import dayjs from 'dayjs'
import { Table, Tag } from 'antd'
import { handleConvertTime, handleConvertTotalTime } from '../../utils/lib'

const PatientJourneyList = ({ workflowHistory }) => {
  return (
    <Table
      size='small'
      dataSource={workflowHistory?.history || []}
      columns={[
        {
          title: 'Date / Time',
          dataIndex: 'submissionDate',
          key: 'submissionDate',
          render: (value) => {
            return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
          },
          width: '20%'
        },
        {
          title: 'Responsible User',
          dataIndex: 'responsibleUsers',
          key: 'responsibleUsers',
          render: (responsibleUsers) => responsibleUsers.join(', ')
        },
        { title: 'Action Performed', dataIndex: 'optionSelected', key: 'submissionDate' },
        {
          title: 'Action Duration',
          key: 'submissionDate',
          render: (value, record, index) => {
            if (
              index > 1 &&
              [
                'Patient Checked In',
                'Screening Completed',
                'Diagnosis Completed and Procedure Pending',
                'Procedure Completed',
                'Diagnosis Completed',
                'Billing & Booking Completed'
              ].includes(record.optionSelected)
            ) {
              return <>{handleConvertTime(record.submissionDate, index, workflowHistory)}</>
            } else {
              return <Tag color='orange'>Not Applicable</Tag>
            }
          },
          width: '20%'
        }
      ]}
      summary={() => {
        let durationTotal = 0

        if (workflowHistory?.history) {
          workflowHistory.history.forEach((item, index) => {
            if (
              index > 1 &&
              [
                'Patient Checked In',
                'Screening Completed',
                'Diagnosis Completed and Procedure Pending',
                'Procedure Completed',
                'Diagnosis Completed',
                'Billing & Booking Completed'
              ].includes(item.optionSelected)
            ) {
              durationTotal += Math.ceil(
                dayjs(item.submissionDate).diff(workflowHistory.history[index - 1].submissionDate, 'minutes', true)
              )
            }
          })
        }

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell>
                <b>Booking Duration: </b>
              </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <b>{handleConvertTotalTime(durationTotal)}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    />
  )
}

export default PatientJourneyList
