import React, { useEffect, useState } from 'react'
import { Controls } from './controls'
import { Videos } from './videos'
import { Col, Row } from 'antd'
import { handleError } from '../../../lib/utils'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'

export const VirtualVisitPlayer = ({
  channelName,
  setInCall,
  useMicrophoneAndCameraTracks,
  useClient,
  appId,
  token,
  uid,
  patient,
  handleVideoMinimized,
  setLeftCall
}) => {
  const [users, setUsers] = useState([])
  const client = useClient()
  const { ready, tracks } = useMicrophoneAndCameraTracks()

  useEffect(() => {
    // function to initialise the SDK
    client.on('user-published', handleUserPublished)
    client.on('user-unpublished', handleUserUnplublished)
    client.on('user-left', handleUserLeft)

    if (ready && tracks) {
      handleJoin()
    }

    // eslint-disable-next-line
  }, [channelName, ready, tracks])

  const handleJoin = async () => {
    try {
      await client.join(appId, channelName, token, uid)
      await client.publish(tracks)
    } catch (e) {
      handleError(e, true)
    }
  }

  const handleUserLeft = async (user) => {
    try {
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid)
      })

      await client.unsubscribe(user)
    } catch (e) {
      handleError(e, true)
    }
  }

  const handleUserPublished = async (user, mediaType) => {
    try {
      await client.subscribe(user, mediaType)

      if (mediaType === 'video') {
        setUsers((prevUsers) => {
          return [...prevUsers, user]
        })
      }

      if (mediaType === 'audio') {
        user.audioTrack?.play()
      }
    } catch (e) {
      handleError(e, true)
    }
  }

  const handleUserUnplublished = (user, type) => {
    if (type === 'audio') {
      user.audioTrack?.stop()
    }

    if (type === 'video') {
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid)
      })
    }
  }

  return (
    <Row style={{ height: '100%' }}>
      {ready && tracks ? (
        <Col span={24} style={{ height: '100%' }}>
          <Videos users={users} tracks={tracks} uid={uid} patient={patient} />
          <Controls
            setLeftCall={setLeftCall}
            tracks={tracks}
            setInCall={setInCall}
            useClient={useClient}
            handleVideoMinimized={handleVideoMinimized}
            patient={patient}
          />
        </Col>
      ) : (
        <Col span={24} style={{ height: '100%' }}>
          <AgiliteSkeleton spinnerTip='Virtual Consultation Starting...' />
        </Col>
      )}
    </Row>
  )
}
