import Agilite from 'agilite'
import { handleError } from '../../../lib/utils'
import ConfigEnums from './enums'
import store from '../../../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readConfig = () => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null
        let filter = {}

        if (store.getState().core.entity) {
          filter.entityRef = store.getState().core.entity._id
        }

        response = await agilite.Connectors.execute(ConfigEnums.profileKeys.CONFIG, ConfigEnums.routeKeys.READ, {
          filter: JSON.stringify(filter)
        })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const updateConfig = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(ConfigEnums.profileKeys.CONFIG, ConfigEnums.routeKeys.UPDATE, {
          filter: JSON.stringify({ _id: data._id, entityRef: store.getState().core.entity._id }),
          data: JSON.stringify({ ...data, entityRef: store.getState().core.entity._id })
        })

        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const uploadFile = (fileName, contentType, file, persistFile = false, isPublic) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Files.uploadFile(fileName, contentType, file, persistFile, isPublic)
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const deleteFile = (fileId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Files.deleteFile(fileId)
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const getFile = (fileId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Files.getFile(fileId)
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const processFile = (uploadType, fileId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.DataMappings.execute(uploadType, { fileId })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const readImports = (type, searchFilter, page, pageLimit, qry) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let filter = {}
        let response = null

        if (searchFilter) {
          filter = {
            $expr: {
              $regexMatch: {
                input: { $concat: ['$code', ' ', '$description'] },
                regex: searchFilter, // Your text search here
                options: 'i'
              }
            }
          }
        }

        if (qry) {
          filter = {
            ...filter,
            ...qry
          }
        }

        filter = JSON.stringify(filter)

        response = await agilite.Connectors.execute(type, 'find', {
          filter,
          page,
          pageLimit
        })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const writeImports = (type, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(type, 'bulk_write', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}
