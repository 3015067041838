import React, { useEffect, useState } from 'react'
import { Col, Button, Table, Empty, Space } from 'antd'
import { uniqBy } from 'lodash'

import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import Templates from '../utils/templates'

import _ from 'lodash'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import CoreEnums from '../../../../core/utils/enums'

const CustomDiagnosisList = ({ data, refreshView, createRecord, editRecord, loading, viewRecord, hidden }) => {
  const [filters, setFilters] = useState({
    province: [],
    name: [],
    address: []
  })

  useEffect(() => {
    generateFilters()
    // eslint-disable-next-line
  }, [data])

  const generateFilters = () => {
    const tmpFilters = {
      province: [],
      clinicType: []
    }

    const provinceFilters = Object.keys(CoreEnums.provinces).map((key) => {
      return CoreEnums.provinces[key]
    })

    provinceFilters.forEach((item) => {
      tmpFilters.province.push({
        text: item,
        value: item
      })
    })

    data.forEach((item) => {
      tmpFilters.clinicType.push({ text: item.clinicType, value: item.clinicType })
    })

    tmpFilters.name = uniqBy(tmpFilters.name, 'text')
    tmpFilters.clinicType = uniqBy(tmpFilters.clinicType, 'text')

    setFilters(tmpFilters)
  }

  return (
    <CustomRow className='basic-card'>
      {hidden ? (
        <Col span={24}>
          <Empty description='Custom Diagnosis Disabled' />
        </Col>
      ) : (
        <>
          {' '}
          <Col span={24}>
            <Space wrap>
              <CustomButton
                size='small'
                text='Refresh'
                icon={faRefresh}
                onClick={() => refreshView()}
                type='primary'
                disabled={loading}
              />
              <Button type='primary' onClick={createRecord} disabled={loading}>
                Add Diagnosis
              </Button>
            </Space>
          </Col>
          <Col span={24}>
            <Table
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={_.sortBy(data, 'name')}
              pagination={false}
              scroll={{ x: 950 }}
              columns={Templates.columnTemplate(editRecord, filters)}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => viewRecord(record)
                }
              }}
              locale={{ emptyText: <Empty description='There are currently no registered clinics to show.' /> }}
            />
          </Col>
        </>
      )}
    </CustomRow>
  )
}

export default CustomDiagnosisList
