import Agilite from 'agilite'
import CoreEnums from '../../../../core/utils/enums'
import InventoryEnums from './enums'
import store from '../../../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const createInventoryEntry = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          InventoryEnums.profileKeys.INVENTORY,
          InventoryEnums.routeKeys.CREATE,
          {
            data: JSON.stringify({ ...data, entityRef: store.getState().core.entity._id })
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readInventory = (filters, activityFilters) => {
  return new Promise((resolve, reject) => {
    let qry = { ...filters, entityRef: store.getState().core.entity._id }
    let activityQry = { ...activityFilters }
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          InventoryEnums.profileKeys.INVENTORY,
          InventoryEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )
        const itemIds = response.data.map((item) => item._id)

        activityQry.itemRef = { $in: itemIds }

        let activity = await readInventoryActivity(activityQry)
        for (const item of response.data) {
          item.activity = activity.filter((act) => act.itemRef === item._id)
        }
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const updateInventoryItem = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          InventoryEnums.profileKeys.INVENTORY,
          InventoryEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createInventoryActivity = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          InventoryEnums.profileKeys.INVENTORY_ACTIVITY,
          InventoryEnums.routeKeys.CREATE,
          {
            data: JSON.stringify({ ...data })
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readInventoryActivity = (filters) => {
  return new Promise((resolve, reject) => {
    let qry = { ...filters }
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          InventoryEnums.profileKeys.INVENTORY_ACTIVITY,
          InventoryEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
