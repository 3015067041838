import { Col, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleError } from '../../../lib/utils'
import { cancelEnrollement, fetchEnrollment } from '../../utils/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import EnrolledPatients from './EnrolledPatients'
import EnrollmentSearch from './EnrollmentSearch.js'
import { useSelector } from 'react-redux'
import TeamList from './TeamList.js'

const CarePlanEnrollment = ({ template, handleBack, setCurrentTemplate }) => {
  const authState = useSelector((state) => state.auth)
  const [enrolledUsers, setEnrolledUsers] = useState([])
  const [newEnrollment, setNewEnrollment] = useState(false)
  const [loadingPatients, setLoadingPatients] = useState(false)
  const [teamMemberModalOpen, setTeamMemberModalOpen] = useState(false)
  const [teamRecordReference, setTeamRecordReference] = useState([])
  const [currentPatient, setCurrentPatient] = useState(null)

  useEffect(() => {
    handleFetchEnrolledUsers()
    // eslint-disable-next-line
  }, [])

  const handleFetchEnrolledUsers = async () => {
    let tmpUsers = []
    setLoadingPatients(true)
    try {
      tmpUsers = await fetchEnrollment({
        templateRef: template._id,
        $or: [
          { teamMembers: authState.agiliteUser._id }, // Direct match if teamMembers contains ObjectIds
          { 'audit.createdBy': authState.agiliteUser._id },
          { doctorRef: authState.agiliteUser._id }
        ]
      })
      setEnrolledUsers(tmpUsers)
    } catch (error) {
      message.error(handleError(error))
    }
    setLoadingPatients(false)
  }

  const handleNewEnrollment = (record) => {
    let tmpEnrolledUsers = [...enrolledUsers, record]
    setEnrolledUsers(tmpEnrolledUsers)
  }

  const handleCancelEnrollment = async (record) => {
    let tmpEnrollment = [...enrolledUsers]
    let entryIndex = tmpEnrollment.findIndex((i) => i._id === record._id)
    try {
      await cancelEnrollement(record._id)
      tmpEnrollment.splice(entryIndex, 1)
      setEnrolledUsers(tmpEnrollment)
    } catch (error) {
      message.error(handleError(error))
    }
  }

  const handleNewTeamMember = (record, newTeamMeber) => {
    let tmpEnrolledUsers = [...enrolledUsers]
    let targetIndex = tmpEnrolledUsers.findIndex((i) => i._id === record._id)
    tmpEnrolledUsers[targetIndex].teamMembers = [...record.teamMembers, newTeamMeber]
    setEnrolledUsers(enrolledUsers)
  }

  return (
    <CustomRow>
      <Col span={24}>
        <EnrolledPatients
          handleCancelEnrollment={handleCancelEnrollment}
          currentPatient={currentPatient}
          setCurrentPatient={setCurrentPatient}
          loadingPatients={loadingPatients}
          handleFetchEnrolledUsers={handleFetchEnrolledUsers}
          setCurrentTemplate={setCurrentTemplate}
          setNewEnrollment={setNewEnrollment}
          setTeamMemberModalOpen={setTeamMemberModalOpen}
          setTeamRecordReference={setTeamRecordReference}
          enrolledUsers={enrolledUsers}
          template={template}
        />
      </Col>
      <Modal width={1920} destroyOnClose open={newEnrollment} footer={false} maskClosable={false} closable={false}>
        <EnrollmentSearch setModalOpen={setNewEnrollment} template={template} onAfterSuccess={handleNewEnrollment} />
      </Modal>

      <Modal
        width={1920}
        destroyOnClose
        open={teamMemberModalOpen}
        footer={false}
        maskClosable={false}
        closable={false}
      >
        <TeamList
          setTeamRecordReference={setTeamRecordReference}
          teamRecordReference={teamRecordReference}
          setModalOpen={setTeamMemberModalOpen}
          template={template}
          onAfterSuccess={handleNewTeamMember}
        />
      </Modal>
    </CustomRow>
  )
}

export default CarePlanEnrollment
