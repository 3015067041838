import { Col, Button, Table, Empty, Input, Space, Modal, message, Card, Radio, Switch, theme } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPrescriptionBottle, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'

import { ScriptHistoryTableTemplate } from '../utils/templates'
import CustomRow from '../../reusable-components/CustomRow'
import { useCallback, useEffect, useState } from 'react'
import { readPatients } from '../../Admin/patients/utils/utils'
import { handleError } from '../../lib/utils'
import { removeScript } from '../utils/utils'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import CustomLoadingIcon from '../../reusable-components/CustomLoadingIcon'

const ScriptHistoryList = ({
  data,
  patientId,
  refreshView,
  createNewScript,
  viewScript,
  loading,
  setLoading,
  handleSearch,
  patientSearchQuery,
  handleGenerateScriptTemplate,
  printingScript,
  total,
  setPageSize,
  setPage,
  page,
  pageSize,
  type,
  setType,
  onlyDue,
  setOnlyDue,
  totalDue
}) => {
  const [modalOpen, setModalOpen] = useState()
  const [searchResults, setSearchResults] = useState([])
  const [searching, setSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    handlePatientSearch()
    // eslint-disable-next-line
  }, [searchQuery])

  const handlePatientSearch = async () => {
    try {
      let tmpUsers = []
      let qry = { 'extraData.role.type': 'patient' }
      setSearching(true)
      try {
        if (searchQuery) {
          qry = {
            ...qry,
            $or: [
              {
                $expr: {
                  $regexMatch: {
                    input: { $concat: ['$firstName', ' ', '$lastName'] },
                    regex: searchQuery, //Your text search here
                    options: 'i'
                  }
                }
              },
              { phoneNumber: { $regex: searchQuery, $options: 'i' } },
              { email: { $regex: searchQuery, $options: 'i' } },
              { physicalAddress: { $regex: searchQuery, $options: 'i' } },
              { idNo: { $regex: searchQuery, $options: 'i' } }
            ]
          }
          tmpUsers = await readPatients(qry)
        }
        setSearchResults(tmpUsers)
      } catch (e) {
        message.error(handleError(e))
      }
      setSearching(false)
    } catch (e) {}
  }

  const handlePatientQuery = (query) => {
    setSearching(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )

  const handleRemoveScript = async (recordId) => {
    setLoading(true)
    try {
      await removeScript(recordId)
      message.success('Removed Script')
      refreshView()
    } catch (e) {
      message.error(handleError(e))
      setLoading(false)
    }
  }

  const filterByDue = () => {
    const todaysDate = dayjs().format('YYYY-MM-DD')
    let tmpEntries = [...data]
    tmpEntries = tmpEntries.filter((i, index) => {
      const nextDueDate = dayjs(i.nextDue).format('YYYY-MM-DD')
      // If next due date is today then show it
      if (nextDueDate === todaysDate) {
        return true
      }
      // if the next due date is overdue (next due is behind today) show it
      // due 24 january
      // today is the 28 january
      // due date is behind todays date meaning next due was missed so still show it and maybe show days overdue
      if (todaysDate > nextDueDate) {
        // Calculate the difference in days
        const daysDifference = dayjs(todaysDate).diff(nextDueDate, 'day')
        tmpEntries[index].daysOverDue = daysDifference
        return true
      }
      return false
    })
    return tmpEntries
  }

  const { token } = theme.useToken()
  return (
    <>
      <CustomRow justify='center'>
        <Col span={24}>
          <Card
            headStyle={{ borderRadius: 0 }}
            style={{ borderRadius: 0 }}
            extra={
              <Space>
                <Radio.Group
                  buttonStyle='solid'
                  disabled={loading}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <Radio.Button value=''>
                    <FontAwesomeIcon icon={faPrescriptionBottle} />
                  </Radio.Button>
                  <Radio.Button value='schedule'>
                    <FontAwesomeIcon icon={faClock} /> {totalDue ? <>({totalDue})</> : undefined}
                  </Radio.Button>
                </Radio.Group>
              </Space>
            }
            bodyStyle={{ padding: 0 }}
            title={
              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                <Space wrap style={{ width: '100%' }}>
                  <Button
                    type='primary'
                    onClick={() => {
                      if (patientId) {
                        createNewScript(patientId)
                      } else {
                        setModalOpen(true)
                      }
                    }}
                    disabled={loading}
                  >
                    New Script
                  </Button>
                  {patientId ? undefined : (
                    <Input
                      defaultValue={patientSearchQuery}
                      placeholder='Search for patient name or ID'
                      suffix={<FontAwesomeIcon icon={faSearch} />}
                      allowClear
                      onChange={(e) => {
                        handleSearch(e.target.value)
                      }}
                      style={{ width: 500, maxWidth: '100%' }}
                    />
                  )}
                  <Button
                    onClick={() => refreshView()}
                    type='primary'
                    style={{ background: token.colorSuccess }}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </Space>
              </div>
            }
          >
            <Card
              extra={
                type === 'schedule' ? (
                  <Space>
                    <p>Only Show due:</p>
                    <Switch checked={onlyDue} onChange={(boolean) => setOnlyDue(boolean)} />
                  </Space>
                ) : undefined
              }
              type='inner'
              size='small'
              bodyStyle={{ padding: 0 }}
              headStyle={{ borderRadius: 0 }}
              style={{ borderRadius: 0 }}
            >
              <CustomRow gutter={[0, 0]}>
                <Col span={24}>
                  <Table
                    className={loading ? 'custom-loading-mask' : ''}
                    loading={{
                      spinning: loading,
                      indicator: <CustomLoadingIcon loadingText='Loading...' />
                    }}
                    size='small'
                    bordered
                    pagination={{
                      current: page,
                      pageSize: pageSize,
                      total: total,
                      onChange: (p, ps) => {
                        setPage(p)
                        setPageSize(ps)
                      }
                    }}
                    rowKey={(record) => record._id}
                    dataSource={onlyDue && type === 'schedule' ? filterByDue() : data}
                    scroll={{ x: 950 }}
                    columns={ScriptHistoryTableTemplate(
                      patientId ? true : false,
                      handleGenerateScriptTemplate,
                      printingScript,
                      handleRemoveScript,
                      type === 'schedule',
                      viewScript
                    ).filter((i) => !i.hidden)}
                    // onRow={(record, rowIndex) => {
                    //   return {
                    //     onClick: () => viewScript(record)
                    //   }
                    // }}
                    expandable={{
                      expandedRowRender: (record) => (
                        <Table
                          size='small'
                          dataSource={record.items}
                          columns={[
                            { title: 'Item', dataIndex: 'name', key: 'name' },
                            { title: 'Scripting Dosage', dataIndex: 'scriptingDosage', key: 'scriptingDosage' }
                          ]}
                          pagination={false}
                          bordered={true}
                        />
                      ),
                      rowExpandable: (record) => record.items.length > 0
                    }}
                    locale={{ emptyText: <Empty description='You have no scripting history' /> }}
                  />
                </Col>
              </CustomRow>
            </Card>
          </Card>
        </Col>
      </CustomRow>
      <Modal
        width={1000}
        open={modalOpen}
        destroyOnClose
        okButtonProps={{ style: { display: 'none' } }}
        title={'Search for patients'}
        onCancel={() => {
          setModalOpen(false)
        }}
        onOk={() => {}}
      >
        <Input
          placeholder='Search by name or ID'
          style={{ marginBottom: 12 }}
          onChange={(e) => {
            handlePatientQuery(e.target.value)
          }}
        />
        <Table
          loading={{
            spinning: searching,
            indicator: <CustomLoadingIcon loadingText='' />
          }}
          size='small'
          bordered
          className={searching ? 'custom-loading-mask' : ''}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                setModalOpen(false)
                setSearchQuery('')
                createNewScript(record)
              }
            }
          }}
          dataSource={searchResults}
          columns={[
            { title: 'Patient', render: (record) => `${record.firstName} ${record.lastName}` },
            { title: 'ID', render: (record) => record.idNo },
            { title: 'Contact', render: (record) => (record.phoneNumber ? record.phoneNumber : 'N/A') }
          ]}
        />
      </Modal>
    </>
  )
}

export default ScriptHistoryList
