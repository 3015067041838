import React, { useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, message, Row, Space, Switch, theme } from 'antd'
import { generateAIResponse } from '../../AI Tools/assistants-lib'
import { AssistantEnums } from '../../AI Tools/assitants-enums'
import { assistantInitiate } from '../../AI Tools/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import MacroRings from './MacroRings'
import { handleError, hexToRGBA } from '../../lib/utils'
import { useSelector } from 'react-redux'
import { createMacroTrackers, removeSavedMacroTrackerMeal, saveMacroTrackerMeal } from '../utils/macro-tracker-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckCircle, faStar, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import CameraCapture from './CameraCapture'
import HealthAlternativeItem from './HealthAlternativeItem'
import SavedMeals from './SavedMeals'
import { uploadFileTemp } from '../../Admin/config/utils/temp-utils'

const NewImageUpload = ({ setModalOpen, savedMeals, setSavedMeals = [], limits, handleRefresh }) => {
  const authState = useSelector((state) => state.auth)

  const [contactingAi, stContactingAi] = useState(false)
  const [facet, setFacet] = useState('')
  const [macroData, setMacroData] = useState(null)
  const [saveToFavourites, setSaveToFavourites] = useState(false)
  const [saving, setSaving] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [servings, setServings] = useState(1)
  const [uploadedImage, setUploadedImage] = useState(null)
  const [removing, setRemoving] = useState(false)
  const [aiError, setAiError] = useState(null)
  const [HAMSaved, setHAMSaved] = useState(false)

  const contactMacroAssistantAi = async (base64, file) => {
    stContactingAi(true) // Show loading spinner while contacting AI
    setAiError(null)
    try {
      const contentType = file.type // Get the original content type
      const uploadResponse = await uploadFileTemp('Test', contentType, file, false, true)
      const fileId = uploadResponse?._id
      const fileUrl = `https://nodered-metahealth-dev.agilite.io/api/images/${fileId}.jpeg`
      const aiInitiateResponse = await assistantInitiate(
        [
          {
            type: 'image_url',
            image_url: {
              url: fileUrl
            }
          }
        ],
        AssistantEnums.assistant_ids.MACRO_TRACKER
      )
      const aiContentResponse = await generateAIResponse(aiInitiateResponse)
      const tmpMacroData = JSON.parse(aiContentResponse?.data?.data[0]?.content[0]?.text?.value)
      if (!tmpMacroData.macros) {
        setAiError(tmpMacroData.error)
      } else {
        setUploadedImage(base64)
        setMacroData(tmpMacroData)
      }
    } catch (error) {
      message.error('Failed to analyze image. Please try again.') // Error handling
    }
    stContactingAi(false)
  }

  const handleCreateNewMacroEntry = async () => {
    // const tmpTrackerData = JSON.parse(JSON.stringify(trackerData))
    // let response = null
    let newMealFavourite = null

    // No need to multiply by servings here, we will handle that in MacroRings

    const payload = JSON.parse(JSON.stringify(macroData))

    for (let key in payload.macros) {
      if (payload.macros.hasOwnProperty(key)) {
        payload.macros[key] = payload.macros[key] * servings
      }
    }

    if (payload._id) {
      delete payload._id
      delete payload.createdAt
      delete payload.createdBy
      delete payload.userRef
    }

    // Setting createdBy and userRef
    payload.createdBy = authState.agiliteUser._id
    payload.userRef = authState.agiliteUser._id
    payload.servings = servings
    payload.image = uploadedImage

    setSaving(true)
    try {
      await createMacroTrackers(payload)
      if (saveToFavourites) {
        newMealFavourite = await saveMacroTrackerMeal({ ...payload, ...macroData, servings: 1 })
        setSavedMeals([...savedMeals, newMealFavourite])
      }

      // tmpTrackerData.push(response)
      // setTrackerData(tmpTrackerData)
      handleRefresh()
      handleReset(true)
    } catch (error) {
      message.error(handleError(error))
    }
    setSaving(false)
  }

  const handleReset = (close) => {
    setHAMSaved(false)
    setAiError(null)
    setUploadedImage(null)
    setMacroData(null)
    setServings(1) // Reset servings to 1
    setFacet('')
    setSearchQuery('')
    setSaveToFavourites(false)
    if (close) {
      setModalOpen(false)
    }
  }

  const handleRemoveSavedMeal = async (recordId) => {
    let tmpSavedMeals = JSON.parse(JSON.stringify(savedMeals))
    const mealIndex = tmpSavedMeals.findIndex((i) => i._id === recordId)
    setRemoving(true)
    try {
      await removeSavedMacroTrackerMeal(recordId)
      tmpSavedMeals.splice(mealIndex, 1)
      setSavedMeals(tmpSavedMeals)
    } catch (error) {
      message.error(handleError(error))
    }
    setRemoving(false)
  }

  const { token } = theme.useToken()
  return (
    <CustomRow>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'space-between',
          top: 0,
          left: 0,
          right: 0,
          background: hexToRGBA(token.colorPrimary, 0.2),
          padding: 8
        }}
      >
        <h3 style={{ paddingTop: '5px', paddingLeft: '13px', textAlign: 'center' }}>NEW MEAL ENTRY</h3>{' '}
        <Button
          onClick={() => {
            handleReset(true)
          }}
          disabled={saving}
          style={{ border: 'none', background: 'none' }}
        >
          <FontAwesomeIcon style={{ fontSize: 24 }} color={token.colorError} icon={faXmarkCircle} />
        </Button>
      </div>
      <Col span={24} style={{ marginTop: 48 }}>
        {contactingAi ? (
          <AgiliteSkeleton skActive spinnerTip='Contacting Macro Tracker AI...' />
        ) : (
          <>
            {facet === 'alt' ? (
              <SavedMeals
                handleRemoveSavedMeal={handleRemoveSavedMeal}
                removing={removing}
                savedMeals={savedMeals}
                saving={saving}
                searchQuery={searchQuery}
                setAiError={setAiError}
                setFacet={setFacet}
                setMacroData={setMacroData}
                setSearchQuery={setSearchQuery}
              />
            ) : (
              <>
                {macroData ? (
                  <>
                    {facet === 'ha' ? (
                      <HealthAlternativeItem
                        isNew
                        handleBack={(isSaved) => {
                          if (isSaved) {
                            setHAMSaved(true)
                          }
                          setFacet(null)
                        }}
                        healthyAlternative={macroData.healthyAlternative}
                      />
                    ) : (
                      <>
                        {macroData.healthyAlternative ? (
                          <>
                            {HAMSaved ? (
                              <Card style={{ background: hexToRGBA(token.colorSuccess, 0.1), marginBottom: 12 }}>
                                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                  <b>Healthy Alternative Added to Recipes!</b>
                                  <FontAwesomeIcon icon={faCheckCircle} color={token.colorSuccess} />
                                </Space>
                              </Card>
                            ) : (
                              <Button
                                type='primary'
                                style={{ width: '100%', marginBottom: 12 }}
                                onClick={() => {
                                  setFacet('ha')
                                }}
                              >
                                View healthy alternative <FontAwesomeIcon icon={faArrowRight} />
                              </Button>
                            )}
                          </>
                        ) : undefined}
                        <div style={{ textAlign: 'center' }}>
                          {uploadedImage || macroData.image ? (
                            <img
                              style={{ width: '100%', borderRadius: '10px' }}
                              src={uploadedImage || macroData.image}
                              alt='Uploaded Macro Meal'
                            />
                          ) : undefined}
                        </div>

                        {/* Pass raw macroData and servings to MacroRings */}

                        <MacroRings
                          macroData={macroData}
                          servings={servings}
                          setServings={setServings}
                          limits={limits}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Row gutter={[0, 12]}>
                    <Col span={24}>
                      <Button
                        style={{ width: '100%', background: hexToRGBA(token.colorPrimary, 0.2) }}
                        onClick={() => {
                          setFacet('alt')
                        }}
                      >
                        Select from Favourites <FontAwesomeIcon icon={faStar} style={{ color: 'goldenrod`' }} />
                      </Button>
                    </Col>
                    <Col span={24}>
                      <CameraCapture onAfterCapture={contactMacroAssistantAi} />
                    </Col>

                    {aiError ? (
                      <Col span={24}>
                        <Card style={{ color: token.colorError, background: hexToRGBA(token.colorError, 0.1) }}>
                          <p>
                            <b> {aiError} Please try again.</b>
                          </p>
                        </Card>
                      </Col>
                    ) : undefined}
                  </Row>
                )}
              </>
            )}
          </>
        )}
      </Col>

      {macroData?._id || facet === 'alt' || !macroData || facet === 'ha' ? undefined : (
        <Col span={24}>
          <Card>
            <Space>
              Save to favourite meals?{' '}
              <Switch
                onChange={(boolean) => {
                  setSaveToFavourites(boolean)
                }}
                value={saveToFavourites}
                checkedChildren={'Yes'}
                unCheckedChildren='No'
              />
            </Space>
          </Card>
        </Col>
      )}
      {facet === 'ha' || facet === 'alt' ? undefined : (
        <Col span={24}>
          <Card style={{ backgroundColor: '#f9f9f9', borderColor: '#d9d9d9' }} bordered={true}>
            <p style={{ fontSize: '14px', color: '#595959', textAlign: 'left', fontWeight: 'bold' }}>Please note:</p>
            <p style={{ fontSize: '14px', color: '#595959', textAlign: 'justify', lineHeight: '1.5' }}>
              The nutrient data provided by this AI tool is intended for informational purposes only and may not be
              fully accurate. It should not be used as a substitute for professional dietary advice. We recommend
              consulting a qualified healthcare provider for personalized guidance.
            </p>
          </Card>
        </Col>
      )}
      {macroData && facet !== 'ha' && facet !== 'alt' ? (
        <Col span={24}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 12 }}>
            <Button
              type='primary'
              style={{ background: token.colorError }}
              onClick={() => handleReset(false)}
              loading={saving}
            >
              Discard
            </Button>
            <Button
              type='primary'
              style={{ background: token.colorSuccess }}
              onClick={handleCreateNewMacroEntry}
              loading={saving}
            >
              Confirm
            </Button>
          </div>
        </Col>
      ) : undefined}
    </CustomRow>
  )
}

export default NewImageUpload
