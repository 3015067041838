import { faArrowRight, faCancel, faCheck, faFilePdf, faMultiply, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, DatePicker, Form, Row, Space, Switch, Table, Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { handleError } from '../../lib/utils'
import { deleteSickNote, readSickNotes, saveSickNote } from '../examination-utils/examination-lib'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { downloadSickLeave } from '../../Medical Vault/diagnosis-history/utils/utils'
import pdfGenerationEnums from '../../Medical Vault/diagnosis-history/utils/enums'

const SickNoteCapture = ({ setModalOpen, bookingData, userRef, patient }) => {
  const authState = useSelector((state) => state.auth)

  const [loading, setLoading] = useState(false)
  const [familyResponsibility, setFamilyResponsibility] = useState(false)
  const [sickLeaveForm] = Form.useForm()
  const [sickNotesLoading, setSickNotesLoading] = useState(false)
  const [sickNotes, setSickNotes] = useState([])

  useEffect(() => {
    handleReadSickNotes()
    // eslint-disable-next-line
  }, [])

  const handleReadSickNotes = async () => {
    let tmpSickNotes = []

    try {
      tmpSickNotes = await readSickNotes({
        bookingRef: bookingData ? bookingData._id : '',
        userRef: bookingData ? bookingData.userRef : userRef
      })
      setSickNotes(tmpSickNotes)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const submitSickNote = async () => {
    setLoading(true)
    try {
      await saveSickNote({
        ...sickLeaveForm.getFieldsValue(),
        bookingRef: bookingData ? bookingData._id : '',
        userRef: bookingData ? bookingData.userRef : userRef
      })
      handleGenerateSickLeaveTemplate(sickLeaveForm.getFieldsValue())
      sickLeaveForm.resetFields()
      setModalOpen(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleCancel = () => {
    sickLeaveForm.resetFields()
    setModalOpen(false)
  }

  const handleSickNoteAction = async (action, record) => {
    try {
      switch (action) {
        case 'delete':
          setSickNotesLoading(true)
          await deleteSickNote(record._id)
          handleReadSickNotes()
          break
        case 'print':
          handleGenerateSickLeaveTemplate(record)
          break
        default:
          break
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSickNotesLoading(false)
  }

  const handleGenerateSickLeaveTemplate = async (record) => {
    const signature = authState.agiliteUser.extraData.signature
    const mpNumber = authState.agiliteUser.extraData.mpNumber

    setLoading(true)

    if (!signature) {
      setLoading(false)
      return message.error(
        'No Signature has been set. Please set your Signature in your Personal Details Medical Profile.'
      )
    }

    if (!mpNumber) {
      setLoading(false)
      return message.error(
        'No MP Number has been set. Please set your MP Number in your Personal Details Medical Profile.'
      )
    }

    try {
      await downloadSickLeave(
        bookingData,
        record,
        signature,
        pdfGenerationEnums.pdfGeneration.SICK_LEAVE_STANDARD,
        patient
      )
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  return (
    <Form onFinish={submitSickNote} form={sickLeaveForm}>
      <Row gutter={[0, 24]}>
        {sickNotes.length > 0 ? (
          <Col span={24}>
            <Table
              loading={sickNotesLoading}
              size='small'
              bordered={false}
              columns={[
                {
                  title: 'Start Date',
                  dataIndex: 'startDate',
                  key: 'startDate',
                  render: (text) => dayjs(text).format('DD MMMM YYYY'),
                  width: '15%'
                },
                {
                  title: 'End Date',
                  dataIndex: 'endDate',
                  key: 'endDate',
                  render: (text) => dayjs(text).format('DD MMMM YYYY'),
                  width: '15%'
                },
                {
                  title: 'Family',
                  dataIndex: 'familyResponsibility',
                  key: 'familyResponsibility',
                  render: (text) => {
                    return (
                      <center>
                        {text ? (
                          <FontAwesomeIcon icon={faCheck} color='green' />
                        ) : (
                          <FontAwesomeIcon icon={faMultiply} color='red' />
                        )}
                      </center>
                    )
                  },
                  width: '10%'
                },
                { title: 'Comments', dataIndex: 'comments', key: 'comments' },
                {
                  title: 'Actions',
                  key: 'actions',
                  render: (text, record) => {
                    return (
                      <center>
                        <Space style={{ gap: 30 }}>
                          <FontAwesomeIcon
                            onClick={() => handleSickNoteAction('delete', record)}
                            size='lg'
                            icon={faTrash}
                            color='red'
                          />
                          <Tooltip title='Generate Sick Note PDF'>
                            <FontAwesomeIcon
                              onClick={() => handleSickNoteAction('print', record)}
                              size='lg'
                              icon={faFilePdf}
                              color='green'
                            />
                          </Tooltip>
                        </Space>
                      </center>
                    )
                  },
                  width: '15%'
                }
              ]}
              dataSource={sickNotes}
              pagination={false}
            />
          </Col>
        ) : null}
        <Col span={24}>
          <Space direction='vertical'>
            <p>Sick leave duration:</p>
            <Space>
              <Form.Item
                style={{ margin: 0 }}
                name={'startDate'}
                rules={[{ required: true, message: 'Please select a date' }]}
              >
                <DatePicker style={{ width: 200 }} format='DD MMMM YYYY' placeholder='Start Date' />
              </Form.Item>
              <FontAwesomeIcon icon={faArrowRight} />
              <Form.Item
                style={{ margin: 0 }}
                name={'endDate'}
                rules={[{ required: true, message: 'Please select a date' }]}
              >
                <DatePicker style={{ width: 200 }} format='DD MMMM YYYY' placeholder='End Date' />
              </Form.Item>
            </Space>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            <p>Family Responsibility Applicable:</p>
            <Form.Item valuePropName='checked' name='familyResponsibility' style={{ margin: 0 }}>
              <Switch
                checkedChildren='Yes'
                unCheckedChildren='No'
                onChange={(value) => setFamilyResponsibility(value)}
                value={familyResponsibility}
              />
            </Form.Item>
          </Space>
        </Col>
        {familyResponsibility ? (
          <Col span={24}>
            <p style={{ marginBottom: 12 }}>Comment: (max: 80 characters)</p>
            <Form.Item name='comments' style={{ margin: 0 }}>
              <TextArea placeholder='Your comments here...' maxLength={80} rows={8} />
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Row style={{ marginTop: 10 }} justify='space-between'>
        <Col>
          <Button danger disabled={loading} onClick={handleCancel}>
            <Space>
              <FontAwesomeIcon icon={faCancel} />
              Cancel
            </Space>
          </Button>
        </Col>
        <Col>
          <Button type='primary' htmlType='submit' loading={loading}>
            <Space>
              <FontAwesomeIcon icon={faCheck} />
              Submit
            </Space>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default SickNoteCapture
