import State from './state'
import { createSlice, current } from '@reduxjs/toolkit'

const systemUsersReducer = createSlice({
  name: 'systemUsersReducer',
  initialState: State,
  reducers: {
    setRecords: (state, action) => {
      state.data = action.payload
    },
    setActiveRecords: (state, action) => {
      state.activeRecords = action.payload
    },
    setActiveRecord: (state, action) => {
      state.activeRecords[action.payload.dataIndex] = action.payload.data
    },
    setFacet: (state, action) => {
      state.facet = action.payload
    },
    submitRecord: (state, action) => {
      state.data.push(action.payload)
    },
    updateRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload.id)
      state.data.splice(tmpIndex, 1, action.payload)
    },
    deleteRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload)
      state.data.splice(tmpIndex, 1)
    }
  }
})

export default systemUsersReducer
