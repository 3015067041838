import React from 'react'
import Templates from '../../utils/templates'
import { generateFormItem } from '../../../../lib/utils'
import { Col } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'

const MedHistFamily = ({ clientForm }) => {
  return (
    <>
      <CustomRow className='basic-card'>
        <Col>{generateFormItem(Templates.dataModel.familyHistoryList, clientForm)}</Col>
      </CustomRow>
    </>
  )
}

export default MedHistFamily
