import { faRefresh, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { Col, Empty, Space, Table, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleError } from '../../../lib/utils'
import { readSystemUsers } from '../../../Admin/system-users/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import coreReducer from '../../../../core/utils/reducer'
import DependantForm from './dependant-form'
import { columnTemplate } from './utils/templates'
import CoreEnums from '../../../../core/utils/enums'
import DependentsReducer from './utils/depentdents-reducer'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import { readMedicalHistory } from '../../../Admin/patients/utils/utils'
import { deviceDetect } from 'react-device-detect'

const Dependants = ({ isReception, mainPatient, setFacet, setFormData, refreshView }) => {
  const dispatch = useDispatch()
  const dependantsState = useSelector((state) => state.dependents.data)
  const [receptionData, setReceptionData] = useState([])
  const authState = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleGetDependants()
    // eslint-disable-next-line
  }, [])

  const handleGetDependants = async () => {
    let tmpDependants = []
    let tmpDependantsPatientData = []
    let dependantIds = []

    setLoading(true)

    try {
      tmpDependants = await readSystemUsers({
        mainMemberId: isReception ? mainPatient._id : authState.agiliteUser._id
      })

      dependantIds = tmpDependants.map((dependant) => dependant._id)

      if (dependantIds.length > 0) {
        tmpDependantsPatientData = await readMedicalHistory({ userRef: { $in: dependantIds } })
      }

      tmpDependants.map((dependant) => {
        return (dependant.patientRecord = tmpDependantsPatientData.find(
          (patientRecord) => patientRecord.userRef === dependant._id
        ))
      })

      if (isReception) {
        setReceptionData(tmpDependants)
      } else {
        dispatch(DependentsReducer.actions.setRecords(tmpDependants))
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleOpenDependantForm = (record, isNew) => {
    dispatch(
      coreReducer.actions.addTab({
        key: isNew ? `${CoreEnums.tabKeys.DEPENDANTS}_new` : `${CoreEnums.tabKeys.DEPENDANTS}_${record._id}`,
        closable: true,
        label: isNew ? 'New Dependant' : `Dependant: ${record.firstName} ${record.lastName}`,
        children: (
          <DependantForm
            isNew={isNew}
            data={record}
            token={token}
            key={record._id}
            handleGetDependants={handleGetDependants}
            setFormData={setFormData}
            mainPatient={mainPatient}
            isReception={isReception}
            refreshView={refreshView}
          />
        )
      })
    )
  }

  const closeTab = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `${CoreEnums.tabKeys.PATIENTS}_${CoreEnums.tabKeys.DEPENDANTS}_${mainPatient._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const { token } = theme.useToken()

  return (
    <ContainerCard title='Family Accounts'>
      <CustomRow justify='center' style={deviceDetect().isMobile ? { textAlign: 'center' } : {}}>
        <Col span={24}>
          <CustomRow className='basic-card'>
            <Col span={24}>
              <Space wrap>
                {isReception ? (
                  <center>
                    <CustomButton size='small' text='Close' type='danger' onClick={() => closeTab()} />
                  </center>
                ) : null}
                <CustomButton
                  text='Add Family Account'
                  size='small'
                  icon={faUserPlus}
                  loading={loading}
                  type='primary'
                  onClick={() => {
                    handleOpenDependantForm(
                      { firstName: '', lastName: '', idNo: '', dateOfBirth: '', gender: '', race: '' },
                      true
                    )
                  }}
                />
                {deviceDetect().isMobile ? undefined : (
                  <CustomButton
                    size='small'
                    text='Refresh'
                    icon={faRefresh}
                    iconPosition='end'
                    type='primary'
                    onClick={() => handleGetDependants()}
                  />
                )}
              </Space>
            </Col>
            <Col span={24}>
              <Table
                showHeader={!deviceDetect().isMobile}
                pagination={false}
                locale={{ emptyText: <Empty description='No Family Accounts to show' /> }}
                columns={columnTemplate(token)}
                rowKey={(record) => record._id}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleOpenDependantForm(record, false)
                    }
                  }
                }}
                dataSource={isReception ? receptionData : dependantsState}
                loading={loading}
              />
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default Dependants
