import React from 'react'
import CustomButton from './CustomButton'
import { handleClinicBooking, handleVirtualConsult } from '../Bookings/utils/lib'
import { useDispatch, useSelector } from 'react-redux'
import { faClinicMedical, faVideoCamera, faWarning } from '@fortawesome/free-solid-svg-icons'

import { deviceDetect } from 'react-device-detect'
import { checkSubscriptions } from '../Subscriptions/utils/utils'
import CustomRow from './CustomRow'
import { Col } from 'antd'

const BookingShortcut = ({ showSOS }) => {
  const dispatch = useDispatch()
  const entityState = useSelector((state) => state.core.entity)

  const containerStyle = { width: '100%', display: 'flex', flexWrap: 'wrap', gap: 16 }
  const buttonStyle = {
    width: deviceDetect().isMobile ? '100%' : 'auto'
  }

  return (
    <CustomRow className='basic-card'>
      <Col span={24}>
        <div style={containerStyle}>
          <CustomButton
            style={buttonStyle}
            onClick={() => {
              handleClinicBooking(false, dispatch)
            }}
            icon={faClinicMedical}
            type='primary'
            text='Schedule A Clinic Visit'
          />
          {/* <CustomButton
        style={buttonStyle}
        onClick={() => {
          handleHomeVisit(dispatch)
        }}
        icon={faHome}
        type='primary'
        text='Schedule A Home Visit'
      /> */}
          {entityState.virtualConsultations.enabled ? (
            <CustomButton
              onClick={() => handleVirtualConsult(false, dispatch)}
              style={buttonStyle}
              icon={faVideoCamera}
              type='primary'
              text='Schedule A Virtual Consult'
            />
          ) : undefined}
          {entityState.vitalLink.enabled && showSOS ? (
            <CustomButton
              onClick={() => window.open('tel:+27')}
              icon={faWarning}
              style={buttonStyle}
              type='danger'
              text='SOS (Emergency)'
              disabled={!checkSubscriptions('vitalLink')}
              tooltip={!checkSubscriptions('vitalLink') ? 'Meta-Link Subscription Required' : undefined}
            />
          ) : undefined}
        </div>
      </Col>
    </CustomRow>
  )
}

export default BookingShortcut
