import { Col, Collapse, message } from 'antd'
import React, { useEffect, useState } from 'react'
import BloodPressure from './Blood Pressure/blood-pressure'
import { handleError } from '../../lib/utils'
import Pulse from './Pulse/pulse'
import Bmi from './BMI/bmi'
import BloodSugar from './Blood Sugar/blood-sugar'
import Temperature from './Temperature/temperature'
import OxygenSaturation from './Oxygen Saturation/oxygen-saturation'
import Cholesterol from './Cholesterol/cholesterol'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { useSelector } from 'react-redux'
import { readBookings } from '../../Bookings/utils/utils'
import CustomRow from '../../reusable-components/CustomRow'

import DoctorPreview from './doctor-preview'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'
import { deviceDetect } from 'react-device-detect'

const PatientAnalytics = ({ userRef, gender, isDependant }) => {
  const authState = useSelector((state) => state.auth)
  const userId = userRef ? userRef : authState.agiliteUser._id
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    handleSetdata()
    // eslint-disable-next-line
  }, [userRef, authState.agiliteUser._id])

  const handleSetdata = async () => {
    let tmpData = null
    setLoading(true)
    try {
      tmpData = await readBookings({ userRef: userId })
      tmpData = tmpData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))
      tmpData = tmpData.filter((i) => i.examinationData && i.examinationData?.checkinData)
      setData(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const scrollGraph = (reference) => {
    const element = document.getElementById(reference)
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const currentReadings = data[data.length - 1]?.examinationData?.checkinData
  return (
    <>
      {loading ? (
        <AgiliteSkeleton spinnerTip='Loading Patient Analytics...' />
      ) : (
        <CustomRow gutter={[20, 20]}>
          {userRef && !isDependant ? <DoctorPreview scrollGraph={scrollGraph} data={data} /> : undefined}
          <Col span={24}>
            <Collapse>
              <CollapsePanel
                header={'Blood Pressure'}
                key='bp'
                extra={
                  !deviceDetect().isMobile ? undefined : (
                    <>
                      {currentReadings?.bloodPressure?.systolic}/{currentReadings?.bloodPressure?.diastolic}
                    </>
                  )
                }
              >
                <BloodPressure data={data} />
              </CollapsePanel>
              <CollapsePanel
                header='Pulse'
                key='pulse'
                extra={!deviceDetect().isMobile ? undefined : <>{currentReadings?.pulse}</>}
              >
                <Pulse data={data} />
              </CollapsePanel>
              <CollapsePanel
                header='Blood Sugar Level'
                key='sugarLevel'
                extra={!deviceDetect().isMobile ? undefined : <>{currentReadings?.sugarLevel}</>}
              >
                <BloodSugar data={data} />
              </CollapsePanel>
              <CollapsePanel
                header='Cholesterol'
                key='cholesterol'
                extra={!deviceDetect().isMobile ? undefined : <>{currentReadings?.cholesterol}</>}
              >
                <Cholesterol data={data} />
              </CollapsePanel>
              <CollapsePanel
                header='Temperature'
                key='temperature'
                extra={!deviceDetect().isMobile ? undefined : <>{currentReadings?.temperature}</>}
              >
                <Temperature data={data} />
              </CollapsePanel>
              <CollapsePanel
                header='Oxygen Saturation'
                key='oxygenSaturation'
                extra={!deviceDetect().isMobile ? undefined : <>{currentReadings?.oxygenSaturation}</>}
              >
                <OxygenSaturation data={data} />
              </CollapsePanel>
              <CollapsePanel
                header='BMI'
                key='bmi'
                extra={!deviceDetect().isMobile ? undefined : <>{currentReadings?.bmi}</>}
              >
                <Bmi data={data} gender={gender ? gender : authState.agiliteUser.gender} />
              </CollapsePanel>
            </Collapse>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default PatientAnalytics
