import { Col, Row, Space, Spin, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet } from '@fortawesome/free-solid-svg-icons'
import { getSugarLevelStatus } from '../utils'
import GraphGraphic from '../../../../assets/svg/Group 11.png'

const BloodSugarSimple = ({ data, loading }) => {
  const [sugarLevel, setSugarLevel] = useState(0)
  const cardStyle = {
    borderRadius: 12,
    boxShadow: '0 0 30px 0 #ECEDEE',
    padding: 12,
    minHeight: '100%'
  }
  const colorPrimary = '#FF9907'
  const colorSecondary = 'rgba(255, 152, 7, 0.15)'
  const spaceStyle = {
    alignItems: 'center'
  }
  const iconStyle = {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colorSecondary,
    borderRadius: 10
  }

  useEffect(() => {
    if (data?.examinationData?.checkinData?.sugarLevel) {
      setSugarLevel(data?.examinationData?.checkinData?.sugarLevel)
    }
  }, [data])

  return (
    <Row style={cardStyle} gutter={[0, 10]}>
      <Col span={24}>
        <Space style={spaceStyle}>
          <div style={iconStyle}>
            <FontAwesomeIcon size='2x' color={colorPrimary} icon={faDroplet} />
          </div>
          <span>Blood Sugar</span>
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          <div style={{ fontSize: 28 }}>{loading ? <Spin spinning /> : sugarLevel}</div>{' '}
          <div style={{ color: '#A1A6AC' }}>mg / dL</div>
        </Space>
      </Col>
      <Col span={24}>
        {loading ? undefined : (
          <Tag color={colorSecondary} style={{ color: 'black' }}>
            {data?.examinationData?.checkinData?.sugarLevel ? getSugarLevelStatus(sugarLevel) : 'No Readings'}
          </Tag>
        )}
      </Col>
      <Col span={24}>
        <img src={GraphGraphic} alt='Graphic' style={{ width: '100%' }} />
        {/* <SimpleAreaChartExample data={dummyData} colorPrimary={colorPrimary} colorSecondary={colorSecondary} /> */}
      </Col>
    </Row>
  )
}

export default BloodSugarSimple
