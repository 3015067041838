import { Col, Divider, Empty, Modal, Space, Spin, Tag, theme } from 'antd'
import React, { useEffect } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import './todays-patients.css'
import { useState } from 'react'
import ProfileEnums from '../../../../Profile/utils/enums'
import { handleCalculateAge } from '../../../../Bookings/utils/lib'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { handleDashboardExamination } from '../../../utils/lib'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../../../reusable-components/CustomButton'
import { capitalizeFirstLetter, convertDateTimeSAST } from '../../../../lib/utils'
import dayjs from 'dayjs'
import { generateStatus } from '../../../../lib/booking-utils'
import CoreEnums from '../../../../../core/utils/enums'
import ServicesEnums from '../../../../Admin/services/utils/enums'

const TodaysPatients = ({ refreshFunction, loading, setLoading }) => {
  const state = useSelector((state) => state)
  const todaysPatientsState = useSelector((state) => state.todaysPatients.data)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    setSelectedPatient(todaysPatientsState[0])
    handleSetLastUpdated()
    // eslint-disable-next-line
  }, [todaysPatientsState])
  const { token } = theme.useToken()

  const handleSetLastUpdated = () => {
    let currentTime = null
    let timeSAST = null
    let formattedTime = null

    currentTime = new Date()
    timeSAST = convertDateTimeSAST(currentTime)
    formattedTime = dayjs(timeSAST).format('HH:mm')

    setLastUpdated(formattedTime)
  }

  const checkActive = (record) => {
    let isActive = false
    isActive = selectedPatient?._id === record?._id
    if (isActive) {
      return 'active'
    } else {
      return ''
    }
  }
  const consultationLabelStyle = {
    width: 150
  }

  const handleSkipScreening = (record) => {
    Modal.confirm({
      title: 'Action Required',
      content: 'This booking is still at screening. Would you like to bypass screening?',
      okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
      cancelButtonProps: { style: { background: token.colorPrimary, color: 'white' } },
      onOk: () =>
        handleDashboardExamination(
          dispatch,
          selectedPatient,
          state.auth.agiliteUser.extraData.profession,
          refreshFunction,
          loading,
          setLoading,
          true
        ),
      onCancel: () => {
        handleDashboardExamination(
          dispatch,
          selectedPatient,
          state.auth.agiliteUser.extraData.profession,
          refreshFunction,
          loading,
          setLoading
        )
      },
      okText: 'Yes, skip screening.',
      cancelText: 'No, just view the record.',
      className: token.themeControl
    })
  }

  return (
    <Spin spinning={loading}>
      <CustomRow className='basic-card'>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Space
            wrap
            style={{ justifyContent: 'space-between', width: '100%', alignItems: 'flex-start', marginBottom: 24 }}
          >
            <div>
              <h2 style={{ margin: 0 }}>Today's Patient List</h2>
              <small>Last Updated: {lastUpdated}</small>
            </div>
            <CustomButton
              type='primary'
              onClick={() => {
                refreshFunction()
              }}
              size='small'
              disabled={loading}
              text='Refresh'
              icon={faRefresh}
            />
          </Space>
          {todaysPatientsState?.length > 0 ? (
            <CustomRow
              gutter={[24, 8]}
              style={{
                maxHeight: 500,
                overflow: 'auto',
                border: `2px ${token.colorBorderSecondary} solid`
              }}
              className='basic-card'
            >
              {todaysPatientsState.map((entry) => {
                const patientRecord = entry.patientRecord
                const status = entry.status
                const startTime = entry.startTime
                const service = entry.serviceName
                return (
                  <CustomRow
                    key={entry._id}
                    onClick={() => {
                      setSelectedPatient(entry)
                    }}
                    justify='space-between'
                    className={`todays-patients-item-container ${checkActive(entry)}`}
                  >
                    <Col>
                      <Space wrap style={{ alignItems: 'center' }}>
                        <div
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            justifyContent: 'center',
                            display: 'flex',
                            overflow: 'hidden'
                          }}
                        >
                          <img
                            style={{ width: '100%' }}
                            src={
                              patientRecord.profileImage
                                ? patientRecord.profileImage
                                : ProfileEnums.images.DEFAULT_IMAGE
                            }
                            alt='Profile'
                          />
                        </div>
                        <div style={{ maxWidth: '100%', width: 200 }}>
                          <h3>
                            {patientRecord.firstName} {patientRecord.lastName}
                          </h3>
                          <div style={{ color: 'rgba(0,0,0,0.60)' }}>{service}</div>
                        </div>
                      </Space>
                    </Col>

                    <Col>
                      <div style={{ color: '#0B82A5' }}>
                        <h3>{generateStatus(status, token)}</h3>
                      </div>
                    </Col>
                    <Col>
                      <Tag color='#77D3ED' style={{ color: token.colorText, padding: '8px 16px', borderRadius: 16 }}>
                        {startTime}
                      </Tag>
                    </Col>
                  </CustomRow>
                )
              })}
            </CustomRow>
          ) : (
            <CustomRow
              className='basic-card'
              style={{ border: `2px ${token.colorBorderSecondary}  solid` }}
              gutter={[0, 16]}
            >
              <Col span={24}>
                <Empty description='There are currently no patients left for the day.' />
              </Col>
            </CustomRow>
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Space
            wrap
            style={{ justifyContent: 'space-between', width: '100%', alignItems: 'flex-start', marginBottom: 24 }}
          >
            <div>
              <h2 style={{ margin: 0 }}>Consultation</h2>
              <small>Overview</small>
            </div>
          </Space>
          <CustomRow
            className='basic-card'
            style={{ border: `2px ${token.colorBorderSecondary}  solid` }}
            gutter={[0, 8]}
          >
            {selectedPatient ? (
              <>
                <Col span={24}>
                  <Space style={{ alignItems: 'flex-start' }}>
                    <div
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        display: 'flex',
                        overflow: 'hidden'
                      }}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={
                          selectedPatient.patientRecord.profileImage
                            ? selectedPatient.patientRecord.profileImage
                            : ProfileEnums.images.DEFAULT_IMAGE
                        }
                        alt='Profile'
                      />
                    </div>

                    <div>
                      {selectedPatient.patientRecord.firstName} {selectedPatient.patientRecord.lastName}
                      <br />
                      <span style={{ opacity: 0.5 }}>
                        {capitalizeFirstLetter(selectedPatient.patientRecord.gender)} -{' '}
                        {`${handleCalculateAge(selectedPatient)} Years`} - {selectedPatient.startTime}
                      </span>
                      <br />
                    </div>
                  </Space>
                </Col>
                <Col span={24}>
                  <Divider style={{ margin: 0 }} />
                </Col>
                {state.auth.agiliteUser.extraData.role.type === 'reception' ? (
                  <Col span={24}>
                    <Space wrap>
                      <div style={consultationLabelStyle}>Doctor:</div>
                      <div>{selectedPatient.medicalProfName}</div>
                    </Space>
                  </Col>
                ) : null}
                <Col span={24}>
                  <Space wrap>
                    <div style={consultationLabelStyle}>Status:</div>
                    <div>{generateStatus(selectedPatient.status, token)}</div>
                  </Space>
                </Col>
                {state.auth.agiliteUser.extraData.role.type === 'reception' ? (
                  <Col span={24}>
                    <Space wrap>
                      <div style={consultationLabelStyle}>OTP:</div>
                      <div>{selectedPatient.otp}</div>
                    </Space>
                  </Col>
                ) : null}
                <Col span={24}>
                  <Space wrap>
                    <div style={consultationLabelStyle}>Reason For Consult:</div>
                    {selectedPatient.serviceType === ServicesEnums.labels.VITALITY_HEALTH_CHECK ? (
                      <Tag color={token.colorPrimary}>{ServicesEnums.labels.VITALITY_HEALTH_CHECK}</Tag>
                    ) : (
                      <div>
                        {!selectedPatient.checkinData
                          ? 'Patient has not checked in'
                          : selectedPatient.checkinData?.ailment}
                      </div>
                    )}
                  </Space>
                </Col>
                {selectedPatient.serviceType !== ServicesEnums.labels.VITALITY_HEALTH_CHECK ? (
                  <Col span={24}>
                    <Space wrap>
                      <div style={consultationLabelStyle}>Symptoms:</div>
                      <div>
                        {!selectedPatient.checkinData ? (
                          'Patient has not checked in'
                        ) : (
                          <Space wrap>
                            {selectedPatient?.checkinData?.patientSelection?.map((symptom) => (
                              <>{symptom !== 'Other' ? <Tag color={token.colorSecondary}>{symptom}</Tag> : null}</>
                            ))}
                          </Space>
                        )}
                      </div>
                    </Space>
                  </Col>
                ) : null}
                {selectedPatient.checkinData?.patientInput ? (
                  <Space wrap>
                    <div style={consultationLabelStyle}>Checkin Note:</div>{' '}
                    <div>{selectedPatient.checkinData?.patientInput}</div>
                  </Space>
                ) : null}
                {state.auth.agiliteUser.extraData.role.type === 'reception' &&
                selectedPatient.status !== CoreEnums.bookingStatuses.billing ? undefined : (
                  <Col
                    span={24}
                    style={{ display: 'flex', justifyContent: 'center', marginTop: 24, cursor: 'pointer' }}
                  >
                    <CustomButton
                      type='success'
                      text={
                        state.auth.agiliteUser.extraData.role.type === 'reception'
                          ? 'Start Billing'
                          : state.auth.agiliteUser.extraData.role.type === 'admin'
                          ? 'View Record'
                          : 'Start Examination'
                      }
                      size='small'
                      onClick={() => {
                        if (
                          state.auth.agiliteUser.extraData.profession === 'doctor' &&
                          selectedPatient.status === CoreEnums.bookingStatuses.screening
                        ) {
                          handleSkipScreening(selectedPatient)
                        } else {
                          handleDashboardExamination(
                            dispatch,
                            selectedPatient,
                            state.auth.agiliteUser.extraData.profession,
                            refreshFunction,
                            loading,
                            setLoading
                          )
                        }
                      }}
                    />
                  </Col>
                )}
              </>
            ) : (
              <Col span={24}>
                <Empty
                  description={
                    todaysPatientsState.length === 0
                      ? 'There are currently no patients left for the day.'
                      : 'Select a patient to view appointment details.'
                  }
                />
              </Col>
            )}
          </CustomRow>
        </Col>
      </CustomRow>
    </Spin>
  )
}

export default TodaysPatients
