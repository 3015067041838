import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Space,
  message,
  AutoComplete,
  Switch,
  Select,
  Tabs,
  Row,
  Card
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { handleError, specialFieldValidation } from '../../../lib/utils'
import MedicalHistory from '../../../Medical Vault/medical-history/components/medical-history-form'
import { createAgiliteUser, findAgiliteUser, updateAgiliteUser } from '../../../Auth/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { createPatient, getPatient } from '../../../Medical Vault/medical-history/utils/utils'
import Templates from '../../../Medical Vault/medical-history/utils/templates'
import dayjs from 'dayjs'
import DiagnosisHistoryWrapper from '../../../Medical Vault/diagnosis-history/components/diagnosis-history-wrapper'
import DatePickerModal from '../../../reusable-components/DatePickerModal'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import coreReducer from '../../../../core/utils/reducer'
import { handleRelationChange } from './lib/dependant-functions'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import CustomRow from '../../../reusable-components/CustomRow'
import './dependents.css'
import PatientBookingsView from '../../../Bookings/components/Booking View Components/patient-bookings-view'
import CoreEnums from '../../../../core/utils/enums'
import { deviceDetect } from 'react-device-detect'
import PatientAnalytics from '../../../Analytics/Patients/patient-analytics'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import {
  MedPraxSchemePlanOptionSearch,
  MedPraxSchemePlanSearch,
  MedPraxSchemeSearch
} from '../../../Scripting/utils/utils'
import { debounce } from 'lodash'

const DependantForm = ({
  data,
  token,
  isNew,
  handleGetDependants,
  mainPatient,
  isReception,
  setFormData,
  refreshView
}) => {
  const formTemplate = {
    firstName: '',
    lastName: '',
    idNo: '',
    dateOfBirth: '',
    residentialAddress: '',
    gender: '',
    race: ''
  }

  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const [specialFormState, setSpecialFormState] = useState([])
  const [idType, setIdType] = useState('id')
  const [idChanged, setIdChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [patientData, setPatientData] = useState(null)
  const [medicalHistoryLoading, setMedicalHistoryLoading] = useState(false)
  const [record, setRecord] = useState(data)
  const [facet, setFacet] = useState('personalDetails')
  const [isNewDependant, setIsNewDependant] = useState(isNew)
  const [consentConfirmed, setConsentConfirmed] = useState(isNew ? false : true)
  const [familyRelationOptions, setFamilyRelationOptions] = useState([])
  const [med, setMed] = useState(false)

  // Medical Aid
  const [schemeLoading, setSchemeLoading] = useState(false)
  const [schemeData, setSchemeData] = useState([])
  const [plansLoading, setPlansLoading] = useState(false)
  const [plansData, setPlansData] = useState([])
  const [planOptionsLoading, setPlanOptionsLoading] = useState(false)
  const [planOptionData, setPlanOptionData] = useState([])

  // useEffect for defaulting values of dependant
  useEffect(() => {
    let tmpRecord = { ...record }

    if (isNew) {
      if (isReception) {
        if (mainPatient && mainPatient.medicalAid.name) {
          setMed(true)
          tmpRecord = {
            ...tmpRecord,
            medicalAid: mainPatient?.medicalAid
          }
        }
      } else if (authState.agiliteUser.medicalAid.name) {
        setMed(true)
        tmpRecord = {
          ...tmpRecord,
          medicalAid: authState.agiliteUser.medicalAid
        }
      }
    } else {
      if (data.medicalAid.name) {
        setMed(true)
        tmpRecord = {
          ...tmpRecord,
          medicalAid: data.medicalAid
        }
      }
    }

    tmpRecord.residentialAddress = data.residentialAddress || authState.agiliteUser.residentialAddress

    setRecord(tmpRecord)
    form.setFieldsValue(tmpRecord)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const tmpArr = []
    const tmpData = { ...formTemplate, ...record }

    for (const prop in tmpData) {
      tmpArr.push({ key: prop, value: tmpData[prop] })
    }

    if (tmpData.idNo) {
      if (tmpData.idNo.length === 13 && /^[0-9]+$/.test(tmpData.idNo)) {
        setIdType('id')
      } else {
        setIdType('passport')
      }
    } else {
      setIdType('id')
    }

    setSpecialFormState(tmpArr)

    // eslint-disable-next-line
  }, [record])

  useEffect(() => {
    if (!isNewDependant) {
      handleGetPatient()
    }

    // eslint-disable-next-line
  }, [record])

  const handleGetMedicalAids = async (query) => {
    let tmpData = []

    setSchemeLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemeSearch(query)
        setSchemeData(tmpData)
      } else {
        setSchemeData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSchemeLoading(false)
  }

  const handleGetMedicalAidPlans = async (query) => {
    let tmpData = []

    setPlansLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanSearch(query)
        setPlansData(tmpData)
      } else {
        setPlansData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlansLoading(false)
  }

  const handleGetMedicalAidPlanOptions = async (query) => {
    let tmpData = []

    setPlanOptionsLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanOptionSearch(query)
        setPlanOptionData(tmpData)
      } else {
        setPlanOptionData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlanOptionsLoading(false)
  }

  const handleGetPatient = async () => {
    let tmpPatient = null

    setMedicalHistoryLoading(true)

    try {
      tmpPatient = await getPatient(record._id)
      setPatientData(tmpPatient)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setMedicalHistoryLoading(false)
  }

  const handleSubmit = async () => {
    const formData = form.getFieldsValue(true)
    let formError = []
    let agiliteUser = null

    setLoading(true)

    try {
      try {
        await form.validateFields()
      } catch (e) {
        formError = e
        message.error(formError.errorFields[0].errors[0])
      }

      if (!formError.errorFields || formError.errorFields.length < 1) {
        // No form errors, continue
        if (idChanged && formData.idNo !== record.idNo) {
          agiliteUser = await findAgiliteUser({ idNo: formData.idNo })

          if (agiliteUser) {
            setLoading(false)
            return message.error(
              `An Account with this ${idType === 'id' ? 'ID Number' : 'Passport Number'} already exists. Please revise`
            )
          }
        }

        if (isNewDependant) {
          agiliteUser = await createAgiliteUser(
            { ...formData, mainMemberId: isReception ? mainPatient?._id : authState.agiliteUser._id },
            false,
            authState.agiliteUser._id
          )

          setRecord(agiliteUser)
          await createPatient({ userRef: agiliteUser._id })

          // Update Main Patient
          if (isReception) {
            refreshView()
          }

          setIsNewDependant(false)
        } else {
          agiliteUser = await updateAgiliteUser(record._id, {
            ...formData,
            extraData: { ...record.extraData, ...formData.extraData }
          })
          setRecord(agiliteUser)
          setIsNewDependant(false)
        }

        message.success('Family Account saved successfully')
        handleGetDependants()
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const closeTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNew ? `${CoreEnums.tabKeys.DEPENDANTS}_new` : `${CoreEnums.tabKeys.DEPENDANTS}_${record._id}`,
        removeBreadcrumb: true
      })
    )
    form.resetFields()
    setRecord({ firstName: '', lastName: '', idNo: '', dateOfBirth: '', gender: '', race: '' })
    handleGetDependants()
  }

  // eslint-disable-next-line
  const debouncedSchemeFilter = useCallback(
    debounce((query) => {
      if (query) {
        handleGetMedicalAids(query)
      } else {
        setSchemeLoading(false)
      }
    }, 1000),
    []
  )

  const [form] = Form.useForm()

  const facets = [
    {
      key: 'personalDetails',
      title: 'Personal Details'
    },
    {
      key: 'medicalHistory',
      title: 'Medical History'
    },
    {
      key: 'bookings',
      title: 'Bookings'
    },
    {
      key: 'diagnosisHistory',
      title: 'Diagnosis History'
    },
    {
      key: 'analytics',
      title: 'Analytics'
    }
  ]

  const handleValidateSave = () => {
    if (form.getFieldValue(['medicalAid', 'schemeCode'])) {
      if (!form.getFieldValue(['medicalAid', 'planCode'])) {
        return message.error('Please select a Medical Aid Plan')
      } else if (!form.getFieldValue(['medicalAid', 'planOptionCode'])) {
        return message.error('Please select a Medical Aid Plan Option')
      } else if (!form.getFieldValue(['medicalAid', 'number'])) {
        return message.error('Please provide your Medical Aid Number')
      } else if (!form.getFieldValue(['medicalAid', 'dependantNumber'])) {
        return message.error('Please provide your Medical Aid Dependant Number')
      }
    }

    handleSubmit()
  }

  return (
    <ContainerCard title={isNew ? 'New Dependent' : `${data.firstName} ${data.lastName}`}>
      <CustomRow justify='center'>
        <Col span={24}>
          {deviceDetect().isMobile ? (
            <Card size='small'>
              <center>
                <small style={{ color: token.colorError }}>Use the select box below to navigate</small>
              </center>
              <Select
                onChange={(value) => {
                  setFacet(value)
                }}
                value={facet}
                style={{ width: '100%' }}
                options={facets.map((facet) => {
                  return { value: facet.key, label: facet.title }
                })}
              />
            </Card>
          ) : (
            <Space>
              <Tabs
                type='card'
                onChange={(key) => {
                  setFacet(key)
                }}
              >
                {facets.map((item) => {
                  return <Tabs.TabPane tab={item.title} key={item.key}></Tabs.TabPane>
                })}
              </Tabs>
            </Space>
          )}
        </Col>
        {facet === 'personalDetails' ? (
          <>
            <Col span={24} style={{ width: 500, maxWidth: '100%' }}>
              <Form
                form={form}
                onFinishFailed={() => message.error('Please complete the form before saving.')}
                initialValues={{
                  ...formTemplate,
                  ...record,
                  dateOfBirth: record?.dateOfBirth ? dayjs(record.dateOfBirth) : null
                }}
                layout='vertical'
              >
                <CustomRow>
                  <Col span={24}>
                    {isNewDependant ? (
                      <div style={{ marginBottom: 12 }}>
                        <Checkbox
                          checked={consentConfirmed}
                          onChange={(e) => {
                            setConsentConfirmed(e.target.checked)
                          }}
                        >
                          I confirm that I have consent to manage this individuals account.
                        </Checkbox>
                      </div>
                    ) : (
                      <center>
                        You confirmed that you have consent to manage this account on
                        <b style={{ color: token.colorPrimary }}> {dayjs(data.dateCreated).format('DD MMMM YYYY')} </b>
                        at <b style={{ color: token.colorPrimary }}> {dayjs(data.dateCreated).format('HH:mm')}</b>
                      </center>
                    )}
                    <Row justify='center' style={{ marginTop: 10 }}>
                      <Col>
                        <Space>
                          <Button
                            disabled={loading}
                            danger
                            onClick={() => {
                              Modal.confirm({
                                title: 'Confirmation',
                                content: 'Are you sure you want to close this form? Any unsaved changes will be lost.',
                                onOk: () => {
                                  closeTab()
                                },
                                okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
                                okText: 'Yes',
                                cancelButtonProps: { danger: true },
                                className: token.themeControl
                              })
                            }}
                          >
                            Close
                          </Button>
                          <Button
                            disabled={!consentConfirmed}
                            loading={loading}
                            type='primary'
                            style={
                              consentConfirmed ? { background: token.colorSuccess } : { background: 'transparent' }
                            }
                            onClick={() => {
                              handleValidateSave()
                            }}
                          >
                            Save
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <CustomRow className='basic-card'>
                      <Col span={24}>
                        <h2
                          style={{
                            textAlign: 'center',
                            padding: 12,
                            borderRadius: 12,
                            background: token.colorSecondary,
                            color: 'white'
                          }}
                        >
                          Personal Details
                        </h2>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          style={{ margin: 0 }}
                          label='Relation'
                          name={['extraData', 'relation']}
                          rules={[{ required: true, message: 'Relation can`t be blank' }]}
                        >
                          <AutoComplete
                            style={{ fontSize: '16px !important' }}
                            onChange={(value) => {
                              let tmpOptions = ''
                              tmpOptions = handleRelationChange(value)
                              setFamilyRelationOptions(tmpOptions)
                            }}
                            options={familyRelationOptions}
                            placeholder='-Select Relation-'
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          style={{ margin: 0 }}
                          label='First Name'
                          name='firstName'
                          rules={[{ required: true, message: 'First name can`t be blank' }]}
                        >
                          <Input
                            onChange={(e) => {
                              specialFieldValidation(form, specialFormState, setSpecialFormState, {
                                key: 'firstName',
                                event: e,
                                validationConfig: {
                                  letters: { allowed: true, onlyCaps: false },
                                  numbers: false,
                                  spaces: true
                                }
                              })
                            }}
                            placeholder='e.g. Jane'
                            rules={[{ required: true, message: 'First name can`t be blank' }]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          style={{ margin: 0 }}
                          label='Last Name'
                          name='lastName'
                          rules={[{ required: true, message: 'Last name can`t be blank' }]}
                        >
                          <Input
                            onChange={(e) => {
                              specialFieldValidation(form, specialFormState, setSpecialFormState, {
                                key: 'lastName',
                                event: e,
                                validationConfig: {
                                  letters: { allowed: true, onlyCaps: false },
                                  numbers: false,
                                  spaces: true
                                }
                              })
                            }}
                            placeholder='e.g. Doe'
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Radio.Group
                          style={{ marginBottom: 8 }}
                          onChange={(e) => {
                            form.setFieldValue('idNo', null)
                            setIdType(e.target.value)
                          }}
                          value={idType}
                        >
                          <Radio value={'id'}>South African ID</Radio>
                          <Radio value={'passport'}>Passport</Radio>
                        </Radio.Group>
                        {idType === 'id' ? (
                          <Form.Item
                            style={{ margin: 0 }}
                            label='ID Number'
                            name='idNo'
                            rules={[
                              { required: true, message: 'Please provide your ID Number.' },
                              { min: 13, message: 'Invalid South African ID' }
                            ]}
                          >
                            <Input
                              placeholder='e.g. 790725*******'
                              maxLength={13}
                              onChange={(e) => {
                                setIdChanged(true)
                                specialFieldValidation(form, specialFormState, setSpecialFormState, {
                                  key: 'idNo',
                                  event: e,
                                  validationConfig: {
                                    letters: { allowed: false, onlyCaps: false },
                                    numbers: true,
                                    spaces: false
                                  }
                                })
                              }}
                            />
                          </Form.Item>
                        ) : (
                          <>
                            <Form.Item
                              style={{ margin: 0 }}
                              label='Passport Number'
                              name='idNo'
                              rules={[{ required: true, message: 'Please provide your Passport Number.' }]}
                            >
                              <Input
                                placeholder='e.g. AZ330B*******'
                                maxLength={35}
                                onChange={(e) => {
                                  setIdChanged(true)
                                  specialFieldValidation(form, specialFormState, setSpecialFormState, {
                                    key: 'idNo',
                                    event: e,
                                    validationConfig: {
                                      letters: { allowed: true, onlyCaps: true },
                                      numbers: true,
                                      spaces: false
                                    }
                                  })
                                }}
                              />
                            </Form.Item>
                          </>
                        )}
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          style={{ margin: 0 }}
                          label='Gender'
                          name='gender'
                          rules={[{ required: true, message: 'Please select your Gender.' }]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              form.setFieldValue('gender', e.target.value)
                            }}
                          >
                            <Radio value='female'>Female</Radio>
                            <Radio value='male'>Male</Radio>
                            <Radio value='other'>Other</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          style={{ margin: 0 }}
                          label='Race'
                          name='race'
                          rules={[{ required: true, message: 'Please select your Race.' }]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              form.setFieldValue('race', e.target.value)
                            }}
                          >
                            <Radio value='African'>African</Radio>
                            <Radio value='Coloured'>Coloured</Radio>
                            <Radio value='Indian'>Indian</Radio>
                            <Radio value='White'>White</Radio>
                            <Radio value='Other'>Other</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          style={{ margin: 0 }}
                          label='Date Of Birth'
                          name='dateOfBirth'
                          rules={[{ required: true, message: 'Please specify your date of birth' }]}
                        >
                          <DatePickerModal form={form} initialValue={record?.dateOfBirth} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          style={{ margin: 0 }}
                          label='Residential Address'
                          name='residentialAddress'
                          rules={[{ required: true, message: 'Please provide a Physical Address.' }]}
                        >
                          <GoogleAddress
                            selectProps={{
                              placeholder: form.getFieldValue('residentialAddress')
                                ? form.getFieldValue('residentialAddress')
                                : 'Residential Address',
                              onChange: (e) => {
                                form.setFieldValue('residentialAddress', e.label)
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </CustomRow>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={12}>
                    <CustomRow className='basic-card'>
                      <Col span={24}>
                        <h2
                          style={{
                            textAlign: 'center',
                            padding: 12,
                            borderRadius: 12,
                            background: token.colorSecondary,
                            color: 'white'
                          }}
                        >
                          Medical Aid
                        </h2>
                      </Col>
                      <Col span={24}>
                        {' '}
                        <Space>
                          Applicable
                          <Switch
                            checked={med}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                            onChange={(value) => {
                              setMed(value)
                            }}
                          />
                        </Space>
                        {med ? (
                          <div style={{ marginTop: 20 }}>
                            <Form.Item name={['medicalAid', 'schemeCode']} style={{ display: 'none' }}>
                              <Input />
                            </Form.Item>
                            <Form.Item name={['medicalAid', 'planCode']} style={{ display: 'none' }}>
                              <Input />
                            </Form.Item>
                            <Form.Item name={['medicalAid', 'planOptionCode']} style={{ display: 'none' }}>
                              <Input />
                            </Form.Item>
                            <Form.Item requiredMark='optional' label={'Medical Aid Name'} name={['medicalAid', 'name']}>
                              <Select
                                showSearch
                                allowClear
                                placeholder='Search for a Medical Aid...'
                                disabled={false}
                                onSearch={(value) => {
                                  debouncedSchemeFilter(value)
                                }}
                                onChange={(e, option) => {
                                  form.setFieldValue(
                                    ['medicalAid', 'schemeCode'],
                                    option?.data ? JSON.parse(option.data).code : ''
                                  )
                                  setSchemeData([])
                                }}
                                options={schemeData.map((i) => {
                                  return {
                                    label: i.name,
                                    value: i.name,
                                    data: JSON.stringify(i)
                                  }
                                })}
                                notFoundContent={
                                  schemeLoading ? (
                                    <AgiliteSkeleton skActive spinnerTip='Searching Schemes...' />
                                  ) : undefined
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              required={
                                form.getFieldValue(['medicalAid', 'schemeCode'])
                                  ? [{ required: true, message: 'Medical Aid Plan is required' }]
                                  : false
                              }
                              label={'Medical Aid Plan'}
                              name={['medicalAid', 'plan']}
                            >
                              <Select
                                showSearch
                                allowClear
                                onClear={() => {
                                  form.setFieldValue(['medicalAid', 'planCode'], '')
                                }}
                                placeholder='Select a Medical Aid Plan...'
                                onChange={(e, option) => {
                                  form.setFieldValue(
                                    ['medicalAid', 'planCode'],
                                    option?.data ? JSON.parse(option.data).code : ''
                                  )
                                  setPlansData([])
                                }}
                                onClick={() => {
                                  handleGetMedicalAidPlans(form.getFieldValue(['medicalAid', 'schemeCode']))
                                }}
                                options={plansData.map((i) => {
                                  return {
                                    label: i.option,
                                    value: i.option,
                                    data: JSON.stringify(i)
                                  }
                                })}
                                notFoundContent={
                                  plansLoading ? (
                                    <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plans...' />
                                  ) : undefined
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              required={
                                form.getFieldValue(['medicalAid', 'planCode'])
                                  ? [{ required: true, message: 'Medical Aid Plan Option is required' }]
                                  : false
                              }
                              label={'Medical Aid Plan Option'}
                              name={['medicalAid', 'planOption']}
                            >
                              <Select
                                showSearch
                                allowClear
                                onClear={() => {
                                  form.setFieldValue(['medicalAid', 'planOptionCode'], '')
                                }}
                                placeholder='Select a Medical Aid Plan Option...'
                                onChange={(e, option) => {
                                  form.setFieldValue(
                                    ['medicalAid', 'planOptionCode'],
                                    option?.data ? JSON.parse(option.data).code : ''
                                  )
                                  setPlanOptionData([])
                                }}
                                onClick={() => {
                                  handleGetMedicalAidPlanOptions(form.getFieldValue(['medicalAid', 'planCode']))
                                }}
                                options={planOptionData.map((i) => {
                                  return {
                                    label: i.option,
                                    value: i.option,
                                    data: JSON.stringify(i)
                                  }
                                })}
                                notFoundContent={
                                  planOptionsLoading ? (
                                    <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plan Options...' />
                                  ) : undefined
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              required={
                                form.getFieldValue(['medicalAid', 'schemeCode'])
                                  ? [{ required: true, message: 'Medical Aid Number is required' }]
                                  : false
                              }
                              label={'Medical Aid Number'}
                              name={['medicalAid', 'number']}
                            >
                              <Input placeholder='e.g. 335***' />
                            </Form.Item>
                            <Form.Item
                              required={
                                form.getFieldValue(['medicalAid', 'schemeCode'])
                                  ? [{ required: true, message: 'Medical Aid Dependant Number is required' }]
                                  : false
                              }
                              label='Dependant Number'
                              name={['medicalAid', 'dependantNumber']}
                            >
                              <Input placeholder='e.g. 01' />
                            </Form.Item>
                          </div>
                        ) : (
                          <center>
                            <i>Not Applicable</i>
                          </center>
                        )}
                      </Col>
                    </CustomRow>
                  </Col>
                </CustomRow>
              </Form>
            </Col>
          </>
        ) : undefined}
        {facet === 'medicalHistory' ? (
          <>
            {isNewDependant ? (
              <center>
                <Empty
                  description={
                    <>
                      Medical History is only available on existing family accounts. Please create the famnily account
                      first by capturing their Personal Details and clicking the <b>Save</b> button in the Personal
                      Details section.
                    </>
                  }
                />
              </center>
            ) : (
              <Col xs={24} sm={20} md={18} lg={16} xl={14} xxl={12}>
                <MedicalHistory
                  setHasConfirmedMedicalHistory={() => {}}
                  wrapperLoad={medicalHistoryLoading}
                  data={{ ...Templates.dataTemplate, ...patientData }}
                  isDependant={true}
                  userRef={record._id}
                  setDependantMedHist={setPatientData}
                  closeButton={true}
                  onClose={() => {
                    closeTab()
                  }}
                  saveButtonTitle='Save'
                />
              </Col>
            )}
          </>
        ) : undefined}
        {facet === 'bookings' ? (
          <>
            {isNewDependant ? (
              <center>
                <Empty
                  description={
                    <>
                      Bookings are only available on existing family accounts. Please create the family account first by
                      capturing their Personal Details and clicking the <b>Save</b> button in the Personal Details
                      section.
                    </>
                  }
                />
              </center>
            ) : (
              <>
                <PatientBookingsView userRef={data._id} />
                <center>
                  <Button
                    style={{ marginTop: 10 }}
                    disabled={loading}
                    danger
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirmation',
                        content: 'Are you sure you want to close this form? Any unsaved changes will be lost.',
                        onOk: () => {
                          closeTab()
                        },
                        okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
                        okText: 'Yes',
                        cancelButtonProps: { danger: true },
                        className: token.themeControl
                      })
                    }}
                  >
                    Close
                  </Button>
                </center>
              </>
            )}
          </>
        ) : undefined}
        {facet === 'diagnosisHistory' ? (
          <>
            {isNewDependant ? (
              <center>
                <Empty
                  description={
                    <>
                      Diagnosis History is only available on existing family accounts. Please create the family account
                      first by capturing their Personal Details and clicking the <b>Save</b> button in the Personal
                      Details section.
                    </>
                  }
                />
              </center>
            ) : (
              <>
                <CustomRow>
                  <Col span={24}>
                    <Button
                      style={{ marginTop: 10 }}
                      disabled={loading}
                      danger
                      onClick={() => {
                        Modal.confirm({
                          title: 'Confirmation',
                          content: 'Are you sure you want to close this form? Any unsaved changes will be lost.',
                          onOk: () => {
                            closeTab()
                          },
                          okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
                          okText: 'Yes',
                          cancelButtonProps: { danger: true },
                          className: token.themeControl
                        })
                      }}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col span={24}>
                    <DiagnosisHistoryWrapper userRef={record._id} />
                  </Col>
                </CustomRow>
              </>
            )}
          </>
        ) : undefined}
        {facet === 'analytics' ? (
          <>
            {isNewDependant ? (
              <center>
                <Empty
                  description={
                    <>
                      Analytics is only available on existing family accounts. Please create the family account first by
                      capturing their Personal Details and clicking the <b>Save</b> button in the Personal Details
                      section.
                    </>
                  }
                />
              </center>
            ) : (
              <>
                <CustomRow>
                  <Col span={24}>
                    <Button
                      style={{ marginTop: 10 }}
                      disabled={loading}
                      danger
                      onClick={() => {
                        Modal.confirm({
                          title: 'Confirmation',
                          content: 'Are you sure you want to close this form? Any unsaved changes will be lost.',
                          onOk: () => {
                            closeTab()
                          },
                          okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
                          okText: 'Yes',
                          cancelButtonProps: { danger: true },
                          className: token.themeControl
                        })
                      }}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col span={24}>
                    <PatientAnalytics userRef={record._id} isDependant={true} />
                  </Col>
                </CustomRow>
              </>
            )}
          </>
        ) : undefined}
      </CustomRow>
    </ContainerCard>
  )
}

export default DependantForm
