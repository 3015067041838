import { Col, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'

import DashboardGreeting from './components/dashboard-greeting'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { readAllBookings } from '../../Admin/bookings/utils/utils'
import { convertDateTimeSAST, handleError } from '../../lib/utils'
import ClinicsListed from './components/Admin/clinics-listed'
import dayjs from 'dayjs'
import { calculateMonthEnd, calculateMonthStart } from './lib/utils'

const DoctorDashboard = () => {
  const [clinics, setClinics] = useState([])
  const [loading, setLoading] = useState([])

  useEffect(() => {
    handleFetchClinicData()
    // eslint-disable-next-line
  }, [])
  const handleFetchClinicData = async () => {
    const tmpClinics = []

    let tmpClinicData = null
    let tmpBookingData = null
    let qry = {}

    setLoading(true)

    try {
      tmpClinicData = await readClinics()

      qry.bookingDate = {
        $gte: calculateMonthStart(),
        $lte: calculateMonthEnd()
      }

      tmpBookingData = await readAllBookings(qry)

      for (const clinic of tmpClinicData) {
        tmpClinicData = { ...clinic }
        tmpClinicData.bookingData = tmpBookingData.filter((booking) => booking.clinicRef === clinic._id)

        tmpClinics.push(tmpClinicData)
      }

      setClinics(tmpClinics)
    } catch (e) {
      message.error(handleError(e))
    }

    setLoading(false)
  }

  const { token } = theme.useToken()

  return (
    <CustomRow justify='center' style={{ background: '#f4f9fa' }}>
      <Col span={24}>
        <DashboardGreeting />
      </Col>
      <Col span={24}>
        <h1 style={{ background: token.colorSecondary, padding: 12, borderRadius: 12, color: 'white' }}>
          {dayjs()
            .month(dayjs(convertDateTimeSAST(new Date())).month())
            .format('MMMM')}{' '}
          Bookings
        </h1>
      </Col>
      <Col span={24}>
        <ClinicsListed handleFetchClinicData={handleFetchClinicData} loading={loading} clinics={clinics} />
      </Col>
    </CustomRow>
  )
}

export default DoctorDashboard
