import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import {
  Card,
  Col,
  Space,
  theme,
  Switch,
  InputNumber,
  Input,
  message,
  Modal,
  Button,
  Table,
  Tooltip,
  Row,
  Radio,
  Select
} from 'antd'
import ScriptingTool from './scripting-tool'
import CustomButton from '../../reusable-components/CustomButton'
import { faFilePdf, faMedkit } from '@fortawesome/free-solid-svg-icons'
import { validateChronicIllnesses, validateScript } from '../utils/lib'
import { handleError, hexToRGBA } from '../../lib/utils'
import dayjs from 'dayjs'
import { getDischems, updateScript } from '../utils/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updatePatient } from '../../Medical Vault/medical-history/utils/utils'
import { medAdminTypes } from '../utils/templates'

const ScriptingRecordView = ({
  isCopy,
  setIsCopy,
  isEditMode,
  setIsEditMode,
  data,
  handleCreateScript,
  isNew,
  handleCancel,
  loading,
  handleGenerateScriptTemplate,
  printingScript,
  patientChronicDetails,
  setPatientChronicDetails,
  patientId,
  selectedPatient,
  refreshView,
  getUserRef
}) => {
  const [script, setScript] = useState(data ? { ...data } : {})
  const [scriptBusy, setScriptBusy] = useState(false)
  const [bypassing, setBypassing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [previousScriptState, setPreviosScriptState] = useState(null)
  const [dischemModalOpen, setDischemModalOpen] = useState()
  const [dischemDeliveryApplicable, setDischemDeliveryApplicable] = useState(false)
  const [dischemType, setDischemType] = useState('C')
  const [dischemPaymentType, setDischemPaymentType] = useState('Card')
  const [dischems, setDischems] = useState([])
  const [selectedDischem, setSelectedDischem] = useState(null)
  const { token } = theme.useToken()

  useEffect(() => {
    setScript({ ...data })
    // eslint-disable-next-line
  }, [isCopy])

  useEffect(() => {
    handleGetDischems()
  }, [])

  const handleGetDischems = async () => {
    let tmpDischems = []

    try {
      tmpDischems = await getDischems()
      setDischems(tmpDischems)
    } catch (e) {
      message.error(handleError(e))
    }
  }

  const handleUpdateScheduleData = (value, key) => {
    let tmpScript = { ...script }
    tmpScript[key] = value
    setScript(tmpScript)
  }

  const confirmReadOnly = () => {
    if (isNew) return false
    if (!isEditMode) return true
  }

  const handleBypassNextView = async () => {
    setBypassing(true)
    try {
      let newRecord = await updateScript(script._id, {
        nextDue: dayjs(script.nextDue).add(script.scheduleInterval * 30, 'days')
      })
      setScript(newRecord)
      refreshView()
    } catch (e) {
      handleError(e)
    }
    setBypassing(false)
  }

  const handleSave = async () => {
    // TODO: Update script with changes
    // TODO: If the notify interval changes we need to also correct the next due date by subtracting / adding by the n amount changed
    // TODO: Update medical history if the medical history has changed
    // TODO: Make sure there is always a userRef for updating the medical history else it might create another record for the patient as the route is an upsert
    setSaving(true)
    if (!script.name) {
      setSaving(false)
      return message.error('Script name required.')
    }
    if (!script.scheduleInterval) {
      setSaving(false)
      return message.error('Script reminder interval required.')
    }
    let errorHandler = validateScript(script)

    if (errorHandler[0]) {
      setSaving(false)
      return message.error(errorHandler[0])
    }

    if (validateChronicIllnesses(patientChronicDetails, script, 'confirmation')?.length > 0) {
      // NO ERROR MESSAGES PROCEED
      Modal.confirm({
        title: 'Changes have been made to patient medical history. Please confirm the changes.',
        width: 950,
        content: (
          <CustomRow style={{ maxWidth: '100%' }}>
            <Col span={24}>
              {validateChronicIllnesses(patientChronicDetails, script, 'confirmation').map((message) => {
                return message
              })}
            </Col>
          </CustomRow>
        ),
        // CALL PDF GENERATION && MEDICAL HISTORY UPDATES
        onOk: () => handleSaveExtended(),
        onCancel: () => {
          setSaving(false)
        },
        okText: 'Accept Changes',
        cancelText: 'Cancel',
        okButtonProps: { style: { background: token.colorPrimary } }
      })
    } else {
      handleSaveExtended()
    }
  }

  const handleSaveExtended = async () => {
    const medicalHistoryPayload = {
      chronicIllnesses: {
        updatedAt: dayjs(),
        list: [...validateChronicIllnesses(patientChronicDetails, script)]
      }
    }

    try {
      await updatePatient(medicalHistoryPayload, {
        userRef: getUserRef(script)
      })
      let newRecord = await updateScript(script._id, script)
      setIsEditMode(false)
      refreshView()
      setScript(newRecord)
    } catch (e) {
      message.error(handleError(e))
    }
    setSaving(false)
  }

  return (
    <CustomRow justify='center'>
      {bypassing ? (
        <Col span={24}>
          <AgiliteSkeleton skActive spinnerTip='Bypassing next due date...' />
        </Col>
      ) : (
        <Col xs={24} sm={24} md={script?.onSchedule || isNew || isCopy || isEditMode ? 24 : 16}>
          <Card size='small' type='inner' style={{ borderRadius: 0, borderBottom: 'none' }}>
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space style={{ alignItems: 'center', gap: 12 }} wrap>
                {!isNew && !script.onSchedule ? undefined : (
                  <Space>
                    <p>
                      <b>Name:</b>
                    </p>
                    {isEditMode || isNew ? (
                      <Input
                        readOnly={confirmReadOnly()}
                        placeholder={`New Script - ${dayjs().format('YYYY-MM-DD HH:mm')}`}
                        style={{ width: 355, maxWidth: '100%' }}
                        value={script.name}
                        onChange={(e) => {
                          handleUpdateScheduleData(e.target.value, 'name')
                        }}
                      />
                    ) : (
                      <>
                        <p>{script?.name}</p>
                      </>
                    )}
                  </Space>
                )}
                {!isNew && !script.onSchedule ? undefined : (
                  <>
                    {isNew ? (
                      <Space>
                        <p>Schedule</p>
                        <Switch
                          disabled={confirmReadOnly()}
                          checked={script.onSchedule}
                          onChange={(boolean) => {
                            handleUpdateScheduleData(boolean, 'onSchedule')
                          }}
                        />
                      </Space>
                    ) : undefined}
                    {script.onSchedule ? (
                      <Space>
                        <p>
                          <b>Notify me every: </b>
                        </p>
                        {isEditMode || isNew ? (
                          <InputNumber
                            min={1}
                            max={12}
                            formatter={(value) => `${Math.floor(value)}`}
                            parser={(value) => Math.floor(value)}
                            readOnly={confirmReadOnly()}
                            style={{ width: 150 }}
                            value={script?.scheduleInterval}
                            onChange={(value) => {
                              handleUpdateScheduleData(value, 'scheduleInterval')
                            }}
                            addonAfter={script?.scheduleInterval > 1 ? 'Months' : 'Month'}
                          />
                        ) : (
                          <p>
                            {script?.scheduleInterval} {script?.scheduleInterval > 1 ? 'Months' : 'Month'}
                          </p>
                        )}
                      </Space>
                    ) : undefined}
                  </>
                )}
              </Space>
              <Space wrap>
                <CustomButton
                  onClick={() => {
                    handleCancel()
                  }}
                  hasPopconfirm={isNew || isCopy}
                  popConfirmDescription={'Are you sure? All progress will be lost.'}
                  popConfirmTitle='Confirm Cancel'
                  type='error'
                  size='small'
                  style={{ color: token.colorError }}
                  text='CLOSE'
                  disabled={scriptBusy || loading || printingScript}
                />
                {!isNew ? (
                  <>
                    {script.onSchedule ? (
                      <CustomButton
                        onClick={() => {
                          if (isEditMode) {
                            let tmpPrevScript = JSON.parse(JSON.stringify(previousScriptState)) // create new instance works
                            setScript(tmpPrevScript)
                          } else {
                            let tmpScript = JSON.parse(JSON.stringify(script)) // create new instance works
                            setPreviosScriptState(tmpScript)
                          }
                          setIsEditMode(!isEditMode)
                        }}
                        type={isEditMode ? 'warning' : 'primary'}
                        size='small'
                        style={{ background: isEditMode ? hexToRGBA(token.colorWarning, 0.5) : token.colorPrimary }}
                        hasPopconfirm={isEditMode}
                        popConfirmDescription='Are you sure? All progress will be lost.'
                        popConfirmTitle='Confirm Undo'
                        text={isEditMode ? 'CANCEL EDIT' : 'EDIT'}
                        disabled={scriptBusy || loading || printingScript}
                      />
                    ) : (
                      <CustomButton
                        onClick={() => setIsCopy(!isCopy)}
                        type={isCopy ? 'warning' : 'primary'}
                        size='small'
                        style={{ background: isCopy ? hexToRGBA(token.colorWarning, 0.5) : token.colorPrimary }}
                        hasPopconfirm={isCopy}
                        popConfirmDescription='Are you sure? All progress will be lost.'
                        popConfirmTitle='Confirm Undo'
                        text={isCopy ? 'UNDO COPY' : 'Copy'}
                        disabled={scriptBusy || loading || printingScript}
                      />
                    )}
                  </>
                ) : undefined}
                {!isNew && !isCopy && !isEditMode ? (
                  <CustomButton
                    onClick={() => {
                      if (
                        script.onSchedule &&
                        script.nextDue &&
                        dayjs().format('YYYY-MM-DD') < dayjs(script.nextDue).format('YYYY-MM-DD')
                      ) {
                        Modal.confirm({
                          title: 'Not Due are you sure',
                          width: 950,
                          content: (
                            <CustomRow style={{ maxWidth: '100%' }}>
                              <Col span={24}>
                                This script is only due for repeat on the{' '}
                                {dayjs(script?.nextDue).format('DD MMMM YYYY')}
                              </Col>
                            </CustomRow>
                          ),
                          // CALL PDF GENERATION && MEDICAL HISTORY UPDATES
                          onOk: () => handleCreateScript(script, dischemDeliveryApplicable),
                          okText: 'Print Anyway',
                          cancelText: 'Cancel',
                          okButtonProps: { style: { background: token.colorPrimary } }
                        })
                      } else {
                        script.onSchedule
                          ? handleCreateScript(script, dischemDeliveryApplicable)
                          : handleGenerateScriptTemplate(data)
                      }
                    }}
                    // hasPopconfirm={script.onSchedule}
                    popConfirmDescription={'Are you sure? This will log a new entry in the schedule history.'}
                    popConfirmTitle={'Confirm'}
                    loading={printingScript}
                    icon={faFilePdf}
                    type='danger'
                    size='small'
                    text={script.onSchedule ? 'Print Next Script' : 'Print PDF'}
                    disabled={scriptBusy || loading}
                  />
                ) : undefined}{' '}
                {isNew || isCopy || isEditMode ? (
                  <CustomButton
                    onClick={() => {
                      if (isEditMode) {
                        handleSave()
                      } else {
                        handleCreateScript(
                          script,
                          dischemDeliveryApplicable,
                          selectedDischem ? JSON.parse(selectedDischem).practiceNo : null,
                          dischemType,
                          dischemPaymentType
                        )
                      }
                    }}
                    loading={loading || printingScript || saving}
                    type='success'
                    size='small'
                    hasPopconfirm={isEditMode ? false : true}
                    popConfirmDescription={
                      script.onSchedule && isNew
                        ? 'Are you sure? This will log the initial script date.'
                        : 'Are you sure? Scripts can`t be edited or deleted once created.'
                    }
                    popConfirmTitle='Finalise Script'
                    text={isEditMode ? 'SAVE CHANGES' : 'CONFIRM SCRIPT'}
                    disabled={
                      scriptBusy ||
                      script?.items?.length === 0 ||
                      !script ||
                      !script?.items ||
                      (dischemDeliveryApplicable && (!dischemType || !selectedDischem))
                    }
                  />
                ) : undefined}
              </Space>
            </Space>
          </Card>
          <CustomRow gutter={[0, 0]}>
            {script.onSchedule && !isEditMode && !isNew ? (
              <Col span={12}>
                <Card
                  style={{ minHeight: '100%', borderRadius: 0 }}
                  headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorPrimary, 0.2) }}
                  size='small'
                  type='inner'
                  title={<center>Repeat history</center>}
                >
                  <Table
                    dataSource={[
                      dayjs(script.createdAt).format('DD MMMM YYYY'),
                      ...script.logs.map((log) => dayjs(log).format('DD MMMM YYYY'))
                    ]}
                    columns={[
                      { title: 'Date', render: (text) => text },
                      {
                        title: 'Actions',
                        render: () => (
                          <Tooltip title='Print PDF'>
                            <Button
                              loading={printingScript}
                              disabled={loading}
                              onClick={() => {
                                handleGenerateScriptTemplate(data)
                              }}
                            >
                              <FontAwesomeIcon icon={faFilePdf} />
                            </Button>
                          </Tooltip>
                        )
                      }
                    ]}
                  />
                  <Space>
                    <p>
                      <b>Next Due: </b>
                      {dayjs(script.nextDue).format('DD MMMM YYYY')}
                    </p>
                    <Space>
                      <small>
                        <i>Already supplied?</i>{' '}
                        <i
                          onClick={() => {
                            Modal.confirm({
                              title: 'Confirm Skip Rescript',
                              width: 950,
                              content: (
                                <CustomRow style={{ maxWidth: '100%' }}>
                                  <Col span={24}>
                                    Are you sure you want to skip rescript for the{' '}
                                    <b>{dayjs(script.nextDue).format('DD MMMM YYYY')}</b>? This action can't be undone.
                                  </Col>
                                  <Col span={24}>
                                    <p>
                                      <b>Next due: </b>
                                      {dayjs(script.nextDue)
                                        .add(script.scheduleInterval * 30, 'days')
                                        .format('DD MMMM YYYY')}
                                    </p>
                                  </Col>
                                </CustomRow>
                              ),
                              // CALL PDF GENERATION && MEDICAL HISTORY UPDATES
                              onOk: () => handleBypassNextView(),
                              okText: 'Skip',
                              cancelText: 'Cancel',
                              okButtonProps: { style: { background: token.colorError } }
                            })
                          }}
                          style={{ color: 'red', cursor: 'pointer' }}
                        >
                          skip
                        </i>
                      </small>
                    </Space>
                  </Space>
                </Card>
              </Col>
            ) : undefined}
            {isNew || isCopy || isEditMode ? (
              <>
                <Col span={24}>
                  <ScriptingTool
                    adhoc
                    selectedPatient={selectedPatient}
                    patientChronicDetails={patientChronicDetails}
                    setPatientChronicDetails={setPatientChronicDetails}
                    scriptBusy={scriptBusy}
                    setScriptBusy={setScriptBusy}
                    script={script}
                    setScript={setScript}
                    dischemDeliveryApplicable={dischemDeliveryApplicable}
                    patientId={patientId}
                    dischemModalOpen={dischemModalOpen}
                    setDischemModalOpen={setDischemModalOpen}
                    selectedDischem={selectedDischem}
                    dischemType={dischemType}
                  />
                </Col>
              </>
            ) : (
              <Col xs={24} sm={24} md={script.onSchedule ? 12 : 24} lg={script.onSchedule ? 12 : 24}>
                <Card
                  style={{ minHeight: '100%', borderRadius: 0 }}
                  headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorPrimary, 0.2) }}
                  size='small'
                  type='inner'
                  title={
                    <center>
                      <Space direction='vertical' style={{ gap: 0 }}>
                        <h2>Script Items</h2>{' '}
                        <small>
                          Scripted on {dayjs(data.createdAt).format('DD MMM YYYY')} at{' '}
                          {dayjs(data.createdAt).format('HH:mm')}
                        </small>
                      </Space>
                    </center>
                  }
                >
                  <Row gutter={[0, 24]}>
                    {script.items.map((med) => {
                      return (
                        <Col span={24}>
                          <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start  ' }}>
                            <div>
                              <Space style={{ gap: 0 }}>
                                {medAdminTypes.find((i) => i.key === med.presentationCode)?.icon ? (
                                  <div style={{ color: med.isChronic ? '#F39C12' : token.colorPrimary, width: 22 }}>
                                    {medAdminTypes.find((i) => i.key === med.presentationCode)?.icon}{' '}
                                  </div>
                                ) : (
                                  <div style={{ color: med.isChronic ? '#F39C12' : token.colorPrimary, width: 22 }}>
                                    <FontAwesomeIcon icon={faMedkit} />
                                  </div>
                                )}
                                {`${med.name ? med.name : ''} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                                  med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                                } ${med.presentationCode ? med.presentationCode : ''}`}
                              </Space>
                              <div
                                style={{
                                  paddingLeft: 20,
                                  borderLeft: `2px ${med.isChronic ? token.colorWarning : token.colorPrimary} solid`
                                }}
                              >
                                <small>{med.rpt ? <>Repeat: {med.rpt}</> : <>No Repeat</>}</small>
                                <br />
                                <small>
                                  {med.scriptingDosage ? med.scriptingDosage : 'No scripting dosage specified.'}
                                </small>
                                <br />
                                <small>
                                  {med.icd ? (
                                    <>
                                      ICD: {med.icd.code} - {med.icd.description}
                                    </>
                                  ) : (
                                    <>No ICD</>
                                  )}
                                </small>
                                <br />

                                {med.isChronic ? <small style={{ color: '#F39C12' }}>Chronic</small> : undefined}
                              </div>
                            </div>
                          </Space>
                        </Col>
                      )
                    })}
                  </Row>
                </Card>
              </Col>
            )}
          </CustomRow>
        </Col>
      )}
      <Modal
        width={650}
        title={
          <div style={{ padding: 8, background: 'linear-gradient(45deg, #00A049,  #F3EC1C)' }}>
            <h1 style={{ color: '#ffffff' }}>Dischem Delivery/Collection</h1>
          </div>
        }
        closable={false}
        open={dischemModalOpen}
        onCancel={() => setDischemModalOpen(false)}
        okButtonProps={{ style: { background: 'linear-gradient(45deg, #00A049,  #F3EC1C)' } }}
        onOk={() => {
          setDischemModalOpen(false)
        }}
        okText='Confirm'
      >
        <Card size='small' type='inner'>
          <Row>
            <Col>
              <Space>
                Patient Requesting Delivery/Collection
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  style={{ background: dischemDeliveryApplicable ? '#00A049' : '' }}
                  onChange={(value) => setDischemDeliveryApplicable(value)}
                />
              </Space>
            </Col>
          </Row>
          {dischemDeliveryApplicable ? (
            <>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <Radio.Group buttonStyle='solid' value={dischemType} onChange={(e) => setDischemType(e.target.value)}>
                    <Radio.Button
                      style={dischemType === 'C' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                      value='C'
                    >
                      Collection
                    </Radio.Button>
                    <Radio.Button
                      style={dischemType === 'D' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                      value='D'
                    >
                      Delivery
                    </Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <span>Payment Type</span>
                  <br />
                  <Radio.Group
                    style={{ marginTop: 5 }}
                    buttonStyle='solid'
                    value={dischemType === 'C' ? 'In-Store' : dischemPaymentType}
                    onChange={(e) => setDischemPaymentType(e.target.value)}
                  >
                    {dischemType !== 'C' ? (
                      <>
                        <Radio.Button
                          style={dischemPaymentType === 'Cash' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                          value='Cash'
                        >
                          Cash
                        </Radio.Button>
                        <Radio.Button
                          style={dischemPaymentType === 'Card' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                          value='Card'
                        >
                          Card
                        </Radio.Button>
                        <Radio.Button
                          style={
                            dischemPaymentType === 'Medical Aid'
                              ? { background: '#00A049', borderColor: '#00A049' }
                              : {}
                          }
                          value='Medical Aid'
                        >
                          Medical Aid
                        </Radio.Button>
                      </>
                    ) : (
                      <Radio.Button
                        style={dischemType === 'C' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                        value='In-Store'
                      >
                        In-Store
                      </Radio.Button>
                    )}
                  </Radio.Group>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <Space wrap direction='vertical'>
                    <span>
                      <span style={{ color: token.colorError }}>*</span>Dischem Store{' '}
                      <span style={{ color: token.colorError }}>(required)</span>:
                    </span>
                    <Select
                      style={{ width: 500, maxWidth: '100%' }}
                      showSearch
                      allowClear
                      optionFilterProp='value'
                      value={selectedDischem}
                      placeholder='Please search for a Dischem Store'
                      options={dischems.map((i) => {
                        return {
                          label: i.name,
                          value: JSON.stringify(i)
                        }
                      })}
                      onChange={(value, option) => {
                        setSelectedDischem(value)
                      }}
                      optionRender={(item) => {
                        return (
                          <Space wrap direction='vertical' style={{ width: 500 }}>
                            <b>{item.label}</b>
                            <small>{JSON.parse(item.value).address}</small>
                          </Space>
                        )
                      }}
                    />
                  </Space>
                </Col>
              </Row>
            </>
          ) : undefined}
        </Card>
      </Modal>
    </CustomRow>
  )
}

export default ScriptingRecordView
