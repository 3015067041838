import React, { useEffect, useState } from 'react'
import BloodPressurePlotting from './blood-pressure-plotting'

const BloodPressure = ({ data, statuses, setStatuses }) => {
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    let tmpData = data.concat()

    tmpData = tmpData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))

    setFormattedData(tmpData)
  }, [data])

  return <BloodPressurePlotting data={formattedData} />
}

export default BloodPressure
