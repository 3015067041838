// src/components/Readings/BPReadings.js

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List, Checkbox, Button, Typography, message, Space, theme, Popconfirm } from 'antd'
import { hexToRGBA } from '../../../../lib/utils'
import { useSelector } from 'react-redux'
import { createClinicDataRecords } from '../../../../Examination/examination-utils/examination-lib'

const { Title } = Typography

/**
 * BPReadings Component
 *
 * Props:
 * - readings: Array of BP reading objects.
 * - onConfirm: Function to handle sending selected readings to the database.
 */
const BPReadings = ({ userRef, bookingRef, readings, setReadings, isDetecting }) => {
  const authState = useSelector((state) => state.auth)
  // Local state to manage selected readings
  const [selectedReadings, setSelectedReadings] = useState({})
  // Local state to manage readings display
  const [localReadings, setLocalReadings] = useState(readings)

  // Initialize selections: all selected by default
  useEffect(() => {
    const initialSelections = {}
    readings.forEach((reading) => {
      initialSelections[reading.id] = true
    })
    setSelectedReadings(initialSelections)
    setLocalReadings(readings)
  }, [readings])

  /**
   * Handle selection toggle for a reading
   * @param {string} id - The unique identifier of the reading
   */
  const handleSelect = (id) => {
    setSelectedReadings((prev) => ({
      ...prev,
      [id]: !prev[id]
    }))
  }

  /**
   * Handle confirmation of selected readings
   */
  const handleConfirm = async () => {
    // Filter selected readings
    const readingsToConfirm = localReadings.filter((reading) => selectedReadings[reading.id])

    if (readingsToConfirm.length === 0) {
      message.warning('No readings selected to confirm.')
      return
    }

    try {
      const promiseDataArray = readingsToConfirm.map((reading) => {
        return createClinicDataRecords({
          dateCreated: reading.timestamp,
          createdBy: authState.agiliteUser._id,
          userRef: userRef,
          bookingRef: bookingRef,
          origin: 'booking',
          category: 'standard',
          key: 'bloodPressure',
          value: [
            {
              key: 'systolic',
              value: reading.systolic
            },
            {
              key: 'diastolic',
              value: reading.diastolic
            }
          ]
        })
      })
      await Promise.all(promiseDataArray)

      setReadings([])
      setLocalReadings([])
      setSelectedReadings({})
      message.success('Selected readings have been successfully sent to the database.')
    } catch (error) {
      console.error('Error confirming readings:', error)
      message.error('Failed to send readings to the database. Please try again.')
    }
  }

  /**
   * Handle cancellation of confirmation (optional)
   */
  // const handleDiscard = () => {
  //   // Optionally implement if needed
  //   setReadings([])
  //   setLocalReadings([])
  //   setSelectedReadings({})
  //   message.info('Confirmation canceled.')
  // }

  const { token } = theme.useToken()

  return (
    <>
      <div
        style={{
          padding: '20px',
          maxWidth: '100%',
          margin: '0 auto',
          maxHeight: '60vh',
          overflow: 'scroll',
          border: `2px ${hexToRGBA(token.colorPrimary, 0.2)} solid`
        }}
      >
        <Title level={3}>Blood Pressure Readings</Title>
        {localReadings.length === 0 ? (
          <p>No readings available.</p>
        ) : (
          <>
            <List
              itemLayout='horizontal'
              dataSource={localReadings}
              renderItem={(reading) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Checkbox
                        checked={selectedReadings[reading.id] || false}
                        onChange={() => handleSelect(reading.id)}
                      />
                    }
                    title={`Reading ID: ${reading.id}`}
                    description={
                      <>
                        <p>Systolic: {reading.systolic} mmHg</p>
                        <p>Diastolic: {reading.diastolic} mmHg</p>
                        <p>Pulse: {reading.pulse} bpm</p>
                        {reading.timestamp && <p>Timestamp: {new Date(reading.timestamp).toLocaleString()}</p>}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </>
        )}
      </div>
      <Space
        style={{
          justifyContent: 'right',
          width: '100%',
          background: hexToRGBA(token.colorPrimary, 0.2),
          padding: 12
        }}
      >
        <Popconfirm title='Are you sure?' description='This action can not be undone.' onConfirm={handleConfirm}>
          <Button
            type='primary'
            style={{ marginRight: '10px', background: isDetecting ? '' : token.colorError }}
            disabled={isDetecting || readings.length === 0}
          >
            Discard All
          </Button>
        </Popconfirm>
        <Popconfirm title='Are you sure?' description='This action can not be undone.' onConfirm={handleConfirm}>
          <Button
            type='primary'
            disabled={Object.values(selectedReadings).every((selected) => !selected) || isDetecting}
            style={{ marginRight: '10px', background: isDetecting ? '' : token.colorSuccess }}
          >
            Confirm Selected
          </Button>
        </Popconfirm>
        {/* Optional Cancel Button */}
        {/* <Button onClick={handleCancel}>Cancel</Button> */}
      </Space>
    </>
  )
}

BPReadings.propTypes = {
  readings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      systolic: PropTypes.number.isRequired,
      diastolic: PropTypes.number.isRequired,
      pulse: PropTypes.number.isRequired,
      timestamp: PropTypes.string // ISO string
    })
  ).isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default BPReadings
