import { Button, Col, Empty, Select, Space, Tooltip, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { handleError } from '../../../../../lib/utils'
import { debounce } from 'lodash'
import AgiliteSkeleton from '../../../../../reusable-components/AgiliteSkeleton'
import { SearchOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { MedPraxProductSearch } from '../../../../../Scripting/utils/utils'

const CardiacMedsListing = ({ form, path }) => {
  const [searchFilter, setSearchFilter] = useState(null)
  const [medsList, setMedsList] = useState(form.getFieldValue(path) ? form.getFieldValue(path) : [])
  const [nappiData, setNappiData] = useState([])
  const [nappiLoading, setNappiLoading] = useState(false)

  const { token } = theme.useToken()

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxProductSearch(searchQuery)
        setNappiData(tmpData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setNappiLoading(false)
  }

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(searchFilter)

    // eslint-disable-next-line
  }, [searchFilter])

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )
  const handleAdd = (value) => {
    let tmpMedsList = [...medsList]
    tmpMedsList.push({ name: value })
    form.setFieldValue(path, tmpMedsList)
    setMedsList(tmpMedsList)
    setSearchFilter(null)
    getNappiData()
  }
  const handleRemove = (index) => {
    let tmpMedsList = [...medsList]
    form.setFieldValue(path, tmpMedsList)
    tmpMedsList.splice(index, 1)
    setMedsList(tmpMedsList)
  }

  return (
    <Col span={24}>
      <ol>
        {medsList.map((med, index) => {
          return (
            <li key={index} style={{ marginBottom: 8 }}>
              <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
                {med.name}
                <Tooltip title='Remove'>
                  <Button
                    onClick={() => {
                      handleRemove(index)
                    }}
                  >
                    <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faTrash} color={token.colorError} />
                  </Button>
                </Tooltip>
              </Space>
            </li>
          )
        })}
      </ol>
      <Select
        value={searchFilter}
        showSearch
        onBlur={() => {
          setSearchFilter(null)
        }}
        options={nappiData.map((med) => ({
          value: `${med.name} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
            med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
          } ${med.presentationCode ? med.presentationCode : ''} - ${med.standardPacksize ? med.standardPacksize : ''}`,
          label: `${med.name} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
            med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
          } ${med.presentationCode ? med.presentationCode : ''} - ${med.standardPacksize ? med.standardPacksize : ''}`
        }))}
        placeholder={'Type to search Nappi List'}
        onChange={(value) => {
          handleAdd(value)
        }}
        suffixIcon={<SearchOutlined />}
        onSearch={(value) => {
          setSearchFilter(value)
        }}
        searchValue={searchFilter}
        style={{ width: '100%' }}
        loading={nappiLoading}
        notFoundContent={
          nappiLoading ? (
            <AgiliteSkeleton skActive={false} spinnerTip='Loading...' />
          ) : (
            <>
              {searchFilter && nappiData.length === 0 ? (
                <Empty description='No results found' />
              ) : (
                <Empty description='Start typing to search' />
              )}
            </>
          )
        }
      />
    </Col>
  )
}

export default CardiacMedsListing
