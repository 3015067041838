import { theme } from 'antd'
import React from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const GoogleAddress = (props) => {
  const { token } = theme.useToken()

  props.selectProps.styles = {
    dropdownIndicator: (baseStyles, state) => ({
      display: 'none'
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      color: token.colorTextBase,
      borderColor: state.isFocused ? token.colorPrimary : token.colorBorder,
      background: token.googleAddressControl,
      outline: 'none',
      height: 65
    }),
    input: (provided, state) => ({
      ...provided,
      color: token.colorTextBase,
      borderColor: state.isFocused ? token.colorPrimary : token.colorBorder,
      boxShadow: 'none'
    }),
    loadingIndicator: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: token.googleAddressControl,
      color: token.colorTextBase,
      borderColor: state.isFocused ? token.colorPrimary : token.colorBorder,
      outline: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      color: token.colorTextBase,
      background: token.googleAddressControl,
      position: 'relative',
      zIndex: 1
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: token.colorTextBase,
      background: token.colorInputBg,
      message: 'test'
    }),
    option: (provided, state) => ({
      ...provided,
      color: token.colorTextBase,
      background: state.isFocused ? token.colorBgBase : token.colorBgInput
    }),
    singleValue: (provided) => ({
      ...provided,
      color: token.colorTextBase,
      outline: 'none'
    }),
    valueContainer: (provided) => ({
      ...provided,
      color: token.colorTextBase
    })
  }

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAPS_API_KEY}
        {...props}

        // autocompletionRequest={{ componentRestrictions: { country: 'za' } }}
      />
    </div>
  )
}

export default GoogleAddress
