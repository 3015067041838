import { Button, Card, Col, Row, Space, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'

import DashboardGreeting from './components/dashboard-greeting'
import { handleError } from '../../lib/utils'
import { getSubscriptions } from '../../Subscriptions/utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faClose, faGlobeAfrica, faRefresh } from '@fortawesome/free-solid-svg-icons'

const SubscriptionAdminDashboard = () => {
  const [subs, setSubs] = useState([])
  const [loading, setLoading] = useState([])

  useEffect(() => {
    handleGetSubscriptions()
    // eslint-disable-next-line
  }, [])

  const handleGetSubscriptions = async () => {
    let subscriptions = []

    setLoading(true)

    try {
      subscriptions = await getSubscriptions()
      setSubs(subscriptions)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const { token } = theme.useToken()

  const spanStyle = { fontSize: 20 }

  return (
    <CustomRow justify='center' style={{ background: '#f4f9fa' }}>
      <Col span={24}>
        <DashboardGreeting />
      </Col>
      <Col span={24}>
        <Row>
          <Col xs={24} md={8}>
            <Card
              loading={loading}
              title='Meta-Link Subscriptions'
              headStyle={{ backgroundColor: token.colorSecondary, color: 'white' }}
              bodyStyle={{ backgroundColor: token.primaryMainLight }}
              extra={
                <Button type='primary' onClick={() => handleGetSubscriptions()}>
                  <Space>
                    <FontAwesomeIcon icon={faRefresh} /> Refresh
                  </Space>
                </Button>
              }
            >
              <Space style={{ display: 'flex', justifyContent: 'space-around' }}>
                <span style={{ ...spanStyle, color: token.colorSuccess }}>
                  <FontAwesomeIcon icon={faCheckCircle} /> Verified: {subs.filter((i) => i.verified).length}
                </span>
                <span style={{ ...spanStyle, color: token.colorError }}>
                  <FontAwesomeIcon icon={faClose} /> Unverified: {subs.filter((i) => !i.verified).length}
                </span>
                <span style={{ ...spanStyle, color: token.colorPrimary }}>
                  <FontAwesomeIcon icon={faGlobeAfrica} /> Total: {subs.length}
                </span>
              </Space>
            </Card>
          </Col>
        </Row>
      </Col>
    </CustomRow>
  )
}

export default SubscriptionAdminDashboard
