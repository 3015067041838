import { Col, message } from 'antd'
import React, { useEffect, useState } from 'react'
import BloodPressureSimple from './Summaries/BloodPressureSimple'
import HeartRateSimple from './Summaries/HeartRateSimple'
import CholesterolSimple from './Summaries/CholesterolSimple'
import BloodSugarSimple from './Summaries/BloodSugarSimple'
import BmiSimple from './Summaries/BmiSimple'
import CustomRow from '../../reusable-components/CustomRow'
import HeightWeight from './Summaries/height-weight'
import { handleError } from '../../lib/utils'
import { readBookings } from '../../Bookings/utils/utils'
import { useSelector } from 'react-redux'

const MedicalAnalysis = () => {
  const authState = useSelector((state) => state.auth)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const getData = async () => {
    let tmpData = null
    setLoading(true)
    try {
      tmpData = await readBookings({ userRef: authState.agiliteUser._id })
      tmpData = tmpData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))
      tmpData = tmpData.filter((i) => i.examinationData && i.examinationData.checkinData)
      if (tmpData.length > 0) {
        setData(tmpData[tmpData.length - 1])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  return (
    <CustomRow gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <HeartRateSimple loading={loading} data={data} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <BloodPressureSimple loading={loading} data={data} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <CholesterolSimple loading={loading} data={data} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <BloodSugarSimple loading={loading} data={data} />
      </Col>
      <Col span={24}>
        <h1>BMI Calculator</h1>
      </Col>
      <Col xs={24} sm={9} md={9} lg={9} xl={9}>
        <HeightWeight loading={loading} data={data} />
      </Col>
      <Col xs={24} sm={15} md={15} lg={15} xl={15}>
        <BmiSimple loading={loading} data={data} />
      </Col>
    </CustomRow>
  )
}

export default MedicalAnalysis
