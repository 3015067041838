import React from 'react'
import Templates from '../../utils/templates'
import { generateFormItem } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import { Col } from 'antd'

const MedHistMentalHealth = ({ clientForm }) => {
  return (
    <>
      <CustomRow className='basic-card'>
        <Col style={{ width: 620 }} span={24}>
          {generateFormItem(Templates.dataModel.mentalHealthList, clientForm)}
        </Col>
      </CustomRow>
    </>
  )
}

export default MedHistMentalHealth
