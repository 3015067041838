import State from './state'
import { createSlice, current } from '@reduxjs/toolkit'

const entitiesCRUDReducer = createSlice({
  name: 'entitiesCRUDReducer',
  initialState: State,
  reducers: {
    setRecords: (state, action) => {
      state.data = action.payload
    },
    submitRecord: (state, action) => {
      state.data.push(action.payload)
    },
    updateRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload.id)
      state.data.splice(tmpIndex, 1, action.payload)
    },
    deleteRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload)
      state.data.splice(tmpIndex, 1)
    }
  }
})

export default entitiesCRUDReducer
