import { Button, Card, Col, Row, Space, Steps, message, theme } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomRow from '../../reusable-components/CustomRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deviceDetect } from 'react-device-detect'
import { useSelector } from 'react-redux'
import {
  faArrowLeft,
  faArrowRight,
  faBookOpenReader,
  faCheckCircle,
  faCreditCard,
  faPhone,
  faPlane,
  faUsers
} from '@fortawesome/free-solid-svg-icons'

import '../styles.css'

import ContactInfo from './contact-info'
import TripDetails from './trip-details'
import SubscriptionSummary from './summary'
import SubscriptionSuccess from './subscription-success'
import OverView from './overview'
import SubscriptionPlan from './subscription-plan'

import { createNetCashTransaction, getNetCashTransaction, updateNetCashTransaction } from '../../Bookings/utils/utils'
import { handleError } from '../../lib/utils'
import dayjs from 'dayjs'

const VitalLink = () => {
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const isLocalVitalLink = state.core.entity.vitalLink.localVitalLink
  const isStaffVitalLink = state.core.entity.vitalLink.staffVitalLink
  const netCashEnabled = state.core.entity.vitalLink.netCashEnabled
  const defaultFirstAccAddress = state.core.entity.vitalLink.firstAccomodationAddress

  const paymentFormRef = useRef()
  const paymentSubFormRef = useRef()

  const { token } = theme.useToken()
  const [currentStep, setCurrentStep] = useState(0)
  const [currentStepKey, setCurrentStepKey] = useState('1')
  const [showNext, setShowNext] = useState(true)
  const [showPrevious, setShowPrevious] = useState(false)
  const [enableNext, setEnableNext] = useState(true)
  const [netCashTransaction, setNetCashTransaction] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isSub, setIsSub] = useState(false)
  const [amount, setAmount] = useState(0)
  const [subAmount, setSubAmount] = useState(0)
  const [continuePayment, setContinuePayment] = useState(false)
  const [subscription, setSubscription] = useState({
    isActive: true,
    key: 'vitalLink',
    name: 'Meta-Link',
    description: 'Meta-Link Subscription',
    userRef: authState.agiliteUser._id,
    startDate: null,
    endDate: null,
    extraData: {
      plan: null,
      phoneNumber: authState.agiliteUser.phoneNumber,
      location: null,
      panicButton: false
    }
  })

  useEffect(() => {
    const tmpSteps = steps.filter((s) => s.visible)
    setCurrentStepKey(tmpSteps[currentStep].key)
    // eslint-disable-next-line
  }, [currentStep])

  useEffect(() => {
    handleGetPaymentAmount()
    // eslint-disable-next-line
  }, [subscription])

  const handleGetPaymentAmount = () => {
    const vitalLinkEntity = state.core.entity.vitalLink
    let key = null
    let tmpAmount = null

    if (vitalLinkEntity.localVitalLink) {
      key = 'local'
      setIsSub(true)
    } else {
      key = 'tourist'
      setIsSub(false)
    }

    switch (subscription.extraData.plan) {
      case 'individual':
        tmpAmount = vitalLinkEntity[key].individualSubPrice
        break
      case 'couple':
        tmpAmount = vitalLinkEntity[key].coupleSubPrice
        break
      case 'family':
        tmpAmount = vitalLinkEntity[key].famSubPrice
        break
      default:
        tmpAmount = 0
        break
    }

    setSubAmount(tmpAmount)

    if (subscription.extraData.panicButton) {
      setAmount(parseInt(tmpAmount) + 3390)
    } else {
      setAmount(tmpAmount)
    }
  }

  const handleGetTransaction = async (id, intervalId) => {
    let response = null

    try {
      response = await getNetCashTransaction(id)

      if (response.status === 'success') {
        clearInterval(intervalId)
        // PAYMENT WAS SUCCESSFUL
        setLoading(false)
        setShowPrevious(false)
        next()
      } else if (response.status === 'failed') {
        setLoading(false)
        clearInterval(intervalId)
        message.error('Payment Failed. Please try again.', 10000)
      }

      setNetCashTransaction(response)
    } catch (e) {
      setLoading(false)
      clearInterval(intervalId)
      message.error(handleError(e, true))
    }
  }

  useEffect(() => {
    if (netCashTransaction && continuePayment) {
      if (netCashTransaction?._id) {
        if (isSub) {
          paymentSubFormRef.current.submit()
        } else {
          paymentFormRef.current.submit()
        }
      }
      setContinuePayment(false)
    }
    // eslint-disable-next-line
  }, [netCashTransaction])

  const handlePay = async () => {
    let transaction = null
    let tmpTransactionIntervalId = null

    setLoading(true)

    try {
      transaction = await createNetCashTransaction(state.auth.agiliteUser._id, amount, 'Vital-Link', isSub)
      setNetCashTransaction(transaction)
      setContinuePayment(true)

      tmpTransactionIntervalId = setInterval(
        () => handleGetTransaction(transaction._id, tmpTransactionIntervalId),
        5000
      )

      if (netCashEnabled) {
        setContinuePayment(true)
      } else {
        await updateNetCashTransaction(transaction._id)
      }
    } catch (e) {
      setLoading(false)
      message.error(handleError(e, true))
    }
  }

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
    setShowNext(true)
  }

  const iconStyle = (index) => {
    return {
      fontSize: 22,
      marginRight: 10,
      color: currentStep === index ? token.colorPrimary : currentStep > index ? token.colorSuccess : ''
    }
  }

  const textStyle = (index) => {
    return {
      color: currentStep === index ? token.colorPrimary : currentStep > index ? token.colorSuccess : ''
    }
  }

  const steps = [
    {
      key: '1',
      title: 'Overview',
      content: (
        <OverView
          setEnableNext={setEnableNext}
          subscription={subscription}
          setSubscription={setSubscription}
          token={token}
        />
      ),
      icon: <FontAwesomeIcon icon={faBookOpenReader} style={iconStyle(0)} />,
      visible: true
    },
    {
      key: '2',
      title: 'Subscription Plan',
      content: (
        <SubscriptionPlan
          setEnableNext={setEnableNext}
          subscription={subscription}
          setSubscription={setSubscription}
          token={token}
        />
      ),
      icon: <FontAwesomeIcon icon={faUsers} style={iconStyle(1)} />,
      visible: netCashEnabled
    },
    {
      key: '3',
      title: 'Contact Information',
      content: (
        <ContactInfo
          setEnableNext={setEnableNext}
          subscription={subscription}
          setSubscription={setSubscription}
          token={token}
        />
      ),
      icon: <FontAwesomeIcon icon={faPhone} style={iconStyle(2)} />,
      visible: true
    },
    {
      key: '4',
      title: 'Trip Details',
      content: (
        <TripDetails
          defaultFirstAccAddress={defaultFirstAccAddress}
          subscription={subscription}
          setSubscription={setSubscription}
          token={token}
          setEnableNext={setEnableNext}
        />
      ),
      icon: <FontAwesomeIcon icon={faPlane} style={iconStyle(3)} />,
      visible: !isLocalVitalLink
    },
    {
      key: '5',
      title: netCashEnabled ? 'Summary & Payment' : 'Summary',
      content: (
        <SubscriptionSummary
          netCashEnabled={netCashEnabled}
          defaultFirstAccAddress={defaultFirstAccAddress}
          subscription={subscription}
          setSubscription={setSubscription}
          token={token}
          setEnableNext={setEnableNext}
          setShowNext={setShowNext}
          next={next}
          setNetCashTransaction={setNetCashTransaction}
          netCashTransaction={netCashTransaction}
          setShowPrevious={setShowPrevious}
        />
      ),
      icon: <FontAwesomeIcon icon={faCreditCard} style={iconStyle(4)} />,
      visible: true
    },
    {
      key: '6',
      title: 'Confirmation',
      content: (
        <SubscriptionSuccess
          subscription={subscription}
          setSubscription={setSubscription}
          token={token}
          setShowNext={setShowNext}
          netCashTransaction={netCashTransaction}
          isStaffVitalLink={isStaffVitalLink}
        />
      ),
      icon: <FontAwesomeIcon icon={faCheckCircle} style={iconStyle(5)} />,
      visible: true
    }
  ]

  useEffect(() => {
    if (currentStep > 0) {
      setShowPrevious(true)
    } else {
      setShowPrevious(false)
    }
  }, [currentStep])

  return (
    <div className={deviceDetect().isMobile ? '' : 'meta-link-background'}>
      <ContainerCard
        title='Meta-Link'
        style={
          deviceDetect().isMobile
            ? {
                background: `linear-gradient(to right, ${token.colorPrimary}, ${token.colorSecondary})`,
                height: 'calc(100vh - 120px)',
                overflow: 'scroll'
              }
            : {}
        }
      >
        <CustomRow>
          <Col span={24}>
            <Row justify='center' style={{ marginBottom: 10 }}>
              <Col xs={24} sm={24} md={23} lg={23}>
                <Card
                  size='small'
                  style={{ backgroundColor: deviceDetect().isMobile ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.8)' }}
                >
                  {deviceDetect().isMobile ? (
                    <>
                      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                        {steps.map((step) => {
                          if (step.visible) {
                            return step.icon
                          } else {
                            return null
                          }
                        })}
                      </Space>
                    </>
                  ) : (
                    <Steps
                      size='small'
                      current={currentStep}
                      items={steps
                        .filter((s) => s.visible)
                        .map((step, index) => {
                          let tmpStep = { ...step }
                          tmpStep.icon = <></>
                          tmpStep.title = (
                            <div style={{ display: 'flex', alignItems: 'center', ...textStyle(index) }}>
                              {step.icon} {step.title}
                            </div>
                          )

                          return tmpStep
                        })}
                      type='navigation'
                    />
                  )}
                </Card>
              </Col>
            </Row>
            <Row justify='center' style={{ marginBottom: deviceDetect().isMobile ? 24 : 10 }}>
              <Col xs={24} sm={24} md={23} lg={23}>
                <Card
                  type='inner'
                  size='small'
                  style={{ backgroundColor: deviceDetect().isMobile ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.8)' }}
                >
                  <div>{steps.filter((s) => s.visible)[currentStep].content}</div>
                </Card>
              </Col>
            </Row>
            <Row
              justify='center'
              style={deviceDetect().isMobile ? { position: 'fixed', bottom: 0, left: 0, right: 0 } : {}}
            >
              <Col xs={24} sm={24} md={23} lg={23}>
                <Card
                  size='small'
                  headStyle={deviceDetect().isMobile ? { borderRadius: 0 } : {}}
                  style={
                    deviceDetect().isMobile
                      ? { backgroundColor: 'rgba(255,255,255,1)', borderRadius: 0 }
                      : { backgroundColor: 'rgba(255,255,255,0.8)' }
                  }
                >
                  <Row justify='space-between'>
                    <Col>
                      {showPrevious ? (
                        <Button danger onClick={() => prev()}>
                          <span>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                          </span>
                        </Button>
                      ) : undefined}
                    </Col>
                    <Col>
                      {showNext ? (
                        <Button disabled={!enableNext} type='primary' onClick={() => next()}>
                          <span>
                            Next <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                        </Button>
                      ) : undefined}
                    </Col>
                    {currentStepKey === '5' ? (
                      <Col>
                        <Button
                          // loading={loading}
                          type='primary'
                          size='large'
                          style={{ backgroundColor: token.colorSuccess }}
                          onClick={handlePay}
                        >
                          {loading ? 'Loading...' : netCashEnabled ? 'Pay' : 'Continue'}
                        </Button>
                      </Col>
                    ) : undefined}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </CustomRow>
      </ContainerCard>
      {isSub ? (
        <form
          action='https://paynow.netcash.co.za/site/paynow.aspx'
          method='post'
          name='payNowSubscriptionForm'
          target='_blank'
          ref={paymentSubFormRef}
          style={{ display: 'none' }}
        >
          <input id='m1' name='m1' type='hidden' value={state.auth.netCashConfig.service_key} />
          <input id='m2' name='m2' type='hidden' value={state.auth.netCashConfig.netcash_key} />
          {/* Extra */}
          <input id='m4' name='m4' type='hidden' value={netCashTransaction?._id} />
          <input id='m5' name='m5' type='hidden' value={dayjs(new Date()).format('YYYY-MM-DD HH:MM:SS')} />
          <input id='m6' name='m6' type='hidden' value={state.auth.agiliteUser._id} />
          {/* Unique Ref */}
          <input id='p2' name='p2' type='hidden' value={`${state.auth.agiliteUser._id}-${netCashTransaction?._id}`} />
          {/* Description Of Goods */}
          <input id='p3' name='p3' type='hidden' value='Meta-Link' />
          {/* Amount */}
          <input id='p4' name='p4' type='hidden' value={amount.toString()} />
          <input id='budget' name='Budget' type='hidden' value={'N'} />
          {/* Tokenize Credit Card */}
          <input id='m14' name='m14' type='hidden' value={'1'} />
          {/* Subscription indicator */}
          <input id='m16' name='m16' type='hidden' value={'1'} />
          {/* Number of cycles for a subscription payment */}
          <input id='m17' name='m17' type='hidden' value={'999'} />
          {/* Subscription Frequency */}
          <input id='m18' name='m18' type='hidden' value={'1'} />
          {/* Subscription Start Date */}
          <input id='m19' type='date' name='m19' value={dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD')} />
          {/* Recurring Amount */}
          <input id='m20' name='m20' type='hidden' value={subAmount.toString()} />
          <input type='submit' value='Confirm' />
        </form>
      ) : (
        <form
          action='https://paynow.netcash.co.za/site/paynow.aspx'
          method='post'
          name='payNowForm'
          target='_blank'
          ref={paymentFormRef}
          style={{ display: 'none' }}
        >
          <input id='m1' name='m1' type='hidden' value={state.auth.netCashConfig.service_key} />
          <input id='m2' name='m2' type='hidden' value={state.auth.netCashConfig.netcash_key} />
          <input id='p2' name='p2' type='hidden' value={`${state.auth.agiliteUser._id}-${netCashTransaction?._id}`} />
          <input id='p3' name='p3' type='hidden' value='Meta-Link' />
          <input id='p4' name='p4' type='hidden' value={amount.toString()} />
          {/* Extra */}
          <input id='m4' name='m4' type='hidden' value={netCashTransaction?._id} />
          <input id='m5' name='m5' type='hidden' value={dayjs(new Date()).format('YYYY-MM-DD HH:MM:SS')} />
          <input id='m6' name='m6' type='hidden' value={state.auth.agiliteUser._id} />
          <input type='submit' value='Confirm' />
        </form>
      )}
    </div>
  )
}

export default VitalLink
