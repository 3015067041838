import React from 'react'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomRow from '../../reusable-components/CustomRow'
import { Col } from 'antd'
import CustomButton from '../../reusable-components/CustomButton'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import CoreEnums from '../../../core/utils/enums'
import { generateContent } from '../../../setup'

const OccupationalHealthWrapper = () => {
  const dispatch = useDispatch()
  const openCompContr = () => {
    dispatch(coreReducer.actions.addTab(generateContent(CoreEnums.tabKeys.COMPANIES_CONTRACTORS)))
  }
  return (
    <ContainerCard title='Occupational Health'>
      <CustomRow>
        <Col span={24}>
          <CustomRow>
            <Col span={24}>
              <CustomButton
                onClick={() => {
                  openCompContr()
                }}
                text='Manage Companies/Contractors'
              />
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default OccupationalHealthWrapper
