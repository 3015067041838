import { useDispatch, useSelector } from 'react-redux'
import MedicalProfessionalDashboard from './medical-professional'
import { useState } from 'react'
import { message } from 'antd'
import { useEffect } from 'react'
import { convertDateTimeSAST, handleError } from '../../lib/utils'
import dayjs from 'dayjs'
import { countBookings } from '../../Admin/bookings/utils/utils'
import { getAppointments } from '../../Bookings/utils/utils'
import todaysPatientsReducer from './components/todays-patients/todays-patients-reducer'
import ReceptionDashboard from './reception-dashboard'
import { calculateMonthEnd, calculateMonthStart } from './lib/utils'
import CoreEnums from '../../../core/utils/enums'

const EmployeeDashboard = () => {
  const authState = useSelector((state) => state.auth)
  const todaysPatientsState = useSelector((state) => state.todaysPatients.data)
  const state = useSelector((state) => state)
  const dispatch = useDispatch()

  const employeeId = authState.agiliteUser._id
  const employeeRole = authState.agiliteUser.extraData.role.type
  const employeeLinkedClinics = authState.agiliteUser.extraData.clinics
  const employeeProfession = authState.agiliteUser.extraData.profession

  const [todaysBookingsCount, setTodaysBookingsCount] = useState(0)
  const [todaysPatientsLoading, setTodaysPatientsLoading] = useState(false)
  const [monthsBookingsCount, setMonthsBookingsCount] = useState(0)

  useEffect(() => {
    getTodaysPatients()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    countMonthsBookings()
    countOwnBookings()
    // eslint-disable-next-line
  }, [todaysPatientsState])

  const countOwnBookings = async () => {
    let bookingsCount = 0
    let qry = {}

    try {
      qry.clinicRef = { $in: authState.agiliteUser.extraData.clinics }
      qry.$nor = [
        { status: CoreEnums.bookingStatuses.completed },
        { wasNoShow: true },
        { status: CoreEnums.bookingStatuses.cancelled }
      ]
      if (employeeRole === 'medical_professional') {
        qry.$nor.push({ status: CoreEnums.bookingStatuses.billing })
        if (employeeProfession === 'doctor') {
          qry.medicalProfRef = state.auth.agiliteUser._id
        }
      }
      qry.bookingDate = qry.bookingDate = {
        $eq: convertDateTimeSAST(new Date(), true)
      }
      bookingsCount = await countBookings(qry)
    } catch (e) {
      message.error(handleError(e))
    }
    setTodaysBookingsCount(bookingsCount)
  }

  const countMonthsBookings = async () => {
    let bookingsCount = 0
    let qry = {}

    try {
      qry.$nor = [{ status: CoreEnums.bookingStatuses.cancelled }, { wasNoShow: true }]
      qry.clinicRef = { $in: employeeLinkedClinics }
      if (employeeRole === 'medical_professional') {
        if (employeeProfession === 'doctor') {
          qry.medicalProfRef = employeeId
        }
      }

      qry.bookingDate = qry.bookingDate = {
        $gte: calculateMonthStart(),
        $lte: calculateMonthEnd()
      }
      bookingsCount = await countBookings(qry)
    } catch (e) {
      message.error(handleError(e))
    }
    setMonthsBookingsCount(bookingsCount)
  }

  // TODO: Need to figure out a way to do this efficiently
  // Count the number of patients that have been seen by the doctor
  // const countPatients = async () => {}

  const getTodaysPatients = async () => {
    let tmpBookings = []
    let qry = {}

    setTodaysPatientsLoading(true)
    try {
      qry.$nor = [
        { status: CoreEnums.bookingStatuses.completed },
        { status: CoreEnums.bookingStatuses.cancelled },
        { wasNoShow: true }
      ]
      qry.clinicRef = { $in: employeeLinkedClinics }
      qry.bookingDate = {
        $eq: convertDateTimeSAST(new Date(), true)
      }
      if (employeeRole === 'medical_professional') {
        qry.$nor.push({ status: CoreEnums.bookingStatuses.billing })
        if (employeeProfession === 'doctor') {
          qry.medicalProfRef = employeeId
        }
      }

      tmpBookings = await getAppointments(state, qry)
      tmpBookings.sort(
        (a, b) =>
          dayjs().set('hour', a.startTime.slice(0, 2)).set('minute', a.startTime.slice(-2)) -
          dayjs().set('hour', b.startTime.slice(0, 2)).set('minute', b.startTime.slice(-2))
      )
      dispatch(todaysPatientsReducer.actions.setRecords(tmpBookings))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setTodaysPatientsLoading(false)
  }
  switch (authState.agiliteUser.extraData.role.type) {
    case 'medical_professional':
      return (
        <MedicalProfessionalDashboard
          todaysBookingsCount={todaysBookingsCount}
          getTodaysPatients={getTodaysPatients}
          todaysPatientsLoading={todaysPatientsLoading}
          setTodaysPatientsLoading={setTodaysPatientsLoading}
          monthsBookingsCount={monthsBookingsCount}
        />
      )
    case 'reception':
      return (
        <ReceptionDashboard
          getTodaysPatients={getTodaysPatients}
          todaysBookingsCount={todaysBookingsCount}
          todaysPatientsLoading={todaysPatientsLoading}
          monthsBookingsCount={monthsBookingsCount}
          setTodaysPatientsLoading={setTodaysPatientsLoading}
        />
      )
    default:
      return (
        <MedicalProfessionalDashboard
          getTodaysPatients={getTodaysPatients}
          todaysBookingsCount={todaysBookingsCount}
          todaysPatientsLoading={todaysPatientsLoading}
          monthsBookingsCount={monthsBookingsCount}
          setTodaysPatientsLoading={setTodaysPatientsLoading}
        />
      )
  }
}

export default EmployeeDashboard
