import Agilite from 'agilite'
import { handleError } from '../../../lib/utils'
import MedicalHistoryEnums from './enums'
import store from '../../../../store'
import Templates from './templates'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getPatient = (userRef) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const qry = { userRef: userRef || store.getState().auth.agiliteUser?._id }
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )

        if (response.data.length > 0) {
          resolve(response.data[0])
        } else {
          response = await createPatient({
            userRef: store.getState().auth.agiliteUser._id,
            medicalHistory: Templates.dataTemplate
          })
          resolve(response)
        }
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const createPatient = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const getMedHistProgress = (data) => {
  if (data) {
    const medHistSections = [
      { key: 'allergies' },
      { key: 'chronicIllnesses' },
      { key: 'meds' },
      { key: 'covidInfo' },
      { key: 'familyHistory' },
      { key: 'lifestyle' },
      { key: 'mentalHealth' },
      { key: 'surgicalHistory' }
    ]
    const totalFields = medHistSections.length
    let completedFields = 0

    medHistSections.forEach((section) => {
      const { key } = section
      if (data[key] && data[key].updatedAt) {
        completedFields++
      }
    })

    const completionPercentage = (completedFields / totalFields) * 100
    return completionPercentage.toFixed(0) // Round to 2 decimal places
  } else {
    return 0
  }
}

export const updatePatient = (data, filter) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE,
          {
            filter: JSON.stringify(filter),
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}
