import ObjectId from 'bson-objectid'
import { Button, Checkbox, Col, Collapse, Input, Modal, Row, Space, Switch, Table, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import BillingTool from './billing-tool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines, faRefresh, faSearch, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import './billing.css'
import { isReception } from '../../../lib/profile-utils'
import CoreEnums from '../../../../core/utils/enums'
import { handleError } from '../../../lib/utils'
import { createBillingRecord } from '../../utils/utils'
import { useSelector } from 'react-redux'

const Billing = ({
  billing,
  billingItems,
  setBillingItems,
  bookingData,
  setBookingData,
  handleGetBillingRecords,
  billingRecordsLoading,
  setBillingSyncRequired,
  billingWebSocket
}) => {
  const authState = useSelector((state) => state.auth)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedMacros, setSelectedMacros] = useState([])
  const [readOnly, setReadOnly] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const { token } = theme.useToken()

  useEffect(() => {
    if (isReception()) {
      if (bookingData?.status !== CoreEnums.bookingStatuses.billing) {
        setReadOnly(true)
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleCheckboxChange = (checked, macro) => {
    const tmpSelectedMacros = [...selectedMacros]
    const tmpIndex = tmpSelectedMacros.findIndex((item) => item.name === macro.name)

    if (tmpIndex === -1 && checked) {
      tmpSelectedMacros.push(macro)
    } else if (tmpIndex !== -1 && !checked) {
      tmpSelectedMacros.splice(tmpIndex, 1)
    }

    setSelectedMacros(tmpSelectedMacros)
  }

  const handleAddMacro = () => {
    const tmpBillingItems = [...billingItems]
    const tmpLastItem = tmpBillingItems[tmpBillingItems.length - 1]
    const entries = []

    try {
      tmpBillingItems.pop()

      selectedMacros.forEach((macro) => {
        macro.items.forEach((item) => {
          const record = {
            _id: ObjectId().toHexString(),
            bookingRef: bookingData._id,
            code: item.code,
            name: item.name,
            qty: item.qty,
            type: item.type
          }

          tmpBillingItems.push(record)
          entries.push(record)
        })
      })

      if (tmpLastItem) {
        tmpBillingItems.push(tmpLastItem)
      }

      setBillingItems(tmpBillingItems)
      setSelectedMacros([])
      setModalOpen(false)

      // No need to await this function
      createBillingRecord(entries)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const filteredMacros = () => {
    let tmpMacros = [...bookingData?.clinicRecord?.billingMacros]

    if (filterQuery) {
      tmpMacros = tmpMacros.filter((object) => object.name?.toLowerCase().search(filterQuery?.toLowerCase()) > -1)
    }

    return tmpMacros.map((i) => i.name)
  }

  return (
    <div>
      <CustomRow>
        <Col span={24}>
          <Row justify='space-between'>
            <Col>
              <Button
                disabled={readOnly}
                type='primary'
                style={{ marginBottom: 10, float: 'left' }}
                onClick={() => setModalOpen(true)}
              >
                <Space>
                  <FontAwesomeIcon icon={faFileLines} />
                  <span>Billing Macros</span>
                </Space>
              </Button>
            </Col>
            {authState.agiliteUser._id === bookingData.medicalProfRef ? (
              <Col>
                <Space>
                  <span>No Charge</span>
                  <Switch
                    value={bookingData.noCharge}
                    onChange={(value) => setBookingData({ ...bookingData, noCharge: value })}
                    unCheckedChildren='No'
                    checkedChildren='Yes'
                  />
                </Space>
              </Col>
            ) : null}
            <Col>
              <Button
                disabled={readOnly}
                style={{ marginBottom: 10, float: 'right' }}
                onClick={() => {
                  handleGetBillingRecords()
                  setBillingSyncRequired(false)
                }}
              >
                <Space>
                  <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
                  <span>Refresh</span>
                </Space>
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <BillingTool
            billing={billing}
            itemSelection={billingItems}
            setItemSelection={setBillingItems}
            readOnly={readOnly}
            bookingData={bookingData}
            isMacroForm={false}
            billingRecordsLoading={billingRecordsLoading}
            billingWebSocket={billingWebSocket}
          />
        </Col>
        <Modal
          title='Billing Macros'
          destroyOnClose
          closeIcon={<FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />}
          closable={true}
          open={modalOpen}
          okText='Add'
          onOk={() => {
            setFilterQuery('')
            handleAddMacro()
          }}
          onCancel={() => {
            setFilterQuery('')
            setModalOpen(false)
          }}
          className='responsive-modal-macros'
        >
          <Row>
            <Col span={24} style={{ marginBottom: 12 }}>
              <Space wrap style={{ width: '100%' }}>
                <p>Search:</p>
                <Input
                  placeholder='Type to search...'
                  value={filterQuery}
                  style={{ width: 350, maxWidth: '100%' }}
                  onChange={(e) => {
                    setFilterQuery(e.target.value)
                  }}
                  addonAfter={<FontAwesomeIcon icon={faSearch} />}
                />
                <Button
                  style={{ color: token.colorError }}
                  onClick={() => {
                    setFilterQuery('')
                  }}
                >
                  Clear
                </Button>
              </Space>
            </Col>
            <Col span={24}>
              <Collapse>
                {/* eslint-disable-next-line */}
                {bookingData?.clinicRecord?.billingMacros.map((macro, index) => {
                  if (macro.isActive) {
                    return (
                      <Collapse.Panel
                        style={{ display: filteredMacros().includes(macro.name) ? '' : 'none' }}
                        header={macro.name}
                        key={index}
                        extra={
                          <Checkbox
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                            onChange={(e) => {
                              handleCheckboxChange(e.target.checked, macro)
                            }}
                          />
                        }
                      >
                        <Table
                          dataSource={macro.items}
                          size='small'
                          bordered={true}
                          pagination={false}
                          columns={[
                            { title: 'Type', dataIndex: 'type', key: 'type' },
                            { title: 'Code', dataIndex: 'code', key: 'code' },
                            { title: 'Name', dataIndex: 'name', key: 'name' },
                            { title: 'QTY', dataIndex: 'qty', key: 'qty' }
                          ]}
                        />
                      </Collapse.Panel>
                    )
                  }
                })}
              </Collapse>
            </Col>
          </Row>
        </Modal>
      </CustomRow>
    </div>
  )
}

export default Billing
