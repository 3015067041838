import { Col, Row, Space, Spin, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGauge } from '@fortawesome/free-solid-svg-icons'
import { getBloodPressureStatus } from '../utils'
import GraphGraphic from '../../../../assets/svg/Group 30.svg'

const BloodPressureSimple = ({ data, loading }) => {
  const [bp, setBp] = useState({ diastolic: 0, systolic: 0 })
  const cardStyle = {
    borderRadius: 12,
    boxShadow: '0 0 30px 0 #ECEDEE',
    padding: 12,
    minHeight: '100%'
  }
  const colorPrimary = '#015EAC'
  const colorSecondary = 'rgba(1, 94, 172, 0.15)'
  const iconStyle = {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colorSecondary,
    borderRadius: 10
  }

  useEffect(() => {
    if (data?.examinationData?.checkinData?.bloodPressure?.diastolic) {
      setBp({
        diastolic: data?.examinationData?.checkinData?.bloodPressure.diastolic,
        systolic: data?.examinationData?.checkinData?.bloodPressure.systolic
      })
    }
  }, [data])

  return (
    <Row style={cardStyle} gutter={[0, 10]}>
      <Col span={24}>
        <Space>
          <div style={iconStyle}>
            <FontAwesomeIcon size='2x' color={colorPrimary} icon={faGauge} />
          </div>
          <span>
            Blood <br />
            Pressure
          </span>
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          {loading ? (
            <Spin spinning />
          ) : (
            <div style={{ fontSize: 28, display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 8 }}>
              {bp?.systolic ? bp.systolic : 0}
              <span style={{ color: '#A1A6AC', fontSize: 20 }}>/ {bp?.diastolic ? bp.diastolic : 0}</span>{' '}
            </div>
          )}{' '}
          <div style={{ color: '#A1A6AC' }}>mmhg</div>
        </Space>
      </Col>
      <Col span={24}>
        {loading ? undefined : (
          <Tag color={colorSecondary} style={{ color: 'black' }}>
            {data?.examinationData?.checkinData?.bloodPressure?.diastolic
              ? getBloodPressureStatus('label', [bp.systolic, bp.diastolic])
              : 'No Readings'}
          </Tag>
        )}
      </Col>
      <Col span={24}>
        <img src={GraphGraphic} style={{ width: '100%' }} alt='Graphic' />
        {/* <SimpleAreaChartExample data={dummyData} colorPrimary={colorPrimary} colorSecondary={colorSecondary} /> */}
      </Col>
    </Row>
  )
}

export default BloodPressureSimple
