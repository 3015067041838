import React, { useEffect, useState } from 'react'
import { Col, Row, Button, message, Input, Spin, Modal, theme, Form, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getRedirectResult, GoogleAuthProvider, sendSignInLinkToEmail, signInWithRedirect } from 'firebase/auth'
import { useSelector } from 'react-redux'

// Utils
import AuthEnums from '../utils/enums'
import { firebaseAuth } from '../../../index'
import { findAgiliteUser } from '../utils/utils'

// Components
import PhoneSignUpForm from './FormComponents/phoneSignUpForm'
import { handleError } from '../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheckCircle, faSignIn } from '@fortawesome/free-solid-svg-icons'
import { GoogleOutlined, MailOutlined, MailTwoTone, MobileOutlined } from '@ant-design/icons'
import { deviceDetect } from 'react-device-detect'
import CustomRow from '../../reusable-components/CustomRow'
import CoreEnums from '../../../core/utils/enums'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const Login = ({ isLogin, pathArray, setPathArray }) => {
  const navigate = useNavigate()
  const [facet, setFacet] = useState('options')
  const coreState = useSelector((state) => state.core)
  const [userInputValue, setUserInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [forgotModalOpen, setForgotModalOpen] = useState(false)
  const [resendTimeout, setResendTimeout] = useState(0)
  const [id, setId] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [facet])
  useEffect(() => {
    let result = null

    async function init() {
      // Check if an auth provider redirect occurred
      setLoading(true)
      result = await getRedirectResult(firebaseAuth)
      setLoading(false)

      // If redirecting back to login or register, reset to Home Page
      if (
        result?.operationType &&
        [AuthEnums.routes.REGISTER, AuthEnums.routes.LOGIN].includes(window.location.pathname.toLowerCase())
      ) {
        return navigate(AuthEnums.routes.ROOT)
      }
    }

    init()
    // eslint-disable-next-line
  }, [])

  const handleLoginEmailLink = async (email) => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true
    }
    let agiliteUser = null

    try {
      setLoading(true)

      if (email) {
        agiliteUser = await findAgiliteUser({ email })
      } else {
        agiliteUser = await findAgiliteUser({ email: userInputValue })
      }

      if (agiliteUser) {
        if (
          agiliteUser.extraData.role.type !== CoreEnums.userRoles.PATIENT &&
          agiliteUser.extraData.role.type !== CoreEnums.userRoles.SUPERADMIN &&
          !agiliteUser.extraData.entities.includes(coreState.entity._id)
        ) {
          setLoading(false)
          throw new Error(CoreEnums.errorMessages.ENTITY_NOT_AUTH)
        }
      }

      await sendSignInLinkToEmail(firebaseAuth, email ? email : userInputValue, actionCodeSettings)
      setLoading(false)
      window.localStorage.setItem(AuthEnums.localStorage.EMAIL_FOR_SIGN_IN, userInputValue)
      setFacet(AuthEnums.facets.EMAIL_LINK_SUCCESS)
      message.success('Email link sent successfully')
      setResendTimeout(60)
    } catch (e) {
      setLoading(false)
      message.error(handleError(e, true))
    }
  }

  const handleLoginGoogle = async () => {
    let provider = null

    try {
      // First Set Provider
      provider = new GoogleAuthProvider()
      setLoading(true)
      await signInWithRedirect(firebaseAuth, provider)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      message.error(handleError(e, true))
    }
  }

  // const generateCardTitle = () => {
  //   const type = isLogin ? 'Sign In' : 'Register'

  //   if (facet === AuthEnums.facets.OPTIONS) {
  //     return <h3>{type}</h3>
  //   } else {
  //     switch (facet) {
  //       case AuthEnums.facets.EMAIL_LINK:
  //       case AuthEnums.facets.EMAIL_LINK_SUCCESS:
  //         return <>{type} With Email</>
  //       case AuthEnums.facets.PHONE:
  //       case AuthEnums.facets.CODE_CONFIRM:
  //         return <>{type} With Cellphone</>
  //     }
  //   }
  // }

  const handleForgotAction = async () => {
    let agiliteUser = null

    setLoading(true)
    setForgotModalOpen(false)

    try {
      agiliteUser = await findAgiliteUser({ idNo: id })

      if (agiliteUser && agiliteUser.email) {
        await handleLoginEmailLink(agiliteUser.email)
      } else {
        message.error('No account found based on ID No provided')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setId('')
    setLoading(false)
  }

  const { token } = theme.useToken()

  const handleLogin = () => {
    if (pathArray.length > 0) {
      navigate(`/${pathArray[0]}${AuthEnums.routes.LOGIN}`, { replace: true })
    } else {
      navigate(`${AuthEnums.routes.LOGIN}`)
    }

    setPathArray(window.location.pathname.split('/').filter((p) => p !== '') || [])
  }

  const handleSignup = () => {
    if (pathArray.length > 0) {
      navigate(`/${pathArray[0]}${AuthEnums.routes.REGISTER}`, { replace: true })
    } else {
      navigate(`${AuthEnums.routes.REGISTER}`)
    }

    setPathArray(window.location.pathname.split('/').filter((p) => p !== '') || [])
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendTimeout > 0) {
        setResendTimeout(resendTimeout - 1)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [resendTimeout])
  return (
    <CustomRow justify='center' style={{ marginTop: 10 }}>
      <Col
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          borderRadius: deviceDetect().isMobile ? 12 : 30,
          background: deviceDetect().isBrowser ? 'white' : 'rgba(255,255,255,0.9)',
          overflow: 'hidden',
          width: facet === AuthEnums.facets.PHONE ? 900 : 600,
          maxWidth: '100%',
          marginRight: deviceDetect().isBrowser ? 80 : 0
        }}
      >
        {coreState?.entity ? (
          <>
            {facet === AuthEnums.facets.OPTIONS ? (
              <>
                <center>
                  <img src={coreState.entity.entityIcon} alt='meta-logo' style={{ width: 100 }} />
                  <h1>{coreState.entity.name.toUpperCase()} PORTAL</h1>
                  <h2 style={{ marginBottom: 0, marginTop: 40 }}> {isLogin ? ' SIGN-IN' : 'REGISTER'}</h2>
                </center>
                {!loading ? (
                  <CustomRow justify='center' style={{ marginTop: 40 }}>
                    {isLogin ? (
                      <Col>
                        <span
                          onClick={() => {
                            handleSignup()
                          }}
                        >
                          Don't have an account yet?
                          <b style={{ cursor: 'pointer', color: token.colorSecondary }}> Register here</b>
                        </span>
                      </Col>
                    ) : (
                      <Col>
                        <span
                          onClick={() => {
                            handleLogin()
                          }}
                        >
                          Already have an account?
                          <b style={{ cursor: 'pointer', color: token.colorSecondary }}> Sign in here</b>
                        </span>
                      </Col>
                    )}
                  </CustomRow>
                ) : null}

                <CustomRow justify='space-around' style={{ marginTop: 40 }}>
                  <Col span={24}>
                    <center>
                      <button
                        className='sign-register-button'
                        disabled={loading}
                        onClick={() => handleLoginGoogle()}
                        style={loading ? {} : { background: token.colorPrimary }}
                      >
                        <div>
                          <GoogleOutlined style={{ fontSize: 20 }} />
                        </div>
                        <span>{isLogin ? 'Sign In' : 'Register'} with Google</span>
                      </button>
                    </center>
                  </Col>
                  <Col span={24}>
                    <center>
                      <button
                        className='sign-register-button'
                        disabled={loading}
                        onClick={() => setFacet(AuthEnums.facets.EMAIL_LINK)}
                        style={loading ? {} : { background: token.colorPrimary }}
                      >
                        <div>
                          <MailOutlined style={{ fontSize: 20 }} />
                        </div>
                        <span>{isLogin ? 'Sign In' : 'Register'} with Email Link</span>
                      </button>
                    </center>
                  </Col>
                  <Col span={24}>
                    <center>
                      <button
                        type='primary'
                        className='sign-register-button'
                        disabled={loading}
                        onClick={() => setFacet(AuthEnums.facets.PHONE)}
                        style={loading ? {} : { background: token.colorSecondary }}
                      >
                        <div>
                          <MobileOutlined style={{ fontSize: 20 }} />
                        </div>
                        <span>{isLogin ? 'Sign In' : 'Register'} with Cellphone</span>
                      </button>
                    </center>
                  </Col>
                </CustomRow>
                <CustomRow style={{ marginTop: 40 }}>
                  <Col span={24}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>
                        <p style={{ marginBottom: 8 }}>
                          <FontAwesomeIcon color={token.colorSuccess} icon={faCheckCircle} /> Innovative Technology
                        </p>
                        <p style={{ marginBottom: 8 }}>
                          <FontAwesomeIcon color={token.colorSuccess} icon={faCheckCircle} /> Comprehensive Care
                        </p>
                        <p style={{ marginBottom: 8 }}>
                          <FontAwesomeIcon color={token.colorSuccess} icon={faCheckCircle} /> Immediate Results
                        </p>
                      </div>
                    </div>
                  </Col>
                </CustomRow>
              </>
            ) : undefined}
            {facet === AuthEnums.facets.EMAIL_LINK ? (
              <CustomRow justify='center'>
                <Col span={24}>
                  {' '}
                  <center style={{ marginBottom: 20 }}>
                    <MailTwoTone style={{ fontSize: 50 }} />
                  </center>
                </Col>
                <Col span={24}>
                  {' '}
                  <center>
                    <b style={{ marginBottom: 0 }}>EMAIL LINK {isLogin ? ' SIGN-IN' : 'REGISTRATION'} </b>
                  </center>
                </Col>
                <Col span={24}>
                  <center>
                    A link will be sent to your email's inbox.
                    <br />
                    {isLogin
                      ? `Clicking on the link will automatically sign you into ${coreState.entity.name}`
                      : `Clicking on the link will automatically sign you into ${coreState.entity.name} to complete your registration`}
                    .
                  </center>
                </Col>
                <Col span={24}>
                  <center>
                    <Form
                      onFinish={async () => {
                        handleLoginEmailLink()
                      }}
                    >
                      <Form.Item name='email' rules={[{ required: true, message: 'Please provide an Email Address' }]}>
                        <Input
                          style={{ width: 300 }}
                          placeholder='e.g. john.doe@acme.com'
                          maxLength={70}
                          type='email'
                          onChange={(e) => setUserInputValue(e.target.value)}
                        />
                      </Form.Item>
                      <Row justify='center' style={{ marginTop: 24 }} gutter={12}>
                        <Col>
                          <Button
                            disabled={loading}
                            onClick={() => {
                              setFacet('options')
                            }}
                            type='primary'
                            style={{ backgroundColor: token.colorSecondary }}
                          >
                            <Space>
                              <FontAwesomeIcon icon={faArrowLeft} />
                              <span>Back</span>
                            </Space>
                          </Button>
                        </Col>
                        <Col>
                          <Button htmlType='submit' disabled={loading} type='primary'>
                            <Space>
                              <span> {isLogin ? 'Sign In' : 'Register'}</span>
                              <FontAwesomeIcon icon={faSignIn} />
                            </Space>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </center>
                </Col>
                <Col span={24}>
                  {isLogin ? (
                    <Row justify='center'>
                      <Col>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          onClick={() => {
                            setForgotModalOpen(true)
                          }}
                          style={{ color: token.colorPrimary }}
                        >
                          Forgot Email Address?
                        </a>
                      </Col>
                    </Row>
                  ) : undefined}
                </Col>
              </CustomRow>
            ) : undefined}
            {facet === AuthEnums.facets.PHONE ? (
              <Row justify='center'>
                <Col span={24}>
                  <PhoneSignUpForm setFacet={setFacet} isLogin={isLogin} />
                </Col>
              </Row>
            ) : undefined}
            {facet === AuthEnums.facets.EMAIL_LINK_SUCCESS ? (
              <Row justify='center'>
                <Col span={24}>
                  <CustomRow justify='center'>
                    <Col span={24}>
                      <center>
                        <h3>SIGN-IN LINK SENT</h3>
                      </center>
                    </Col>
                    <Col span={24}>
                      <center>
                        <p>
                          Sent To: <b>{userInputValue}</b>
                        </p>
                      </center>
                    </Col>
                    <Col span={24}>
                      <center>
                        <p>
                          You should receive an email shortly containing a link that will automatically sign you in.
                        </p>
                      </center>
                    </Col>
                    <Col span={24}>
                      <CustomRow justify='center' gutter={12}>
                        <Col>
                          <Button
                            disabled={loading}
                            onClick={() => {
                              setFacet('options')
                            }}
                            type='primary'
                            style={{ backgroundColor: token.colorSecondary }}
                          >
                            Back to Home
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            disabled={loading || resendTimeout > 0}
                            type='primary'
                            onClick={() => handleLoginEmailLink()}
                          >
                            Resend Email Link {resendTimeout !== 0 ? `(${resendTimeout})` : null}
                          </Button>
                        </Col>
                      </CustomRow>
                    </Col>
                  </CustomRow>
                </Col>
              </Row>
            ) : undefined}
            {loading ? (
              <Row justify='center' style={{ marginTop: 20 }}>
                <Col>
                  <Spin size='large' />
                </Col>
              </Row>
            ) : undefined}
          </>
        ) : (
          <AgiliteSkeleton skActive />
        )}
      </Col>
      <Modal
        title='Requesting Email Address'
        open={forgotModalOpen}
        closable={false}
        okText='Submit'
        cancelButtonProps={{ danger: true }}
        onOk={() => handleForgotAction()}
        onCancel={() => {
          setForgotModalOpen(false)
          setId('')
        }}
      >
        Please enter your ID/Passport Number
        <Input
          value={id}
          maxLength={35}
          onChange={(e) => {
            if (/[^A-Za-z0-9]+/g.test(e.target.value) === false) {
              setId(e.target.value.toUpperCase())
            }
          }}
        />
      </Modal>
    </CustomRow>
  )
}

export default Login
