import React, { useCallback, useState } from 'react'
import { Row, Col, Form, theme, message, Button, Table, Space, Modal, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import coreReducer from '../../../../../core/utils/reducer'
import { ContainerCard } from '../../../../reusable-components/AgiliteCards'
import { generateFormItem, handleError } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import CoreEnums from '../../../../../core/utils/enums'
import { createCompContr, updateCompContr } from '../utils/utils'
import CompContrTemplates from '../utils/templates'
import { useEffect } from 'react'
import CustomButton from '../../../../reusable-components/CustomButton'
import { debounce } from 'lodash'
import { readPatients } from '../../../patients/utils/utils'

const CompContrForm = ({ recordId, isNewRecord, refreshView }) => {
  const dispatch = useDispatch()
  const compContrState = useSelector((state) => state.compContr.data)
  const [recordData, setRecordData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [linkedUsers, setLinkedUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [linkingPatients, setLinkingPatients] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [searching, setSearching] = useState(false)
  const [selectedPatients, setSelectedPatients] = useState([])
  const [linking, setLinking] = useState(false)

  const [compContrForm] = Form.useForm()
  const { token } = theme.useToken()

  useEffect(() => {
    if (!isNewRecord) {
      handleSetRecordData()
    }
    // eslint-disable-next-line
  }, [compContrState])

  useEffect(() => {
    if (!isNewRecord && recordData) {
      handleGetLinkedUsers()
    }
    // eslint-disable-next-line
  }, [recordData])

  const handleSetRecordData = () => {
    const tmpRecordData = JSON.parse(JSON.stringify(compContrState.find((i) => i._id === recordId)))
    setRecordData(tmpRecordData)
  }

  const handleGetLinkedUsers = async () => {
    let tmpUsers = []
    let filter = {}
    try {
      filter = { _id: { $in: recordData.linkedUsers } }
      tmpUsers = await readPatients(filter)
      setLinkedUsers(tmpUsers)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoadingUsers(false)
  }

  useEffect(() => {
    if (recordData) {
      handleFetchUserList()
    }

    // eslint-disable-next-line
  }, [searchQuery])

  const handleFetchUserList = async () => {
    let tmpUsers = []
    let qry = {}
    setSearching(true)
    try {
      qry = { 'extraData.role.type': 'patient', _id: { $nin: recordData.linkedUsers } }
      if (searchQuery) {
        qry = {
          ...qry,
          $or: [
            {
              $expr: {
                $regexMatch: {
                  input: { $concat: ['$firstName', ' ', '$lastName'] },
                  regex: searchQuery, //Your text search here
                  options: 'i'
                }
              }
            },
            { phoneNumber: { $regex: searchQuery, $options: 'i' } },
            { email: { $regex: searchQuery, $options: 'i' } },
            { physicalAddress: { $regex: searchQuery, $options: 'i' } },
            { idNo: { $regex: searchQuery, $options: 'i' } }
          ]
        }
        tmpUsers = await readPatients(qry)
      }
      setSearchResults(tmpUsers)
    } catch (e) {
      message.error(handleError(e))
    }
    setSearching(false)
  }

  const handleSearch = (query) => {
    setSearching(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )

  const handleSubmit = async () => {
    let record = compContrForm.getFieldsValue()
    try {
      setLoading(true)

      if (isNewRecord) {
        await createCompContr({ ...record })
      } else {
        await updateCompContr({ _id: recordData._id, ...record })
      }

      closeTab()
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const closeTab = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNewRecord
          ? `${CoreEnums.tabKeys.OCCUPATIONAL_HEALTH}_${CoreEnums.tabKeys.COMPANIES_CONTRACTORS}_new}`
          : `${CoreEnums.tabKeys.OCCUPATIONAL_HEALTH}_${CoreEnums.tabKeys.COMPANIES_CONTRACTORS}_${recordData._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const handleLinkSelectedPatients = async () => {
    let tmpSelectedPatients = JSON.parse(JSON.stringify(selectedPatients))
    let tmpLinkedUsers = JSON.parse(JSON.stringify(linkedUsers))
    setLinking(true)
    try {
      if (selectedPatients.length < 1) {
        throw new Error('Select atleast one patient')
      }
      await updateCompContr({ ...recordData, linkedUsers: [...tmpLinkedUsers, ...tmpSelectedPatients] })
      refreshView()
      setSelectedPatients([])
      setLinkingPatients(false)
      setSearchQuery('')
      setSearchResults([])
    } catch (e) {
      message.error(handleError(e))
    }
    setLinking(false)
  }

  const sectionHeadingStyle = {
    padding: 6,
    borderRadius: 6,
    background: token.colorSecondary,
    color: '#ffffff',
    textAlign: 'center'
  }

  const handleUnlinkPatient = async (recordId) => {
    let tmpLinkedUsers = [...recordData.linkedUsers]
    let recordIndex = -1
    setLinking(true)
    try {
      recordIndex = tmpLinkedUsers.findIndex((i) => i === recordId)
      if (recordIndex === -1) throw new Error('Record could not be found')
      tmpLinkedUsers.splice(recordIndex, 1)
      await updateCompContr({ ...recordData, linkedUsers: tmpLinkedUsers })
      setRecordData({ ...recordData, linkedUsers: tmpLinkedUsers })
      message.success('Patient Successfully Unlinked')
      refreshView()
    } catch (e) {
      message.error(handleError(e))
    }
    setLinking(false)
  }

  return (
    <>
      <ContainerCard title={isNewRecord ? 'Register a New Clinic' : 'Viewing Clinic'}>
        <Row justify='center'>
          {recordData || isNewRecord ? (
            <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
              <CustomRow>
                <Col span={24}>
                  <h2 style={sectionHeadingStyle}>{isNewRecord ? 'New Clinic' : `${recordData.name}`}</h2>
                </Col>
                <Col span={24}>
                  <CustomRow className='basic-card'>
                    <Col span={24}>
                      <Form
                        onFinish={handleSubmit}
                        name='form'
                        form={compContrForm}
                        initialValues={{
                          ...CompContrTemplates.dataTemplate(),
                          ...recordData
                        }}
                        layout='vertical'
                      >
                        <CustomRow justify='center'>
                          <Col span={24}>
                            <Col>{generateFormItem(CompContrTemplates.dataModel.name, compContrForm)}</Col>
                          </Col>
                          <Col span={24}>
                            <CustomRow justify='center'>
                              <Col>
                                <Form.Item noStyle>
                                  <Button style={{ background: token.colorError, color: 'white' }} onClick={closeTab}>
                                    Cancel
                                  </Button>
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item noStyle>
                                  <Button
                                    style={{
                                      backgroundColor: token.colorSuccess,
                                      color: 'white',
                                      marginRight: 10
                                    }}
                                    htmlType='submit'
                                    loading={loading}
                                  >
                                    {isNewRecord ? 'Add Clinic' : 'Save Changes'}
                                  </Button>
                                </Form.Item>
                              </Col>
                            </CustomRow>
                          </Col>
                        </CustomRow>
                      </Form>
                    </Col>
                  </CustomRow>
                </Col>
                {!isNewRecord ? (
                  <Col span={24}>
                    <Table
                      title={() => (
                        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                          <p>Linked Patients</p>{' '}
                          <CustomButton
                            onClick={() => {
                              setLinkingPatients(true)
                            }}
                            type='primary'
                            size='small'
                            text='Link Patient'
                          />
                        </Space>
                      )}
                      loading={loadingUsers}
                      dataSource={linkedUsers}
                      columns={[
                        {
                          title: 'Patient',
                          render: (record) => {
                            return `${record.firstName} ${record.lastName}`
                          }
                        },
                        {
                          render: (record) => (
                            <Button
                              onClick={() => {
                                handleUnlinkPatient(record._id)
                              }}
                              loading={linking}
                            >
                              Unlink
                            </Button>
                          )
                        }
                      ]}
                    />
                  </Col>
                ) : undefined}
              </CustomRow>
            </Col>
          ) : undefined}
        </Row>
      </ContainerCard>
      <Modal
        destroyOnClose
        okText='Link Patients'
        open={linkingPatients}
        title={'Search for patients'}
        onCancel={() => {
          setSelectedPatients([])
          setLinkingPatients(false)
          setSearchQuery('')
          setSearchResults([])
        }}
        onOk={() => {
          handleLinkSelectedPatients()
        }}
        cancelButtonProps={{ disabled: linking }}
        okButtonProps={{ loading: linking }}
      >
        <Input
          style={{ marginBottom: 12 }}
          onChange={(e) => {
            handleSearch(e.target.value)
          }}
        />
        <Table
          rowKey={(record) => record._id}
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedPatients(selectedRows.map((i) => i._id))
            },
            selectedRowKeys: selectedPatients
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                let tmpSelectedPatients = [...selectedPatients]
                let recordIndex = tmpSelectedPatients.findIndex((i) => i === record._id)

                if (recordIndex === -1) {
                  tmpSelectedPatients.push(record._id)
                  setSelectedPatients(tmpSelectedPatients)
                } else {
                  tmpSelectedPatients.splice(recordIndex, 1)
                  setSelectedPatients(tmpSelectedPatients)
                }
              }
            }
          }}
          loading={searching}
          dataSource={searchResults}
          columns={[{ title: 'name', render: (record) => `${record.firstName} ${record.lastName}` }]}
        />
      </Modal>
    </>
  )
}

export default CompContrForm
