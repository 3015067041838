import Agilite from 'agilite'
import CoreEnums from '../../../core/utils/enums'
import { ExaminationEnums } from '../../Examination/examination-utils/examination-enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const createClinicDataRecords = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('clinical_data_records', 'create', {
          data: JSON.stringify(data)
        })

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const aggregateClinicalDataRecords = (pipeline) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_DATA_RECORDS,
          ExaminationEnums.routeKeys.AGGREGATE,
          {
            pipeline: JSON.stringify(pipeline)
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const bulkWriteClinicalEntries = (bulkWriteArray) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('clinical_data_records', 'bulk_write', {
          data: JSON.stringify(bulkWriteArray)
        })

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readClinicalEntries = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('clinical_data_records', 'read', {
          filter: JSON.stringify(filter)
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
