import Modal from 'antd/es/modal/Modal'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Col, DatePicker, Empty, Input, Radio, Space, Tag, message, theme } from 'antd'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { readAvailability } from '../../../Availability/components/utils/utils'
import { readSystemUsers } from '../../../Admin/system-users/utils/utils'
import { readBookings } from '../../utils/utils'
import { handleError } from '../../../lib/utils'
import { handleVerifyAvailability, isAlreadyBooked, willOverlap } from '../../utils/lib'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const NurseToDoctor = ({ modalOpen, setModalOpen, handleSave, setData, data, loading }) => {
  const state = useSelector((state) => state)
  const [services, setServices] = useState([])
  const [availabilityLoading, setAvailabilityLoading] = useState(false)
  const virtualServicesState = useSelector((state) => state.virtualServices.data)
  const servicesState = useSelector((state) => state.services.data)
  const [isVirtual, setIsVirtual] = useState(false)
  const [date, setDate] = useState(dayjs())
  const [availability, setAvailability] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [medProfSearchQuery, setMedProfSearchQuery] = useState('')
  const [availabilityEntry, setAvailabilityEntry] = useState({
    service: null,
    medProf: null,
    medProfName: null,
    startTime: null,
    endTime: null
  })
  const [showMore, setShowMore] = useState(false)
  const [verifying, setVerifying] = useState(false)

  useEffect(() => {
    setData({ ...data, isVirtualVisit: isVirtual })
    //eslint-disable-next-line
  }, [isVirtual])
  useEffect(() => {
    handleReadAvailability()
    //eslint-disable-next-line
  }, [isVirtual, modalOpen, date])

  const handleReadAvailability = async () => {
    // TODO: Confirm filter criteria
    // Fetch med profs from everywhere in South Africa?
    // Filter by entity.preferredEntities ?

    const services = isVirtual ? state.virtualServices.data : state.services.data
    const bookingDate = date.format('YYYY-MM-DD')

    let finalEntries = []
    let availabilityResponse = null
    let medProfs = null
    let entries = []
    let qry = {}

    setAvailabilityLoading(true)

    try {
      qry.profession = 'doctor'
      if (isVirtual) {
        qry = { ...qry, 'availability.date': bookingDate }
      }
      availabilityResponse = await readAvailability(qry)

      // `Buld availability entries
      if (availabilityResponse.length > 0) {
        availabilityResponse.forEach((item) => {
          item.availability.forEach((availability) => {
            if (availability.date === bookingDate) {
              availability.timeSlots.forEach((timeslot) => {
                if (timeslot.blocked === false) {
                  entries.push({
                    time: timeslot.time,
                    medProfRef: timeslot.linkedProfessional
                  })
                }
              })
            }
          })
        })
      }

      // Get med profs based on the availability entries using their uniqued IDs

      medProfs = await readSystemUsers(
        { 'extraData.profession': 'doctor', 'extraData.services': { $in: services.map((e) => e._id) } },
        null,
        null,
        '_id firstName lastName extraData',
        isVirtual ? true : false // fetch all doctors regardless of the entities they are linked to
      )

      for (const entry of entries) {
        const medProf = medProfs.find((item) => item._id === entry.medProfRef)

        if (medProf) {
          entry.medProfName = `${medProf.firstName} ${medProf.lastName}`
          entry.medProfRecord = medProf
        }
      }

      entries = entries.filter((item) => item.medProfRecord)

      if (!isVirtual) {
        for (const medProf of medProfs) {
          finalEntries.push({
            medProfRef: medProf._id,
            medProfName: medProf.firstName + ' ' + medProf.lastName,
            timeslots: [],
            services: services.filter((service) => medProf.extraData.services.includes(service._id))
          })
        }
      }

      // Group Results
      entries.forEach((entry) => {
        const found = finalEntries.find((item) => item.medProfRef === entry.medProfRef)
        if (!found) {
          finalEntries.push({
            medProfRef: entry.medProfRef,
            medProfName: entry.medProfName,
            timeslots: [entry.time],
            services: services.filter((service) => entry.medProfRecord.extraData.services.includes(service._id))
          })
        } else {
          const index = finalEntries.findIndex((item) => item.medProfRef === entry.medProfRef)

          if (index !== -1) {
            finalEntries[index].timeslots.push(entry.time)
          }
        }
      })

      // Remove already booked slots
      const bookings = await readBookings({
        bookingDate: { $gte: dayjs(date).format('YYYY-MM-DD') },
        medicalProfRef: { $in: finalEntries.map((e) => e.medProfRef) }
      })

      finalEntries.forEach((entry) => {
        let tmpBookings = []
        bookings.forEach((booking) => {
          if (booking.medicalProfRef === entry.medProfRef) {
            tmpBookings.push([booking.startTime, booking.endTime])
          }
        })

        for (let i = entry.timeslots.length - 1; i >= 0; i--) {
          const slot = entry.timeslots[i]

          if (slot < dayjs().format('HH:mm') && bookingDate === dayjs().format('YYYY-MM-DD')) {
            entry.timeslots.splice(i, 1)
          } else if (isAlreadyBooked(slot, tmpBookings) || willOverlap(slot, data, tmpBookings)) {
            entry.timeslots.splice(i, 1)
          }
        }

        entry.timeslots.splice(0, 1)
      })

      if (isVirtual) {
        // only filter out when its a virtual consult because in clinic they can still opt for "Now" if there is no availability set
        finalEntries = finalEntries.filter((item) => item.timeslots.length > 0)
      }
      finalEntries = finalEntries.sort((a, b) => {
        return a.timeslots[0] > b.timeslots[0] ? 1 : -1
      })

      setAvailability(finalEntries)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setAvailabilityLoading(false)
  }

  const { token } = theme.useToken()

  useEffect(() => {
    let tmpServices = []
    if (isVirtual) {
      tmpServices = virtualServicesState
    } else {
      tmpServices = servicesState
    }
    if (searchQuery) {
      tmpServices = tmpServices.filter((item) => {
        return item.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1
      })
    }
    setServices(tmpServices)
    // eslint-disable-next-line
  }, [availability, searchQuery])

  useEffect(() => {
    setData({
      ...data,
      consult: {
        startTime: availabilityEntry.startTime === 'Now' ? dayjs().format('HH:mm') : availabilityEntry.startTime,
        medProfRef: availabilityEntry.medProf,
        service: isVirtual
          ? virtualServicesState.find((i) => i._id === availabilityEntry.service)
          : servicesState.find((i) => i._id === availabilityEntry.service),
        date: date,
        isVirtualVisit: isVirtual
      }
    })
    // eslint-disable-next-line
  }, [availabilityEntry])

  return (
    <Modal
      title={
        !availabilityEntry.service ? (
          <center>
            <Radio.Group disabled={loading} value={isVirtual} onChange={(e) => setIsVirtual(e.target.value)}>
              <Radio.Button value={false}>Clinic Services</Radio.Button>
              <Radio.Button value={true}>Virtual Services</Radio.Button>
            </Radio.Group>
          </center>
        ) : (
          <h2 style={{ padding: 12, borderRadius: 12, background: token.colorPrimary, color: '#ffffff' }}>
            Availability
          </h2>
        )
      }
      closable={false}
      destroyOnClose
      maskClosable={false}
      okButtonProps={{
        disabled: !availabilityEntry.medProf || !availabilityEntry.startTime || !availabilityEntry.endTime,
        loading: loading || verifying
      }}
      open={modalOpen}
      okText={verifying ? 'Verifying...' : loading ? 'Loading...' : 'Confirm'}
      cancelText={!availabilityEntry.service ? 'Cancel' : 'Back'}
      onOk={async () => {
        let tmpBookingDetails = {}
        try {
          // Build booking details for verification
          tmpBookingDetails.bookingDate = dayjs(date)
          tmpBookingDetails.dateCreated = dayjs(date)
          tmpBookingDetails.medicalProf = { _id: availabilityEntry.medProf }
          tmpBookingDetails.service = availabilityEntry.service
          tmpBookingDetails.startTime = availabilityEntry.startTime

          await handleVerifyAvailability(tmpBookingDetails, setVerifying)
          await handleSave(true, {
            startTime: availabilityEntry.startTime,
            medProfRef: availabilityEntry.medProf,
            service: isVirtual
              ? virtualServicesState.find((i) => i._id === availabilityEntry.service)
              : servicesState.find((i) => i._id === availabilityEntry.service),
            date: date
          })
        } catch (e) {
          message.error(handleError(e, true))
        }
      }}
      onCancel={() => {
        if (!availabilityEntry.service) {
          setModalOpen(false)
          setSearchQuery('')
          setIsVirtual(false)
          setDate('')
          setServices([])
        } else {
          setAvailabilityEntry({ service: null, medProf: null, startTime: null, endTime: null })
          setShowMore({ enabled: false, index: null })
          setMedProfSearchQuery('')
        }
      }}
    >
      {!availabilityEntry.service ? (
        <CustomRow>
          <Col span={24}>
            <p>
              You have opted for a {isVirtual ? 'virtual consultation' : 'consultation'} for this patient. Please select
              the service you require.
            </p>
          </Col>
          {availabilityLoading ? (
            <Col span={24}>
              <AgiliteSkeleton skActive spinnerTip='Loading Availability...' />
            </Col>
          ) : (
            <Col span={24}>
              <div style={{ marginBottom: 12 }}>
                <DatePicker
                  value={date}
                  disabledDate={(current) => current && current < dayjs().startOf('day')}
                  onChange={(e) => {
                    setDate(dayjs(e))
                  }}
                  allowClear={false}
                />
              </div>
              <div style={{ marginBottom: 12 }}>
                <Input
                  suffix={<FontAwesomeIcon icon={faSearch} />}
                  placeholder='Search...'
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value)
                  }}
                />
              </div>
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                <h2
                  style={{
                    color: '#ffffff',
                    padding: 8,
                    borderRadius: 8,
                    background: token.colorSecondary,
                    width: '100%',
                    marginBottom: 12
                  }}
                >
                  Services:
                </h2>
                <div style={{ maxHeight: 500, overflow: 'scroll', width: '100%' }}>
                  <Space wrap direction='vertical' style={{ width: '100%', maxHeight: '60vh', overflow: 'scroll' }}>
                    {
                      <>
                        {services.length > 0 ? (
                          <>
                            {services.map((service) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      cursor: 'pointer',
                                      padding: 8,
                                      borderRadius: 8,
                                      border: '1px rgba(0,0,0,0.25) solid',
                                      width: '100%'
                                    }}
                                    onClick={() => {
                                      setAvailabilityEntry({
                                        ...availabilityEntry,
                                        service: service._id,
                                        serviceName: service.name
                                      })
                                    }}
                                  >
                                    {service.name}
                                  </div>
                                </>
                              )
                            })}
                          </>
                        ) : (
                          <>
                            <Empty description='No available services found' />
                          </>
                        )}
                      </>
                    }
                  </Space>
                </div>
              </div>
            </Col>
          )}
        </CustomRow>
      ) : (
        <CustomRow>
          {availabilityLoading ? (
            <Col span={24}>
              <AgiliteSkeleton skActive spinnerTip='Loading Availability...' />
            </Col>
          ) : (
            <>
              <Col span={24}>
                <Input
                  suffix={<FontAwesomeIcon icon={faSearch} />}
                  placeholder='Search...'
                  value={medProfSearchQuery}
                  onChange={(e) => {
                    setMedProfSearchQuery(e.target.value)
                  }}
                />
              </Col>
              {availability.filter((i) => {
                if (medProfSearchQuery) {
                  if (i.medProfName.toLowerCase().search(medProfSearchQuery.toLowerCase()) === -1) {
                    return false
                  }
                }
                let item = i.services.find((i) => i._id === availabilityEntry.service)

                if (item) {
                  return true
                } else {
                  return false
                }
              }).length === 0 ? (
                <CustomRow>
                  <Col span={24}>
                    <Empty
                      description={
                        <>
                          There are no available practitioners for <br /> {dayjs(date).format('DD MMMM YYYY')}
                        </>
                      }
                    />
                  </Col>
                </CustomRow>
              ) : (
                <>
                  {availability
                    .filter((i) => {
                      if (medProfSearchQuery) {
                        if (i.medProfName.toLowerCase().search(medProfSearchQuery.toLowerCase()) === -1) {
                          return false
                        }
                      }
                      let item = i.services.find((i) => i._id === availabilityEntry.service)

                      if (item) {
                        return true
                      } else {
                        return false
                      }
                    })
                    .sort((a, b) => {
                      return b.timeslots.length - a.timeslots.length
                    })
                    .map((item, index) => (
                      <>
                        <CustomRow gutter={[8, 8]}>
                          <Col span={24}>
                            <p
                              style={{
                                color: availabilityEntry.medProf === item.medProfRef ? '#ffffff' : '',
                                width: '100%',
                                padding: 8,
                                borderRadius: 8,
                                background: availabilityEntry.medProf === item.medProfRef ? token.colorSecondary : '',
                                border: '1px rgba(0,0,0,0.25) solid'
                              }}
                            >
                              {item.medProfName}
                            </p>
                          </Col>
                          <Col span={24}>
                            <Space wrap>
                              {!isVirtual ? (
                                <Tag
                                  style={{ cursor: 'pointer' }}
                                  color={
                                    item.medProfRef === availabilityEntry.medProf &&
                                    'Now' === availabilityEntry.startTime
                                      ? token.colorSecondary
                                      : undefined
                                  }
                                  onClick={() => {
                                    setAvailabilityEntry({
                                      ...availabilityEntry,
                                      medProf: item.medProfRef,
                                      medProfName: item.medProfName,
                                      startTime: 'Now',
                                      endTime: 'Now'
                                    })
                                  }}
                                >
                                  Now
                                </Tag>
                              ) : undefined}
                              {item.timeslots.map((slot, slotIndex) =>
                                (showMore.enabled && showMore.index === index) || slotIndex < 3 ? (
                                  <Tag
                                    style={{ cursor: 'pointer' }}
                                    color={
                                      item.medProfRef === availabilityEntry.medProf &&
                                      slot === availabilityEntry.startTime
                                        ? token.colorSecondary
                                        : undefined
                                    }
                                    onClick={() => {
                                      setAvailabilityEntry({
                                        ...availabilityEntry,
                                        medProf: item.medProfRef,
                                        medProfName: item.medProfName,
                                        startTime: slot,
                                        endTime: dayjs(slot, 'HH:mm')
                                          .add(
                                            services.find((i) => i._id === availabilityEntry.service).timeslotInterval,
                                            'minutes'
                                          )
                                          .format('HH:mm')
                                      })
                                    }}
                                  >
                                    {slot}
                                  </Tag>
                                ) : undefined
                              )}
                              {item.timeslots.length > 3 ? (
                                <Tag
                                  color={showMore.enabled && showMore.index === index ? 'red' : 'green'}
                                  onClick={() => {
                                    setShowMore(
                                      showMore.enabled && showMore.index === index
                                        ? { enabled: false, index: null }
                                        : { enabled: true, index }
                                    )
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {showMore.enabled && showMore.index === index ? 'Show Less' : 'Show More'}
                                </Tag>
                              ) : undefined}
                            </Space>
                          </Col>
                        </CustomRow>
                      </>
                    ))}
                </>
              )}
            </>
          )}
        </CustomRow>
      )}
    </Modal>
  )
}

export default NurseToDoctor
