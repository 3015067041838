import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popconfirm, Tooltip, theme } from 'antd'

const CustomButton = ({
  type,
  disabled,
  icon,
  iconPosition,
  text,
  onClick,
  style,
  loading,
  size,
  hasPopconfirm,
  popConfirmTitle,
  popConfirmDescription,
  tooltip
}) => {
  const { token } = theme.useToken()
  // DO NOT CHANGE THIS STYLE
  const buttonStyle = {}
  if (size !== 'small') {
    buttonStyle.padding = '16px 24px'
    buttonStyle.borderRadius = '8px'
    buttonStyle.border = 'none'

    buttonStyle.height = 'auto'
  }

  buttonStyle.display = 'flex'
  buttonStyle.flexWrap = 'wrap'
  buttonStyle.alignItems = 'center'
  buttonStyle.justifyContent = 'center'
  buttonStyle.gap = 8

  // TODO: setup these colours in the theme
  // Only a temporary solution

  switch (type) {
    case 'primary':
      buttonStyle.backgroundColor = token.colorPrimary
      buttonStyle.color = '#ffffff'
      break
    case 'secondary':
      buttonStyle.backgroundColor = token.colorSecondary
      buttonStyle.color = '#ffffff'
      break
    case 'accent':
      buttonStyle.backgroundColor = '#E1EFF2'
      buttonStyle.color = '#6B737F'
      break
    case 'danger':
      buttonStyle.backgroundColor = token.colorError
      buttonStyle.color = '#E1EFF2'
      break
    case 'transparent':
      buttonStyle.backgroundColor = 'transparent'
      buttonStyle.color = '#000000'
      break
    case 'warning':
      buttonStyle.backgroundColor = token.colorWarning
      buttonStyle.color = '#000000'

      break
    case 'success':
      buttonStyle.backgroundColor = token.colorSuccess
      buttonStyle.color = '#ffffff'
      break
  }

  if (disabled) {
    delete buttonStyle.backgroundColor
    delete buttonStyle.color
  }
  return (
    <>
      {!hasPopconfirm ? (
        <Tooltip title={tooltip}>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={() => {
              if (!loading && !disabled) {
                onClick()
              }
            }}
            className='custom-button'
            style={{ ...buttonStyle, ...style }}
          >
            {icon && iconPosition !== 'end' ? <FontAwesomeIcon icon={icon} /> : undefined} {text}{' '}
            {icon && iconPosition === 'end' ? <FontAwesomeIcon icon={icon} /> : undefined}
          </Button>
        </Tooltip>
      ) : (
        <Popconfirm
          title={popConfirmTitle || 'No title provided'}
          description={popConfirmDescription || 'No description provided'}
          onConfirm={() => {
            if (!loading && !disabled) {
              onClick()
            }
          }}
          okText='Yes'
          cancelText='No'
          okButtonProps={{ type: 'primary', style: { backgroundColor: token.colorSuccess } }}
          cancelButtonProps={{ danger: true }}
          disabled={disabled || loading}
        >
          <Tooltip title={tooltip}>
            <Button
              disabled={disabled}
              loading={loading}
              className='custom-button'
              style={{ ...buttonStyle, ...style }}
            >
              {icon && iconPosition !== 'end' ? <FontAwesomeIcon icon={icon} /> : undefined} {text}{' '}
              {icon && iconPosition === 'end' ? <FontAwesomeIcon icon={icon} /> : undefined}
            </Button>
          </Tooltip>
        </Popconfirm>
      )}
    </>
  )
}

export default CustomButton
