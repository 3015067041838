import { Button, Card, Col, Divider, Empty, Form, Row, Space, Tag, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { generateFormItem, handleError, hexToRGBA } from '../../lib/utils'
import { useSelector } from 'react-redux'
import CustomRow from '../../reusable-components/CustomRow'
import {
  createClinicalReportRecord,
  readClinicalEntries,
  readClinicalReportRecords,
  updateClinicalReportRecord
} from '../examination-utils/examination-lib'
import { readClinicDataPoints } from '../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import CustomButton from '../../reusable-components/CustomButton'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import debounce from 'lodash/debounce'
import { readClinicalReportTemplates } from '../../Super-Admin/Clinical Report Templates/utils/clinical-reporting-utils'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { generateClinicalReport } from '../../Admin/Reporting/reporting-utils'
import { readMedicalHistory } from '../../Admin/patients/utils/utils'
import { aggregateClinicalDataRecords } from '../../Super-Admin/Data Points/tmp-capture-utils'

const ClinicalReporting = ({ webSocket, userRef, bookingData, modalOpen, setModalOpen }) => {
  const authState = useSelector((state) => state.auth)
  const [template, setTemplate] = useState(null)
  const [templateData, setTemplateData] = useState(null)
  const [dataPoints, setDataPoints] = useState([])
  const [dataTemplates, setDataTemplates] = useState([])
  const [templateSelectionRequired, setTemplateSelectionRequired] = useState(false)
  const [loadingReferenceData, setLoadingReferenceData] = useState(false)
  const [referenceData, setReferenceData] = useState([])
  const [saving, setSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(true)
  const [loadingData, setLoadingData] = useState(false)
  const [checkingExisting, setCheckingExisting] = useState(false)
  const [creatingInitialTemplate, setCreatingInitialTemplate] = useState(false)
  const [initialFetchError, setInitialFetchError] = useState(false)
  const [saveError, setSaveError] = useState(false)
  const [templatesDataLoadError, setTemplatesDataLoadError] = useState(false)
  const [reportLoading, setReportLoading] = useState(false)

  const [clinicalReportingForm] = Form.useForm()
  const { token } = theme.useToken()

  useEffect(() => {
    if (templateData) {
      clinicalReportingForm.setFieldsValue(templateData?.data)
    }
    // eslint-disable-next-line
  }, [templateData])

  useEffect(() => {
    handleFetchData()
  }, [])

  useEffect(() => {
    if (template) {
      fetchLatestReferenceData()
    }
    // eslint-disable-next-line
  }, [template])

  useEffect(() => {
    handleDetermineInitialTemplate()
    // eslint-disable-next-line
  }, [dataPoints, dataTemplates])

  const fetchLatestReferenceData = async () => {
    let tmpData = []
    setLoadingReferenceData(true)
    try {
      tmpData = await readClinicalEntries({ key: { $in: template?.references || [] } })
      setReferenceData(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }

    setLoadingReferenceData(false)
  }

  const handleFetchData = async () => {
    // Fetch templates and data points
    setTemplatesDataLoadError(false)
    setLoadingData(true)
    let tmpDataPoints = []
    let tmpDataTemplates = []
    let qry = {}

    try {
      // qry.templateType = 'clinical_report'

      tmpDataPoints = await readClinicDataPoints()
      tmpDataTemplates = await readClinicalReportTemplates(qry, true)

      setDataPoints(tmpDataPoints)
      setDataTemplates(tmpDataTemplates)
    } catch (e) {
      message.error(handleError(e))
      setTemplatesDataLoadError(true)
    }
    setLoadingData(false)
  }

  const handleDetermineInitialTemplate = async () => {
    setCheckingExisting(true)
    setInitialFetchError(false)
    // Check if template exists
    let existingRecords = []
    try {
      existingRecords = await readClinicalReportRecords({
        bookingRef: bookingData._id,
        createdBy: authState.agiliteUser._id
      })
      if (existingRecords[0]) {
        const targetTemplate = dataTemplates.find((i) => i._id === existingRecords[0].templateRef)

        if (targetTemplate) {
          setTemplate(targetTemplate)
          setTemplateData(existingRecords[0])
        }
      }
    } catch (e) {
      setInitialFetchError(true)
      message.error(handleError(e))
    }
    setCheckingExisting(false)
  }

  const determineDataPointValue = (dataPoint, targetData) => {
    if (dataPoint.subFields.length > 0) {
      if (dataPoint.delimiter) {
        return targetData.value.map((i) => i.value).join('/')
      }
    } else {
      return `${targetData.value} ${dataPoint.suffix}`
    }
  }

  const handleNewTemplateCreation = async (tmpTemplate) => {
    let existingRecords = []
    let newRecord = null
    setCreatingInitialTemplate(true)
    setSaving(true)

    // Build payload with necessary details
    const payload = {
      createdAt: new Date(),
      createdBy: authState.agiliteUser._id,
      modifiedAt: new Date(),
      modifiedBy: authState.agiliteUser._id,
      templateRef: tmpTemplate._id,
      bookingRef: bookingData._id
    }

    try {
      existingRecords = await readClinicalReportRecords({
        bookingRef: bookingData._id,
        createdBy: authState.agiliteUser._id,
        templateRef: tmpTemplate._id
      })

      if (!existingRecords[0]) {
        newRecord = await createClinicalReportRecord(payload)
        setTemplate(tmpTemplate)
        setTemplateData(newRecord)
      } else {
        setTemplate(tmpTemplate)
        setTemplateData(existingRecords[0])
      }
      setTemplateSelectionRequired(false)
    } catch (e) {
      // Handle any errors that occur during the process
      message.error(handleError(e))
      // Ensure saving state is reset after operation
    }
    setSaving(false)
    setCreatingInitialTemplate(false)
  }

  const handleUpdateClinicalReportData = async (targetId, data) => {
    // Update
    setSaveError(false)
    let payload = {
      data: data,
      modifiedAt: new Date()
    }
    try {
      await updateClinicalReportRecord({ _id: targetId }, payload)
      setIsSaved(true)
    } catch (e) {
      setSaveError(true)
      message.error(handleError(e))
    }
  }

  const handleGenerateClinicalReport = async () => {
    setReportLoading(true)
    let payload = {}
    const aggregationPipeline = [
      // Match documents with the specified userRef
      { $match: { userRef: userRef || bookingData.userRef } },
      // Sort by key and dateCreated to get the latest document for each key
      // First, sort the documents by key and dateCreated in descending order
      { $sort: { key: 1, dateCreated: -1 } },
      // Group by the key to get the latest document for each unique key
      {
        $group: {
          _id: '$key',
          bookingRef: { $first: '$bookingRef' },
          category: { $first: '$category' },
          createdBy: { $first: '$createdBy' },
          dateCreated: { $first: '$dateCreated' },
          fileRef: { $first: '$fileRef' },
          key: { $first: '$key' },
          label: { $first: '$label' },
          origin: { $first: '$origin' },
          userRef: { $first: '$userRef' },
          value: { $first: '$value' }
        }
      },
      // Optionally, sort the results by any field you prefer
      { $sort: { dateCreated: -1 } }
    ]
    const mapDataPointsToObject = (dataPoints) => {
      const result = {}

      dataPoints.forEach((point) => {
        // Split the key by '.' to handle nested keys
        const keys = point.key.split('.')
        let currentLevel = result

        // Traverse through the keys and create nested objects as needed
        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            // If it's the last key, assign the value
            currentLevel[key] = point.value
          } else {
            // Otherwise, move to the next level in the object
            currentLevel[key] = currentLevel[key] || {}
            currentLevel = currentLevel[key]
          }
        })
      })

      return result
    }

    try {
      const medHist = await readMedicalHistory({ userRef: userRef ? userRef : bookingData.userRef })
      payload.medicalHistory = medHist[0]
      const dataPoints = await aggregateClinicalDataRecords(aggregationPipeline)
      payload.dataPoints = mapDataPointsToObject(dataPoints)
      payload.clinicalReportData = clinicalReportingForm.getFieldsValue()
      await generateClinicalReport(payload, 'd4377b2344003c1c')
    } catch (e) {
      message.error(handleError(e, true))
    }
    setReportLoading(false)
  }

  // eslint-disable-next-line
  const debouncedUpdateClinicReportData = useCallback(
    debounce((targetId, data) => {
      handleUpdateClinicalReportData(targetId, data)
    }, 5000),
    []
  )
  const isHidden = (array, key) => {
    return array.includes(key)
  }

  const isError = saveError || templatesDataLoadError || initialFetchError

  return (
    <Card
      type='inner'
      headStyle={{ background: hexToRGBA(token.colorSecondary, 0.2) }}
      title={
        !template || templateSelectionRequired
          ? 'NO CURRENT REPORT'
          : `CLINICAL REPORT - ${template?.name.toUpperCase()}`
      }
      extra={
        template && !templateSelectionRequired ? (
          <CustomButton
            tooltip={''}
            onClick={() => {}}
            size='small'
            type={isSaved ? 'success' : 'danger'}
            text={isSaved ? 'SAVED' : isError ? 'ERROR' : 'BUSY'}
          />
        ) : templateSelectionRequired ? (
          <CustomButton
            style={{ width: '250px' }}
            size='small'
            disabled={!isSaved || loadingData || checkingExisting}
            type='danger'
            text='CANCEL'
            onClick={() => setTemplateSelectionRequired(false)}
          />
        ) : undefined
      }
      size='small'
    >
      <div>
        {isError ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              background: hexToRGBA(token.colorError, 0.2),
              zIndex: 100,
              top: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Space
              direction='vertical'
              style={{
                padding: 12,
                border: '1px #ffffff solid',
                borderRadius: 12,
                background: token.colorError,
                color: '#ffffff'
              }}
            >
              <h2>OOPS SOMETHING WENT WRONG</h2>
              <p>Please click the retry button below to sync up your data.</p>
              <center>
                <CustomButton
                  type='success'
                  size='small'
                  text='Sync Data'
                  onClick={() => {
                    if (templatesDataLoadError) {
                      return handleFetchData()
                    }
                    if (initialFetchError) {
                      return handleDetermineInitialTemplate()
                    }
                    if (saveError) {
                      setIsSaved(false)
                      return handleUpdateClinicalReportData(templateData._id, clinicalReportingForm.getFieldsValue())
                    }
                  }}
                />
              </center>
            </Space>
          </div>
        ) : undefined}
        {loadingData || checkingExisting || creatingInitialTemplate ? (
          <div style={{ opacity: isError ? 0.2 : 1 }}>
            <AgiliteSkeleton
              skActive
              spinnerTip={
                creatingInitialTemplate
                  ? 'Creating Template...'
                  : checkingExisting
                  ? 'Loading Template...'
                  : 'Loading Data...'
              }
            />
          </div>
        ) : (
          <div style={{ opacity: isError ? 0.2 : 1 }}>
            {templateSelectionRequired ? (
              <Row gutter={[0, 12]}>
                {dataTemplates?.map((tmpTemplate) => {
                  return (
                    <Col span={24}>
                      <Card
                        size='small'
                        type='inner'
                        title={tmpTemplate?.name}
                        extra={
                          <CustomButton
                            text='Select'
                            type={'primary'}
                            size='small'
                            onClick={() => {
                              handleNewTemplateCreation(tmpTemplate)
                            }}
                          />
                        }
                      >
                        <Space>
                          {tmpTemplate?.keys.map((key) => {
                            const dataReference = dataPoints.find((i) => i.key === key)
                            return <Tag>{dataReference.label}</Tag>
                          })}
                        </Space>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            ) : (
              <>
                {/* <Select
            value={templateDataPointsSelection}
            placeholder='- Select datapoints to capture -'
            showSearch
            optionFilterProp='label'
            mode='multiple'
            options={dataPoints.map((dataPoint) => ({ value: JSON.stringify(dataPoint), label: dataPoint.label }))}
            onChange={(array) => {
              setTemplateDataPointsSelection(array)
              setCurrentTemplateName('Custom Template')
            }}
            style={{ width: '100%', marginBottom: 12 }}
          /> */}{' '}
                <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                  {template ? (
                    <Form
                      form={clinicalReportingForm}
                      onValuesChange={(field, fields) => {
                        setIsSaved(false)
                        debouncedUpdateClinicReportData(templateData._id, fields)
                      }}
                      layout='inline'
                      initialValues={templateSelectionRequired ? {} : templateData.data}
                    >
                      <CustomRow>
                        {saving ? (
                          <Col span={24}>
                            <AgiliteSkeleton skActive spinnerTip='Generating template...' />
                          </Col>
                        ) : (
                          <>
                            <Col span={24}>
                              <CustomRow wrap gutter={[8, 8]}>
                                {template?.keys.map((fieldKey) => {
                                  const targetFieldReference = dataPoints.find((i) => i.key === fieldKey)
                                  if (targetFieldReference.subFields.length > 0) {
                                    return (
                                      <Col span={24}>
                                        <Card
                                          headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
                                          title={targetFieldReference.label}
                                          size='small'
                                          type='inner'
                                        >
                                          {targetFieldReference.subFields
                                            .filter((i) => !isHidden(template.hiddenKeys, i.key))
                                            .map((subfield) => {
                                              return (
                                                <Card size='small' style={{ width: '100%', marginBottom: 12 }}>
                                                  {generateFormItem({
                                                    ...subfield,
                                                    key: [targetFieldReference.key, subfield.key],
                                                    formProps: { style: { marginBottom: 0 } }
                                                  })}
                                                </Card>
                                              )
                                            })}
                                        </Card>
                                      </Col>
                                    )
                                  } else {
                                    return (
                                      <Col span={24}>
                                        <Card size='small' style={{ width: '100%' }}>
                                          {generateFormItem({
                                            ...targetFieldReference,
                                            formProps: { style: { marginBottom: 0 } }
                                          })}
                                        </Card>
                                      </Col>
                                    )
                                  }
                                })}
                              </CustomRow>
                            </Col>
                            {/* {referenceData.length > 0 ? (
                              <Col span={8}>
                                <Card size='small' title='Data References:'>
                                  {loadingReferenceData ? (
                                    <AgiliteSkeleton skActive spinnerTip='Loading data...' />
                                  ) : (
                                    <>
                                      {dataPoints
                                        .filter((i) => !i.reportSpecific)
                                        .map((dataPoint) => {
                                          const targetData = referenceData.find((i) => i.key === dataPoint.key)
                                          if (targetData) {
                                            return (
                                              <>
                                                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                                  <p>{dataPoint.label}</p>
                                                  <p>{determineDataPointValue(dataPoint, targetData)}</p>
                                                </Space>
                                                <Divider style={{ margin: '4px 0' }} />
                                              </>
                                            )
                                          } else {
                                            return null
                                          }
                                        })}{' '}
                                    </>
                                  )}
                                </Card>
                              </Col>
                            ) : undefined} */}
                          </>
                        )}
                      </CustomRow>
                    </Form>
                  ) : (
                    <Empty description='Select a template for use.' />
                  )}
                  {!templateSelectionRequired ? (
                    <Space direction='vertical'>
                      <CustomButton
                        style={{ width: '250px' }}
                        size='small'
                        disabled={!isSaved || loadingData || checkingExisting}
                        type='primary'
                        text='CHOOSE TEMPLATE'
                        onClick={() => setTemplateSelectionRequired(true)}
                      />
                      <CustomButton
                        style={{ width: '250px' }}
                        size='small'
                        disabled={!isSaved || loadingData || checkingExisting || reportLoading}
                        type='secondary'
                        text='PRINT TO PDF'
                        icon={faFilePdf}
                        iconPosition='end'
                        onClick={() => {
                          handleGenerateClinicalReport()
                        }}
                        loading={reportLoading}
                      />
                    </Space>
                  ) : undefined}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}

export default ClinicalReporting
