import React, { useEffect, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Col } from 'antd'
import CarePlansList from './CarePlansList'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import { readClinicDataPoints } from '../../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { readCarePlanTemplates } from '../../utils/utils'
import CarePlanEnrollment from '../Enrollment/CarePlanEnrollment'

const CarePlanManagement = ({ isMonitoring }) => {
  // State
  const [carePlansTemplatesLoading, setCarePlansTemplatesLoading] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [dataPoints, setDataPoints] = useState([])

  // Loading
  const [carePlansTemplates, setCarePlansTemplates] = useState([])
  const [dataPointsLoading, setDataPointsLoading] = useState(false)

  useEffect(() => {
    handleReadCarePlanTemplates()
    handleReadDataPoints()
    // eslint-disable-next-line
  }, [])

  const handleReadDataPoints = async () => {
    let tmpDataPoints = []

    setDataPointsLoading(true)
    try {
      tmpDataPoints = await readClinicDataPoints()
      setDataPoints(tmpDataPoints)
    } catch (e) {}
    setDataPointsLoading(false)
  }

  const handleReadCarePlanTemplates = async () => {
    let tmpData = []

    setCarePlansTemplatesLoading(true)
    try {
      tmpData = await readCarePlanTemplates({ isMonitoring })
      setCarePlansTemplates(tmpData)
    } catch (e) {}
    setCarePlansTemplatesLoading(false)
  }

  const handleEnrollmentView = (templateProps) => {
    setCurrentTemplate(templateProps)
  }

  return (
    <>
      <CustomRow>
        {dataPointsLoading || carePlansTemplatesLoading ? (
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Loading...' />
          </Col>
        ) : (
          <Col span={24}>
            {!currentTemplate ? (
              <CarePlansList
                isMonitoring={isMonitoring}
                handleEnrollmentView={handleEnrollmentView}
                dataPoints={dataPoints}
                carePlansTemplates={carePlansTemplates}
                refreshView={handleReadCarePlanTemplates}
              />
            ) : undefined}
            {currentTemplate ? (
              <CarePlanEnrollment
                isMonitoring={isMonitoring}
                setCurrentTemplate={setCurrentTemplate}
                template={currentTemplate}
              />
            ) : undefined}
          </Col>
        )}
      </CustomRow>
    </>
  )
}

export default CarePlanManagement
