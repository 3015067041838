import Agilite from 'agilite'
import CoreEnums from '../../../../core/utils/enums'
import store from '../../../../store'
import ServicesEnums from './enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readServices = (filters) => {
  return new Promise((resolve, reject) => {
    let qry = {}
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        for (const key in filters) {
          if (filters[key] !== '') {
            qry[key] = filters[key]
          }
        }

        if (store.getState().core.entity) {
          qry.entityRef = store.getState().core.entity._id
        }

        response = await agilite.Connectors.execute(
          ServicesEnums.profileKeys.ADMINISTRATION_SERVICES,
          ServicesEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createService = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()

    ;(async () => {
      try {
        data.dateCreated = timestamp
        data.dateModified = timestamp
        data.createdBy = store.getState().auth.agiliteUser._id
        data.modifiedBy = store.getState().auth.agiliteUser._id
        data.entityRef = store.getState().core.entity._id

        response = await agilite.Connectors.execute(
          ServicesEnums.profileKeys.ADMINISTRATION_SERVICES,
          ServicesEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const updateService = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()

    ;(async () => {
      try {
        data.dateModified = timestamp
        data.modifiedBy = store.getState().auth.agiliteUser._id

        response = await agilite.Connectors.execute(
          ServicesEnums.profileKeys.ADMINISTRATION_SERVICES,
          ServicesEnums.routeKeys.UPDATE,
          {
            recordId: data._id,
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
