import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  theme,
  message,
  Select,
  Switch,
  Popconfirm,
  Space,
  Avatar,
  Radio,
  Tabs,
  Divider
} from 'antd'
import { batch, useDispatch, useSelector } from 'react-redux'
import { sendSignInLinkToEmail } from 'firebase/auth'
/* import { UploadOutlined } from '@ant-design/icons' */

import coreReducer from '../../../../core/utils/reducer'
import Theme from '../../../../core/utils/theme'
import Templates from '../utils/templates'
import { createAgiliteUser, findAgiliteUser, updateAgiliteUser } from '../../../Auth/utils/utils'
import { firebaseAuth } from '../../../..'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { allTrim, handleError, specialFieldValidation } from '../../../lib/utils'
import { deviceDetect } from 'react-device-detect'
import { UserOutlined } from '@ant-design/icons'
import PatientBookings from '../../patients/components/patients-bookings'
import Profile from '../../../Profile/components/Profile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookMedical, faPencil, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import Dependants from '../../../Profile/components/dependants/dependants-list-view'
import systemUsersReducer from '../utils/reducer'
import CustomRow from '../../../reusable-components/CustomRow'
import PatientAnalytics from '../../../Analytics/Patients/patient-analytics'
import CustomButton from '../../../reusable-components/CustomButton'
import CoreEnums from '../../../../core/utils/enums'
import PatientExaminationForm from '../../../Bookings/components/booking-examination-wrapper'
import { BookingDataTemplate } from '../../../Bookings/utils/booking-data-template'
import BeginExaminationModal from './begin-examination-modal'
import ScriptsWrapper from '../../../Scripting/components/scripting-wrapper'
import ReportList from '../../Reporting/report-list'

const SystemUsersForm = ({
  dataId,
  isNewRecord,
  refreshView,
  type,
  handleRootTabKey,
  entities,
  isWizard,
  handleWizardNextPrevious
}) => {
  const coreState = useSelector((state) => state.core)
  const activeRecords = useSelector((state) => state.systemUsers.activeRecords)
  const facet = useSelector((state) => state.systemUsers.facet)
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const [viewType, setViewType] = useState(type)
  const [loading, setLoading] = useState(false)
  const [deactivatePrompt, setDeactivatePrompt] = useState(false)
  const [examinationModalOpen, setExaminationModalOpen] = useState(false)
  const [specialFormState, setSpecialFormState] = useState([])
  const [emailOrPhone, setEmailOrPhone] = useState('email')
  const [servicesLoading, setServicesLoading] = useState(false)
  const [services, setServices] = useState([])
  const dispatch = useDispatch()
  const { token } = theme.useToken()
  const [clientForm] = Form.useForm()

  const dataIndex = isNewRecord
    ? activeRecords.findIndex((item) => item.isNew === true)
    : activeRecords.findIndex((item) => item._id === dataId)

  useEffect(() => {
    switch (type) {
      case 'system':
        setViewType('System User')
        break
      case 'medical':
        setViewType('Medical Professional')
        break
      case 'patient':
        setViewType('Patient')
        break
      default:
        setViewType('System User')
    }

    if (isNewRecord && type === 'medical') {
      handleSetFormData({ extraData: { role: { type: 'medical_professional', isManager: false } } })
      clientForm.setFieldValue(['extraData', 'role', 'type'], 'medical_professional')
    }

    if (isNewRecord && type === 'patient') {
      handleSetFormData({ extraData: { role: { type: 'patient', isManager: false } } })
      clientForm.setFieldValue(['extraData', 'role', 'type'], 'patient')
    }
    // eslint-disable-next-line
  }, [])

  const handleSetFormData = (data) => {
    const tmpActiveRecords = JSON.parse(JSON.stringify(activeRecords))

    tmpActiveRecords[dataIndex] = {
      ...tmpActiveRecords[dataIndex],
      ...data,
      extraData: { ...tmpActiveRecords[dataIndex].extraData, ...data.extraData }
    }
    dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `+27${phoneNumber.slice(1, 10)}`
    } else {
      phoneNumber = `+27${phoneNumber}`
    }

    return phoneNumber
  }

  const handleSubmit = async () => {
    let record = allTrim(clientForm.getFieldsValue(true))
    let phoneNumber = null
    let user = null

    try {
      setLoading(true)

      if (record.extraData.role.type === 'medical_professional') {
        if (record.extraData.services.length < 1) {
          setLoading(false)
          return message.error('Please select at least one service')
        }
      }
      if (record.extraData.role.type === 'medical_professional' || record.extraData.role.type === 'reception') {
        if (record.extraData.clinics.length < 1) {
          setLoading(false)
          return message.error('Please select at least one clinic')
        }
      }

      if (isNewRecord) {
        if (record.phoneNumber) {
          phoneNumber = formatNumber(record.phoneNumber)
          user = await findAgiliteUser({ phoneNumber })
        }

        if (record.email) {
          user = await findAgiliteUser({ email: record.email })
        }

        if (user) {
          setLoading(false)
          message.error('User already exists. Please revise.')
          return null
        } else {
          user = await createAgiliteUser(
            {
              ...record,
              phoneNumber
            },
            false,
            authState.agiliteUser._id
          )

          if (record.email) {
            await sendSignInLinkToEmail(firebaseAuth, record.email, {
              url: window.location.href,
              handleCodeInApp: true
            })
          }

          if (phoneNumber) {
            // TODO: Here we need to send sms with link to sign in to the portal
          }

          message.success('User created successfully.')
        }
      } else {
        await updateAgiliteUser(activeRecords[dataIndex]?._id, {
          'extraData.isActive': record.extraData.isActive,
          'extraData.role.type': record.extraData.role.type,
          'extraData.role.isManager': record.extraData.role.isManager,
          'extraData.profession': record.extraData.profession,
          'extraData.services': record.extraData.services,
          'extraData.clinics': record.extraData.clinics,
          'extraData.entities': record.extraData.entities,
          'extraData.mpNumber': record.extraData.mpNumber,
          'extraData.qualification': record.extraData.qualification,
          'extraData.hasAvailabilityAccess': record.extraData.hasAvailabilityAccess
        })

        message.success(
          `User: ${activeRecords[dataIndex]?.firstName} ${activeRecords[dataIndex]?.lastName} - updated successfully`
        )
      }

      closeTab()

      if (coreState.tourOpen) {
        dispatch(coreReducer.actions.nextTourStep())
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const closeTab = () => {
    let tmpActiveRecords = activeRecords.concat()

    if (isNewRecord) {
      tmpActiveRecords = tmpActiveRecords.filter((i) => i.isNew === true)
    } else {
      tmpActiveRecords = tmpActiveRecords.filter((i) => i._id !== dataId)
    }

    refreshView()
    batch(() => {
      dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
      dispatch(
        coreReducer.actions.closeTab({
          targetKey: isNewRecord ? `${handleRootTabKey()}_new_${type}_user` : `${handleRootTabKey()}_${dataId}`,
          removeBreadcrumb: true
        })
      )
    })
  }

  const handleIsActive = (value) => {
    if (activeRecords[dataIndex]?.extraData.isActive && !value) setDeactivatePrompt(true)
    else setDeactivatePrompt(false)
  }

  const handleRolesSelection = () => {
    if (type === 'system' && isNewRecord) {
      let tmpRoles = JSON.parse(JSON.stringify(authState.roles))
      tmpRoles = tmpRoles.filter((i) => i.value !== 'patient' && i.value !== 'medical_professional')
      return tmpRoles
    }
    return authState.roles
  }

  useEffect(() => {
    handleSetServices()
    // eslint-disable-next-line
  }, [])

  const handleSetServices = () => {
    let services = [
      ...JSON.parse(JSON.stringify(state.services.data)).map((i) => {
        return {
          label: i.name,
          value: i._id
        }
      })
    ]
    let tmpVirtualServices = JSON.parse(JSON.stringify(state.virtualServices.data))
    setServicesLoading(true)

    for (const service of tmpVirtualServices) {
      services.push({
        label: service.name + ' (Virtual)',
        value: service._id
      })
    }

    setServicesLoading(false)
    setServices(services)
  }

  const beginAdhocScreening = async (data) => {
    // setLoading(true)
    const record = BookingDataTemplate(activeRecords[dataIndex], JSON.parse(data.clinic), {
      chiefComplaint: data.ailment,
      ailment: data.ailment,
      patientSelection: data.patientSelection,
      patientInput: data.patientInput,
      paymentMethod: 'No Appointment',
      service: authState.agiliteUser.extraData.services[0]
    })

    try {
      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          label: `Booking: ${record.patientName}`,
          children: <PatientExaminationForm data={record} noAppointment={true} />
        })
      )
      setExaminationModalOpen(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
    // setLoading(false)
  }

  const handleOpenPatientProfile = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.PATIENTS}_${CoreEnums.tabKeys.PERSONAL_DETAILS}_${activeRecords[dataIndex]._id}`,
        closable: true,
        label: `Patient: ${activeRecords[dataIndex].firstName + ' ' + activeRecords[dataIndex].lastName}}`,
        children: (
          <Profile
            isReception={true}
            record={activeRecords[dataIndex]}
            setFormData={handleSetFormData}
            refreshView={refreshView}
          />
        )
      })
    )
  }
  const handleOpenPatientDependants = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.PATIENTS}_${CoreEnums.tabKeys.DEPENDANTS}_${activeRecords[dataIndex]._id}`,
        closable: true,
        label: `Dependants: ${activeRecords[dataIndex].firstName + ' ' + activeRecords[dataIndex].lastName}}`,
        children: (
          <Dependants
            isReception={true}
            mainPatient={activeRecords[dataIndex]}
            setFormData={handleSetFormData}
            refreshView={refreshView}
          />
        )
      })
    )
  }

  return (
    <>
      {!facet ? (
        <ContainerCard
          customBackComponent={
            <Col span={6}>
              <Button style={{ marginLeft: 4 }} danger onClick={closeTab}>
                {isNewRecord ? 'Cancel' : `Close ${viewType} File`}
              </Button>
            </Col>
          }
          title={isNewRecord ? 'Invite ' + viewType : 'Viewing ' + viewType}
        >
          <CustomRow justify='center'>
            <Col span={24}>
              <h2 style={{ padding: 12, background: token.colorSecondary, borderRadius: 12, color: '#ffffff' }}>
                {isNewRecord
                  ? `New ${viewType}`
                  : `User: ${activeRecords[dataIndex]?.firstName} ${activeRecords[dataIndex]?.lastName}`}
              </h2>
            </Col>

            {authState.agiliteUser.extraData.role.type === 'medical_professional' ? (
              <Col span={24}>
                <CustomRow className='basic-card'>
                  <Col span={24}>
                    <center>
                      If you need to screen a patient without a scheduled appointment you can begin the screening
                      process using the "Begin Examination" button below.
                    </center>
                  </Col>
                  <Col span={24}>
                    <center>
                      <CustomButton
                        type='success'
                        text={'Begin Examination'}
                        icon={faBookMedical}
                        onClick={() => {
                          setExaminationModalOpen(true)
                        }}
                      />
                    </center>
                  </Col>
                </CustomRow>
              </Col>
            ) : undefined}

            <Col span={24}>
              <CustomRow className='basic-card'>
                <Col span={24}>
                  <Tabs type='card' centered>
                    <Tabs.TabPane tab='General' key='general'>
                      <CustomRow justify='center' className='basic-card'>
                        <Col>
                          <Form
                            id='medProf_form'
                            style={{ width: 600, maxWidth: '100%' }}
                            onFinish={handleSubmit}
                            name='form'
                            form={clientForm}
                            onFieldsChange={() => {
                              const tmpData = clientForm.getFieldsValue()
                              let tmpActiveRecords = JSON.parse(JSON.stringify(activeRecords))

                              tmpActiveRecords[dataIndex] = { ...tmpActiveRecords[dataIndex], ...tmpData }
                              dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))

                              clientForm.setFieldsValue(tmpData)
                              handleSetFormData(tmpData)
                            }}
                            initialValues={{
                              ...Templates.dataTemplate(),
                              ...activeRecords[dataIndex],
                              extraData: {
                                ...Templates.dataTemplate().extraData,
                                ...activeRecords[dataIndex]?.extraData
                              },
                              phoneNumber: activeRecords[dataIndex]?.phoneNumber
                                ? activeRecords[dataIndex]?.phoneNumber.replace('+27', '')
                                : ''
                            }}
                            layout='vertical'
                          >
                            <Row justify='center' gutter={[12, 12]} style={{ marginTop: 12 }}>
                              {isNewRecord ? undefined : (
                                <>
                                  <Col>
                                    <Row gutter={[12, 12]} justify='center'>
                                      <Col>
                                        {activeRecords[dataIndex]?.profileImage ? (
                                          <img
                                            src={activeRecords[dataIndex]?.profileImage}
                                            alt='Patient'
                                            width={deviceDetect().isMobile ? 80 : 100}
                                          />
                                        ) : (
                                          <Avatar
                                            shape='square'
                                            size={deviceDetect().isMobile ? 80 : 100}
                                            icon={<UserOutlined />}
                                          />
                                        )}
                                      </Col>
                                      <Col>
                                        <span
                                          style={{ fontSize: '16pt', color: token.colorPrimary }}
                                        >{`${activeRecords[dataIndex]?.firstName} ${activeRecords[dataIndex]?.lastName}`}</span>
                                        <br />
                                        <span style={{ fontSize: '12pt' }}>
                                          <b>National ID:</b> {activeRecords[dataIndex]?.idNo}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '12pt' }}>
                                          <b>Date Of Birth:</b> {activeRecords[dataIndex]?.dateOfBirth}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '12pt' }}>
                                          <b>Gender:</b>{' '}
                                          {activeRecords[dataIndex]?.gender.charAt(0).toUpperCase() +
                                            activeRecords[dataIndex]?.gender.slice(1)}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '12pt' }}>
                                          <b>Address:</b> {activeRecords[dataIndex]?.residentialAddress}
                                        </span>
                                        {activeRecords[dataIndex]?.email || activeRecords[dataIndex]?.tmpEmail ? (
                                          <>
                                            <br />
                                            <span style={{ fontSize: '12pt' }}>
                                              <b>Email:</b>{' '}
                                              {activeRecords[dataIndex]?.email || activeRecords[dataIndex]?.tmpEmail}
                                            </span>
                                          </>
                                        ) : undefined}
                                        {activeRecords[dataIndex]?.phoneNumber ||
                                        activeRecords[dataIndex]?.tmpPhoneNumber ? (
                                          <>
                                            <br />
                                            <span style={{ fontSize: '12pt' }}>
                                              <b>Contact:</b>{' '}
                                              {activeRecords[dataIndex]?.phoneNumber ||
                                                activeRecords[dataIndex]?.tmpPhoneNumber}
                                            </span>
                                          </>
                                        ) : undefined}
                                      </Col>
                                    </Row>
                                  </Col>
                                  {authState.agiliteUser.extraData.role.type === 'reception' ? (
                                    <Row justify='center' gutter={[12, 12]}>
                                      <Col span={24}>
                                        <center>
                                          <Button
                                            type='primary'
                                            onClick={() => {
                                              handleOpenPatientProfile()
                                            }}
                                            style={{ width: '70%' }}
                                          >
                                            <Space>
                                              <FontAwesomeIcon icon={faPencil} />
                                              <>Edit Profile</>
                                            </Space>
                                          </Button>
                                        </center>
                                      </Col>
                                      <Col span={24}>
                                        <center>
                                          <Button
                                            type='primary'
                                            onClick={() => {
                                              handleOpenPatientDependants()
                                            }}
                                            style={{ width: '70%' }}
                                          >
                                            <Space>
                                              <FontAwesomeIcon icon={faUsersCog} />
                                              <>Edit Dependants</>
                                            </Space>
                                          </Button>
                                        </center>
                                      </Col>
                                    </Row>
                                  ) : undefined}
                                  <Col span={22}>
                                    <Divider style={{ borderColor: token.colorSecondary }} />
                                  </Col>
                                </>
                              )}
                              <Col span={22}>
                                <Space wrap style={{ marginBottom: '20px' }}>
                                  {Templates.dataModel.isActive.label}
                                  <Form.Item
                                    style={{ margin: 0 }}
                                    name={Templates.dataModel.isActive.key}
                                    valuePropName='checked'
                                  >
                                    <Switch
                                      disabled={
                                        authState.agiliteUser.extraData.role.type !== 'admin' &&
                                        authState.agiliteUser.extraData.role.type !== 'super_admin'
                                      }
                                      checkedChildren='Active'
                                      unCheckedChildren='Inactive'
                                      onChange={(e) => {
                                        handleIsActive(e)
                                      }}
                                    />
                                  </Form.Item>
                                </Space>
                                {isNewRecord ? (
                                  <>
                                    <Form.Item
                                      name={Templates.dataModel.firstName.key}
                                      label={Templates.dataModel.firstName.label}
                                      rules={[
                                        {
                                          required: Templates.dataModel.firstName.required,
                                          message: Templates.dataModel.firstName.validationMsg
                                        }
                                      ]}
                                    >
                                      <Input
                                        onChange={(e) => {
                                          specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                            key: 'firstName',
                                            event: e,
                                            validationConfig: {
                                              letters: { allowed: true, onlyCaps: false },
                                              numbers: false,
                                              spaces: true
                                            }
                                          })
                                        }}
                                        maxLength={50}
                                        readOnly={isNewRecord ? false : true}
                                        placeholder={Templates.dataModel.firstName.placeholder}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name={Templates.dataModel.lastName.key}
                                      label={Templates.dataModel.lastName.label}
                                      rules={[
                                        {
                                          required: Templates.dataModel.lastName.required,
                                          message: Templates.dataModel.lastName.validationMsg
                                        }
                                      ]}
                                    >
                                      <Input
                                        onChange={(e) => {
                                          specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                            key: 'lastName',
                                            event: e,
                                            validationConfig: {
                                              letters: { allowed: true, onlyCaps: false },
                                              numbers: false,
                                              spaces: true
                                            }
                                          })
                                        }}
                                        maxLength={50}
                                        readOnly={isNewRecord ? false : true}
                                        placeholder={Templates.dataModel.lastName.placeholder}
                                      />
                                    </Form.Item>
                                    <Space direction='vertical' style={{ marginBottom: 20 }}>
                                      Invite User via:
                                      <Radio.Group
                                        value={emailOrPhone}
                                        onChange={(e) => setEmailOrPhone(e.target.value)}
                                      >
                                        <Radio value='email'>Email</Radio>
                                        <Radio value='phone'>Cellphone Number</Radio>
                                      </Radio.Group>
                                    </Space>
                                    {emailOrPhone === 'email' ? (
                                      <Form.Item
                                        name={Templates.dataModel.email.key}
                                        label={Templates.dataModel.email.label}
                                        rules={[
                                          {
                                            required: isNewRecord ? Templates.dataModel.email.required : false,
                                            message: Templates.dataModel.email.validationMsg
                                          }
                                        ]}
                                      >
                                        <Input
                                          type='email'
                                          maxLength={70}
                                          readOnly={isNewRecord ? false : true}
                                          placeholder={Templates.dataModel.email.placeholder}
                                        />
                                      </Form.Item>
                                    ) : undefined}
                                    {emailOrPhone === 'phone' ? (
                                      <Form.Item
                                        name={Templates.dataModel.phoneNumber.key}
                                        label={Templates.dataModel.phoneNumber.label}
                                        rules={[
                                          {
                                            required: isNewRecord ? Templates.dataModel.phoneNumber.required : false,
                                            message: Templates.dataModel.phoneNumber.validationMsg
                                          }
                                        ]}
                                      >
                                        <Input
                                          addonBefore='+27'
                                          placeholder='8288*****'
                                          maxLength={10}
                                          minLength={9}
                                          onChange={(e) => {
                                            specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                              key: 'phoneNumber',
                                              event: e,
                                              validationConfig: {
                                                letters: { allowed: false, onlyCaps: true },
                                                numbers: true
                                              }
                                            })
                                          }}
                                        />
                                      </Form.Item>
                                    ) : undefined}
                                  </>
                                ) : null}
                                <Form.Item
                                  name={Templates.dataModel.role.key}
                                  label={Templates.dataModel.role.label}
                                  rules={[
                                    {
                                      required: Templates.dataModel.role.required,
                                      message: Templates.dataModel.role.validationMsg
                                    }
                                  ]}
                                >
                                  <Select
                                    style={{ width: 250 }}
                                    disabled={type !== 'system'}
                                    options={[
                                      {
                                        label: '-Select a role-',
                                        value: ''
                                      },
                                      ...handleRolesSelection()
                                    ]}
                                  />
                                </Form.Item>
                                {activeRecords[dataIndex]?.extraData.role.type === 'medical_professional' ? (
                                  <>
                                    <Form.Item
                                      name={Templates.dataModel.isManager.key}
                                      label={Templates.dataModel.isManager.label}
                                      valuePropName='checked'
                                      rules={[
                                        {
                                          required: Templates.dataModel.isManager.required,
                                          message: Templates.dataModel.isManager.validationMsg
                                        }
                                      ]}
                                    >
                                      <Switch checkedChildren='Yes' unCheckedChildren='No' />
                                    </Form.Item>
                                    <Form.Item
                                      name={Templates.dataModel.profession.key}
                                      label={Templates.dataModel.profession.label}
                                      rules={[
                                        {
                                          required: Templates.dataModel.profession.required,
                                          message: Templates.dataModel.profession.validationMsg
                                        }
                                      ]}
                                    >
                                      <Select
                                        style={{ width: 250 }}
                                        options={[
                                          { value: '', label: '-Select a profession-' },
                                          ...authState.professions
                                        ]}
                                      />
                                    </Form.Item>
                                    {activeRecords[dataIndex]?.extraData.profession === 'doctor' ? (
                                      <>
                                        <Form.Item
                                          name={Templates.dataModel.mpNumber.key}
                                          label={Templates.dataModel.mpNumber.label}
                                          rules={[
                                            {
                                              required: Templates.dataModel.mpNumber.required,
                                              message: Templates.dataModel.mpNumber.validationMsg
                                            }
                                          ]}
                                        >
                                          <Input
                                            onChange={(e) => {
                                              specialFieldValidation(
                                                clientForm,
                                                specialFormState,
                                                setSpecialFormState,
                                                {
                                                  key: 'extraData.mpNumber',
                                                  event: e,
                                                  validationConfig: {
                                                    letters: { allowed: false, onlyCaps: false },
                                                    numbers: true,
                                                    spaces: false
                                                  }
                                                }
                                              )
                                            }}
                                            maxLength={50}
                                            placeholder={Templates.dataModel.mpNumber.placeholder}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          name={Templates.dataModel.qualification.key}
                                          label={Templates.dataModel.qualification.label}
                                          rules={[
                                            {
                                              required: Templates.dataModel.qualification.required,
                                              message: Templates.dataModel.qualification.validationMsg
                                            }
                                          ]}
                                        >
                                          <Input placeholder={Templates.dataModel.qualification.placeholder} />
                                        </Form.Item>
                                      </>
                                    ) : undefined}
                                    <Form.Item
                                      name={Templates.dataModel.services.key}
                                      label={
                                        <span>
                                          <span style={{ color: token.colorError }}>* </span>Services:
                                        </span>
                                      }
                                    >
                                      <Select
                                        loading={servicesLoading}
                                        placeholder='-Select services-'
                                        mode='multiple'
                                        showSearch
                                        optionFilterProp='label'
                                        options={services}
                                      />
                                    </Form.Item>
                                  </>
                                ) : undefined}
                                {activeRecords[dataIndex]?.extraData.role.type === 'medical_professional' ? (
                                  <Form.Item
                                    name={['extraData', 'clinics']}
                                    label={
                                      <span>
                                        <span style={{ color: token.colorError }}>* </span>Linked Clinics:
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: Templates.dataModel.clinics.required,
                                        message: 'Select Medical Professionals linked clinics'
                                      }
                                    ]}
                                  >
                                    <Select
                                      mode='multiple'
                                      placeholder='-Select clinics-'
                                      maxLength={1}
                                      filterOption={(input, option) => {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }}
                                      options={[
                                        ...state.clinics.data.map((clinic) => {
                                          return {
                                            label: clinic.name,
                                            value: clinic._id
                                          }
                                        })
                                      ]}
                                    />
                                  </Form.Item>
                                ) : null}
                                {activeRecords[dataIndex]?.extraData.role.type === 'reception' ? (
                                  <Form.Item
                                    name={['extraData', 'clinics']}
                                    label={
                                      <span>
                                        <span style={{ color: token.colorError }}>* </span>Linked Clinic:
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: Templates.dataModel.clinics.required,
                                        message: "Select Reception's linked clinic"
                                      }
                                    ]}
                                  >
                                    <Select
                                      placeholder='-Select clinic-'
                                      onChange={(value) => {
                                        clientForm.setFieldValue(Templates.dataModel.clinics.key, [value])
                                      }}
                                      options={[
                                        ...state.clinics.data.map((clinic) => {
                                          return {
                                            label: clinic.name,
                                            value: clinic._id
                                          }
                                        })
                                      ]}
                                    />
                                  </Form.Item>
                                ) : null}
                                {authState.agiliteUser.extraData.role.type === 'super_admin' ? (
                                  <Form.Item
                                    name={Templates.dataModel.entities.key}
                                    label={<span>Linked Entities:</span>}
                                    rules={[
                                      {
                                        required: Templates.dataModel.entities.required,
                                        message: 'Select Admin linked entities'
                                      }
                                    ]}
                                  >
                                    <Select
                                      mode='multiple'
                                      placeholder='-Select entities-'
                                      maxLength={1}
                                      filterOption={(input, option) => {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }}
                                      options={entities.map((entity) => {
                                        return {
                                          label: entity.name,
                                          value: entity._id
                                        }
                                      })}
                                    />
                                  </Form.Item>
                                ) : undefined}
                                {activeRecords[dataIndex]?.extraData.role.type === 'medical_professional' ? (
                                  <Form.Item
                                    name={['extraData', 'hasAvailabilityAccess']}
                                    label={
                                      <span>
                                        <span style={{ color: token.colorError }}>* </span>Has Access to Availability:
                                      </span>
                                    }
                                    valuePropName='checked'
                                  >
                                    <Switch checkedChildren='Yes' unCheckedChildren='No' />
                                  </Form.Item>
                                ) : undefined}
                              </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }} justify='center' gutter={[8, 8]}>
                              <Col>
                                <Form.Item noStyle>
                                  <Button danger onClick={closeTab}>
                                    {isNewRecord ? 'Cancel' : `Close ${viewType} File`}
                                  </Button>
                                </Form.Item>
                              </Col>
                              {authState.agiliteUser.extraData.role.type !== 'admin' &&
                              authState.agiliteUser.extraData.role.type !== 'super_admin' ? undefined : (
                                <>
                                  <Col>
                                    <Form.Item noStyle>
                                      {deactivatePrompt ? (
                                        <Popconfirm
                                          okText='Yes'
                                          cancelText='No'
                                          okButtonProps={{
                                            style: { background: token.colorSuccess, color: 'white' }
                                          }}
                                          cancelButtonProps={{
                                            style: { background: token.colorError, color: 'white' }
                                          }}
                                          title={<h3 style={{ color: token.colorWarning, margin: 0 }}>WARNING</h3>}
                                          description='You have switched this system user account to be inactive. Are you sure you want to proceed?'
                                          onConfirm={() => {
                                            clientForm.submit()
                                          }}
                                        >
                                          <Button
                                            style={{
                                              backgroundColor: Theme.twitterBootstrap.success,
                                              color: 'white',
                                              marginRight: 10
                                            }}
                                            htmlType='submit'
                                            loading={loading}
                                          >
                                            {isNewRecord ? 'Invite' : 'Save'}
                                          </Button>
                                        </Popconfirm>
                                      ) : (
                                        <Button
                                          style={{
                                            backgroundColor: Theme.twitterBootstrap.success,
                                            color: 'white',
                                            marginRight: 10
                                          }}
                                          htmlType='submit'
                                          loading={loading}
                                        >
                                          {isNewRecord ? 'Invite' : 'Save'}
                                        </Button>
                                      )}
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Form>
                        </Col>
                      </CustomRow>
                    </Tabs.TabPane>
                    {isNewRecord || type !== 'patient' ? undefined : (
                      <>
                        <Tabs.TabPane tab='Bookings' key='bookings'>
                          <CustomRow>
                            <Col span={24}>
                              <PatientBookings userRef={activeRecords[dataIndex]?._id} />
                            </Col>
                          </CustomRow>
                          <Row justify='center' style={{ marginTop: 20 }}>
                            <Col>
                              <Button danger onClick={closeTab}>
                                {isNewRecord ? 'Cancel' : `Close ${viewType} File`}
                              </Button>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                        {authState.agiliteUser.extraData.role.type === 'medical_professional' ? (
                          <>
                            <Tabs.TabPane tab='Scripts' key='scripts'>
                              <CustomRow>
                                <Col span={24}>
                                  <ScriptsWrapper patientId={dataId} />
                                </Col>
                              </CustomRow>
                              <Row justify='center' style={{ marginTop: 20 }}>
                                <Col>
                                  <Button danger onClick={closeTab}>
                                    {isNewRecord ? 'Cancel' : `Close ${viewType} File`}
                                  </Button>
                                </Col>
                              </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab='Analytics' key='analytics'>
                              <CustomRow>
                                <Col span={24}>
                                  <PatientAnalytics userRef={dataId} />
                                </Col>
                              </CustomRow>
                              <Row justify='center' style={{ marginTop: 20 }}>
                                <Col>
                                  <Button danger onClick={closeTab}>
                                    {isNewRecord ? 'Cancel' : `Close ${viewType} File`}
                                  </Button>
                                </Col>
                              </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab='Reports' key='reports'>
                              <CustomRow>
                                <Col span={24}>
                                  <ReportList userRef={dataId} />
                                </Col>
                              </CustomRow>
                              <Row justify='center' style={{ marginTop: 20 }}>
                                <Col>
                                  <Button danger onClick={closeTab}>
                                    {isNewRecord ? 'Cancel' : `Close ${viewType} File`}
                                  </Button>
                                </Col>
                              </Row>
                            </Tabs.TabPane>
                          </>
                        ) : undefined}
                      </>
                    )}
                  </Tabs>
                </Col>
              </CustomRow>
            </Col>
          </CustomRow>
        </ContainerCard>
      ) : null}
      <BeginExaminationModal
        examinationModalOpen={examinationModalOpen}
        setExaminationModalOpen={setExaminationModalOpen}
        beginAdhocExamination={beginAdhocScreening}
      />
    </>
  )
}

export default SystemUsersForm
