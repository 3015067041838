import React, { useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Dropdown, Modal, Space, theme } from 'antd'
import { useNavigate } from 'react-router-dom'
import { BrowserView } from 'react-device-detect'
import { signOut } from 'firebase/auth'

import { generateContent } from '../../../../setup'
import { firebaseAuth } from '../../../..'
import { handleError } from '../../../lib/utils'
import Router from '../router'
import AuthEnums from '../../utils/enums'
import CoreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import authReducer from '../../utils/reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ProfileEnums from '../../../Profile/utils/enums'

const ToolbarCustomMenus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const coreState = useSelector((state) => state.core)
  const [image, setImage] = useState()
  const state = useSelector((state) => state.auth)
  const { token } = theme.useToken()

  const handleLogin = () => {
    navigate(AuthEnums.routes.LOGIN)
  }

  const handleSignup = () => {
    navigate(AuthEnums.routes.REGISTER)
  }

  const handleSignOut = async () => {
    try {
      await signOut(firebaseAuth)
      batch(() => {
        dispatch(CoreReducer.actions.resetTabs())
        dispatch(CoreReducer.actions.resetState())
        dispatch(CoreReducer.actions.enableDisableLeftMenu(false))
        dispatch(CoreReducer.actions.enableDisableRightMenu(false))
        dispatch(CoreReducer.actions.enableDisableTabs(false))
        dispatch(CoreReducer.actions.setRootContent(Router))
        dispatch(authReducer.actions.setUser(null))
        dispatch(authReducer.actions.setAgiliteUser(null))
        dispatch(authReducer.actions.setLoggedIn(false))
      })
    } catch (e) {
      handleError(e, true)
    }
  }

  // const generateUserType = () => {
  //   let text = ''

  //   if (state.agiliteUser) {
  //     if (state.agiliteUser.extraData.role.type === 'medical_professional') {
  //       text = <span style={{ textTransform: 'capitalize' }}>{state.agiliteUser.extraData.profession}</span>
  //     } else if (state.agiliteUser.extraData.role.type === 'admin') {
  //       text = 'Admin'
  //     } else if (state.agiliteUser.extraData.role.type === 'reception') {
  //       text = 'Reception'
  //     }
  //   }

  //   return text
  // }

  const handleGetItems = () => {
    const dropDownItems = {
      loggedInUser: {
        key: 'loggedInUser',
        label: (
          <div style={{ marginBottom: 10 }}>
            <span style={{ fontSize: 12, color: '#ccc' }}>
              {state.agiliteUser?.firstName + ' ' + state.agiliteUser?.lastName}
            </span>
          </div>
        ),
        onClick: () => {},
        type: 'group'
      },
      personalDetails: {
        key: CoreEnums.tabKeys.PERSONAL_DETAILS,
        label: CoreEnums.tabTitles.PERSONAL_DETAILS,
        onClick: () => {
          dispatch(CoreReducer.actions.addTab(generateContent(CoreEnums.tabKeys.PERSONAL_DETAILS)))
        }
      },
      management: {
        key: CoreEnums.tabTitles.MANAGEMENT,
        label: CoreEnums.tabTitles.MANAGEMENT,
        onClick: () => {
          dispatch(CoreReducer.actions.addTab(generateContent(CoreEnums.tabKeys.MANAGEMENT)))
        }
      },
      availability: {
        key: CoreEnums.tabTitles.AVAILABILITY,
        label: CoreEnums.tabTitles.AVAILABILITY,
        onClick: () => {
          dispatch(CoreReducer.actions.addTab(generateContent(CoreEnums.tabKeys.AVAILABILITY)))
        }
      },
      signOut: {
        danger: true,
        key: CoreEnums.tabKeys.SIGN_OUT,
        label: CoreEnums.tabTitles.SIGN_OUT,
        onClick: () => {
          Modal.confirm({
            title: 'Confirmation',
            content: `Are you sure you want to Sign Out of the ${coreState?.entity?.name} Portal`,
            onOk: () => handleSignOut(),
            okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
            cancelButtonProps: { style: { background: token.colorError, color: 'white' } },
            okText: 'Yes',
            cancelText: 'No',
            className: token.themeControl
          })
        }
      },
      medicalHistory: {
        key: CoreEnums.tabKeys.MEDICAL_HISTORY,
        label: CoreEnums.tabTitles.MEDICAL_HISTORY,
        onClick: () => {
          dispatch(CoreReducer.actions.addTab(generateContent(CoreEnums.tabKeys.MEDICAL_HISTORY)))
        }
      },
      dependants: {
        key: CoreEnums.tabKeys.DEPENDANTS,
        label: CoreEnums.tabTitles.DEPENDANTS,
        onClick: () => {
          dispatch(CoreReducer.actions.addTab(generateContent(CoreEnums.tabKeys.DEPENDANTS)))
        }
      },
      version: {
        key: 'version',
        label: <small style={{ color: '#ccc' }}>Version {process.env.REACT_APP_VERSION}</small>,
        type: 'group'
      }
    }

    if (
      state.agiliteUser?.extraData?.role.type === 'medical_professional' &&
      state.agiliteUser?.extraData?.role.isManager === false
    ) {
      return [
        dropDownItems.loggedInUser,
        dropDownItems.availability,
        dropDownItems.personalDetails,
        dropDownItems.signOut,
        dropDownItems.version
      ]
    }

    if (
      state.agiliteUser?.extraData?.role.type === 'medical_professional' &&
      state.agiliteUser?.extraData?.role.isManager === true
    ) {
      return [
        dropDownItems.loggedInUser,
        dropDownItems.management,
        dropDownItems.availability,
        dropDownItems.personalDetails,
        dropDownItems.signOut,
        dropDownItems.version
      ]
    }

    if (state.agiliteUser?.extraData?.role.type !== 'patient') {
      return [dropDownItems.loggedInUser, dropDownItems.personalDetails, dropDownItems.signOut, dropDownItems.version]
    } else {
      return [
        dropDownItems.loggedInUser,
        dropDownItems.personalDetails,
        // dropDownItems.medicalHistory,
        // dropDownItems.dependants,
        dropDownItems.signOut,
        dropDownItems.version
      ]
    }
  }

  useEffect(() => {
    handleGetImage()
    // eslint-disable-next-line
  }, [state.agiliteUser])

  const handleGetImage = () => {
    const userData = state.agiliteUser

    let tmpImage = null

    if (userData?.profileImage) {
      tmpImage = userData.profileImage
    } else if (firebaseAuth?.currentUser?.photoURL) {
      tmpImage = firebaseAuth.currentUser.photoURL
    } else {
      tmpImage = ProfileEnums.images.DEFAULT_IMAGE
    }

    setImage(tmpImage)
  }

  return (
    <>
      {state.loggedIn ? (
        <>
          {/* Note: No need to end any custom toolbar components with a Divider */}
          <Dropdown
            style={{ border: '1px red solid' }}
            trigger={['hover']}
            menu={{
              items: handleGetItems()
            }}
          >
            <Space wrap style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faChevronDown} style={{ cursor: 'pointer' }} />
              <div
                style={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: '1px rgba(255,255,255, 0.2) solid'
                }}
              >
                <img style={{ width: '100%', display: 'block' }} src={image} alt='Profile' />
              </div>
            </Space>
          </Dropdown>
        </>
      ) : (
        <>
          {/* <Divider type='vertical' /> */}
          <BrowserView>
            <span style={{ fontSize: 14 }}>ALREADY A MEMBER?</span>
          </BrowserView>
          <Button
            style={{ backgroundColor: token.colorPrimary, border: '1px solid white' }}
            type='primary'
            onClick={handleLogin}
          >
            Sign In
          </Button>
          <Divider type='vertical' />
          <Button
            style={{ backgroundColor: token.colorPrimary, border: 'none', color: token.colorWhite, padding: 0 }}
            onClick={handleSignup}
          >
            Register
          </Button>
        </>
      )}
    </>
  )
}

export default ToolbarCustomMenus
