import React, { useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Col, Space } from 'antd'
import '../utils/style.css'
import CustomButton from '../../reusable-components/CustomButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

const ScriptDescriptionBuilderPanel = ({ currentDescFocus, setCurrentDescFocus, state, setState }) => {
  const [hidePanel, setHidePanel] = useState(false)
  const ScriptPanelSetions = {
    appMethod: [
      ['Take', 'Inhale', 'Insert', 'Instill/Drop', 'Neb with'],
      ['Apply', 'Rinse with', 'Suck', 'Spray', 'Use']
    ],
    dosageMetric: [
      ['1', '2', '3'],
      ['4', '5', '6'],
      ['7', '8', '9'],
      ['.', '0', '.'],
      ['1/2', '1/4', 'X']
    ],
    bodyPart: ['po', 'eye(s)', 'ear(s)', 'nostril(s)', 'per rectum'],
    timing: [
      [
        { label: 'od', value: 'Once a day' },
        { label: 'stat', value: 'Immediately' },
        { label: 'mane', value: 'In the morning' }
      ],
      [
        { label: 'bd', value: 'Twice a day' },
        { label: 'prn', value: 'As needed' },
        { label: 'nocte', value: 'At night' }
      ],
      [
        { label: 'tds', value: 'Three times a day' },
        { label: 'mdu', value: 'As directed by the physician' },
        { label: 'then', value: 'Then' }
      ],
      [{ label: 'qid', value: 'Four times a day' }, null, { label: 'with', value: 'With' }],
      [{ label: 'at', value: 'At' }, null, { label: 'h', value: 'Hour' }]
    ],
    duration: [
      ['1', '3', '5', '7', '9'],
      ['2', '4', '6', '8', '0']
    ],
    frequency: ['hour(s)', 'day(s)', 'week(s)', 'month(s)', 'all days'],
    closeOff: [
      ['Complete course', 'Dissolve in water', 'Do not exceed', 'Loperamide', 'For allergy'],
      ['For pain', 'For pain and fever', 'For pain and inflam', 'For nausea', 'Do not substitute']
    ]
  }

  const handleValueChange = (newValue) => {
    let textAreaRef = document.getElementById(`script-item-${currentDescFocus}`)
    setCurrentDescFocus(currentDescFocus)
    let tmpState = [...state]
    tmpState[currentDescFocus].scriptingDosage = tmpState[currentDescFocus].scriptingDosage
      ? `${tmpState[currentDescFocus].scriptingDosage} ${newValue}`
      : `${newValue}`
    setState(tmpState)
    textAreaRef.focus()
  }

  const handleHidePanel = () => {
    setHidePanel(!hidePanel)
  }
  return (
    <div id='script-description-panel-container' className={hidePanel ? 'hidden' : ''}>
      <div
        onClick={() => {
          handleHidePanel()
        }}
        style={{
          width: '100%',
          padding: hidePanel ? 14 : 8,
          marginBottom: hidePanel ? 0 : 12,
          cursor: 'pointer'
        }}
      >
        <center style={{ color: 'white' }}>
          <Space>
            {hidePanel ? 'Show Shortcuts' : 'Hide Shortcuts'}
            <FontAwesomeIcon icon={hidePanel ? faCaretUp : faCaretDown} />
          </Space>
        </center>
      </div>
      <CustomRow gutter={[8, 8]} justify='center' style={{ minWidth: 1600 }}>
        {/* INTAKE METHOD */}
        <Col>
          <CustomRow gutter={[4, 4]}>
            {ScriptPanelSetions.appMethod.map((array) => {
              return (
                <Col span={12}>
                  <Space direction='vertical'>
                    {array.map((method) => (
                      <CustomButton
                        size='small'
                        style={{ color: 'white', background: '#FF4433', width: 100 }}
                        text={method}
                        onClick={() => {
                          handleValueChange(method)
                        }}
                      />
                    ))}
                  </Space>
                </Col>
              )
            })}
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            <Col>
              <Space direction='vertical'>
                {ScriptPanelSetions.dosageMetric.map((array) => (
                  <Space>
                    {array.map((metric) => (
                      <CustomButton
                        size='small'
                        style={{ color: 'white', background: 'green', width: 40 }}
                        text={metric}
                        onClick={() => {
                          handleValueChange(metric)
                        }}
                      />
                    ))}
                  </Space>
                ))}
              </Space>
            </Col>
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            <Col>
              <Space direction='vertical'>
                <Space direction='vertical'>
                  <CustomButton
                    size='small'
                    style={{ color: 'white', background: 'blue', width: 100 }}
                    text={
                      state[currentDescFocus]?.rxUnit?.toLowerCase()
                        ? state[currentDescFocus]?.rxUnit?.toLowerCase() + '(s)'
                        : ''
                    }
                    onClick={() => {
                      if (state[currentDescFocus]?.rxUnit?.toLowerCase())
                        handleValueChange(state[currentDescFocus]?.rxUnit?.toLowerCase() + '(s)')
                    }}
                  />
                </Space>
                <Space direction='vertical'>
                  <CustomButton
                    size='small'
                    style={{ color: 'white', background: 'goldenrod', width: 100 }}
                    text='into left'
                    onClick={() => {
                      handleValueChange('into left')
                    }}
                  />
                  <CustomButton
                    size='small'
                    style={{ color: 'white', background: 'goldenrod', width: 100 }}
                    text='into right'
                    onClick={() => {
                      handleValueChange('into right')
                    }}
                  />
                  <CustomButton
                    size='small'
                    style={{ color: 'white', background: 'goldenrod', width: 100 }}
                    text='into both'
                    onClick={() => {
                      handleValueChange('into both')
                    }}
                  />
                </Space>
              </Space>
            </Col>
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            <Col>
              <Space direction='vertical'>
                {ScriptPanelSetions.bodyPart.map((part) => (
                  <CustomButton
                    size='small'
                    style={{ color: 'white', background: '#5A5A5A', width: 100 }}
                    text={part}
                    onClick={() => {
                      handleValueChange(part)
                    }}
                  />
                ))}
              </Space>
            </Col>
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            <Col>
              <Space direction='vertical'>
                {ScriptPanelSetions.timing.map((array) => (
                  <Space>
                    {array.map((time) => {
                      if (time) {
                        return (
                          <CustomButton
                            size='small'
                            style={{ color: 'white', background: 'blue', width: 60 }}
                            text={time.label}
                            onClick={() => {
                              handleValueChange(time.value)
                            }}
                          />
                        )
                      } else {
                        return <div style={{ width: 60 }}> </div>
                      }
                    })}
                  </Space>
                ))}
              </Space>
            </Col>
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            <Col>
              <Space direction='vertical'>
                <CustomButton
                  size='small'
                  style={{ color: 'white', background: 'blue', width: 70 }}
                  text='every'
                  onClick={() => {
                    handleValueChange('every')
                  }}
                />
                <CustomButton
                  size='small'
                  style={{ color: 'white', background: 'blue', width: 70 }}
                  text='per'
                  onClick={() => {
                    handleValueChange('per')
                  }}
                />
                <CustomButton
                  size='small'
                  style={{ color: 'white', background: 'blue', width: 70 }}
                  text='for'
                  onClick={() => {
                    handleValueChange('for')
                  }}
                />
              </Space>
            </Col>
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            {ScriptPanelSetions.duration.map((array) => (
              <Col span={12}>
                <Space direction='vertical'>
                  {array.map((number) => (
                    <CustomButton
                      size='small'
                      style={{ color: 'white', background: 'green', width: 40 }}
                      text={number}
                      onClick={() => {
                        handleValueChange(number)
                      }}
                    />
                  ))}
                </Space>
              </Col>
            ))}
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            <Col>
              <Space direction='vertical'>
                {ScriptPanelSetions.frequency.map((freq) => (
                  <CustomButton
                    size='small'
                    style={{ color: 'white', background: 'goldenrod', width: 100 }}
                    text={freq}
                    onClick={() => {
                      handleValueChange(freq)
                    }}
                  />
                ))}
              </Space>
            </Col>
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            <Col>
              <Space direction='vertical'>
                <CustomButton
                  size='small'
                  style={{ color: 'white', background: '#5A5A5A', width: 120 }}
                  text='before meal(s)'
                  onClick={() => {
                    handleValueChange('before meal(s)')
                  }}
                />
                <CustomButton
                  size='small'
                  style={{ color: 'white', background: '#5A5A5A', width: 120 }}
                  text='with meal(s)'
                  onClick={() => {
                    handleValueChange('with meal(s)')
                  }}
                />
                <CustomButton
                  size='small'
                  style={{ color: 'white', background: '#5A5A5A', width: 120 }}
                  text='after meal(s)'
                  onClick={() => {
                    handleValueChange('after meal(s)')
                  }}
                />
              </Space>
            </Col>
          </CustomRow>
        </Col>
        <Col>
          <CustomRow gutter={[4, 4]}>
            {ScriptPanelSetions.closeOff.map((array) => (
              <Col span={12}>
                <Space direction='vertical'>
                  {array.map((close) => (
                    <CustomButton
                      size='small'
                      style={{ color: 'white', background: '#FF4433', width: 160 }}
                      text={close}
                      onClick={() => {
                        handleValueChange(close)
                      }}
                    />
                  ))}
                </Space>
              </Col>
            ))}
          </CustomRow>
        </Col>
      </CustomRow>
    </div>
  )
}

export default ScriptDescriptionBuilderPanel
