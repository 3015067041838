import { Row } from 'antd'
import React from 'react'
import { deviceDetect } from 'react-device-detect'

const CustomRow = (props) => {
  return (
    <Row
      {...props}
      style={{ width: '100%', ...props.style }}
      gutter={props.gutter ? props.gutter : [deviceDetect().isMobile ? 8 : 12, 12]}
      justify={props.justify}
      className={props.className}
      onClick={props.onClick}
      id={props?.id}
    >
      {props.children}
    </Row>
  )
}

export default CustomRow
