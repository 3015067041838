import { Col, DatePicker, Select, Space, message, theme } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { readAllBookings } from '../../Admin/bookings/utils/utils'
import dayjs from 'dayjs'
import { isAlreadyBooked, willOverlap } from '../../Bookings/utils/lib'
import { executeBooking, registerBooking } from '../../Bookings/utils/bpm-utils'
import { createBooking } from '../../Bookings/utils/utils'
import { convertDateTimeSAST, eachHalfHour, generateOTP, handleError } from '../../lib/utils'
import CustomRow from '../../reusable-components/CustomRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import CustomButton from '../../reusable-components/CustomButton'

const FollowUpBookingCapture = ({ bookingData, userRef, setModalOpen }) => {
  const state = useSelector((state) => state)
  const [followUpSlotsLoading, setFollowUpSlotsLoading] = useState(false)
  const [followUpDetails, setFollowUpDetails] = useState({
    bookingDate: '',
    startTime: '',
    service: null
  })
  const [availableFollowUps, setAvailableFollowUps] = useState(null)
  const disabledFollowUpDate = (current) => {
    return current && current < dayjs().startOf('day')
  }
  const [creatingBooking, setCreatingBooking] = useState(false)

  const makeFollowUpBooking = async () => {
    let serviceInfo = null
    let clinicRecord = null
    let data = null

    let upcomingBookings = []
    let unavailableTimeslots = []
    let processRecord = null

    setCreatingBooking(true)

    try {
      if (bookingData) {
        data = JSON.parse(JSON.stringify(bookingData))
        clinicRecord = bookingData.clinicRecord
        serviceInfo =
          state.services.data.find((item) => item._id === bookingData.service) ||
          state.virtualServices.data.find((item) => item._id === bookingData.service)
      } else {
        data = JSON.parse(JSON.stringify({ service: followUpDetails.service }))
        clinicRecord = state.auth.agiliteUser.extraData.clinics[0]
        serviceInfo =
          state.services.data.find((item) => item._id === followUpDetails.service) ||
          state.virtualServices.data.find((item) => item._id === followUpDetails.service)
      }

      // Check again that the slot hasn't been taken since initial check.
      upcomingBookings = await readAllBookings({
        bookingDate: dayjs(followUpDetails.bookingDate).format('YYYY-MM-DD'),
        medicalProfRef: state.auth.agiliteUser._id
      })
      upcomingBookings.forEach((booking) => {
        unavailableTimeslots.push([booking.startTime, booking.endTime])
      })

      if (
        isAlreadyBooked(followUpDetails.startTime, unavailableTimeslots) ||
        willOverlap(followUpDetails.startTime, data, unavailableTimeslots)
      ) {
        return false
      }

      // BPM
      processRecord = await registerBooking(
        clinicRecord.bpmKey,
        `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`
      )
      processRecord = await executeBooking(
        clinicRecord.bpmKey,
        processRecord.recordId,
        'submit',
        `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`,
        'create_booking'
      )

      delete data._id
      delete data.__v
      delete data.examinationData

      await createBooking({
        bookingDate: convertDateTimeSAST(followUpDetails.bookingDate, true),
        medicalProfRef: state.auth.agiliteUser._id,
        startTime: followUpDetails.startTime,
        endTime: dayjs(followUpDetails.startTime, 'HH:mm').add(serviceInfo.timeslotInterval, 'minutes').format('HH:mm'),
        chiefComplaint: 'Follow-up',
        dateCreated: new Date(),
        processRef: processRecord.recordId,
        otp: generateOTP(6),
        status: processRecord.processStage,
        userRef: userRef ? userRef : bookingData.userRef,
        service: followUpDetails.service,
        clinicRef: clinicRecord._id
      })

      handleReset()
    } catch (e) {
      message.error(handleError(e, true))
    }
    setCreatingBooking(false)
  }
  const getAvailableFollowUps = async (date) => {
    let upcomingBookings = []
    let unavailableTimeslots = []
    let tmpAvailableSlots = []
    let availableSlots = []

    setFollowUpSlotsLoading(true)
    try {
      upcomingBookings = await readAllBookings({
        bookingDate: dayjs(date).format('YYYY-MM-DD'),
        medicalProfRef: state.auth.agiliteUser._id
      })
      upcomingBookings.forEach((booking) => {
        unavailableTimeslots.push([booking.startTime, booking.endTime])
      })
      tmpAvailableSlots = eachHalfHour('06:00', '22:00')
      tmpAvailableSlots.forEach((slot) => {
        if (slot.length === 4) {
          slot = '0' + slot
        }
        if (
          !isAlreadyBooked(slot, unavailableTimeslots) &&
          !willOverlap(slot, { service: followUpDetails.service }, unavailableTimeslots)
        ) {
          if (dayjs(date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
            // if the follow up booking is today, don't add a slot that is in the past
            if (slot > dayjs().format('HH:mm')) {
              availableSlots.push(slot)
            }
          } else {
            availableSlots.push(slot)
          }
        }
      })
    } catch (e) {
      message.error(handleError(e))
    }
    setFollowUpSlotsLoading(false)
    setAvailableFollowUps(availableSlots)
  }

  const handleReset = () => {
    setFollowUpDetails({
      bookingDate: '',
      service: '',
      startTime: ''
    })
    setModalOpen(false)
  }

  const { token } = theme.useToken()
  return (
    <CustomRow>
      <Col span={24}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <h1>Book a Follow-Up Visit</h1>
          <FontAwesomeIcon
            style={{ float: 'right', cursor: 'pointer' }}
            fontSize={24}
            icon={faXmarkCircle}
            color={token.colorError}
            onClick={() => {
              if (!creatingBooking) {
                setModalOpen(false)
              } else {
                message.warning('Busy creating booking, please wait.')
              }
            }}
          />
        </Space>
      </Col>{' '}
      <Col span={24}>
        <Space wrap>
          <p style={{ minWidth: 125 }}>Service:</p>
          <Select
            style={{ width: 'auto' }}
            showSearch
            optionFilterProp='label'
            placeholder='- Select appointment service -'
            value={followUpDetails.service}
            onChange={(service) => {
              setFollowUpDetails({ ...followUpDetails, service })
            }}
            options={state.services.data.map((service) => ({
              label: service.name,
              value: service._id
            }))}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Space wrap>
          <p style={{ minWidth: 125 }}>Booking Date:</p>

          <DatePicker
            disabled={!followUpDetails.service}
            value={followUpDetails.bookingDate}
            format='DD MMMM YYYY'
            disabledDate={disabledFollowUpDate}
            placeholder='Select Booking Date'
            style={{ width: 200 }}
            onChange={(date) => {
              if (date) {
                setFollowUpDetails({ ...followUpDetails, bookingDate: date })
                getAvailableFollowUps(date)
              } else {
                setFollowUpDetails({ ...followUpDetails, bookingDate: null })
                setAvailableFollowUps(null)
              }
            }}
          />
        </Space>
      </Col>
      {followUpSlotsLoading ? (
        <Col span={24}>
          <AgiliteSkeleton spinnerTip='' />
        </Col>
      ) : undefined}
      {availableFollowUps && !followUpSlotsLoading ? (
        <Col span={24}>
          <Space wrap>
            <p style={{ minWidth: 125 }}>Booking Time:</p>
            <Select
              value={followUpDetails.startTime}
              onChange={(time) => {
                setFollowUpDetails({ ...followUpDetails, startTime: time })
              }}
              placeholder='Select Booking Time'
              options={availableFollowUps.map((time) => {
                if (time.length === 4) {
                  time = '0' + time
                }
                return {
                  value: time,
                  label: time
                }
              })}
              style={{ width: 200 }}
            />
          </Space>
        </Col>
      ) : undefined}
      <Col span={24}>
        <CustomButton
          size='small'
          type='success'
          style={{ float: 'right' }}
          text='Confirm Follow-Up'
          onClick={() => {
            makeFollowUpBooking()
          }}
          loading={creatingBooking}
        />
      </Col>
    </CustomRow>
  )
}

export default FollowUpBookingCapture
