// src/BluetoothContext.js
import React from 'react'

const BluetoothContext = React.createContext({
  bleDevice: null,
  bleCommandCharacteristic: null,
  bleNotifyCharacteristic: null,
  requestDevice: () => {},
  connectDevice: () => {},
  disconnectDevice: () => {}, // **Add disconnectDevice to default context**
  engine: null
})

export default BluetoothContext
