import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, Empty, Modal, Popconfirm, Row, Space, Spin, Tooltip, message, theme } from 'antd'
import { handleError, hexToRGBA } from '../../lib/utils'
import {
  createClinicalNote,
  groupData,
  readClinicalEntries,
  readClinicalNotes,
  readClinicalReportRecords,
  updateClinicalNote,
  whisperCreate
} from '../examination-utils/examination-lib'
import CustomButton from '../../reusable-components/CustomButton'
import dayjs from 'dayjs'
import DiagnosisCapture from './diagnosis-capture'
import debounce from 'lodash/debounce'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBookMedical,
  faChainBroken,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
  faEarListen,
  faHistory,
  faPencil,
  faRefresh,
  faRobot,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons'
import SickNoteCapture from './sick-note-capture'
import RefferalLetterCapture from './referral-letter-capture'
import FollowUpBookingCapture from './follow-up-booking-capture'
import ReactQuill from 'react-quill'
import ExaminationWrapper from './examination-wrapper'
import { assistantInitiate } from '../../AI Tools/utils'
import { AssistantEnums } from '../../AI Tools/assitants-enums'
import { generateAIResponse } from '../../AI Tools/assistants-lib'
import FeatureButton from '../../reusable-components/FeatureButton'
import '../styling/notes.css'
import { readMedicalHistory } from '../../Admin/patients/utils/utils'
import ClinicalNotesTemplates from './clinical-notes-templates'
import Dictaphone from '../../lib/dictaphone'
import DictaphoneControls from '../../lib/dictaphone-controls'
import '../../Examination/examination-utils/style.css'
import VoiceRecorder from '../../reusable-components/VoiceRecorder'
import CustomLoadingIcon from '../../reusable-components/CustomLoadingIcon'
import { isNurse } from '../../lib/profile-utils'
import ScheduleVirtualConsult from './schedule-virtual-consult'

const ClinicalNotes = ({ setBookingData, bookingData, userRef, noBooking, setModalOpen }) => {
  const authState = useSelector((state) => state.auth)
  const [autoGeneratingNotes, setAutoGeneratingNotes] = useState(false)
  const [currentNote, setCurrentNote] = useState('')
  const [historicalNotes, setHistoricalNotes] = useState([])
  const [submittingNewNote, setSubmittingNewNote] = useState(false)
  const [historicalNotesLoading, setHistoricalNotesLoading] = useState(false)
  const [noteId, setNoteId] = useState(null)
  const [updatingNotes, setUpdatingNotes] = useState(false)
  const [fetchingError, setFetchingError] = useState('')
  const [updatingError, setUpdatingError] = useState('')
  const [assessmentModalOpen, setAssessmentModalOpen] = useState('')
  const [clinicalNotesTemplatesOpen, setClinicalNotesTemplatesOpen] = useState(false)
  const [referralModalOpen, setReferralModalOpen] = useState(false)
  const [sickNoteModalOpen, setSickNoteModalOpen] = useState(false)
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false)
  const [virtualConsultModalOpen, setvirtualConsultModalOpen] = useState(false)
  const [showHistorical, setShowHistorical] = useState(false)
  const [currentHistroicalNoteIndex, setCurrentHistoricalNoteIndex] = useState(0)
  const [transcriptionAiListening, setTranscriptionAiListening] = useState(null)
  const [recordingTracker, setRecordingTracker] = useState(false)
  // const [generatingAiTranscription, setGeneratingAiTranscription] = useState(false)
  const [creatingNote, setCreatingNote] = useState(false)

  const [isRecording, setIsRecording] = useState(false)
  const [tmpTranscript, setTmpTranscript] = useState('')

  useEffect(() => {
    handleReadHistoricalNotes()
    // eslint-disable-next-line
  }, [])

  const handleAutoGenerateNotes = async () => {
    let tmpContent = ''
    let tmpClinicalData = null
    let aiResponse = null
    let medicalHistory = null
    let clinicalReports = null
    const payload = {}

    setAutoGeneratingNotes(true)
    try {
      // Fetch clinical data
      tmpClinicalData = await readClinicalEntries({ userRef: bookingData?.userRef ? bookingData.userRef : userRef })
      medicalHistory = await readMedicalHistory({ userRef: bookingData?.userRef ? bookingData.userRef : userRef })
      clinicalReports = await readClinicalReportRecords({ bookingRef: bookingData._id })

      // Prep payload
      payload.patientDetails = bookingData?.patientRecord
      payload.clinicalData = groupData(tmpClinicalData)
      payload.medicalHistory = medicalHistory
      payload.clinicalReports = clinicalReports

      if (currentNote) {
        payload.existingContent = currentNote
      }

      // Contact AI
      const tmpData = await assistantInitiate(JSON.stringify(payload), AssistantEnums.assistant_ids.CLINICAL_NOTES)

      aiResponse = await generateAIResponse(tmpData)

      tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value
    } catch (e) {
      message.error(handleError(e))
    }
    setAutoGeneratingNotes(false)
    setUpdatingNotes(true)
    setCurrentNote(tmpContent)
    debouncedNoteCapture(noteId, tmpContent)
  }

  useEffect(() => {
    if (!noBooking) {
      checkForExistingNote()
    }
    // eslint-disable-next-line
  }, [])

  const checkForExistingNote = async () => {
    setSubmittingNewNote(true)

    let existingNote = null
    let qry = {}
    try {
      qry.bookingRef = bookingData._id
      qry.createdBy = authState.agiliteUser._id
      existingNote = await readClinicalNotes(qry)
      if (existingNote[existingNote?.length - 1]) {
        setCurrentNote(existingNote[existingNote?.length - 1].content)
        setNoteId(existingNote[existingNote?.length - 1]._id)
      }
      setFetchingError('')
    } catch (e) {
      setFetchingError(handleError(e, true))
    }

    setSubmittingNewNote(false)
  }
  const handleUpdateNote = async (noteId, newNote) => {
    if (noteId) {
      try {
        await updateClinicalNote(noteId, newNote)
        setUpdatingError('')
      } catch (e) {
        setUpdatingError(handleError(e))
      }
    }
    setUpdatingNotes(false)
  }

  // eslint-disable-next-line
  const debouncedNoteCapture = useCallback(
    debounce((noteId, newNote) => {
      handleUpdateNote(noteId, newNote)
    }, 3000),
    []
  )

  const handleReadHistoricalNotes = async () => {
    const qry = {}
    let tmpData = {}

    setHistoricalNotesLoading(true)
    try {
      qry.userRef = bookingData?.userRef ? bookingData.userRef : userRef
      if (isNurse()) {
        qry.isDoctor = false
      }
      tmpData = await readClinicalNotes(qry)
      tmpData = tmpData.filter((note) => note._id !== noteId)
      tmpData = tmpData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setHistoricalNotes(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setHistoricalNotesLoading(false)
  }

  const handleModalReset = () => {
    setFollowUpModalOpen(false)
    setReferralModalOpen(false)
    setSickNoteModalOpen(false)
  }

  useEffect(() => {
    setTmpTranscript('')
  }, [currentNote])

  const { token } = theme.useToken()

  const handleAiTranscription = async (file) => {
    let aiResponse = null
    let tmpContent = null
    try {
      const response = await whisperCreate(file)

      // Contact AI
      const tmpData = await assistantInitiate(response, AssistantEnums.assistant_ids.CLINICAL_NOTES)

      aiResponse = await generateAIResponse(tmpData)
      tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value

      setTranscriptionAiListening(false)
      setCurrentNote(tmpContent)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleCreateNote = async () => {
    setCreatingNote(true)
    let newNote = null
    try {
      newNote = await createClinicalNote('', bookingData)
      setNoteId(newNote._id)
      setCurrentNote('')
    } catch (error) {
      message.error(handleError(error))
    }
    setCreatingNote(false)
  }

  // TODO: Create Error
  const isDisabled =
    autoGeneratingNotes ||
    updatingError ||
    fetchingError ||
    updatingNotes ||
    creatingNote ||
    updatingNotes ||
    submittingNewNote ||
    historicalNotesLoading ||
    !noteId
  return (
    <>
      <CustomRow>
        {noBooking ? (
          <Col span={24}>
            <FontAwesomeIcon
              size='2x'
              color={token.colorError}
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => {
                setModalOpen(false)
              }}
              icon={faCircleXmark}
            />
          </Col>
        ) : undefined}
        {!noBooking ? (
          <Col span={24}>
            <DiagnosisCapture bookingData={bookingData} />
          </Col>
        ) : undefined}

        <Col span={24} style={{ minHeight: '100%', position: 'relative' }}>
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr auto', gap: 12 }}>
            <Row>
              <Col span={24}>
                <Card
                  bodyStyle={!noteId ? { padding: 0 } : { padding: 0 }}
                  size='small'
                  extra={
                    <>
                      {showHistorical || noBooking ? (
                        <Space>
                          <Button
                            disabled={historicalNotesLoading}
                            onClick={() => {
                              setShowHistorical(false)
                            }}
                          >
                            Show Current <FontAwesomeIcon icon={faPencil} />
                          </Button>
                          <Button
                            disabled={historicalNotesLoading}
                            style={{ background: historicalNotesLoading ? '' : token.colorSuccess }}
                            type='primary'
                            onClick={() => {
                              handleReadHistoricalNotes()
                            }}
                          >
                            Sync Notes <FontAwesomeIcon icon={faRefresh} />
                          </Button>
                        </Space>
                      ) : (
                        <div>
                          {updatingNotes || submittingNewNote ? (
                            <Space style={{ alignItems: 'center' }}>
                              <small>{submittingNewNote ? 'LOADING NOTE' : 'SAVING'}</small>
                              <Spin spinning />
                            </Space>
                          ) : (
                            <>
                              {showHistorical ? undefined : (
                                <div style={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                                  <small>{fetchingError || updatingError ? 'ERROR' : 'SAVED'}</small>
                                  <FontAwesomeIcon
                                    fontSize={20}
                                    icon={fetchingError || updatingError ? faChainBroken : faCheckCircle}
                                    color={fetchingError || updatingError ? token.colorError : token.colorSuccess}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  }
                >
                  {showHistorical || noBooking ? (
                    <>
                      {historicalNotes?.length > 0 ? (
                        <div
                          style={{
                            display: showHistorical || noBooking ? '' : 'none'
                          }}
                        >
                          <div
                            style={{
                              padding: '12px 15px',
                              border: '1px solid #ccc',
                              borderBottom: 0
                            }}
                          >
                            <center>
                              <Space>
                                <FontAwesomeIcon
                                  style={{
                                    cursor:
                                      currentHistroicalNoteIndex === historicalNotes.length - 1 ? 'disabled' : 'pointer'
                                  }}
                                  fontSize={16}
                                  icon={faChevronLeft}
                                  onClick={() => {
                                    if (currentHistroicalNoteIndex === 0) {
                                      setCurrentHistoricalNoteIndex(historicalNotes.length - 1)
                                    } else {
                                      setCurrentHistoricalNoteIndex(currentHistroicalNoteIndex - 1)
                                    }
                                  }}
                                />
                                {currentHistroicalNoteIndex + 1} / {historicalNotes.length}
                                <FontAwesomeIcon
                                  fontSize={16}
                                  icon={faChevronRight}
                                  style={{
                                    cursor:
                                      currentHistroicalNoteIndex === historicalNotes.length - 1 ? 'disabled' : 'pointer'
                                  }}
                                  onClick={() => {
                                    if (currentHistroicalNoteIndex === historicalNotes.length - 1) {
                                      setCurrentHistoricalNoteIndex(0)
                                    } else {
                                      setCurrentHistoricalNoteIndex(currentHistroicalNoteIndex + 1)
                                    }
                                  }}
                                />
                              </Space>
                            </center>
                          </div>
                          <Row
                            gutter={[0, 24]}
                            style={{
                              height: 600,
                              overflow: 'scroll',
                              alignContent: 'baseline',
                              border: '1px solid #ccc',
                              padding: '12px 15px'
                            }}
                          >
                            <Col span={24}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                  <h4>
                                    Recorded by{' '}
                                    {historicalNotes[currentHistroicalNoteIndex]?.createdBy ===
                                    authState.agiliteUser._id
                                      ? ' You '
                                      : historicalNotes[currentHistroicalNoteIndex]?.createdByFullName}
                                    {dayjs(historicalNotes[currentHistroicalNoteIndex]?.createdAt).format(
                                      'DD MMMM YYYY'
                                    ) === dayjs().format('DD MMMM YYYY') ? (
                                      ' Today '
                                    ) : (
                                      <>
                                        {' '}
                                        on{' '}
                                        {dayjs(historicalNotes[currentHistroicalNoteIndex]?.createdAt).format(
                                          'DD MMMM YYYY'
                                        )}{' '}
                                      </>
                                    )}
                                    at {dayjs(historicalNotes[currentHistroicalNoteIndex]?.createdAt).format('HH:mm')}
                                  </h4>

                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: historicalNotes[currentHistroicalNoteIndex]?.content
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div>
                          <Empty description='There are currently no historical notes to show. Use the sync notes button to check for any changes.' />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col span={5}>
                          {noBooking ? undefined : (
                            <div wrap style={{ display: 'flex', flexWrap: 'wrap', gap: 8, width: '100%', padding: 8 }}>
                              <Button
                                disabled={
                                  autoGeneratingNotes ||
                                  updatingError ||
                                  fetchingError ||
                                  updatingNotes ||
                                  creatingNote ||
                                  updatingNotes ||
                                  submittingNewNote ||
                                  historicalNotesLoading
                                }
                                style={{ width: '100%' }}
                                onClick={() => {
                                  setShowHistorical(!showHistorical)
                                }}
                              >
                                {!showHistorical ? (
                                  <>
                                    Show History <FontAwesomeIcon icon={faHistory} />
                                  </>
                                ) : (
                                  'Show Current'
                                )}
                              </Button>
                              {noteId && !showHistorical ? (
                                <Tooltip
                                  title={
                                    !currentNote ? "You can't add another note when your current note is empty." : ''
                                  }
                                >
                                  <Popconfirm
                                    overlayStyle={{ maxWidth: 450 }}
                                    onConfirm={() => {
                                      handleCreateNote()
                                    }}
                                    okText='I am sure'
                                    title='Add New Note'
                                    description='Are you sure? You will not be able to go back and edit your previous note after adding an additional note. Old notes can be accessed from the historical notes tab.'
                                  >
                                    <Button
                                      type='primary'
                                      style={{
                                        background: !currentNote || isDisabled ? '  ' : token.colorSuccess,
                                        width: '100%'
                                      }}
                                      disabled={!currentNote || isDisabled}
                                    >
                                      Add Note <FontAwesomeIcon icon={faPencil} />
                                    </Button>
                                  </Popconfirm>
                                </Tooltip>
                              ) : undefined}
                              <Button
                                disabled={isDisabled || showHistorical}
                                type='primary'
                                style={{
                                  width: '100%'
                                }}
                                onClick={() => setClinicalNotesTemplatesOpen(true)}
                              >
                                Templates <FontAwesomeIcon icon={faBookMedical} />
                              </Button>
                              {isRecording ? (
                                <Dictaphone
                                  buttonStyle={{ width: '100%' }}
                                  noteId={noteId}
                                  handleUpdateNote={handleUpdateNote}
                                  value={currentNote}
                                  setValue={setCurrentNote}
                                  tmpTranscript={tmpTranscript}
                                  setTmpTranscript={setTmpTranscript}
                                  setIsRecording={setIsRecording}
                                  isRecording={isRecording}
                                  disabled={isDisabled || showHistorical}
                                />
                              ) : (
                                <DictaphoneControls
                                  buttonStyle={{ width: '100%' }}
                                  disabled={isDisabled || showHistorical}
                                  startListening={() => setIsRecording(true)}
                                />
                              )}
                              <Button
                                style={{
                                  background: isDisabled ? '' : 'linear-gradient(45deg, #13B1E1, #F14F8A)',
                                  width: '100%'
                                }}
                                disabled={isDisabled || showHistorical}
                                type='primary'
                                onClick={() => handleAutoGenerateNotes()}
                              >
                                Generate Note (AI) <FontAwesomeIcon icon={faRobot} />
                              </Button>

                              <Button
                                disabled={isDisabled || showHistorical}
                                type='primary'
                                style={{
                                  background: isDisabled ? '' : 'linear-gradient(45deg, #13B1E1, #F14F8A)',
                                  width: '100%'
                                }}
                                onClick={() => {
                                  setTranscriptionAiListening(true)
                                }}
                              >
                                Transcribe (AI) <FontAwesomeIcon icon={faEarListen} />
                              </Button>
                            </div>
                          )}
                        </Col>
                        <Col span={19}>
                          <CustomLoadingIcon
                            loading={autoGeneratingNotes || submittingNewNote || historicalNotesLoading}
                            loadingText={
                              creatingNote
                                ? 'Creating Note...'
                                : autoGeneratingNotes
                                ? 'AI is Generating Clinical Notes...'
                                : 'Loading Notes...'
                            }
                            content={
                              <>
                                {!noteId ? (
                                  <Row gutter={[0, 12]}>
                                    <Col span={24}>
                                      <Empty description='You have no notes captured for this booking' />
                                    </Col>
                                    <Col span={24}>
                                      <center>
                                        <Button
                                          type='primary'
                                          style={{ background: token.colorSuccess }}
                                          onClick={() => {
                                            handleCreateNote()
                                          }}
                                        >
                                          Add Note
                                        </Button>
                                      </center>
                                    </Col>
                                  </Row>
                                ) : (
                                  <ReactQuill
                                    id={`${bookingData?._id}-notes-capture-area`}
                                    style={{ display: showHistorical ? 'none' : '' }}
                                    // style={{ opacity: fetchingError || updatingError ? 0.1 : 1 }}
                                    readOnly={showHistorical || isRecording}
                                    onChange={(e) => {
                                      if (!isRecording && !creatingNote) {
                                        setUpdatingNotes(true)
                                        setCurrentNote(e)
                                        debouncedNoteCapture(noteId, e)
                                      }
                                    }}
                                    value={currentNote + tmpTranscript}
                                    // disabled={autoGeneratingNotes || submittingNewNote || fetchingError || updatingError}
                                    // placeholder={autoGeneratingNotes ? 'Busy generating your clinical notes...' : 'Your notes here...'}
                                    // rows={14}
                                  />
                                )}
                              </>
                            }
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>

                {fetchingError || updatingError ? (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      background: hexToRGBA(token.colorError, 0.2),
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 30
                    }}
                  >
                    <center>
                      <Space direction='vertical'>
                        <h2>Oops something went wrong.</h2>
                        <p>{fetchingError ? fetchingError : updatingError}</p>
                        <CustomButton
                          size='small'
                          text='Retry'
                          type='success'
                          onClick={() => {
                            if (fetchingError) {
                              setFetchingError('')
                              setSubmittingNewNote(true)
                              checkForExistingNote()
                            } else {
                              setUpdatingError('')
                              setUpdatingNotes(true)
                              handleUpdateNote(noteId, currentNote)
                            }
                          }}
                        />
                      </Space>
                    </center>
                  </div>
                ) : undefined}
              </Col>
            </Row>
            {!noBooking ? (
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Card>
                    <Space direction='vertical'>
                      {isNurse() && !noBooking ? (
                        <FeatureButton
                          background='linear-gradient(45deg, green, lightgreen)'
                          backgroundHover='green'
                          text='VIRTUAL CONSULT'
                          onClick={() => setvirtualConsultModalOpen(true)}
                        />
                      ) : undefined}
                      <FeatureButton
                        background='linear-gradient(45deg, #DC143C, #FF7F50)'
                        backgroundHover='#DC143C'
                        text='ASSESSMENT'
                        onClick={() => setAssessmentModalOpen(true)}
                      />
                      <FeatureButton
                        background='linear-gradient(45deg, #50C878,  #40E0D0)'
                        backgroundHover='#50C878'
                        text='REFERRAL'
                        onClick={() => setReferralModalOpen(true)}
                      />
                      <FeatureButton
                        background='linear-gradient(45deg,#FF69B4, #FF1493)'
                        backgroundHover='#FF69B4'
                        text='SICK NOTE'
                        onClick={() => setSickNoteModalOpen(true)}
                      />
                      <FeatureButton
                        background='linear-gradient(45deg, #FFD700, #FFA500'
                        backgroundHover='#FFD700'
                        text='FOLLOW-UP'
                        onClick={() => setFollowUpModalOpen(true)}
                      />
                    </Space>
                  </Card>
                </Col>
              </Row>
            ) : undefined}
          </div>
        </Col>

        <Modal
          footer={false}
          maskClosable={false}
          closable={false}
          width={assessmentModalOpen ? 1920 : followUpModalOpen ? 500 : 950}
          open={
            followUpModalOpen ||
            sickNoteModalOpen ||
            referralModalOpen ||
            assessmentModalOpen ||
            clinicalNotesTemplatesOpen ||
            virtualConsultModalOpen
          }
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          onCancel={() => {
            handleModalReset()
          }}
          destroyOnClose
        >
          {assessmentModalOpen ? (
            <>
              <FontAwesomeIcon
                onClick={() => {
                  setAssessmentModalOpen(false)
                }}
                style={{ float: 'right', cursor: 'pointer' }}
                icon={faXmarkCircle}
                color={token.colorError}
                fontSize={26}
              />
              <ExaminationWrapper bookingData={bookingData} userRef={userRef} />{' '}
            </>
          ) : undefined}

          {followUpModalOpen ? (
            <FollowUpBookingCapture setModalOpen={setFollowUpModalOpen} bookingData={bookingData} />
          ) : undefined}
          {referralModalOpen ? (
            <RefferalLetterCapture setModalOpen={setReferralModalOpen} bookingData={bookingData} />
          ) : undefined}
          {sickNoteModalOpen ? (
            <SickNoteCapture setModalOpen={setSickNoteModalOpen} bookingData={bookingData} />
          ) : undefined}
          {clinicalNotesTemplatesOpen ? (
            <ClinicalNotesTemplates
              setModalOpen={setClinicalNotesTemplatesOpen}
              setValue={setCurrentNote}
              setUpdatingNotes={setUpdatingNotes}
              debouncedNoteCapture={debouncedNoteCapture}
              noteId={noteId}
            />
          ) : undefined}
          {virtualConsultModalOpen ? (
            <ScheduleVirtualConsult
              setBookingData={setBookingData}
              bookingData={bookingData}
              targetPatient={bookingData.patientRecord}
              setModalOpen={setvirtualConsultModalOpen}
            />
          ) : undefined}
        </Modal>
      </CustomRow>
      <Modal
        destroyOnClose
        onCancel={() => {
          setTranscriptionAiListening(false)
        }}
        open={transcriptionAiListening}
        onOk={() => {
          handleAiTranscription()
        }}
      >
        <div
          style={{
            background: 'linear-gradient(45deg, #13B1E1, #F14F8A)',
            height: 400,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            borderRadius: 12
          }}
        >
          <FontAwesomeIcon
            className={recordingTracker ? 'pulse' : ''}
            fontSize={120}
            icon={faEarListen}
            color='#ffffff'
            style={{ position: 'absolute' }}
          />
          <div style={{ position: 'absolute' }}>
            <VoiceRecorder setRecordingTracker={setRecordingTracker} handleOnStop={handleAiTranscription} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ClinicalNotes
