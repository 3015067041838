const SystemUsersEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    AUTH_USERS: 'auth_users'
  },
  routeKeys: {
    FIND: 'find',
    COUNT: 'count'
  }
}

export default SystemUsersEnums
