import { CheckCircleTwoTone } from '@ant-design/icons'
import { Card, Col, Row, theme } from 'antd'
import React from 'react'
import PaymentBackground from '../../../assets/images/Payment-Background.jpg'
import CustomRow from '../../reusable-components/CustomRow'

const PaymentSucceeded = () => {
  const { token } = theme.useToken()

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundImage: `url(${PaymentBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <CustomRow justify='center' style={{ width: '100%' }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={12} xxl={10}>
            <Card
              headStyle={{ backgroundColor: token.colorPrimary }}
              title={<center style={{ color: '#fff', fontSize: 20 }}>Payment Successful</center>}
            >
              <Row justify='center'>
                <Col>
                  <CheckCircleTwoTone twoToneColor='#52c41a' style={{ fontSize: 80 }} />
                </Col>
                <Col style={{ marginTop: 10, marginBottom: 20 }} span={24}>
                  <center>
                    <b>Your transaction has been successfully processed</b>
                    <br />
                    <br />
                    <i>Please return to the Meta-Clinic Portal to view your booking.</i>
                  </center>
                </Col>
              </Row>
            </Card>
          </Col>
        </CustomRow>
      </div>
    </>
  )
}

export default PaymentSucceeded
