import { Button, Card, Col, Table, theme } from 'antd'
import React from 'react'
import { hexToRGBA } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import { DataComplianceTableTemplates } from './utils/DataComplianceTableTemplates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const PatientOverview = ({ currentPatient, setCurrentPatient }) => {
  const { token } = theme.useToken()
  return (
    <CustomRow>
      <Col span={24}>
        <FontAwesomeIcon icon={faExclamationCircle} color={token.colorWarning} /> Please note that compliance may take
        up to 24 hours to reflect from the time of submission.
      </Col>
      <Col span={12}>
        <Card headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }} size='small' title='Test Compliance'>
          <Table
            size='small'
            bordered
            columns={DataComplianceTableTemplates(token).columnTemplate}
            dataSource={currentPatient.compliance?.filter((i) => i.trackerType === 'data')}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }} size='small' title='Visit Compliance'>
          <Table
            size='small'
            bordered
            columns={DataComplianceTableTemplates(token).columnTemplate}
            dataSource={currentPatient.compliance?.filter((i) => i.trackerType === 'visits')}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Button
          style={{ float: 'right' }}
          onClick={() => {
            setCurrentPatient(null)
          }}
        >
          Back
        </Button>
      </Col>
    </CustomRow>
  )
}

export default PatientOverview
