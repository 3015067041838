import { Button, Card, Col, message, Space, theme } from 'antd'
import React from 'react'
import { Cell, Pie, PieChart } from 'recharts'
import { hexToRGBA } from '../../lib/utils'
import CustomRow from '../../reusable-components/CustomRow'

const MacroRings = ({ macroData, limits, servings, setServings }) => {
  // Function to render each nutrient's ring graph
  const renderNutrientGraph = (key, color) => {
    const value = setServings ? macroData.macros[key] * servings : macroData.macros[key]
    const percentage = (value / limits?.macros[key]) * 100

    const ringData = [{ value: percentage }, { value: 100 - percentage }]
    const lighterColor = hexToRGBA(color, 0.2) // Lighter  color for the unfilled part

    return (
      <div key={key} style={{ position: 'relative', width: '120px', height: '120px' }}>
        <PieChart width={120} height={120}>
          <Pie data={ringData} innerRadius={45} outerRadius={55} paddingAngle={5} dataKey='value' stroke='none'>
            {ringData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={index === 0 ? color : lighterColor} />
            ))}
          </Pie>
        </PieChart>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}
        >
          <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{`${value}g`}</span>
          <br />
          <span style={{ fontSize: '14px' }}>{`${getNutrientLabel(key)}`}</span>
        </div>
      </div>
    )
  }

  const getNutrientLabel = (key) => {
    switch (key) {
      case 'prot':
        return 'Protein'
      case 'carb':
        return 'Carbs'
      case 'fat':
        return 'Fats'
      case 'sug':
        return 'Sugar'
      default:
        return ''
    }
  }

  const { token } = theme.useToken()

  const handleServingSizeChange = (action) => {
    let tmpServings = servings
    if (action === 'decrement') {
      if (tmpServings === 1) {
        return message.error('Serving size cannot cant be zero')
      }
      tmpServings = tmpServings - 1
    } else if (action === 'increment') {
      tmpServings = tmpServings + 1
    }
    setServings(tmpServings)
  }
  return (
    <CustomRow>
      <Col span={24}>
        <Card
          style={{
            background: hexToRGBA(token.colorPrimary, 0.2),
            width: '100%',
            maxWidth: '500px',
            margin: '0 auto 1px'
          }}
        >
          {!setServings ? (
            <img src={macroData.image} alt={macroData.description} className='image-responsive' />
          ) : undefined}
          <p style={{ textAlign: 'center', marginTop: '10px' }}>{macroData.description}</p>
        </Card>
      </Col>
      {macroData && setServings ? (
        <Col span={24}>
          <Card style={{ backgroundColor: '#f9f9f9', borderColor: '#d9d9d9' }} bordered={true}>
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <p>Number of Servings:</p>
              <Space>
                <Button onClick={() => handleServingSizeChange('decrement')}>-</Button>
                <div>{servings}</div>
                <Button onClick={() => handleServingSizeChange('increment')}>+</Button>
              </Space>
            </Space>
          </Card>
        </Col>
      ) : undefined}

      <Card style={{ width: '100%', justifyContent: 'space-between' }}>
        <Col span={24}>
          {/* Ring graphs */}
          <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
            {renderNutrientGraph('prot', '#ff8000')}
            {renderNutrientGraph('fat', '#2ecc71')}
            {renderNutrientGraph('carb', '#3498db')}
            {renderNutrientGraph('sug', '#ff1493')}
          </div>
        </Col>

        {/* Total calories and serving size */}
        <Col span={24}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr', gap: 12, paddingTop: '15px' }}>
              <strong>Total Calories:</strong>
              <p>{setServings ? macroData.macros.cal * servings : macroData.macros.cal} kcal</p>
              <strong>Servings:</strong> <p>{setServings ? servings : macroData.servings}</p>
              <strong>Serving Size:</strong>
              <p>{setServings ? macroData.macros.grams * servings : macroData.macros.grams} g</p>
            </div>
          </div>
        </Col>
      </Card>
    </CustomRow>
  )
}

export default MacroRings
