import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { stringToColor } from '../../lib/utils'

export const StandardDataPointGraph = ({ data, dataTemplate }) => {
  const dateFormat = 'DD-MM-YYYY'
  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    handleSetGraphData()
    // eslint-disable-next-line
  }, [data])

  const handleSetGraphData = () => {
    let tmpData = JSON.parse(JSON.stringify(data))
    let tmpGraphData = []
    sortGraphData(tmpData?.records).forEach((record) => {
      let tmpObject = {}
      if (typeof record.value === 'object' && record.value !== null) {
        let tmpObject = {}
        tmpObject.name = dayjs(record.dateCreated).format(dateFormat)
        record.value.forEach((subValue) => {
          tmpObject[subValue.key] = subValue.value
        })
        tmpGraphData.push(tmpObject)
      } else {
        tmpObject.name = dayjs(record.dateCreated).format(dateFormat)
        tmpObject[record.key] = record.value
        tmpGraphData.push(tmpObject)
      }
    })

    setGraphData(tmpGraphData)
  }

  const CustomLabel = ({ x, y, stroke, value }) => {
    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
        {value}
      </text>
    )
  }

  const fetchGraphReferences = () => {
    let tmpGraphReferences = []
    if (dataTemplate.subFields?.length > 0) {
      dataTemplate.subFields.forEach((subField) => {
        tmpGraphReferences.push(
          <Line
            type='monotone'
            dataKey={subField.key}
            stroke={stringToColor(subField.key)}
            activeDot={{ r: 8 }}
            label={<CustomLabel />}
          />
        )
      })
    } else {
      tmpGraphReferences.push(
        <Line
          type='monotone'
          dataKey={data.key}
          stroke={stringToColor(data.key)}
          activeDot={{ r: 8 }}
          label={<CustomLabel />}
        />
      )
    }

    return tmpGraphReferences
  }

  const renderLegend = (value) => {
    if (dataTemplate.subFields.length > 0) {
      let subFieldEntry = dataTemplate.subFields.find((i) => i.key === value)
      return <span style={{ color: stringToColor(subFieldEntry.key) }}>{subFieldEntry.label}</span>
    } else {
      return <span style={{ color: stringToColor(dataTemplate.key) }}>{dataTemplate.label}</span>
    }
  }

  const sortGraphData = (data) => {
    return data.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
  }

  return (
    <ResponsiveContainer width={'100%'} height={250} style={{ background: 'white' }}>
      <LineChart
        width={'100%'}
        height={250}
        data={graphData}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          ticks={[
            dayjs(data.records[0]?.dateCreated).format(dateFormat),
            dayjs(data.records[data.records.length - 1]?.dateCreated).format(dateFormat)
          ]}
          dataKey='name'
        />
        <YAxis />
        <Tooltip />
        <Legend formatter={renderLegend} />
        {fetchGraphReferences().map((reference) => reference)}
      </LineChart>
    </ResponsiveContainer>
  )
}
