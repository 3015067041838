const CarePlansEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    CARE_PLANS_TEMPLATES: 'care_plans_templates',
    CARE_PLANS_RECORDS: 'care_plans_records'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    COUNT: 'count',
    DELETE_ONE: 'delete_one',
    DELETE_ONE_BY_FILTER: 'delete_one_by_filter'
  }
}

export default CarePlansEnums
