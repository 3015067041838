import { Button, Card, Col, Drawer, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandsHelping, faHeartCircleCheck, faInfoCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { checkSubscriptions } from '../../Subscriptions/utils/utils'
import { hexToRGBA } from '../../lib/utils'

const OverView = ({ setEnableNext, token, setShowNext }) => {
  const [open, setOpen] = useState(false)
  const [size, setSize] = useState()

  const showDrawer = () => {
    setSize('large')
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (checkSubscriptions('vitalLink')) {
      setEnableNext(false)
    } else {
      setEnableNext(true)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Row justify='center' style={{ marginBottom: 10 }}>
        <Col xs={24} sm={24} md={18} lg={12}>
          <Space direction='vertical'>
            <center style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
              <b>Welcome to Meta-Link</b>
            </center>
            <Space style={{ alignItems: 'flex-start', gap: 0 }}>
              <div
                style={{
                  width: 30,
                  padding: 8,
                  paddingRight: 5,
                  background: hexToRGBA(token.colorSecondary, 0.2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FontAwesomeIcon icon={faHeartCircleCheck} />
              </div>
              <p style={{ border: `3px ${hexToRGBA(token.colorSecondary, 0.2)} solid`, padding: 8, minHeight: '100%' }}>
                Your go to service for any emergency.
              </p>
            </Space>
            <Space style={{ alignItems: 'flex-start', gap: 0 }}>
              <div
                style={{
                  width: 30,
                  padding: 8,
                  paddingRight: 5,
                  background: hexToRGBA(token.colorSuccess, 0.2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FontAwesomeIcon icon={faHandsHelping} />
              </div>
              <p style={{ border: `3px ${hexToRGBA(token.colorSuccess, 0.2)} solid`, padding: 8, minHeight: '100%' }}>
                <b>One-Touch SOS:</b> Immediate connection to emergency services.
              </p>
            </Space>
            <Space style={{ alignItems: 'flex-start', gap: 0 }}>
              <div
                style={{
                  width: 30,
                  padding: 8,
                  paddingRight: 5,
                  background: hexToRGBA(token.colorSuccess, 0.2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FontAwesomeIcon icon={faHandsHelping} />
              </div>
              <p style={{ border: `3px ${hexToRGBA(token.colorSuccess, 0.2)} solid`, padding: 8, minHeight: '100%' }}>
                <b>No Paperwork:</b> We handle all details and authorizations.
              </p>
            </Space>
            <Space style={{ alignItems: 'flex-start', gap: 0 }}>
              <div
                style={{
                  width: 30,
                  padding: 8,
                  paddingRight: 5,
                  background: hexToRGBA(token.colorSuccess, 0.2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FontAwesomeIcon icon={faHandsHelping} />
              </div>
              <p style={{ border: `3px ${hexToRGBA(token.colorSuccess, 0.2)} solid`, padding: 8, minHeight: '100%' }}>
                <b>Continuous Updates:</b> Families stay informed every step of the way.
              </p>
            </Space>
            <Space style={{ alignItems: 'flex-start', gap: 0 }}>
              <div
                style={{
                  width: 30,
                  padding: 8,
                  paddingRight: 5,
                  background: hexToRGBA(token.colorSuccess, 0.2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FontAwesomeIcon icon={faHandsHelping} />
              </div>
              <p style={{ border: `3px ${hexToRGBA(token.colorSuccess, 0.2)} solid`, padding: 8, minHeight: '100%' }}>
                <b>Wide Network:</b> Coordination with EMS, hospitals, law enforcement and security.
              </p>
            </Space>
            {/* <Space style={{ alignItems: 'flex-start', gap: 0 }}>
              <div
                style={{
                  width: 30,
                  padding: 8,
                  paddingRight: 5,
                  background: hexToRGBA(token.colorSuccess, 0.2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FontAwesomeIcon icon={faHandsHelping} />
              </div>
              <p style={{ border: `3px ${hexToRGBA(token.colorSuccess, 0.2)} solid`, padding: 8, minHeight: '100%' }}>
                Peace of Mind: Your safety, our priority.
              </p>
            </Space> */}
            <Space style={{ alignItems: 'flex-start', gap: 0 }}>
              <div
                style={{
                  width: 30,
                  padding: 8,
                  paddingRight: 5,
                  background: hexToRGBA(token.colorSuccess, 0.2),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FontAwesomeIcon icon={faHandsHelping} />
              </div>
              <p style={{ border: `3px ${hexToRGBA(token.colorSuccess, 0.2)} solid`, padding: 8, minHeight: '100%' }}>
                <b>Mobile Panic Button (Optional):</b> Take it wherever you go. Equipped with a sim card, it will allow
                us to locate and communicate with you.
              </p>
            </Space>
          </Space>
          <center>
            <Button style={{ marginTop: 12 }} onClick={showDrawer} size='large'>
              <Space>
                Learn more <FontAwesomeIcon icon={faInfoCircle} color={token.colorSecondary} size='xl' />
              </Space>
            </Button>
            <Drawer
              style={{ marginTop: 12 }}
              bodyStyle={{ padding: 0 }}
              size={size}
              title='Meta-Link Process'
              onClose={onClose}
              open={open}
              closeIcon={<FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} size='2x' />}
            >
              <Card>
                <text>
                  <h1>
                    <b>Subscription Process: </b>
                  </h1>
                  <br />
                  <b>Step 1 - Subscription Model Selection:</b> Users begin by selecting their desired subscription
                  model, tailored to their specific requirements and preferences.
                  <br />
                  <br />
                  <b>Step 2 - Mobile Number Verification:</b> To ensure seamless communication and coordination during
                  emergencies, users are prompted to verify their mobile number. <br />
                  <br />
                  <b>Step 3 - Trip Details:</b> Subscribers provide relevant trip details to enhance the accuracy and
                  efficiency of emergency response efforts. <br />
                  <br />
                  <b>Step 4 - Summary and Payment:</b> A comprehensive summary of the subscription details is presented
                  to users for review, followed by a secure payment process to activate the subscription. <br />
                  <br />
                  <b>Step 5 - Payment Confirmation:</b> Upon successful payment, users receive confirmation of their
                  subscription activation, granting access to the Meta-Link emergency assistance service.
                </text>
              </Card>
            </Drawer>
          </center>
        </Col>
      </Row>
    </div>
  )
}

export default OverView
