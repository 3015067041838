import React, { memo } from 'react'
import { VirtualVisitPlayer } from './virtual-visit-player'
import { createClient, createMicrophoneAndCameraTracks } from 'agora-rtc-react'
import { Col, Row } from 'antd'

const config = {
  mode: 'rtc',
  codec: 'vp8'
}

export const VirtualVisit = memo(
  ({ uid, setJoined, joined, token, channel, patient, handleVideoMinimized, setLeftCall }) => {
    const useClient = createClient(config)
    const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()

    return (
      <Row style={{ height: '100%' }}>
        {joined ? (
          <Col span={24} style={{ height: '100%' }}>
            <VirtualVisitPlayer
              setLeftCall={setLeftCall}
              handleVideoMinimized={handleVideoMinimized}
              patient={patient}
              uid={uid}
              useClient={useClient}
              useMicrophoneAndCameraTracks={useMicrophoneAndCameraTracks}
              appId={process.env.REACT_APP_AGORA_APP_ID}
              token={token}
              setInCall={setJoined}
              channelName={channel}
            />
          </Col>
        ) : undefined}
      </Row>
    )
  }
)
