const PatientEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    AUTH_USERS: 'auth_users',
    PATIENT_RECORD: 'patients'
  },
  routeKeys: {
    FIND: 'find',
    READ: 'read'
  }
}

export default PatientEnums
