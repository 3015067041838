import { Form, Input, Space } from 'antd'

export const trackers = (isNewRecord) => {
  const inputFieldStyle = {
    width: 250,
    maxWidth: '100%'
  }
  return [
    {
      key: 'height',
      label: 'Height',
      addOnAfter: 'cm',
      render: () => (
        <Form.Item rules={[{ required: true }]} name='height' label='Height'>
          <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='cm' />
        </Form.Item>
      )
    },
    {
      key: 'weight',
      label: 'Weight',
      addOnAfter: 'kg',
      render: () => (
        <Form.Item rules={[{ required: true }]} name='weight' label='Weight'>
          <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='kg' />
        </Form.Item>
      )
    },
    {
      key: 'bmi',
      label: 'BMI',
      addOnAfter: '',
      dependencies: ['height', 'weight'],
      render: () => (
        <Form.Item rules={[{ required: true }]} name='bmi' label='BMI (Body Mass Index)'>
          <Input style={inputFieldStyle} type='number' placeholder='- Will auto calculate -' readOnly />
        </Form.Item>
      )
    },
    {
      key: 'oxygenSaturation',
      label: 'Oxygen Saturation',
      addOnAfter: '%',
      render: () => (
        <Form.Item rules={[{ required: true }]} name='oxygenSaturation' label='Oxygen Saturation'>
          <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='%' />
        </Form.Item>
      )
    },
    {
      key: 'temperature',
      label: 'Temperature',
      addOnAfter: '°C',
      render: () => (
        <Form.Item rules={[{ required: true }]} name='temperature' label='Temperature'>
          <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='°C' />
        </Form.Item>
      )
    },
    {
      key: 'bloodPressure',
      label: 'Blood Pressure',
      addOnAfter: 'mmHg',
      render: () => (
        <Space style={{ width: '100%' }} wrap>
          <Form.Item rules={[{ required: true }]} name={['bloodPressure', 'systolic']} label='Systolic'>
            <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='SBP' />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name={['bloodPressure', 'diastolic']} label='Diastolic'>
            <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='DBP' />
          </Form.Item>
        </Space>
      )
    },
    {
      key: 'pulse',
      label: 'Pulse',
      addOnAfter: 'bpm',
      render: () => (
        <Form.Item rules={[{ required: true }]} name='pulse' label='Pulse'>
          <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='bpm' />
        </Form.Item>
      )
    },
    {
      key: 'bloodGlucose',
      label: 'Blood Glucose',
      addOnAfter: 'mmol/L',
      render: () => (
        <Form.Item rules={[{ required: true }]} name='bloodGlucose' label='Blood Glucose'>
          <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='mmol/L' />
        </Form.Item>
      )
    },
    {
      key: 'cholesterol',
      label: 'Cholesterol',
      addOnAfter: 'mg/dL',
      render: () => (
        <Form.Item rules={[{ required: true }]} name='cholesterol' label='Cholesterol'>
          <Input style={inputFieldStyle} readOnly={!isNewRecord} type='number' addonAfter='mg/dL' />
        </Form.Item>
      )
    }
  ]
}
