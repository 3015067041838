import { assistantCheckRunStatus, assistantReadThread } from './utils'

const generateAIResponse = (tmpData) => {
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      try {
        let response = await checkStatus(tmpData.thread_id, tmpData.id)
        if (response.status === 'completed') {
          clearInterval(intervalId)
          response = await assistantReadThread(tmpData.thread_id)
          resolve(response)
        }
      } catch (error) {
        clearInterval(intervalId)
        reject(error)
      }
    }, 2000)
  })
}

const checkStatus = async (thread_id, run_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let runState = await assistantCheckRunStatus(thread_id, run_id)
        resolve(runState)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export { generateAIResponse }
