import React, { useEffect, useState } from 'react'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import ListView from './diagnosis-history-list'
import { Col, message } from 'antd'
import { handleError } from '../../../lib/utils'
import { readBookings } from '../../../Bookings/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { readMedicalProfessionalUsers } from '../../../Admin/medical-professionals/utils/utils'
import DiagnosisHistoryReducer from '../utils/diagnosis-history-reducer'
import CustomRow from '../../../reusable-components/CustomRow'
import CoreEnums from '../../../../core/utils/enums'

const AppWrapper = ({ userRef }) => {
  const authState = useSelector((state) => state.auth)
  const diagnosisHistoryState = useSelector((state) => state.diagnosisHistory.data)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    handleGetBookings()
    // eslint-disable-next-line
  }, [])

  const handleGetBookings = async () => {
    let tmpMedicalProfessionals = []
    setLoading(true)

    try {
      const tmpBookings = await readBookings({
        userRef: userRef ? userRef : authState.agiliteUser._id,
        status: CoreEnums.bookingStatuses.completed
      })
      tmpMedicalProfessionals = await readMedicalProfessionalUsers({
        _id: { $in: tmpBookings.map((booking) => booking.medicalProfRef) }
      })

      tmpBookings.map((booking) => {
        tmpMedicalProfessionals.map((professional) => {
          if (booking.medicalProfRef === professional._id) {
            booking.medicalProfName = `${professional.firstName} ${professional.lastName}`
          }

          return null
        })

        return null
      })

      dispatch(DiagnosisHistoryReducer.actions.setRecords(tmpBookings))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  return (
    <>
      {userRef ? (
        <ListView userRef={userRef} data={diagnosisHistoryState} loading={loading} />
      ) : (
        <ContainerCard title='Diagnosis History'>
          <CustomRow>
            <Col span={24}>
              <ListView data={diagnosisHistoryState} loading={loading} handleGetBookings={handleGetBookings} />
            </Col>
          </CustomRow>
        </ContainerCard>
      )}
    </>
  )
}

export default AppWrapper
