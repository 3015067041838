import { Button, Col, DatePicker, Form, Input, Radio, Row, Space, Table, Tabs, Tooltip, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { handleError, parseIdToDateString, specialFieldValidation } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faChevronRight, faSearch, faTrash, faWarning } from '@fortawesome/free-solid-svg-icons'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import { createAgiliteUser, findAgiliteUser, updateAgiliteUser } from '../../../Auth/utils/utils'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { readSystemUsers } from '../../../Admin/system-users/utils/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'

const ReceptionUserSearch = ({
  nextStep,
  homeVisitState,
  setHomeVisitState,
  setPatient,
  isNewPatient,
  virtualVisitState,
  setVirtualVisitState,
  setIsNewPatient,
  patient
}) => {
  const authState = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [patientsLoading, setPatientsLoading] = useState(false)
  const [patients, setPatients] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [specialFormState, setSpecialFormState] = useState([])
  const [dependants, setDependants] = useState([
    { key: new Date().toISOString(), firstName: '', lastName: '', gender: '', dateOfBirth: '' }
  ])
  const [idType, setIdType] = useState('id')

  const dependantTemplate = () => {
    return { key: new Date().toISOString(), firstName: '', lastName: '', gender: '', dateOfBirth: '' }
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchValue(query)
    }, 1000),
    []
  )

  const handleSearch = (query) => {
    setPatientsLoading(true)
    debouncedFilter(query)
  }

  useEffect(() => {
    if (searchValue) {
      handleGetPatients()
    } else {
      setPatients([])
      setPatientsLoading(false)
    }

    // eslint-disable-next-line
  }, [searchValue])

  const handleGetPatients = async () => {
    let tmpPatients = []
    // Only return patients as results
    // Returning any other system users will cause the record to break in all list views and when they are actioned as no patient record exists
    let qry = { 'extraData.role.type': 'patient' }

    try {
      if (searchValue) {
        qry = {
          ...qry,
          $or: [
            {
              $expr: {
                $regexMatch: {
                  input: { $concat: ['$firstName', ' ', '$lastName'] },
                  regex: searchValue, //Your text search here
                  options: 'i'
                }
              }
            },
            // Need to find a way to escape the + sign in the phone number
            { phoneNumber: { $regex: searchValue, $options: 'i' } },
            { email: { $regex: searchValue, $options: 'i' } },
            { physicalAddress: { $regex: searchValue, $options: 'i' } },
            { idNo: { $regex: searchValue, $options: 'i' } }
          ]
        }
      }
      tmpPatients = await readSystemUsers(qry)
      setPatients(tmpPatients)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPatientsLoading(false)
  }

  const handleDepChange = (value, index, field) => {
    let tmpDependants = dependants.concat()
    tmpDependants[index][field] = value
    setDependants(tmpDependants)
  }

  const handleSubmit = async () => {
    const dependantIds = []
    let tmpPatient = null
    let agiliteUser = null
    let tmpPhone = null
    let tmpDependants = dependants.concat()
    let qry = {}
    let error = null

    setLoading(true)

    if (isNewPatient) {
      // New Patient
      try {
        tmpPatient = patientForm.getFieldsValue(true)
        if (!tmpPatient.phoneNumber) {
          error = 'Please provide a Cellphone Number'
        }
        if (tmpPatient.email) {
          qry['email'] = tmpPatient.email
        }

        if (tmpPatient.phoneNumber) {
          tmpPhone = formatNumber(tmpPatient.phoneNumber)
          qry['phoneNumber'] = tmpPhone
        }

        if (tmpPatient.idNo) {
          qry['idNo'] = tmpPatient.idNo
        }

        if (idType === 'id') {
          tmpPatient.dateOfBirth = parseIdToDateString(tmpPatient.idNo)
        }

        // Format Dependants
        tmpDependants.forEach((dep, index) => {
          if (!dep.firstName && !dep.lastName && !dep.dateOfBirth && !dep.gender) {
            tmpDependants.splice(index, 1)
          } else {
            if (!dep.firstName || !dep.lastName || !dep.dateOfBirth || !dep.gender) {
              error = 'Please make sure all fields for dependants are filled in'
            }
          }
        })

        if (error) {
          setLoading(false)
          return message.error(error)
        }

        agiliteUser = await findAgiliteUser(qry)

        if (agiliteUser) {
          setLoading(false)
          return message.error('User already exists. Please search for the user instead.')
        } else {
          if (tmpPatient.phoneNumber) {
            tmpPatient.phoneNumber = formatNumber(tmpPatient.phoneNumber)
          }

          agiliteUser = await createAgiliteUser(
            {
              ...tmpPatient,
              extraData: {
                role: {
                  type: 'patient'
                }
              }
            },
            false,
            authState.agiliteUser._id
          )
          for (const dep of tmpDependants) {
            let tmpUser = null
            tmpUser = await createAgiliteUser(
              {
                ...dep,
                residentialAddress: tmpPatient.residentialAddress,
                extraData: {
                  role: {
                    type: 'patient'
                  }
                }
              },
              false,
              authState.agiliteUser._id
            )
            dependantIds.push(tmpUser._id)
          }

          if (dependantIds.length > 0) {
            agiliteUser = await updateAgiliteUser(agiliteUser._id, { dependants: dependantIds })
          }
        }

        message.success('Patient created successfully')
        handleReset(tmpPatient)
      } catch (e) {}
    }

    setLoading(false)
  }

  const handleReset = (patient) => {
    setPatient(null)
    setDependants([dependantTemplate()])
    setIsNewPatient(false)
    setHomeVisitState(false)
    setVirtualVisitState(false)
    setSearchValue(patient ? patient.lastName : '')
    patientForm.resetFields()
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `+27${phoneNumber.slice(1, 10)}`
    } else {
      phoneNumber = `+27${phoneNumber}`
    }

    return phoneNumber
  }

  const handleAddDep = () => {
    const tmpDependants = dependants.concat()
    tmpDependants.push(dependantTemplate())
    setDependants(tmpDependants)
  }

  const handleRemoveDep = (index) => {
    let tmpDependants = dependants.concat()

    if (index === 0) {
      tmpDependants[index].firstName = ''
      tmpDependants[index].lastName = ''
      tmpDependants[index].gender = ''
      tmpDependants[index].dateOfBirth = ''
    } else {
      tmpDependants = tmpDependants.filter((dep, i) => i !== index)
    }

    setDependants(tmpDependants)
  }

  const [patientForm] = Form.useForm()
  const { token } = theme.useToken()

  const typeSelectionButtonStyle = {
    padding: 12,
    borderRadius: 12,
    border: '2px transparent solid',
    cursor: 'pointer'
  }

  const sectionHeadingStyle = {
    padding: 6,
    borderRadius: 6,
    background: token.colorSecondary,
    marginBottom: 24,
    color: '#ffffff'
  }

  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <h2
          style={{
            textAlign: 'center',
            padding: 12,
            borderRadius: 12,
            background: token.colorSecondary,
            color: '#ffff'
          }}
        >
          {isNewPatient ? 'Adding Patient' : 'Schedule a Booking'}
        </h2>
      </Col>
      {!isNewPatient ? (
        <Col span={24}>
          <CustomRow className='basic-card'>
            <Col span={24}>
              <Space>
                <div
                  style={{
                    ...typeSelectionButtonStyle,
                    color: homeVisitState || virtualVisitState ? '' : '#ffffff',
                    background: homeVisitState || virtualVisitState ? '' : token.colorPrimary,
                    borderColor: homeVisitState || virtualVisitState ? 'black' : token.colorPrimary
                  }}
                  onClick={() => {
                    setHomeVisitState(false)
                    setVirtualVisitState(false)
                  }}
                >
                  Clinic Visit
                </div>
                {/* <div
                  style={{
                    ...typeSelectionButtonStyle,
                    color: homeVisitState ? 'white' : '',
                    background: homeVisitState ? token.colorPrimary : '',
                    borderColor: homeVisitState ? token.colorPrimary : 'black'
                  }}
                  onClick={() => {
                    setHomeVisitState(true)
                    setVirtualVisitState(false)
                  }}
                >
                  Home Visit
                </div>
                <div
                  style={{
                    ...typeSelectionButtonStyle,
                    color: virtualVisitState ? 'white' : '',
                    background: virtualVisitState ? token.colorPrimary : '',
                    borderColor: virtualVisitState ? token.colorPrimary : 'black'
                  }}
                  onClick={() => {
                    setVirtualVisitState(true)
                    setHomeVisitState(false)
                  }}
                >
                  Virtual Consultation
                </div> */}
              </Space>
            </Col>
          </CustomRow>
        </Col>
      ) : undefined}
      <>
        {isNewPatient ? (
          // New Patient
          <Col span={24}>
            <Tabs type='card' centered>
              <Tabs.TabPane tab='Main Patient' key='1'>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form
                      initialValues={{
                        firstName: '',
                        lastName: '',
                        idNo: '',
                        email: '',
                        phoneNumber: '',
                        residentialAddress: ''
                      }}
                      layout='vertical'
                      form={patientForm}
                      onFinish={handleSubmit}
                      onFinishFailed={(e) => {
                        message.error(e.errorFields[0].errors[0])
                      }}
                      onErrorCapture={(e) => {
                        console.log(e)
                      }}
                    >
                      <CustomRow>
                        <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                          <h2 style={sectionHeadingStyle}>Personal Details</h2>
                          <CustomRow className='basic-card'>
                            <Col span={24}>
                              <Form.Item
                                name='firstName'
                                label='First Name'
                                rules={[{ required: true, message: 'Please provide a First Name' }]}
                              >
                                <Input
                                  placeholder='e.g John'
                                  onChange={(e) => {
                                    specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                      key: 'firstName',
                                      event: e,
                                      validationConfig: {
                                        letters: { allowed: true, onlyCaps: false },
                                        numbers: false,
                                        spaces: true
                                      }
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name='lastName'
                                label='Last Name'
                                rules={[{ required: false, message: 'Please provide a Last Name' }]}
                              >
                                <Input
                                  placeholder='e.g Doe'
                                  onChange={(e) => {
                                    specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                      key: 'lastName',
                                      event: e,
                                      validationConfig: {
                                        letters: { allowed: true, onlyCaps: false },
                                        numbers: false,
                                        spaces: true
                                      }
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Radio.Group
                                style={{ marginBottom: 8 }}
                                onChange={(e) => {
                                  patientForm.setFieldValue('idNo', null)
                                  setIdType(e.target.value)
                                }}
                                value={idType}
                              >
                                <Radio value={'id'}>South African ID</Radio>
                                <Radio value={'pass'}>Passport</Radio>
                              </Radio.Group>
                            </Col>
                            <Col span={24}>
                              {idType === 'id' ? (
                                <Form.Item
                                  name='idNo'
                                  rules={[
                                    { required: true, message: 'Please provide patient ID Number.' },
                                    { min: 13, message: 'Invalid South African ID' }
                                  ]}
                                >
                                  <Input
                                    placeholder='e.g. 790725*******'
                                    maxLength={13}
                                    onChange={(e) => {
                                      specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                        key: 'idNo',
                                        event: e,
                                        validationConfig: {
                                          letters: { allowed: false, onlyCaps: false },
                                          numbers: true,
                                          spaces: false
                                        }
                                      })
                                    }}
                                  />
                                </Form.Item>
                              ) : (
                                <>
                                  <Form.Item
                                    name='idNo'
                                    rules={[{ required: true, message: 'Please provide patient Passport Number.' }]}
                                  >
                                    <Input
                                      placeholder='e.g. AZ330B*******'
                                      maxLength={35}
                                      onChange={(e) => {
                                        specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                          key: 'idNo',
                                          event: e,
                                          validationConfig: {
                                            letters: { allowed: true, onlyCaps: true },
                                            numbers: true,
                                            spaces: false
                                          }
                                        })
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label='Date of Birth'
                                    name='dateOfBirth'
                                    rules={[{ required: true, message: 'Please select patient date of birth.' }]}
                                  >
                                    <DatePicker />
                                  </Form.Item>
                                  <Form.Item
                                    label='Gender'
                                    name='gender'
                                    rules={[{ required: true, message: 'Please select patient Gender.' }]}
                                  >
                                    <Radio.Group
                                      onChange={(e) => {
                                        patientForm.setFieldValue('gender', e.target.value)
                                      }}
                                    >
                                      <Radio value='female'>Female</Radio>
                                      <Radio value='male'>Male</Radio>
                                      <Radio value='other'>Other</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </>
                              )}
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name='email'
                                label='Email Address'
                                rules={[{ type: 'email', message: 'Please provide a valid Email Address' }]}
                              >
                                <Input placeholder='e.g jane.doe@acme.com' maxLength={70} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name='phoneNumber'
                                label='Cellphone Number'
                                rules={[{ message: 'Please provide a Cellphone Number' }]}
                              >
                                <Input
                                  addonBefore='+27'
                                  placeholder='e.g 8465*****'
                                  onChange={(e) => {
                                    specialFieldValidation(patientForm, specialFormState, setSpecialFormState, {
                                      key: 'phoneNumber',
                                      event: e,
                                      validationConfig: { letters: { allowed: false, onlyCaps: false }, numbers: true }
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name='residentialAddress'
                                label='Physical Address'
                                rules={[{ message: 'Please provide a Physical Address' }]}
                              >
                                <GoogleAddress
                                  selectProps={{
                                    placeholder: patientForm.getFieldValue('residentialAddress')
                                      ? patientForm.getFieldValue('residentialAddress')
                                      : 'e.g. 5 Doe Street, Gqeberha, South Africa',
                                    onChange: (e) => {
                                      patientForm.setFieldValue('residentialAddress', e.label)
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </CustomRow>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                          <h2 style={sectionHeadingStyle}>Medical Aid:</h2>

                          <CustomRow className='basic-card'>
                            <Col span={24}>
                              <Form.Item name={['medicalAid', 'name']} label='Name'>
                                <Input placeholder='e.g. Discovery Health' />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name={['medicalAid', 'plan']}
                                label='Plan'
                                rules={[{ message: 'Please provide a Physical Address' }]}
                              >
                                <Input placeholder='e.g. Coastal Saver' />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item name={['medicalAid', 'number']} label='Number'>
                                <Input placeholder='e.g. 1234567890' />
                              </Form.Item>
                            </Col>
                          </CustomRow>
                        </Col>
                      </CustomRow>
                    </Form>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab='Dependants' key='2'>
                <Row justify='center'>
                  <Col span={24}>
                    <center>
                      <FontAwesomeIcon style={{ color: token.colorWarning }} icon={faWarning} /> Please make sure to add
                      all dependants <FontAwesomeIcon style={{ color: token.colorWarning }} icon={faWarning} />
                    </center>
                    <Table
                      size='small'
                      bordered
                      dataSource={dependants}
                      rowClassName='table-row-no-cursor'
                      style={{ marginBottom: 20 }}
                      rowKey={(record) => record.key}
                      columns={[
                        {
                          title: 'First Name',
                          key: 'firstName',
                          dataIndex: 'firstName',
                          render: (text, record, index) => {
                            return (
                              <Input
                                value={record.firstName}
                                onChange={(e) => handleDepChange(e.target.value, index, 'firstName')}
                                placeholder='e.g Jane'
                                allowClear
                              />
                            )
                          }
                        },
                        {
                          title: 'Last Name',
                          key: 'lastName',
                          dataIndex: 'lastName',
                          render: (text, record, index) => {
                            return (
                              <Input
                                value={record.lastName}
                                onChange={(e) => handleDepChange(e.target.value, index, 'lastName')}
                                placeholder='e.g Jane'
                                allowClear
                              />
                            )
                          }
                        },
                        {
                          title: 'Gender',
                          key: 'gender',
                          dataIndex: 'gender',
                          render: (text, record, index) => {
                            return (
                              <center>
                                <Radio.Group
                                  value={record.gender}
                                  onChange={(e) => handleDepChange(e.target.value, index, 'gender')}
                                >
                                  <Radio value='female'>Female</Radio>
                                  <Radio value='male'>Male</Radio>
                                  <Radio value='other'>Other</Radio>
                                </Radio.Group>
                              </center>
                            )
                          }
                        },
                        {
                          title: 'Date Of Birth',
                          key: 'dateOfBirth',
                          dataIndex: 'dateOfBirth',
                          render: (text, record, index) => {
                            return (
                              <DatePicker
                                value={record.dateOfBirth ? dayjs(record.dateOfBirth) : ''}
                                onChange={(value) =>
                                  handleDepChange(dayjs(value).format('YYYY-MM-DD'), index, 'dateOfBirth')
                                }
                                allowClear={false}
                              />
                            )
                          }
                        },
                        {
                          title: 'Actions',
                          key: 'actions',
                          render: (value, record, index) => {
                            return (
                              <Row justify='space-around'>
                                <Col>
                                  <Tooltip title='Delete Dependant'>
                                    <FontAwesomeIcon
                                      style={{
                                        color: token.colorError,
                                        cursor: 'pointer',
                                        fontSize: 20
                                      }}
                                      icon={faTrash}
                                      onClick={() => {
                                        handleRemoveDep(index)
                                      }}
                                    />
                                  </Tooltip>
                                </Col>
                                <Col>
                                  <Tooltip title='Add Family Account'>
                                    <FontAwesomeIcon
                                      style={{ color: token.colorSuccess, cursor: 'pointer', fontSize: 20 }}
                                      icon={faAdd}
                                      onClick={() => handleAddDep()}
                                    />
                                  </Tooltip>
                                </Col>
                              </Row>
                            )
                          }
                        }
                      ]}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        ) : (
          // Existing Patient
          <>
            <Col span={24}>
              <Space>
                <Input
                  placeholder='Search...'
                  style={{ width: 400 }}
                  suffix={<FontAwesomeIcon icon={faSearch} />}
                  allowClear={patientsLoading ? false : true}
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                />{' '}
                <Button style={{ margin: '10px 0px 10px 0px' }} type='primary' onClick={() => setIsNewPatient(true)}>
                  Add Patient
                </Button>
              </Space>
            </Col>
            <Col span={24}>
              <Table
                loading={patientsLoading}
                rowKey={(record) => record._id}
                rowSelection={{
                  type: 'radio',
                  hideSelectAll: true,
                  onChange: (selectedRowKeys, selectedRows) => {
                    if (selectedRows.length > 0) {
                      setPatient(selectedRows[0])
                    }
                  },
                  selectedRowKeys: patient ? [patient._id] : []
                }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setPatient(record)
                    }
                  }
                }}
                style={{ marginBottom: 20 }}
                columns={[
                  {
                    title: 'First Name',
                    dataIndex: 'firstName',
                    key: 'firstName'
                  },
                  {
                    title: 'Last Name',
                    dataIndex: 'lastName',
                    key: 'firstName'
                  },
                  {
                    title: 'Identification',
                    dataIndex: 'idNo',
                    key: 'idNo'
                  },
                  {
                    title: 'Email Address',
                    dataIndex: 'email',
                    key: 'email'
                  },
                  {
                    title: 'Cellphone Number',
                    dataIndex: 'phoneNumber',
                    key: 'phoneNumber'
                  }
                ]}
                dataSource={patients.sort(
                  (a, b) => a.firstName.localeCompare(b.firstName) && a.lastName.localeCompare(b.lastName)
                )}
                locale={
                  searchValue
                    ? {
                        emptyText: (
                          <CustomRow justify='center' gutter={[12, 12]}>
                            <Col span={24}>
                              {patientsLoading
                                ? 'Searching for patients..'
                                : 'No patients found based on Search Criteria.'}{' '}
                            </Col>
                          </CustomRow>
                        )
                      }
                    : {
                        emptyText: (
                          <CustomRow justify='center' gutter={[12, 12]}>
                            <Col span={24}>
                              Search for a patient by First Name, Last Name, ID, Email or Contact Number...
                            </Col>
                          </CustomRow>
                        )
                      }
                }
                pagination={false}
                size='small'
              />
            </Col>
          </>
        )}
        <Col span={24}>
          <CustomRow justify='center' gutter={[24, 24]}>
            <Col span={24}>
              <CustomButton
                loading={loading}
                type='secondary'
                disabled={!patient && !isNewPatient}
                onClick={() => {
                  if (isNewPatient) {
                    patientForm.submit()
                  } else {
                    // Existing Patient
                    if (!patient) {
                      setLoading(false)
                      return message.error('Please select a patient that the booking is for')
                    }

                    nextStep()
                  }
                }}
                iconPosition='end'
                icon={faChevronRight}
                text={isNewPatient ? 'CONFIRM ACCOUNT DETAILS' : 'PROCEED TO AVAILABILITY'}
                style={{ width: '100%' }}
              />
            </Col>
            {isNewPatient ? (
              <Col span={24}>
                <CustomButton
                  disabled={loading}
                  onClick={() => handleReset()}
                  text='CANCEL'
                  type='accent'
                  style={{ width: '100%' }}
                />
              </Col>
            ) : undefined}
          </CustomRow>
        </Col>
      </>
    </CustomRow>
  )
}

export default ReceptionUserSearch
