import {
  faBookMedical,
  faCancel,
  faClinicMedical,
  faClock,
  faHouse,
  faPeopleArrows,
  faVideoCamera
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Popconfirm, Space, Tag, Tooltip } from 'antd'
import theme from '../../../core/utils/theme'
import dayjs from 'dayjs'
import Store from '../../../store'
import { generateStatus } from '../../lib/booking-utils'
import CustomRow from '../../reusable-components/CustomRow'
import CoreEnums from '../../../core/utils/enums'

// const joinVirtualConsult = async () => {
//   let agoraCreds = null

//   try {
//     agoraCreds = await generateAgoraRtcToken(data._id, 2)
//     setAgoraToken(agoraCreds.token)
//     setAgoraChannel(agoraCreds.channelName)
//     setAgoraUid(agoraCreds.uid)
//     setJoined(true)
//   } catch (e) {
//     message.error(handleError(e, true))
//   }
// }

const templates = {
  dataModel: {},
  columnTemplate: (
    token,
    cancelBooking,
    handleExamination,
    filterValue,
    handleReassignment,
    wasNoShow,
    joinVirtualConsult
  ) => {
    const state = Store.getState()

    return [
      // Small Screens
      {
        title: 'Record',
        key: 'column1',
        responsive: ['xs'],
        render: (text, record) => {
          return (
            <CustomRow gutter={[4, 4]} style={{ textAlign: 'center' }}>
              <Col span={24}>
                <h2>{record.clinicName ? record.clinicName : 'Home Visit'}</h2>
              </Col>
              <Col span={24}>
                <p> {record.medicalProfName}</p>
              </Col>
              <Col span={24}>
                <p>
                  {dayjs(new Date(record.bookingDate)).format('DD MMM YYYY')} ({record.startTime})
                </p>
              </Col>

              {record.status === CoreEnums.bookingStatuses.checkin ||
              record.status === CoreEnums.bookingStatuses.screening ? (
                <Col span={24}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      cancelBooking(record)
                    }}
                  >
                    <Space>
                      <FontAwesomeIcon size='md' color={theme.twitterBootstrap.danger} icon={faCancel} />
                      <div style={{ color: theme.twitterBootstrap.danger, fontSize: 12 }}>Cancel</div>
                    </Space>
                  </Button>
                </Col>
              ) : undefined}
              {record.isVirtualVisit ? (
                <Col span={24}>
                  <Tag color={token.colorSecondary}>Virtual Consultation</Tag>
                </Col>
              ) : undefined}
              {record.status === CoreEnums.bookingStatuses.diagnosis && record.isVirtualVisit ? (
                <Col span={24}>
                  <Popconfirm
                    title='Confirmation'
                    description='Are you sure you want to Join the Virtual Consultation?'
                    okText='Yes'
                    cancelText='No'
                    onConfirm={(e) => {
                      e.stopPropagation()
                      joinVirtualConsult(record._id)
                    }}
                    onCancel={(e) => {
                      e.stopPropagation()
                    }}
                    okButtonProps={{ danger: true }}
                  >
                    <Button onClick={(e) => e.stopPropagation()} type='primary'>
                      <Space>
                        <FontAwesomeIcon icon={faVideoCamera} />
                        Join
                      </Space>
                    </Button>
                  </Popconfirm>
                </Col>
              ) : undefined}
            </CustomRow>
          )
        }
      },
      // {
      //   title: 'Actions',
      //   width: '50%',
      //   key: 'column2',
      //   responsive: ['xs'],
      //   render: (text, record) => {
      //     return (
      //       <Row gutter={[8, 8]}>

      //       </Row>
      //     )
      //   }
      // },
      // Medium Screens and Up
      {
        title: 'Patient',
        hidden:
          state.auth.agiliteUser?.extraData.role.type === 'admin' ||
          state.auth.agiliteUser?.extraData.role.type === 'reception' ||
          state.auth.agiliteUser?.extraData.role.type === 'medical_professional'
            ? false
            : true,
        dataIndex: 'patientName',
        key: 'individual2',
        responsive: ['sm']
      },
      {
        title: 'Date / Time',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => {
          return (
            <>
              {dayjs(new Date(record.bookingDate)).format('DD MMM YYYY')} - {record.startTime}
            </>
          )
        },
        responsive: ['sm']
      },
      {
        title: 'Practitioner',
        hidden:
          state.auth.agiliteUser?.extraData.role.type === 'medical_professional' &&
          state.auth.agiliteUser?.extraData.profession === 'doctor' &&
          !state.auth.agiliteUser?.extraData.role.isManager
            ? true
            : false,
        dataIndex: 'medicalProfName',
        key: 'individual',
        responsive: ['sm']
      },
      {
        title: 'Clinic',
        hidden:
          state.auth.agiliteUser?.extraData.role.type !== 'reception' &&
          (filterValue === undefined || filterValue !== 'home')
            ? false
            : true,
        dataIndex: 'clinicName',
        key: 'clinic',
        responsive: ['sm'],
        render: (text, record) => {
          if (record.clinicName) {
            return <>{record.clinicName}</>
          } else {
            return 'Home Visit'
          }
        }
      },
      {
        title: 'Reason For Consult',
        hidden:
          state.auth.agiliteUser?.extraData.role.type === 'medical_professional' ||
          state.auth.agiliteUser?.extraData.role.type === 'admin'
            ? false
            : true,
        dataIndex: 'chiefComplaint',
        key: 'chiefComplaint',
        responsive: ['sm']
      },
      {
        title: 'OTP',
        hidden:
          state.auth.agiliteUser?.extraData.role.type === 'patient' ||
          state.auth.agiliteUser?.extraData.role.type === 'reception' ||
          (state.auth.agiliteUser?.extraData.role.type === 'medical_professional' &&
            state.auth.agiliteUser?.extraData.profession === 'nurse')
            ? false
            : true,
        dataIndex: 'otp',
        key: 'otp',
        responsive: ['sm']
      },
      {
        title: 'Consultation Type',
        hidden:
          state.auth.agiliteUser?.extraData.role.type === 'patient' ||
          state.auth.agiliteUser?.extraData.role.type === 'reception' ||
          (state.auth.agiliteUser?.extraData.role.type === 'medical_professional' &&
            state.auth.agiliteUser?.extraData.profession === 'doctor')
            ? false
            : true,
        key: 'isVirtualVisit',
        render: (text, record) => {
          if (record.isVirtualVisit) {
            return (
              <center>
                <FontAwesomeIcon icon={faVideoCamera} />
              </center>
            )
          } else if (record.clinicRef === 'Home Visit') {
            return (
              <center>
                <FontAwesomeIcon icon={faHouse} />
              </center>
            )
          } else {
            return (
              <center>
                <FontAwesomeIcon icon={faClinicMedical} />
              </center>
            )
          }
        },
        responsive: ['sm']
      },
      {
        title: 'Status',
        key: 'status',
        render: (text, record) => {
          return generateStatus(record?.status, token, record?.wasNoShow)
        },
        responsive: ['sm']
      },
      {
        title: 'Actions',
        render: (text, record) => {
          const handleVirtualConsultAction = () => {
            if (
              record.status === CoreEnums.bookingStatuses.diagnosis &&
              state.auth.agiliteUser?.extraData.role.type === 'patient' &&
              record.isVirtualVisit
            ) {
              return [true]
            }

            return [false]
          }

          const handleExaminationAction = () => {
            if (
              record.status === CoreEnums.bookingStatuses.diagnosis &&
              state.auth.agiliteUser?.extraData.role.type === 'medical_professional' &&
              state.auth.agiliteUser?.extraData.profession !== 'nurse' &&
              state.auth.agiliteUser?.extraData.profession !== 'reception'
            ) {
              return [true, 'Begin Examination']
            }

            if (
              record.status === CoreEnums.bookingStatuses.screening &&
              state.auth.agiliteUser?.extraData.profession === 'nurse'
            ) {
              return [true, 'Begin Examination']
            }

            if (
              record.status === CoreEnums.bookingStatuses.billing &&
              state.auth.agiliteUser?.extraData.role.type === 'reception'
            ) {
              if (
                state.auth.agiliteUser?.extraData.clinics.includes(record.clinicRef) ||
                record.clinicRef === 'Home Visit'
              ) {
                return [true, 'Finalize Booking']
              }
            }

            if (
              record.status === CoreEnums.bookingStatuses.procedure &&
              state.auth.agiliteUser?.extraData.role.type === 'medical_professional' &&
              state.auth.agiliteUser?.extraData.profession === 'nurse'
            ) {
              return [true, 'Begin Procedure']
            }

            return [false, '']
          }

          const handleReAssignmentAction = () => {
            if (
              record.status === CoreEnums.bookingStatuses.checkin ||
              record.status === CoreEnums.bookingStatuses.screening
            ) {
              if (
                state.auth.agiliteUser?.extraData.role.type === 'medical_professional' &&
                state.auth.agiliteUser?.extraData.profession === 'doctor'
              ) {
                if (record.clinicRef !== 'Home Visit') {
                  return [true, 'Re-Assign']
                }
              }
            }

            return [false, '']
          }

          const handleCancelAction = () => {
            if (!record.wasNoShow) {
              if (record.status === CoreEnums.bookingStatuses.checkin) {
                if (
                  state.auth.agiliteUser?.extraData.role.type === 'patient' ||
                  state.auth.agiliteUser?.extraData.role.type === 'reception'
                ) {
                  return true
                }
              } else if (record.status === CoreEnums.bookingStatuses.screening) {
                if (record.clinicRef === 'Home Visit' || state.auth.agiliteUser?.extraData.role.type === 'reception') {
                  return true
                }
              }
            }
          }

          const handleNoShowAction = () => {
            if (!record.wasNoShow) {
              if (
                record.status === CoreEnums.bookingStatuses.checkin &&
                state.auth.agiliteUser?.extraData.role.type === 'reception'
              ) {
                return true
              }
            }
          }

          return (
            <>
              {handleVirtualConsultAction()[0] ? (
                <center>
                  <Popconfirm
                    title='Confirmation'
                    description='Are you sure you want to Join the Virtual Consultation?'
                    okText='Yes'
                    cancelText='No'
                    onConfirm={(e) => {
                      e.stopPropagation()
                      joinVirtualConsult(record._id)
                    }}
                    onCancel={(e) => {
                      e.stopPropagation()
                    }}
                    okButtonProps={{ danger: true }}
                  >
                    <Button onClick={(e) => e.stopPropagation()} type='primary'>
                      <Space>
                        <FontAwesomeIcon icon={faVideoCamera} />
                        Join
                      </Space>
                    </Button>
                  </Popconfirm>
                </center>
              ) : (
                <Space size='large'>
                  {handleCancelAction() ? (
                    <>
                      {state.auth.agiliteUser?.extraData.role.type === 'patient' ||
                      state.auth.agiliteUser?.extraData.role.type === 'reception' ? (
                        <Tooltip title='Cancel Booking'>
                          <FontAwesomeIcon
                            size='lg'
                            id='cancel-booking'
                            color={theme.twitterBootstrap.danger}
                            icon={faCancel}
                            onClick={(e) => {
                              e.stopPropagation()
                              cancelBooking(record)
                            }}
                          />
                        </Tooltip>
                      ) : undefined}
                    </>
                  ) : undefined}
                  {handleExaminationAction()[0] ? (
                    <Tooltip title={handleExaminationAction()[1]}>
                      <FontAwesomeIcon
                        size='lg'
                        id='begin-examination'
                        color={theme.twitterBootstrap.success}
                        icon={faBookMedical}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleExamination(record, state.auth.agiliteUser?.extraData.profession)
                        }}
                      />
                    </Tooltip>
                  ) : undefined}
                  {handleReAssignmentAction()[0] ? (
                    <Tooltip title={handleReAssignmentAction()[1]}>
                      <FontAwesomeIcon
                        size='lg'
                        id='re-assign'
                        color={token.colorSecondary}
                        icon={faPeopleArrows}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleReassignment(record, state.auth.agiliteUser?.extraData.profession)
                        }}
                      />
                    </Tooltip>
                  ) : undefined}
                  {handleNoShowAction() ? (
                    <Tooltip title='Mark as No Show'>
                      <FontAwesomeIcon
                        size='lg'
                        color={token.coloBorderSecondary}
                        icon={faClock}
                        onClick={(e) => {
                          e.stopPropagation()
                          wasNoShow(record)
                        }}
                      />
                    </Tooltip>
                  ) : undefined}
                </Space>
              )}
            </>
          )
        },
        responsive: ['sm']
      }
    ]
  }
}

export default templates
