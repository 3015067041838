import Agilite from 'agilite'
import HealthTrackingEntryEnums from './enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readHealthTrackingEntries = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          HealthTrackingEntryEnums.profileKeys.HEALHT_TRACKING_ENTRIES,
          HealthTrackingEntryEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createHealthTrackingEntry = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          HealthTrackingEntryEnums.profileKeys.HEALHT_TRACKING_ENTRIES,
          HealthTrackingEntryEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
