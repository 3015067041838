import { faBan, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Form, message, Modal, Popconfirm, Row, Space, Table, theme, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { generateFormItem, handleError } from '../../lib/utils'
import { invalidateClinicDataRecords, updateClinicDataRecords } from '../examination-utils/examination-lib'
import TextArea from 'antd/es/input/TextArea'

const CorrectionsModal = ({ template, setTemplate, data, patientDataEntries, setPatientDataEntries }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [editTarget, setEditTarget] = useState(null)
  const [tableData, setTableData] = useState()

  const [invalidateTarget, setInvalidateTarget] = useState(null)
  const [invalidateReasom, setInvalidateReason] = useState('')
  const [invalidateModalOpen, setInvalidateModalOpen] = useState(false)
  const [loading, setloading] = useState(false)
  useEffect(() => {
    setTableData(data)
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (editTarget) {
      mapInitialValues()
    } else {
      captureForm.resetFields()
    }
    // eslint-disable-next-line
  }, [editTarget])

  const customRowClick = (row_props) => {
    const keyIndex = expandedRowKeys.indexOf(row_props.key)
    const newExpandedRowKeys = [...expandedRowKeys]
    if (keyIndex === -1) {
      newExpandedRowKeys.push(row_props.key)
    } else {
      newExpandedRowKeys.splice(keyIndex, 1)
    }
    setExpandedRowKeys(newExpandedRowKeys)
  }

  const mapInitialValues = () => {
    let tmpInitialValues = {}
    // Loop through each key in the data object
    if (Array.isArray(editTarget.value)) {
      tmpInitialValues[editTarget.key] = {}
      editTarget.value.forEach((item) => {
        tmpInitialValues[item.key] = item.value
      })
    } else {
      tmpInitialValues[editTarget.key] = editTarget.value
    }
    captureForm.setFieldsValue(tmpInitialValues)
  }

  const handleFinalizeCorrection = async (formData) => {
    let newRecord = null
    let tmpPatientDataEntries = JSON.parse(JSON.stringify(patientDataEntries)) // Using spread to avoid direct mutation
    let tmpTableData = JSON.parse(JSON.stringify(tableData)) // Same here

    setloading(true)
    try {
      // Convert formData to the format expected by the backend
      const convertedArray = Object.entries(formData).map(([key, value]) => ({
        key,
        value
      }))

      // Call the API to update the record
      newRecord = await updateClinicDataRecords(
        editTarget._id,
        Array.isArray(editTarget.value) ? convertedArray : convertedArray[0].value
      )

      // Update the state with the new record
      const targetEntryIndex = tmpPatientDataEntries.findIndex((i) => i._id === newRecord._id)
      const targetTableIndex = tmpTableData.findIndex((i) => i._id === newRecord._id)

      // Ensure both indices are valid
      if (targetEntryIndex !== -1) {
        tmpPatientDataEntries[targetEntryIndex] = newRecord
      }

      if (targetTableIndex !== -1) {
        tmpTableData[targetTableIndex] = newRecord
      }

      // Update state with new data
      setPatientDataEntries(tmpPatientDataEntries)
      //   fetchPatientData()
      setTableData(tmpTableData)
      setEditTarget(null) // Clear the edit target
    } catch (e) {
      message.error(handleError(e))
    }
    setloading(false)
  }

  const handleInvalidate = async (data) => {
    let newRecord = null
    let tmpPatientDataEntries = JSON.parse(JSON.stringify(patientDataEntries)) // Using spread to avoid direct mutation
    let tmpTableData = JSON.parse(JSON.stringify(tableData)) // Same here

    setloading(true)

    try {
      newRecord = await invalidateClinicDataRecords(invalidateTarget._id, invalidateReasom)

      const targetEntryIndex = tmpPatientDataEntries.findIndex((i) => i._id === newRecord._id)
      const targetTableIndex = tmpTableData.findIndex((i) => i._id === newRecord._id)

      if (targetEntryIndex !== -1) {
        tmpPatientDataEntries.splice(targetEntryIndex, 1)
      }

      if (targetTableIndex !== -1) {
        tmpTableData.splice(targetTableIndex, 1)
      }

      // Update state with new data
      setPatientDataEntries(tmpPatientDataEntries)
      //   fetchPatientData()
      setTableData(tmpTableData)
      resetInvalidateParams()
    } catch (error) {
      message.error(handleError(error))
    }
    setloading(false)
  }
  const [captureForm] = Form.useForm()
  const { token } = theme.useToken()

  const resetInvalidateParams = () => {
    setInvalidateTarget(null)
    setInvalidateReason('')
    setInvalidateModalOpen(false)
  }

  if (template) {
    return (
      <Modal
        okButtonProps={{ style: editTarget ? {} : { display: 'none' }, loading: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText='Confirm Correction'
        width={950}
        open={template}
        destroyOnClose
        closable={!loading}
        maskClosable={false}
        onOk={() => {
          captureForm.submit()
        }}
        onClose={() => {
          captureForm.resetFields()
          setEditTarget(null)
          setTemplate(null)
          setTableData([])
        }}
        onCancel={() => {
          setEditTarget(null)
          captureForm.resetFields()
          if (!editTarget) {
            setTableData([])
            setTemplate(null)
          }
        }}
      >
        {editTarget && template ? (
          <>
            <Form
              onFinishFailed={(e) => {
                message.error(e.errorFields[0].errors[0])
              }}
              form={captureForm}
              onFinish={(data) => {
                handleFinalizeCorrection(data)
              }}
              style={{ maxHeight: '65vh', overflow: 'auto' }}
            >
              <CustomRow wrap gutter={[8, 8]}>
                <Col span={24}>
                  {template?.subFields?.length > 0 ? (
                    <Col span={24}>
                      <Card title={template.label} size='small' type='inner'>
                        {template.subFields.map((subfield) => {
                          return (
                            <Card size='small' style={{ width: '100%', marginBottom: 12 }}>
                              {generateFormItem({
                                ...subfield,
                                formProps: { style: { marginBottom: 0 } }
                              })}
                            </Card>
                          )
                        })}
                      </Card>
                    </Col>
                  ) : (
                    <Col span={24}>
                      <Card size='small' style={{ width: '100%' }}>
                        {generateFormItem({ ...template, formProps: { style: { marginBottom: 0 } } })}
                      </Card>
                    </Col>
                  )}
                </Col>
              </CustomRow>
            </Form>
          </>
        ) : (
          <>
            {template.nestedSubFields ? (
              <Table
                onRow={(row_props) => {
                  return {
                    onClick: () => {
                      customRowClick(row_props)
                    }
                  }
                }}
                size='small'
                dataSource={tableData}
                columns={[
                  {
                    title: 'Reading',
                    render: () => 'Dropdown to see redings.'
                  },
                  {
                    title: 'Date',
                    render: (data) => {
                      return dayjs(data.dateCreated).format('DD MMM YYYY - HH:mm')
                    }
                  },
                  {
                    title: 'Actions',
                    render: (data) => {
                      return (
                        <Space>
                          <Tooltip title='Make a Correction'>
                            <Button
                              type='primary'
                              onClick={() => {
                                setEditTarget(data)
                              }}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                          </Tooltip>
                          <Tooltip title='Invalidate'>
                            <Button
                              type='primary'
                              style={{ background: token.colorError }}
                              onClick={(e) => {
                                e.stopPropagation()

                                setInvalidateTarget(data)
                                setInvalidateModalOpen(true)
                              }}
                            >
                              <FontAwesomeIcon icon={faBan} />
                            </Button>
                          </Tooltip>
                        </Space>
                      )
                    }
                  }
                ]}
                pagination={false}
                bordered={true}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (subFieldTableData) => {
                    return (
                      <Table
                        size='small'
                        dataSource={subFieldTableData.value}
                        columns={[
                          {
                            title: 'Reading',
                            render: (readingData) => {
                              const subFieldtemplate = template.subFields.find((i) => readingData.key === i.key)
                              return subFieldtemplate?.label
                            }
                          },
                          {
                            title: 'Reading',
                            render: (readingData) => {
                              const subFieldtemplate = template.subFields.find((i) => readingData.key === i.key)
                              if (readingData.value !== null && Array.isArray(readingData.value)) {
                                return (
                                  <Space>
                                    <span>{readingData.value.join(', ')}</span>
                                    <span style={{ opacity: 0.5 }}>{subFieldtemplate?.suffix}</span>
                                  </Space>
                                )
                              } else {
                                return (
                                  <Space>
                                    <span>{readingData.value}</span>
                                    <span style={{ opacity: 0.5 }}>{subFieldtemplate?.suffix}</span>
                                  </Space>
                                )
                              }
                            }
                          },
                          {
                            title: 'Date',
                            render: (subFieldData) => {
                              return dayjs(subFieldTableData.dateCreated).format('DD MMM YYYY - HH:mm')
                            }
                          }
                        ]}
                        pagination={false}
                        bordered={true}
                      />
                    )
                  }
                }}
              />
            ) : (
              <Table
                size='small'
                dataSource={tableData}
                columns={[
                  {
                    title: 'Reading',
                    render: (readingData) => {
                      if (readingData.value !== null && Array.isArray(readingData.value)) {
                        return (
                          <Space>
                            <span>{readingData.value.map((i) => i.value).join('/')}</span>
                            <span style={{ opacity: 0.5 }}>{template?.suffix}</span>
                          </Space>
                        )
                      } else {
                        return (
                          <Space>
                            <span>{readingData.value}</span>
                            <span style={{ opacity: 0.5 }}>{template?.suffix}</span>
                          </Space>
                        )
                      }
                    }
                  },
                  {
                    title: 'Date',
                    render: (data) => {
                      return dayjs(data.dateCreated).format('DD MMM YYYY - HH:mm')
                    }
                  },
                  {
                    title: 'Actions',
                    render: (data) => {
                      return (
                        <Space>
                          <Tooltip title='Make a Correction'>
                            <Button
                              type='primary'
                              onClick={(e) => {
                                e.stopPropagation()
                                setEditTarget(data)
                              }}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                          </Tooltip>
                          <Tooltip title='Invalidate'>
                            <Button
                              type='primary'
                              style={{ background: token.colorError }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setInvalidateTarget(data)
                                setInvalidateModalOpen(true)
                              }}
                            >
                              <FontAwesomeIcon icon={faBan} />
                            </Button>
                          </Tooltip>
                        </Space>
                      )
                    }
                  }
                ]}
                pagination={false}
                bordered={true}
              />
            )}
          </>
        )}
        <Modal
          title='Invalidate Data Entry'
          open={invalidateModalOpen}
          closable={false}
          footer={false}
          maskClosable={false}
        >
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction='vertical' style={{ width: '100%' }}>
                Please provide a reason for invalidating this data entry.
                <TextArea
                  disabled={loading}
                  style={{ width: '100%' }}
                  value={invalidateReasom}
                  rows={4}
                  onChange={(e) => {
                    setInvalidateReason(e.target.valie)
                  }}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space style={{ float: 'right' }} wrap>
                <Button
                  disabled={loading}
                  onClick={() => {
                    resetInvalidateParams()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  style={{ background: token.colorError }}
                  type='primary'
                  onClick={() => {
                    handleInvalidate()
                  }}
                >
                  Invalidate
                </Button>
              </Space>
            </Col>
          </Row>
        </Modal>
      </Modal>
    )
  }
}

export default CorrectionsModal
