import { Col, Empty, Space, message, theme } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import CustomRow from './CustomRow'
import { generateAgoraRtcToken, getBookings } from '../Bookings/utils/utils'
import { useState } from 'react'
import { convertDateTimeSAST, handleError } from '../lib/utils'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgiliteSkeleton from './AgiliteSkeleton'
import CoreEnums from '../../core/utils/enums'
import { deviceDetect } from 'react-device-detect'
import CustomButton from './CustomButton'
import { handleClinicBooking } from '../Bookings/utils/lib'
import BookingSummaryModal from '../Bookings/components/booking-summary-modal'
import DiagnosisOverview from '../Medical Vault/diagnosis-history/components/diagnosis-overview'
import coreReducer from '../../core/utils/reducer'

const NextAppointmentBanner = ({ id }) => {
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const adminBookingsState = useSelector((state) => state.adminBookings.data)
  const [nextAppLoading, setNextAppLoading] = useState(false)
  const [nextApp, setNextApp] = useState(null)
  const [prevApp, setPrevApp] = useState(null)
  const [booking, setBooking] = useState(null)
  const [bookingOpen, setBookingOpen] = useState()
  const [joined, setJoined] = useState(false)
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)

  useEffect(() => {
    handleGetBookingData()
    // eslint-disable-next-line
  }, [id, state.bookings.data, adminBookingsState])

  const handleGetBookingData = async () => {
    let tmpBookings = []
    let qry = {}

    setNextAppLoading(true)

    try {
      qry.userRef = id ? id : state.auth.agiliteUser._id
      qry.bookingDate = {
        $gte: convertDateTimeSAST(new Date(), true)
      }
      tmpBookings = await getBookings(state, qry)
      tmpBookings.sort(
        (a, b) =>
          new Date(dayjs(b.bookingDate).format('YYYY MMM DD')) - new Date(dayjs(a.bookingDate).format('YYYY MMM DD')) ||
          dayjs().set('hour', a.startTime.slice(0, 2)).set('minute', a.startTime.slice(-2)) -
            dayjs().set('hour', b.startTime.slice(0, 2)).set('minute', b.startTime.slice(-2))
      )

      const bookingsArray = tmpBookings.filter(
        (i) => i.status !== CoreEnums.bookingStatuses.completed && i.status !== CoreEnums.bookingStatuses.cancelled
      )

      setNextApp(bookingsArray[0])

      qry = { userRef: id ? id : state.auth.agiliteUser._id, status: CoreEnums.bookingStatuses.completed }
      let queryOptions = {
        sort: { bookingDate: -1 }
      }
      let tmpPrevApp = await getBookings(state, qry, null, queryOptions)
      if (tmpPrevApp[0]) {
        setPrevApp(tmpPrevApp[0])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setNextAppLoading(false)
  }

  const { token } = theme.useToken()

  const handleOpenBooking = (record) => {
    if (record.status !== CoreEnums.bookingStatuses.completed) {
      setBookingOpen(true)
      setBooking(record)
    } else {
      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          // TODO: Why is this label test?
          label: 'test',
          children: <DiagnosisOverview data={record} />
        })
      )
    }
  }

  const joinVirtualConsult = async (id) => {
    let agoraCreds = null

    try {
      agoraCreds = await generateAgoraRtcToken(id, 2)
      setAgoraToken(agoraCreds.token)
      setAgoraChannel(agoraCreds.channelName)
      setAgoraUid(agoraCreds.uid)
      setJoined(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <>
      {nextAppLoading ? (
        <CustomRow className='basic-card' style={{ boxShadow: '0 0 30px 0 #ECEDEE' }}>
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Loading Booking Data...' />
          </Col>
        </CustomRow>
      ) : (
        <Col span={24}>
          <CustomRow>
            {nextApp ? (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <CustomRow
                    className='basic-card'
                    gutter={[4, 4]}
                    style={{
                      textAlign: deviceDetect().isMobile ? 'center' : '',
                      boxShadow: '0 0 15px 0 rgba(0,0,0,0.055)'
                    }}
                  >
                    <Col span={24}>
                      <p style={{ background: token.colorPrimary, padding: 4, color: 'white', borderRadius: 4 }}>
                        Next Appointment:
                      </p>
                    </Col>
                    <Col span={24}>
                      <p>
                        <b>{deviceDetect().isMobile ? undefined : 'Medical professional:'}</b> {nextApp.medicalProfName}
                      </p>
                      <p>
                        <b>{deviceDetect().isMobile ? undefined : 'Clinic:'}</b>{' '}
                        {nextApp.clinicName ? nextApp.clinicName : 'Home Visit'}
                      </p>
                      <p>
                        <b>{deviceDetect().isMobile ? undefined : 'Date:'}</b>{' '}
                        {dayjs(new Date(nextApp.bookingDate)).format('DD MMM YYYY')} ({nextApp.startTime})
                      </p>
                    </Col>
                    <Col span={24}>
                      <center>
                        <CustomButton
                          size='small'
                          type='primary'
                          text='View'
                          onClick={() => {
                            handleOpenBooking(nextApp)
                          }}
                        />
                      </center>
                    </Col>
                  </CustomRow>
                </Col>
                {/* <div
              className='next-appointment-banner-container'
              style={deviceDetect().isMobile ? { textAlign: 'center' } : {}}
            >
              <div className='next-appointment-date'>
                <center style={{ textAlign: 'center' }}>
                  <h1>{dayjs(nextApp.bookingDate).format('DD').toUpperCase()}</h1>
                  <h1>{dayjs(nextApp.bookingDate).format('MMM').toUpperCase()}</h1>
                </center>
              </div>
              <div style={infoDisplayStyle}>
                {deviceDetect().isMobile ? (
                  <h2>{nextApp.medicalProfName}</h2>
                ) : (
                  <h2>
                    {nextApp.serviceName} with {nextApp.medicalProfName}
                  </h2>
                )}
                <p> </p>
                <p>
                  {nextApp.startTime} {deviceDetect().isMobile ? <br /> : undefined} (
                  {nextApp.isVirtualVisit
                    ? 'Join the Virtual Consultation by clicking on the relevant booking in the "Appointments" section.'
                    : nextApp.clinicRef === 'Home Visit'
                    ? 'A Nurse will arrive at your home at the scheduled time.'
                    : 'Please arrive at the clinic 15 min before your appointment time for check-in'}
                  ){' '}
                </p>
                <p>
                  Status: {generateStatus(nextApp.status, token, false, true)}{' '}
                  {!nextApp.isVirtualVisit && !nextApp.clinicRef === 'Home Visit' ? (
                    <b>(OTP: {nextApp.otp})</b>
                  ) : null}
                </p>
                <p></p>
              </div>
            </div> */}
              </>
            ) : (
              <Col xs={24} sm={24} md={12} lg={12}>
                <CustomRow
                  className='basic-card'
                  gutter={[4, 4]}
                  style={{
                    textAlign: deviceDetect().isMobile ? 'center' : '',
                    boxShadow: '0 0 15px 0 rgba(0,0,0,0.055)'
                  }}
                >
                  <Col span={24}>
                    <Empty
                      description={
                        <center>
                          <Space direction='vertical'>
                            <p>You have no upcoming appointments.</p>
                            <CustomButton
                              type='primary'
                              text={'BOOK NOW'}
                              size='small'
                              onClick={() => {
                                handleClinicBooking(false, dispatch)
                              }}
                            />
                          </Space>
                        </center>
                      }
                    />
                  </Col>
                </CustomRow>
              </Col>
            )}
            {prevApp ? (
              <Col xs={24} sm={24} md={12} lg={12}>
                <CustomRow
                  className='basic-card'
                  gutter={[4, 4]}
                  style={{
                    textAlign: deviceDetect().isMobile ? 'center' : '',
                    boxShadow: '0 0 15px 0 rgba(0,0,0,0.055)'
                  }}
                >
                  <Col span={24}>
                    <p style={{ background: token.colorSecondary, padding: 4, color: 'white', borderRadius: 4 }}>
                      Previous Appointment:
                    </p>
                  </Col>
                  <Col span={24}>
                    <p>
                      <b>{deviceDetect().isMobile ? undefined : 'Medical professional:'}</b> {prevApp.medicalProfName}
                    </p>
                    <p>
                      <b>{deviceDetect().isMobile ? undefined : 'Clinic:'}</b>{' '}
                      {prevApp.clinicName ? prevApp.clinicName : 'Home Visit'}
                    </p>
                    <p>
                      <b>{deviceDetect().isMobile ? undefined : 'Date:'}</b>{' '}
                      {dayjs(new Date(prevApp.bookingDate)).format('DD MMM YYYY')} ({prevApp.startTime})
                    </p>
                  </Col>
                  <Col span={24}>
                    <center>
                      <CustomButton
                        size='small'
                        type='secondary'
                        text='View'
                        onClick={() => {
                          handleOpenBooking(prevApp)
                        }}
                      />
                    </center>
                  </Col>
                </CustomRow>
              </Col>
            ) : undefined}
          </CustomRow>
        </Col>
      )}

      <BookingSummaryModal
        joinVirtualConsult={joinVirtualConsult}
        data={booking}
        setData={setBooking}
        modalOpen={bookingOpen}
        setModalOpen={setBookingOpen}
        joined={joined}
        setJoined={setJoined}
        agoraToken={agoraToken}
        agoraChannel={agoraChannel}
        agoraUid={agoraUid}
      />
    </>
  )
}

export default NextAppointmentBanner
