import { Tag } from 'antd'

export const DataPointDataTemplate = () => {
  return {
    label: '',
    key: '',
    fieldType: null,
    placeholder: '',
    required: {
      value: false,
      message: ''
    },
    data: '',
    prefix: '',
    suffix: '',
    subFields: [],
    props: ''
  }
}

export const DataPointColumnTemplate = () => {
  return [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (value) => {
        return <b>{value}</b>
      }
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Type',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (value) => {
        switch (value) {
          case 'text':
            return <Tag color='orange'>Text</Tag>
          case 'number':
            return <Tag color='green'>Number</Tag>
          case 'select':
            return <Tag color='cyan'>Select</Tag>
          case 'radio':
            return <Tag color='blue'>Radio Group</Tag>
          case 'checkboxGroup':
            return <Tag color='goldenrod'>Checkbox Group</Tag>
          case 'checkbox':
            return <Tag color='geekblue'>Checkbox Group</Tag>
          case 'switch':
            return <Tag color='lime'>Switch</Tag>
          case 'date':
            return <Tag color='purple'>Date</Tag>
          default:
            return <Tag>Unknown</Tag>
        }
      }
    },
    {
      title: 'Required',
      dataIndex: 'required',
      key: 'required',
      render: (value) => {
        return value ? <Tag color='red'>Required</Tag> : <Tag color='green'>Optional</Tag>
      }
    },
    {
      title: 'Suffix',
      dataIndex: 'suffix',
      key: 'suffix'
    }
  ]
}
