import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Col, Row, Space, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'

const templates = {
  dataModel: {},
  columnTemplate: (token, handleDownload, handleSend) => {
    return [
      // Small Screens
      {
        title: 'Record',
        key: 'column1',
        responsive: ['xs'],
        render: (text, record) => {
          return (
            <Space style={{ width: '100%' }} direction='vertical'>
              <p>
                {dayjs(new Date(record.bookingDate)).format('DD MMM YYYY')} ({record.startTime})
              </p>
              <p>
                <b>Professional:</b>
                {record.medicalProfName}
              </p>
              {record?.examinationData?.billing?.diagnosis?.length > 0 ? (
                <>
                  {record?.examinationData?.billing?.diagnosis?.map((item) => (
                    <Alert key={item.code} message={item.description} />
                  ))}
                </>
              ) : (
                <Alert key={'No Diagnosis'} message={'No Diagnosis'} />
              )}
            </Space>
          )
        },
        width: '70%'
      },
      // {
      //   title: 'Actions',
      //   key: 'column2',
      //   responsive: ['xs'],
      //   render: (text, record) => {
      //     if (!record.examinationData.diagnosisData.medications) {
      //       return null
      //     }

      //     return (
      //       <Row gutter={[8, 8]}>
      //         <Col span={24}>
      //           <Button style={{ width: '100%' }} onClick={() => handleDownload(record)}>
      //             <Space style={{ alignItems: 'center' }}>
      //               <FontAwesomeIcon size='md' icon={faDownload} color={token.colorPrimary} />
      //               <div style={{ color: token.colorPrimary, fontSize: 12 }}>Download Script</div>
      //             </Space>
      //           </Button>
      //         </Col>
      //         <Col span={24}>
      //           <Button style={{ width: '100%' }} onClick={() => handleSend(record)}>
      //             <Space style={{ alignItems: 'center' }}>
      //               <FontAwesomeIcon size='md' icon={faEnvelope} color={token.colorSuccess} />
      //               <div style={{ color: token.colorSuccess, fontSize: 12 }}>Email Script</div>
      //             </Space>
      //           </Button>
      //         </Col>
      //       </Row>
      //     )
      //   }
      // },
      // Medium Screens and Up
      {
        title: 'Diagnosis',
        key: 'individual',
        responsive: ['md'],
        render: (text, record) => {
          return (
            <Row gutter={[12, 12]}>
              {record.examinationData?.billing?.diagnosis?.length > 0 ? (
                <>
                  {record.examinationData?.billing?.diagnosis?.map((item) => (
                    <>
                      {item.description ? (
                        <Col span={24}>
                          <Tag key={item.code} color='blue'>
                            {item.description}
                          </Tag>
                        </Col>
                      ) : (
                        <Col span={24}>
                          <Tag key={item.code} color='red'>
                            No Diagnosis
                          </Tag>
                        </Col>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <Col span={24}>
                  <Tag key={'noDiagnosis'} color='red'>
                    No Diagnosis
                  </Tag>
                </Col>
              )}
            </Row>
          )
        }
      },
      {
        title: 'Date / Time',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => {
          return (
            <span>
              {dayjs(new Date(record.bookingDate)).format('DD MMM YYYY')} - {record.startTime}
            </span>
          )
        },
        responsive: ['md']
      },
      {
        title: 'Professional',
        dataIndex: 'medicalProfName',
        key: 'date',
        responsive: ['md']
      },

      {
        title: 'Actions',
        dataIndex: '',
        key: '',
        responsive: ['md'],
        render: () => (
          <center>
            <Tooltip title='View Record'>
              <FontAwesomeIcon icon={faEye} />
            </Tooltip>
          </center>
        )
      }
      // {
      //   title: 'Actions',
      //   render: (text, record) => {
      //     if (!record.examinationData.diagnosisData.medications) {
      //       return null
      //     }

      //     return (
      //       <Space size='large'>
      //         <Tooltip title='Download Script'>
      //           <FontAwesomeIcon
      //             size='lg'
      //             icon={faDownload}
      //             color={token.colorPrimary}
      //             onClick={() => handleDownload(record)}
      //           />
      //         </Tooltip>
      //         <Tooltip title='Email Script'>
      //           <FontAwesomeIcon
      //             size='lg'
      //             icon={faEnvelope}
      //             color={token.colorSuccess}
      //             onClick={() => handleSend(record)}
      //           />
      //         </Tooltip>
      //       </Space>
      //     )
      //   },
      //   responsive: ['md']
      // }
    ]
  }
}

export default templates
