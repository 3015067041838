import React, { useState, useEffect } from 'react'
import { Col, Input, message, Modal, Radio, theme } from 'antd'

import { createCustomDiagnosis, readCustomDiagnosis, updateCustomDiagnosis } from '../utils/utils'

import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { handleError } from '../../../lib/utils'
import CustomDiagnosisList from './custom-diagnosis-list'
import { useDispatch, useSelector } from 'react-redux'
import CustomRow from '../../../reusable-components/CustomRow'
import { updateEntity } from '../../entities/utils/utils'
import coreReducer from '../../../../core/utils/reducer'

const CustomDiagnosisWrapper = () => {
  const entityState = useSelector((state) => state.core.entity)
  const preference = useSelector((state) => state.core.entity.diagnosisPreference)
  const dispatch = useDispatch()
  const { token } = theme.useToken()

  const [viewData, setViewData] = useState([])
  const [loading, setLoading] = useState(false)
  const [adding, setAdding] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [editing, setEditing] = useState(null)
  const [currentRecord, setCurrentRecord] = useState(null)
  const [verifying, setVerifying] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [newEntryValue, setNewEntryValue] = useState({ code: '', description: '' })

  const refreshView = async () => {
    let filter = {}
    let data = []
    setLoading(true)
    try {
      data = await readCustomDiagnosis(filter)
      setViewData(data)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  // eslint-disable-next-line

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [])

  const createRecord = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (currentRecord) {
      setNewEntryValue({ code: currentRecord.code, description: currentRecord.description })
      setEditing(true)
    } else {
      setEditing(false)
    }
  }, [currentRecord])

  const viewRecord = (record) => {
    message.warning('Diagnosis Insights Coming Soon')
  }

  const handleAddNewRecord = async () => {
    let exists = null
    setVerifying(true)
    try {
      exists = await readCustomDiagnosis({ code: newEntryValue.code })
      if (exists[0]) {
        setVerifying(false)
        throw new Error('Duplicate codes, unable to add diagnosis.')
      }
      setVerifying(false)
      await createCustomDiagnosis({ code: newEntryValue.code, description: newEntryValue.description })
      setNewEntryValue({ code: '', value: '' })
      setModalOpen(false)
      refreshView()
    } catch (e) {
      message.error(handleError(e))
    }
    setAdding(false)
  }

  const handleUpdateRecord = async () => {
    setUpdating(true)
    try {
      await updateCustomDiagnosis({ ...currentRecord, ...newEntryValue })
      setNewEntryValue({ code: '', value: '' })
      setCurrentRecord(null)
      refreshView()
    } catch (e) {
      message.error(handleError(e))
    }
    setUpdating(false)
  }

  const handleSetEntityPreference = async (value) => {
    setLoading(true)
    let tmpData = { ...entityState, diagnosisPreference: value }
    try {
      await updateEntity(tmpData)
      dispatch(coreReducer.actions.setEntity(tmpData))
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  return (
    <ContainerCard title='Diagnosis'>
      <CustomRow>
        <Col span={24}>
          <CustomRow className='basic-card'>
            <Col span={24}>
              <h2>Custom Diagnosis Configuration:</h2>
              <p>
                An extensive default list of diagnoses is readily available. Custom codes can be seamlessly integrated
                with the comprehensive list or utilized independently. Choose your preferred setting below.
              </p>
            </Col>
            <Col span={24}>
              <Radio.Group
                disabled={loading}
                value={preference}
                style={{ marginBottom: 12 }}
                buttonStyle='solid'
                onChange={(e) => {
                  handleSetEntityPreference(e.target.value)
                }}
              >
                <Radio.Button value=''>Default</Radio.Button>
                <Radio.Button value='custom'>Custom</Radio.Button>
                <Radio.Button value='combination'>Combination</Radio.Button>
              </Radio.Group>
              {preference === '' ? (
                <p>
                  The default option provides a predefined and comprehensive list of diagnoses. It offers a convenient
                  starting point based on commonly encountered medical conditions, ensuring a structured and efficient
                  approach to diagnosis.
                </p>
              ) : preference === 'custom' ? (
                <p>
                  Opting for the custom option allows healthcare professionals to input specific diagnosis codes that
                  are tailored to unique patient scenarios. This flexibility accommodates cases where standard codes may
                  not fully capture the intricacies of the patient's condition.
                </p>
              ) : (
                <p>
                  The combination option allows practitioners to use both the default and custom codes concurrently.
                  This approach enables a nuanced and personalized diagnostic process, leveraging the predefined list
                  for common cases while incorporating custom codes for exceptional or rare conditions. It offers a
                  balanced and adaptable solution to meet diverse diagnostic needs.
                </p>
              )}
            </Col>
          </CustomRow>
        </Col>
        <Col span={24}>
          <CustomDiagnosisList
            hidden={!preference}
            loading={loading}
            data={viewData}
            createRecord={createRecord}
            editRecord={setCurrentRecord}
            viewRecord={viewRecord}
            refreshView={refreshView}
          />
        </Col>
      </CustomRow>
      <Modal
        onCancel={() => {
          setNewEntryValue({ code: '', description: '' })
          setModalOpen(false)
          setCurrentRecord(null)
        }}
        title='New Diagnosis'
        open={modalOpen || editing}
        onOk={() => {
          if (editing) {
            handleUpdateRecord()
          } else {
            handleAddNewRecord()
          }
        }}
        okText={verifying ? 'Verifying...' : adding ? 'Adding...' : updating ? 'Updating...' : 'Confirm'}
        okButtonProps={{
          loading: adding || verifying || updating,
          disabled: !newEntryValue.code || !newEntryValue.description
        }}
        cancelButtonProps={{
          disabled: adding || verifying || updating,
          background: adding || verifying || updating ? '' : token.colorError
        }}
      >
        <CustomRow>
          <Col span={24}>
            <p>
              Please enter a unique code and its description for the new diagnosis. Ensure the code is distinct as
              duplicates are not allowed in the system.
            </p>
          </Col>
          <Col span={24}>
            <p style={{ marginBottom: 6 }}>Code</p>
            <Input
              readOnly={editing}
              style={editing ? { cursor: 'not-allowed' } : {}}
              value={newEntryValue?.code}
              onChange={(e) => {
                setNewEntryValue({ ...newEntryValue, code: e.target.value })
              }}
            />
          </Col>
          <Col span={24}>
            <p style={{ marginBottom: 6 }}>Description</p>
            <Input
              value={newEntryValue?.description}
              onChange={(e) => {
                setNewEntryValue({ ...newEntryValue, description: e.target.value })
              }}
            />
          </Col>
        </CustomRow>
      </Modal>
    </ContainerCard>
  )
}

export default CustomDiagnosisWrapper
