const SubscriptionEnums = {
  profileKeys: {
    SUBSCRIPTIONS: 'subscriptions'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create'
  }
}

export default SubscriptionEnums
