import { Card, Col, Divider, Form, Modal, Select, Space, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { generateFormItem, handleError, hexToRGBA } from '../../lib/utils'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import CustomRow from '../../reusable-components/CustomRow'
import { createClinicDataRecords } from '../examination-utils/examination-lib'
import { readClinicDataPoints } from '../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

const ExaminationDataCapture = ({
  bookingWebSocket,
  userRef,
  bookingData,
  modalOpen,
  setModalOpen,
  dataTemplates,
  handleRefresh,
  hasLabReport,
  onAfterSuccess,
  onAfterCancel
}) => {
  const authState = useSelector((state) => state.auth)
  const [templateDataPointsSelection, setTemplateDataPointsSelection] = useState([])
  const [dataPoints, setDataPoints] = useState([])
  const [uploading, setUploading] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    handleFetchDataPoints()
    // eslint-disable-next-line
  }, [])

  const handleFetchDataPoints = async () => {
    let tmpDataPoints = []
    try {
      tmpDataPoints = await readClinicDataPoints(hasLabReport ? { hasLabReport: true } : {})
      setDataPoints(tmpDataPoints)
    } catch (e) {
      message.error(handleError(e))
    }
  }

  const captureDataPoints = async (data) => {
    const dateCreated = dayjs()
    const createdBy = authState.agiliteUser._id
    let tmpData = null

    setUploading(true)

    try {
      const promiseDataArray = Object.entries(data).map(([key, value]) => {
        if (typeof value === 'object') {
          const subFields = Object.entries(value).map(([subKey, subValue]) => ({
            key: subKey,
            value: subValue
          }))
          const dataPointIndex = dataPoints.findIndex((i) => i.key === key)

          return createClinicDataRecords({
            dateCreated,
            createdBy,
            userRef: bookingData ? bookingData.userRef : userRef,
            bookingRef: bookingData ? bookingData._id : '',
            origin: 'booking',
            category: dataPointIndex !== -1 ? dataPoints[dataPointIndex].category : 'standard',
            key,
            value: subFields.length > 0 ? subFields : value
          })
        } else {
          const dataPointIndex = dataPoints.findIndex((i) => i.key === key)

          return createClinicDataRecords({
            dateCreated,
            createdBy,
            userRef: bookingData ? bookingData.userRef : userRef,
            bookingRef: bookingData ? bookingData._id : '',
            origin,
            category: dataPointIndex !== -1 ? dataPoints[dataPointIndex].category : 'standard',
            key,
            value
          })
        }
      })
      tmpData = await Promise.all(promiseDataArray)
      if (bookingData) {
        bookingWebSocket.socket.send(JSON.stringify({ bookingRef: bookingData._id, data: tmpData }))
      } else {
        if (handleRefresh) {
          handleRefresh()
        }
      }
      if (onAfterSuccess) {
        onAfterSuccess(tmpData)
      } else {
        handleReset()
      }
      // handleReset()
    } catch (e) {
      message.error(handleError(e))
    }

    setUploading(false)
  }

  const handleTemplateSelection = (template) => {
    const dataPointsToBeAdded = []
    template?.keys?.forEach((key) => {
      const dataReference = dataPoints.find((i) => i.key === key)
      dataPointsToBeAdded.push(JSON.stringify(dataReference))
    })
    setTemplateDataPointsSelection(dataPointsToBeAdded)
  }

  const handleReset = () => {
    setTemplateDataPointsSelection([])
    setCurrentTemplate(null)
    captureForm.resetFields()
    setModalOpen(!modalOpen)
    if (onAfterCancel) {
      onAfterCancel()
    }
  }

  const getModalTitle = () => {
    switch (modalOpen) {
      case 'general':
        return 'NEW CAPTURE - GENERAL' // blue
      case 'vitals':
        return 'NEW CAPTURE - VITALS' // green
      case 'rapid':
        return 'NEW CAPTURE - RAPID TESTS' // yellow
      case 'poc':
        return 'NEW CAPTURE - POINT OF CARE' // pink
      case 'lab':
        return 'NEW CAPTURE - LAB RESULTS' // orange
    }
  }

  const [captureForm] = Form.useForm()
  const { token } = theme.useToken()

  return (
    <>
      <Modal
        destroyOnClose
        closable={false}
        maskClosable={false}
        width={1920}
        open={modalOpen}
        onCancel={() => handleReset()}
        onOk={() => {
          captureForm.submit()
        }}
        okText='Submit Readings'
        okButtonProps={{ loading: uploading, style: { background: token.colorSuccess } }}
        cancelButtonProps={{ disabled: uploading }}
        cancelText='Cancel'
      >
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 12,
              padding: 8,
              background: hexToRGBA(token.colorPrimary, 0.2)
            }}
          >
            <h2>
              {modalOpen === 'all' ? (
                <>NEW DATA CAPTURE - {currentTemplate ? currentTemplate.name.toUpperCase() : 'CUSTOM TEMPLATE'}</>
              ) : (
                getModalTitle()
              )}
            </h2>
          </div>
          {hasLabReport ? undefined : modalOpen === 'all' ? (
            <Select
              allowClear
              value={currentTemplate?.name}
              placeholder='- Custom Template -'
              showSearch
              optionFilterProp='label'
              options={[
                { value: null, label: '- Custom Template -' },
                ...dataTemplates.map((dataTemplate) => ({
                  value: JSON.stringify(dataTemplate),
                  label: dataTemplate.name
                }))
              ]}
              onChange={(template) => {
                if (template) {
                  setCurrentTemplate(JSON.parse(template))
                  handleTemplateSelection(JSON.parse(template))
                } else {
                  setCurrentTemplate(null)
                }
              }}
              style={{ width: '100%', marginBottom: 12 }}
            />
          ) : undefined}
          <div style={{ display: 'grid', gridTemplateColumns: '350px 1fr', border: '1px grey solid' }}>
            <div style={{ borderRight: '1px grey solid', minHeight: 300, maxHeight: '65vh', overflow: 'auto' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', borderBottom: '1px grey solid' }}>
                <input
                  value={searchQuery}
                  placeholder='Search'
                  style={{ width: '100%', borderRadius: 0, border: 'none', paddingLeft: 10 }}
                  onChange={(e) => {
                    setSearchQuery(e.target.value)
                  }}
                />
                <div
                  style={{
                    height: 35,
                    width: 35,
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderLeft: '1px grey solid',
                    background: token.colorPrimary,
                    color: '#ffffff'
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              </div>
              <div
                style={{
                  padding: 10,
                  borderBottom: '1px grey solid',
                  background: hexToRGBA(token.colorSecondary, 0.2)
                }}
              >
                DATA POINTS
              </div>
              <Space direction='vertical' style={{ width: '100%' }}>
                {dataPoints
                  .filter(
                    (dataPoint) =>
                      dataPoint.label.toLowerCase().includes(searchQuery.toLowerCase()) &&
                      (dataPoint.category === modalOpen || modalOpen === 'all' || hasLabReport)
                  )
                  .sort((a, b) => {
                    const aInSelection = templateDataPointsSelection.includes(JSON.stringify(a))
                    const bInSelection = templateDataPointsSelection.includes(JSON.stringify(b))

                    if (aInSelection && !bInSelection) return -1
                    if (!aInSelection && bInSelection) return 1

                    // If both are in the selection or both are not in the selection, sort alphabetically
                    return a.label.localeCompare(b.label)
                  })
                  .map((dataPoint) => (
                    <>
                      <Space style={{ width: '100%', padding: 10, justifyContent: 'space-between' }}>
                        <p style={{ width: '100%' }}>{dataPoint.label}</p>
                        <div
                          style={{
                            background: templateDataPointsSelection.includes(JSON.stringify(dataPoint))
                              ? token.colorError
                              : token.colorSuccess,
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            color: '#ffffff',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setCurrentTemplate(null)
                            if (templateDataPointsSelection.includes(JSON.stringify(dataPoint))) {
                              let tmpTemplateDataPointsSelection = JSON.parse(
                                JSON.stringify(templateDataPointsSelection)
                              )
                              const itemIndex = tmpTemplateDataPointsSelection.findIndex(
                                (i) => i === JSON.stringify(dataPoint)
                              )
                              tmpTemplateDataPointsSelection.splice(itemIndex, 1)
                              setTemplateDataPointsSelection(tmpTemplateDataPointsSelection)
                            } else {
                              setTemplateDataPointsSelection([
                                ...templateDataPointsSelection,
                                JSON.stringify(dataPoint)
                              ])
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 10 }}
                            icon={templateDataPointsSelection.includes(JSON.stringify(dataPoint)) ? faMinus : faPlus}
                          />
                        </div>
                      </Space>
                      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                    </>
                  ))}
              </Space>
            </div>
            {/* <Select
                value={templateDataPointsSelection}
                placeholder={`- Select ${currentTemplate ? 'additional' : ''} clinical readings to capture -`}
                showSearch
                optionFilterProp='label'
                mode='multiple'
                options={dataPoints
                  .filter((i) => !currentTemplate?.keys?.includes(i.key))
                  .map((dataPoint) => ({ value: JSON.stringify(dataPoint), label: dataPoint.label }))}
                onChange={(array) => {
                  setTemplateDataPointsSelection(array)
                }}
                style={{ width: '100%', marginBottom: 12 }}
              /> */}
            <Form
              onFinishFailed={(e) => {
                message.error(e.errorFields[0].errors[0])
              }}
              form={captureForm}
              onFinish={(data) => {
                captureDataPoints(data)
              }}
              style={{ maxHeight: '65vh', overflow: 'auto' }}
            >
              <CustomRow wrap gutter={[8, 8]}>
                {[...templateDataPointsSelection.map((jsonData) => JSON.parse(jsonData))]?.map((selection) => {
                  if (selection?.subFields?.length > 0) {
                    return (
                      <Col span={24}>
                        <Card title={selection.label} size='small' type='inner'>
                          {selection.subFields.map((subfield) => {
                            return (
                              <Card size='small' style={{ width: '100%', marginBottom: 12 }}>
                                {generateFormItem({
                                  ...subfield,
                                  key: [selection.key, subfield.key],
                                  formProps: { style: { marginBottom: 0 } }
                                })}
                              </Card>
                            )
                          })}
                        </Card>
                      </Col>
                    )
                  } else {
                    return (
                      <Col span={24}>
                        <Card size='small' style={{ width: '100%' }}>
                          {generateFormItem({ ...selection, formProps: { style: { marginBottom: 0 } } })}
                        </Card>
                      </Col>
                    )
                  }
                })}
              </CustomRow>
            </Form>
          </div>
        </>
      </Modal>
    </>
  )
}

export default ExaminationDataCapture
