import { Col, Divider, Empty, Row } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import CustomButton from '../../../reusable-components/CustomButton'
import CustomRow from '../../../reusable-components/CustomRow'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import store from '../../../../store'
import Profile from '../../../Profile/components/Profile'
import { capitalizeFirstLetter } from '../../../lib/utils'

const PersonalDetails = ({ data }) => {
  const authState = useSelector((state) => state.auth)

  // Data is only passed if it is a dependant
  // Use this to determine if we should use the data passed or the data from the authState
  // It can also be used to show or information based on data existing or not
  const personalDetails = data ? data : authState.agiliteUser
  const containerStyle = {
    borderRadius: 12,
    boxShadow: '0 0 30px 0 #ECEDEE',
    padding: 12,
    minHeight: '100%'
  }
  const dividerStyle = {
    borderColor: 'rgba(204, 204, 204, 0.35)',
    margin: '12px 0'
  }

  const navigateToMedicalHistory = () => {
    store.dispatch(
      coreReducer.actions.addTab({
        key: CoreEnums.tabKeys.PERSONAL_DETAILS,
        closable: true,
        label: CoreEnums.tabTitles.PERSONAL_DETAILS,
        children: <Profile />
      })
    )
  }
  return (
    <Row style={containerStyle}>
      <Col span={24}>
        <h2 className='mb-1'>Personal Details</h2>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <span>First Name</span>
              </Col>
              <Col span={12} style={{ color: '#A1A6AC' }}>
                <span>{personalDetails.firstName}</span>
              </Col>
            </Row>
            <Divider style={dividerStyle} />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <span>Last Name</span>
              </Col>
              <Col span={12}>
                <span style={{ color: '#A1A6AC' }}>{personalDetails.lastName}</span>
              </Col>
            </Row>
            <Divider style={dividerStyle} />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <span>South African ID</span>
              </Col>
              <Col span={12}>
                <span style={{ color: '#A1A6AC' }}>{personalDetails.idNo}</span>
              </Col>
            </Row>
            <Divider style={dividerStyle} />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <span>Date of Birth</span>
              </Col>
              <Col span={12}>
                <span style={{ color: '#A1A6AC' }}>{personalDetails.dateOfBirth}</span>
              </Col>
            </Row>
            <Divider style={dividerStyle} />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <span>Gender</span>
              </Col>
              <Col span={12}>
                <span style={{ color: '#A1A6AC' }}>{capitalizeFirstLetter(personalDetails.gender)}</span>
              </Col>
            </Row>
            <Divider style={dividerStyle} />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <span>Race</span>
              </Col>
              <Col span={12}>
                <span style={{ color: '#A1A6AC' }}>{personalDetails.race}</span>
              </Col>
            </Row>
            <Divider style={dividerStyle} />
          </Col>
        </Row>
        <h2 className='mb-1'>Medical Cover</h2>
        <Row>
          {personalDetails.medicalAid.name ? (
            <>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Medical Aid Name</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>{personalDetails.medicalAid.name}</span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Medical Aid Plan</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>{personalDetails.medicalAid.plan}</span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Medical Aid Number</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>{personalDetails.medicalAid.number}</span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Dependant Number</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>{personalDetails.medicalAid.dependantNumber}</span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
            </>
          ) : (
            <Col span={24}>
              <Empty
                description={
                  <>
                    <CustomRow justify='center'>
                      <Col span={24}>
                        <center>No medical aid details captured</center>
                      </Col>
                      {data ? (
                        <></>
                      ) : (
                        <Col span={24}>
                          <center>
                            <CustomButton
                              onClick={() => {
                                navigateToMedicalHistory()
                              }}
                              type='primary'
                              text={'Update'}
                            />
                          </center>
                        </Col>
                      )}
                    </CustomRow>
                  </>
                }
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default PersonalDetails
