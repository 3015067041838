import { Button, Card, Col, Form, Input, Modal, Row, Select, Space, Switch, Table, message, theme } from 'antd'
import React, { useState } from 'react'
import { handleError } from '../../lib/utils'
import { createClinicDataPoints, updateClinicDataPoints } from './data-points-utils/data-point-utils'
import { ContainerCard } from '../../reusable-components/AgiliteCards'

// Code Mirror
import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import { DataPointColumnTemplate, DataPointDataTemplate } from './data-points-utils/data-point-templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import CoreEnums from '../../../core/utils/enums'

const DataPointsForm = ({ record, isNew, refreshView, type }) => {
  const dispatch = useDispatch()
  const [dataPoint, setDataPoint] = useState(record)
  const [modalOpen, setModalOpen] = useState(false)
  const [isNewSubField, setIsNewSubField] = useState(false)
  const [subField, setSubField] = useState({})
  const [nestedSubFields, setNestedSubFields] = useState(false)

  const handleSubmit = async () => {
    let tmpDataPoint = JSON.parse(JSON.stringify(dataPoint))
    try {
      tmpDataPoint = {
        ...tmpDataPoint,
        ...form.getFieldsValue()
      }

      if (isNew) {
        await createClinicDataPoints(tmpDataPoint)
      } else {
        await updateClinicDataPoints(tmpDataPoint)
      }

      handleClose()
    } catch (e) {
      message.error(handleError(e))
    }
  }

  const handleClose = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNew ? `${CoreEnums.tabKeys.DATA_POINTS}_new` : `${CoreEnums.tabKeys.DATA_POINTS}_${record._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const handleSubmitSubField = () => {
    const tmpDataPoint = JSON.parse(JSON.stringify(dataPoint))
    const tmpData = subForm.getFieldsValue()

    if (isNewSubField) {
      tmpDataPoint.subFields.push(tmpData)
    } else {
      const index = tmpDataPoint.subFields.findIndex((x) => x.key === tmpData.key)
      tmpDataPoint.subFields[index] = tmpData
    }

    setDataPoint(tmpDataPoint)
    setSubField(DataPointDataTemplate())
    setModalOpen(false)
    subForm.resetFields()
  }

  const [form] = Form.useForm()
  const [subForm] = Form.useForm()

  const mainForm = (formInstance, data, type) => {
    return (
      <Form
        form={formInstance}
        initialValues={data}
        layout='vertical'
        onFinish={() => {
          if (modalOpen) {
            handleSubmitSubField()
          } else {
            handleSubmit()
          }
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label='Category' name='category'>
              <Select
                defaultValue={type}
                options={[
                  {
                    value: 'general',
                    label: 'General'
                  },
                  {
                    value: 'vitals',
                    label: 'Vitals'
                  },
                  {
                    value: 'rapid',
                    label: 'Rapid'
                  },
                  {
                    value: 'poc',
                    label: 'POC'
                  },
                  {
                    value: 'Lab',
                    label: 'lab'
                  }
                ]}
              />
            </Form.Item>
            <Form.Item label='Report Specific' name='reportSpecific' valuePropName='checked'>
              <Switch checkedChildren='Yes' unCheckedChildren='No' />
            </Form.Item>
            <Form.Item label='Has Lab Report' name='hasLabReport' valuePropName='checked'>
              <Switch checkedChildren='Yes' unCheckedChildren='No' />
            </Form.Item>
            <Form.Item name={['required', 'value']} label='Field Required' valuePropName='checked'>
              <Switch checkedChildren='Yes' unCheckedChildren='No' />
            </Form.Item>

            <Form.Item
              name={'key'}
              label='Field Key'
              rules={[
                {
                  required: true,
                  message: 'Please provide a Field Key'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'prefix'} label='Prefix'>
              <Input />
            </Form.Item>
            <Form.Item
              name={'fieldType'}
              label='Field Type'
              rules={[
                {
                  required: true,
                  message: 'Please select a Field Type'
                }
              ]}
            >
              <Select
                placeholder='Select a Field Type'
                options={[
                  { label: 'Text', value: 'text' },
                  { label: 'Text Area', value: 'textArea' },
                  { label: 'Number', value: 'number' },
                  { label: 'Select', value: 'select' },
                  { label: 'Radio Group', value: 'radio' },
                  { label: 'Checkbox', value: 'checkbox' },
                  { label: 'Checkbox Group', value: 'checkboxGroup' },
                  { label: 'Switch', value: 'switch' }
                ]}
              />
            </Form.Item>
            <Form.Item name={'nestedSubFields'} label='Nested Sub Fields?' valuePropName='checked'>
              <Switch checkedChildren='Yes' unCheckedChildren='No' onChange={(value) => setNestedSubFields(value)} />
            </Form.Item>
            {!nestedSubFields ? (
              <Form.Item name={'delimiter'} label='Delimiter'>
                <Input placeholder='Delimiter e.g "/" or ","' />
              </Form.Item>
            ) : undefined}
          </Col>
          <Col span={12}>
            <Form.Item name={['required', 'message']} label='Validation Message'>
              <Input />
            </Form.Item>
            <Form.Item
              name={'label'}
              label='Field Label'
              rules={[
                {
                  required: true,
                  message: 'Please provide a Field Label'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'suffix'} label='Suffix'>
              <Input />
            </Form.Item>
            <Form.Item
              name={'placeholder'}
              label='Placeholder'
              rules={[
                {
                  required: true,
                  message: 'Please provide a Field Placeholder'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Form.Item name={'data'} style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item name={'props'} style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <div style={{ width: '100%' }}>
            <h4>Data</h4>
            <CodeMirror
              value={data.data}
              onChange={(value) => formInstance.setFieldValue('data', value)}
              height='200px'
              extensions={[json()]}
            />
          </div>
          <div style={{ width: '100%' }}>
            <h4>Properties</h4>
            <CodeMirror
              value={formInstance.getFieldValue('props')}
              onChange={(value) => formInstance.setFieldValue('props', value)}
              height='200px'
              extensions={[json()]}
            />
          </div>
        </Row>
      </Form>
    )
  }

  const handleOpenModal = (isNewSub, data) => {
    setIsNewSubField(isNewSub)
    setSubField(isNewSub ? DataPointDataTemplate() : data)
    subForm.setFieldsValue(isNewSub ? DataPointDataTemplate() : data)
    setModalOpen(true)
  }

  const handleDeleteSubField = (index) => {
    const tmpDataPoint = JSON.parse(JSON.stringify(dataPoint))
    tmpDataPoint.subFields.splice(index, 1)

    setDataPoint(tmpDataPoint)
  }

  const { token } = theme.useToken()

  return (
    <ContainerCard title={isNew ? 'New Data Point' : `Data Point: ${dataPoint.key}`}>
      <Row justify='center'>
        <Col span={23}>
          <Card size='small'>
            <Row gutter={[12, 12]}>
              <Col span={10}>{mainForm(form, dataPoint, type)}</Col>
              <Col span={14}>
                <Card
                  title='Sub Fields'
                  size='small'
                  bordered={false}
                  extra={
                    <Button onClick={() => handleOpenModal(true, {})}>
                      <Space>
                        <FontAwesomeIcon icon={faAdd} color={token.colorSuccess} />
                        New
                      </Space>
                    </Button>
                  }
                >
                  <Table
                    size='small'
                    bordered
                    dataSource={dataPoint.subFields}
                    columns={[
                      ...DataPointColumnTemplate(),
                      {
                        title: 'Actions',
                        width: '5%',
                        render: (text, record, index) => (
                          <center>
                            <FontAwesomeIcon
                              onClick={(e) => {
                                e.stopPropagation()
                                handleDeleteSubField(index)
                              }}
                              color={token.colorError}
                              icon={faTrash}
                            />
                          </center>
                        )
                      }
                    ]}
                    pagination={false}
                    onRow={(record) => {
                      return {
                        onClick: () => handleOpenModal(false, record)
                      }
                    }}
                  />
                </Card>
              </Col>
            </Row>
            <Row justify='center' gutter={[12, 12]} style={{ marginTop: 10 }}>
              <Col>
                <Button type='primary' onClick={() => form.submit()}>
                  Submit
                </Button>
              </Col>
              <Col>
                <Button danger onClick={() => handleClose()}>
                  Close
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title={isNewSubField ? 'New Sub Field' : `Sub Field: ${subField.key}`}
        destroyOnClose
        closeIcon={false}
        open={modalOpen}
        okText='Submit'
        cancelText='Close'
        onOk={() => subForm.submit()}
        onCancel={() => {
          setModalOpen(false)
          setSubField({})
          subForm.resetFields()
        }}
        width='50%'
      >
        {mainForm(subForm, subField)}
      </Modal>
    </ContainerCard>
  )
}

export default DataPointsForm
