import { Col, Row, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import coreReducer from '../../../../core/utils/reducer'
import { handleError } from '../../../lib/utils'
import { findAgiliteUser } from '../../../Auth/utils/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import { readClinics } from '../../../Admin/clinics/utils/utils'

const BookingConfirmation = ({ bookingDetails, patient, isReceptionBooking, isVirtualVisit }) => {
  const dispatch = useDispatch()
  const coreState = useSelector((state) => state.core)
  const tabNavigationState = useSelector((state) => state.core.tabNavigation)
  const agiliteUser = useSelector((state) => state.auth.agiliteUser)
  const servicesState = useSelector((state) => state.services.data)
  const virtualServicesState = useSelector((state) => state.virtualServices.data)
  const [clinicName, setClinicName] = useState(null)
  const [clinicNameLoading, setClinicNameLoading] = useState(false)

  const clinicsState = useSelector((state) => state.clinics.data)
  const [mainPatient, setMainPatient] = useState(patient)

  useEffect(() => {
    if (mainPatient) {
      if (!mainPatient.phoneNumber && !mainPatient.email) {
        handleFindMainPatient()
      }
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getClinicName()
    // eslint-disable-next-line
  }, [bookingDetails.clinicRef])

  const handleFindMainPatient = async () => {
    let tmpMainPatient = null

    try {
      tmpMainPatient = await findAgiliteUser({ dependants: { $in: [mainPatient._id] } })

      if (tmpMainPatient) {
        setMainPatient(tmpMainPatient)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const checkBookingComms = () => {
    if (isReceptionBooking) {
      if (mainPatient.email && mainPatient.phoneNumber) {
        return 'Email and SMS'
      } else if (mainPatient.email) {
        return 'Email'
      } else if (mainPatient.phoneNumber) {
        return 'SMS'
      }
    } else {
      if (agiliteUser.email && agiliteUser.phoneNumber) {
        return 'Email and SMS'
      } else if (agiliteUser.email) {
        return 'Email'
      } else if (agiliteUser.phoneNumber) {
        return 'SMS'
      }
    }
  }

  const getServiceName = () => {
    const service =
      servicesState.find((i) => i._id === bookingDetails.service) ||
      virtualServicesState.find((i) => i._id === bookingDetails.service)
    return service.name
  }

  const getClinicName = async () => {
    setClinicNameLoading(true)
    try {
      const clinic = clinicsState.find((i) => i._id === bookingDetails.clinicRef)

      if (clinic) {
        setClinicName(clinic.name)
      } else {
        const tmpClinic = await readClinics({ _id: bookingDetails.clinicRef }, isVirtualVisit)
        setClinicName(tmpClinic[0].name)
      }
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setClinicNameLoading(false)
    }
  }

  const handleClose = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: tabNavigationState.activeKey,
        removeBreadcrumb: true
      })
    )
  }

  const generatePatientInfo = () => {
    if (patient) {
      return `${patient.firstName} ${patient.lastName}`
    } else {
      return `${agiliteUser.firstName} ${agiliteUser.lastName}`
    }
  }

  const { token } = theme.useToken()

  return (
    <Row justify='center'>
      <Col xs={24} sm={24} md={20} lg={18} xl={16} xxl={14}>
        <CustomRow justify='center' style={{ textAlign: 'center' }}>
          <Col span={24}>
            <img src={coreState.entity.entityLogo} alt='logo' style={{ width: '300px', maxWidth: '100%' }} />
            <h1>Booking successful</h1>
            {bookingDetails.otp ? (
              <>
                <p>
                  The Booking Confirmation has been sent to you via <b>{checkBookingComms()}</b>.
                </p>
                <p>
                  The booking confirmation will contain a <b>One-time Pin (OTP)</b> which you will require on the day of
                  the booking.
                </p>
              </>
            ) : null}
          </Col>
          <Col span={24}>
            <CustomRow
              gutter={[8, 8]}
              className='basic-card'
              style={{ background: token.colorPrimary, color: '#ffffff' }}
            >
              <Col span={24}>
                <h2>BOOKING CONFIRMATION</h2>
              </Col>
              <Col span={24}>
                <CustomRow className='basic-card' gutter={[2, 2]} style={{ background: 'rgba(255,255,255, 0.26)' }}>
                  <Col span={24}>
                    <p>
                      Service: {getServiceName()}
                      {bookingDetails.clinicRef === 'Home Visit' ? '(Home Visit)' : undefined}
                    </p>
                  </Col>
                  <Col span={24}>
                    {bookingDetails.startTime} - {bookingDetails.endTime}{' '}
                    {dayjs(bookingDetails.bookingDate).format('DD MMM YYYY')}
                  </Col>
                  <Col span={24}>
                    <b>Patient:</b> {generatePatientInfo()}
                  </Col>
                  <Col span={24}>
                    <p>
                      Medical Professional: {bookingDetails.medicalProf.firstName} {bookingDetails.medicalProf.lastName}
                    </p>
                  </Col>
                  {bookingDetails.clinicRef !== 'Home Visit' ? (
                    <>
                      <Col span={24}>
                        <p>Clinic: {clinicNameLoading ? 'Loading...' : clinicName}</p>
                      </Col>
                    </>
                  ) : undefined}
                  {bookingDetails.otp ? (
                    <>
                      <Col span={24}>
                        <b>One-time Pin (OTP):</b> {bookingDetails.otp}
                      </Col>
                    </>
                  ) : null}
                </CustomRow>
              </Col>
            </CustomRow>
          </Col>
          <Col span={24}>
            {bookingDetails.clinicRef !== 'Home Visit' && !isVirtualVisit ? (
              <>
                <Col span={24}>
                  <p>Please arrive 15 Minutes before your booking time for screening purposes.</p>
                </Col>
              </>
            ) : null}
          </Col>
          <Col span={24}>
            <center>
              <CustomButton
                style={{ width: 250, maxWidth: '100%' }}
                text={'Close'}
                onClick={() => {
                  handleClose()
                }}
                type='secondary'
              />
            </center>
          </Col>
        </CustomRow>
      </Col>
    </Row>
  )
}

export default BookingConfirmation
