import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const CustomDiagnosisTemplates = {
  dataModel: {
    code: {
      label: 'Diagnosis Code',
      placeholder: 'e.g. 0001',
      key: 'name',
      required: true,
      validationMsg: 'Please provide the clinic`s name'
    },
    description: {
      label: 'Practice Name:',
      placeholder: 'e.g. Dr Jane Doe Medical',
      required: true,
      key: 'practiceName'
    }
  },

  dataTemplate: () => {
    return {
      code: '',
      description: ''
    }
  },

  columnTemplate: (editRecord) => {
    return [
      {
        title: 'Description',
        dataIndex: 'description',
        width: '20%',
        key: 'description',
        render: (value, record) => {
          return (
            <>
              {value}{' '}
              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation()
                  editRecord(record)
                }}
                icon={faEdit}
              />
            </>
          )
        }
      },
      {
        title: 'Code',
        dataIndex: 'code',
        width: '20%',
        key: 'code',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name)
        }
      }
    ]
  }
}

export default CustomDiagnosisTemplates
