import { Alert, Button, Card, Col, Input, Modal, Popconfirm, Row, Space, Tag, theme } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { generateStatus } from '../../lib/booking-utils'
import { handleBookingNotice } from '../../lib/utils'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoCamera } from '@fortawesome/free-solid-svg-icons'
import { VirtualVisit } from './Examination Components/virtual-visit'
import CoreEnums from '../../../core/utils/enums'

const BookingSummaryModal = ({
  data,
  setData,
  modalOpen,
  setModalOpen,
  joinVirtualConsult,
  joined,
  setJoined,
  agoraToken,
  agoraChannel,
  agoraUid
}) => {
  const state = useSelector((state) => state)

  const { token } = theme.useToken()

  return (
    <>
      {data ? (
        <Modal
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { background: token.colorError, color: 'white' } }}
          cancelText='Close'
          okText='Begin examination'
          open={modalOpen}
          onCancel={() => {
            setModalOpen(false)
            setData(null)
          }}
        >
          <>
            <span style={{ fontSize: 18, color: token.colorPrimary }}>Booking Details</span>
            <Card type='inner' style={{ marginTop: 20 }}>
              <Row>
                <Col span={24}>
                  <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                    <div style={{ fontWeight: 'bold' }}>Date:</div>
                    <div>{dayjs(data.bookingDate).format('DD MMM YYYY')}</div>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                    <div style={{ fontWeight: 'bold' }}>Time:</div>
                    <div>
                      {data.startTime} - {data.endTime}
                    </div>
                  </Space>
                </Col>
                {state.auth.agiliteUser && state.auth.agiliteUser?.extraData.role.type === 'medical_professional' ? (
                  <>
                    <Col span={24}>
                      <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                        <div style={{ fontWeight: 'bold' }}>Patient:</div>
                        <div>{data.patientName}</div>
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                        <div style={{ fontWeight: 'bold' }}>Patient Brief:</div>
                        <div>{data.patientBrief}</div>
                      </Space>
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                      <div style={{ fontWeight: 'bold' }}>Medical Professional:</div>
                      <div>{data.medicalProfName}</div>
                    </Space>
                  </Col>
                )}
                <Col span={24}>
                  <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                    <div style={{ fontWeight: 'bold' }}>Service:</div>
                    <div>{data.serviceName ? data.serviceName : 'No service name'}</div>
                  </Space>
                </Col>
                {data.otp ? (
                  <Col span={24}>
                    <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                      <div style={{ fontWeight: 'bold' }}>One-time Pin:</div>
                      <div style={{ color: token.colorPrimary }}>{data.otp}</div>
                    </Space>
                  </Col>
                ) : undefined}
                <Col span={24}>
                  <Space direction='vertical' style={{ width: '100%', rowGap: 0 }} wrap>
                    <div style={{ fontWeight: 'bold' }}>Status:</div>
                    <div>{generateStatus(data?.status, token, data?.wasNoShow)}</div>
                  </Space>
                </Col>
              </Row>
            </Card>
            <Row style={{ marginTop: 20 }}>
              <Col span={24}>
                <Alert message={handleBookingNotice(data, state)} />
              </Col>
            </Row>
            {data.checkinData && data.checkinData.checkedIn && data.checkinData.patientInput ? (
              <Row style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Input.TextArea rows={3} value={data.checkinData.patientInput} />
                </Col>
              </Row>
            ) : undefined}
            {data.checkinData && data.checkinData.patientSelection.length > 0 ? (
              <Row style={{ marginTop: 20 }}>
                <Col span={24}>
                  {data.checkinData.patientSelection.map((tag) => {
                    return (
                      <Tag color='blue' style={{ borderColor: token.colorPrimary, padding: '4px 15px' }}>
                        {tag}
                      </Tag>
                    )
                  })}
                </Col>
              </Row>
            ) : undefined}
            {data.isVirtualVisit && data.status === CoreEnums.bookingStatuses.diagnosis ? (
              <Row justify='center' style={{ marginTop: 20 }}>
                <Col span={24}>
                  <center>
                    <Card>
                      <Popconfirm
                        title='Confirmation'
                        description='Are you sure you want to Join the Virtual Consultation?'
                        okText='Yes'
                        cancelText='No'
                        onConfirm={() => {
                          joinVirtualConsult(data._id)
                        }}
                        okButtonProps={{ danger: true }}
                      >
                        <Button type='primary' style={{ height: 50 }}>
                          <Space>
                            <FontAwesomeIcon icon={faVideoCamera} />
                            Join Virtual Consultation
                          </Space>
                        </Button>
                      </Popconfirm>
                    </Card>
                  </center>
                </Col>
              </Row>
            ) : undefined}
          </>
        </Modal>
      ) : undefined}
      <Modal
        // title='Virtual Consultation'
        open={joined}
        maskClosable={false}
        closable={false}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        destroyOnClose
        width={1920}
      >
        {/* <Row>
          <Col>
            <span>Date: {dayjs(data?.bookingDate).format('DD MMM YYYY')}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>
              Time: {data?.startTime} - {data?.endTime}
            </span>
          </Col>
        </Row> */}
        <div
          style={{ height: 30, borderTopRightRadius: 15, borderTopLeftRadius: 15, background: token.colorPrimary }}
        ></div>
        <VirtualVisit
          uid={agoraUid}
          token={agoraToken}
          channel={agoraChannel}
          patient={true}
          setJoined={setJoined}
          joined={joined}
        />
      </Modal>
    </>
  )
}

export default BookingSummaryModal
