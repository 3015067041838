import React, { useEffect, useState } from 'react'
import { Col, Input, Modal, Row, Space, Switch } from 'antd'
import BillingTool from '../../../Bookings/components/Examination Components/billing-tool'

const BillingMacroForm = ({ visible, setVisible, isNew, macro, setMacro, macros, setMacros, index }) => {
  const [macroName, setMacroName] = useState(macro.name)

  useEffect(() => {
    let tmpMacro = null

    if (isNew) {
      tmpMacro = {
        isActive: true,
        name: '',
        items: [{ type: undefined, code: undefined, name: undefined, icd10: undefined, qty: 0 }]
      }
    } else {
      tmpMacro = macro
      tmpMacro.items.push({ type: undefined, code: undefined, name: undefined, icd10: undefined, qty: 0 })
    }

    setMacro(tmpMacro)
    setMacroName(tmpMacro.name)

    // eslint-disable-next-line
  }, [])

  const handleOk = () => {
    const tmpMacros = [...macros]
    const tmpMacro = { ...macro }

    tmpMacro.items = tmpMacro.items.filter((item) => item.type && item.code && item.name && item.qty > 0)

    if (isNew) {
      tmpMacros.push(tmpMacro)
    } else {
      tmpMacros[index] = tmpMacro
    }

    setMacros(tmpMacros)
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
    setMacro({ isActive: true, name: '', items: [] })
  }

  const handleFieldChange = (field, value) => {
    setMacro({ ...macro, [field]: value })
  }

  return (
    <Modal
      title={isNew ? 'New Macro' : `Edit ${macroName} Macro`}
      destroyOnClose
      open={visible}
      closable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      width='70%'
    >
      <Row style={{ marginBottom: 10 }}>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <>
              <p>Is Active:</p>
              <Switch
                checked={macro.isActive}
                onChange={(checked) => {
                  handleFieldChange('isActive', checked)
                }}
                checkedChildren='Yes'
                unCheckedChildren='No'
              />
            </>
            <>
              <p>Name:</p>
              <Input
                value={macro.name}
                onChange={(e) => {
                  handleFieldChange('name', e.target.value)
                }}
                placeholder='Macro Name'
              />
            </>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BillingTool
            billing={{}}
            itemSelection={macro.items}
            setItemSelection={(items) => setMacro({ ...macro, items })}
            readOnly={false}
            isMacroForm={true}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default BillingMacroForm
