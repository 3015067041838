import React, { useCallback, useEffect } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { debounce } from 'lodash'
import DictaphoneControls from './dictaphone-controls'

const Dictaphone = ({
  value,
  setValue,
  setTmpTranscript,
  setIsRecording,
  isRecording,
  noteId,
  handleUpdateNote,
  disabled,
  buttonStyle
}) => {
  const {
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript,
    finalTranscript,
    interimTranscript,
    transcript
  } = useSpeechRecognition()

  useEffect(() => {
    if (setValue && interimTranscript) {
      setTmpTranscript(interimTranscript)
    }

    // eslint-disable-next-line
  }, [interimTranscript])

  useEffect(() => {
    if (setValue && finalTranscript) {
      setValue(value + `<p>${formatFinalScript(finalTranscript)}</p>`)
      handleUpdateNote(noteId, value + `<p>${formatFinalScript(finalTranscript)}</p>`)
    }

    // eslint-disable-next-line
  }, [finalTranscript])

  const formatFinalScript = (finalTranscript) => {
    let tmpScript = finalTranscript
    tmpScript = tmpScript.charAt(0).toUpperCase() + tmpScript.slice(1)
    return tmpScript + '. '
  }

  useEffect(() => {
    if (isRecording) {
      startListening()
    }

    // eslint-disable-next-line
  }, [isRecording])

  useEffect(() => {
    stopListeningDebounced()

    // eslint-disable-next-line
  }, [transcript])

  // eslint-disable-next-line
  const stopListeningDebounced = useCallback(
    debounce(() => {
      stopListening()
    }, 3000),
    []
  )

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>
  }

  const startListening = () => {
    setIsRecording(true)
    SpeechRecognition.startListening({ continuous: true })
  }

  const stopListening = async () => {
    await SpeechRecognition.stopListening()
    setIsRecording(false)
    resetTranscript()
  }

  return (
    <DictaphoneControls
      buttonStyle={buttonStyle}
      disabled={disabled}
      listening={listening}
      startListening={startListening}
      stopListening={stopListening}
    />
  )
}

export default Dictaphone
