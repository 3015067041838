const CustomDiagnosisEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    CUSTOM_DIAGNOSIS: 'custom_diagnosis'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update'
  }
}

export default CustomDiagnosisEnums
