import { Col, Row, Space, Spin, Tag } from 'antd'
import React, { useEffect, useState } from 'react'

const BmiSimple = ({ data, loading }) => {
  const containerStyle = {
    boxShadow: '0px 0px 10px 0px #ECEDEE',
    padding: '20px',
    borderRadius: 12
  }

  const [bmi, setBmi] = useState(0)

  useEffect(() => {
    if (data?.examinationData?.checkinData?.bmi) {
      setBmi(Math.floor(data?.examinationData?.checkinData?.bmi))
    }
  }, [data])

  const calculateBmiColour = () => {
    if (bmi < 18.5) return '#8ae2df'
    if (bmi < 25) return '#96e1c8'

    if (bmi < 32) return '#f9d67a'
    if (bmi < 38) return '#e5b686'
    if (bmi >= 38) return '#e3808d'
  }

  return (
    <Row style={containerStyle} gutter={[0, 20]}>
      <Col span={24}>Body Mass Index (BMI)</Col>
      <Col span={24}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <h1 style={{ fontSize: 48, color: calculateBmiColour() }}>{loading ? <Spin /> : bmi}</h1>
          <Tag color='#96e1c8' style={{ color: 'black' }}>
            <h2>Ideal: 19-25</h2>
          </Tag>
        </Space>
      </Col>
      <Col span={24}>
        <div className='custom-bmi-scale-container'>
          <div id='bmi-bar-1' className='custom-bmi-scale-bar'></div>
          <div className='custom-bmi-scale-labels-container'>
            <div className='custom-bmi-scale-labels'>15</div>
            <div className='custom-bmi-scale-labels'>18.5</div>
            <div className='custom-bmi-scale-labels'>25</div>
            <div className='custom-bmi-scale-labels'>30</div>
            <div className='custom-bmi-scale-labels'>40</div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default BmiSimple
