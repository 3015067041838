export const ExaminationEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    CLINICAL_NOTES_TEMPLATES: 'clinical_notes_templates',
    CLINICAL_DATA_RECORDS: 'clinical_data_records',
    CLINICAL_NOTES: 'clinical_notes',
    CLINICAL_REPORT_RECORDS: 'clinical_report_records',
    SICK_NOTES: 'sick_notes',
    REFERRAL_LETTERS: 'referral_letters'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    BULK_WRITE: 'bulk_write',
    AGGREGATE: 'aggregate'
  }
}
