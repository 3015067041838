import Agilite from 'agilite'
import LibEnums from './enums'
import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space, Switch } from 'antd'
import { DateTime } from 'luxon'
import Store from '../../store'
import CoreEnums from '../../core/utils/enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

const logAgiliteError = async (e, message) => {
  try {
    await agilite.BatchLogging.createLogEntry(
      LibEnums.LOG_PROFILE_KEY,
      LibEnums.META_CLINIC,
      LibEnums.META_CLINIC_PORTAL,
      LibEnums.errorCodes[400],
      message,
      LibEnums.contentTypes.APPLICATION_JSON,
      {
        error: e.stack ? e.stack : e,
        user: Store.getState().auth.agiliteUser ? Store.getState().auth.agiliteUser : 'No Authenticated User'
      }
    )
  } catch (e) {}
}

const handleError = (e = '', logError = false, skipChecks = false) => {
  let errorMessage = ''
  let messageMatch = null

  if (!skipChecks) {
    if (!e.response) {
      errorMessage = 'Network error occured. Please check your internet connection and try again'
    } else if (e.response && e.response.data?.errorMessage) {
      return e.response.data.errorMessage
    } else if (e.message) {
      messageMatch = e.message.match(/\(([^)]+)\)/)

      if (messageMatch && messageMatch.length > 0) {
        switch (messageMatch[1]) {
          case 'auth/email-already-exists':
            errorMessage = 'Email already exists'
            break
          // case 'auth/id-token-expired':
          // case 'auth/id-token-revoked':
          // case 'auth/internal-error':
          case 'auth/too-many-requests':
            errorMessage = 'You have made too many log in attempts on this device. Please try again later.'
            break
          case 'auth/invalid-credential':
            errorMessage = 'Unknown error occured. Please contact support'
            break
          case 'auth/insufficient-permission':
            errorMessage = 'You do not have permission to perform this action'
            break
          case 'auth/invalid-email':
            errorMessage = 'Invalid email'
            break
          case 'auth/invalid-password':
            errorMessage = 'Invalid password'
            break
          case 'auth/invalid-phone-number':
            errorMessage = 'Invalid cellphone number'
            break
          case 'auth/invalid-photo-url':
            errorMessage = 'Invalid Photo'
            break
          case 'auth/phone-number-already-exists':
            errorMessage = 'Cellphone number already exists'
            break
          case 'auth/user-not-found':
            errorMessage = 'User not found'
            break
          case 'auth/account-exists-with-different-credential':
            errorMessage = 'Account exists with different credentials'
            break
          case 'auth/invalid-verification-code':
            errorMessage = 'Invalid verification code'
            break
          default:
            errorMessage = e.message
        }
      } else {
        try {
          JSON.parse(e.message)
          errorMessage = 'Unknown error occured. Please contact support'
        } catch (error) {
          if (e.message === '[object Object]') {
            errorMessage = 'Unknown error occured. Please contact support'
          } else {
            errorMessage = e.message
          }
        }
      }
    } else {
      errorMessage = 'Unknown error occured. Please contact support'
    }
  } else {
    errorMessage = e
  }

  if (logError) {
    // We are not awaiting logError as we want to execute it in the background
    logAgiliteError(e, errorMessage)
  }

  console.error(e)
  return errorMessage
}

const getTimeSlots = (start, end) => {
  const arr = []
  const range = [start, end]
  const timeStringToFloat = (time) => {
    const hoursMinutes = time.split(/[.:]/)
    const hours = parseInt(hoursMinutes[0], 10)
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
    return hours + minutes / 60
  }
  let hourRange = timeStringToFloat(range[1]) - timeStringToFloat(range[0])
  let count = 0
  let startHour = null

  while (hourRange !== 0.25) {
    hourRange = hourRange - 0.25
    count++
  }

  arr.push(range[0])

  startHour = Number(range[0].replace(':', ''))

  while (count > 0) {
    if (startHour.toString().slice(-2) === '45') {
      startHour = startHour + 55
    } else {
      startHour = startHour + 15
    }

    if (startHour.toString().length < 4) {
      arr.push('0' + startHour.toString().slice(0, 1) + ':' + startHour.toString().slice(-2))
    } else {
      arr.push(startHour.toString().slice(0, 2) + ':' + startHour.toString().slice(-2))
    }

    count--
  }

  arr.push(range[1])

  return arr
}
const checkOverlappingTimes = (times) => {
  const arr = []
  times.forEach((item) => {
    arr.push({ start: item[0], end: item[1] })
  })
  const overlapping = (a, b) => {
    const getMinutes = (s) => {
      const p = s.split(':').map(Number)
      return p[0] * 60 + p[1]
    }
    return getMinutes(a.end) > getMinutes(b.start) && getMinutes(b.end) > getMinutes(a.start)
  }
  const isOverlapping = (arr) => {
    let i, j
    let overlappingTimes = []
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (overlapping(arr[i], arr[j])) {
          overlappingTimes.push(arr[i])
          overlappingTimes.push(arr[j])
        }
      }
    }
    return overlappingTimes
  }
  const errorTimes = []
  const errorSlots = []
  isOverlapping(arr).forEach((item) => {
    errorTimes.push([item.start, item.end])
  })
  times.forEach((slot) => {
    let timeCheck = JSON.stringify(errorTimes)
    let slotCheck = JSON.stringify(slot)
    let c = timeCheck.indexOf(slotCheck)
    if (c !== -1) errorSlots.push(slot)
  })

  if (errorSlots.length > 0) return true
  else return false
}

const getTimeslotsReworked = (time) => {
    return ((h, m) => h * 4 + m / 15)(...time.split(':').map(parseFloat))
  },
  toTime = (int) => {
    return [Math.floor(int / 4), int % 4 === 1 ? '15' : int % 4 === 2 ? '30' : int % 4 === 3 ? '45' : '00'].join(':')
  },
  range = (from, to) => {
    return Array(to - from + 1)
      .fill()
      .map((_, i) => from + i)
  },
  eachHalfHour = (t1, t2) => range(...[t1, t2].map(getTimeslotsReworked)).map(toTime)

const toCamelCase = (value) => {
  let result = value
    .trim() //might need polyfill if you need to support older browsers
    .toLowerCase() //lower case everything
    .replace(
      /([^A-Z0-9]+)(.)/gi, //match multiple non-letter/numbers followed by any character
      function (match) {
        return arguments[2].toUpperCase() //3rd index is the character we need to transform uppercase
      }
    )
  return result
}

const generateOTP = (length) => {
  const digits = '0123456789'
  let OTP = ''

  for (let i = 0; i < length; i++) {
    OTP += digits[Math.floor(Math.random() * 10)]
  }

  return OTP
}

const handleBookingNotice = (booking, state) => {
  const service =
    state.services.data.find((s) => s._id === booking.service) ||
    state.virtualServices.data.find((s) => s._id === booking.service)

  if (booking.status === CoreEnums.bookingStatuses.cancelled) {
    return 'Booking was cancelled.'
  } else {
    if (booking.status === CoreEnums.bookingStatuses.completed) {
      return 'This consultation has been completed.'
    } else {
      if (state.auth.agiliteUser.extraData.role.type === 'medical_professional') {
        if (booking.checkinData?.ailment) {
          if (booking.status === CoreEnums.bookingStatuses.billing) {
            return 'Finalizing billing.'
          } else {
            return booking.checkinData.ailment
          }
        } else {
          return 'Patient needs to check in.'
        }
      } else {
        if (service?.type === 'Home Visit') {
          switch (booking.status) {
            case CoreEnums.bookingStatuses.screening:
              return 'A nurse will meet you at your requested destination to perform a screening.'
            case CoreEnums.bookingStatuses.diagnosis:
              return 'A doctor has been assigned to your case and will perform an examination.'
            case CoreEnums.bookingStatuses.billing:
              return 'Our admin department are finalizing the billing process.'
            case CoreEnums.bookingStatuses.completed:
              return 'Your consultation is complete.'
          }
        } else {
          if (booking.isVirtualVisit) {
            return 'Please click on the Join Virtual Consultation button below to join the Virtual Consultation'
          } else {
            switch (booking.status) {
              case CoreEnums.bookingStatuses.checkin:
                return 'Please checkin at the clinic using your One-time Pin (OTP).'
              case CoreEnums.bookingStatuses.screening:
                return 'A nurse will attend to you shortly and conduct a screening.'
              case CoreEnums.bookingStatuses.diagnosis:
                return 'You will be taken through to your doctor shortly for examination.'
              case CoreEnums.bookingStatuses.billing:
                return 'Our admin department are finalizing the billing process.'
              case CoreEnums.bookingStatuses.completed:
                return 'Your consultation is complete.'
            }
          }
        }
      }
    }
  }
}
const handleCalculateDOB = (id) => {
  let dob = ''
  let year = ''
  let month = ''
  let day = ''

  if (id.length === 13) {
    year = id.substring(0, 2)
    month = id.substring(2, 4)
    day = id.substring(4, 6)

    if (parseInt(year) > 0) {
      dob = `19${year}-${month.substring(0, 2)}-${day}`
    } else {
      dob = `20${year}-${month.substring(0, 2)}-${day}`
    }
  }

  return dob
}

const handleCalculateGender = (id) => {
  if (id.substring(6, 10) > 4999) {
    return 'male'
  } else {
    return 'female'
  }
}

const specialFieldValidation = (
  form,
  state,
  setState,
  config = {
    key: null,
    event: null,
    validationConfig: {
      numbers: false,
      letters: { allowed: false, onlyCaps: false },
      onlyCaps: false,
      spaces: true,
      specialChars: false
    }
  }
) => {
  const tmpRecord = JSON.parse(JSON.stringify(state))
  let error = null
  let value = config.event.target.value

  if (value.charAt(0) === ' ') {
    error = true
  }

  if (!config.validationConfig.spaces) {
    if (/\s/.test(value)) {
      error = true
    }
  }

  if (!config.validationConfig.numbers) {
    if (/\d/.test(value)) {
      error = true
    }
  }
  if (!config.validationConfig.letters.allowed) {
    if (/[a-zA-ZÀ-ú-_ ]/g.test(value)) {
      error = true
    }
  }
  if (config.validationConfig.letters.onlyCaps) {
    value = value.toUpperCase()
  }

  if (!config.validationConfig.specialChars) {
    // eslint-disable-next-line
    if (/[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      error = true
    }
  }
  // if (!config.validationConfig.spaces) {
  //   if (value.indexOf(' ') >= 0) {
  //     error = true
  //   }
  // }

  let tmpIndex = state.findIndex((i) => i.key === config.key)
  if (!error) {
    if (tmpIndex === -1) {
      tmpRecord.push({ key: config.key, value: value })
      setState(tmpRecord)
      form.setFieldValue(config.key, value)
    } else {
      tmpRecord[tmpIndex].value = value
      setState(tmpRecord)
      form.setFieldValue(config.key, value)
    }
  } else {
    let previousRecord = state.find((i) => i.key === config.key)
    if (previousRecord) {
      form.setFieldValue(config.key, previousRecord.value)
    } else {
      form.setFieldValue(config.key, null)
    }
  }
  return value
}

const allTrim = (
  trimValue = {
    firstLevel1: 'hello Sir ',
    secondLevel1: ' hello',
    thirdLevel1: ['test ', 'test2 '],
    firstLevel2: { firstLevel3: ' hello', secondLevel3: { firstLevel4: 'hello ' } }
  }
) => {
  const isObject = (object) => {
    for (const [key, value] of Object.entries(object)) {
      if (value) {
        if (typeof value === 'string') {
          object[key] = object[key].trim()
        }
        if (typeof value === 'object') {
          isObject(object[key])
        }
        if (Array.isArray(value)) {
          isArray(object[key])
        }
      }
    }
  }
  const isArray = (array) => {
    array.forEach((item) => {
      if (item) {
        if (typeof item === 'string') {
          item = item.trim()
        }
        if (Array.isArray(item)) {
          isArray(item)
        }
        if (typeof item === 'object') {
          isObject(item)
        }
      }
    })
  }

  if (typeof trimValue === 'object') {
    isObject(trimValue)
  }

  if (Array.isArray(trimValue)) {
    isArray(trimValue)
  }
  if (trimValue === 'string') {
    trimValue = trimValue.trim()
  }

  return trimValue
}

const generateFormItem = (config, form, specialFormState, setSpecialFormState, onChange) => {
  const switchContainerStyle = { alignItems: 'center', marginBottom: '24px', display: 'flex', gap: '8px' }

  if ((form && form.getFieldValue(config?.dependency)) || !config.dependency) {
    if (config.type === 'checkbox' || config.fieldType === 'checkbox') {
      return (
        <Space>
          <p>{config.label}</p>
          <Form.Item style={{ margin: '0' }} name={config.key} valuePropName='checked'>
            <Checkbox />
          </Form.Item>
        </Space>
      )
    }
    if (config.type === 'switch' || config.fieldType === 'switch') {
      return (
        <div style={switchContainerStyle}>
          {config.label}
          <Form.Item style={{ margin: '0' }} name={config.key} valuePropName='checked'>
            <Switch
              checkedChildren={config.checkedChildren || JSON.parse(config.data).checkedChildren}
              unCheckedChildren={config.uncheckedChildren || JSON.parse(config.data).uncheckedChildren}
            />
          </Form.Item>
        </div>
      )
    } else {
      return (
        <Form.Item
          {...config.formProps}
          name={config.key}
          label={<b style={{ fontSize: 15 }}>{config.label}</b>}
          rules={[
            {
              required: config.required,
              message: config.validationMsg
            }
          ]}
        >
          {/*======================
                      SELECT        
            ======================*/}
          {config.type === 'select' || config.fieldType === 'select' ? (
            <Select
              style={{ width: 'auto' }}
              placeholder={'- Select -'}
              options={config.options || JSON.parse(config.data).options}
            />
          ) : null}

          {/*======================
                      NUMBER        
            ======================*/}
          {config.type === 'number' || config.fieldType === 'number' ? (
            <InputNumber
              onKeyDown={(event) => {
                if (event.key === ',') {
                  event.preventDefault()
                  // Get the current input element
                  const input = event.target
                  // Insert a dot at the current cursor position
                  const { selectionStart, selectionEnd, value } = input
                  const newValue = value.slice(0, selectionStart) + '.' + value.slice(selectionEnd)
                  input.value = newValue
                  // Manually set the cursor position after the dot
                  input.setSelectionRange(selectionStart + 1, selectionStart + 1)
                }
              }}
              style={{ width: 'auto' }}
              placeholder={0}
              addonAfter={config.suffix}
              // maxLength={3}
            />
          ) : undefined}

          {/*======================
                      INPUT 
            ======================*/}
          {config.type === 'input' || config.fieldType === 'input' || config.fieldType === 'text' ? (
            <Input
              onChange={(e) => {
                let value = ''
                if (specialFormState && setSpecialFormState) {
                  value = specialFieldValidation(form, specialFormState, setSpecialFormState, {
                    key: config.key,
                    event: e,
                    validationConfig: {
                      letters: { allowed: true, onlyCaps: false },
                      numbers: false,
                      spaces: true
                    }
                  })
                } else {
                  value = e.target.value
                }
                if (onChange) {
                  onChange(value)
                }
              }}
              placeholder={config.placeholder || ''}
              rules={[{ required: true, message: 'First name can`t be blank' }]}
              addonAfter={config.suffix}
            />
          ) : undefined}

          {/*======================
                      Text Area 
            ======================*/}
          {config.type === 'textArea' || config.fieldType === 'textArea' ? (
            <Input.TextArea
              onChange={(e) => {
                if (specialFormState && setSpecialFormState) {
                  specialFieldValidation(form, specialFormState, setSpecialFormState, {
                    key: config.key,
                    event: e,
                    validationConfig: {
                      letters: { allowed: true, onlyCaps: false },
                      numbers: false,
                      spaces: true
                    }
                  })
                }
              }}
              placeholder={config.placeholder || 'Your text here...'}
              rules={[{ required: true, message: 'First name can`t be blank' }]}
              rows={config.rows || 4}
            />
          ) : undefined}

          {/*======================
              CHECKBOX GROUP        
            ======================*/}
          {config.type === 'checkboxGroup' || config.fieldType === 'checkboxGroup' ? (
            <Checkbox.Group>
              <Row gutter={[12, 24]}>
                {(config.options || JSON.parse(config.data).options).map((option, index) => {
                  return (
                    <Col span={config.props?.colSpan || 24} key={index}>
                      <Space>
                        {option.label}
                        <Checkbox value={option.value} style={{ margin: '8px 0px' }} />
                      </Space>
                    </Col>
                  )
                })}
              </Row>
            </Checkbox.Group>
          ) : undefined}

          {config.type === 'datePicker' || config.fieldType === 'datePicker' ? (
            <DatePicker format='DD MMMM YYYY' style={{ minWidth: 250 }} {...config.customProps} />
          ) : undefined}

          {config.type === 'radioGroup' || config.fieldType === 'radioGroup' || config.fieldType === 'radio' ? (
            <Radio.Group onChange={onChange} {...config.props}>
              {(config.options || JSON.parse(config.data).options).map((option) => {
                return <Radio value={option.value}>{option.label}</Radio>
              })}
            </Radio.Group>
          ) : undefined}
          {config.type === 'radioGroupButtons' || config.fieldType === 'radioGroupButtons' ? (
            <Radio.Group onChange={onChange} {...config.props}>
              {(config.options || JSON.parse(config.data).options).map((option) => {
                return <Radio.Button value={option.value}>{option.label}</Radio.Button>
              })}
            </Radio.Group>
          ) : undefined}
        </Form.Item>
      )
    }
    /*======================
            CHECKBOX        
          ======================*/
  }
}

const convertDateTimeSAST = (dateTime, dateOnly) => {
  // dateOnly means that we want apply timezone offset to the date only and not the time in the case
  // that only the date is relevant and the time isn't needed
  if (dateOnly) {
    // returns 2023-01-01
    return DateTime.fromJSDate(new Date(dateTime)).setZone('Africa/Johannesburg').toISODate()
  } else {
    // returns 2023-01-01T00:00:00.000+02:00
    return DateTime.fromJSDate(new Date(dateTime)).setZone('Africa/Johannesburg').toISO()
  }
}

const capitalizeFirstLetter = (string) => {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1)
}

const parseIdToDateString = (numStr) => {
  //if the last 2 digits of the input year are less than the last 2 of the current year
  //assume they were born after the year 2000
  var currentYearTwoDigits = parseInt(new Date().getFullYear().toString().substring(2, 4), 10)
  var inputYear = parseInt(numStr.substring(0, 2), 10)
  var bestGuessYear = (inputYear < currentYearTwoDigits ? '20' : '19') + inputYear

  let day = parseInt(numStr.substring(4, 6), 10)
  if (day.toString().length === 1) {
    day = '0' + day.toString()
  }

  let month = parseInt(numStr.substring(2, 4), 10)
  if (month.toString().length === 1) {
    month = '0' + month.toString()
  }
  let year = parseInt(bestGuessYear, 10)
  return `${year}-${month}-${day}`
}

const hexToRGBA = (hex, opacity) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const calculateDistanceInKm = (coord1, coord2) => {
  const dx = coord1.lat - coord2.lat
  const dy = coord1.lng - coord2.lng
  // Convert the distance from degrees to kilometers
  return Math.sqrt(dx * dx + dy * dy) * 111.325
}

const findClosestCoordinate = (targetCoord, coordsList) => {
  const distances = coordsList.map((coord) => ({
    coord,
    distance: calculateDistanceInKm(targetCoord, coord)
  }))

  distances.sort((a, b) => a.distance - b.distance)

  return distances
}

const calculateBMI = (weight, heightCm) => {
  const heightMeters = heightCm / 100
  return weight / (heightMeters * heightMeters)
}

const generateGenderPrefix = (gender) => {
  switch (gender) {
    case 'male':
      return 'm'
    case 'female':
      return 'f'
  }
}

const generateTitle = (gender) => {
  switch (gender) {
    case 'male':
      return 'Mr'
    case 'female':
      return 'Mrs'
  }
}

const extractAddressDetails = (addressData) => {
  // This will hold the final address components
  const result = {
    streetAddress: '',
    city: '',
    province: '',
    postalCode: '',
    country: ''
  }

  if (!Array.isArray(addressData) || addressData.length === 0) {
    console.error('Invalid address data provided.')
    return result
  }

  // Access the first item's address components if it's an array with items
  const components = addressData[0].address_components
  if (!Array.isArray(components)) {
    console.error('Address components are missing or invalid.')
    return result
  }

  // Loop through each component and populate the result object based on the type
  components.forEach((component) => {
    if (component.types.includes('street_number')) {
      result.streetAddress += `${component.long_name} `
    } else if (component.types.includes('route')) {
      result.streetAddress += component.long_name
    } else if (component.types.includes('locality')) {
      result.city = component.long_name
    } else if (component.types.includes('administrative_area_level_1')) {
      result.province = component.long_name
    } else if (component.types.includes('country')) {
      result.country = component.long_name
    } else if (component.types.includes('postal_code')) {
      result.postalCode = component.long_name
    }
  })

  return result
}

const stringToColor = (string) => {
  // Simple string to color function
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = Math.abs(hash).toString(16).substring(0, 6)
  color = '#' + '000000'.substring(0, 6 - color.length) + color // Pad with zeros

  // Reduce brightness
  const brightnessFactor = 0.7 // Adjust to make colors darker (0.1 to 1.0)
  const rgb = parseInt(color.substring(1), 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff
  const adjustedR = Math.floor(r * brightnessFactor)
  const adjustedG = Math.floor(g * brightnessFactor)
  const adjustedB = Math.floor(b * brightnessFactor)
  color = `#${((1 << 24) + (adjustedR << 16) + (adjustedG << 8) + adjustedB).toString(16).slice(1)}`

  return color
}

const extractCGMError = (errorString) => {
  try {
    const match = errorString.match(/{.*}/)

    if (!match) {
      console.error('Error object not found in CGM Error Message')
      return 'An unknown error occurred, please contact support.'
    }

    const errorObject = JSON.parse(match[0])
    let actualError = null
    let returnError = null

    for (const key in errorObject) {
      if (errorObject.hasOwnProperty(key) && errorObject[key].status === 'ERROR') {
        actualError = errorObject[key].description
        break
      }
    }

    if (actualError) {
      const actualErrorObj = JSON.parse(actualError.split('Message: ')[1])

      if (actualErrorObj.errors && actualErrorObj.errors.length > 0) {
        returnError = actualErrorObj.errors.join('')
      }
    }

    return returnError
  } catch (err) {
    console.error('Failed to parse CGM error message:')
    console.error(err)
    return 'An unknown error occurred, please contact support.'
  }
}

function maybeThrowError() {
  if (Math.random() < 0.5) {
    throw new Error('Forced Error Thrown')
  }
}

function getDateOfBirthFromID(idNumber) {
  if (!/^\d{13}$/.test(idNumber)) {
    return
  }

  const currentDate = new Date()
  const yearPrefix = currentDate.getFullYear().toString().slice(0, 2)

  let year = idNumber.slice(0, 2)
  let month = idNumber.slice(2, 4)
  let day = idNumber.slice(4, 6)

  // Determine the century
  year =
    parseInt(year, 10) <= parseInt(currentDate.getFullYear().toString().slice(2), 10)
      ? yearPrefix + year
      : (parseInt(yearPrefix, 10) - 1).toString() + year

  // Create date string in the format YYYY-MM-DD
  const birthDate = `${year}-${month}-${day}`

  return birthDate
}
export {
  handleError,
  logAgiliteError,
  getTimeSlots,
  checkOverlappingTimes,
  eachHalfHour,
  capitalizeFirstLetter,
  toCamelCase,
  generateFormItem,
  generateOTP,
  handleBookingNotice,
  handleCalculateDOB,
  handleCalculateGender,
  specialFieldValidation,
  allTrim,
  convertDateTimeSAST,
  parseIdToDateString,
  hexToRGBA,
  findClosestCoordinate,
  generateGenderPrefix,
  generateTitle,
  extractAddressDetails,
  stringToColor,
  calculateBMI,
  extractCGMError,
  maybeThrowError,
  getDateOfBirthFromID
}
