import medicalHistoryState from './state'
import { createSlice } from '@reduxjs/toolkit'

const MedicalHistoryReducer = createSlice({
  name: 'medicalHistoryReducer ',
  initialState: medicalHistoryState,
  reducers: {
    setPatient: (state, action) => {
      state.patient = action.payload
    }
  }
})

export default MedicalHistoryReducer
