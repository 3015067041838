import { familyRelations } from '../utils/templates'

export const handleRelationChange = (value) => {
  let tmpOptions = []

  if (value) {
    familyRelations.forEach((option) => {
      let checkMatch = false
      let optionValue = ''
      option.checks.forEach((check) => {
        if (check.toLocaleLowerCase().match(value.toLocaleLowerCase())) {
          checkMatch = true
          optionValue = option.value
        }
      })
      if (checkMatch) {
        tmpOptions.push({
          value: optionValue,
          label: optionValue
        })
      }
    })
  }
  return tmpOptions
}
