import React from 'react'
import { Col, Table, theme } from 'antd'
import Templates from '../utils/templates'
import coreReducer from '../../../../core/utils/reducer'
import DiagnosisOverview from './diagnosis-overview'
import { useDispatch } from 'react-redux'
import CoreEnums from '../../../../core/utils/enums'
import dayjs from 'dayjs'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { deviceDetect } from 'react-device-detect'
// import { handleError } from '../../../../lib/utils'
// import { downloadScript, sendScript } from '../utils/utils'
// import dayjs from 'dayjs'
const ListView = ({ data, loading, userRef, handleGetBookings }) => {
  const { token } = theme.useToken()
  const dispatch = useDispatch()

  const handleDiagnosisOverview = (record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.DIAGNOSIS_HISTORY}_${record._id}`,
        closable: true,
        label: `Diagnosis: ${dayjs(record.bookingDate).format('YYYY-MM-DD')}`,
        children: <DiagnosisOverview data={record} />
      })
    )
  }

  return (
    <>
      {userRef ? (
        <CustomRow className='basic-card'>
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={data}
              pagination={false}
              columns={Templates.columnTemplate(token)}
              rowKey={(record) => record._id}
            />
          </Col>
        </CustomRow>
      ) : (
        <CustomRow justify='center' className='basic-card'>
          {deviceDetect().isMobile ? undefined : (
            <Col span={24}>
              <CustomButton
                text='Refresh'
                size='small'
                icon={faRefresh}
                onClick={handleGetBookings}
                type='primary'
                disabled={loading}
              />
            </Col>
          )}
          <Col span={24}>
            <Table
              showHeader={deviceDetect().isMobile ? false : true}
              loading={loading}
              style={{ border: 'none' }}
              bordered={false}
              dataSource={data}
              pagination={false}
              columns={Templates.columnTemplate(token)}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleDiagnosisOverview(record)
                  }
                }
              }}
              rowKey={(record) => record._id}
            />
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default ListView
