import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const aggregateAvailability = (pipeline) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('availability', 'aggregate', {
          pipeline: JSON.stringify(pipeline)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readAvailability = (qry) => {
  return new Promise((resolve, reject) => {
    let response = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute('availability', 'read', {
          filter: JSON.stringify(qry),
          page: null,
          pageLimit: null
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
export const createAvailability = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('availability', 'create', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
export const updateAvailability = (filter, data, options) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('availability', 'update', {
          filter: JSON.stringify(filter),
          data: JSON.stringify(data),
          options: JSON.stringify(options)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
