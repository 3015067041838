import axios from 'axios'
import { AssistantEnums } from './assitants-enums'

export const assistantReadThread = async (thread_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let assistant_response = null

      try {
        assistant_response = await axios.post(AssistantEnums.routes.READ_THREAD, {
          thread_id
        })

        resolve(assistant_response)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const assistantInitiate = async (newMessage, assistant_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(AssistantEnums.routes.INITIATE_THREAD, {
          assistant_id: assistant_id,
          message: newMessage
        })
        if (response.data) {
          resolve(response.data)
        } else {
          throw new Error('There was an issue contacting your assistant.')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const assistantContinue = async (newMessage, thread_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(AssistantEnums.routes.CONTINUE_THREAD, {
          thread_id: thread_id,
          role: 'user',
          content: newMessage
        })

        if (response.data) {
          resolve(response.data)
        } else {
          throw new Error('There was an issue contacting your assistant.')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const assistantCheckRunStatus = async (thread_id, run_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(AssistantEnums.routes.CHECK_THREAD_STATUS, {
          thread_id,
          run_id
        })
        if (response?.data) {
          resolve(response.data)
        } else {
          throw new Error('There was an issue contacting your assistant.')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}
