import Agilite from 'agilite'
import CoreEnums from '../../../../core/utils/enums'
import PatientEnums from './enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readPatients = (qry, options, projection, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(PatientEnums.profileKeys.AUTH_USERS, PatientEnums.routeKeys.FIND, {
          filter: JSON.stringify({
            $or: [{ 'extraData.role.type': 'patient' }, { 'extraData.role.type': null }],
            ...qry
          }),
          options: JSON.stringify(options),
          projection,
          page,
          pageLimit
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readPatientsAggregate = (pipeline) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(PatientEnums.profileKeys.AUTH_USERS, 'aggregate', {
          pipeline: JSON.stringify(pipeline)
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readMedicalHistory = (qry) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          PatientEnums.profileKeys.PATIENT_RECORD,
          PatientEnums.routeKeys.READ,
          {
            filter: JSON.stringify({
              ...qry
            }),
            page: null,
            pageLimit: null
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
