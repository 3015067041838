import React, { useEffect, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Card, Col, message, Space, Table, theme } from 'antd'
import { hexToRGBA } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import TeamSearch from './TeamSearch'
import { linkTeamMember } from '../../utils/utils'

const TeamList = ({ teamRecordReference, setTeamRecordReference, setModalOpen, onAfterSuccess }) => {
  const [newTeamMember, setNewTeamMember] = useState(null)
  const [facet, setFacet] = useState('list')

  useEffect(() => {
    if (newTeamMember && facet === 'search') {
      setFacet('confirm')
    } else {
      if (facet === 'confirm') {
        setFacet('search')
      }
    }

    // eslint-disable-next-line
  }, [newTeamMember])

  const EnrolledPatientsTemplates = () => {
    return {
      columnTemplate: [
        {
          title: 'User',
          render: (record) => `${record.firstName} ${record.lastName}`
        },
        {
          title: 'Contact',
          render: (record) =>
            record.phoneNumber ? (
              record.phoneNumber
            ) : (
              <small>
                <em style={{ color: token.colorError }}>NOT AVAILABLE</em>
              </small>
            )
        },
        {
          title: 'Email',
          render: (record) =>
            record.email ? (
              record.email
            ) : (
              <small>
                <em style={{ color: token.colorError }}>NOT AVAILABLE</em>
              </small>
            )
        },
        {
          title: 'Role',
          render: (record) =>
            record.extraData.profession ? (
              record.extraData.profession.charAt(0).toUpperCase() + record.extraData.profession.slice(1).toLowerCase()
            ) : (
              <small>
                <em style={{ color: token.colorError }}>NOT AVAILABLE</em>
              </small>
            )
        },
        {
          render: (record) => (
            <Space>
              <Button>
                <FontAwesomeIcon icon={faCancel} />
              </Button>
            </Space>
          )
        }
      ]
    }
  }

  const handleAddTeamMember = async () => {
    try {
      await linkTeamMember(newTeamMember._id, teamRecordReference._id)
      onAfterSuccess(teamRecordReference, newTeamMember)
    } catch (error) {
      message.error(error)
    }
  }
  const { token } = theme.useToken()

  return (
    <CustomRow>
      <Col span={24}>
        {facet === 'list' ? (
          <Card
            bodyStyle={{ padding: 0, overflow: 'scroll', maxHeight: 450 }}
            headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            extra={
              <Button
                onClick={() => {
                  setFacet('search')
                }}
                type='primary'
                style={{ background: token.colorSuccess }}
              >
                Add Team Member
                <FontAwesomeIcon icon={faUserPlus} />
              </Button>
            }
            title='TEAM MEMBERS'
            size='small'
          >
            <Table dataSource={teamRecordReference?.team} columns={EnrolledPatientsTemplates().columnTemplate} />
          </Card>
        ) : undefined}
        {facet === 'search' ? (
          <Card>
            <TeamSearch setProfile={setNewTeamMember} teamRecordReference={teamRecordReference} />
          </Card>
        ) : undefined}
        {facet === 'confirm' ? (
          <Card>
            {newTeamMember.firstName}{' '}
            <Button
              onClick={() => {
                handleAddTeamMember()
              }}
            ></Button>
          </Card>
        ) : undefined}
      </Col>
      <Button
        onClick={() => {
          setModalOpen(false)
        }}
      >
        Close
      </Button>
    </CustomRow>
  )
}

export default TeamList
