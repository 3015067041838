const VirtualServicesEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    ADMINISTRATION_VIRTUAL_SERVICES: 'virtual_services'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update'
  }
}

export default VirtualServicesEnums
