import React from 'react'

const WeekEventContent = ({ patientRecord, service, recordData }) => {
  return (
    <div style={{ width: '100%' }}>
      <div direction='vertical' style={{ display: 'flex', flexDirection: 'column', gap: 0, width: '100%' }}>
        <span style={{ fontSize: 10 }}>
          {`${patientRecord.gender === 'male' ? 'MR' : 'MS'} ${patientRecord.lastName}`.toUpperCase()}
        </span>
        <b style={{ fontSize: 10 }}>{recordData?.status.toUpperCase()}</b>
      </div>
    </div>
  )
}

export default WeekEventContent
