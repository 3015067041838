import React from 'react'
import { useSelector } from 'react-redux'
import { convertDateTimeSAST } from '../../../lib/utils'
import dayjs from 'dayjs'

const DashboardGreeting = () => {
  const state = useSelector((state) => state)
  const getGreetingMessage = () => {
    let date = new Date()
    date = convertDateTimeSAST(date, false)
    const hour = dayjs(date).hour()
    if (hour < 12) {
      return 'Good morning'
    } else if (hour < 18) {
      return 'Good afternoon'
    } else {
      return 'Good evening'
    }
  }
  return (
    <section>
      <div>
        <span style={{ fontSize: 20 }}>{getGreetingMessage()}</span>{' '}
        <span className='custom-font-bold' style={{ fontSize: 28 }}>
          {state.auth?.agiliteUser?.firstName} {state.auth?.agiliteUser?.lastName}
        </span>
      </div>
    </section>
  )
}

export default DashboardGreeting
