import { Button } from 'antd'
import React, { useState, useRef } from 'react'

const VoiceRecorder = ({ handleOnStop, setRecordingTracker }) => {
  const [recording, setRecording] = useState(false)
  const [audioURL, setAudioURL] = useState('')
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      mediaRecorderRef.current = new MediaRecorder(stream)

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data)
      }

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' })
        const audioUrl = URL.createObjectURL(audioBlob)
        setAudioURL(audioUrl)
        handleOnStop(audioBlob)

        audioChunksRef.current = []
      }

      mediaRecorderRef.current.start()
      setRecording(true)
      setRecordingTracker(true)
    } catch (error) {
      console.error('Error accessing media devices.', error)
    }
  }

  const stopRecording = () => {
    mediaRecorderRef.current.stop()
    setRecording(false)
    setRecordingTracker(false)
  }

  return (
    <div>
      <Button onClick={startRecording} disabled={recording}>
        Start Recording
      </Button>
      <Button onClick={stopRecording} disabled={!recording}>
        Stop Recording
      </Button>
    </div>
  )
}

export default VoiceRecorder
