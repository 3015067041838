import { Col, Input, message } from 'antd'
import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import CustomButton from '../../reusable-components/CustomButton'
import { useSelector } from 'react-redux'

const CheckinOTPCapture = ({
  otp,
  setOtp,
  loading,
  setBookingService,
  checkinConfig,
  services,
  handleVerify,
  MetaLogo,
  setView,
  setPatientInput,
  setCurrentSelection,
  setPatientSelection
}) => {
  const coreState = useSelector((state) => state.core)
  return (
    <CustomRow
      gutter={[12, 12]}
      style={{ maxWidth: '100%', alignItems: 'center', minHeight: '100vh' }}
      justify='center'
    >
      <Col style={{ width: 1050, maxWidth: '90%' }}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <center>
              <img src={coreState.entity.entityLogo} alt='Meta Logo' style={{ width: 341, maxWidth: '100%' }} />
            </center>
          </Col>
          <Col span={24}>
            <center>
              <h2 style={{ width: 450, textAlign: 'center' }}>
                Please provide us with the OTP (One-time PIN) provided to you for your booking.
              </h2>
            </center>
          </Col>
          <Col span={24}>
            <Input
              style={{ textAlign: 'center', padding: 16 }}
              value={otp}
              placeholder='Input One-time Pin'
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  setOtp(e.target.value)
                }
              }}
            />
          </Col>
          <Col span={24}>
            <center>
              <CustomButton
                type='secondary'
                style={{ width: '100%' }}
                disabled={!checkinConfig || !services}
                loading={loading || !checkinConfig || !services}
                onClick={() => {
                  if (otp) {
                    handleVerify()
                  } else {
                    message.error("Please provide your booking's One-time Pin.")
                  }
                }}
                text={loading ? 'Verifying' : 'NEXT'}
              />
            </center>
          </Col>
          <Col span={24}>
            <center>
              <CustomButton
                loading={loading}
                onClick={() => {
                  setView('')
                  setOtp('')
                  setPatientInput('')
                  setCurrentSelection('')
                  setPatientSelection([])
                  setBookingService('')
                }}
                disabled={loading}
                style={{ width: '100%' }}
                text='Cancel Checkin'
              />
            </center>
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default CheckinOTPCapture
