import React from 'react'
import Templates from '../../utils/templates'
import { generateFormItem } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import { Col } from 'antd'

const MedHistCovid = ({ clientForm }) => {
  return (
    <>
      <CustomRow className='basic-card'>
        <Col span={24}>
          {generateFormItem(Templates.dataModel.hadCovid, clientForm)}
          {generateFormItem(Templates.dataModel.fullyVaccinated, clientForm)}
          {generateFormItem(Templates.dataModel.lastVaccinatedDate, clientForm)}
        </Col>
      </CustomRow>
    </>
  )
}

export default MedHistCovid
