export const DataPointsEnums = {
  titles: {
    DATA_POINTS: 'Data Points'
  },
  profileKeys: {
    CLINICAL_DATA_POINTS: 'clinical_data_points'
  },
  routeKeys: {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete',
    AGGREGATE: 'aggregate'
  }
}
