export const FullCalendarEnums = {
  viewTypeKeys: {
    DAY_VIEW: 'timeGridDay',
    WEEK_VIEW: 'timeGridWeek',
    MONTH_VIEW: 'dayGridMonth'
  },
  viewTypeLabels: {
    DAY_VIEW: 'Day',
    WEEK_VIEW: 'Week',
    MONTH_VIEW: 'Month'
  }
}
