import { Col } from 'antd'
import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'

import DashboardGreeting from './components/dashboard-greeting'
import BookingsCountSummary from './components/bookings-count-summary'
import TodaysPatients from './components/todays-patients/todays-patients'

const ReceptionDashboard = ({
  todaysBookingsCount,
  todaysPatientsLoading,
  setTodaysPatientsLoading,
  getTodaysPatients,
  monthsBookingsCount
}) => {
  return (
    <CustomRow justify='center' style={{ minHeight: '100vh', background: '#f4f9fa' }}>
      <Col span={24}>
        <CustomRow>
          <Col span={24}>
            <DashboardGreeting />
          </Col>
          <Col l xs={24} sm={24} md={24} lg={14}>
            <BookingsCountSummary todaysBookingsCount={todaysBookingsCount} monthsBookingsCount={monthsBookingsCount} />
          </Col>
          <Col span={24}>
            <TodaysPatients
              refreshFunction={getTodaysPatients}
              loading={todaysPatientsLoading}
              setLoading={setTodaysPatientsLoading}
            />
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default ReceptionDashboard
