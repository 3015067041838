import { Button, Space, message, theme } from 'antd'
import React, { useState } from 'react'
import { handleMobileNextDisabled, handleVerifyAvailability } from '../../utils/lib'
import { deviceDetect } from 'react-device-detect'
import { handleError } from '../../../lib/utils'

const MobileNav = ({
  mobileSteps,
  setMobileSteps,
  loading,
  handleAppointmentSearch,
  prevStep,
  nextStep,
  location,
  province,
  bookingDetails,
  active,
  isFinalizeStep,
  finalizeForm,
  finalizeFormDisabled,
  isVirtualVisit
}) => {
  const { token } = theme.useToken()
  const [verifying, setVerifying] = useState(false)
  const containerStyle = {
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    borderTop: `1px ${token.colorBorderSecondary} solid`,
    background: token.colorBgContainer,
    padding: '12px 12px',
    boxSizing: 'border-box'
  }

  const handleSearchSteps = async (action) => {
    try {
      if (action === 'back') {
        if (mobileSteps === 1) {
          setMobileSteps(mobileSteps - 1)
        }
        if (mobileSteps === 2) {
          setMobileSteps(mobileSteps - 1)
        }
        if (mobileSteps === 3) {
          setMobileSteps(mobileSteps - 1)
        }
      } else {
        if (mobileSteps === 1) {
          if (province || isVirtualVisit) {
            handleAppointmentSearch()
          } else {
            message.error('Please select a province')
          }
        }
        if (mobileSteps === 2) {
          await handleVerifyAvailability(bookingDetails, setVerifying)
          nextStep()
        }
      }
    } catch (e) {
      message.error(handleError(e))
    }
  }
  if (deviceDetect().isMobile) {
    return (
      <div style={containerStyle}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button
            type='primary'
            disabled={mobileSteps === 1 || loading || verifying}
            onClick={() => {
              if (isFinalizeStep) {
                prevStep()
              } else {
                handleSearchSteps('back')
              }
            }}
          >
            <span>BACK</span>
          </Button>
          <Button
            disabled={handleMobileNextDisabled(mobileSteps, location, active) || finalizeFormDisabled}
            loading={loading || verifying}
            type='primary'
            onClick={async () => {
              // if (mobileSteps === 1) {
              //   setMobileSteps(mobileSteps + 1)
              // }
              try {
                if (isFinalizeStep) {
                  await handleVerifyAvailability(bookingDetails, setVerifying)
                  finalizeForm.submit()
                } else {
                  handleSearchSteps()
                }
              } catch (e) {
                message.error(handleError(e))
              }
            }}
          >
            {/* {mobileSteps === 1 ? (
              <span>
                NEXT <FontAwesomeIcon icon={faArrowRight} />
              </span>
            ) : null} */}
            {mobileSteps === 1 ? <span>FIND</span> : null}
            {mobileSteps === 2 ? <span>NEXT</span> : null}
            {isFinalizeStep ? <span>CONFIRM</span> : null}
          </Button>
        </Space>
      </div>
    )
  }
}

export default MobileNav
