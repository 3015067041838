import React from 'react'
import { Card, Col, Row, theme } from 'antd'

const App = () => {
  const { token } = theme.useToken()

  return (
    <Row justify='center'>
      <Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={18}>
        <Card headStyle={{ background: token.colorSecondaryLight }} title={<center>PORTAL DASHBOARD</center>}></Card>
      </Col>
    </Row>
  )
}

export default App
