import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Card, Col, Empty, Space, Modal, theme, message, Table, Button, Tooltip } from 'antd'
import { AvailableReports } from './reporting-utils'
import './report.css'
import { handleError, hexToRGBA } from '../../lib/utils'
import { readBookings } from '../../Bookings/utils/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { readAllBookings } from '../bookings/utils/utils'

const ReportList = ({ userRef }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [bookings, setBookings] = useState([])
  const [loadingBookings, setLoadingBookings] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)
  const [printing, setPrinting] = useState()

  const { token } = theme.useToken()

  useEffect(() => {
    fetchBookings()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedReport) {
      setModalOpen(true)
    }
  }, [selectedReport])

  const fetchBookings = async () => {
    let tmpBookings = []
    setLoadingBookings(true)
    try {
      tmpBookings = await readBookings({ userRef })
      setBookings(tmpBookings)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoadingBookings(false)
  }

  const filterAvailableReports = () => {
    return AvailableReports.filter((report) => {
      let exists = false
      exists = bookings.some((booking) => booking?.examinationData?.checkinData?.hasOwnProperty(report.key))
      return exists
    })
  }

  const filterAvailableBookings = () => {
    let tmpBookings = [...bookings]
    tmpBookings = tmpBookings.filter((booking) =>
      booking?.examinationData?.checkinData?.hasOwnProperty(selectedReport?.key)
    )
    tmpBookings = tmpBookings.sort((a, b) => b.bookingDate.localeCompare(a.bookingDate))
    return tmpBookings
  }

  const handleGenerateReport = async (bookingId) => {
    setPrinting(true)
    try {
      let bookingData = await readAllBookings({ _id: bookingId })
      if (bookingData[0]) {
        await selectedReport.generate(bookingData[0])
      } else {
        throw new Error('Booking could not be retrieved.')
      }
    } catch (e) {
      message.error(handleError(e))
    }
    setPrinting(false)
  }

  return (
    <CustomRow>
      <Col span={24}>
        <Card title={<center>AVAILABLE REPORTS</center>} size='small' type='inner'>
          {loadingBookings ? (
            <AgiliteSkeleton skActive spinnerTip='Loading available reports...' />
          ) : (
            <Space style={{ justifyContent: 'center', width: '100%', alignItems: 'flex-start' }}>
              {filterAvailableReports()?.length > 0 ? (
                <>
                  {filterAvailableReports().map((report) => {
                    return (
                      <Card
                        size='small'
                        headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
                        style={{ width: 350, maxWidth: '100%' }}
                        bodyStyle={{ padding: 0 }}
                        title={<center>{report.title}</center>}
                        key={report.key}
                      >
                        <div>
                          <div style={{ padding: 10, textAlign: 'justify', maxHeight: 200, overflow: 'scroll' }}>
                            {report.description}
                          </div>
                          <div
                            onClick={() => setSelectedReport(report)}
                            className='report-print-button'
                            style={{ width: '100%', padding: 10 }}
                          >
                            <center>PRINT</center>
                          </div>
                        </div>
                      </Card>
                    )
                  })}
                </>
              ) : (
                <Empty description='No available reports to show.' />
              )}
            </Space>
          )}
        </Card>
      </Col>
      <Modal
        closeIcon={<FontAwesomeIcon color={token.colorError} fontSize={24} icon={faXmarkCircle} />}
        title={selectedReport?.title + ' Report'}
        onCancel={() => {
          setModalOpen(false)
          setSelectedReport(null)
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ disabled: printing }}
        cancelText='CLOSE'
        open={modalOpen}
        closable={!printing}
        maskClosable={false}
      >
        {printing ? (
          <AgiliteSkeleton skActive spinnerTip={`Printing ${selectedReport?.title} report...`} />
        ) : (
          <Table
            showHeader={false}
            pagination={{ pageSize: 5 }}
            dataSource={filterAvailableBookings()}
            columns={[
              {
                title: 'Date',
                render: (booking) => (
                  <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <p>{dayjs(booking.bookingDate).format('DD MMMM YYYY')}</p>
                    <Tooltip title='Print PDF'>
                      <Button
                        disabled={printing}
                        style={{ background: token.colorError, color: '#fff' }}
                        onClick={() => {
                          handleGenerateReport(booking._id)
                        }}
                      >
                        PDF
                      </Button>
                    </Tooltip>
                  </Space>
                )
              }
            ]}
          />
        )}
      </Modal>
    </CustomRow>
  )
}

export default ReportList
