import { Col, Divider, Empty, Space, Tag, theme } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { deviceDetect } from 'react-device-detect'
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import CustomRow from '../../../reusable-components/CustomRow'
import BmiLegend from './bmi-legend'
import { bmiMethod, filterByDateRange } from '../utils'
import { generateXAxisLabel } from '../../lib'

const BmiGraph = ({ data }) => {
  const [graphData, setGraphData] = useState([])
  // eslint-disable-next-line
  const [dateRange, setDateRange] = useState()

  useEffect(() => {
    handleSetGraphData()
    // eslint-disable-next-line
  }, [data, dateRange])

  const handleSetGraphData = () => {
    const tmpGraphData = []

    let tmpData = []

    if (dateRange) {
      tmpData = filterByDateRange(data, dateRange[0], dateRange[1])
    } else {
      tmpData = data
    }

    tmpData.forEach((entry) => {
      if (entry?.examinationData?.checkinData?.bmi) {
        tmpGraphData.push({
          name: `${dayjs(entry.bookingDate).format('DD MMM YYYY')}`,
          BMI: Number(entry?.examinationData?.checkinData?.bmi)
        })
      }
    })
    setGraphData(tmpGraphData)
  }
  const customizedDot = (props) => {
    let value = props.payload.BMI
    let color = null
    const { cx, cy } = props
    Object.keys(bmiMethod(value)).forEach((key) => {
      const object = bmiMethod(value)[key]
      if (object.statusTest) {
        color = object.color
      }
    })

    return <circle cx={cx} cy={cy} r={6} strokeWidth={3} fill={color} />
  }
  const { token } = theme.useToken()
  return (
    <CustomRow className='basic-card'>
      <>
        {graphData.length > 0 ? (
          <Col span={24}>
            <ResponsiveContainer width='100%' height={300}>
              <ComposedChart
                data={graphData}
                margin={{
                  top: 5,
                  right: 6,
                  bottom: 15,
                  left: 0
                }}
              >
                <CartesianGrid stroke={token.colorBorderSecondary} />
                <XAxis
                  stroke={token.colorTextBase}
                  dataKey='name'
                  fontSize={14}
                  label={{ value: generateXAxisLabel(graphData), dy: 20, style: { fontSize: 14 } }}
                  tick={false}
                />
                <YAxis
                  stroke={token.colorTextBase}
                  dataKey={'BMI'}
                  label={{ value: 'Body Mass Index', angle: -90, dx: -20, style: { fontSize: 14 } }}
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    let bmi = 0
                    return (
                      <>
                        {deviceDetect().isMobile ? (
                          <Space
                            style={{
                              width: '100%',
                              justifyContent: 'space-between',
                              background: 'rgba(255,255,255,1)',
                              boxShadow: '0 0 5px 0 rgba(0,0,0,0.155)',
                              padding: 4,
                              borderRadius: 8
                            }}
                          >
                            {/* eslint-disable-next-line */}
                            {payload.map((item) => {
                              bmi = item.value
                            })}
                            {bmi}
                            <span style={{ opacity: 0.455 }}>BMI</span>
                          </Space>
                        ) : (
                          <CustomRow
                            className='basic-card'
                            style={{
                              background: 'rgba(255,255,255,0.95)',
                              boxShadow: '0 0 10px 0 rgba(0,0,0,0.25)',
                              width: 250
                            }}
                          >
                            <Col span={24}>
                              <center>
                                <p span={24}>{label}</p>
                              </center>
                              <Divider style={{ margin: '4px 0px' }} />
                              {payload.forEach((item) => {
                                bmi = item.value
                              })}
                              <>
                                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                  <p>BMI:</p> <p>{bmi}</p>
                                </Space>
                                {Object.keys(bmiMethod(bmi)).map((key) => {
                                  const object = bmiMethod(bmi)[key]
                                  if (object.statusTest) {
                                    return (
                                      <center style={{ marginTop: 8 }}>
                                        <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                                          {object.title}
                                        </Tag>
                                      </center>
                                    )
                                  } else {
                                    return null
                                  }
                                })}
                              </>
                            </Col>
                          </CustomRow>
                        )}{' '}
                      </>
                    )
                  }}
                  contentStyle={{
                    color: token.colorTextBase,
                    background: token.colorBgContainer,
                    border: `2px ${token.colorBorderSecondary} solid`
                  }}
                  itemStyle={{ color: token.colorTextBase }}
                />
                <Line type='monotone' dataKey='BMI' stroke='#8884d8' activeDot={{ r: 8 }} dot={customizedDot} />
              </ComposedChart>
            </ResponsiveContainer>
          </Col>
        ) : (
          <Col span={24}>
            <Empty description='No BMI readings captured to date.' />
          </Col>
        )}
        <Col span={24}>
          <BmiLegend />
        </Col>
      </>
    </CustomRow>
  )
}

export default BmiGraph
