import React from 'react'
import { Drawer, Menu, Modal, Space, theme } from 'antd'

import { signOut } from 'firebase/auth'
import { batch, useDispatch, useSelector } from 'react-redux'

import { handleError } from '../../custom/lib/utils'
import { firebaseAuth } from '../..'
import CoreReducer from '../utils/reducer'
import Theme from '../utils/theme'
import CoreEnums from '../utils/enums'

// Components
import Router from '../../custom/Auth/components/router'
import authReducer from '../../custom/Auth/utils/reducer'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HomeOutlined } from '@ant-design/icons'

const LeftMenu = (props) => {
  const dispatch = useDispatch()
  const { token } = theme.useToken()
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)

  const handleSignOut = async () => {
    try {
      await signOut(firebaseAuth)
      batch(() => {
        dispatch(CoreReducer.actions.resetTabs())
        dispatch(CoreReducer.actions.resetState())
        dispatch(CoreReducer.actions.enableDisableLeftMenu(false))
        dispatch(CoreReducer.actions.enableDisableRightMenu(false))
        dispatch(CoreReducer.actions.enableDisableTabs(false))
        dispatch(CoreReducer.actions.setRootContent(Router))
        dispatch(authReducer.actions.setUser(null))
        dispatch(authReducer.actions.setAgiliteUser(null))
        dispatch(authReducer.actions.setLoggedIn(false))
      })
    } catch (e) {
      handleError(e, true)
    }
  }

  const handleCheckTabKey = () => {
    let key = null

    props.menuItems().forEach((item) => {
      if (coreState.tabNavigation.activeKey.indexOf(item.key) > -1) {
        key = item.key
      }
    })

    if (key) {
      return key
    } else {
      return coreState.tabNavigation.activeKey
    }
  }

  function getItem(label, key, icon, children, newItem) {
    let tmpLabel = label

    if (newItem) {
      tmpLabel = (
        <Space>
          {label}
          <sup style={{ fontSize: 13, color: 'yellow' }}>new</sup>
        </Space>
      )
    }

    if (children && children.length > 0) {
      for (const child of children) {
        let tmpChildLabel = child.label

        if (child.new) {
          tmpChildLabel = (
            <Space>
              {child.label}
              <sup style={{ fontSize: 13, color: 'yellow' }}>new</sup>
            </Space>
          )

          child.new = false
        }

        child.label = tmpChildLabel
      }
    }

    return {
      key,
      icon,
      children,
      label: tmpLabel
    }
  }

  const filterMenuItems = () => {
    let filteredItems = []
    let menuItems = []

    const menuItemIsValid = (menuItem) => {
      let valid = true
      if (menuItem.additionalValidatiors && menuItem.additionalValidatiors.length > 0) {
        menuItem.additionalValidatiors.forEach((validator) => {
          valid = validator()
        })
      }
      return valid
    }

    props.menuItems().forEach((item) => {
      if (
        (item.visibleTo.includes(authState.agiliteUser?.extraData?.role?.type) ||
          item.visibleTo.includes(authState.agiliteUser?.extraData?.profession)) &&
        menuItemIsValid(item)
      ) {
        if (item.propInEntity && item.enabledProp) {
          if (coreState.entity[item.propInEntity][item.enabledProp]) {
            filteredItems.push(item)
          }
        } else {
          filteredItems.push(item)
        }
      }

      item.children = item.children?.filter((child) => {
        return (
          (child.visibleTo.includes(authState.agiliteUser?.extraData?.role?.type) ||
            item.visibleTo.includes(authState.agiliteUser?.extraData?.profession)) &&
          menuItemIsValid(child)
        )
      })
    })

    menuItems = filteredItems.map((item) => {
      return getItem(item.label, item.key, item.icon ? item.icon : <HomeOutlined />, item.children, item.new)
    })

    return menuItems
  }

  return (
    <Drawer
      title={<div style={{ color: props.secondaryLight || Theme.secondaryLight }}>{props.leftMenuTitle}</div>}
      placement='left'
      closable={true}
      width={300}
      open={props.visible}
      onClose={props.onLeftMenuClose}
      headerStyle={{
        backgroundColor: token.colorHeader
      }}
      bodyStyle={{ padding: 0, paddingTop: 0, background: token.colorHeader }}
      closeIcon={<FontAwesomeIcon icon={faClose} color='white' style={{ fontSize: 24 }} />}
    >
      <Menu
        // _internalDisableMenuItemTitleTooltip
        onClick={(event) => {
          if (event.key === CoreEnums.tabKeys.SIGN_OUT) {
            Modal.confirm({
              title: 'Confirmation',
              content: `Are you sure you want to sign out of the ${coreState.entity.name} Portal?`,
              okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
              cancelButtonProps: { style: { background: token.colorError, color: 'white' } },
              onOk: () => handleSignOut(),
              okText: 'Yes',
              cancelText: 'No',
              className: token.themeControl
            })
          } else {
            dispatch(CoreReducer.actions.menuItemClick(event))
          }
        }}
        defaultSelectedKeys={['home']}
        selectedKeys={handleCheckTabKey()}
        defaultOpenKeys={coreState.defaultOpenKeys}
        mode='inline'
        items={filterMenuItems()}
        style={{ minHeight: '100%' }}
      />
    </Drawer>
  )
}

export default LeftMenu
