import { Col, Form, Input, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCountries } from '../../Auth/utils/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import MedicalHistoryDetails from './medical-history-details'

const ContactInfo = ({ setEnableNext, subscription, setSubscription, token }) => {
  const authState = useSelector((state) => state.auth)
  const [countriesLoading, setCountriesLoading] = useState(true)
  const [country, setCountry] = useState({ code: 'za', name: 'South Africa', phoneCode: '+27' })

  useEffect(() => {
    handleGetCountries()

    if (subscription.extraData.phoneNumber) {
      setEnableNext(true)
    } else {
      setEnableNext(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (subscription.extraData.phoneNumber) {
      setEnableNext(true)
    } else {
      setEnableNext(false)
    }
    // eslint-disable-next-line
  }, [subscription])

  const handleGetCountries = async () => {
    let tmpCountries = []
    let tmpCountry = null

    setCountriesLoading(true)

    try {
      tmpCountries = await getCountries({ code: authState.agiliteUser.country })

      if (tmpCountries.length > 0) {
        tmpCountry = tmpCountries[0]
        setCountry(tmpCountry)
      } else {
        tmpCountry = { code: 'za', name: 'South Africa', phoneCode: '+27' }
        setCountry(tmpCountry)
      }
    } catch (e) {
      message.error(e)
    }

    setCountriesLoading(false)
  }

  const [form] = Form.useForm()

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `${country ? country.phoneCode : '+27'}${phoneNumber.slice(1, 10)}`
    } else {
      if (phoneNumber) {
        phoneNumber = `${country ? country.phoneCode : '+27'}${phoneNumber}`
      }
    }

    return phoneNumber
  }

  return (
    <>
      <Row justify='center' style={{ marginBottom: 10 }}>
        <Col span={24}>
          <center style={{ marginBottom: 10 }}>
            <b>Confirm Your Contact Number</b>
          </center>
        </Col>
      </Row>
      <Row justify='center'>
        {countriesLoading ? (
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Loading...' />
          </Col>
        ) : (
          <div>
            <Col></Col>
            <Col>
              <Form
                initialValues={{
                  phoneNumber: subscription.extraData.phoneNumber.replace(country.phoneCode, '')
                }}
                form={form}
              >
                <Form.Item name='phoneNumber' rules={[{ required: true, message: 'Please provide a Phone Number' }]}>
                  <Input
                    maxLength={15}
                    addonBefore={country.phoneCode}
                    placeholder='8288*****'
                    onChange={(e) => {
                      setSubscription({
                        ...subscription,
                        extraData: { ...subscription.extraData, phoneNumber: formatNumber(e.target.value) }
                      })
                      form.setFieldsValue({ phoneNumber: e.target.value })
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </div>
        )}
      </Row>
      <Row justify='center'>
        <Col span={24}>
          <MedicalHistoryDetails subscription={subscription} token={token} />
        </Col>
      </Row>
    </>
  )
}

export default ContactInfo
