import { useSelector } from 'react-redux'

import DoctorDashboard from './doctor-dashboard'
import NurseDashboard from './nurse-dashboard'

const MedicalProfessionalDashboard = ({
  todaysPatients,
  todaysBookingsCount,
  todaysPatientsLoading,
  getTodaysPatients,
  monthsBookingsCount,
  setTodaysPatientsLoading
}) => {
  const state = useSelector((state) => state)

  const generateProfessionDashboard = () => {
    switch (state.auth?.agiliteUser?.extraData.profession) {
      case 'doctor':
        return (
          <DoctorDashboard
            todaysPatients={todaysPatients}
            todaysBookingsCount={todaysBookingsCount}
            todaysPatientsLoading={todaysPatientsLoading}
            monthsBookingsCount={monthsBookingsCount}
            setTodaysPatientsLoading={setTodaysPatientsLoading}
            getTodaysPatients={getTodaysPatients}
          />
        )
      default:
        return (
          <NurseDashboard
            todaysPatients={todaysPatients}
            getTodaysPatients={getTodaysPatients}
            todaysBookingsCount={todaysBookingsCount}
            todaysPatientsLoading={todaysPatientsLoading}
            monthsBookingsCount={monthsBookingsCount}
            setTodaysPatientsLoading={setTodaysPatientsLoading}
          />
        )
    }
  }
  return <>{generateProfessionDashboard()}</>
}

export default MedicalProfessionalDashboard
