const state = {
  user: null,
  agiliteUser: null,
  medicalProf: null,
  config: null,
  netCashConfig: null,
  terms: null,
  loggedIn: false,
  completeEmailSignUp: false,
  loading: false,
  roles: [],
  professions: []
}

export default state
