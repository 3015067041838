import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Card, Checkbox, Col, Table, theme } from 'antd'
import dayjs from 'dayjs'
import { hexToRGBA } from '../../../lib/utils'

const CarePlanReview = ({ userRef }) => {
  const medicalProfessionals = [
    {
      name: 'Endocrinologist',
      compliant: true,
      dateConfirmedCompliant: dayjs().subtract(1, 'day').toISOString() // Example date
    },
    {
      name: 'Primary Care Physician',
      compliant: true,
      dateConfirmedCompliant: dayjs().subtract(2, 'days').toISOString() // Example date
    },
    {
      name: 'Dietitian/Nutritionist',
      compliant: false,
      dateConfirmedCompliant: null // Example of no date
    },
    {
      name: 'Certified Diabetes Educator',
      compliant: true,
      dateConfirmedCompliant: dayjs().subtract(5, 'days').toISOString() // Example date
    },
    {
      name: 'Podiatrist',
      compliant: false,
      dateConfirmedCompliant: null // Example of no date
    },
    {
      name: 'Ophthalmologist',
      compliant: false,
      dateConfirmedCompliant: null
    },
    {
      name: 'Cardiologist',
      compliant: false,
      dateConfirmedCompliant: null // Example of no date
    }
  ]

  const columns = [
    {
      title: 'Visit Required',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Compliant',
      dataIndex: 'compliant',
      key: 'compliant',
      render: (text, record) => (
        <>
          <Checkbox checked={record.compliant} />
          <small>
            {record.dateConfirmedCompliant ? (
              <span> - {dayjs(record.dateConfirmedCompliant).format('DD MMM YYYY')}</span>
            ) : (
              ''
            )}
          </small>
        </>
      )
    }
  ]

  const vitalsData = [
    {
      date: dayjs().subtract(1, 'day').format('DD MMM YYYY'),
      bloodGlucose: '120 mg/dL',
      bloodPressure: '130/85 mmHg',
      weight: '72 kg',
      hba1c: '6.5%'
    },
    {
      date: dayjs().subtract(2, 'days').format('DD MMM YYYY'),
      bloodGlucose: '115 mg/dL',
      bloodPressure: '125/80 mmHg',
      weight: '71 kg',
      hba1c: '6.4%'
    },
    {
      date: dayjs().subtract(7, 'days').format('DD MMM YYYY'),
      bloodGlucose: '130 mg/dL',
      bloodPressure: '135/90 mmHg',
      weight: '73 kg',
      hba1c: '6.6%'
    },
    {
      date: dayjs().subtract(10, 'days').format('DD MMM YYYY'),
      bloodGlucose: '140 mg/dL',
      bloodPressure: '140/95 mmHg',
      weight: '74 kg',
      hba1c: '6.7%'
    }
  ]

  const vitalsColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Blood Glucose',
      dataIndex: 'bloodGlucose',
      key: 'bloodGlucose'
    },
    {
      title: 'Blood Pressure',
      dataIndex: 'bloodPressure',
      key: 'bloodPressure'
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight'
    },
    {
      title: 'HbA1c',
      dataIndex: 'hba1c',
      key: 'hba1c'
    }
  ]

  const { token } = theme.useToken()
  return (
    <CustomRow>
      <Col span={24}>
        <Card
          size='small'
          title={<h2>DIABETES</h2>}
          headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
          bodyStyle={{ padding: 0 }}
        >
          <Card size='small' title={<h2>VISITS</h2>} headStyle={{ background: '#f5f5f5' }} bodyStyle={{ padding: 0 }}>
            {<Table pagination={false} size='small' bordered dataSource={medicalProfessionals} columns={columns} />}
          </Card>
          <Card size='small' title={<h2>TRACKERS</h2>} headStyle={{ background: '#f5f5f5' }} bodyStyle={{ padding: 0 }}>
            {<Table pagination={false} size='small' bordered dataSource={vitalsData} columns={vitalsColumns} />}
          </Card>
        </Card>
      </Col>
    </CustomRow>
  )
}

export default CarePlanReview
