import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { Button, DatePicker, Radio, Select, Space, Tooltip, message, theme } from 'antd'
import { diaryViewOptions } from './full-calendar-utils/full-calendar-templates'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faRefresh } from '@fortawesome/free-solid-svg-icons'
import DayHeader from './full-calendar-components/DayHeader'
import WeekHeader from './full-calendar-components/WeekHeadert'
import MonthHeader from './full-calendar-components/MonthHeader'
import { FullCalendarEnums } from './full-calendar-utils/full-calendar-enums'
import { handleError, hexToRGBA } from '../lib/utils'
import { isAdmin, isDoctor, isNurse, isReception, userClinics } from '../lib/profile-utils'
import CoreEnums from '../../core/utils/enums'
import { useDispatch, useSelector } from 'react-redux'
import AppointmentsCalendarReducer from './utils/appointments-calendar-reducer'
import { updateAllBookingsState } from '../lib/booking-utils'
import { getAppointments, updateBooking } from '../Bookings/utils/utils'
import { ContainerCard } from '../reusable-components/AgiliteCards'
import './full-calendar-style.css'
import DayEventContent from './full-calendar-components/DayEventContent'
import MonthEventContent from './full-calendar-components/MonthEventContent copy'
import WeekEventContent from './full-calendar-components/WeekEventContent'
import FullCalendarContentModal from './full-calendar-components/FullCalendarContentModal'
import { aggregateAvailability } from '../Availability/components/utils/utils'
import CustomLoadingIcon from '../reusable-components/CustomLoadingIcon'

const EHRCalendar = ({
  medicalProfessionals,
  medicalProfessionalsLoading,
  currentDate,
  setCurrentDate,
  currentView,
  setCurrentView
}) => {
  const state = useSelector((state) => state)
  const calendarRef = useRef(null)
  const clinicsState = useSelector((state) => state.clinics.data)
  const authState = useSelector((state) => state.auth)
  const servicesState = useSelector((state) => state.services.data)
  const virtualServicesState = useSelector((state) => state.virtualServices.data)
  const sideBarExpanded = useSelector((state) => state.core.sideBarExpanded)
  const dispatch = useDispatch()
  const appointmentsCalendarState = useSelector((state) => state.appointmentsCalendar.data)
  const { token } = theme.useToken()
  const [events, setEvents] = useState([]) // Initialize events state with your initial events array
  const [appointmentsLoading, setAppointmentsLoading] = useState(false)
  const [selectedProfessional, setSelectedProfessional] = useState(null)
  const [sortedMedicalProfessionals, setSortedMedicalProfessionals] = useState([])
  const dateFormat = 'YYYY-MM-DD'
  const [modalData, setModalData] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [isListView, setIsListView] = useState(false)

  useEffect(() => {
    // Initializes the function without a medical professional (medprof) assigned. Upon invocation, this function triggers the setting of a medical professional, which leads to its execution again, this time with a medical professional assigned. Consequently, the fetchAppointments() function is called to retrieve appointments associated with the assigned medical professional.
    if (!selectedProfessional) {
      let tmpSelectedProfessional = medicalProfessionals[0]
      if (isDoctor()) {
        setSelectedProfessional(authState.agiliteUser?._id)
      }
      if (isReception() || isAdmin()) {
        setSelectedProfessional(tmpSelectedProfessional?._id)
      }
      if (isNurse()) {
        setSelectedProfessional(tmpSelectedProfessional?._id)
      }
    } else {
      fetchAppointments()
    }
    // eslint-disable-next-line
  }, [selectedProfessional])

  useEffect(() => {
    // Initially, no medical professional (medprof) is set, so it's essential not to call fetchAppointments().
    if (selectedProfessional) {
      fetchAppointments()
    }
    // eslint-disable-next-line
  }, [currentDate, currentView])

  const bookingRequestQueries = () => {
    let tmpQry = {}
    switch (currentView) {
      case FullCalendarEnums.viewTypeKeys.DAY_VIEW:
        tmpQry.bookingDate = { $eq: dayjs(currentDate).format(dateFormat) }
        break
      case FullCalendarEnums.viewTypeKeys.WEEK_VIEW:
        tmpQry.bookingDate = {
          $gte: dayjs(currentDate).format(dateFormat),
          $lt: dayjs(currentDate).add(7, 'day').format(dateFormat)
        }
        break
      case FullCalendarEnums.viewTypeKeys.MONTH_VIEW:
        const lastDayOfPreviousMonth = dayjs(currentDate).subtract(1, 'month').endOf('month')
        // Calculate the first day of the next month
        const firstDayOfNextMonth = dayjs(currentDate).add(1, 'month').startOf('month')

        tmpQry.bookingDate = {
          $gt: lastDayOfPreviousMonth.format(dateFormat),
          $lt: firstDayOfNextMonth.format(dateFormat)
        }
        break
      // case 'agenda':
      //   tmpQry.bookingDate = {
      //     $gte: dayjs().format('YYYY-MMM-DD'),
      //     $lt: dayjs(dayjs().add(1, 'day')).format('YYYY-MMM-DD')
      //   }
      //   break
      default:
      // tmpQry.bookingDate = {
      //   $gte: dayjs(dateRange?.start).format('YYYY-MMM-DD'),
      //   $lt: dayjs(dateRange?.end).add(1, 'day').format('YYYY-MMM-DD')
      // }
    }

    if (isReception()) {
      tmpQry.medicalProfRef = selectedProfessional
    }

    if (isDoctor()) {
      tmpQry.medicalProfRef = selectedProfessional
    }

    if (isNurse()) {
      tmpQry.clinicRef = { $in: userClinics() }
    }
    if (isAdmin()) {
      tmpQry.medicalProfRef = selectedProfessional
    }
    tmpQry.status = { $ne: CoreEnums.bookingStatuses.cancelled }
    return tmpQry
  }

  useEffect(() => {
    handleSetEvents()
    // eslint-disable-next-line
  }, [appointmentsCalendarState, isListView])

  const formatEventTime = (time, date) => {
    const bookingDate = dayjs(date).format('YYYY-MM-DD') // Booking date in YYYY-MM-DD format

    // Combine booking date and start time to create the start date-time value
    const formattedDate = new Date(`${bookingDate}T${time}`)
    // Convert date-time values to ISO 8601 format
    return formattedDate.toISOString()
  }
  const getEventColor = (status, opacity) => {
    if (!status) return hexToRGBA(token.colorError, 0.8)
    switch (status) {
      case CoreEnums.bookingStatuses.checkin:
        return hexToRGBA(token.colorPrimary, opacity)
      case CoreEnums.bookingStatuses.screening:
        return hexToRGBA(token.colorSecondary, opacity)
      case CoreEnums.bookingStatuses.diagnosis:
        return hexToRGBA(token.colorWarning, opacity)
      case CoreEnums.bookingStatuses.procedure:
        return hexToRGBA('#FF69B4', opacity)
      case CoreEnums.bookingStatuses.billing:
        return hexToRGBA(token.colorInfo, opacity)
      case CoreEnums.bookingStatuses.completed:
        return hexToRGBA(token.colorSuccess, opacity)
    }
  }
  const handleSetEvents = () => {
    let tmpEvents = []
    const targetMedProf = sortedMedicalProfessionals.find((medProf) => medProf._id === selectedProfessional)
    appointmentsCalendarState.forEach((appointment) => {
      tmpEvents.push({
        id: appointment._id, // Unique identifier for the event
        // Title of the event
        start: formatEventTime(appointment.startTime, appointment.bookingDate), // Start date and time of the event (in ISO 8601 format)
        end: formatEventTime(appointment.endTime, appointment.bookingDate), // End date and time of the event (in ISO 8601 format),
        recordData: appointment,
        color: getEventColor(appointment.status, isListView ? 1 : 0.2)
      })
    })
    if (!isListView && !isNurse()) {
      if (targetMedProf && targetMedProf.availability && targetMedProf.availability.length > 0) {
        targetMedProf.availability.forEach((entry, index) => {
          const validEntries = validateAvailability(entry)
          validEntries.forEach((e) => {
            tmpEvents.push({
              availabilityRecordId: entry.availabilityRecordId,
              availabilityEntryId: entry.availabilityEntryId,
              // entry id
              id: e._id, // Unique identifier for the event
              // Title of the event
              start: formatEventTime(e.time, entry.date), // Start date and time of the event (in ISO 8601 format)
              end: formatEventTime(dayjs(e.time, 'HH:mm').add(15, 'minute').format('HH:mm'), entry.date), // End date and time of the event (in ISO 8601 format),
              color: e.blocked ? hexToRGBA(token.colorError, 0.2) : hexToRGBA('#ffffff', 0.2),
              isAvailabilityEntry: true,
              editable: false,
              blocked: e.blocked,
              blockReason: e.blockReason,
              blockedBy: e.blockedBy,
              unblockedBy: e.unblockedBy
            })
          })
        })
      }
    }
    setEvents(tmpEvents)
  }

  const validateAvailability = (entry) => {
    const availabilityDate = entry.date // YYYY-MM-DD
    const availabilityTimeslots = JSON.parse(JSON.stringify(entry.timeSlots))

    let filteredSlots = availabilityTimeslots.filter((slot) => {
      const isBooked = appointmentsCalendarState.some(
        (booking) =>
          booking.startTime === slot.time &&
          dayjs(booking.bookingDate).format(dateFormat) === availabilityDate &&
          booking.status !== CoreEnums.bookingStatuses.cancelled
      )

      const hasOverlap = appointmentsCalendarState.some((booking) => {
        const bookingStartTime = dayjs(booking.startTime, 'HH:mm')
        const bookingEndTime = dayjs(booking.endTime, 'HH:mm')
        const slotTime = dayjs(slot.time, 'HH:mm')
        return (
          booking.status !== CoreEnums.bookingStatuses.cancelled &&
          dayjs(booking.bookingDate).format(dateFormat) === availabilityDate &&
          dayjs(slotTime).isAfter(bookingStartTime) &&
          dayjs(slotTime).isBefore(bookingEndTime)
        )
      })
      return !isBooked && !hasOverlap
    })
    return filteredSlots
  }

  const fetchAppointments = async () => {
    let tmpmedProfsOnDuty = []
    let tmpMedicalPofessionals = JSON.parse(JSON.stringify(medicalProfessionals))
    let tmpData = []
    let qry = {}
    setAppointmentsLoading(true)

    try {
      tmpmedProfsOnDuty = await aggregateAvailability(avalabilityRequestPipeline())

      // REMOVE AVAILABILITY ENTRIES BEFORE TODAY
      // tmpmedProfsOnDuty = tmpmedProfsOnDuty.filter((entry) => {
      //   const entryDate = dayjs(entry.date) // Parse entry date
      //   return entryDate.isAfter(dayjs(), 'day') || entryDate.isSame(dayjs(), 'day') // Check if entry date is after current date or is same day
      // })

      const availableIds = tmpmedProfsOnDuty.map((item) => item.linkedProfessional)
      const unavailableDoctors = tmpMedicalPofessionals.filter(
        (professional) => !availableIds.includes(professional._id)
      )
      const availableDoctors = tmpMedicalPofessionals.filter((professional) => availableIds.includes(professional._id))

      availableDoctors.forEach((medProf) => {
        medProf.onDuty = true
        medProf.availability = tmpmedProfsOnDuty.filter((i) => i.linkedProfessional === medProf._id)
      })

      const tmpSortedProfessionals = [...availableDoctors, ...unavailableDoctors]

      setSortedMedicalProfessionals(tmpSortedProfessionals)

      qry = { ...bookingRequestQueries() }

      tmpData = await getAppointments(state, qry)
      dispatch(AppointmentsCalendarReducer.actions.setRecords(tmpData))
      // Set events
    } catch (e) {
      message.error(handleError(e))
    }
    setAppointmentsLoading(false)
  }

  const handleUpdateEvent = async (info) => {
    // Track the previous event state for potential rollback
    // let recordData = info.event.extendedProps.recordData
    let prevEventsState = JSON.parse(JSON.stringify(events))
    let newStart = info.event.start
    let newEnd = info.event.end
    let eventId = info.event.id
    let updatedEvent = null

    setAppointmentsLoading(true)
    try {
      // Update the event state to trigger rerendering in the calendar,
      // ensuring it recognizes the change in state. Without this step,
      // reverting to the previous events state might not trigger a rerender.
      const updatedEvents = events.map((event) => {
        // Update the dragged event
        if (event.id === info.event.id) {
          return {
            ...event,
            start: newStart,
            end: newEnd // Adjust end time if needed
          }
        }
        // Return the unchanged event
        return event
      })
      setEvents(updatedEvents)
      let payload = {
        bookingDate: dayjs(newStart).format(dateFormat),
        startTime: dayjs(newStart).format('HH:mm'),
        endTime: dayjs(newEnd).format('HH:mm')
      }
      updatedEvent = await updateBooking(payload, { _id: eventId })
      updateAllBookingsState(updatedEvent)
    } catch (e) {
      // Handle and display any errors
      message.error(handleError(e))
      // Rollback to the previous events state in case of error
      setEvents(prevEventsState)
    }
    setAppointmentsLoading(false)
  }

  useEffect(() => {
    if (modalData) {
      setModalOpen(true)
    } else {
      setModalOpen(false)
    }
  }, [modalData])

  const handleEventClick = (info) => {
    if (currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW) {
      setSelectedProfessional(info.event.extendedProps.medProfId)
      setCurrentView(FullCalendarEnums.viewTypeKeys.DAY_VIEW)
      setCurrentDate(dayjs(info.event.start).toDate())
    } else {
      setModalData(info.event)
    }
  }

  const handleDateChange = (action) => {
    let newDate
    if (action === 'today') {
      newDate = dayjs().toDate()
    } else {
      switch (currentView) {
        case FullCalendarEnums.viewTypeKeys.MONTH_VIEW:
          newDate =
            action === 'next'
              ? dayjs(currentDate).add(1, 'month').toDate()
              : dayjs(currentDate).subtract(1, 'month').toDate()
          break
        case FullCalendarEnums.viewTypeKeys.WEEK_VIEW:
          newDate =
            action === 'next'
              ? dayjs(currentDate).add(1, 'week').toDate()
              : dayjs(currentDate).subtract(1, 'week').toDate()
          break
        default:
          newDate =
            action === 'next'
              ? dayjs(currentDate).add(1, 'day').toDate()
              : dayjs(currentDate).subtract(1, 'day').toDate()
          break
      }
    }
    setCurrentDate(newDate)
  }

  const DayHeaderContent = (data) => {
    switch (currentView) {
      case FullCalendarEnums.viewTypeKeys.MONTH_VIEW:
        return <MonthHeader {...data} isListView={isListView} />
      case FullCalendarEnums.viewTypeKeys.WEEK_VIEW:
        return <WeekHeader {...data} setCurrentDate={setCurrentDate} setCurrentView={setCurrentView} />
      default:
        return <DayHeader {...data} />
    }
  }
  const renderEventContent = (eventInfo) => {
    if (eventInfo.event?.extendedProps?.isAvailabilityEntry)
      return (
        <Space direction='vertical' style={{ gap: 0 }}>
          <p style={{ fontSize: 12 }}>
            {eventInfo.event?.extendedProps?.blocked ? (
              <>
                Blocked by <b>{eventInfo.event?.extendedProps?.blockedBy}</b>
              </>
            ) : (
              <>
                Available{' '}
                {eventInfo.event?.extendedProps?.blockReason
                  ? `- previously blocked (Unblocked by ${
                      eventInfo.event?.extendedProps?.unblockedBy ? eventInfo.event?.extendedProps?.unblockedBy : 'n/a'
                    })`
                  : ''}
              </>
            )}
          </p>
          {eventInfo.event?.extendedProps?.blockReason && currentView === FullCalendarEnums.viewTypeKeys.DAY_VIEW ? (
            <p style={{ fontSize: 12 }}>
              <b>Reason:</b> {eventInfo.event?.extendedProps?.blockReason}
            </p>
          ) : undefined}
        </Space>
      )
    const recordData = eventInfo.event?.extendedProps?.recordData
    if (recordData) {
      const patientRecord = recordData?.patientRecord
      const service = virtualServicesState.find((serv) => serv._id === recordData?.service)
        ? { ...virtualServicesState.find((serv) => serv._id === recordData?.service), isVirtual: true }
        : undefined || servicesState.find((serv) => serv._id === recordData?.service)

      if (isListView)
        return (
          <DayEventContent
            patientRecord={patientRecord}
            recordData={recordData}
            service={service}
            isListView={isListView}
          />
        )
      switch (currentView) {
        case FullCalendarEnums.viewTypeKeys.WEEK_VIEW:
          return <WeekEventContent patientRecord={patientRecord} recordData={recordData} service={service} />
        case FullCalendarEnums.viewTypeKeys.MONTH_VIEW:
          return <MonthEventContent patientRecord={patientRecord} recordData={recordData} service={service} />
        default:
          return <DayEventContent patientRecord={patientRecord} recordData={recordData} service={service} />
      }
    }
  }
  const handleSlotLabelContent = (arg) => {
    // Customizing time slot label content and style
    return <div style={{ height: '50px', lineHeight: '50px' }}>{arg.text}</div>
  }

  const handleEventMouseEnter = (info) => {
    if (info.event?.extendedProps?.isAvailabilityEntry) {
      if (info.event?.extendedProps?.blocked) {
        info.el.style.backgroundColor = hexToRGBA(token.colorError, 0.4)
      } else {
        info.el.style.backgroundColor = hexToRGBA(token.colorPrimary, 0.4)
      }
    } else {
      if (!isListView) {
        if (currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW) {
          if (isDoctor()) {
            info.el.style.backgroundColor = hexToRGBA(token.colorSuccess, 0.4)
          } else {
            info.el.style.backgroundColor = hexToRGBA(token.colorPrimary, 0.4)
          }
        } else {
          info.el.style.backgroundColor = getEventColor(info.event.extendedProps.recordData.status, 0.4)
        }
      }
    }
    // Add more style changes as needed
  }

  const handleEventMouseLeave = (info) => {
    if (info.event?.extendedProps?.isAvailabilityEntry) {
      if (info.event?.extendedProps?.blocked) {
        info.el.style.backgroundColor = hexToRGBA(token.colorError, 0.2)
      } else {
        info.el.style.backgroundColor = hexToRGBA('#ffffff', 0.2)
      }
    } else {
      if (!isListView) {
        if (currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW) {
          if (isDoctor()) {
            info.el.style.backgroundColor = hexToRGBA(token.colorSuccess, 0.2)
          } else {
            info.el.style.backgroundColor = hexToRGBA(token.colorPrimary, 0.2)
          }
        } else {
          info.el.style.backgroundColor = getEventColor(info.event.extendedProps.recordData.status, 0.2)
        }
      }
    }
  }

  const handleEmptySlotClick = (info) => {
    setModalData(info)
    // Perform any desired action when an empty slot is clicked
  }

  const avalabilityRequestPipeline = () => {
    let pielineDateQuery = {}

    // FETCH AVAILABILITY
    switch (currentView) {
      case FullCalendarEnums.viewTypeKeys.WEEK_VIEW:
        pielineDateQuery = {
          $gte: dayjs(currentDate).format(dateFormat),
          $lt: dayjs(currentDate).add(7, 'day').format(dateFormat)
        }
        break
      case FullCalendarEnums.viewTypeKeys.DAY_VIEW:
        pielineDateQuery = {
          $gte: dayjs(currentDate).format(dateFormat),
          $lt: dayjs(currentDate).add(1, 'day').format(dateFormat)
        }
        break
      case FullCalendarEnums.viewTypeKeys.MONTH_VIEW:
        const lastDayOfPreviousMonth = dayjs(currentDate).subtract(1, 'month').endOf('month')
        // Calculate the first day of the next month
        const firstDayOfNextMonth = dayjs(currentDate).add(1, 'month').startOf('month')

        pielineDateQuery = {
          $gt: lastDayOfPreviousMonth.format(dateFormat),
          $lt: firstDayOfNextMonth.format(dateFormat)
        }
        break
      default:
        return
    }
    return [
      {
        $match: {
          clinicRef: { $in: isAdmin() ? clinicsState.map((clinic) => clinic._id) : userClinics() }
        }
      },
      {
        $unwind: '$availability'
      },
      {
        $unwind: '$availability.timeSlots'
      },
      {
        $match: {
          'availability.date': pielineDateQuery
        }
      },
      {
        $group: {
          availabilityRecordId: { $first: '$_id' },
          availabilityEntryId: { $first: '$availability._id' },
          _id: {
            date: '$availability.date',
            linkedProfessional: '$availability.timeSlots.linkedProfessional'
          },
          clinicRef: { $first: '$clinicRef' },
          profession: { $first: '$profession' },
          timeSlots: {
            $push: {
              _id: '$availability.timeSlots._id', // Include the _id of the time slot
              time: '$availability.timeSlots.time',
              blocked: '$availability.timeSlots.blocked',
              blockReason: '$availability.timeSlots.blockReason',
              blockedBy: '$availability.timeSlots.blockedBy',
              unblockedBy: '$availability.timeSlots.unblockedBy'
            }
          }
        }
      },
      {
        $project: {
          availabilityRecordId: '$availabilityRecordId',
          availabilityEntryId: '$availabilityEntryId',
          clinicRef: 1,
          profession: 1,
          linkedProfessional: '$_id.linkedProfessional',
          date: '$_id.date',
          timeSlotId: '$_id.id',
          timeSlots: 1
        }
      }
    ]
  }

  const extendedProps =
    currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW
      ? {
          eventContent: (info) => {
            return <b style={{ fontSize: 12 }}>{info.event.title}</b>
          }
        }
      : {
          dayCellContent: renderEventContent,
          eventContent: renderEventContent
        }

  const getMonthViewEvents = () => {
    const tmpEvents = []
    if (isDoctor()) {
      sortedMedicalProfessionals
        .filter((i) => i.onDuty && i._id === selectedProfessional)
        .forEach((medProf) => {
          medProf.availability.forEach((i) => {
            tmpEvents.push({
              title: 'AVAILABLE',
              start: i.date,
              end: i.date,
              color: hexToRGBA(token.colorSuccess, 0.2),
              medProfId: authState.agiliteUser._id
            })
          })
        })
    } else {
      sortedMedicalProfessionals
        .filter((i) => i.onDuty)
        .forEach((medProf) => {
          medProf.availability.forEach((i) => {
            tmpEvents.push({
              title: `DR. ${medProf.firstName.charAt(0)} ${medProf.lastName}`.toUpperCase(),
              start: i.date,
              end: i.date,
              color: hexToRGBA(token.colorPrimary, 0.2),
              medProfId: medProf._id
            })
          })
        })
    }

    return tmpEvents
  }

  const getCurrentListView = () => {
    switch (currentView) {
      case FullCalendarEnums.viewTypeKeys.DAY_VIEW:
        return 'listDay'
      case FullCalendarEnums.viewTypeKeys.WEEK_VIEW:
        return 'listWeek'
      default:
        return FullCalendarEnums.viewTypeKeys.MONTH_VIEW
    }
  }

  return (
    <ContainerCard title='Calendar' bodyStyle={{ paddingTop: 0 }}>
      <Space style={{ padding: 8 }}>
        <Button
          type='primary'
          onClick={() => {
            setModalData({ date: dayjs().toString(), dateStr: dayjs().toString() })
          }}
        >
          New Appointment
        </Button>
        <Button
          style={
            dayjs(currentDate).format(dateFormat) === dayjs().format(dateFormat)
              ? { background: token.colorSecondary, color: 'white' }
              : {}
          }
          onClick={() => {
            handleDateChange('today')
          }}
        >
          Today
        </Button>
        <Radio.Group
          onChange={(e) => {
            setCurrentView(e.target.value)
          }}
          buttonStyle='solid'
          value={currentView}
        >
          {diaryViewOptions.map((t) => {
            return <Radio.Button value={t.value}>{t.label.charAt(0)?.toUpperCase()}</Radio.Button>
          })}
        </Radio.Group>
        {currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW || isDoctor() ? undefined : (
          <Select
            optionFilterProp='search'
            showSearch
            value={selectedProfessional}
            onSelect={(value) => {
              setSelectedProfessional(value)
            }}
            options={sortedMedicalProfessionals.map((medProf) => {
              return {
                search: `${medProf.firstName} ${medProf.lastName} 
              `,
                label: (
                  <>
                    {`${medProf.extraData.profession === 'doctor' ? 'Dr. ' : ''}${medProf.firstName
                      .charAt(0)
                      .toUpperCase()}. ${medProf.lastName} 
            `}{' '}
                    {medProf.onDuty ? <sup style={{ color: token.colorSuccess, fontSize: 8 }}>ON DUTY</sup> : undefined}
                  </>
                ),
                value: medProf._id
              }
            })}
            style={{ width: 250 }}
          />
        )}
        <Space style={{ gap: 0 }}>
          {/* Previous button */}
          <Button
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onClick={() => {
              handleDateChange('prev')
            }}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </Button>
          {/* Date picker */}
          <DatePicker
            onChange={(date) => {
              setCurrentDate(new Date(date))
            }}
            allowClear={false}
            picker={'date'}
            value={dayjs(currentDate)}
            style={{ borderRadius: 0, borderRight: 'none', borderLeft: 'none' }}
          />
          {/* Next button */}
          <Button
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => {
              handleDateChange('next')
            }}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </Button>
        </Space>
        {currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW ? undefined : (
          <Button
            onClick={() => setIsListView(!isListView)}
            style={{ background: isListView ? token.colorSecondary : '', color: isListView ? '#fff' : '#000' }}
          >
            List View
          </Button>
        )}
        <Tooltip title='Refresh'>
          <Button onClick={() => fetchAppointments()} style={{ background: token.colorSuccess, color: '#fff' }}>
            <FontAwesomeIcon icon={faRefresh} />
          </Button>
        </Tooltip>
      </Space>
      <CustomLoadingIcon
        loading={appointmentsLoading || medicalProfessionalsLoading}
        content={
          <div>
            <FullCalendar
              slotLabelContent={handleSlotLabelContent}
              displayEventTime={false}
              dateClick={
                currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW
                  ? (info) => {
                      setCurrentView(FullCalendarEnums.viewTypeKeys.DAY_VIEW)
                      setCurrentDate(dayjs(info.date).toDate())
                    }
                  : handleEmptySlotClick
              }
              allDaySlot={false}
              key={currentView + currentDate + isListView + sideBarExpanded}
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              initialView={isListView ? getCurrentListView() : currentView}
              initialDate={currentDate}
              events={currentView === FullCalendarEnums.viewTypeKeys.MONTH_VIEW ? getMonthViewEvents() : events}
              eventTextColor='#000'
              weekends={true}
              eventMouseEnter={handleEventMouseEnter}
              eventMouseLeave={handleEventMouseLeave}
              editable={currentView !== FullCalendarEnums.viewTypeKeys.MONTH_VIEW}
              slotDuration='00:15:00'
              slotLabelInterval='00:15'
              slotMinTime='00:00:00'
              slotMaxTime='24:00:00'
              slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: false,
                hour12: false
              }}
              height={'calc(100vh - 160px)'}
              headerToolbar={false}
              eventDrop={handleUpdateEvent} // Handle event drop
              eventResize={handleUpdateEvent}
              eventClick={handleEventClick}
              // listDayFormat={'DDD'}
              scrollTime={'14:00:00'}
              listDaySideFormat={false}
              firstDay={currentDate.getDay()}
              dayHeaderContent={DayHeaderContent}
              {...extendedProps}
            />
          </div>
        }
      />
      <FullCalendarContentModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        event={modalData}
        setModalData={setModalData}
        selectedProfessional={selectedProfessional}
        refreshFunction={fetchAppointments}
        medicalProfessionals={sortedMedicalProfessionals}
      />
    </ContainerCard>
  )
}

export default EHRCalendar
