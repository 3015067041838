import React, { useEffect, useState } from 'react'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import SystemUsersWrapper from './system-users-wrapper'
import CustomRow from '../../../reusable-components/CustomRow'
import { Col } from 'antd'

const SystemUsersContainer = ({ type }) => {
  const [viewType, setViewType] = useState()
  useEffect(() => {
    switch (type) {
      case 'system':
        setViewType('System User')
        break
      case 'medical':
        setViewType('Medical Professional')
        break
      case 'patient':
        setViewType('Patient')
        break
      default:
        setViewType('System User')
    }
    // eslint-disable-next-line
  }, [])
  return (
    <ContainerCard title={viewType + 's'}>
      <CustomRow>
        <Col span={24}>
          <SystemUsersWrapper viewType={viewType} type={type} />
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default SystemUsersContainer
