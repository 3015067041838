import React, { memo, useEffect, useState } from 'react'
import { Col, Button, Table, Empty, Space } from 'antd'

import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import Templates from '../utils/templates'
import { uniqBy, sortBy } from 'lodash'
import CustomButton from '../../../reusable-components/CustomButton'
import CustomRow from '../../../reusable-components/CustomRow'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'

const _ListView = ({ data, refreshView, createRecord, editRecord, loading }) => {
  const coreState = useSelector((state) => state.core)
  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    serviceType: []
  })

  useEffect(() => {
    generateFilters()
    // eslint-disable-next-line
  }, [data])

  const generateFilters = () => {
    const tmpFilters = {
      serviceType: []
    }

    data.forEach((item) => {
      tmpFilters.serviceType.push({ text: item.type, value: item.type })
    })

    tmpFilters.type = uniqBy(tmpFilters.type, 'text')

    setFilters(tmpFilters)
  }
  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <Space wrap>
              <CustomButton
                size='small'
                text='Refresh'
                icon={faRefresh}
                onClick={() => {
                  refreshView()
                }}
                type='primary'
                disabled={loading}
              />
              <Col>
                <Button
                  id='service_add_btn'
                  onClick={() => {
                    createRecord()

                    if (coreState.tourOpen) {
                      dispatch(coreReducer.actions.nextTourStep())
                    }
                  }}
                  disabled={loading}
                  type='primary'
                >
                  Add Service
                </Button>
              </Col>
            </Space>
          </Col>
          <Col span={24}>
            <Table
              id='services_list_view'
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={sortBy(data, 'name')}
              pagination={{ defaultPageSize: 10, showSizeChanger: true }}
              scroll={{ x: 950 }}
              columns={Templates.columnTemplate(filters)}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => editRecord(record)
                }
              }}
              locale={{ emptyText: <Empty description='There are currently no services to show.' /> }}
            />
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

const ListView = memo(_ListView)

export default ListView
