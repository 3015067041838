import { Col, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleError } from '../../lib/utils'
import { createSubscription, getSubscriptions } from '../../Subscriptions/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import SubscriptionsReducer from '../../Subscriptions/utils/reducer'

const SubscriptionSuccess = ({ netCashTransaction, subscription, isStaffVitalLink }) => {
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    handleCreateSubscription()
    // eslint-disable-next-line
  }, [])

  const handleCreateSubscription = async () => {
    let tmpSubscriptions = []
    setLoading(true)

    try {
      await createSubscription({
        isActive: true,
        key: 'vitalLink',
        name: 'Meta-Link',
        description: 'Meta-Link Subscription',
        userRef: authState.agiliteUser._id,
        startDate: subscription.startDate,
        endDate: subscription.endDate,
        extraData: {
          ...subscription.extraData,
          transactionRef: netCashTransaction._id
        },
        isStaff: isStaffVitalLink,
        entityRef: coreState.entity._id,
        verified: false
      })

      tmpSubscriptions = await getSubscriptions({ userRef: authState.agiliteUser._id })
      dispatch(SubscriptionsReducer.actions.setSubscriptions(tmpSubscriptions))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  return (
    <>
      {loading ? (
        <AgiliteSkeleton spinnerTip='Processing Transaction...' skActive={true} />
      ) : (
        <Row justify='center' style={{ marginBottom: 10 }}>
          <Col span={24}>
            <center>
              <b>Successfully Subscribed</b>
            </center>
            <center>
              <small>You have successfully subscribed to Meta-Link</small>
            </center>
          </Col>
        </Row>
      )}
    </>
  )
}

export default SubscriptionSuccess
