import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, Empty, Input, Space, theme } from 'antd'
import { hexToRGBA } from '../../lib/utils'

const SavedMeals = ({
  savedMeals,
  setFacet,
  setSearchQuery,
  searchQuery,
  setAiError,
  setMacroData,
  handleRemoveSavedMeal,
  removing,
  saving
}) => {
  const { token } = theme.useToken()
  return (
    <CustomRow>
      <Col span={24}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 12 }}>
          <Button onClick={() => setFacet(null)}>Back</Button>
          <Input
            placeholder='search'
            onChange={(e) => {
              setSearchQuery(e.target.value)
            }}
          />
        </div>
      </Col>
      {savedMeals
        ?.filter(
          (i) =>
            i?.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            i?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .map((meal) => (
          <Col span={24}>
            <Card
              size='small'
              headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
              title={meal.name}
              bodyStyle={{ paddingBottom: 64 }}
            >
              <Space>
                <div style={{ width: 100 }}>
                  <img style={{ maxWidth: '100%', boxShadow: '0 0 5px 0px #ccc' }} src={meal?.image} alt={meal.name} />
                </div>
                {meal?.description}{' '}
              </Space>
              <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: 12 }}>
                <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                  <Button
                    type='primary'
                    loading={removing}
                    disabled={saving}
                    style={{ background: token.colorError }}
                    onClick={() => {
                      handleRemoveSavedMeal(meal._id)
                    }}
                  >
                    Remove
                  </Button>
                  <Button
                    type='primary'
                    disabled={removing}
                    loading={saving}
                    onClick={() => {
                      setAiError(null)
                      setMacroData(meal)
                      setFacet('')
                    }}
                  >
                    Select
                  </Button>
                </Space>
              </div>
            </Card>
          </Col>
        ))}

      {savedMeals.length === 0 ? (
        <Col span={24}>
          <Card>
            <Empty description='You have no saved meals.' />
          </Card>
        </Col>
      ) : undefined}
    </CustomRow>
  )
}

export default SavedMeals
