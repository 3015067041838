import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import ExaminationDataCapture from './examination-data-capture'

import { Button, Card, Col, Modal, Row, Select, Space, Tooltip, message, theme } from 'antd'
import { readClinicDataPoints } from '../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { handleError, hexToRGBA } from '../../lib/utils'
import { readDataPointTemplates } from '../../Data-Point-Templates/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChain, faChainBroken, faRefresh } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import ExaminationProceduresPerformed from './examination-procedures-performed'
import SymptomsCapture from './symptoms-capture'
import DataPointTableDisplay from './Data Points/data-point-display-table'
import LabResults from './Lab Results/lab-results'
import LinkTop from '../../Linktop/lib'
import LabOrdersWrapper from './Lab Orders/LabOrdersWrapper'

const PatientDataDisaply = ({
  fetchPatientData,
  loadingPatientData,
  patientDataEntries,
  setPatientDataEntries,
  otherDataEntries,
  currentTemplate,
  setCurrentTemplate,
  bookingWebSocket,
  userRef,
  bookingData
}) => {
  // Data
  const [labOrders, setLabOrders] = useState([])
  const [dataPoints, setDataPoints] = useState([])
  const [dataTemplates, setDataTemplates] = useState([])

  // Modal control
  const [captureModalOpen, setCaptureModalOpen] = useState(false)
  const [labReportModalOpen, setLabReportModalOpen] = useState(false)

  const [labOrdertModalOpen, setLabOrderModalOpen] = useState(false)

  // Loading
  const [dataPointsLoading, setDataPointsLoading] = useState(false)
  const [dataTemplatesLoading, setDataTemplatesLoading] = useState(false)

  const { token } = theme.useToken()
  // Local state management

  const [linktopModalOpen, setLinktopModalOpen] = useState(false)

  useEffect(() => {
    if (!currentTemplate) {
      setCurrentTemplate(dataTemplates[0])
    }
    // eslint-disable-next-line
  }, [dataTemplates])

  useEffect(() => {
    fetchPatientData()
    // eslint-disable-next-line
  }, [currentTemplate])

  useEffect(() => {
    if (currentTemplate) {
      fetchDataPoints()
    } else {
      setDataPoints([])
    }
    // eslint-disable-next-line
  }, [currentTemplate])

  useEffect(() => {
    fetchDataPointTemplates()
    // eslint-disable-next-line
  }, [])

  const fetchDataPointTemplates = async () => {
    let tmpData = []
    const qry = {}

    setDataTemplatesLoading(true)
    try {
      qry.templateType = { $ne: 'clinical_report' }
      tmpData = await readDataPointTemplates(qry, true)
      setDataTemplates(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setDataTemplatesLoading(false)
  }

  const fetchDataPoints = async () => {
    setDataPointsLoading(true)

    const qry = {}
    let tmpDataPoints = []
    try {
      if (currentTemplate?.records) {
        qry.key = { $in: currentTemplate?.keys }
      }

      tmpDataPoints = await readClinicDataPoints(qry)
      setDataPoints(tmpDataPoints)
    } catch (e) {
      message.error(handleError(e))
    }

    setDataPointsLoading(false)
  }
  const handleFilterPatientEntries = (type) => {
    let tmpData = [...patientDataEntries]
    tmpData = tmpData.filter((i) => {
      const itemReference = dataPoints.find((i2) => i.key === i2.key)
      if (itemReference) {
        return itemReference.category === type
      } else {
        return false
      }
    })
    return tmpData
  }

  const handleSuccessfulLabOrder = (data) => {
    setLabOrders([...labOrders, data])
  }

  return (
    <CustomRow>
      <>
        <Col span={24}>
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            {bookingData ? <SymptomsCapture bookingData={bookingData} /> : undefined}

            <Button
              disabled={loadingPatientData || dataPointsLoading || dataTemplatesLoading}
              type='primary'
              onClick={() => setLinktopModalOpen(true)}
            >
              Linktop Device <FontAwesomeIcon icon={faChain} />
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Card
            size='small'
            bodyStyle={{ padding: '8px 0px 8px 0' }}
            extra={
              <Space>
                <Tooltip title='Refresh'>
                  <Button onClick={() => fetchPatientData()} type='primary' style={{ background: token.colorSuccess }}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    bookingWebSocket.connected
                      ? 'Connected to Realtime Update Server'
                      : 'Disconnected from Realtime Update Server'
                  }
                >
                  <Space
                    style={{
                      padding: '4px 12px 4px 12px',
                      borderRadius: 8,
                      alignItems: 'centerx',
                      background: hexToRGBA(bookingWebSocket.connected ? token.colorSuccess : token.colorError, 0.2)
                    }}
                  >
                    <small>Real Time Updates </small>
                    <FontAwesomeIcon
                      icon={bookingWebSocket.connected ? faChain : faChainBroken}
                      size='sm'
                      color={bookingWebSocket.connected ? token.colorSuccess : token.colorError}
                      style={{ cursor: 'help' }}
                    />
                  </Space>
                </Tooltip>
              </Space>
            }
            title={
              <Space wrap style={{ justifyContent: 'space-between', width: '100%' }}>
                <Space>
                  <Select
                    showSearch
                    optionFilterProp='label'
                    value={JSON.stringify(currentTemplate)}
                    style={{ width: 450 }}
                    onChange={(template) => {
                      setCurrentTemplate(JSON.parse(template))
                    }}
                    options={dataTemplates.map((template) => ({
                      value: JSON.stringify(template),
                      label: template.name
                    }))}
                  />

                  <Button
                    onClick={() => {
                      setCaptureModalOpen('all')
                    }}
                    type='primary'
                    style={{ background: token.colorSuccess }}
                  >
                    New Capture
                  </Button>
                </Space>
              </Space>
            }
          >
            <Row>
              <Col span={24}>
                {loadingPatientData || dataPointsLoading || dataTemplatesLoading ? (
                  <AgiliteSkeleton />
                ) : (
                  <CustomRow>
                    <>
                      <Col span={24}>
                        <DataPointTableDisplay
                          type='general'
                          dataPoints={dataPoints.filter((i) => i.category === 'general')}
                          fetchPatientData={fetchPatientData}
                          otherDataEntries={otherDataEntries}
                          patientDataEntries={handleFilterPatientEntries('general')}
                          setPatientDataEntries={setPatientDataEntries}
                          setCaptureModalOpen={setCaptureModalOpen}
                        />
                      </Col>
                      <Col span={24}>
                        <DataPointTableDisplay
                          type='vitals'
                          dataPoints={dataPoints.filter((i) => i.category === 'vitals')}
                          fetchPatientData={fetchPatientData}
                          otherDataEntries={otherDataEntries}
                          patientDataEntries={handleFilterPatientEntries('vitals')}
                          setPatientDataEntries={setPatientDataEntries}
                          setCaptureModalOpen={setCaptureModalOpen}
                        />
                      </Col>
                      <Col span={24}>
                        <DataPointTableDisplay
                          type='rapid'
                          dataPoints={dataPoints.filter((i) => i.category === 'rapid')}
                          fetchPatientData={fetchPatientData}
                          otherDataEntries={otherDataEntries}
                          patientDataEntries={handleFilterPatientEntries('rapid')}
                          setPatientDataEntries={setPatientDataEntries}
                          setCaptureModalOpen={setCaptureModalOpen}
                        />
                      </Col>
                      <Col span={24}>
                        <DataPointTableDisplay
                          setLabReportModalOpen={setLabReportModalOpen}
                          type='poc'
                          tmpEntriesForDemo={patientDataEntries}
                          dataPoints={dataPoints.filter((i) => i.category === 'poc')}
                          fetchPatientData={fetchPatientData}
                          otherDataEntries={otherDataEntries}
                          patientDataEntries={handleFilterPatientEntries('poc')}
                          setPatientDataEntries={setPatientDataEntries}
                          setCaptureModalOpen={setCaptureModalOpen}
                        />
                      </Col>
                      <Col span={24}>
                        <DataPointTableDisplay
                          type='lab'
                          labOrders={labOrders}
                          dataPoints={dataPoints.filter((i) => i.category === 'lab')}
                          fetchPatientData={fetchPatientData}
                          otherDataEntries={otherDataEntries}
                          patientDataEntries={handleFilterPatientEntries('lab')}
                          setPatientDataEntries={setPatientDataEntries}
                          setCaptureModalOpen={setCaptureModalOpen}
                          setLabOrderModalOpen={setLabOrderModalOpen}
                        />
                      </Col>
                      {bookingData ? (
                        <Col span={24}>
                          <ExaminationProceduresPerformed bookingData={bookingData} userRef={userRef} />
                        </Col>
                      ) : undefined}
                    </>
                  </CustomRow>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </>

      <ExaminationDataCapture
        socket={null}
        modalOpen={captureModalOpen}
        setModalOpen={setCaptureModalOpen}
        dataTemplates={dataTemplates}
        bookingWebSocket={bookingWebSocket}
        bookingData={bookingData}
        userRef={userRef}
        handleRefresh={fetchPatientData}
      />
      <Modal width={1920} closable={false} maskClosable={false} footer={false} open={labReportModalOpen}>
        <LabResults
          setModalOpen={setLabReportModalOpen}
          userRef={bookingData ? bookingData.userRef : userRef}
          patientDataEntries={patientDataEntries}
          dataPoints={dataPoints}
          otherDataEntries={otherDataEntries}
          socket={null}
          modalOpen={labReportModalOpen}
          dataTemplates={dataTemplates}
          bookingWebSocket={bookingWebSocket}
          bookingData={bookingData}
          handleRefresh={fetchPatientData}
        />
      </Modal>
      <Modal width={1920} open={linktopModalOpen} closable={false} maskClosable={false} footer={false} destroyOnClose>
        <LinkTop
          userRef={bookingData ? bookingData.userRef : userRef}
          bookingRef={bookingData ? bookingData._id : ''}
          setModalOpen={setLinktopModalOpen}
        />
      </Modal>
      <Modal width={1920} closable={false} maskClosable={false} footer={false} open={labOrdertModalOpen}>
        <LabOrdersWrapper onAfterSuccess={handleSuccessfulLabOrder} setModalOpen={setLabOrderModalOpen} />
      </Modal>
    </CustomRow>
  )
}

export default PatientDataDisaply
