import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Card, Col, DatePicker, Input, message, Space, Table, theme } from 'antd'
import { handleError, hexToRGBA } from '../../../lib/utils'
import { alreadyEnrolled, enrollPatient } from '../../utils/utils'
import SystemUsersTemplates from '../../../Admin/system-users/utils/templates'
import debounce from 'lodash/debounce'
import { readSystemUsers } from '../../../Admin/system-users/utils/utils'
import TeamSearch from './TeamSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { DataComplianceTableTemplates } from './utils/DataComplianceTableTemplates'

const EnrollmentSearch = ({ template, setModalOpen, onAfterSuccess, isMonitoring }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [patient, setPatient] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [enrolling, setEnrolling] = useState(false)
  const [teamMembers, setTeamMembers] = useState([])
  const [searching, setSearching] = useState(false)
  const [enrollmentOverride, setEnrollmentOverride] = useState(dayjs())

  useEffect(() => {
    handlePatientSearch()
    // eslint-disable-next-line
  }, [searchQuery])

  const handleReset = () => {
    setPatient(null)
    setSearchQuery('')
    setSearchResults([])
    setModalOpen(false)
  }

  const handleEnrollPatient = async () => {
    let tmpResponse = null

    setEnrolling(true)
    try {
      const enrolled = await alreadyEnrolled(patient._id, template._id)
      if (enrolled) {
        return message.error('Patient already enrolled.')
      }
      tmpResponse = await enrollPatient(patient, teamMembers, template, enrollmentOverride)
      onAfterSuccess({ ...tmpResponse, patientProfile: patient })
      handleReset()
    } catch (error) {
      message.error(handleError(error))
    }
    setEnrolling(false)
  }
  const handlePatientSearch = async () => {
    try {
      let tmpUsers = []
      let qry = { 'extraData.role.type': 'patient' }
      setSearching(true)
      try {
        if (searchQuery) {
          const searchTerms = searchQuery.trim().toLowerCase().split(/\s+/)

          // Prepare regex patterns for partial matching
          const regexPatterns = searchTerms.map((term) => new RegExp(term, 'i'))
          qry = {
            ...qry,
            $or: [
              // Match against concatenated firstName and lastName with partial regexes
              {
                $expr: {
                  $regexMatch: {
                    input: {
                      $concat: [{ $toLower: '$firstName' }, ' ', { $toLower: '$lastName' }]
                    },
                    regex: searchTerms.map((term) => `.*${term}.*`).join(''),
                    options: 'i'
                  }
                }
              },
              // Match individually against firstName and lastName with partial regexes
              {
                $or: [
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$firstName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  },
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$lastName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  }
                ]
              },

              { phoneNumber: { $regex: searchQuery, $options: 'i' } },
              { email: { $regex: searchQuery, $options: 'i' } },
              { physicalAddress: { $regex: searchQuery, $options: 'i' } },
              { idNo: { $regex: searchQuery, $options: 'i' } }
            ]
          }
          tmpUsers = await readSystemUsers(qry)
        }
        setSearchResults(tmpUsers)
      } catch (e) {
        message.error(handleError(e))
      }
      setSearching(false)
    } catch (e) {}
  }

  const handlePatientQuery = (query) => {
    setSearching(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )
  const handleTeamMemberSelection = (newTeamMeber) => {
    let tmpTeamMembers = [...teamMembers, newTeamMeber]
    setTeamMembers(tmpTeamMembers)
  }

  const getComplianceEntries = (type) => {
    let tmpEntries = []
    template.tracking
      .filter((i) => i.trackerType === type)
      .forEach((tracker) => {
        let dueDate = null
        for (let i = 0; i < tracker.count; i++) {
          if (dueDate) {
            dueDate = dayjs(dueDate).add(tracker.intervalDays, 'days').format('YYYY MM DD')
          } else {
            dueDate = dayjs(enrollmentOverride).add(tracker.intervalDays, 'days').format('YYYY MM DD')
          }
          tmpEntries.push({
            trackerType: tracker.trackerType,
            trackerLabel: tracker.dataLabel,
            count: tracker.count,
            key: tracker.dataKey, // Corresponds to 'dataKey'
            dueDate: dueDate,
            type: tracker.trackerType, // Corresponds to 'trackerType'
            value: false, // Initially set to false (not compliant yet)
            lastUpdated: new Date(), // Set the current date as lastUpdated
            notes: '' // Empty notes field for now
          })
        }
      })
    return tmpEntries
  }

  const { token } = theme.useToken()
  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA(token.colorPrimary, 0.2),
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          top: 0,
          left: 0
        }}
      >
        <h2>NEW ENROLLMENT</h2>
        <Button style={{ fontSize: 24, background: 'transparent', border: 'none' }}>
          <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
        </Button>
      </div>
      <CustomRow style={{ marginTop: 48 }}>
        {!patient ? (
          <>
            <Col span={24}>
              Search:{' '}
              <Input
                placeholder='Patient Name or ID...'
                onChange={(e) => handlePatientQuery(e.target.value)}
                style={{ width: 350, maxWidth: '100%' }}
              />
            </Col>
            <Col span={24}>
              <Table
                size='small'
                bordered
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      setSearchQuery('')
                      setPatient(record)
                    }
                  }
                }}
                loading={searching}
                dataSource={searchResults}
                columns={SystemUsersTemplates.columnTemplate([], 'patient', [], token, null, null, true).filter(
                  (i) => !i.hidden
                )}
              />
            </Col>
          </>
        ) : (
          <>
            {isMonitoring ? (
              <Col>
                <Card size='small' title='Team Members'>
                  {teamMembers.map((teamMember) => (
                    <>
                      {`${teamMember?.firstName} ${teamMember?.lastName} | ${teamMember?.extraData?.profession}`} <br />
                    </>
                  ))}
                </Card>
                <TeamSearch setProfile={handleTeamMemberSelection} />
              </Col>
            ) : (
              <>
                <Col span={24}>
                  You are about to enroll {patient.firstName} {patient.lastName} into the{' '}
                  <span style={{ color: token.colorPrimary }}>{template.name}</span> care plan.{' '}
                </Col>
                <Col span={24}>
                  The enrollment date will be:{' '}
                  <DatePicker
                    allowClear={false}
                    onChange={(date) => setEnrollmentOverride(date)}
                    value={enrollmentOverride}
                  />
                </Col>
                <Col span={12}>
                  <h2 style={{ marginBottom: 12 }}>Test Compliance</h2>
                  <Table
                    pagination={false}
                    size='small'
                    bordered
                    columns={DataComplianceTableTemplates(token, true).columnTemplate.filter((i) => !i.hidden)}
                    dataSource={getComplianceEntries('data')}
                  />
                </Col>
                <Col span={12}>
                  <h2 style={{ marginBottom: 12 }}>Visit Compliance</h2>
                  <Table
                    pagination={false}
                    size='small'
                    bordered
                    columns={DataComplianceTableTemplates(token, true).columnTemplate.filter((i) => !i.hidden)}
                    dataSource={getComplianceEntries('visits')}
                  />
                </Col>
              </>
            )}
          </>
        )}

        <Col span={24}>
          <Space style={{ float: 'right' }}>
            <Button
              disabled={enrolling || searching}
              onClick={() => {
                if (patient) {
                  setPatient(null)
                } else {
                  handleReset()
                }
              }}
            >
              Cancel
            </Button>
            <Button
              loading={enrolling || searching}
              type='primary'
              style={{ background: token.colorSuccess }}
              onClick={() => {
                handleEnrollPatient()
              }}
            >
              Enroll Patient
            </Button>
          </Space>
        </Col>
      </CustomRow>
    </>
  )
}

export default EnrollmentSearch
