const ServicesEnums = {
  keys: {
    STANDARD_CONSULT: 'standardConsult',
    VITALITY_HEALTH_CHECK: 'vitalityHealthCheck'
  },
  labels: {
    STANDARD_CONSULT: 'Standard Consult',
    VITALITY_HEALTH_CHECK: 'Vitality Health Check'
  },
  titles: {},
  profileKeys: {
    ADMINISTRATION_SERVICES: 'services'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update'
  }
}

export default ServicesEnums
