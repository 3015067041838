import { Popconfirm, Row, Space, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import { handleError } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophone,
  faMicrophoneSlash,
  faMinimize,
  faPhone,
  faVideoCamera,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons'

export const Controls = ({ tracks, setInCall, useClient, handleVideoMinimized, patient, setLeftCall }) => {
  const client = useClient()
  const [trackState, setTrackState] = useState({ video: true, audio: true })

  const mute = async (type) => {
    try {
      if (type === 'audio') {
        await tracks[0].setEnabled(!trackState.audio)

        setTrackState((ps) => {
          return { ...ps, audio: !ps.audio }
        })
      } else if (type === 'video') {
        await tracks[1].setEnabled(!trackState.video)

        setTrackState((ps) => {
          return { ...ps, video: !ps.video }
        })
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const leaveChannel = async () => {
    try {
      await client.leave()

      client.removeAllListeners()
      tracks[0].close()
      tracks[1].close()

      if (setLeftCall) {
        setLeftCall(true)
      }
      setInCall(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <Row>
      <Space
        style={{
          width: '100%',
          height: 100,
          background: '#10B2E1',
          justifyContent: 'center',
          borderRadius: '0 0 20px 20px',
          overflow: 'hidden'
        }}
      >
        <div
          onClick={() => mute('audio')}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.30)',
            borderRadius: '50%',
            width: 50,
            height: 50,
            padding: 15,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {trackState.audio ? (
            <FontAwesomeIcon icon={faMicrophone} style={{ color: 'white' }} />
          ) : (
            <FontAwesomeIcon icon={faMicrophoneSlash} style={{ color: 'white' }} />
          )}
        </div>
        <Popconfirm
          title='Confirmation'
          description='Are you sure you want to Leave the Virtual Consultation?'
          okText='Yes'
          cancelText='No'
          onConfirm={() => leaveChannel()}
          okButtonProps={{ danger: true }}
        >
          <div
            danger
            style={{
              padding: 15,
              height: 50,
              width: 50,
              borderRadius: '50%',
              border: '2px solid white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#F14F8B',
              cursor: 'pointer'
            }}
          >
            <FontAwesomeIcon icon={faPhone} style={{ transform: 'rotate(135deg)', fontSize: 20, color: 'white' }} />
          </div>
        </Popconfirm>
        <div
          onClick={() => mute('video')}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.30)',
            borderRadius: '50%',
            width: 50,
            height: 50,
            padding: 15,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {trackState.video ? (
            <FontAwesomeIcon icon={faVideoCamera} style={{ color: 'white' }} />
          ) : (
            <FontAwesomeIcon icon={faVideoSlash} style={{ color: 'white' }} />
          )}
        </div>
        {!patient ? (
          <Tooltip title='Minimize'>
            <div
              onClick={() => handleVideoMinimized(true)}
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.30)',
                borderRadius: '50%',
                width: 50,
                height: 50,
                padding: 15,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                right: 15
              }}
            >
              <FontAwesomeIcon icon={faMinimize} style={{ color: 'white' }} />
            </div>
          </Tooltip>
        ) : undefined}
      </Space>
    </Row>
  )
}
