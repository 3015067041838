import dayjs from 'dayjs'

export const confirmAfterHours = (day, slot) => {
  // Temporary hard coded
  const afterHours = {
    0: {
      startTime: '00:00',
      endTime: '23:59'
    },
    1: {
      startTime: '17:00',
      endTime: '22:00'
    },
    2: {
      startTime: '17:00',
      endTime: '22:00'
    },
    3: {
      startTime: '17:00',
      endTime: '22:00'
    },
    4: {
      startTime: '17:00',
      endTime: '22:00'
    },
    5: {
      startTime: '17:00',
      endTime: '22:00'
    },
    6: {
      startTime: '13:00',
      endTime: '22:00'
    }
  }
  let afterHourDetails = null
  afterHourDetails = afterHours[day]
  if ((slot >= afterHourDetails.startTime && slot < afterHourDetails.endTime) || day === 0) {
    return afterHourDetails
  } else {
    return null
  }
}

export const isPublicHoliday = (date) => {
  const formattedDate = dayjs(date).format('MM-DD')
  const publicHolidays = [
    '01-01', // New Year's Day
    '03-21', // Human Rights Day
    '04-07', // Good Friday
    '04-10', // Family Day
    '04-27', // Freedom Day
    '05-01', // Workers' Day
    '06-16', // Youth Day
    '08-09', // National Women's Day
    '09-24', // Heritage Day
    '12-16', // Day of Reconciliation
    '12-25', // Christmas Day
    '12-26' // Day of Goodwill
    // Add more holiday dates as needed
  ]

  return publicHolidays.includes(formattedDate)
}
