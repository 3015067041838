import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  message,
  theme
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { handleError } from '../../lib/utils'
import { generateMedLabel } from '../utils/lib'
import {
  MedPraxMaterialSearch,
  MedPraxProductSearch,
  createScriptTemplateEntry,
  readScriptTemplates,
  removeScriptTemplate,
  updateScriptTemplate
} from '../utils/utils'
import CustomRow from '../../reusable-components/CustomRow'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRemove, faTrashAlt, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import CustomButton from '../../reusable-components/CustomButton'

const ScriptingTemplatesModal = ({ open, setOpen, setItems, items }) => {
  const userProfile = useSelector((state) => state.auth.agiliteUser)
  const newEntryDataTemplate = {
    createdBy: userProfile._id,
    modifiedBy: userProfile._id,
    createdAt: userProfile._id,
    modifiedAt: userProfile._id,
    medProfRef: userProfile._id,
    name: '',
    items: []
  }

  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const [newEntryData, setNewEntryData] = useState(newEntryDataTemplate)
  const [facet, setFacet] = useState('')
  const [nappiData, setNappiData] = useState([])
  const [nappiLoading, setNappiLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [templateSelection, setTemplateSelection] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [isManualCapture, setIsManualCapture] = useState(false)
  const [manualInput, setManualInput] = useState('')
  useEffect(() => {
    if (open) {
      fetchScriptingTemplates()
    }
  }, [open])

  useEffect(() => {
    if (facet === 'add' && !isEdit) {
      setNewEntryData(newEntryDataTemplate)
    }
    // eslint-disable-next-line
  }, [facet])

  const fetchScriptingTemplates = async () => {
    setLoading(true)
    let tmpData = []
    try {
      tmpData = await readScriptTemplates()
      setTemplates(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const isAddView = facet === 'add'

  const handleAdd = () => {
    let tmpItems = templates
      .find((i) => i._id === templateSelection)
      .items.filter((i) => {
        let exists = items.find((existingItem) => existingItem.nappiCode === i.nappiCode)
        return !exists
      })
    setItems([...items, ...tmpItems])
    setSearchFilter(null)
    setTemplateSelection(null)
    setOpen(false)
  }

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpMedsData = await MedPraxProductSearch(searchQuery)
        const tmpMatsData = await MedPraxMaterialSearch(searchQuery)
        setNappiData([...tmpMatsData, ...tmpMedsData])
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setNappiLoading(false)
  }

  const handleNewItem = (newItem) => {
    let exists = false
    if (manualInput) {
      exists = newEntryData.items.find((i) => i.name?.toLowerCase() === newItem.name?.toLowerCase())
    } else {
      exists = newEntryData.items.find((i) => i.nappiCode === newItem.nappiCode)
    }
    if (exists) {
      setQuery('')
      return message.error('Item already added.')
    }
    let tmpItems = [...newEntryData?.items]
    tmpItems.push(newItem)
    setNewEntryData({ ...newEntryData, items: tmpItems })
    setQuery('')
    setManualInput('')
    setNappiData([])
  }

  const handleRemoveItem = (spliceIndex) => {
    let tmpItems = [...newEntryData?.items]
    tmpItems.splice(spliceIndex, 1)
    setNewEntryData({ ...newEntryData, items: tmpItems })
  }

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(query)

    // eslint-disable-next-line
  }, [query])

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  const handleAddNewTemplate = async () => {
    setLoading(true)
    try {
      if (isEdit) {
        await updateScriptTemplate(newEntryData._id, newEntryData)
      } else {
        await createScriptTemplateEntry(newEntryData)
      }
      setIsEdit(false)
      setFacet('')
      fetchScriptingTemplates()
    } catch (e) {
      message.error(handleError(e))
      setLoading(false)
    }
  }

  const handleRemoveTemplate = async (_id) => {
    setLoading(true)
    try {
      await removeScriptTemplate(_id)
      if (templateSelection === _id) {
        setTemplateSelection(null)
      }
      fetchScriptingTemplates()
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  return (
    <Modal
      width={950}
      closeIcon={<FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} fontSize={24} />}
      destroyOnClose={true}
      cancelButtonProps={{ disabled: loading, style: isAddView ? { background: token.colorWarning } : {} }}
      cancelText={isAddView ? 'Cancel Edit' : 'Close'}
      okButtonProps={{
        loading: loading,
        style: isAddView ? { background: token.colorSuccess } : {},
        disabled:
          (isAddView && (newEntryData.items.length === 0 || !newEntryData.name)) || (!isAddView && !templateSelection)
      }}
      okText={isAddView ? (newEntryData._id ? 'Save Changes' : 'Add Template') : 'Use Template'}
      open={open}
      onOk={() => {
        if (isAddView) {
          handleAddNewTemplate()
        } else {
          handleAdd()
          //   Modal.confirm({
          //     title: 'Confirmation',
          //     content: 'Are you sure you want to use this template. Any existing script items added will be lost?',
          //     okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
          //     cancelButtonProps: { style: { background: token.colorError, color: 'white' } },
          //     onOk: () => handleAdd(),
          //     okText: 'Yes',
          //     cancelText: 'No',
          //     className: token.themeControl
          //   })
        }
      }}
      onCancel={() => {
        if (isAddView) {
          setIsManualCapture(false)
          setIsEdit(false)
          setFacet('')
          setNewEntryData(newEntryDataTemplate)
        } else {
          setIsManualCapture(false)
          setSearchFilter(null)
          setTemplateSelection(null)
          setOpen(false)
        }
      }}
      title={<center>Script Templates {isAddView ? <>(Edit)</> : undefined}</center>}
    >
      <CustomRow justify='end' gutter={[8, 8]}>
        {facet === 'add' ? undefined : (
          <Col span={24}>
            <Space style={{ width: '100%', justifyContent: 'space-between' }} wrap>
              <Space>
                <p>Search:</p>
                <Input
                  value={searchFilter}
                  onChange={(e) => {
                    setSearchFilter(e.target.value)
                  }}
                  placeholder='Type to search...'
                  style={{ width: 350, maxWidth: '10)%' }}
                />
              </Space>
              <CustomButton type='success' size='small' onClick={() => setFacet('add')} text='Add Script Template' />
            </Space>
          </Col>
        )}
        <Col span={24}>
          {facet === 'add' ? (
            <Space direction='vertical' style={{ width: '100%' }} wrap>
              <Space wrap>
                <p>Name:</p>
                <Input
                  style={{ width: 350, maxWidth: '100%' }}
                  value={newEntryData?.name}
                  placeholder='Script Name'
                  onChange={(e) => {
                    setNewEntryData({ ...newEntryData, name: e.target.value })
                  }}
                />
              </Space>
              <Table
                size='small'
                dataSource={[...newEntryData?.items, 'add_column']}
                columns={[
                  {
                    title: 'Medication',
                    render: (entry, _, entryIndex) => {
                      if (entry === 'add_column') {
                        return (
                          <div
                            style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 8, alignItems: 'center' }}
                          >
                            {isManualCapture ? (
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '1fr auto',
                                  gap: 8,
                                  alignItems: 'center'
                                }}
                              >
                                <Input
                                  onChange={(e) => {
                                    setManualInput(e.target.value?.toUpperCase())
                                  }}
                                  value={manualInput}
                                  placeholder='Medication Description'
                                  style={{ width: '100%' }}
                                />
                                <CustomButton
                                  onClick={() => {
                                    handleNewItem({ name: manualInput })
                                  }}
                                  disabled={!manualInput}
                                  type={'success'}
                                  text='Add'
                                  size='small'
                                />
                              </div>
                            ) : (
                              <Select
                                value={null}
                                loading={nappiLoading}
                                allowClear
                                showSearch
                                placeholder='Type to search for Medications to add...'
                                style={{ width: '100%' }}
                                optionFilterProp='label'
                                options={nappiData.map((entry) => {
                                  return {
                                    label: generateMedLabel(entry),
                                    value: JSON.stringify(entry)
                                  }
                                })}
                                onSearch={(searchValue) => {
                                  setQuery(searchValue)
                                }}
                                onSelect={(med) => {
                                  let tmpMed = JSON.parse(med)
                                  handleNewItem(tmpMed)
                                }}
                                notFoundContent={
                                  nappiLoading ? (
                                    <AgiliteSkeleton skActive={false} spinnerTip='Loading...' />
                                  ) : (
                                    <>
                                      {nappiData && nappiData.length === 0 ? (
                                        <Empty description='No results found' />
                                      ) : (
                                        <Empty description='Start typing to search for a Diagnosis' />
                                      )}
                                    </>
                                  )
                                }
                              />
                            )}
                            <Switch
                              onChange={(boolean) => setIsManualCapture(boolean)}
                              checked={isManualCapture}
                              checkedChildren='Manual'
                              style={{ background: isManualCapture ? token.colorSecondary : token.colorPrimary }}
                              unCheckedChildren='Search'
                            />
                          </div>
                        )
                      } else {
                        return (
                          <Space style={{ width: '100%' }} wrap>
                            <Tooltip title='Remove'>
                              <Button
                                onClick={() => {
                                  handleRemoveItem(entryIndex)
                                }}
                              >
                                <FontAwesomeIcon icon={faRemove} color={token.colorError} />
                              </Button>
                            </Tooltip>
                            {generateMedLabel(entry)}
                          </Space>
                        )
                      }
                    }
                  }
                ]}
              />
            </Space>
          ) : (
            <Table
              loading={loading}
              dataSource={templates.filter(
                (item) => item?.name?.toLowerCase().search(searchFilter?.toLowerCase()) !== -1
              )}
              columns={[
                { title: 'Template Name', render: (entry) => entry.name },
                {
                  title: 'Actions',
                  width: 75,
                  render: (entry) => (
                    <Space>
                      <Tooltip title='Edit'>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsEdit(true)
                            setFacet('add')
                            setNewEntryData(entry)
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Tooltip>{' '}
                      <Popconfirm
                        okButtonProps={{ danger: true }}
                        okText='Remove'
                        title='Remove Template'
                        description="Are you sure you want to remove this template? This action can't be undone"
                        onConfirm={(e) => {
                          handleRemoveTemplate(entry._id)
                        }}
                      >
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <FontAwesomeIcon color={token.colorError} icon={faTrashAlt} />
                        </Button>
                      </Popconfirm>
                    </Space>
                  )
                }
              ]}
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    size='small'
                    dataSource={record.items}
                    columns={[
                      {
                        title: 'Items',
                        render: (entry) => generateMedLabel(entry)
                      }
                    ]}
                    pagination={false}
                    bordered={true}
                  />
                ),
                rowExpandable: (record) => record.items.length > 0
              }}
              rowKey={(record) => record._id}
              rowSelection={{
                type: 'radio',

                hideSelectAll: true,
                onChange: (selectedRowKeys, selectedRows) => {
                  setTemplateSelection(selectedRows[0]._id)
                },
                selectedRowKeys: [templateSelection]
              }}
              onRow={(entry) => {
                return {
                  onClick: () => {
                    setTemplateSelection(entry._id)
                  }
                }
              }}
            />
          )}
        </Col>
      </CustomRow>
    </Modal>
  )
}

export default ScriptingTemplatesModal
