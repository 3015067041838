import { Col, Row, Space, Spin, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet } from '@fortawesome/free-solid-svg-icons'
import { getCholesterolStatus } from '../utils'
import GraphGraphic from '../../../../assets/svg/Group 30-2.svg'
const CholesterolSimple = ({ data, loading }) => {
  const [cholesterol, setCholesterol] = useState(0)
  const cardStyle = {
    borderRadius: 12,
    boxShadow: '0 0 30px 0 #ECEDEE',
    padding: 12,
    minHeight: '100%'
  }
  const colorPrimary = '#3CB931'
  const colorSecondary = 'rgba(60, 185, 49, 0.15)'
  const spaceStyle = {
    alignItems: 'center'
  }
  const iconStyle = {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colorSecondary,
    borderRadius: 10
  }

  // const dummyData = [
  //   {
  //     name: 'Page F',
  //     uv: 4.3
  //   },
  //   {
  //     name: 'Page F',
  //     uv: 4.6
  //   },
  //   {
  //     name: 'Page F',
  //     uv: 4.1
  //   },
  //   {
  //     name: 'Page F',
  //     uv: 5.1
  //   }
  // ]

  useEffect(() => {
    if (data?.examinationData?.checkinData?.cholesterol) {
      setCholesterol(data.examinationData.checkinData.cholesterol)
    }
  }, [data])

  return (
    <Row style={cardStyle} gutter={[0, 10]}>
      <Col span={24}>
        <Space style={spaceStyle}>
          <div style={iconStyle}>
            <FontAwesomeIcon size='2x' color={colorPrimary} icon={faDroplet} />
          </div>
          <span> Cholesterol</span>
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          <div style={{ fontSize: 28 }}>{loading ? <Spin spinning /> : cholesterol}</div>{' '}
          <div style={{ color: '#A1A6AC' }}>mg / dL</div>
        </Space>
      </Col>
      <Col span={24}>
        {loading ? undefined : (
          <Tag color={colorSecondary} style={{ color: 'black' }}>
            {data?.examinationData?.checkinData?.cholesterol ? getCholesterolStatus(cholesterol) : 'No Readings'}
          </Tag>
        )}
      </Col>
      <Col span={24}>
        <img src={GraphGraphic} alt='Graphic' style={{ width: '100%' }} />
        {/* <SimpleAreaChartExample data={dummyData} colorPrimary={colorPrimary} colorSecondary={colorSecondary} /> */}
      </Col>
    </Row>
  )
}

export default CholesterolSimple
