import Agilite from 'agilite'
import pdfGenerationEnums from '../../Medical Vault/diagnosis-history/utils/enums'
import reportGenerationEnums from './reporting-enums'
import { AdditionalScreeningDataTemplate } from '../../Bookings/components/Examination Components/utils/additional-screening-dataModel'
import { isArray } from 'lodash'
import { handleGenerateDiabetesReport } from '../../Bookings/components/Examination Components/utils/additional-screening-lib'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const generateReport = (payload, templateId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let pdfResponse = null
      Object.keys(payload).forEach(function eachKey(key) {
        if (!payload[key]) delete payload[key]
        if (isArray(payload[key]) && payload[key].length === 0) {
          delete payload[key]
        }
      })
      console.log(JSON.stringify({ ...AdditionalScreeningDataTemplate(), ...payload }))
      try {
        pdfResponse = await agilite.Connectors.execute(
          pdfGenerationEnums.pdfGeneration.CRAFT_MY_PDF,
          reportGenerationEnums.routes.CREATE,
          {
            data: JSON.stringify({ ...AdditionalScreeningDataTemplate(), ...payload }),
            templateId: JSON.stringify(templateId)
          }
        )

        if (pdfResponse.data && pdfResponse.data.status === 'success') {
          window.open(pdfResponse.data.file, '_blank', 'noreferrer')
        } else {
          reject('Error generating PDF')
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateClinicalReport = (payload, templateId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let pdfResponse = null
      Object.keys(payload).forEach(function eachKey(key) {
        if (!payload[key]) delete payload[key]
        if (isArray(payload[key]) && payload[key].length === 0) {
          delete payload[key]
        }
      })

      try {
        pdfResponse = await agilite.Connectors.execute(
          pdfGenerationEnums.pdfGeneration.CRAFT_MY_PDF,
          reportGenerationEnums.routes.CREATE,
          {
            data: JSON.stringify({ ...payload }),
            templateId: JSON.stringify(templateId)
          }
        )

        if (pdfResponse.data && pdfResponse.data.status === 'success') {
          window.open(pdfResponse.data.file, '_blank', 'noreferrer')
        } else {
          reject('Error generating PDF')
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const AvailableReports = [
  {
    key: 'renalCare',
    title: 'Renal Care',
    description:
      'Renal care focuses on the health and treatment of the kidneys. It involves various medical interventions and lifestyle adjustments to manage conditions such as chronic kidney disease, kidney stones, and kidney failure. Renal care may include medication management, dietary changes, dialysis, and transplant evaluations.',
    generate: async (data) => {
      try {
        throw new Error('No template yet. Coming soon.')
      } catch (e) {}
    }
  },
  {
    key: 'diabetesScreening',
    title: 'Diabetes Screening',
    description:
      'Diabetes screening is the process of assessing individuals for the presence of diabetes or prediabetes through various tests such as blood glucose measurements, oral glucose tolerance tests, and hemoglobin A1c tests. Early detection through screening allows for timely intervention and management of diabetes to prevent complications such as heart disease, kidney damage, and nerve damage.',
    generate: async (data) => {
      try {
        await handleGenerateDiabetesReport(data)
      } catch (e) {}
    }
  },
  {
    key: 'occupationalHealth',
    title: 'Occupational Health',
    description:
      'Occupational health focuses on the physical, mental, and social well-being of workers in their workplaces. It involves identifying and controlling workplace hazards, promoting healthy work environments, preventing work-related injuries and illnesses, and providing medical care and rehabilitation services to workers. Occupational health aims to ensure that workers can perform their jobs safely and effectively while maintaining their overall health and well-being.'
  }
]
