import { faRefresh, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Row, Space, Table, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleError } from '../../lib/utils'
import { deleteFile, getFile, uploadFile } from '../../Admin/config/utils/utils'
import {
  createFileArchiveRecord,
  deleteFileArchiveRecord,
  readFileArchiveRecords
} from '../examination-utils/file-archive-utils'
import dayjs from 'dayjs'

const FileArchive = ({ bookingData }) => {
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(false)

  const { token } = theme.useToken()

  useEffect(() => {
    handleReadFileArchiveRecords()
    // eslint-disable-next-line
  }, [])

  const handleReadFileArchiveRecords = async () => {
    let tmpRecords = null

    setLoading(true)
    try {
      tmpRecords = await readFileArchiveRecords({ bookingRef: bookingData._id })
      setRecords(tmpRecords)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const UploadFileButton = () => {
    return (
      <Button
        type='primary'
        onClick={() => {
          document.getElementById('uploadFile').value = ''
          document.getElementById('uploadFile').click()
        }}
      >
        <Space>
          <FontAwesomeIcon icon={faUpload} />
          Upload File
        </Space>
      </Button>
    )
  }

  const RefreshButton = () => {
    return (
      <Button
        onClick={() => {
          handleReadFileArchiveRecords()
        }}
      >
        <Space>
          <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
          Refresh
        </Space>
      </Button>
    )
  }

  const handleFileUpload = async (e) => {
    try {
      const fileReader = new FileReader()

      fileReader.onload = async (event) => {
        try {
          setLoading(true)
          const arrayBuffer = event.target.result
          const fileResponse = await uploadFile(e.target.files[0].name, 'application/octet-stream', arrayBuffer, true)

          await createFileArchiveRecord({
            fileName: e.target.files[0].name,
            fileRef: fileResponse._id,
            dateUploaded: dayjs(new Date()),
            bookingRef: bookingData._id
          })

          handleReadFileArchiveRecords()
        } catch (e) {
          message.error(handleError(e, true))
        }
      }

      fileReader.readAsArrayBuffer(e.target.files[0])
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const renderRecord = (text, record) => {
    return (
      // eslint-disable-next-line
      <a
        onClick={async () => {
          try {
            const fileResponse = await getFile(record.fileRef)
            const link = document.createElement('a')
            const blob = new Blob([fileResponse])
            const url = URL.createObjectURL(blob)
            link.href = url
            link.download = record.fileName
            link.click()
            URL.revokeObjectURL(url)
          } catch (e) {
            message.error(handleError(e, true))
          }
        }}
      >
        {text}
      </a>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <Card title={UploadFileButton()} extra={RefreshButton()}>
          <Table
            dataSource={records}
            loading={loading}
            size='small'
            columns={[
              {
                title: 'File Name',
                dataIndex: 'fileName',
                key: 'fileName',
                render: (text, record) => {
                  return renderRecord(text, record)
                }
              },
              {
                title: 'Date Uploaded',
                dataIndex: 'dateUploaded',
                key: 'dateUploaded',
                render: (text) => {
                  return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                }
              },
              {
                title: 'Actions',
                key: 'actions',
                width: '10%',
                render: (text, record) => {
                  return (
                    <Button
                      type='danger'
                      onClick={async () => {
                        try {
                          deleteFileArchiveRecord(record._id)
                          deleteFile(record.fileRef)
                          handleReadFileArchiveRecords()
                        } catch (e) {
                          message.error(handleError(e, true))
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} color={token.colorError} />
                    </Button>
                  )
                }
              }
            ]}
            pagination={false}
          />
        </Card>
      </Col>
      <input
        id='uploadFile'
        multiple={false}
        type='file'
        style={{ display: 'none' }}
        onChange={(e) => handleFileUpload(e)}
      />
    </Row>
  )
}

export default FileArchive
