import { faChainBroken, faRefresh, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Select, Space, Spin, Tooltip, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { handleError, hexToRGBA } from '../../lib/utils'
import debounce from 'lodash/debounce'
import { findBookingByIDUpdate } from '../examination-utils/examination-lib'
import { readBookings } from '../../Bookings/utils/utils'

const SymptomsCapture = ({ bookingData }) => {
  const symptomsArray = [
    'General: anorexia (R63.0)',
    'General: weight loss (R63.4)',
    'General: cachexia (R64)',
    'General: chills and shivering',
    'General: convulsions (R56)',
    'General: deformity',
    'General: discharge',
    'General: dizziness / Vertigo (R42)',
    'General: fatigue (R53)',
    'General: malaise',
    'General: asthenia',
    'General: hypothermia (T68)',
    'General: jaundice (P58, P59, R17)',
    'General: muscle weakness (M62.8)',
    'General: pyrexia (R50)',
    'General: sweats',
    'General: swelling',
    'General: swollen or painful lymph node(s) (I88, L04, R59.1)',
    'General: weight gain (R63.5)',
    'Cardiovascular: arrhythmia',
    'Cardiovascular: bradycardia (R00.1)',
    'Cardiovascular: chest pain (R07)',
    'Cardiovascular: claudication',
    'Cardiovascular: palpitations (R00.2)',
    'Cardiovascular: tachycardia (R00.0)',
    'Ear, Nose and Throat: dry mouth (R68.2)',
    'Ear, Nose and Throat: epistaxis (R04.0)',
    'Ear, Nose and Throat: halitosis',
    'Ear, Nose and Throat: hearing loss',
    'Ear, Nose and Throat: nasal discharge',
    'Ear, Nose and Throat: otalgia (H92.0)',
    'Ear, Nose and Throat: otorrhea (H92.1)',
    'Ear, Nose and Throat: sore throat',
    'Ear, Nose and Throat: toothache',
    'Ear, Nose and Throat: tinnitus (H93.1)',
    'Ear, Nose and Throat: trismus',
    'Gastrointestinal: abdominal pain (R10)',
    'Gastrointestinal: bloating (R14)',
    'Gastrointestinal: belching (R14)',
    'Gastrointestinal: bleeding: Hematemesis',
    'Gastrointestinal: blood in stool: melena (K92.1), hematochezia',
    'Gastrointestinal: constipation (K59.0)',
    'Gastrointestinal: diarrhea (A09, K58, K59.1)',
    'Gastrointestinal: dysphagia (R13)',
    'Gastrointestinal: dyspepsia (K30)',
    'Gastrointestinal: fecal incontinence',
    'Gastrointestinal: flatulence (R14)',
    'Gastrointestinal: heartburn',
    'Gastrointestinal: nausea (R11)',
    'Gastrointestinal: odynophagia',
    'Gastrointestinal: proctalgia fugax',
    'Gastrointestinal: pyrosis (R12)',
    'Gastrointestinal: Rectal tenesmus',
    'Gastrointestinal: steatorrhea',
    'Gastrointestinal: vomiting (R11)',
    'Integumentary: Hair: alopecia',
    'Integumentary: Hair: hirsutism',
    'Integumentary: Hair: hypertrichosis',
    'Integumentary: Skin: abrasion',
    'Integumentary: Skin: anasarca (R60.1)',
    'Integumentary: Skin: bleeding into the skin',
    'Integumentary: Skin: petechia',
    'Integumentary: Skin: purpura',
    'Integumentary: Skin: ecchymosis and bruising (Sx0 (x=0 through 9))',
    'Integumentary: Skin: blister (T14.0)',
    'Integumentary: Skin: edema (R60)',
    'Integumentary: Skin: itching (L29)',
    "Integumentary: Skin: Janeway lesions and Osler's node",
    'Integumentary: Skin: laceration',
    'Integumentary: Skin: rash (R21)',
    'Integumentary: Skin: urticaria (L50)',
    'Neurological: abnormal posturing',
    'Neurological: acalculia',
    'Neurological: agnosia',
    'Neurological: alexia',
    'Neurological: amnesia',
    'Neurological: anomia',
    'Neurological: anosognosia',
    'Neurological: aphasia and apraxia',
    'Neurological: apraxia',
    'Neurological: ataxia',
    'Neurological: cataplexy (G47.4)',
    'Neurological: confusion',
    'Neurological: dysarthria',
    'Neurological: dysdiadochokinesia',
    'Neurological: dysgraphia',
    'Neurological: hallucination',
    'Neurological: headache (R51)',
    'Neurological: hypokinetic movement disorder: akinesia',
    'Neurological: hypokinetic movement disorder: bradykinesia',
    'Neurological: hyperkinetic movement disorder: akathisia',
    'Neurological: hyperkinetic movement disorder: athetosis',
    'Neurological: hyperkinetic movement disorder: ballismus',
    'Neurological: hyperkinetic movement disorder: blepharospasm',
    'Neurological: hyperkinetic movement disorder: chorea',
    'Neurological: hyperkinetic movement disorder: dystonia',
    'Neurological: hyperkinetic movement disorder: fasciculation',
    'Neurological: muscle cramps (R25.2)',
    'Neurological: myoclonus',
    'Neurological: opsoclonus',
    'Neurological: tic',
    'Neurological: tremor',
    'Neurological: flapping tremor',
    'Neurological: insomnia (F51.0, G47.0)',
    "Neurological: Lhermitte's sign (as if an electrical sensation shoots down back & into arms)",
    'Neurological: loss of consciousness',
    'Neurological: Syncope (medicine) (R55)',
    'Neurological: neck stiffness',
    'Neurological: opisthotonus',
    'Neurological: paralysis and paresis',
    'Neurological: paresthesia (R20.2)',
    'Neurological: prosopagnosia',
    'Neurological: somnolence (R40.0)',
    'Obstetric / Gynaecological: abnormal vaginal bleeding',
    'Obstetric / Gynaecological: vaginal bleeding in early pregnancy / miscarriage',
    'Obstetric / Gynaecological: vaginal bleeding in late pregnancy',
    'Obstetric / Gynaecological: amenorrhea',
    'Obstetric / Gynaecological: infertility',
    'Obstetric / Gynaecological: painful intercourse (N94.1)',
    'Obstetric / Gynaecological: pelvic pain',
    'Obstetric / Gynaecological: vaginal discharge',
    'Ocular: amaurosis fugax (G45.3) and amaurosis',
    'Ocular: blurred vision',
    "Ocular: Dalrymple's sign",
    'Ocular: double vision (H53.2)',
    'Ocular: exophthalmos (H05.2)',
    'Ocular: mydriasis/miosis (H570)',
    'Ocular: nystagmus',
    'Psychiatric: amusia',
    'Psychiatric: anhedonia',
    'Psychiatric: anxiety',
    'Psychiatric: apathy',
    'Psychiatric: confabulation',
    'Psychiatric: depression',
    'Psychiatric: delusion',
    'Psychiatric: euphoria',
    'Psychiatric: homicidal ideation',
    'Psychiatric: irritability',
    'Psychiatric: mania (F30)',
    'Psychiatric: paranoid ideation',
    'Psychiatric: phobia: Main article: list of phobias',
    'Psychiatric: suicidal ideation',
    'Pulmonary: apnea and hypopnea',
    'Pulmonary: cough (R05)',
    'Pulmonary: dyspnea (R06.0)',
    'Pulmonary: bradypnea (R06.0) and tachypnea (R06.0)',
    'Pulmonary: orthopnea and platypnea',
    'Pulmonary: trepopnea',
    'Pulmonary: hemoptysis (R04.2)',
    'Pulmonary: pleuritic chest pain',
    'Pulmonary: sputum production (R09.3)',
    'Rheumatologic: arthralgia',
    'Rheumatologic: back pain',
    'Rheumatologic: sciatica',
    'Urologic: dysuria (R30.0)',
    'Urologic: hematospermia',
    'Urologic: hematuria (R31)',
    'Urologic: impotence (N48.4)',
    'Urologic: polyuria (R35)',
    'Urologic: retrograde ejaculation',
    'Urologic: strangury',
    'Urologic: urethral discharge',
    'Urologic: urinary frequency (R35)',
    'Urologic: urinary incontinence (R32)',
    'Urologic: urinary retention'
  ]
  const [symptoms, setSymptoms] = useState([])
  const [saveError, setSaveError] = useState(false)
  const [busy, setBusy] = useState(false)
  const [searchQry, setSearchQry] = useState('')

  useEffect(() => {
    checkExistingSymptoms()

    // eslint-disable-next-line
  }, [])

  const checkExistingSymptoms = async () => {
    setBusy(true)
    let tmpSymptoms = []

    try {
      tmpSymptoms = await readBookings({ _id: bookingData._id }, null, 'symptoms')
      setSymptoms(tmpSymptoms[0]?.symptoms || [])
    } catch (e) {
      message.error(handleError(e))
    }
    setBusy(false)
  }

  const handleSymtpomsChange = async (array) => {
    setBusy(true)
    setSaveError(false)
    try {
      setSymptoms(array)
      await findBookingByIDUpdate(bookingData._id, { symptoms: array })
    } catch (e) {
      setSaveError(true)
      message.error('There was a problem saving this symptom. Please try again.')
    }
    setBusy(false)
  }

  // eslint-disable-next-line
  const debounceSymptomsChange = useCallback(
    debounce((array) => {
      handleSymtpomsChange(array)
    }, 1000),
    []
  )

  const { token } = theme.useToken()

  return (
    <div
      style={{
        width: '100%',
        background: saveError ? hexToRGBA(token.colorError, 0.1) : '',
        padding: saveError ? 8 : 0
      }}
    >
      <Space>
        <p>Symptoms:</p>
        <Select
          style={{ minWidth: 250 }}
          value={symptoms}
          onSearch={(e) => {
            setSearchQry(e)
          }}
          defaultValue={symptoms}
          mode='multiple'
          onClear={() => {
            setSearchQry('')
          }}
          onChange={(array) => {
            setSearchQry(null)
            setBusy(true)
            setSymptoms(array)
            debounceSymptomsChange(array)
          }}
          onDropdownVisibleChange={(visible) => {
            if (!visible) setSearchQry('')
          }}
          dropdownStyle={{ minWidth: 650 }}
          options={symptomsArray
            .filter((str) => {
              if (searchQry) {
                return str?.toLowerCase().includes(searchQry?.toLowerCase())
              } else {
                return true
              }
            })
            .map((item) => {
              return {
                value: item,
                label: item
              }
            })}
          filterOption={false}
          placeholder={<>- Symptoms -</>}
        />
        {busy ? <Spin spinning /> : undefined}
        {saveError ? (
          <Space>
            {saveError && !busy ? (
              <Tooltip title='Error Saving Symptoms'>
                <FontAwesomeIcon icon={faChainBroken} color={token.colorError} />
              </Tooltip>
            ) : undefined}
            {saveError ? (
              <Button
                onClick={() => {
                  handleSymtpomsChange(symptoms)
                }}
              >
                <Space>
                  Retry <FontAwesomeIcon fontSize={20} icon={faRefresh} color={token.colorSuccess} />
                </Space>
              </Button>
            ) : undefined}
          </Space>
        ) : undefined}
        {!saveError && !busy ? (
          <Tooltip title='Symptoms Captured Successfully'>
            <FontAwesomeIcon fontSize={20} icon={faCheckCircle} color={token.colorSuccess} />{' '}
          </Tooltip>
        ) : undefined}
      </Space>
      {saveError ? (
        <div style={{ width: '100%', marginTop: 12 }}>
          {saveError ? <p>There was an issue saving the symptoms for this booking. Please try again.</p> : undefined}
        </div>
      ) : undefined}
    </div>
  )
}

export default SymptomsCapture
