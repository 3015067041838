import React, { Suspense, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message, Spin } from 'antd'

import Store from '../../../../store'
import coreReducer from '../../../../core/utils/reducer'
import Templates from '../utils/templates'
import { readVirtualServices } from '../utils/utils'

import ListView from './virtual-services-list'
import BasicForm from './virtual-services-form'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import CoreEnums from '../../../../core/utils/enums'
import { handleError } from '../../../lib/utils'
import virtualServicesReducer from '../utils/reducer'

const VirtualServicesWrapper = () => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const viewData = useSelector((state) => state.virtualServices.data)

  const refreshView = async () => {
    let data = []

    setLoading(true)

    try {
      data = await readVirtualServices()
      dispatch(virtualServicesReducer.actions.setRecords(data))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [])

  const createRecord = () => {
    Store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.SERVICES}_new`,
        closable: true,
        label: 'Add Service',
        children: (
          <Suspense fallback={<Spin />}>
            <BasicForm data={Templates.dataTemplate()} isNewRecord refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  const editRecord = (record) => {
    Store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.SERVICES}_${record._id}`,
        closable: true,
        label: `Service: ${record.name}`,
        children: (
          <Suspense fallback={<Spin />}>
            <BasicForm data={record} isNewRecord={false} refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  return (
    <ContainerCard
      title={
        <>
          <h3>Services</h3>
        </>
      }
    >
      <ListView
        loading={loading}
        data={viewData}
        createRecord={createRecord}
        editRecord={editRecord}
        refreshView={refreshView}
      />
    </ContainerCard>
  )
}

export default VirtualServicesWrapper
