import React, { useState, useEffect, useCallback } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'

import coreReducer from '../../../../core/utils/reducer'
import Templates from '../utils/templates'
import { countSystemUsers, readSystemUsers } from '../utils/utils'
import systemUsersReducer from '../utils/reducer'

// Components
import ListView from './system-users-list'
import BasicForm from './system-users-form'
import medicalProfessionalReducer from '../../medical-professionals/utils/reducer'
import patientsReducer from '../../patients/utils/reducer'
import { debounce } from 'lodash'
import { readClinics } from '../../clinics/utils/utils'
import CoreEnums from '../../../../core/utils/enums'
import { readEntities } from '../../entities/utils/utils'
import { handleError } from '../../../lib/utils'
import AddPatient from './add-patient'
import EditPatientProfile from './edit-patient'

const SystemUsersWrapper = ({ type, viewType }) => {
  const activeRecords = useSelector((state) => state.systemUsers.activeRecords)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [clinics, setClinics] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [entities, setEntities] = useState([])
  const state = useSelector((state) => state)

  const dispatch = useDispatch()

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      if (query) {
        setPage(1)
        setSearchQuery(query)
      } else {
        setPage(1)
        setSearchQuery(query)
        refreshView()
      }
    }, 1000),
    []
  )

  const handleSearch = (query) => {
    setLoading(true)
    debouncedFilter(query)
  }

  const refreshView = async () => {
    let qry = {}
    let data = []
    let tmpClinics = []
    let tmpTotal = 0

    setLoading(true)

    try {
      if (state.auth.agiliteUser.extraData.role.type === 'super_admin') {
        let entities = null

        entities = await readEntities()
        setEntities(entities)
      }
      switch (type) {
        case 'system':
          qry = { $nor: [{ 'extraData.role.type': 'medical_professional' }, { 'extraData.role.type': 'patient' }] }
          break
        case 'medical':
          qry = { 'extraData.role.type': 'medical_professional' }
          break
        case 'patient':
          qry = { 'extraData.role.type': 'patient' }
          break
      }

      if (searchQuery) {
        const searchTerms = searchQuery.trim().toLowerCase().split(/\s+/)

        // Prepare regex patterns for partial matching
        const regexPatterns = searchTerms.map((term) => new RegExp(term, 'i'))
        qry = {
          ...qry,
          $or: [
            // Match against concatenated firstName and lastName with partial regexes
            {
              $expr: {
                $regexMatch: {
                  input: {
                    $concat: [{ $toLower: '$firstName' }, ' ', { $toLower: '$lastName' }]
                  },
                  regex: searchTerms.map((term) => `.*${term}.*`).join(''),
                  options: 'i'
                }
              }
            },
            // Match individually against firstName and lastName with partial regexes
            {
              $or: [
                {
                  $expr: {
                    $regexMatch: {
                      input: { $toLower: '$firstName' },
                      regex: regexPatterns.join('|'),
                      options: 'i'
                    }
                  }
                },
                {
                  $expr: {
                    $regexMatch: {
                      input: { $toLower: '$lastName' },
                      regex: regexPatterns.join('|'),
                      options: 'i'
                    }
                  }
                }
              ]
            },
            { phoneNumber: { $regex: searchQuery, $options: 'i' } },
            { email: { $regex: searchQuery, $options: 'i' } },
            { physicalAddress: { $regex: searchQuery, $options: 'i' } },
            { idNo: { $regex: searchQuery, $options: 'i' } }
          ]
        }
      }

      tmpTotal = await countSystemUsers(qry)
      data = await readSystemUsers(qry, page, pageSize)
      tmpClinics = await readClinics()
      setClinics(tmpClinics)
      setTotal(tmpTotal)

      switch (type) {
        case 'system':
          dispatch(systemUsersReducer.actions.setRecords(data))
          break
        case 'medical':
          dispatch(medicalProfessionalReducer.actions.setRecords(data))
          break
        case 'patient':
          dispatch(patientsReducer.actions.setRecords(data))
          break
        default:
          dispatch(systemUsersReducer.actions.setRecords(data))
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [searchQuery, page, pageSize])

  const addPatient = () => {
    const tmpActiveRecords = activeRecords.concat()
    let record = Templates.dataTemplate()
    let tmpIndex = null

    tmpIndex = tmpActiveRecords.findIndex((item) => item.isNew === true)

    if (tmpIndex === -1) {
      tmpActiveRecords.push(record)
    } else {
      tmpActiveRecords[tmpIndex] = record
    }
    batch(() => {
      dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
      dispatch(
        coreReducer.actions.addTab({
          key: `${handleRootTabKey()}_new_${type}_user`,
          closable: true,
          label: `New Invite: ${viewType}`,
          children: (
            <AddPatient
              entities={entities}
              dataId={null}
              handleRootTabKey={handleRootTabKey}
              isNewRecord
              refreshView={refreshView}
              type={type}
            />
          )
        })
      )
    })
  }
  const createRecord = () => {
    const tmpActiveRecords = activeRecords.concat()
    let record = Templates.dataTemplate()
    let tmpIndex = null

    tmpIndex = tmpActiveRecords.findIndex((item) => item.isNew === true)

    if (tmpIndex === -1) {
      tmpActiveRecords.push(record)
    } else {
      tmpActiveRecords[tmpIndex] = record
    }

    batch(() => {
      dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
      dispatch(
        coreReducer.actions.addTab({
          key: `${handleRootTabKey()}_new_${type}_user`,
          closable: true,
          label: `New Invite: ${viewType}`,
          children: (
            <BasicForm
              entities={entities}
              dataId={null}
              handleRootTabKey={handleRootTabKey}
              isNewRecord
              refreshView={refreshView}
              type={type}
            />
          )
        })
      )
    })
  }

  const getTabLabel = () => {
    switch (type) {
      case 'system':
        return 'System User'
      case 'medical':
        return 'Medical Professional'
      case 'patient':
        return 'Patient'
      default:
        return 'User'
    }
  }

  const editRecord = (record) => {
    const tmpActiveRecords = activeRecords.concat()
    let tmpIndex = null

    tmpIndex = tmpActiveRecords.findIndex((item) => item._id === record._id)

    if (tmpIndex === -1) {
      tmpActiveRecords.push(record)
    } else {
      tmpActiveRecords[tmpIndex] = record
    }

    batch(() => {
      dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
      dispatch(
        coreReducer.actions.addTab({
          key: `${handleRootTabKey()}_${record._id}`,
          closable: false,
          label: `${getTabLabel(type)}: ${record.firstName} ${record.lastName}`,
          children:
            type === 'patient' ? (
              <EditPatientProfile handleRootTabKey={handleRootTabKey} dataId={record._id} refreshView={refreshView} />
            ) : (
              <BasicForm
                entities={entities}
                handleRootTabKey={handleRootTabKey}
                dataId={record._id}
                isNewRecord={false}
                refreshView={refreshView}
                type={type}
              />
            )
        })
      )
    })
  }

  const handleRootTabKey = () => {
    switch (type) {
      case 'system':
        return CoreEnums.tabKeys.SYSTEM_USERS
      case 'medical':
        return CoreEnums.tabKeys.MEDICAL_PROFESSIONALS
      case 'patient':
        return CoreEnums.tabKeys.PATIENTS
      default:
        return CoreEnums.tabKeys.SYSTEM_USERS
    }
  }

  return (
    <ListView
      entities={entities}
      type={type}
      addPatient={addPatient}
      loading={loading}
      setLoading={setLoading}
      createRecord={createRecord}
      editRecord={editRecord}
      refreshView={refreshView}
      handleSearch={handleSearch}
      clinics={clinics}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      total={total}
    />
  )
}

export default SystemUsersWrapper
