import { Button, Col, Popconfirm, Switch, theme } from 'antd'
import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'

const AvailabilitySchedule = ({
  saveDaySchedule,
  handleTimeslotChange,
  setRangePicker,
  currentDay,
  selectedProfession,
  professionalDetails,
  handleRangeSelect,
  loading,
  isMedProf
}) => {
  const { token } = theme.useToken()

  return (
    <CustomRow gutter={[12, 12]} className='basic-card'>
      {isMedProf ? undefined : (
        <Col span={24}>
          <h2
            style={{
              padding: 12,
              borderRadius: 12,
              background: token.colorPrimary,
              textAlign: 'center',
              color: '#ffffff'
            }}
          >
            {professionalDetails.firstName} {professionalDetails.lastName}
          </h2>
        </Col>
      )}
      <Col span={24}>
        <CustomRow gutter={[12, 12]} style={{ marginBottom: 20 }} justify='center'>
          <Col>
            <Popconfirm
              title='Confirm'
              description='Are you sure you want to clear all timeslots?'
              onConfirm={() => handleRangeSelect(true)}
              okText='Yes'
              cancelText='No'
              okButtonProps={{ danger: true }}
              disabled={loading}
            >
              <Button disabled={loading} danger>
                Clear
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            <Button
              type='primary'
              onClick={() => {
                setRangePicker(true)
              }}
              disabled={loading}
            >
              Set By Range
            </Button>
          </Col>
          <Col>
            <Button
              style={{ background: token.colorSuccess, color: 'white' }}
              onClick={() => {
                saveDaySchedule()
              }}
              loading={loading}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
          </Col>
        </CustomRow>
        <CustomRow gutter={[12, 12]}>
          {currentDay.timeSlots.map((slot) => {
            return (
              <Col xs={24} md={12} lg={12}>
                <CustomRow
                  gutter={12}
                  style={{ alignItems: 'center', border: '1px rgba(0,0,0,0.2) solid' }}
                  className='basic-card'
                  justify='space-between'
                >
                  <Col>
                    <p>{slot.time}</p>
                  </Col>
                  <Col>
                    {selectedProfession ? (
                      <Switch
                        style={{ background: slot.linkedProfessional ? token.colorPrimary : token.colorError }}
                        checked={slot.linkedProfessional}
                        onChange={(value) => {
                          handleTimeslotChange(slot.time, value)
                        }}
                      />
                    ) : undefined}
                  </Col>
                </CustomRow>
              </Col>
            )
          })}
        </CustomRow>
        <CustomRow gutter={[12, 12]} style={{ marginTop: 20 }} justify='center'>
          <Col>
            <Popconfirm
              title='Confirm'
              description='Are you sure you want to clear all timeslots?'
              onConfirm={() => handleRangeSelect(true)}
              okText='Yes'
              cancelText='No'
              disabled={loading}
              okButtonProps={{ danger: true }}
            >
              <Button disabled={loading} danger>
                Clear
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            <Button
              type='primary'
              onClick={() => {
                setRangePicker(true)
              }}
              disabled={loading}
            >
              Set By Range
            </Button>
          </Col>
          <Col>
            <Button
              style={{ background: token.colorSuccess, color: 'white' }}
              onClick={() => {
                saveDaySchedule()
              }}
              loading={loading}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default AvailabilitySchedule
