import Agilite from 'agilite'
import Axios from 'agilite-utils/axios'
import { firebaseAuth } from '../../..'
import {
  extractAddressDetails,
  generateGenderPrefix,
  generateTitle,
  handleError,
  logAgiliteError
} from '../../lib/utils'
import store from '../../../store'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { cgmAuth } from '../../Bookings/cgm_billing/utils'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import axios from 'axios'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const findAgiliteUser = (filter, isActiveOnly = false) => {
  return new Promise((resolve, reject) => {
    let qry = { $or: [] }
    let response = null

    ;(async () => {
      try {
        for (const prop in filter) {
          qry.$or.push({ [prop]: filter[prop] })
        }

        if (isActiveOnly) {
          qry['extraData.isActive'] = isActiveOnly
        }

        response = await agilite.Connectors.execute('auth_users', 'find', { filter: JSON.stringify(qry) })

        if (response.data.length > 0) {
          resolve(response.data[0])
        } else {
          resolve(null)
        }
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const createAgiliteUser = (data, useFirebaseId = true, createdBy) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      const State = store.getState()
      let response = null
      let timestamp = Date()
      let usersCount = 0

      try {
        data.extraData = { ...data.extraData }

        if (State.auth?.agiliteUser?.extraData?.role?.type !== 'super_admin') {
          data.extraData.entities = [State.core.entity._id]
        }

        data.dateCreated = timestamp
        data.dateModified = timestamp

        // !Temporary logic for troubleshooting intermittend issue with entities
        if (data.extraData.entities.length === 0) {
          await logAgiliteError(
            { data, appState: State },
            `createAgiliteUser: ${data.firstName} ${data.lastName} has no entities assigned`
          )
        }

        usersCount = await agilite.Connectors.execute('auth_users', 'count')

        response = await agilite.Connectors.execute('auth_users', 'create', {
          data: JSON.stringify({
            ...data,
            billingId: usersCount.data.result + 1,
            firebaseId: useFirebaseId ? firebaseAuth.currentUser.uid : '',
            createdBy: createdBy ? createdBy : 'System'
          })
        })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const updateAgiliteUser = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null
      let updatedRecord = null
      let cgmAuthRes = null
      let clinicResponse = null
      let mainPatientRecord = null
      let patientRecord = null
      let timestamp = Date()

      try {
        data.dateModified = timestamp

        response = await agilite.Connectors.execute('auth_users', 'update', { recordId, data: JSON.stringify(data) })
        updatedRecord = response.data

        resolve(response.data)

        // Check if user that was updated is a patient
        // if yes then we need to update their records in CGM
        if (updatedRecord.extraData.role.type === 'patient') {
          patientRecord = updatedRecord

          // Get Main Patient Record (if applicable)
          if (patientRecord.mainMemberId) {
            mainPatientRecord = await findAgiliteUser({ _id: patientRecord.mainMemberId })
          } else {
            mainPatientRecord = patientRecord
          }

          // Get the clinics this user belongs to
          clinicResponse = await readClinics({ cgmEnabled: true })

          // Authenticate with CGM
          cgmAuthRes = await cgmAuth(patientRecord._id)

          // Format Addresses
          const mainPatientAddressResult = await geocodeByAddress(mainPatientRecord.residentialAddress)
          const mainPatientAddressObj = extractAddressDetails(mainPatientAddressResult)
          const patientAddressResult = await geocodeByAddress(patientRecord.residentialAddress)
          const patientAddressObj = extractAddressDetails(patientAddressResult)

          // We need to go through every clinic they might belong to and update all the cgm user records
          // they might have, cgmPracticeId is the clinic id in the cgm system
          const promises = clinicResponse.map((clinic) => {
            return createRequestPoolRecord({
              url: `${process.env.REACT_APP_CGM_BASE_URL}/${clinic.cgmPracticeId}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${cgmAuthRes.access_token}`
              },
              data: {
                practiceNumber: clinic.practiceNumber,
                accountRegistration: {
                  accountID: mainPatientRecord.billingId,
                  accountName: mainPatientRecord.firstName + ' ' + mainPatientRecord.lastName,
                  accountStatus: 0,
                  email: mainPatientRecord.email,
                  tel: mainPatientRecord.phoneNumber,
                  cell: '',
                  fax: '',
                  fileRef: '',
                  vatRegNo: '',
                  address: {
                    postLine1: mainPatientAddressObj.streetAddress,
                    postLine2: '',
                    postCity: mainPatientAddressObj.city,
                    postCode: mainPatientAddressObj.postalCode,
                    postProvince: mainPatientAddressObj.province
                  }
                },
                patientRegistration: {
                  patient: {
                    accountID: mainPatientRecord.billingId,
                    patientID: patientRecord.billingId,
                    dob: patientRecord.dateOfBirth,
                    idNumber: patientRecord.country === 'ZA' ? patientRecord.idNo : patientRecord.dateOfBirth,
                    surname: patientRecord.lastName,
                    fullNames: patientRecord.firstName,
                    initials: patientRecord.firstName[0] + patientRecord.lastName[0],
                    title: generateTitle(patientRecord.gender),
                    gender: generateGenderPrefix(patientRecord.gender),
                    language: null,
                    dependantNo: patientRecord.medicalAid.dependantNumber,
                    contactDetails: {
                      resLine1: patientAddressObj.streetAddress,
                      resLine2: '',
                      resCity: patientAddressObj.city,
                      resPostCode: patientAddressObj.postalCode,
                      resProvince: patientAddressObj.province,
                      email: patientRecord.email,
                      cell: patientRecord.phoneNumber
                    }
                  },
                  medAidDetails: {
                    planCode: patientRecord?.medicalAid?.planCode || 'null',
                    optionCode: patientRecord?.medicalAid?.planOptionCode || 'null',
                    mainMemberId: mainPatientRecord.billingId,
                    membershipNo: patientRecord?.medicalAid?.number || 'null',
                    validFromDate: ''
                  }
                }
              }
            })
          })

          await Promise.all(promises)
        }
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const getCountries = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('countries', 'find', { filter: JSON.stringify(filter) })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const createRequestPoolRecord = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/request-pools/create`, {
          url: data.url,
          method: data.method,
          headers: data.headers,
          data: data.data
        })

        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}
