import React, { useEffect, useState } from 'react'
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'
import { ConfigProvider, Switch, Tour, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

// Utils
import CoreEnums from '../utils/enums'

// Components
import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'
import TabNavigation from './TabNavigation'
import Toolbar from './Toolbar'
import App from '../../custom/app'

// Theme
import { darkTheme, lightTheme } from '../utils/antd-theme'
import { deviceDetect } from 'react-device-detect'
import { readEntities } from '../../custom/Admin/entities/utils/utils'
import { batch, useDispatch, useSelector } from 'react-redux'
import coreReducer from '../utils/reducer'
import { handleError } from '../../custom/lib/utils'
// import SetupWizard from '../../custom/Admin/set-up-wizard/set-up-wizard-wrapper'

const Core = (customProps) => {
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [themeButtonDisabled, setThemeButtonDisabled] = useState(false)
  const RootContent = customProps.state.rootContent || App
  const [motionUnit, setMotionUnit] = useState(0.1)
  const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) return srcValue
  }
  const props = mergeWith(Object.assign({}, coreState), Object.assign({}, customProps), customizer)

  // Messages
  message.config({ duration: 2.5, maxCount: 2, prefixCls: 'agilite_message' })

  // Theme
  const [themeToken, setThemeToken] = useState(
    JSON.parse(localStorage.getItem(CoreEnums.localStorage.THEME_TOKEN)) || lightTheme
  )
  const [isDarkTheme, setIsDarkTheme] = useState(
    JSON.parse(localStorage.getItem(CoreEnums.localStorage.IS_DARK_THEME)) || false
  )

  useEffect(() => {
    const setEntity = async () => {
      const url = window.location.href
      let pathArray = window.location.pathname.split('/')
      let entity = null

      try {
        // Filter out empty values
        pathArray = pathArray.filter((p) => p !== '')

        const entitites = await readEntities()

        if (entitites.length > 0) {
          if (pathArray.length > 0 && !['login', 'register', 'checkin'].includes(pathArray[0])) {
            // If url includes "/something"
            entity = entitites.find((e) => e.customUrl === pathArray[0])
          } else {
            entity = entitites.find((e) => url.includes(e.customUrl))
          }

          if (entity) {
            batch(() => {
              dispatch(coreReducer.actions.setEntity(entity))
              dispatch(coreReducer.actions.setAppTitle(entity.name))
            })
          } else {
            dispatch(coreReducer.actions.setNoEntityFound(true))
          }
        }
      } catch (e) {
        message.error(handleError(e))
      }
    }

    setEntity()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (authState?.agiliteUser?.extraData?.role?.type === 'admin') {
      // TODO: Check if user completed tour

      handleOpenTour()
    }

    // eslint-disable-next-line
  }, [authState?.agiliteUser?.extraData?.role?.type])

  useEffect(() => {
    document.body.style.backgroundColor = themeToken.colorBgBase
    // Change the motion unit to 0.1 again
    setMotionUnit(0.1)
    setThemeButtonDisabled(false)
  }, [themeToken])

  useEffect(() => {
    if (motionUnit === 0) {
      if (isDarkTheme) {
        localStorage.setItem(CoreEnums.localStorage.IS_DARK_THEME, false)
        localStorage.setItem(CoreEnums.localStorage.THEME_TOKEN, JSON.stringify(lightTheme))

        setIsDarkTheme(false)
        setThemeToken(lightTheme)
      } else {
        localStorage.setItem(CoreEnums.localStorage.IS_DARK_THEME, true)
        localStorage.setItem(CoreEnums.localStorage.THEME_TOKEN, JSON.stringify(darkTheme))

        setIsDarkTheme(true)
        setThemeToken(darkTheme)
      }
    }
    // eslint-disable-next-line
  }, [motionUnit])

  const handleThemeToggle = (value) => {
    // We need to set the motion unit to 0 so that there is not transition when changing the theme
    // This state change triggers the useEffect above
    setMotionUnit(0)
    setThemeButtonDisabled(true)
  }

  useEffect(() => {
    // Define the function that will be called when the popstate event is triggered
    const onPopState = (event) => {
      const index = coreState.breadcrumbs.findIndex((b) => b.key === event.state.key)

      if (index !== -1) {
        let key = coreState.breadcrumbs[index].key
        dispatch(coreReducer.actions.changeTab(key))
      } else {
        dispatch(coreReducer.actions.changeTab('home'))
      }
    }

    window.addEventListener('popstate', onPopState)

    return () => {
      window.removeEventListener('popstate', onPopState)
    }
    // eslint-disable-next-line
  }, [coreState.tabNavigation.activeKey])

  const themeToggle = () => {
    return (
      <Switch
        checked={!isDarkTheme}
        style={{ background: themeToken.token.colorBgContainer }}
        unCheckedChildren={
          <>
            <FontAwesomeIcon icon={faMoon} />
          </>
        }
        checkedChildren={
          <>
            <FontAwesomeIcon color='orange' icon={faSun} />
          </>
        }
        onChange={() => {
          handleThemeToggle()
        }}
        disabled={themeButtonDisabled}
      />
    )
  }

  const tourSteps = [
    {
      title: 'Welcome',
      description: (
        <>
          Welcome to the Meta-Clinic Portal.
          <br />
          <br /> This wizard will guide you through the Entity Set Up process to get you started.
        </>
      ),
      onNext: () => {
        dispatch(coreReducer.actions.nextTourStep())
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    {
      title: 'Clinic Management',
      description: (
        <>
          This is where you configure various settings for your clinic.
          <br />
          <br /> Click on the <b>Clinic Management</b> category to get started.
        </>
      ),
      target: () => document.getElementById('clinic_management_category'),
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    {
      title: 'Clinics',
      description: (
        <>
          This is where you can set up and manage the various clinics of your entity. <br />
          <br />
          Click on the <b>Clinics</b> menu item.
        </>
      ),
      target: () => document.getElementById('clinics_menu_item'),
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    // {
    //   title: 'Add Clinic',
    //   description: (
    //     <>
    //       Click on the <b>Add Clinic</b> button to add your first Clinic
    //     </>
    //   ),
    //   target: () => document.getElementById('clinic_add_btn'),
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    // {
    //   title: 'Add Clinic: Form',
    //   description: (
    //     <>
    //       Populate your Clinic's information and click the <b>Add Clinic</b> button to continue.
    //     </>
    //   ),
    //   target: () => document.getElementById('clinic_form'),
    //   placement: 'left',
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    {
      title: 'Clinic Added',
      description: (
        <>
          You have successfully created your first clinic. Here you can view and edit your clinics. <br />
          <br />
          Feel free to navigate back here to add more or edit clinics at any time.
        </>
      ),
      target: () => document.getElementById('clinic_list_view'),
      onNext: () => {
        dispatch(coreReducer.actions.nextTourStep())
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    {
      title: 'Services',
      description: (
        <>
          Services are assigned to Medical Professionals to help patients find the right professional for their needs.
          <br />
          <br /> Click on the <b>Services</b> menu item.
        </>
      ),
      target: () => document.getElementById('services_menu_item'),
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    // {
    //   title: 'Add Service',
    //   description: (
    //     <>
    //       Click on the <b>Add Service</b> button to add your first Service
    //     </>
    //   ),
    //   target: () => document.getElementById('service_add_btn'),
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    // {
    //   title: 'Add Service: Form',
    //   description: (
    //     <>
    //       Populate your Service's information and click the <b>Add Service</b> button to continue.
    //     </>
    //   ),
    //   target: () => document.getElementById('service_form'),
    //   placement: 'left',
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    {
      title: 'Service Added',
      description: (
        <>
          You have successfully created your first service. Here you can view and edit your services. <br />
          <br />
          Feel free to navigate back here to add more or edit services at any time.
        </>
      ),
      target: () => document.getElementById('services_list_view'),
      onNext: () => {
        dispatch(coreReducer.actions.nextTourStep())
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    {
      title: 'User Management',
      description: (
        <>
          This is where you can manage and invite users to your entity.
          <br />
          <br /> Click on the <b>User Management</b> category to get started.
        </>
      ),
      target: () => document.getElementById('user_management_category'),
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    {
      title: 'System Users',
      description: (
        <>
          Receptionists and other Administrators are regarded as system users, this is where you can add these users.
          <br />
          <br />
          Click on the <b>System Users</b> menu item.
        </>
      ),
      target: () => document.getElementById('system_users_menu_item'),
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    // {
    //   title: 'Add System User',
    //   description: (
    //     <>
    //       Click on the <b>Invite Invite System User</b> button to invite a System User
    //     </>
    //   ),
    //   target: () => document.getElementById('systemUser_add_btn'),
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    // {
    //   title: 'Invite System User',
    //   description: (
    //     <>
    //       Populate the System User's information and click the <b>Invite</b> button to continue.
    //     </>
    //   ),
    //   target: () => document.getElementById('medProf_form'),
    //   placement: 'bottom',
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    {
      title: 'System User Added',
      description: (
        <>
          You have successfully invited your first System User. Here you can view and edit your System Users.
          <br />
          <br />
          Feel free to navigate back here to add more or edit System User Profiles at any time.
        </>
      ),
      onNext: () => {
        dispatch(coreReducer.actions.nextTourStep())
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    {
      title: 'Medical Professionals',
      description: (
        <>
          This is where you can view and manage your Medical Professionals, such as Doctors, Nurses etc...
          <br />
          <br /> Lets add your first Medical Professional. Click on the <b>Medical Professional</b> menu item.
        </>
      ),
      target: () => document.getElementById('medical_professionals_menu_item'),
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    // {
    //   title: 'Add Medical Professional',
    //   description: (
    //     <>
    //       Click on the <b>Invite Medical Professional</b> button to invite a Medical Professional
    //     </>
    //   ),
    //   target: () => document.getElementById('medicalProf_add_btn'),
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    // {
    //   title: 'Invite Medical Professional',
    //   description: (
    //     <>
    //       Populate the Medical Professional's information and click the <b>Invite</b> button to continue.
    //     </>
    //   ),
    //   target: () => document.getElementById('medProf_form'),
    //   placement: 'left',
    //   nextButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   },
    //   prevButtonProps: {
    //     style: {
    //       display: 'none'
    //     }
    //   }
    // },
    {
      title: 'Medical Professional Added',
      description: (
        <>
          You have successfully invited your first Medical Professional. Here you can view and edit your Medical
          Professionals. <br />
          <br />
          Feel free to navigate back here to add more or edit Medical Professional Profiles at any time.
        </>
      ),
      onNext: () => {
        dispatch(coreReducer.actions.nextTourStep())
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    },
    {
      title: 'Patients',
      description: (
        <>
          This is where you can view and manage your patients.
          <br />
        </>
      ),
      target: () => document.getElementById('patients_menu_item'),
      onNext: () => {
        dispatch(coreReducer.actions.closeTour())
      },
      prevButtonProps: {
        style: {
          display: 'none'
        }
      }
    }
  ]

  const handleOpenTour = () => {
    // dispatch(
    //   coreReducer.actions.setHiddenLeftMenuItems([
    //     CoreEnums.tabKeys.CLINICAL_ANALYTICS,
    //     CoreEnums.tabKeys.APPOINTMENTS_CALENDAR,
    //     CoreEnums.tabKeys.OCCUPATIONAL_HEALTH,
    //     CoreEnums.tabKeys.EXAMINATION_TEMPLATES,
    //     CoreEnums.tabKeys.SETTINGS
    //   ])
    // )
    // dispatch(coreReducer.actions.openTour())
  }

  const handleTourFinish = () => {
    dispatch(coreReducer.actions.closeTour())
    dispatch(coreReducer.actions.setHiddenLeftMenuItems([]))
    dispatch(coreReducer.actions.resetTourStep())
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          ...lightTheme(coreState.entity?.theme).token,
          motionUnit
        },
        components: {
          ...lightTheme(coreState.entity?.theme).components
        }
      }}
    >
      <div>
        {props.state.toolbar.enabled ? (
          <Toolbar
            isDarkTheme={isDarkTheme}
            themeToggle={themeToggle}
            {...props.state.leftMenu}
            {...props.state.rightMenu}
            {...props.state.toolbar}
            {...props.state.theme}
          />
        ) : null}
        {props.state.leftMenu.leftMenuEnabled && !deviceDetect().isBrowser ? (
          <LeftMenu {...props.state.leftMenu} {...props.state.theme} />
        ) : null}

        {props.state.rightMenu.rightMenuEnabled ? (
          <RightMenu {...props.state.rightMenu} {...props.state.theme} />
        ) : null}
        {props.state.tabNavigation.enabled ? (
          <TabNavigation {...props.state.tabNavigation} {...props.state.leftMenu} {...props} {...props.state.theme} />
        ) : (
          <RootContent />
        )}
      </div>
      {/* {coreState.entity && authState?.agiliteUser?.extraData?.inviteStatus ? (
        <Tour
          open={coreState.tourOpen}
          onClose={() => {}}
          steps={tourSteps}
          closable={false}
          current={coreState.tourStep}
          onFinish={() => {
            handleTourFinish()
          }}
        />
      ) : undefined} */}
    </ConfigProvider>
  )
}

export default Core
