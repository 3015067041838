// src/components/BluetoothManager.js
import React, { useState, useEffect, useRef } from 'react'
import BluetoothContext from '../BluetoothContext'
import Engine from '../lib/hc03lib'

const PATH_WORKER = `${process.env.PUBLIC_URL}/work.bundle.js`

const BluetoothManager = ({ children }) => {
  const [bleDevice, setBleDevice] = useState(null)
  const [bleCommandCharacteristic, setBleCommandCharacteristic] = useState(null)
  const [bleNotifyCharacteristic, setBleNotifyCharacteristic] = useState(null)

  // useRef to store the engine instance
  const engineRef = useRef(null)

  useEffect(() => {
    // Initialize the engine only once
    if (!engineRef.current) {
      console.log('Initializing engine...')
      engineRef.current = Engine.getEngine(PATH_WORKER)
      console.log('Engine initialized:', engineRef.current)
    }

    // Cleanup function to terminate engine if needed
    return () => {
      if (engineRef.current && engineRef.current.terminate) {
        console.log('Terminating engine...')
        engineRef.current.terminate()
      }
    }
  }, [])

  const requestDevice = async () => {
    try {
      console.log('Requesting Bluetooth Device...')
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: 'HC0' }],
        optionalServices: ['0000ff27-0000-1000-8000-00805f9b34fb', '0000180a-0000-1000-8000-00805f9b34fb']
      })
      setBleDevice(device)
      console.log('Device selected:', device.name)
      device.addEventListener('gattserverdisconnected', onDisconnected)
    } catch (error) {
      console.error('Device Request Error:', error)
    }
  }

  const connectDevice = async () => {
    if (!bleDevice) {
      console.warn('No device selected')
      return
    }
    try {
      console.log('Connecting to GATT server...')
      const server = await bleDevice.gatt.connect()
      console.log('Connected to GATT server')

      const service = await server.getPrimaryService('0000ff27-0000-1000-8000-00805f9b34fb')
      console.log('Service obtained:', service)

      const characteristics = await service.getCharacteristics()
      console.log('Characteristics obtained:', characteristics)

      characteristics.forEach((char) => {
        console.log('Characteristic UUID:', char.uuid)
        if (char.uuid === '0000fff1-0000-1000-8000-00805f9b34fb') {
          setBleCommandCharacteristic(char)
          console.log('bleCommandCharacteristic set:', char)
        } else if (char.uuid === '0000fff4-0000-1000-8000-00805f9b34fb') {
          setBleNotifyCharacteristic(char)
          console.log('bleNotifyCharacteristic set:', char)
          setupNotification(char)
        }
      })
    } catch (error) {
      console.error('Connection Error:', error)
    }
  }

  const setupNotification = async (characteristic) => {
    try {
      await characteristic.startNotifications()
      console.log('Starting notifications for characteristic:', characteristic.uuid)

      // Remove existing listener to prevent duplicates
      characteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged)

      // Add the new listener
      characteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged)
      console.log('Notification setup complete')
    } catch (error) {
      console.error('Notification Setup Error:', error)
    }
  }

  const handleCharacteristicValueChanged = (event) => {
    const value = event.target.value // DataView
    console.log('Received BLE Notification:', value)

    // Create a copy of the buffer to prevent detachment
    const bufferCopy = value.buffer.slice(0)

    // Send the copy to the engine
    if (engineRef.current) {
      try {
        engineRef.current.pushRawData(bufferCopy)
        console.log('Raw data pushed to engine:', bufferCopy)
      } catch (error) {
        console.error('Error pushing raw data to engine:', error)
      }
    } else {
      console.warn('Engine is not initialized')
    }
  }

  const onDisconnected = () => {
    console.log('Device disconnected')
    setBleDevice(null)
    setBleCommandCharacteristic(null)
    setBleNotifyCharacteristic(null)
  }

  // **New: Disconnect Function**
  const disconnectDevice = () => {
    if (bleDevice && bleDevice.gatt.connected) {
      console.log('Disconnecting from device...')
      bleDevice.gatt.disconnect()
      // Cleanup state
      setBleDevice(null)
      setBleCommandCharacteristic(null)
      setBleNotifyCharacteristic(null)
      console.log('Device disconnected successfully')
    } else {
      console.warn('No device is connected')
    }
  }

  return (
    <BluetoothContext.Provider
      value={{
        bleDevice,
        bleCommandCharacteristic,
        bleNotifyCharacteristic,
        requestDevice,
        connectDevice,
        disconnectDevice, // **Expose disconnect function via context**
        engine: engineRef.current
      }}
    >
      {children}
    </BluetoothContext.Provider>
  )
}

export default BluetoothManager
