import { Card, Col, DatePicker, Input, Modal, Select, Space, Table, Tag, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import CustomButton from '../../reusable-components/CustomButton'
import dayjs from 'dayjs'
import { handleError, hexToRGBA } from '../../lib/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import BookingFinalize from '../../Bookings/components/Search Engine Components/booking-finalize'
import { useSelector } from 'react-redux'
import { readPatients } from '../../Admin/patients/utils/utils'
import { debounce } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AddPatient from '../../Admin/system-users/components/add-patient'
import { ClockCircleOutlined } from '@ant-design/icons'
import { readAvailability, updateAvailability } from '../../Availability/components/utils/utils'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'
import SystemUsersTemplates from '../../Admin/system-users/utils/templates'

const CalendarSlotAction = ({ event, setEvent, refreshFunction, selectedProfessional, medicalProfessionals }) => {
  const [addingPatient, setAddingPatient] = useState(false)
  const { token } = theme.useToken()
  const servicesState = useSelector((state) => state.services.data)
  const authState = useSelector((state) => state.auth)
  const [service, setService] = useState(null)
  const [loading, setLoading] = useState(false)
  const [patient, setPatient] = useState(null)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [selectedMedicalProfessional, setSelectedMedicalProfessional] = useState(selectedProfessional)
  const [blockingActionBusy, setBlockingActionBusy] = useState(false)
  const [reason, setReason] = useState('')
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [validating, setValidating] = useState(false)

  const [bookingDate, setBookingDate] = useState(dayjs(event?.dateStr ? event?.dateStr : event.start))
  const [bookingStart, setBookingStart] = useState(
    event && event?.dateStr ? dayjs(event?.dateStr).format('HH:mm') : dayjs(event?.start).format('HH:mm')
  )
  // SEARCHING
  const [searchResults, setSearchResults] = useState([])
  const [searching, setSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    buildBookingDetails()
    // eslint-disable-next-line
  }, [patient, service, bookingDate, bookingStart])

  const buildBookingDetails = () => {
    let tmpBookingDetails = {
      dateCreated: dayjs(),
      service: service?._id,
      medicalProfRef: selectedMedicalProfessional?._id,
      userRef: patient?._id,
      endTime: dayjs(bookingStart, 'HH:mm').add(service?.timeslotInterval, 'minute').format('HH:mm'),
      bookingDate: dayjs(bookingDate).format('YYYY-MM-DD'),
      medicalProf: selectedMedicalProfessional,
      startTime: dayjs(bookingStart, 'HH:mm').format('HH:mm'),
      paymentMethods: ['Cash', 'Medical Aid', 'Card'],
      clinicRef: authState.agiliteUser.extraData.clinics[0]
    }
    setBookingDetails(tmpBookingDetails)
  }

  const onAfterSuccessfulBooking = () => {
    if (event) {
      setEvent(null)
    }
    refreshFunction()
  }

  useEffect(() => {
    handlePatientSearch()
    // eslint-disable-next-line
  }, [searchQuery])

  useEffect(() => {
    if (service && event?.extendedProps?.isAvailabilityEntry) {
      validateSlotAvailability()
    }
    // eslint-disable-next-line
  }, [service])

  const handleBlockout = async (isUnBlock) => {
    setBlockingActionBusy(true)
    try {
      const targetId = event.extendedProps.availabilityRecordId
      const targetAvailability = await readAvailability({ _id: targetId })
      const availabilityArray = targetAvailability[0].availability
      const targetDayIndex = availabilityArray.findIndex(
        (i) => i.date === dayjs(event.dateStr ? event.dateStr : event.start).format('YYYY-MM-DD')
      )
      const targetSlotIndex = availabilityArray[targetDayIndex].timeSlots.findIndex(
        (i) => i.linkedProfessional === selectedProfessional._id && i._id === event.id
      )
      if (availabilityArray[targetDayIndex].timeSlots[targetSlotIndex].blocked && !isUnBlock) {
        setBlockingActionBusy(false)
        refreshFunction()
        return message.info('Slot already blocked')
      }
      if (!availabilityArray[targetDayIndex].timeSlots[targetSlotIndex].blocked && isUnBlock) {
        setBlockingActionBusy(false)
        refreshFunction()
        return message.info('Slot already unblocked')
      }

      if (reason) availabilityArray[targetDayIndex].timeSlots[targetSlotIndex].blockReason = reason

      availabilityArray[targetDayIndex].timeSlots[targetSlotIndex].blocked = !isUnBlock

      if (availabilityArray[targetDayIndex].timeSlots[targetSlotIndex].blocked) {
        availabilityArray[targetDayIndex].timeSlots[
          targetSlotIndex
        ].blockedBy = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`

        await updateAvailability({ _id: targetId }, { availability: availabilityArray })
        message.success('Slot Blocked')
      } else {
        availabilityArray[targetDayIndex].timeSlots[
          targetSlotIndex
        ].unblockedBy = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
        await updateAvailability({ _id: targetId }, { availability: availabilityArray })
        message.success('Slot Unblocked')
      }

      setEvent(null)
      refreshFunction()
    } catch (e) {
      message.error(handleError(e))
    }
    setReason('')
    setBlockingActionBusy(false)
  }

  const handlePatientSearch = async () => {
    try {
      let tmpUsers = []
      let qry = { 'extraData.role.type': 'patient' }
      setSearching(true)
      try {
        if (searchQuery) {
          const searchTerms = searchQuery.trim().toLowerCase().split(/\s+/)

          // Prepare regex patterns for partial matching
          const regexPatterns = searchTerms.map((term) => new RegExp(term, 'i'))
          qry = {
            ...qry,
            $or: [
              // Match against concatenated firstName and lastName with partial regexes
              {
                $expr: {
                  $regexMatch: {
                    input: {
                      $concat: [{ $toLower: '$firstName' }, ' ', { $toLower: '$lastName' }]
                    },
                    regex: searchTerms.map((term) => `.*${term}.*`).join(''),
                    options: 'i'
                  }
                }
              },
              // Match individually against firstName and lastName with partial regexes
              {
                $or: [
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$firstName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  },
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$lastName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  }
                ]
              },

              { phoneNumber: { $regex: searchQuery, $options: 'i' } },
              { email: { $regex: searchQuery, $options: 'i' } },
              { physicalAddress: { $regex: searchQuery, $options: 'i' } },
              { idNo: { $regex: searchQuery, $options: 'i' } }
            ]
          }
          tmpUsers = await readSystemUsers(qry)
        }
        setSearchResults(tmpUsers)
      } catch (e) {
        message.error(handleError(e))
      }
      setSearching(false)
    } catch (e) {}
  }

  const handlePatientQuery = (query) => {
    setSearching(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )

  const getServices = () => {
    return servicesState.reduce((acc, serv) => {
      if (selectedMedicalProfessional?.extraData?.services?.includes(serv._id)) {
        acc.push(serv)
      }
      return acc
    }, [])
  }

  const generateTimeSlots = () => {
    const timeSlots = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        timeSlots.push(`${formattedHour}:${formattedMinute}`)
      }
    }
    return timeSlots
  }
  const blockReasonOptions = [
    'Scheduled Break (Bathroom/Eat)',
    'Time - Catch Up',
    'Notes - Catch Up',
    'Medical Rep Meeting',
    'Extended Consultation',
    'Procedure (Sutures/IV/Wound)'
  ]

  const validateSlotAvailability = async () => {
    setValidating(true)
    try {
      const targetId = event.extendedProps.availabilityRecordId
      const targetAvailability = await readAvailability({ _id: targetId })
      const availabilityArray = targetAvailability[0].availability
      const targetDayIndex = availabilityArray.findIndex(
        (i) => i.date === dayjs(event.dateStr ? event.dateStr : event.start).format('YYYY-MM-DD')
      )
      const targetSlotIndex = availabilityArray[targetDayIndex].timeSlots.findIndex(
        (i) => i.linkedProfessional === selectedProfessional._id && i._id === event.id
      )
      if (availabilityArray[targetDayIndex].timeSlots[targetSlotIndex].blocked) {
        setService(null)
        refreshFunction()
        setValidating(false)
        return message.info('Slot recently blocked out')
      }
    } catch (e) {
      message.error(handleError(e))
    }
    setReason('')
    setValidating(false)
  }
  return (
    <>
      <CustomRow>
        {addingPatient ? (
          <AddPatient isBooking setBookingPatient={setPatient} setAddingForBooking={setAddingPatient} />
        ) : (
          <Col span={24}>
            <Card
              headStyle={{
                borderRadius: 0
              }}
              style={{ borderRadius: 0 }}
              bodyStyle={{ padding: 0 }}
              type='inner'
              size='small'
              extra={
                <FontAwesomeIcon
                  fontSize={24}
                  style={{ cursor: 'pointer' }}
                  icon={faXmarkCircle}
                  color={token.colorError}
                  onClick={() => {
                    setAddingPatient(null)
                    setPatient(null)
                    setService(null)
                    if (event) setEvent(null)
                  }}
                />
              }
            >
              <CustomRow gutter={[0, 0]}>
                {loading || blockingActionBusy ? (
                  <Col span={24} style={{ padding: 12 }}>
                    <AgiliteSkeleton
                      skActive
                      spinnerTip={
                        blockingActionBusy
                          ? event?.extendedProps?.blocked
                            ? 'Unblocking slot...'
                            : 'Blocking Slot'
                          : 'Finalizing booking...'
                      }
                    />
                  </Col>
                ) : (
                  <>
                    {' '}
                    <Col xs={24} sm={24} md={16} lg={20}>
                      {event?.extendedProps?.blocked ? (
                        <Card
                          headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorError, 0.2) }}
                          bodyStyle={{ padding: 0 }}
                          style={{ borderRadius: 0, borderBottom: 'none' }}
                          type='inner'
                          size='small'
                          title={<center>{'BLOCKED'}</center>}
                        >
                          <CustomRow>
                            <Col span={24}>
                              <b>Reason: </b>
                              {event?.extendedProps?.blockReason}
                            </Col>
                          </CustomRow>
                        </Card>
                      ) : (
                        <Card
                          headStyle={{ borderRadius: 0 }}
                          bodyStyle={{ padding: 0 }}
                          style={{ borderRadius: 0, borderBottom: 'none' }}
                          type='inner'
                          size='small'
                          title={<center>{event ? 'NEW BOOKING' : 'BOOKING DETAILS'}</center>}
                        >
                          <CustomRow gutter={[0, 0]}>
                            <Col span={24}>
                              <Card
                                headStyle={{ borderRadius: 0 }}
                                style={{ borderRadius: 0, borderBottom: 'none' }}
                                type='inner'
                                size='small'
                                title={'DATE AND TIME'}
                              >
                                <DatePicker
                                  disabled={event?.extendedProps?.availabilityRecordId ? true : false}
                                  format='DD MMMM YYYY'
                                  allowClear={false}
                                  value={bookingDate}
                                  onChange={(date) => {
                                    setBookingDate(date)
                                  }}
                                  style={{ width: '100%' }}
                                />
                                <br />
                                <Select
                                  disabled={event?.extendedProps?.availabilityRecordId ? true : false}
                                  value={bookingStart}
                                  onChange={(time) => setBookingStart(time)}
                                  placeholder='- select time -'
                                  suffixIcon={<ClockCircleOutlined />}
                                  showSearch
                                  style={{ width: '100%', marginTop: 12 }}
                                  options={generateTimeSlots().map((option) => ({ label: option, value: option }))}
                                />
                              </Card>
                            </Col>
                            {bookingStart && bookingDate ? (
                              <Col span={24}>
                                <Card
                                  headStyle={{ borderRadius: 0 }}
                                  style={{ borderRadius: 0 }}
                                  size='small'
                                  title='DOCTOR'
                                >
                                  {selectedMedicalProfessional ? (
                                    <Tag>
                                      <Space>
                                        <p>
                                          {selectedMedicalProfessional.firstName} {selectedMedicalProfessional.lastName}
                                        </p>
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            setSelectedMedicalProfessional(null)
                                            setPatient(null)
                                            setService(null)
                                          }}
                                          icon={faX}
                                          color={token.colorError}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </Space>
                                    </Tag>
                                  ) : (
                                    <>
                                      <Select
                                        showSearch
                                        placeholder='- Select Medical Professional -'
                                        style={{ marginBottom: 12, width: '100%' }}
                                        onChange={(value) => {
                                          setSelectedMedicalProfessional(JSON.parse(value))
                                        }}
                                        options={medicalProfessionals.map((medprof) => ({
                                          value: JSON.stringify(medprof),
                                          label: `${medprof.extraData.profession === 'doctor' ? 'Dr. ' : ''}${
                                            medprof.firstName
                                          } ${medprof.lastName}`
                                        }))}
                                      />
                                    </>
                                  )}
                                </Card>
                              </Col>
                            ) : undefined}
                            {selectedMedicalProfessional ? (
                              <Col span={24}>
                                <Card
                                  headStyle={{ borderRadius: 0 }}
                                  style={{ borderRadius: 0 }}
                                  size='small'
                                  title='PATIENT'
                                >
                                  {patient ? (
                                    <Tag>
                                      <Space>
                                        <p>
                                          {patient.firstName} {patient.lastName}
                                        </p>
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            setPatient(null)
                                            setService(null)
                                          }}
                                          icon={faX}
                                          color={token.colorError}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </Space>
                                    </Tag>
                                  ) : (
                                    <>
                                      <Input
                                        placeholder='Search by name or ID'
                                        style={{ marginBottom: 12 }}
                                        onChange={(e) => {
                                          handlePatientQuery(e.target.value)
                                        }}
                                      />
                                      <Table
                                        size='small'
                                        bordered
                                        onRow={(record, rowIndex) => {
                                          return {
                                            onClick: () => {
                                              setSearchQuery('')
                                              setPatient(record)
                                            }
                                          }
                                        }}
                                        loading={searching}
                                        dataSource={searchResults}
                                        columns={SystemUsersTemplates.columnTemplate(
                                          [],
                                          'patient',
                                          [],
                                          token,
                                          null,
                                          null,
                                          true
                                        ).filter((i) => !i.hidden)}
                                      />
                                    </>
                                  )}
                                </Card>
                              </Col>
                            ) : undefined}
                            {patient ? (
                              <Col span={24}>
                                <Card
                                  headStyle={{ borderRadius: 0 }}
                                  style={{ borderRadius: 0 }}
                                  size='small'
                                  title='SERVICE'
                                >
                                  {service ? (
                                    <Tag>
                                      <Space>
                                        <p>{service.name}</p>
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            setService(null)
                                          }}
                                          icon={faX}
                                          color={token.colorError}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </Space>
                                    </Tag>
                                  ) : (
                                    <>
                                      <Table
                                        showHeader={false}
                                        onRow={(record, rowIndex) => {
                                          return {
                                            onClick: () => {
                                              setService(record)
                                            }
                                          }
                                        }}
                                        dataSource={selectedMedicalProfessional ? getServices() : undefined}
                                        columns={[{ render: (record) => `${record.name}` }]}
                                      />
                                    </>
                                  )}
                                </Card>
                              </Col>
                            ) : undefined}
                            {validating ? (
                              <>
                                {' '}
                                <Col span={24}>
                                  <AgiliteSkeleton skActive spinnerTip='Validating slot...' />
                                </Col>
                              </>
                            ) : (
                              <>
                                {patient && service && bookingDetails.service ? (
                                  <Col span={24}>
                                    <Card
                                      size='small'
                                      type='inner'
                                      title='FINALIZE'
                                      headStyle={{ borderRadius: 0 }}
                                      style={{ borderRadius: 0 }}
                                      bodyStyle={{ padding: 0 }}
                                    >
                                      <div style={{ marginTop: 2 }}>
                                        <BookingFinalize
                                          setBookingDetails={setBookingDetails}
                                          bookingDetails={bookingDetails}
                                          onAfterSuccessfulBooking={onAfterSuccessfulBooking}
                                          isHomeVisit={false}
                                          isVirtualVisit={false}
                                          patient={patient}
                                          setReceptionLoadIndicator={setLoading}
                                          isReceptionBooking
                                        />
                                      </div>
                                    </Card>
                                  </Col>
                                ) : undefined}
                              </>
                            )}
                          </CustomRow>
                        </Card>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4}>
                      <Card
                        headStyle={{ borderRadius: 0 }}
                        style={{ borderRadius: 0 }}
                        bodyStyle={{ padding: 0 }}
                        type='inner'
                        size='small'
                        title={<center>ACTIONS</center>}
                      >
                        {event?.extendedProps?.blocked ? undefined : (
                          <CustomButton
                            type='success'
                            style={{ width: '100%', borderRadius: 0 }}
                            text='ADD PATIENT'
                            disabled={loading}
                            onClick={() => {
                              setAddingPatient(true)
                            }}
                          />
                        )}
                        {event?.extendedProps?.isAvailabilityEntry ? (
                          <CustomButton
                            type='warning'
                            style={{ width: '100%', borderRadius: 0 }}
                            text={event?.extendedProps?.blocked ? 'UNBLOCK' : 'BLOCK'}
                            disabled={loading || blockingActionBusy}
                            onClick={() => {
                              if (event.extendedProps.blocked) {
                                handleBlockout(event.extendedProps.blocked)
                              } else {
                                setShowReasonModal(true)
                              }
                            }}
                          />
                        ) : undefined}
                      </Card>
                    </Col>
                  </>
                )}
              </CustomRow>
            </Card>
          </Col>
        )}
        <Modal
          title='Please provide a reason for the block out.'
          open={showReasonModal}
          destroyOnClose
          okButtonProps={{ disabled: !reason, danger: true }}
          onOk={() => {
            handleBlockout(false, reason)
            setShowReasonModal(false)
          }}
          okText='Block'
          onCancel={() => {
            setReason('')
            setShowReasonModal(false)
          }}
          cancelButtonProps={{ style: { background: 'transparent' } }}
          cancelText='Cancel'
        >
          <Select
            placeholder='- Select Reason -'
            value={reason ? reason : null}
            style={{ width: '100%' }}
            onChange={(value) => setReason(value)}
            options={blockReasonOptions.map((r) => ({ label: r, value: r }))}
          />
        </Modal>
      </CustomRow>
    </>
  )
}

export default CalendarSlotAction
