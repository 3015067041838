import Agilite from 'agilite'
import CoreEnums from '../../../../core/utils/enums'
import SystemUsersEnums from './enums'
import store from '../../../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const countSystemUsers = (qry) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          SystemUsersEnums.profileKeys.AUTH_USERS,
          SystemUsersEnums.routeKeys.COUNT,
          {
            filter: JSON.stringify({ ...qry, 'extraData.entities': { $in: [store.getState().core.entity._id] } })
          }
        )
        resolve(response.data.result)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readSystemUsers = (qry, page = null, pageLimit = null, projection, fetchAll) => {
  return new Promise((resolve, reject) => {
    let usersResponse = null
    let mainMembersResponse = null
    let errorMessage = null

    ;(async () => {
      try {
        if (store.getState().auth.agiliteUser.extraData.role.type !== CoreEnums.userRoles.SUPERADMIN && !fetchAll) {
          qry = { ...qry, 'extraData.entities': { $in: [store.getState().core.entity._id] } }
        }

        // Fetch users
        usersResponse = await agilite.Connectors.execute(
          SystemUsersEnums.profileKeys.AUTH_USERS,
          SystemUsersEnums.routeKeys.FIND,
          {
            filter: JSON.stringify(qry),
            page,
            pageLimit,
            projection
          }
        )

        // Store all mainmember ids to be fetched
        const userIds = usersResponse.data.map((user) => user.mainMemberId).filter(Boolean)

        // Fetch main members for dependants
        if (userIds.length > 0) {
          mainMembersResponse = await agilite.Connectors.execute(
            SystemUsersEnums.profileKeys.AUTH_USERS,
            SystemUsersEnums.routeKeys.FIND,
            {
              filter: JSON.stringify({ _id: { $in: userIds } }),
              projection: '_id idNo phoneNumber email dependants firstName lastName'
            }
          )
          // Set mainmember prop for dependants
          for (const user of usersResponse.data) {
            const mainMember = mainMembersResponse.data.find((item) => item._id === user.mainMemberId)
            if (mainMember) {
              user.tmpPhoneNumber = mainMember.phoneNumber
              user.tmpEmail = mainMember.email
              user.mainMemberName = `${mainMember.firstName} ${mainMember.lastName}`
              user.mainMemberIdNo = mainMember.idNo
            }
          }
        }
        resolve(usersResponse.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
