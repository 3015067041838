import React, { useEffect, useState } from 'react'

import { Col, Layout, Menu, Modal, Space, Tabs, theme } from 'antd'
import App from '../../custom/app'
import Sider from 'antd/es/layout/Sider'
import { HomeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import CoreReducer from '../utils/reducer'
import CoreEnums from '../utils/enums'
import { batch, useDispatch, useSelector } from 'react-redux'
import { deviceDetect } from 'react-device-detect'
import authReducer from '../../custom/Auth/utils/reducer'
import { firebaseAuth } from '../..'
import { signOut } from 'firebase/auth'
import { handleError } from '../../custom/lib/utils'
import Router from '../../custom/Auth/components/router'
import { Content } from 'antd/es/layout/layout'
import CustomRow from '../../custom/reusable-components/CustomRow'
import coreReducer from '../utils/reducer'

const TabNavigation = (props) => {
  const dispatch = useDispatch()
  const CustomRootContent = props.state.rootContent || App
  const { token } = theme.useToken()
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)

  const [collapsed, setCollapsed] = useState()

  function getItem(label, key, icon, children, newItem) {
    let tmpLabel = label

    if (newItem) {
      tmpLabel = (
        <Space>
          {label}
          <sup style={{ fontSize: 13, color: 'yellow' }}>new</sup>
        </Space>
      )
    }

    if (children && children.length > 0) {
      for (const child of children) {
        let tmpChildLabel = child.label

        if (child.new) {
          tmpChildLabel = (
            <Space>
              {child.label}
              <sup style={{ fontSize: 13, color: 'yellow' }}>new</sup>
            </Space>
          )

          child.new = false
        }

        child.label = tmpChildLabel
      }
    }

    return {
      key,
      icon,
      children,
      label: tmpLabel
    }
  }

  const handleSignOut = async () => {
    try {
      await signOut(firebaseAuth)
      batch(() => {
        dispatch(CoreReducer.actions.resetTabs())
        dispatch(CoreReducer.actions.resetState())
        dispatch(CoreReducer.actions.enableDisableLeftMenu(false))
        dispatch(CoreReducer.actions.enableDisableRightMenu(false))
        dispatch(CoreReducer.actions.enableDisableTabs(false))
        dispatch(CoreReducer.actions.setRootContent(Router))
        dispatch(authReducer.actions.setUser(null))
        dispatch(authReducer.actions.setAgiliteUser(null))
        dispatch(authReducer.actions.setLoggedIn(false))
      })
    } catch (e) {
      handleError(e, true)
    }
  }

  const [sliderWidth, setSliderWidth] = useState()
  useEffect(() => {
    switch (collapsed) {
      case true:
        dispatch(coreReducer.actions.setSideBarExpanded(false))
        setSliderWidth(80)
        break
      case false:
        dispatch(coreReducer.actions.setSideBarExpanded(true))
        setSliderWidth(275)
        break
      default:
        dispatch(coreReducer.actions.setSideBarExpanded(true))
        setSliderWidth(275)
    }
    // eslint-disable-next-line
  }, [collapsed])

  const handleCheckTabKey = () => {
    let key = null

    props.menuItems().forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (coreState.tabNavigation.activeKey.indexOf(child.key) > -1) {
            key = child.key
          }
        })
      } else {
        if (coreState.tabNavigation.activeKey.indexOf(item.key) > -1) {
          key = item.key
        }
      }
    })

    if (key) {
      return key
    } else {
      return coreState.tabNavigation.activeKey
    }
  }

  const filterMenuItems = () => {
    let filteredItems = []
    let menuItems = []

    const menuItemIsValid = (menuItem) => {
      let valid = true
      if (menuItem.additionalValidatiors && menuItem.additionalValidatiors.length > 0) {
        menuItem.additionalValidatiors.forEach((validator) => {
          valid = validator()
        })
      }
      return valid
    }

    props.menuItems().forEach((item) => {
      if (
        (item.visibleTo.includes(authState.agiliteUser?.extraData?.role?.type) ||
          item.visibleTo.includes(authState.agiliteUser?.extraData?.profession)) &&
        menuItemIsValid(item)
      ) {
        if (item.propInEntity && item.enabledProp) {
          if (coreState.entity[item.propInEntity][item.enabledProp]) {
            if (!coreState.hiddenLeftMenuItems.includes(item.key)) {
              filteredItems.push(item)
            }
          }
        } else {
          if (!coreState.hiddenLeftMenuItems.includes(item.key)) {
            filteredItems.push(item)
          }
        }
      }

      item.children = item.children?.filter((child) => {
        return (
          (child.visibleTo.includes(authState.agiliteUser?.extraData?.role?.type) ||
            item.visibleTo.includes(authState.agiliteUser?.extraData?.profession)) &&
          menuItemIsValid(child)
        )
      })
    })

    menuItems = filteredItems.map((item) => {
      return getItem(item.label, item.key, item.icon ? item.icon : <HomeOutlined />, item.children, item.new)
    })

    return menuItems
  }

  return (
    <Layout style={{ margin: 0, position: 'sticky', top: 0, zIndex: 5 }}>
      {deviceDetect().isBrowser ? (
        // LEFT MENU (leftMenu) for Browsers
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={sliderWidth}
          style={{
            borderRight: `1px ${token.colorBorderSecondary} solid`,
            overflow: 'auto',
            zIndex: 9,
            position: 'relative'
          }}
          trigger={
            collapsed ? (
              <RightOutlined />
            ) : (
              <>
                <LeftOutlined /> Collapse
              </>
            )
          }
        >
          <Menu
            // _internalDisableMenuItemTitleTooltip
            onClick={(event) => {
              if (event.key === CoreEnums.tabKeys.SIGN_OUT) {
                Modal.confirm({
                  title: 'Confirmation',
                  content: `Are you sure you want to sign out of the ${coreState.entity.name} Portal?`,
                  okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
                  cancelButtonProps: { style: { background: token.colorError, color: 'white' } },
                  onOk: () => handleSignOut(),
                  okText: 'Yes',
                  cancelText: 'No',
                  className: token.themeControl
                })
              } else {
                if (coreState.tourOpen) {
                  dispatch(CoreReducer.actions.nextTourStep())
                }

                dispatch(CoreReducer.actions.menuItemClick(event))
              }
            }}
            onOpenChange={() => {
              if (coreState.tourOpen) {
                dispatch(CoreReducer.actions.nextTourStep())
              }
            }}
            defaultSelectedKeys={['home']}
            selectedKeys={handleCheckTabKey()}
            mode='inline'
            items={filterMenuItems()}
            style={{ minHeight: '100%', position: 'fixed', left: 0, top: 0, width: sliderWidth, paddingTop: '64px' }}
          />
        </Sider>
      ) : undefined}

      <Content>
        <Tabs
          id='scroll-detection'
          className='override-nav-margin'
          style={{
            height: 'calc(100vh - 112px)',
            width: '100%',
            transition: 'margin 300ms ease',
            overflowY: 'scroll'
          }}
          activeKey={props.activeKey}
          animated={props.animated}
          type='editable-card'
          hideAdd
          centered={true}
          onChange={props.onTabChange}
          onEdit={props.onTabClose}
          tabBarStyle={{ display: 'none' }}
          items={[
            { key: props.rootTabKey, label: props.rootTabTitle, children: <CustomRootContent />, closable: false },
            ...props.tabs
          ]}
        />

        {deviceDetect().isMobile ? undefined : (
          <CustomRow
            style={{
              position: 'fixed',
              zIndex: 1000000,
              bottom: 0,
              width: '100%',
              boxSizing: 'border-box',
              background: 'white',
              height: 48,
              alignItems: 'center',
              boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.255)'
            }}
          >
            <Col span={24}>
              <center style={{ marginRight: sliderWidth, transition: 'margin 300ms ease' }}>
                Powered by <a href='https://meta-health.co.za/'>Meta-Health</a>
              </center>
            </Col>
          </CustomRow>
        )}
      </Content>
    </Layout>
  )
}

export default TabNavigation
