import Agilite from 'agilite'
import CarePlansEnums from './enums'
import CoreEnums from '../../../core/utils/enums'
import store from '../../../store'
import { isDoctor } from '../../lib/profile-utils'
import SystemUsersEnums from '../../Admin/system-users/utils/enums'
import { message } from 'antd'
import dayjs from 'dayjs'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readCarePlanTemplates = (filters) => {
  return new Promise((resolve, reject) => {
    let qry = {}
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        if (isDoctor()) {
          // Doctor Specific
          qry.doctorRef = store.getState().auth.agiliteUser._id
        } else {
          // Global entity template
          qry.entityRef = store.getState().core.entity._id
        }
        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_TEMPLATES,
          CarePlansEnums.routeKeys.READ,
          {
            filter: JSON.stringify({ ...qry, ...filters }),
            page: null,
            pageLimit: null
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createCarePlanTemplate = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()

    ;(async () => {
      try {
        data.createdAt = timestamp
        data.modifiedAt = timestamp
        data.createdBy = store.getState().auth.agiliteUser._id
        data.modifiedBy = store.getState().auth.agiliteUser._id
        if (isDoctor()) {
          data.doctorRef = store.getState().auth.agiliteUser._id
        } else {
          data.entityRef = store.getState().core.entity._id
        }

        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_TEMPLATES,
          CarePlansEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const updateCarePlanTemplate = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()

    ;(async () => {
      try {
        data.modifiedAt = timestamp
        data.modifiedBy = store.getState().auth.agiliteUser._id

        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_TEMPLATES,
          CarePlansEnums.routeKeys.UPDATE,
          {
            recordId: data._id,
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
export const readCarePlanRecords = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_RECORDS,
          CarePlansEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )

        resolve(response)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
export const fetchEnrollment = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let carePlanResponse = null
    let errorMessage = null
    let userProfiles = null

    ;(async () => {
      try {
        carePlanResponse = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_RECORDS,
          CarePlansEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )

        const teamIds = []
        carePlanResponse.data.forEach((i) => i.teamMembers?.forEach((teamMember) => teamIds.push(teamMember)))

        userProfiles = await agilite.Connectors.execute(
          SystemUsersEnums.profileKeys.AUTH_USERS,
          SystemUsersEnums.routeKeys.FIND,
          {
            filter: JSON.stringify({
              _id: { $in: [...carePlanResponse.data.map((i) => i.userRef), ...teamIds] }
            })
          }
        )

        response = carePlanResponse.data.map((carePlan) => {
          const patientProfile = userProfiles.data.find((profile) => profile._id === carePlan.userRef)
          const teamMemberProfiles = userProfiles.data.filter((profile) => teamIds.includes(profile._id))

          return {
            patientProfile,
            team: teamMemberProfiles.filter((i) => carePlan.teamMembers.includes(i._id)),
            ...carePlan
          }
        })

        resolve(response)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const enrollPatient = (patient, teamMembers, template, dateOverride) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()

    const createComplianceEntries = (trackers) => {
      const complianceEntries = []
      trackers.forEach((tracker) => {
        let dueDate = null
        for (let i = 0; i < tracker.count; i++) {
          if (dueDate) {
            dueDate = dayjs(dueDate).add(tracker.intervalDays, 'days').format('YYYY MM DD')
          } else {
            dueDate = dayjs(dateOverride).add(tracker.intervalDays, 'days').format('YYYY MM DD')
          }
          complianceEntries.push({
            trackerType: tracker.trackerType,
            trackerLabel: tracker.dataLabel,
            count: tracker.count,
            key: tracker.dataKey, // Corresponds to 'dataKey'
            dueDate: dueDate,
            type: tracker.trackerType, // Corresponds to 'trackerType'
            value: false, // Initially set to false (not compliant yet)
            lastUpdated: new Date(), // Set the current date as lastUpdated
            notes: '' // Empty notes field for now
          })
        }
      })

      return complianceEntries
    }
    const payload = {
      audit: {
        createdAt: timestamp,
        createdBy: store.getState().auth.agiliteUser._id,
        modifiedAt: timestamp,
        modifiedBy: store.getState().auth.agiliteUser._id
      },
      userRef: patient._id,
      enrolledOn: timestamp,
      enrolledBy: store.getState().auth.agiliteUser._id,
      templateRef: template._id,
      teamMembers: [store.getState().auth.agiliteUser._id, ...teamMembers.map((i) => i._id)],
      compliance: createComplianceEntries(template.tracking),
      status: 2 // 'active', 'completed', 'suspended'
    }
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_RECORDS,
          CarePlansEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(payload)
          }
        )
        resolve(response?.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const cancelEnrollement = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_RECORDS,
          CarePlansEnums.routeKeys.UPDATE,
          {
            filter: JSON.stringify({ _id: recordId }),
            data: JSON.stringify({ status: 0 })
          }
        )

        resolve(response)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const alreadyEnrolled = (patientId, templateId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_RECORDS,
          CarePlansEnums.routeKeys.COUNT,
          {
            filter: JSON.stringify({ userRef: patientId, templateRef: templateId })
          }
        )
        resolve(response?.data?.result)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const linkTeamMember = (userId, recordId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        // Fetch the record by ID first to check if the userId exists in teamMember array
        const records = await readCarePlanRecords({ _id: recordId })

        if (!records.data[0]) {
          message.error('Record not found')
        }

        // Check if the userId already exists in the teamMember array
        if (records.data[0].teamMembers.includes(userId)) {
          message.error('User is already a team member')
        }
        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_RECORDS,
          CarePlansEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify({ $push: { teamMembers: userId } })
          }
        )
        resolve(response?.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const teamMemberAlreadyLinked = (patientId, templateId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          CarePlansEnums.profileKeys.CARE_PLANS_RECORDS,
          CarePlansEnums.routeKeys.COUNT,
          {
            filter: JSON.stringify({ userRef: patientId, templateRef: templateId })
          }
        )
        resolve(response?.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
