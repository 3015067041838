import { Modal } from 'antd'
import React from 'react'
import ViewingRecord from './ViewingRecord'
import CalendarSlotAction from './CalendarSlotAction'
import '../style.css'

const FullCalendarContentModal = ({
  modalOpen,
  event,
  setModalData,
  refreshFunction,
  selectedProfessional,
  medicalProfessionals
}) => {
  const recordData = event?.extendedProps?.recordData
  const isNew = !recordData
  const selectedMedicalProfessionalRecord = medicalProfessionals.find((medProf) => medProf._id === selectedProfessional)
  return (
    <Modal
      width={1920}
      footer={false}
      destroyOnClose
      maskClosable={false}
      closable={false}
      onCancel={() => {}}
      open={modalOpen} // Corrected 'open' prop to 'visible'
    >
      {event ? (
        <>
          {isNew ? (
            <CalendarSlotAction
              selectedProfessional={selectedMedicalProfessionalRecord}
              medicalProfessionals={medicalProfessionals}
              event={event}
              setEvent={setModalData}
              refreshFunction={refreshFunction}
            />
          ) : (
            <ViewingRecord
              data={recordData}
              setModalOpen={setModalData}
              setData={setModalData}
              medicalProfessionals={medicalProfessionals}
              refreshFunction={refreshFunction}
            />
          )}
        </>
      ) : undefined}
      {/* {viewingRecord && viewingData ? (
      <>
        {viewingData?.isOpen ? (
          <>
            <CalendarSlotAction
              setEvent={setViewingData}
              event={viewingData}
              refreshFunction={fetchAppointments}
              medicalProfessionals={medicalProfessionals}
              setIsNewAction={setIsNewAction}
              medicalProfessional={medicalProfessionals.find((i) => i._id === viewingData?.resourceId)}
            />
          </>
        ) : (
          <ViewingRecord
            medicalProfessionals={medicalProfessionals}
            setData={setViewingData}
            setIsNewAction={setIsNewAction}
            event={viewingData}
            data={viewingData?.recordData}
            refreshFunction={fetchAppointments}
          />
        )}
      </>
    ) : null}
    {isNewAction ? (
      <>
        <CalendarSlotAction
          setIsNewAction={setIsNewAction}
          refreshFunction={fetchAppointments}
          medicalProfessionals={medicalProfessionals}
        />
      </>
    ) : null} */}
    </Modal>
  )
}

export default FullCalendarContentModal
