import React, { useState, memo, useEffect, useRef } from 'react'
import { Row, Col, Form, Input, Button, message, Tooltip, Modal, theme, Switch, Collapse, Select } from 'antd'
import { useDispatch } from 'react-redux'

import coreReducer from '../../../../core/utils/reducer'
import Theme from '../../../../core/utils/theme'
import Templates from '../utils/templates'
import { createEntity, readEntities, readPaymentMethods, readProcedures, updateEntity } from '../utils/utils'
import { ContainerCard, PrimaryCard } from '../../../reusable-components/AgiliteCards'
import { allTrim, handleError, specialFieldValidation } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import CoreEnums from '../../../../core/utils/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import MetaClinicImage from '../../../../assets/images/landing-image.png'
import GoogleAddress from '../../../reusable-components/GoogleAddress'

const _BasicForm = ({ data, isNewRecord, refreshView }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [isUrlRoute, setIsUrlRoute] = useState(data.isUrlRoute)
  const [primaryColor, setPrimaryColor] = useState(data.theme?.primaryColor || '#10B2E1')
  const [secondaryColor, setSecondaryColor] = useState(data.theme?.secondaryColor || '#F14F8B')
  const [entities, setEntities] = useState([])
  const [entitiesLoading, setEntitiesLoading] = useState(false)
  const [virtualConsultsEnabled, setVirtualConsultsEnabled] = useState(data.virtualConsultations?.enabled || false)
  const [specialFormState, setSpecialFormState] = useState([])
  const imageInputRef = useRef(null)
  const iconInputRef = useRef(null)
  const [image, setImage] = useState(data.entityLogo ? data.entityLogo : MetaClinicImage)
  const [iconImage, setIconImage] = useState(data.entityIcon ? data.entityIcon : MetaClinicImage)
  const [metaLinkEnabled, setMetaLinkEnabled] = useState(data.vitalLink?.enabled || false)
  const [localVitalLink, setLocalVitalLink] = useState(data.vitalLink?.localVitalLink || false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [procedures, setProcedures] = useState([])
  const { token } = theme.useToken()
  const [clientForm] = Form.useForm()

  useEffect(() => {
    const tmpSpecialFormState = []

    for (const [key, value] of Object.entries(data)) {
      tmpSpecialFormState.push({ key: key, value: value })
    }
    setSpecialFormState(tmpSpecialFormState)
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async () => {
    let record = allTrim(clientForm.getFieldsValue())
    record.entityIcon = iconImage
    record.entityLogo = image

    try {
      setLoading(true)

      if (isNewRecord) {
        await createEntity({ ...record })
      } else {
        await updateEntity({ _id: data._id, ...record })
      }

      closeTab()
    } catch (e) {
      if (e === 'Validation failed') {
        message.error(`Entity with this ${isUrlRoute ? 'URL Route' : 'Custom URL'} already exists`)
      } else {
        message.error(handleError(e, true))
      }
    }

    setLoading(false)
  }

  const closeTab = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNewRecord ? `${CoreEnums.tabKeys.ENTITIES}_new` : `${CoreEnums.tabKeys.ENTITIES}_${data._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const handleFileChange = async (e, type) => {
    let file = null

    try {
      if (!e.target.files) {
        return
      }

      file = await readBase64(e.target.files[0])
      switch (type) {
        case 'image':
          setImage(file)
          break
        default:
          setIconImage(file)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const readBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleUploadClick = (type) => {
    let promptText = null
    let enableCancel = false

    if (data?.entityLogo) {
      switch (type) {
        case 'image':
          promptText = "Do you want Remove or Change the Entity's Logo?"
          break
        default:
          promptText = "Do you want Remove or Change the Entity's icon?"
      }
      enableCancel = true
    } else {
      switch (type) {
        case 'image':
          promptText = "Do you want Remove or Change the Entity's Logo?"
          break
        default:
          promptText = "Do you want Remove or Change the Entity's Icon?"
      }
      enableCancel = false
    }

    Modal.confirm({
      title: 'Confirmation',
      content: promptText,
      okText: 'Change',
      cancelText: 'Remove',
      onOk: () => {
        switch (type) {
          case 'image':
            imageInputRef.current?.click()
            break
          default:
            iconInputRef.current?.click()
        }
      },

      onCancel: () => {
        switch (type) {
          case 'image':
            setImage(MetaClinicImage)
            break
          default:
            setIconImage(MetaClinicImage)
        }
      },
      cancelButtonProps: { disabled: !enableCancel, danger: true },
      closable: true,
      maskClosable: true,
      className: token.themeControl
    })
  }

  useEffect(() => {
    handleEntitySearch()
    handleGetPaymentMethods()
    handleGetProcedures()
  }, [])

  const handleEntitySearch = async () => {
    setEntitiesLoading(true)

    try {
      const response = await readEntities()
      const tmpEntities = []

      for (const entity of response) {
        tmpEntities.push({ label: entity.name, value: entity._id })
      }

      setEntities(tmpEntities)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setEntitiesLoading(false)
  }

  const handleGetPaymentMethods = async () => {
    setEntitiesLoading(true)

    try {
      const response = await readPaymentMethods()
      setPaymentMethods(response)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setEntitiesLoading(false)
  }

  const handleGetProcedures = async () => {
    setEntitiesLoading(true)

    try {
      const response = await readProcedures()
      setProcedures(response)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setEntitiesLoading(false)
  }

  const panelItems = [
    {
      key: 'general',
      label: 'General',
      children: (
        <Row justify='center'>
          <Col span={24}>
            <CustomRow justify='center' gutter={[8, 8]} style={{ alignItems: 'flex-end' }}>
              <Col>
                <Tooltip title='Edit Entity Logo'>
                  <input
                    type='file'
                    ref={imageInputRef}
                    onChange={(e) => {
                      handleFileChange(e, 'image')
                    }}
                    style={{ display: 'none' }}
                  />
                  <div style={{ display: 'grid' }}>
                    {/* eslint-disable-next-line */}
                    <img
                      referrerPolicy='no-referrer'
                      alt='User Image'
                      style={{ gridColumn: 1, gridRow: 1, borderRadius: '5%', width: 128 }}
                      src={image}
                    />
                    <div
                      style={{
                        gridColumn: 1,
                        gridRow: 1,
                        boxSizing: 'border-box',
                        display: 'flex',
                        justify: 'center',
                        alignItems: 'center',
                        width: 128,
                        position: 'relative',
                        zIndex: 1,
                        opacity: 0.4,
                        borderRadius: '5%',
                        backgroundColor: token.colorBgMask,
                        cursor: 'pointer'
                      }}
                      onClick={
                        loading
                          ? null
                          : () => {
                              handleUploadClick('image')
                            }
                      }
                    >
                      <FontAwesomeIcon color={token.colorWhite} icon={faEdit} style={{ width: 128 }} size='2x' />
                    </div>
                  </div>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title='Edit Entity Icon'>
                  <input
                    type='file'
                    ref={iconInputRef}
                    onChange={(e) => {
                      handleFileChange(e, 'icon')
                    }}
                    style={{ display: 'none' }}
                  />
                  <div style={{ display: 'grid' }}>
                    {/* eslint-disable-next-line */}
                    <img
                      referrerPolicy='no-referrer'
                      alt='User Image'
                      style={{ gridColumn: 1, gridRow: 1, borderRadius: '5%', width: 64 }}
                      src={iconImage}
                    />
                    <div
                      style={{
                        gridColumn: 1,
                        gridRow: 1,
                        boxSizing: 'border-box',
                        display: 'flex',
                        justify: 'center',
                        alignItems: 'center',
                        width: 64,
                        position: 'relative',
                        zIndex: 1,
                        opacity: 0.4,
                        borderRadius: '5%',
                        backgroundColor: token.colorBgMask,
                        cursor: 'pointer'
                      }}
                      onClick={
                        loading
                          ? null
                          : () => {
                              handleUploadClick('icon')
                            }
                      }
                    >
                      <FontAwesomeIcon color={token.colorWhite} icon={faEdit} style={{ width: 64 }} size='2x' />
                    </div>
                  </div>
                </Tooltip>
              </Col>
            </CustomRow>
          </Col>
          <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
            <Form.Item
              name={Templates.dataModel.name.key}
              label={Templates.dataModel.name.label}
              rules={[
                {
                  required: Templates.dataModel.name.required,
                  message: Templates.dataModel.name.validationMsg
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                    key: 'name',
                    event: e,
                    validationConfig: {
                      letters: { allowed: true, onlyCaps: false },
                      numbers: false,
                      spaces: true
                    }
                  })
                }}
                maxLength={50}
                placeholder={Templates.dataModel.name.placeholder}
              />
            </Form.Item>
            <Form.Item
              name={Templates.dataModel.description.key}
              label={Templates.dataModel.description.label}
              rules={[
                {
                  required: Templates.dataModel.description.required,
                  message: Templates.dataModel.description.validationMsg
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                    key: 'description',
                    event: e,
                    validationConfig: {
                      letters: { allowed: true, onlyCaps: false },
                      numbers: false,
                      spaces: true
                    }
                  })
                }}
                maxLength={50}
                placeholder={Templates.dataModel.name.placeholder}
              />
            </Form.Item>
            <Form.Item
              name={Templates.dataModel.isUrlRoute.key}
              label={Templates.dataModel.isUrlRoute.label}
              valuePropName='checked'
            >
              <Switch checkedChildren='Yes' unCheckedChildren='No' onChange={(checked) => setIsUrlRoute(checked)} />
            </Form.Item>
            <Form.Item
              name={Templates.dataModel.customUrl.key}
              label={isUrlRoute ? 'URL Route' : Templates.dataModel.customUrl.label}
              rules={[
                {
                  required: Templates.dataModel.customUrl.required,
                  message: Templates.dataModel.customUrl.validationMsg
                }
              ]}
            >
              <Input
                addonBefore={isUrlRoute ? 'https://portal.meta-health.co.za/' : 'https://'}
                maxLength={50}
                placeholder={Templates.dataModel.customUrl.placeholder}
              />
            </Form.Item>
            <Form.Item
              name={Templates.dataModel.primaryColor.key}
              label={Templates.dataModel.primaryColor.label}
              rules={[
                {
                  required: Templates.dataModel.primaryColor.required,
                  message: Templates.dataModel.primaryColor.validationMsg
                }
              ]}
            >
              <Input
                style={{ backgroundColor: primaryColor ? primaryColor : '#10B2E1', color: 'white' }}
                onChange={(e) => setPrimaryColor(e.target.value)}
                maxLength={50}
                placeholder={Templates.dataModel.primaryColor.placeholder}
              />
            </Form.Item>
            <Form.Item
              name={Templates.dataModel.secondaryColor.key}
              label={Templates.dataModel.secondaryColor.label}
              rules={[
                {
                  required: Templates.dataModel.secondaryColor.required,
                  message: Templates.dataModel.secondaryColor.validationMsg
                }
              ]}
            >
              <Input
                style={{ backgroundColor: secondaryColor ? secondaryColor : '#F14F8B', color: 'white' }}
                onChange={(e) => setSecondaryColor(e.target.value)}
                maxLength={50}
                placeholder={Templates.dataModel.secondaryColor.placeholder}
              />
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      key: 'configs',
      label: 'Configurations',
      children: (
        <>
          <Row justify='center'>
            <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
              <Form.Item
                name={Templates.dataModel.configs.paymentMethods.key}
                label={Templates.dataModel.configs.paymentMethods.label}
              >
                <Select mode='multiple' showSearch placeholder='Select Payment Methods' options={paymentMethods} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
              <Form.Item
                name={Templates.dataModel.configs.nextAppointmentTests.key}
                label={Templates.dataModel.configs.nextAppointmentTests.label}
                valuePropName='checked'
              >
                <Switch unCheckedChildren='No' checkedChildren='Yes' />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
              <Form.Item
                name={Templates.dataModel.configs.procedures.key}
                label={Templates.dataModel.configs.procedures.label}
              >
                <Select
                  mode='multiple'
                  showSearch
                  placeholder='Select Procedures'
                  options={procedures.map((i) => {
                    return {
                      label: i.label,
                      value: i.label
                    }
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    },
    {
      key: 'virtual_consultations',
      label: 'Virtual Consultations',
      children: (
        <Row justify='center'>
          <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
            <Form.Item
              name={Templates.dataModel.virtualConsultations.enabled.key}
              label={Templates.dataModel.virtualConsultations.enabled.label}
              valuePropName='checked'
            >
              <Switch
                unCheckedChildren='No'
                checkedChildren='Yes'
                onChange={(value) => setVirtualConsultsEnabled(value)}
              />
            </Form.Item>
            {virtualConsultsEnabled ? (
              <Form.Item
                name={Templates.dataModel.virtualConsultations.entities.key}
                label={Templates.dataModel.virtualConsultations.entities.label}
              >
                <Select
                  mode='multiple'
                  showSearch
                  loading={entitiesLoading}
                  options={entities}
                  optionFilterProp='label'
                  placeholder='Select Entities'
                />
              </Form.Item>
            ) : undefined}
          </Col>
        </Row>
      )
    },
    {
      key: 'medical_history',
      label: 'Medical History',
      children: (
        <Row justify='center'>
          <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
            <Form.Item
              name={Templates.dataModel.medicalHistory.renalCareSpecific.key}
              label={Templates.dataModel.medicalHistory.renalCareSpecific.label}
              valuePropName='checked'
            >
              <Switch unCheckedChildren='No' checkedChildren='Yes' />
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      key: 'vital_link',
      label: 'Meta-Link',
      children: (
        <Row justify='center' gutter={[12, 12]}>
          <Col span={24}>
            <Row justify='center'>
              <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                <Form.Item
                  name={Templates.dataModel.vitalLink.enabled.key}
                  label={Templates.dataModel.vitalLink.enabled.label}
                  valuePropName='checked'
                >
                  <Switch
                    unCheckedChildren='No'
                    checkedChildren='Yes'
                    onChange={(checked) => setMetaLinkEnabled(checked)}
                  />
                </Form.Item>
              </Col>
            </Row>
            {metaLinkEnabled ? (
              <>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.netCashEnabled.key}
                      label={Templates.dataModel.vitalLink.netCashEnabled.label}
                      valuePropName='checked'
                    >
                      <Switch unCheckedChildren='No' checkedChildren='Yes' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.staffVitalLink.key}
                      label={Templates.dataModel.vitalLink.staffVitalLink.label}
                      valuePropName='checked'
                    >
                      <Switch unCheckedChildren='No' checkedChildren='Yes' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.localVitalLink.key}
                      label={Templates.dataModel.vitalLink.localVitalLink.label}
                      valuePropName='checked'
                    >
                      <Switch
                        unCheckedChildren='No'
                        checkedChildren='Yes'
                        onChange={(checked) => setLocalVitalLink(checked)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {localVitalLink ? undefined : (
                  <Row justify='center'>
                    <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                      <Form.Item
                        name={Templates.dataModel.vitalLink.firstAccomodationAddress.key}
                        label={Templates.dataModel.vitalLink.firstAccomodationAddress.label}
                      >
                        <GoogleAddress
                          selectProps={{
                            placeholder: data?.vitalLink?.firstAccomodationAddress || 'Location',
                            onChange: (e) => {
                              clientForm.setFieldValue(
                                Templates.dataModel.vitalLink.firstAccomodationAddress.key,
                                e.label
                              )
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.localIndividualPrice.key}
                      label={Templates.dataModel.vitalLink.localIndividualPrice.label}
                    >
                      <Input addonBefore='R' type='number' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.localCouplePrice.key}
                      label={Templates.dataModel.vitalLink.localCouplePrice.label}
                    >
                      <Input addonBefore='R' type='number' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.localFamPrice.key}
                      label={Templates.dataModel.vitalLink.localFamPrice.label}
                    >
                      <Input addonBefore='R' type='number' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.touristIndividualPrice.key}
                      label={Templates.dataModel.vitalLink.touristIndividualPrice.label}
                    >
                      <Input addonBefore='R' type='number' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.touristCouplePrice.key}
                      label={Templates.dataModel.vitalLink.touristCouplePrice.label}
                    >
                      <Input addonBefore='R' type='number' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
                    <Form.Item
                      name={Templates.dataModel.vitalLink.touristFamPrice.key}
                      label={Templates.dataModel.vitalLink.touristFamPrice.label}
                    >
                      <Input addonBefore='R' type='number' />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : undefined}
          </Col>
        </Row>
      )
    }
  ]

  return (
    <ContainerCard title={isNewRecord ? 'Create a New Entity' : 'Viewing Entity'}>
      <Row justify='center'>
        <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
          <PrimaryCard title={isNewRecord ? 'New Entity' : `${data.name}`}>
            <Form
              onFinish={handleSubmit}
              name='form'
              form={clientForm}
              initialValues={{
                ...Templates.dataTemplate(),
                ...data
              }}
              layout='vertical'
            >
              <Collapse items={panelItems} defaultActiveKey='general' />
              <Row style={{ marginTop: 10 }} justify='center' gutter={[8, 8]}>
                <Col>
                  <Form.Item noStyle>
                    <Button
                      style={{ backgroundColor: Theme.twitterBootstrap.danger, color: 'white' }}
                      onClick={closeTab}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item noStyle>
                    <Button
                      style={{ backgroundColor: Theme.twitterBootstrap.success, color: 'white', marginRight: 10 }}
                      htmlType='submit'
                      loading={loading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </PrimaryCard>
        </Col>
      </Row>
    </ContainerCard>
  )
}

const BasicForm = memo(_BasicForm)

export default BasicForm
