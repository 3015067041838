import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'antd'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const SubscriptionSummary = ({ netCashEnabled, subscription, setShowNext, netCashTransaction }) => {
  const state = useSelector((state) => state)
  const [amount, setAmount] = useState(0)
  const [subAmount, setSubAmount] = useState(0)
  const [isSub, setIsSub] = useState(false)

  useEffect(() => {
    setShowNext(false)
    handleGetPaymentAmount()
    // eslint-disable-next-line
  }, [])

  const handleGetPaymentAmount = () => {
    const vitalLinkEntity = state.core.entity.vitalLink
    let key = null
    let tmpAmount = null

    if (vitalLinkEntity.localVitalLink) {
      key = 'local'
      setIsSub(true)
    } else {
      key = 'tourist'
      setIsSub(false)
    }

    switch (subscription.extraData.plan) {
      case 'individual':
        tmpAmount = vitalLinkEntity[key].individualSubPrice
        break
      case 'couple':
        tmpAmount = vitalLinkEntity[key].coupleSubPrice
        break
      case 'family':
        tmpAmount = vitalLinkEntity[key].famSubPrice
        break
      default:
        tmpAmount = 0
        break
    }

    setSubAmount(tmpAmount)

    if (subscription.extraData.panicButton) {
      setAmount(parseInt(tmpAmount) + 3390)
    } else {
      setAmount(tmpAmount)
    }
  }

  return (
    <>
      <Row justify='center'>
        <Col>
          <Table
            size='small'
            pagination={false}
            rowClassName='subscription-summary-table-row'
            columns={[
              {
                dataIndex: 'title',
                title: 'Subscription Summary',
                render: (text, record) => {
                  if (record.value === '') {
                    return <b>{text}</b>
                  } else {
                    return text
                  }
                }
              },
              { dataIndex: 'value' }
            ]}
            dataSource={[
              {
                title: 'Linked Number',
                value: subscription.extraData.phoneNumber,
                visible: true
              },
              {
                title: 'Arrival Date',
                value: dayjs(subscription.startDate ? subscription.startDate : new Date()).format('YYYY-MM-DD'),
                visible: true
              },
              {
                title: 'Departure Date',
                value: subscription.endDate ? dayjs(subscription.endDate).format('YYYY-MM-DD') : 'Not Applicable',
                visible: true
              },
              {
                title: 'Subscription Details',
                value: '',
                visible: true
              },
              {
                title: 'Subscription Type',
                value: subscription.extraData.plan
                  ? subscription.extraData.plan.charAt(0).toUpperCase() + subscription.extraData.plan.slice(1)
                  : 'Individual',
                visible: true
              },
              {
                title: 'Extras',
                value: '',
                visible: true
              },
              {
                title: 'Mobile Panic Button',
                value: subscription.extraData.panicButton ? 'Yes' : 'No',
                visible: true
              },
              {
                title: 'Cost Breakdown',
                value: '',
                visible: true
              },
              {
                title: 'Subscription',
                value: netCashEnabled ? `R${subAmount} ${isSub ? 'Per Month' : '(Once Off)'}` : 'Not Applicable',
                visible: true
              },
              {
                title: 'Mobile Panic Button',
                value: `${subscription.extraData.panicButton ? 'R' + 3390 + ' (Once Off)' : 'Not Applicable'}`,
                visible: subscription.extraData.panicButton ? true : false
              },
              {
                title: <b>Total</b>,
                value: netCashEnabled ? <b>R{amount}</b> : 'Not Applicable',
                visible: true
              }
            ].filter((item) => item.visible)}
          />
        </Col>
      </Row>
    </>
  )
}

export default SubscriptionSummary
