import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, Empty, message, theme } from 'antd'
import HealthAlternativeItem from './HealthAlternativeItem'
import { handleError, hexToRGBA } from '../../lib/utils'
import { readMacroTrackerRecipes } from '../utils/macro-tracker-utils'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const HealthyAlternativesList = ({ setModalOpen, isDoctor, userRef }) => {
  const authState = useSelector((state) => state.auth)
  const [currentRecipe, setCurrentRecipe] = useState(null)
  const [healthyAlternativesList, setSavingHealthyAlternativesList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleReadHealthyAlternatives()
    // eslint-disable-next-line
  }, [])

  const handleReadHealthyAlternatives = async () => {
    let tmpData = []
    setLoading(true)
    try {
      tmpData = await readMacroTrackerRecipes({ userRef: userRef ? userRef : authState.agiliteUser._id })

      setSavingHealthyAlternativesList(tmpData)
    } catch (error) {
      message.error(handleError(error))
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  const handleReset = (close) => {
    setCurrentRecipe('')
    setSavingHealthyAlternativesList([])
    if (close) {
      setModalOpen()
    }
  }
  return (
    <>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'space-between',
          top: 0,
          left: 0,
          right: 0,
          background: hexToRGBA(token.colorPrimary, 0.2),
          padding: 8
        }}
      >
        <h3 style={{ paddingTop: '5px', paddingLeft: '13px', textAlign: 'center' }}>NEW MEAL ENTRY</h3>{' '}
        <Button
          onClick={() => {
            handleReset(true)
          }}
          disabled={loading}
          style={{ border: 'none', background: 'none' }}
        >
          <FontAwesomeIcon style={{ fontSize: 24 }} color={token.colorError} icon={faXmarkCircle} />
        </Button>
      </div>
      <CustomRow style={{ marginTop: 48 }}>
        {currentRecipe ? (
          <HealthAlternativeItem
            handleBack={() => {
              setCurrentRecipe(null)
            }}
            healthyAlternative={currentRecipe}
          />
        ) : (
          <>
            {loading ? (
              <Col span={24}>
                <AgiliteSkeleton skActive spinnerTip='Loading Recipes...' />
              </Col>
            ) : (
              <>
                {healthyAlternativesList?.length === 0 ? (
                  <Empty description='Use the AI Meal Tracker tool to start saving meals.' />
                ) : undefined}
                {healthyAlternativesList.map((i) => {
                  return (
                    <Col span={24}>
                      <Card size='small' title={i.recipeName}>
                        <p style={{ width: '100%', marginBottom: 12 }}>{i.recipeDescription}</p>
                        <center>
                          <Button
                            onClick={() => {
                              setCurrentRecipe(i)
                            }}
                          >
                            View
                          </Button>
                        </center>
                      </Card>
                    </Col>
                  )
                })}
              </>
            )}
          </>
        )}
      </CustomRow>
    </>
  )
}

export default HealthyAlternativesList
