import { createSlice } from '@reduxjs/toolkit'
import state from './state'

const AdminSubscriptionsReducer = createSlice({
  name: 'adminSubscriptions',
  initialState: state,
  reducers: {
    setAdminSubs: (state, action) => {
      state.data = action.payload
    }
  }
})

export default AdminSubscriptionsReducer
