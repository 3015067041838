import Axios from 'axios'
import qs from 'qs'
import { extractAddressDetails, generateGenderPrefix, generateTitle } from '../../lib/utils'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const cgmAuth = (userId) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await Axios.post(
          process.env.REACT_APP_CGM_AUTH_URL,
          qs.stringify({
            grant_type: 'client_credentials',
            client_id: process.env.REACT_APP_CGM_CLIENT_ID,
            client_secret: process.env.REACT_APP_CGM_CLIENT_SECRET,
            scope: `device_${userId}`
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const cgmPost = (data, invoiceID, billingItems, access_token, medProf) => {
  return new Promise((resolve, reject) => {
    let accountResponse = null
    let billResponse = null
    let practiceID = null
    let response = null
    let mainPatientRecord = null
    let isDependantBill = false

    ;(async () => {
      try {
        // Check if patient is dependant
        response = await agilite.Connectors.execute('auth_users', 'find', {
          filter: JSON.stringify({ dependants: { $in: [data.patientRecord._id] } })
        })

        if (response.data.length > 0) {
          mainPatientRecord = response.data[0]
          isDependantBill = true
        } else {
          mainPatientRecord = data.patientRecord
          isDependantBill = false
        }

        // Format Addresses
        const [patientAddressResult, mainPatientAddressResult] = await Promise.all([
          geocodeByAddress(data.patientRecord.residentialAddress),
          geocodeByAddress(mainPatientRecord.residentialAddress)
        ])
        const patientAddressObj = extractAddressDetails(patientAddressResult)
        const mainPatientAddressObj = extractAddressDetails(mainPatientAddressResult)

        practiceID = data.clinicRecord.cgmPracticeId

        const accountRegistration = {
          accountID: mainPatientRecord.billingId,
          accountName: mainPatientRecord.firstName + ' ' + mainPatientRecord.lastName,
          accountStatus: 0,
          email: mainPatientRecord.email,
          tel: mainPatientRecord.phoneNumber,
          cell: '',
          fax: '',
          fileRef: '',
          vatRegNo: '',
          address: {
            postLine1: mainPatientAddressObj.streetAddress,
            postLine2: '',
            postCity: mainPatientAddressObj.city,
            postCode: mainPatientAddressObj.postalCode,
            postProvince: mainPatientAddressObj.province
          }
        }

        const patientRegistration = {
          patient: {
            accountID: mainPatientRecord.billingId,
            patientID: data.patientRecord.billingId,
            dob: data.patientRecord.dateOfBirth,
            idNumber: data.patientRecord.country === 'ZA' ? data.patientRecord.idNo : data.patientRecord.dateOfBirth,
            surname: data.patientRecord.lastName,
            fullNames: data.patientRecord.firstName,
            initials: data.patientRecord.firstName[0] + data.patientRecord.lastName[0],
            title: generateTitle(data.patientRecord.gender),
            gender: generateGenderPrefix(data.patientRecord.gender),
            language: null,
            dependantNo: data.patientRecord.medicalAid.dependantNumber,
            contactDetails: {
              resLine1: patientAddressObj.streetAddress,
              resLine2: '',
              resCity: patientAddressObj.city,
              resPostCode: patientAddressObj.postalCode,
              resProvince: patientAddressObj.province,
              email: data.patientRecord.email,
              cell: data.patientRecord.phoneNumber
            }
          },
          medAidDetails: {
            planCode: data.patientRecord?.medicalAid?.planCode || 'null',
            optionCode: data.patientRecord?.medicalAid?.planOptionCode || 'null',
            mainMemberId: mainPatientRecord.billingId,
            membershipNo: data.patientRecord?.medicalAid?.number || 'null',
            validFromDate: ''
          }
        }

        const billPatient = {
          practiceID,
          doctorNumber: medProf.extraData.mpNumber.startsWith('MP')
            ? medProf.extraData.mpNumber
            : 'MP' + medProf.extraData.mpNumber,
          patientID: data.patientRecord.billingId,
          invoiceID,
          dateOfService: data.bookingDate,
          placeOfService: 2,
          billAsPrivate:
            data.patientRecord?.medicalAid?.planCode && data.patientRecord?.medicalAid?.planOptionCode ? false : true,
          authNumber: '',
          chronicAuthNumber: '',
          diagnosis: data.diagnosis?.map((i) => i),
          materials: billingItems
            .filter((i) => i.type === 'Material')
            .map((i) => {
              return {
                materialCode: i.code,
                quantity: i.qty,
                price: null,
                diagnosisCode: null
              }
            }),
          procedures: billingItems
            .filter((i) => i.type === 'Procedure' || i.type === 'Consultation')
            .map((i, index) => {
              return {
                procedureID: index + 1,
                procedureCode: i.code,
                quantity: i.qty,
                price: null,
                type: i.type === 'Procedure' ? 1 : 0,
                startTime: null,
                endTime: null,
                diagnosisCode: null
              }
            }),
          prescription: billingItems
            .filter((i) => i.type === 'Medicine')
            .map((i) => {
              return {
                scriptCode: i.code,
                quantity: i.qty,
                price: null,
                type: 4,
                duration: null,
                diagnosisCode: null
              }
            }),
          modifiers: [],
          referringDoctor: {
            doctorName: null,
            doctorNumber: null,
            practiceNumber: null
          }
        }

        // Account Registration
        // If it is a dependant bill, we first need to make sure the main patient and account is registered first
        if (isDependantBill) {
          accountResponse = await Axios.post(
            `${process.env.REACT_APP_CGM_BASE_URL}/${practiceID}`,
            JSON.stringify({
              practiceNumber: data.clinicRecord.practiceNumber,
              accountRegistration,
              patientRegistration: {
                patient: {
                  accountID: mainPatientRecord.billingId,
                  patientID: mainPatientRecord.billingId,
                  dob: mainPatientRecord.dateOfBirth,
                  idNumber: mainPatientRecord.country === 'ZA' ? mainPatientRecord.idNo : mainPatientRecord.dateOfBirth,
                  surname: mainPatientRecord.lastName,
                  fullNames: mainPatientRecord.firstName,
                  initials: mainPatientRecord.firstName[0] + mainPatientRecord.lastName[0],
                  title: generateTitle(mainPatientRecord.gender),
                  gender: generateGenderPrefix(mainPatientRecord.gender),
                  language: null,
                  dependantNo: mainPatientRecord.medicalAid.dependantNumber,
                  contactDetails: {
                    resLine1: mainPatientAddressObj.streetAddress,
                    resLine2: '',
                    resCity: mainPatientAddressObj.city,
                    resPostCode: mainPatientAddressObj.postalCode,
                    resProvince: mainPatientAddressObj.province,
                    email: mainPatientRecord.email,
                    cell: mainPatientRecord.phoneNumber
                  }
                },
                medAidDetails: {
                  planCode: mainPatientRecord?.medicalAid?.planCode || null,
                  optionCode: mainPatientRecord?.medicalAid?.planOptionCode || null,
                  mainMemberId: mainPatientRecord.billingId,
                  membershipNo: mainPatientRecord?.medicalAid?.number || null,
                  validFromDate: ''
                }
              }
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
              }
            }
          )
        }

        accountResponse = await Axios.post(
          `${process.env.REACT_APP_CGM_BASE_URL}/${practiceID}`,
          JSON.stringify({
            practiceNumber: data.clinicRecord.practiceNumber,
            accountRegistration,
            patientRegistration
          }),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`
            }
          }
        )

        billResponse = await Axios.post(
          `${process.env.REACT_APP_CGM_BASE_URL}/${practiceID}`,
          JSON.stringify({
            practiceNumber: data.clinicRecord.practiceNumber,
            accountRegistration,
            patientRegistration,
            billPatient
          }),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`
            }
          }
        )

        resolve({ ...accountResponse?.data, ...billResponse?.data })
      } catch (e) {
        reject(e)
      }
    })()
  })
}
