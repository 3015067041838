import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { Spin } from 'antd'
import DayHeader from './full-calendar-components/DayHeader'
import { FullCalendarEnums } from './full-calendar-utils/full-calendar-enums'

import { ContainerCard } from '../reusable-components/AgiliteCards'
import './full-calendar-style.css'
import CustomLoadingIcon from '../reusable-components/CustomLoadingIcon'

const EHRCalendar = ({ medicalProfessionals, currentDate, setCurrentDate, currentView, setCurrentView }) => {
  const DayHeaderContent = (data) => {
    return <DayHeader {...data} />
  }
  const handleSlotLabelContent = (arg) => {
    // Customizing time slot label content and style
    return <div style={{ height: '50px', lineHeight: '50px' }}>{arg.text}</div>
  }

  return (
    <ContainerCard title='Calendar' bodyStyle={{ paddingTop: 0 }}>
      <CustomLoadingIcon
        content={
          <div>
            <FullCalendar
              slotLabelContent={handleSlotLabelContent}
              displayEventTime={false}
              allDaySlot={false}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              initialView={currentView}
              initialDate={currentDate}
              events={[]}
              eventTextColor='#000'
              weekends={true}
              editable={currentView !== FullCalendarEnums.viewTypeKeys.MONTH_VIEW}
              slotDuration='00:15:00'
              slotLabelInterval='00:15'
              slotMinTime='00:00:00'
              slotMaxTime='24:00:00'
              slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: false,
                hour12: false
              }}
              height={'calc(100vh - 160px)'}
              headerToolbar={false}
              // listDayFormat={'DDD'}
              scrollTime={'14:00:00'}
              listDaySideFormat={false}
              firstDay={currentDate.getDay()}
              dayHeaderContent={DayHeaderContent}
            />
          </div>
        }
      />
    </ContainerCard>
  )
}

export default EHRCalendar
