import { faChainBroken, faExclamationTriangle, faRefresh, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Empty, Row, Select, Space, Spin, Tooltip, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { MedPraxICD10Search } from '../../Scripting/utils/utils'
import { handleError, hexToRGBA } from '../../lib/utils'
import debounce from 'lodash/debounce'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { findBookingByIDUpdate } from '../examination-utils/examination-lib'
import { readBookings } from '../../Bookings/utils/utils'

const DiagnosisCapture = ({ bookingData, userRef }) => {
  const [loadingDiagnosis, setLoadingDiagnosis] = useState(false)
  const [diagnosis, setDiagnosis] = useState([])
  const [icd10Search, setIcd10Search] = useState('')
  const [icd10Loading, setIcd10Loading] = useState(false)
  const [icd10Data, setIcd10Data] = useState([])
  const [loadError, setLoadError] = useState(false)
  const [saveError, setSaveError] = useState(false)
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    checkExistingDiagnosis()
    // eslint-disable-next-line
  }, [])

  const checkExistingDiagnosis = async () => {
    setLoadError(false)
    setBusy(true)
    let tmpDiagnosis = []

    setLoadingDiagnosis(true)
    try {
      tmpDiagnosis = await readBookings({ _id: bookingData._id }, null, 'diagnosis')
      setDiagnosis(tmpDiagnosis[0]?.diagnosis || [])
    } catch (e) {
      setLoadError(true)
      message.error(handleError(e))
    }
    setBusy(false)
    setLoadingDiagnosis(false)
  }
  const handleGetIcd10 = async (searchQuery) => {
    let tmpData = []
    setIcd10Loading(true)
    try {
      if (searchQuery) {
        tmpData = await MedPraxICD10Search(searchQuery)
      }
      setIcd10Data(tmpData)
    } catch (e) {
      setIcd10Data([])
      message.error(handleError(e, true))
    }

    setIcd10Loading(false)
  }

  // eslint-disable-next-line
  const debouncedICD10Filter = useCallback(
    debounce((query) => {
      if (query) {
        handleGetIcd10(query)
      } else {
        handleGetIcd10()
      }
    }, 1000),
    []
  )

  const handleDiagnosisChange = async (array) => {
    setBusy(true)
    setSaveError(false)
    try {
      setDiagnosis(array)
      await findBookingByIDUpdate(bookingData._id, { diagnosis: array })
    } catch (e) {
      setSaveError(true)
      message.error('There was a problem saving this diagnosis. Please try again.')
    }
    setBusy(false)
  }

  // eslint-disable-next-line
  const debounceDiagnosisChange = useCallback(
    debounce((array) => {
      handleDiagnosisChange(array)
    }, 1000),
    []
  )

  const { token } = theme.useToken()

  return (
    <div
      style={{
        width: '100%',
        background: loadError || saveError ? hexToRGBA(token.colorError, 0.1) : '',
        padding: loadError || saveError ? 8 : 0
      }}
    >
      <Space>
        <p>Diagnosis:</p>
        <Select
          dropdownStyle={{ width: 700, maxWidth: '100%' }}
          disabled={loadingDiagnosis}
          style={{ minWidth: 250 }}
          value={diagnosis}
          defaultValue={diagnosis?.map((entry) => {
            if (typeof entry === 'string') {
              return entry
            } else {
              return entry.code
            }
          })}
          mode='multiple'
          onChange={(array) => {
            setBusy(true)
            setDiagnosis(array)
            debounceDiagnosisChange(array)
          }}
          options={
            !icd10Loading
              ? icd10Data.map((item) => {
                  return {
                    value: item.code,
                    label: item.code,
                    data: JSON.stringify(item)
                  }
                })
              : []
          }
          filterOption={false}
          placeholder={<>- Diagnosis -</>}
          onSearch={(value) => {
            setIcd10Loading(true)
            setIcd10Search(value)
            debouncedICD10Filter(value)
          }}
          loading={icd10Loading}
          optionRender={(item) => {
            return (
              <Row>
                <Col span={24}>
                  <Row>
                    <Col>{item.data.value}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <small>{JSON.parse(item.data.data).description}</small>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }}
          notFoundContent={
            icd10Loading ? (
              <AgiliteSkeleton skActive={false} spinnerTip='Loading...' />
            ) : (
              <>
                {icd10Search && icd10Data.length === 0 ? (
                  <Empty description='No results found' />
                ) : (
                  <Empty description='Start typing to search for a Diagnosis' />
                )}
              </>
            )
          }
        />
        {busy ? <Spin spinning /> : undefined}
        {loadError || saveError ? (
          <Space>
            {saveError && !busy ? (
              <Tooltip title='Error Saving Diagnosis'>
                <FontAwesomeIcon icon={faChainBroken} color={token.colorError} />
              </Tooltip>
            ) : undefined}
            {loadError && !busy ? (
              <Tooltip title='Error Loading Diagnosis'>
                <FontAwesomeIcon fontSize={20} icon={faChainBroken} color={token.colorError} />
              </Tooltip>
            ) : undefined}
            {loadError || saveError ? (
              <Button
                onClick={() => {
                  if (loadError) {
                    checkExistingDiagnosis()
                  } else {
                    handleDiagnosisChange(diagnosis)
                  }
                }}
              >
                <Space>
                  Retry <FontAwesomeIcon fontSize={20} icon={faRefresh} color={token.colorSuccess} />
                </Space>
              </Button>
            ) : undefined}
          </Space>
        ) : undefined}
        {(!diagnosis || diagnosis.length === 0) && !loadError && !saveError && !busy ? (
          <Tooltip title='Diagnosis Required'>
            <FontAwesomeIcon fontSize={20} icon={faExclamationTriangle} color={token.colorWarning} />
          </Tooltip>
        ) : !loadError && !saveError && !busy ? (
          <Tooltip title='Diagnosis Captured Successfully'>
            <FontAwesomeIcon fontSize={20} icon={faCheckCircle} color={token.colorSuccess} />{' '}
          </Tooltip>
        ) : undefined}
      </Space>
      {loadError || saveError ? (
        <div style={{ width: '100%', marginTop: 12 }}>
          {loadError ? (
            <p>There was an issue fetching the diagnosis for this booking. Please try again.</p>
          ) : saveError ? (
            <p>There was an issue saving the diagnosis for this booking. Please try again.</p>
          ) : undefined}
        </div>
      ) : undefined}
    </div>
  )
}

export default DiagnosisCapture
