import ObjectId from 'bson-objectid'
import { Col, Input, InputNumber, Row, Select, Table, message, theme } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { handleError } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import {
  MedPraxICD10Search,
  MedPraxMaterialSearch,
  MedPraxProcedureSearch,
  MedPraxProductSearch
} from '../../../Scripting/utils/utils'
import { debounce } from 'lodash'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import { createBillingRecord, deleteBillingRecord, getConsultationTypes, updateBillingRecord } from '../../utils/utils'
import { isReception } from '../../../lib/profile-utils'
import { useSelector } from 'react-redux'

const BillingTool = ({
  billing,
  itemSelection,
  setItemSelection,
  readOnly,
  bookingData,
  isMacroForm = false,
  billingRecordsLoading,
  billingWebSocket
}) => {
  const authState = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [diagnosisData, setDiagnosisData] = useState([])
  const [consultationTypes, setConsultationTypes] = useState([])

  const { token } = theme.useToken()

  useEffect(() => {
    if (!readOnly && !billingRecordsLoading) {
      const tmpData = [...itemSelection]

      tmpData.push({ type: undefined, code: undefined, name: undefined, qty: 0, icd10: undefined })
      setItemSelection(tmpData)
    }

    handleGetConsultationTypes()
    // eslint-disable-next-line
  }, [billingRecordsLoading])

  const handleGetConsultationTypes = async () => {
    let tmpData = []

    try {
      tmpData = await getConsultationTypes()
      setConsultationTypes(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const getDiagnosis = async (query) => {
    let tmpData = []

    setLoading(true)

    try {
      tmpData = await MedPraxICD10Search(query)
      setDiagnosisData(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const getData = async (query, type, filters) => {
    let tmpData = []

    setLoading(true)

    try {
      if (query) {
        switch (type) {
          case 'Procedure':
            tmpData = await MedPraxProcedureSearch(query, null, null, filters)
            break
          case 'Material':
            tmpData = await MedPraxMaterialSearch(query, null, null, filters)
            break
          case 'Medicine':
            tmpData = await MedPraxProductSearch(query, null, null, filters)
            break
          case 'Consultation':
            tmpData = await MedPraxProcedureSearch(query, null, null, filters, 'And')
            break
        }

        setSearchData(tmpData)
      } else {
        setSearchData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSelectChange = (props) => {
    const tmpData = [...itemSelection]
    const tmpValue = JSON.parse(props.value)
    let tmpRecord = null

    tmpData[props.index] = {
      ...tmpData[props.index],
      type: tmpValue.type,
      code: tmpValue.code,
      name: tmpValue.name ? tmpValue.name : tmpValue.description,
      qty: 1
    }

    tmpRecord = { ...props.record, ...tmpData[props.index] }

    tmpData.push({ type: undefined, code: undefined, name: undefined, qty: 0, icd10: undefined })
    setItemSelection(tmpData)

    handleUpdateBillingRecord(tmpRecord)

    clearSearch()
  }

  const clearSearch = () => {
    setSearchData([...consultationTypes])
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query, type, filters) => {
      if (query) {
        getData(query, type, filters)
      } else {
        setLoading(false)
      }
    }, 1000),
    []
  )

  // eslint-disable-next-line
  const debouncedICD10Filter = useCallback(
    debounce((query) => {
      if (query) {
        getDiagnosis(query)
      } else {
        setLoading(false)
      }
    }, 1000),
    []
  )

  const handleCreateBillingRecord = async (props) => {
    try {
      const tmpData = [...itemSelection]
      const tmpRecord = { _id: ObjectId().toHexString(), ...tmpData[props.index] }

      if (!isMacroForm) {
        tmpRecord.bookingRef = bookingData._id
      }

      tmpRecord.type = props.value
      tmpRecord.icd10 = bookingData?.diagnosis ? bookingData.diagnosis : []

      tmpData[props.index] = tmpRecord
      setItemSelection(tmpData)

      setSearchData([])

      // Without await cause client doesn't need to wait
      if (!isMacroForm) {
        billingWebSocket.socket.send(
          JSON.stringify({ bookingRef: bookingData._id, userRef: authState.agiliteUser._id })
        )
        createBillingRecord(tmpRecord)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleUpdateBillingRecord = async (record) => {
    try {
      // Without await cause client doesn't need to wait
      if (!isMacroForm) {
        billingWebSocket.socket.send(
          JSON.stringify({ bookingRef: bookingData._id, userRef: authState.agiliteUser._id })
        )
        updateBillingRecord(record._id, record)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleDeleteBillingRecord = async (record) => {
    try {
      // Without await cause client doesn't need to wait
      if (!isMacroForm) {
        billingWebSocket.socket.send(
          JSON.stringify({ bookingRef: bookingData._id, userRef: authState.agiliteUser._id })
        )
        deleteBillingRecord(record._id)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <CustomRow gutter={[0, 0]}>
      <Col span={24}>
        <Table
          loading={billingRecordsLoading}
          pagination={false}
          size='small'
          locale={{ emptyText: 'No billing items added' }}
          dataSource={[...itemSelection]}
          columns={[
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              filters: [
                { text: 'Consultation', value: 'Consultation' },
                { text: 'Procedure', value: 'Procedure' },
                { text: 'Medicine', value: 'Medicine' },
                { text: 'Material', value: 'Material' }
              ],
              onFilter: (value, record) => record.type === value || record.type === undefined,
              render: (text, record, index) => {
                if (record.type) {
                  return (
                    <Input
                      value={text}
                      style={{
                        width: '100%',
                        backgroundColor:
                          record.type === 'Material' || record.type === 'Medicine'
                            ? token.colorPrimary
                            : token.colorSecondary,
                        color: '#fff'
                      }}
                      readOnly
                    />
                  )
                } else {
                  return (
                    <Select
                      showSearch
                      placeholder={<span>Type</span>}
                      value={text}
                      style={{
                        width: '100%'
                      }}
                      dropdownStyle={{ width: 300 }}
                      onChange={(value) => {
                        handleCreateBillingRecord({ index, value })
                      }}
                    >
                      <Select.Option value='Consultation'>Consultation</Select.Option>
                      <Select.Option value='Procedure'>Procedure</Select.Option>
                      <Select.Option value='Medicine'>Medicine</Select.Option>
                      <Select.Option value='Material'>Material</Select.Option>
                    </Select>
                  )
                }
              },
              width: '10%'
            },
            {
              title: 'Code',
              dataIndex: 'code',
              key: 'code',
              render: (text, record, index) => {
                if (record.code) {
                  return (
                    <Input
                      value={
                        (record.type === 'Medicine' || record.type === 'Material') && record.nappiCode
                          ? record.nappiCode
                          : text
                      }
                      style={{
                        width: '100%'
                      }}
                      readOnly
                    />
                  )
                } else {
                  return (
                    <Select
                      showSearch
                      disabled={!record.type}
                      placeholder={!record.type ? <span>Select a Type...</span> : <span>Code</span>}
                      value={undefined}
                      style={{ width: '100%' }}
                      dropdownStyle={{ minWidth: 500 }}
                      onChange={(value) => {
                        handleSelectChange({ value, index, type: record.type, record })
                      }}
                      onSearch={(value) => {
                        setLoading(true)
                        debouncedFilter(
                          value,
                          record.type,
                          record.type === 'Procedure'
                            ? [
                                {
                                  propertyName: 'Code',
                                  operation: 'startsWith',
                                  value: value
                                }
                              ]
                            : record.type === 'Consultation'
                            ? [
                                {
                                  propertyName: 'Code',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Description',
                                  operation: 'contains',
                                  value: 'consultation'
                                }
                              ]
                            : [
                                {
                                  propertyName: 'NappiCode',
                                  operation: 'startsWith',
                                  value: value
                                }
                              ]
                        )
                      }}
                      options={
                        !loading
                          ? searchData.map((i) => {
                              return { label: i.code, value: JSON.stringify({ ...i, type: record.type }) }
                            })
                          : []
                      }
                      optionRender={(item) => {
                        return (
                          <Row>
                            <Col span={24}>
                              <Row>
                                <Col span={24}>
                                  {record.type === 'Medicine' || record.type === 'Material'
                                    ? JSON.parse(item.data.value).nappiCode
                                    : JSON.parse(item.data.value).code}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <small>
                                    {JSON.parse(item.data.value).name
                                      ? JSON.parse(item.data.value).name
                                      : JSON.parse(item.data.value).description}
                                  </small>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                        // }
                      }}
                      notFoundContent={loading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                    />
                  )
                }
              },
              width: '10%'
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (text, record, index) => {
                if (record.name) {
                  return <Input value={text} style={{ width: '100%' }} readOnly />
                } else {
                  return (
                    <Select
                      showSearch
                      disabled={!record.type}
                      placeholder={!record.type ? <span>Select a Type...</span> : <span>Name</span>}
                      value={undefined}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        handleSelectChange({ value, index, type: record.type })
                      }}
                      onSearch={(value) => {
                        setLoading(true)
                        debouncedFilter(
                          value,
                          record.type,
                          record.type === 'Procedure'
                            ? [
                                {
                                  propertyName: 'Description',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Description',
                                  operation: 'contains',
                                  value: value
                                }
                              ]
                            : record.type === 'Consultation'
                            ? [
                                {
                                  propertyName: 'Description',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Description',
                                  operation: 'contains',
                                  value: 'consultation'
                                }
                              ]
                            : [
                                {
                                  propertyName: 'Name',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Name',
                                  operation: 'contains',
                                  value: value
                                }
                              ]
                        )
                      }}
                      options={
                        !loading
                          ? searchData.map((i) => {
                              return {
                                label: i.name ? i.name : i.description,
                                value: JSON.stringify({ ...i, type: record.type })
                              }
                            })
                          : []
                      }
                      optionRender={(item) => {
                        return (
                          <Row>
                            <Col span={24}>
                              <Row>
                                <Col span={24}>
                                  {JSON.parse(item.data.value).name
                                    ? JSON.parse(item.data.value).name
                                    : JSON.parse(item.data.value).description}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <small>
                                    {record.type === 'Medicine' || record.type === 'Material'
                                      ? JSON.parse(item.data.value).nappiCode
                                      : JSON.parse(item.data.value).code}
                                  </small>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                        // }
                      }}
                      notFoundContent={loading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                    />
                  )
                }
              }
            },
            {
              title: 'ICD10',
              dataIndex: 'icd10',
              key: 'icd10',
              render: (text, record, index) => {
                return (
                  <Select
                    disabled={!record.type || readOnly}
                    showSearch
                    placeholder={!record.type ? <span>Select a Type...</span> : <span>ICD10</span>}
                    value={text}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      const tmpData = [...itemSelection]
                      tmpData[index].icd10 = value
                      setItemSelection(tmpData)

                      handleUpdateBillingRecord(record)
                    }}
                    onSearch={(value) => {
                      setLoading(true)
                      debouncedICD10Filter(value)
                    }}
                    mode='multiple'
                    filterOption={false}
                    options={
                      !loading
                        ? diagnosisData.map((i) => {
                            return {
                              label: i.code,
                              value: i.code,
                              data: JSON.stringify(i)
                            }
                          })
                        : []
                    }
                    optionRender={(item) => {
                      return (
                        <Row>
                          <Col span={24}>
                            <Row>
                              <Col span={24}>{JSON.parse(item.data.data).code}</Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <small>{JSON.parse(item.data.data).description}</small>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }}
                    notFoundContent={loading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                  />
                )
              },
              width: '20%'
            },
            {
              title: 'Qty',
              dataIndex: 'qty',
              key: 'qty',
              render: (text, record, index) => {
                return (
                  <InputNumber
                    disabled={!record.type || readOnly}
                    value={text}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      const tmpData = [...itemSelection]
                      tmpData[index].qty = value
                      setItemSelection(tmpData)

                      handleUpdateBillingRecord(record)
                    }}
                  />
                )
              },
              width: '5%'
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (text, record, index) => {
                if (record.code && record.type) {
                  if (!readOnly) {
                    return (
                      <Row justify='space-around'>
                        <Col>
                          <FontAwesomeIcon
                            style={{
                              color: token.colorError
                            }}
                            icon={faTrash}
                            onClick={() => {
                              const tmpData = [...itemSelection]
                              tmpData.splice(index, 1)
                              setItemSelection(tmpData)
                              clearSearch()

                              handleDeleteBillingRecord(record)
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  }
                } else {
                  return (
                    <Row justify='space-around'>
                      <Col>
                        <FontAwesomeIcon
                          style={{
                            color: token.colorError
                          }}
                          icon={faTrash}
                          onClick={() => {
                            const tmpData = [...itemSelection]
                            tmpData[index] = {
                              type: undefined,
                              code: undefined,
                              name: undefined,
                              qty: 0,
                              icd10: undefined
                            }
                            setItemSelection(tmpData)
                            clearSearch()

                            if (record._id) {
                              handleDeleteBillingRecord(record)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  )
                }
              },
              width: '5%'
            }
          ]}
        />
        {isReception() ? (
          <center style={{ margin: 10, color: 'red' }}>PLEASE REMEMBER TO ADD A CONSULTATION</center>
        ) : null}
      </Col>
    </CustomRow>
  )
}

export default BillingTool
