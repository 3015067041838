import React from 'react'
import Store from '../../../../store'
import { Space, Tag, Tooltip } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-regular-svg-icons'
import theme from '../../../../core/utils/theme'
import { sortBy } from 'lodash'
import { faEye, faMessage } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

const SystemUsersTemplates = {
  dataModel: {
    isActive: {
      label: 'Status:',
      key: ['extraData', 'isActive'],
      required: false
    },
    firstName: {
      label: 'Provide a first name:',
      key: 'firstName',
      required: true,
      placeholder: 'User`s first name',
      validationMsg: 'Please provide the user`s first name'
    },
    lastName: {
      label: 'Provide a last name:',
      key: 'lastName',
      required: true,
      placeholder: 'User`s last name',
      validationMsg: 'Please provide the user`s last name'
    },
    email: {
      label: 'Provide an email address:',
      key: 'email',
      type: 'email',
      required: true,
      placeholder: 'User`s email address',
      validationMsg: 'Please provide the user`s email address'
    },
    phoneNumber: {
      label: 'Provide a cellphone number:',
      key: 'phoneNumber',
      type: 'phoneNumber',
      required: true,
      placeholder: 'User`s cellphone number',
      validationMsg: 'Please provide the user`s cellphone number'
    },
    role: {
      label: 'Role:',
      key: ['extraData', 'role', 'type'],
      required: true,
      validationMsg: 'Please specify the user`s role'
    },
    isManager: {
      type: 'switch',
      dependency: null,
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Manager?',
      key: ['extraData', 'role', 'isManager'],
      required: false
    },
    profession: {
      label: 'Profession:',
      key: ['extraData', 'profession'],
      required: true,
      validationMsg: "Please specify the Medical Professional's Profession"
    },
    mpNumber: {
      label: 'MP Number:',
      key: ['extraData', 'mpNumber'],
      required: true,
      validationMsg: 'Please specify the MP Number'
    },
    qualification: {
      label: 'Qualification:',
      key: ['extraData', 'qualification'],
      required: true,
      placeholder: 'e,g, MHSc, BSc, MBChB, etc.',
      validationMsg: 'Please specify qualification'
    },
    services: {
      label: 'Services:',
      key: ['extraData', 'services'],
      required: false
    },
    clinics: {
      label: 'Linked Clinics:',
      key: ['extraData', 'clinics'],
      required: false
    },
    entities: {
      label: 'Linked Entities:',
      key: ['extraData', 'entities'],
      required: true
    }
  },
  dataTemplate: () => {
    return {
      isNew: true,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      gender: '',
      race: '',
      extraData: {
        isActive: true,
        role: { type: '', isManager: false },
        clinics: [],
        services: [],
        profession: '',
        mpNumber: ''
      }
    }
  },
  columnTemplate: (userFilters, type, clinics, token, setTeamChatProps, handleViewRecord, isCalendar) => {
    return [
      {
        title: <h2>Name</h2>,
        dataIndex: 'firstName',
        filterSearch: true,
        key: 'firstName',
        sorter: (a, b) => {
          return a.firstName.localeCompare(b.firstName)
        }
      },

      {
        title: <h2>Surname</h2>,
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: (a, b) => {
          return a.lastName.localeCompare(b.lastName)
        }
      },
      {
        title: <h2>ID/Passport</h2>,
        dataIndex: 'idNo',
        key: 'idNo',
        width: '15%',
        hidden: type === 'patient' ? false : true,
        render: (idNo) => {
          return (
            idNo || (
              <p style={{ opacity: 0.5, color: token.colorError }}>
                <em>Not Recorded</em>
              </p>
            )
          )
        }
      },
      {
        title: <h2>Date Of Birth</h2>,
        hidden: (Store.getState().auth.agiliteUser.extraData.profession === 'nurse' ? true : false) || isCalendar,
        render: (record) => {
          const date = new Date(record.dateOfBirth)
          if (isNaN(date)) {
            return (
              <p>
                <em style={{ color: token.colorError, opacity: 0.5 }}>Invalid Date</em>
              </p>
            )
          } else {
            return dayjs(date)?.format('DD-MM-YYYY')
          }
        }
      },
      {
        title: <h2>Email</h2>,
        width: '20%',
        hidden: (Store.getState().auth.agiliteUser.extraData.profession === 'nurse' ? true : false) || isCalendar,
        render: (record) => {
          return (
            record.email ||
            record.tmpEmail || (
              <p style={{ opacity: 0.5, color: token.colorError }}>
                <em>Not Recorded</em>
              </p>
            )
          )
        }
      },
      {
        title: <h2>Phone</h2>,
        width: '10%',
        hidden: (Store.getState().auth.agiliteUser.extraData.profession === 'nurse' ? true : false) || isCalendar,
        render: (record) => {
          return (
            record.phoneNumber ||
            record.tmpPhoneNumber || (
              <p style={{ opacity: 0.5, color: token.colorError }}>
                <em>Not Recorded</em>
              </p>
            )
          )
        }
      },
      {
        title: <h2>Main Member</h2>,
        width: '10%',
        render: (record) => {
          return record?.mainMemberName ? record.mainMemberName : 'Yes'
        }
      },

      {
        title: <h2>Profession</h2>,
        dataIndex: ['extraData', 'profession'],
        key: 'extraData.profession',
        width: '10%',
        hidden: type === 'medical' ? false : true,
        render: (professionValue, record) => {
          let professionLabel = null
          Store.getState().auth.professions.map((profession) => {
            if (professionValue === profession.value) {
              professionLabel = profession.label
            }

            return null
          })
          return professionLabel
        },
        filters: sortBy(userFilters.professions, ['text']),
        onFilter: (value, record) => {
          return record.extraData.profession.indexOf(value) === 0
        },
        filterIcon: (
          <Tooltip title='Filter by Profession'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: <h2>Services</h2>,
        dataIndex: ['extraData', 'services'],
        key: 'extraData.services',
        width: '10%',
        hidden: type === 'medical' ? false : true,
        render: (servicesValue, record) => {
          const tmpServices = JSON.parse(JSON.stringify(Store.getState().services.data))

          JSON.parse(JSON.stringify(Store.getState().virtualServices.data)).forEach((item) => {
            let tmpItem = JSON.parse(JSON.stringify(item))
            tmpItem.isVirtual = true
            tmpItem.name = tmpItem.name + ' (Virtual)'
            tmpServices.push(tmpItem)
          })

          const colorArray = ['#f5d7b0', '#f5e6b0', '#ecf5b0', '#d7f5b0', '#bdf5b0']

          tmpServices.forEach((item) => {
            item.tagColor = colorArray[0]
            colorArray.splice(0, 1)
          })
          return (
            <Space wrap>
              {servicesValue.map((service) => {
                const tmpService = tmpServices.find((item) => item._id === service)
                if (tmpService) {
                  return (
                    <Tag style={{ color: 'black' }} color={tmpService.tagColor}>
                      {tmpService.name}
                    </Tag>
                  )
                } else {
                  return <Tag style={{ color: 'black' }}>Unknown Service</Tag>
                }
              })}
            </Space>
          )
        },
        filters: sortBy(userFilters.services, ['text']),
        onFilter: (value, record) => {
          return record.extraData.services.includes(value)
        },
        filterIcon: (
          <Tooltip title='Filter by Service'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: <h2>Clinics</h2>,
        dataIndex: ['extraData', 'clinics'],
        key: 'extraData.clinics',
        width: '10%',
        hidden: type === 'medical' ? false : true,
        render: (clinicsValue, record) => {
          const tmpClinics = clinics

          const colorArray = ['#b0f5e6', '#b0f2f5', '#b0d4f5', '#b0b3f5', '#c2b0f5']

          tmpClinics.forEach((item) => {
            item.tagColor = colorArray[0]

            colorArray.splice(0, 1)
          })

          return (
            <Space wrap>
              {clinicsValue.map((clinic_ID) => {
                const tmpClinic = tmpClinics.find((item) => item._id === clinic_ID)

                if (tmpClinic) {
                  return (
                    <Tag style={{ color: 'black' }} color={tmpClinic.tagColor}>
                      {tmpClinic.name}
                    </Tag>
                  )
                } else {
                  return <Tag>{clinicsValue.name}</Tag>
                }
              })}
            </Space>
          )
        },
        filters: sortBy(userFilters.clinics, ['text']),
        onFilter: (value, record) => {
          return record.extraData.clinics.includes(value)
        },
        filterIcon: (
          <Tooltip title='Filter by Clinic'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: <h2>Role</h2>,
        dataIndex: ['extraData', 'role', 'type'],
        key: 'extraData.role',
        width: '5%',
        hidden: type === 'system' ? false : true,
        render: (roleValue, record) => {
          let roleLabel = 'Role No Longer Exists'

          Store.getState().auth.roles.map((role) => {
            if (roleValue === role.value) {
              roleLabel = role.label
            }

            return null
          })

          return roleLabel
        },
        filters: sortBy(userFilters.roles, ['text']),
        onFilter: (value, record) => {
          return record.extraData.role.type.indexOf(value) === 0
        },
        filterIcon: (
          <Tooltip title='Filter by Role'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: <h2>Address</h2>,
        dataIndex: 'residentialAddress',
        key: 'residentialAddress',
        hidden: type === 'patient' || Store.getState().auth.agiliteUser.extraData.profession === 'nurse',
        width: '10%'
      },
      {
        title: <h2>Manager</h2>,
        dataIndex: ['extraData', 'role', 'isManager'],
        hidden: type === 'patient' ? true : false,
        key: ['extraData.isManager'],
        width: '5%',
        render: (isManager) => {
          if (isManager)
            return (
              <center>
                <Tooltip title='This medical professional is a manager'>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.success }}
                  />
                </Tooltip>
              </center>
            )
          else
            return (
              <center>
                <Tooltip title='This medical professional is not a manager'>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.danger }}
                  />
                </Tooltip>
              </center>
            )
        },
        filterMultiple: false,
        filters: [
          {
            text: 'Is Manager',
            value: true
          },
          {
            text: 'Not Manager',
            value: false
          }
        ],
        onFilter: (value, record) => {
          return record.extraData.role.isManager === value
        },
        filterIcon: (
          <Tooltip title='Filter by Status'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: <h2>Status</h2>,
        dataIndex: ['extraData', 'isActive'],
        hidden: type === 'patient',
        width: '5%',
        key: ['extraData', 'isActive'],
        render: (status) => {
          if (status === true)
            return (
              <center>
                <Tooltip title='Active'>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.success }}
                  />
                </Tooltip>
              </center>
            )
          else
            return (
              <center>
                <Tooltip title='Deactivated'>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.danger }}
                  />
                </Tooltip>
              </center>
            )
        },
        filterMultiple: false,
        filters: [
          {
            text: 'Active',
            value: true
          },
          {
            text: 'Inactive',
            value: false
          }
        ],
        onFilter: (value, record) => {
          return record.extraData.isActive === value
        },
        filterIcon: (
          <Tooltip title='Filter by Status'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: <h2>Medical Aid</h2>,
        key: 'medicalAid',
        dataIndex: 'medicalAid',
        hidden: type !== 'patient',
        render: (medicalAid) => {
          return medicalAid.name || 'Private'
        }
      },

      {
        title: <h2>Actions</h2>,
        key: 'actions',
        hidden: Store.getState().auth.agiliteUser.extraData.role.type === 'patient' || isCalendar,
        width: '5%',
        render: (text, record) => {
          return (
            <Space style={{ width: '100%', gap: 12 }}>
              <Tooltip title='View Profile'>
                <FontAwesomeIcon
                  fontSize={20}
                  style={{ color: '#ccc', transition: 'color 300ms ease' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleViewRecord(record)
                  }}
                  icon={faEye}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = token.colorPrimary
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = '#ccc'
                  }}
                />
              </Tooltip>
              <Tooltip title='Team Chat'>
                <FontAwesomeIcon
                  fontSize={20}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = token.colorPrimary
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = '#ccc'
                  }}
                  style={{ color: '#ccc', transition: 'color 300ms ease' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setTeamChatProps({ userRef: record._id, isOpen: true })
                  }}
                  icon={faMessage}
                />
              </Tooltip>
            </Space>
          )
        }
      }
    ]
  }
}

export default SystemUsersTemplates
