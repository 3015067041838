import React, { useCallback, useEffect, useState } from 'react'
import { Col, Empty, Input, Radio, Space, Table, message, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'
import { countBookings, readAllBookings } from '../../Admin/bookings/utils/utils'
import { handleError } from '../../lib/utils'
import CustomRow from '../../reusable-components/CustomRow'
import CustomButton from '../../reusable-components/CustomButton'
import { CRMTableTemplates } from '../utils/crm-templates'
import { updateBooking } from '../../Bookings/utils/utils'
import TeamChatForm from '../../TeamChat/components/team-chat-form'
import { useSelector } from 'react-redux'

const CRMListView = () => {
  const clinicState = useSelector((state) => state.clinics.data)
  const [changingStatus, setChangingStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [total, setTotal] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [rangeFilter, setRangeFilter] = useState('today')
  const [tabletData, setTableData] = useState([])
  const [teamChatProps, setTeamChatProps] = useState({ isOpen: false, userRef: '' })
  const [filteredValues, setFilteredValues] = useState({
    crmStatus: ['Pending', 'Completed', 'Patient Unreachable', 'Doctor to Contact']
  })
  const state = useSelector((state) => state)
  const { token } = theme.useToken()

  useEffect(() => {
    handleGetAllBookings()
    // eslint-disable-next-line
  }, [page, pageSize, filteredValues])

  useEffect(() => {
    handleGetAllBookings()

    // eslint-disable-next-line
  }, [searchQuery, rangeFilter, state.bookings.data, state.bookings.dashboardData])

  const handleGetAllBookings = async () => {
    let tmpBookings = []
    let tmpUsers = []
    let tmpUserIds = []
    let qry = null
    let userQuery = null
    let tmpTotal = 0
    let tmpFilteredValues = JSON.parse(JSON.stringify(filteredValues))

    setLoading(true)

    try {
      if (searchQuery) {
        userQuery = {
          $or: [
            {
              $expr: {
                $regexMatch: {
                  input: { $concat: ['$firstName', ' ', '$lastName'] },
                  regex: searchQuery, // Your text search here
                  options: 'i'
                }
              }
            },
            { phoneNumber: { $regex: searchQuery, $options: 'i' } },
            { email: { $regex: searchQuery, $options: 'i' } },
            { physicalAddress: { $regex: searchQuery, $options: 'i' } },
            { idNo: { $regex: searchQuery, $options: 'i' } }
          ]
        }

        tmpUsers = await readSystemUsers(userQuery, null, null, '_id')

        for (const user of tmpUsers) {
          tmpUserIds.push(user._id)
        }
      }

      if (rangeFilter === 'upcoming') {
        qry = {
          bookingDate: {
            $gte: dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD')
          }
        }
      } else if (rangeFilter === 'previous') {
        qry = {
          bookingDate: {
            $lt: dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z')
          }
        }
      } else if (rangeFilter === 'today') {
        qry = {
          bookingDate: {
            $gt: dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z'),
            $lt: dayjs(new Date()).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z')
          }
        }
      } else {
        qry = {}
      }

      // Reception Roles
      // All bookings that are at their clinic

      // Finalize Query for bookings based on user ids
      if (tmpUserIds.length > 0) {
        qry.$or = [{ userRef: { $in: tmpUserIds } }, { medicalProfRef: { $in: tmpUserIds } }]
      }

      qry.clinicRef = { $in: clinicState.map((clinic) => clinic._id) }
      // Table Filters

      for (const prop in tmpFilteredValues) {
        if (tmpFilteredValues[prop] && tmpFilteredValues[prop].length > 0) {
          const crmFilter = []
          tmpFilteredValues[prop].forEach((i) => {
            if (i === 'Pending') {
              crmFilter.push(null)
              crmFilter.push(i)
            } else {
              crmFilter.push(i)
            }
          })
          qry[prop] = { $in: crmFilter }
        }
      }

      tmpTotal = await countBookings(qry)
      tmpBookings = await readAllBookings(qry, page, pageSize, {
        sort: {
          bookingDate: -1,
          startTime: 1
        }
      })

      setTableData(tmpBookings)
      setTotal(tmpTotal)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSearch = (value) => {
    setLoading(true)
    debouncedFilter(value)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      if (query) {
        setPage(1)
        setSearchQuery(query)
      } else {
        if (searchQuery) {
          setSearchQuery('')
        } else {
          setSearchQuery('')
          handleGetAllBookings()
        }
      }
    }, 1000),
    []
  )

  const handleChangeStatus = async (recordId, status) => {
    setChangingStatus(true)
    try {
      await updateBooking({ crmStatus: status }, { _id: recordId })
      setChangingStatus(false)
      await handleGetAllBookings()
    } catch (e) {
      message.error(handleError(e))
      setChangingStatus(false)
    }
  }
  return (
    <>
      <CustomRow className='basic-card'>
        <Col span={24}>
          <Space wrap>
            <CustomButton
              size='small'
              type='primary'
              text='Refresh'
              icon={faRefresh}
              onClick={() => {
                handleGetAllBookings()
              }}
              disabled={loading}
            />
            <Input
              style={{ width: 400 }}
              placeholder='Search bookings...'
              suffix={<FontAwesomeIcon icon={faSearch} />}
              onChange={(e) => handleSearch(e.target.value)}
              allowClear
            />

            <Radio.Group
              disabled={loading}
              value={rangeFilter}
              onChange={(e) => {
                setRangeFilter(e.target.value)
              }}
            >
              <Radio.Button value='previous'>Previous</Radio.Button>
              <Radio.Button value='today'>Today</Radio.Button>
              <Radio.Button value='upcoming'>Upcoming</Radio.Button>
              <Radio.Button value='all'>All</Radio.Button>
            </Radio.Group>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading || changingStatus}
            dataSource={tabletData}
            locale={{
              emptyText: <Empty description='No Bookings found' />
            }}
            onChange={(pagination, filters, sorter) => {
              setFilteredValues(filters)
            }}
            columns={CRMTableTemplates(handleChangeStatus, filteredValues, setTeamChatProps, token).columnTemplate}
            pagination={{
              current: page,
              pageSize,
              total: total,
              onChange: (page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
              },
              showSizeChanger: true,
              hideOnSinglePage: true,
              position: ['bottomCenter']
            }}
          />
        </Col>
      </CustomRow>
      <TeamChatForm modalProps={teamChatProps} setModalProps={setTeamChatProps} />
    </>
  )
}

export default CRMListView
