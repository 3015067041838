import dayjs from 'dayjs'
import React from 'react'
import { FullCalendarEnums } from '../full-calendar-utils/full-calendar-enums'

const WeekHeader = ({ date, setCurrentDate, setCurrentView }) => {
  const formattedDate = dayjs(date).format('DD/MM') // Get the formatted date (e.g., April 26)
  return (
    <div
      onClick={() => {
        setCurrentView(FullCalendarEnums.viewTypeKeys.DAY_VIEW)
        setCurrentDate(dayjs(date).toDate())
      }}
    >
      <span>{formattedDate}</span>
    </div>
  )
}

export default WeekHeader
