import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import {
  Alert,
  Checkbox,
  Col,
  Empty,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  message,
  theme
} from 'antd'
import { InventoryTemplates } from './utils/templates'
import CustomButton from '../../reusable-components/CustomButton'
import { convertDateTimeSAST, handleError } from '../../lib/utils'
import { debounce } from 'lodash'
import { readImports } from '../config/utils/utils'
import { createInventoryActivity, createInventoryEntry, readInventory, updateInventoryItem } from './utils/utils'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const InventoryManagement = ({ setCurrentInventoryItem, filter, setLocation }) => {
  const clinicState = useSelector((state) => state.clinics.data)
  const authState = useSelector((state) => state.auth)

  const [adjustment, setAdjustment] = useState({ item: null, type: '' })
  const [captureType, setCaptureType] = useState('')
  const [consumanlesData, setConsumablesData] = useState([])
  const [consumablesLoading, setConsumablesLoading] = useState(false)
  const [currentUpdateItem, setCurrentUpdateItem] = useState(null)
  const [inventoryList, setInventoryList] = useState([])
  const [inventoryBusy, setInventoryBusy] = useState(false)
  const [isGlobal, setIsGlobal] = useState(true)
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [newItem, setNewItem] = useState({
    selection: { description: '', code: '' },
    price: '',
    min: 0,
    max: 0,
    clinics: []
  })

  useEffect(() => {
    handleGetInventoryList()
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    setNewItem({
      selection: { _id: '', description: '', code: '' },
      price: '',
      quantity: '',
      min: 0,
      max: 0
    })
    setIsGlobal(true)
  }, [captureType])

  const handleGetInventoryList = async () => {
    let tmpInventoryList = []
    let qry = {}
    let activityQry = {}
    let clinicQuery = null
    let searchQuery = null

    setLoading(true)

    try {
      clinicQuery = filter.clinicQuery
        ? { $or: [{ locations: { $in: [filter.clinicQuery] } }, { isGlobal: true }] }
        : null
      searchQuery = filter.searchQuery
        ? {
            $or: [
              { code: { $regex: filter.searchQuery, $options: 'i' } },
              { description: { $regex: filter.searchQuery, $options: 'i' } }
            ]
          }
        : null
      if (searchQuery || clinicQuery) {
        qry.$and = []
        if (searchQuery) {
          qry.$and.push(searchQuery)
        }
        if (clinicQuery) {
          qry.$and.push(clinicQuery)
          activityQry.locationRef = filter.clinicQuery
        }
      }

      tmpInventoryList = await readInventory(qry, activityQry)
      setInventoryList(tmpInventoryList)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const handleAddStockItem = async () => {
    let tmpNewItem = {}
    setInventoryBusy(true)

    try {
      if (!newItem.selection.code || !newItem.selection.description || !newItem.price)
        throw new Error('Please fill in all fields')

      if (isGlobal && newItem.clinics < 1) {
        throw new Error('Please select at least one clinic')
      }
      tmpNewItem = await createInventoryEntry({
        code: newItem.selection.code,
        description: newItem.selection.description,
        price: newItem.price,
        createdBy: authState.agiliteUser._id,
        createdAt: dayjs(),
        modifiedBy: authState.agiliteUser._id,
        modifiedAt: dayjs(),
        locations: isGlobal ? [] : newItem.clinics,
        min: newItem.min,
        max: newItem.max,
        isGlobal
      })

      setInventoryList([...inventoryList, tmpNewItem])
      setModalOpen(false)
      setCaptureType('')
      setNewItem({
        selection: { _id: '', description: '', code: '' },
        price: '',
        quantity: '',
        min: 0,
        max: 0
      })
      setIsGlobal(true)
    } catch (e) {
      message.error(handleError(e))
    }

    setInventoryBusy(false)
  }
  const handleCreateActivityEntry = async () => {
    let tmpInventoryList = [...inventoryList]
    setInventoryBusy(true)
    try {
      if (!adjustment.locationRef) throw new Error('Please select a location')
      if (!adjustment.item.quantity) throw new Error('Please enter a quantity')
      if (adjustment.item.quantity < 0) throw new Error('Quantity is invalid')
      await createInventoryActivity({
        itemRef: adjustment.item._id,
        createdAt: convertDateTimeSAST(dayjs()),
        createdBy: authState.agiliteUser._id,
        bookingRef: '',
        quantity: adjustment.item.quantity,
        type: adjustment.type,
        locationRef: adjustment.locationRef
      })
      handleGetInventoryList()
      setAdjustment({ type: '', item: null })
      setInventoryList(tmpInventoryList)
    } catch (e) {
      message.error(handleError(e))
    }
    setInventoryBusy(false)
  }

  const handleGetConsumables = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await readImports('nappi', searchQuery)
        setConsumablesData(tmpData)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setConsumablesLoading(false)
  }

  // eslint-disable-next-line
  const debouncedConsumablesFilter = useCallback(
    debounce((query) => {
      if (query) {
        setConsumablesLoading(true)
        handleGetConsumables(query)
      } else {
        handleGetConsumables()
      }
    }, 1000),
    []
  )
  const handleStockAdjustment = (item, type) => {
    setAdjustment({ item: item, type: type })
  }

  const handleUpdateInventoryItem = async () => {
    setLoading(true)
    try {
      await updateInventoryItem(currentUpdateItem._id, {
        price: currentUpdateItem.price,
        min: currentUpdateItem.min,
        max: currentUpdateItem.max,
        isActive: currentUpdateItem.isActive
      })
      setModalOpen(false)
      setCurrentUpdateItem(null)
      handleGetInventoryList()
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const handleUpdateItem = (item) => {
    setCurrentUpdateItem(item)
    setModalOpen(true)
  }

  const { token } = theme.useToken()

  const handleGetQuantity = (activity) => {
    if (activity) {
      let stockIn = activity.filter((stockItem) => stockItem?.type === 'in').map((i) => i.quantity)
      let stockOut = activity.filter((stockItem) => stockItem?.type === 'out').map((i) => i.quantity)
      let stockInTotal = stockIn.reduce((a, b) => a + b, 0) - stockOut.reduce((a, b) => a + b, 0)
      return stockInTotal
    } else {
      return 0
    }
  }
  const fetchMinMaxList = () => {
    let tmpMinMaxList = inventoryList.filter((i) => i.isActive && handleGetQuantity(i.activity) <= i.min)
    return tmpMinMaxList
  }

  return (
    <>
      <CustomRow>
        {fetchMinMaxList()?.length > 0 ? (
          <Col span={24}>
            <Alert
              type='error'
              description={
                <>
                  <p style={{ color: 'red', marginBottom: 8 }}>The following items are running low:</p>
                  <Space>
                    {fetchMinMaxList().map((i) => (
                      <Tag color='red' style={{ cursor: 'pointer' }} onClick={() => handleStockAdjustment(i, 'in')}>
                        {i.description}
                      </Tag>
                    ))}
                  </Space>
                </>
              }
            />
          </Col>
        ) : undefined}
        <Col span={24}>
          <Space wrap>
            <CustomButton
              text='Refresh'
              onClick={() => {
                handleGetInventoryList()
              }}
              type='primary'
              size='small'
            />
            <CustomButton
              text='Add New Item'
              onClick={() => {
                setModalOpen(true)
              }}
              type='success'
              size='small'
            />
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={inventoryList}
            locale={{
              emptyText: <Empty description='No Inventory Items Found' />
            }}
            columns={InventoryTemplates.columnTemplate(
              handleUpdateItem,
              handleStockAdjustment,
              setCurrentInventoryItem,
              setLocation,
              filter
            )}
            scroll={{ x: 1000 }}
          />
        </Col>
      </CustomRow>
      <Modal
        title={
          <h2
            style={{
              padding: 8,
              background:
                adjustment && adjustment.type === 'in'
                  ? token.colorSuccess
                  : adjustment.type === 'out'
                  ? token.colorError
                  : token.colorSecondary,
              color: 'white',
              borderRadius: 4
            }}
          >
            {adjustment.item && adjustment.type
              ? `Stock ${adjustment.type}: ${adjustment.item.description}`
              : currentUpdateItem
              ? 'Updating: ' + currentUpdateItem.description
              : 'Add Stock Item'}
          </h2>
        }
        open={modalOpen || (adjustment.item && adjustment.type) || currentUpdateItem}
        destroyOnClose
        onOk={() => {
          if (adjustment.item && adjustment.type) {
            handleCreateActivityEntry()
          } else {
            if (currentUpdateItem) {
              handleUpdateInventoryItem()
            } else {
              handleAddStockItem()
            }
          }
        }}
        okText={inventoryBusy ? 'Busy...' : 'Confirm'}
        okButtonProps={{ disabled: inventoryBusy }}
        onCancel={() => {
          setModalOpen(false)
          setCaptureType('')
          setCurrentUpdateItem(null)
          setNewItem({
            selection: { _id: '', description: '', code: '' },
            price: '',
            quantity: '',
            min: 0,
            max: 0
          })
          setAdjustment({ type: '', item: null })
          setIsGlobal(true)
        }}
      >
        {adjustment.type && adjustment.item ? (
          <CustomRow>
            <Col span={24}>
              <p style={{ marign: '6px 0' }}>Stock {adjustment.type}:</p>
              <InputNumber
                style={{ width: 200 }}
                placeholder='Quantity'
                value={adjustment.item.quantity}
                onChange={(e) => {
                  setAdjustment({ ...adjustment, item: { ...adjustment.item, quantity: e } })
                }}
              />
            </Col>
            <Col span={24}>
              <p style={{ marign: '6px 0' }}>Location:</p>
              <Select
                style={{ width: '100%' }}
                placeholder='Location'
                showSearch
                optionFilterProp='label'
                value={adjustment.locationRef}
                options={clinicState.map((clinic) => {
                  return { label: clinic.name, value: clinic._id }
                })}
                onChange={(e) => {
                  setAdjustment({ ...adjustment, item: { ...adjustment.item }, locationRef: e })
                }}
              />
            </Col>
          </CustomRow>
        ) : currentUpdateItem ? (
          <CustomRow>
            <Col span={24} style={{ marginTop: 24 }}>
              <Space>
                <p>Status: </p>
                <Switch
                  onChange={(value) => {
                    setCurrentUpdateItem({ ...currentUpdateItem, isActive: value })
                  }}
                  checked={currentUpdateItem.isActive}
                  checkedChildren={'Active'}
                  unCheckedChildren={'In-Active'}
                />
              </Space>
            </Col>

            <Col span={24}>
              <Space>
                <p>Price: </p>{' '}
                <InputNumber
                  style={{ width: 150 }}
                  onChange={(value) => {
                    setCurrentUpdateItem({ ...currentUpdateItem, price: value })
                  }}
                  value={currentUpdateItem.price}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <p>Min: </p>{' '}
                <InputNumber
                  style={{ width: 150 }}
                  onChange={(value) => {
                    setCurrentUpdateItem({ ...currentUpdateItem, min: value })
                  }}
                  value={currentUpdateItem.min}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <p>Max: </p>{' '}
                <InputNumber
                  style={{ width: 150 }}
                  onChange={(value) => {
                    setCurrentUpdateItem({ ...currentUpdateItem, max: value })
                  }}
                  value={currentUpdateItem.max}
                />
              </Space>
            </Col>
          </CustomRow>
        ) : (
          <CustomRow>
            <Col span={24}>
              <center>
                <Radio.Group disabled={loading} value={captureType} onChange={(e) => setCaptureType(e.target.value)}>
                  <Radio.Button value=''>Search List</Radio.Button>
                  <Radio.Button value='manual'>Manual</Radio.Button>
                </Radio.Group>
              </center>
            </Col>
            {captureType === '' ? (
              <Col span={24}>
                <Select
                  onChange={(selection) => {
                    let tmpSelection = JSON.parse(selection)
                    setNewItem({
                      ...newItem,
                      selection: { description: tmpSelection.description, code: tmpSelection.code }
                    })
                  }}
                  placeholder='Search by code or description'
                  style={{ width: '100%' }}
                  options={consumanlesData.map((item) => {
                    return {
                      label: item.description,
                      value: JSON.stringify(item)
                    }
                  })}
                  loading={consumablesLoading}
                  showSearch
                  onSearch={(query) => {
                    debouncedConsumablesFilter(query)
                  }}
                />
              </Col>
            ) : (
              <>
                <Col span={24}>
                  <Input
                    placeholder='Code'
                    value={newItem.selection.code}
                    onChange={(e) => {
                      setNewItem({ ...newItem, selection: { ...newItem.selection, code: e.target.value } })
                    }}
                  />
                </Col>
                <Col span={24}>
                  <Input
                    placeholder='Description'
                    value={newItem.selection.description}
                    onChange={(e) => {
                      setNewItem({ ...newItem, selection: { ...newItem.selection, description: e.target.value } })
                    }}
                  />
                </Col>
              </>
            )}
            <Col span={24}>
              <InputNumber
                style={{ width: 200 }}
                placeholder='Price'
                value={newItem.price}
                onChange={(e) => {
                  setNewItem({ ...newItem, price: e })
                }}
              />
            </Col>
            <Col span={24}>
              <Space>
                <p>Min: </p>{' '}
                <InputNumber
                  style={{ width: 150 }}
                  onChange={(value) => {
                    setNewItem({ ...newItem, min: value })
                  }}
                  value={newItem.min}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <p>Max: </p>{' '}
                <InputNumber
                  style={{ width: 150 }}
                  onChange={(value) => {
                    setNewItem({ ...newItem, max: value })
                  }}
                  value={newItem.max}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space wrap>
                Used at all clinics
                <Checkbox
                  defaultChecked={isGlobal}
                  checked={isGlobal}
                  onChange={(e) => {
                    setIsGlobal(e.target.checked)
                  }}
                />
              </Space>
            </Col>
            <Col span={24}>
              {isGlobal ? undefined : (
                <Select
                  mode='multiple'
                  showSearch
                  optionFilterProp='label'
                  placeholder='Select Linked Clinics'
                  style={{ width: '100%' }}
                  value={newItem.clinics}
                  onChange={(e) => {
                    setNewItem({ ...newItem, clinics: e })
                  }}
                  options={clinicState.map((clinic) => {
                    return {
                      label: clinic.name,
                      value: clinic._id
                    }
                  })}
                />
              )}
            </Col>
          </CustomRow>
        )}
      </Modal>
    </>
  )
}

export default InventoryManagement
