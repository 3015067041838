import React, { useState, useMemo, useCallback } from 'react'
import { Button, Card, Modal, Space, Table, theme, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { StandardDataPointGraph } from '../../examination-utils/standard-graph'
import CorrectionsModal from '../corrections-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hexToRGBA } from '../../../lib/utils'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faPlus, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

const DataPointTableDisplay = ({
  type,
  dataPoints,
  patientDataEntries,
  fetchPatientData,
  setPatientDataEntries,
  // setLabOrderModalOpen,
  setLabReportModalOpen,
  labOrders,
  setCaptureModalOpen
}) => {
  const [currentImage, setCurrentImage] = useState(null)
  const [dataCorrectionTemplate, setDataCorrectionTemplate] = useState('')
  const [dataCorrectionData, setDataCorrectionData] = useState('')
  const [correctingData, setCorrectingData] = useState(false)
  const { token } = theme.useToken()

  // Memoize the groupData function to avoid unnecessary recalculations
  const groupData = useCallback((data) => {
    const groupedData = data.reduce((acc, record) => {
      const { key } = record
      if (!acc[key]) {
        acc[key] = { key, records: [] }
      }
      acc[key].records.push(record)
      return acc
    }, {})

    Object.values(groupedData).forEach((dataPoint) => {
      dataPoint.records.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
    })

    return Object.values(groupedData)
  }, [])

  const getDataPointLabel = useCallback(
    (key, isSubfield = false, subfieldKey = '') => {
      let dataPointTarget = dataPoints.find((dp) => dp.key === key)

      if (isSubfield && dataPointTarget?.subFields) {
        dataPointTarget = dataPointTarget.subFields.find((sf) => sf.key === subfieldKey)
      }

      return dataPointTarget?.label || 'Unknown Data Point'
    },
    [dataPoints]
  )

  const hasGraph = useCallback((dataPointReference) => {
    if (dataPointReference.subFields?.length > 0) {
      return dataPointReference.subFields.every((field) => field.fieldType === 'number')
    }
    return dataPointReference.fieldType === 'number'
  }, [])

  const groupSubfields = useCallback((subFieldData, dataTemplateReference) => {
    return dataTemplateReference.subFields.map((subFieldEntry) => {
      const records = subFieldData.records.map((subfieldRecord) => {
        const targetDataPoint = subfieldRecord.value.find((v) => v.key === subFieldEntry.key)
        return {
          ...subfieldRecord,
          value: targetDataPoint?.value || null
        }
      })
      return {
        key: subFieldEntry.key,
        records
      }
    })
  }, [])

  const handleNestedDataDisplay = useCallback((record, dataPointReference) => {
    const value = record.records[0]?.value
    const displayValue = Array.isArray(value) ? value.join(', ') : value
    return (
      <Space>
        <span>{displayValue}</span>
        {dataPointReference?.suffix && <span style={{ opacity: 0.5 }}>{dataPointReference.suffix}</span>}
      </Space>
    )
  }, [])

  // Extracted component for rendering nested tables
  const NestedTable = ({ data, dataTemplateReference }) => {
    if (hasGraph(dataTemplateReference)) {
      return <StandardDataPointGraph data={data} dataTemplate={dataTemplateReference} />
    }

    if (dataTemplateReference.nestedSubFields) {
      const subfields = groupSubfields(data, dataTemplateReference)
      return (
        <>
          <Table
            dataSource={subfields}
            columns={[
              {
                title: 'Data Point',
                render: (subFieldData) => getDataPointLabel(data.key, true, subFieldData.key)
              },
              {
                title: 'Reading',
                render: (subFieldData) => {
                  const subFieldDataPointReference = dataTemplateReference.subFields.find(
                    (sf) => sf.key === subFieldData.key
                  )
                  return handleNestedDataDisplay(subFieldData, subFieldDataPointReference)
                }
              },
              {
                title: 'Date',
                render: (subFieldData) => dayjs(subFieldData.records[0]?.dateCreated).format('DD MMM YYYY - HH:mm')
              }
            ]}
            pagination={false}
            size='small'
            bordered
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (subFieldData) => (
                <Table
                  dataSource={subFieldData.records}
                  columns={[
                    {
                      title: 'Reading',
                      render: (readingData) => {
                        const subFieldDataPointReference = dataTemplateReference.subFields.find(
                          (sf) => sf.key === subFieldData.key
                        )
                        const value = readingData.value
                        const displayValue = Array.isArray(value) ? value.join('/') : value
                        return (
                          <Space>
                            <span>{displayValue}</span>
                            {subFieldDataPointReference?.suffix && (
                              <span style={{ opacity: 0.5 }}>{subFieldDataPointReference.suffix}</span>
                            )}
                          </Space>
                        )
                      }
                    },
                    {
                      title: 'Date',
                      render: (record) => dayjs(record.dateCreated).format('DD MMM YYYY - HH:mm')
                    }
                  ]}
                  pagination={false}
                  size='small'
                  bordered
                />
              )
            }}
          />
        </>
      )
    }

    return (
      <Table
        dataSource={data.records}
        columns={[
          {
            title: 'Reading',
            render: (readingData) => {
              const value = readingData.value
              const dataPointReference = dataPoints.find((dp) => dp.key === data.key)
              const displayValue = Array.isArray(value) ? value.map((v) => v.value).join('/') : value
              return (
                <Space>
                  <span>{displayValue}</span>
                  {dataPointReference?.suffix && <span style={{ opacity: 0.5 }}>{dataPointReference.suffix}</span>}
                </Space>
              )
            }
          },
          {
            title: 'Date',
            render: (record) => dayjs(record.dateCreated).format('DD MMM YYYY - HH:mm')
          }
        ]}
        pagination={false}
        size='small'
        bordered
      />
    )
  }

  // Memoized columns to prevent unnecessary re-renders
  const columns = useMemo(
    () => [
      {
        title: 'Clinical Reading',
        render: (data) => getDataPointLabel(data.key),
        width: '20%'
      },
      {
        title: 'Value',
        render: (data) => {
          const dataPointReference = dataPoints.find((dp) => dp.key === data.key)
          if (dataPointReference.nestedSubFields) {
            return 'Drop down to see historical entries.'
          }
          const value = data.records[0]?.value
          const displayValue = Array.isArray(value)
            ? value.map((v) => v.value).join(dataPointReference.delimiter || ', ')
            : value
          return (
            <Space>
              <span>{displayValue}</span>
              {dataPointReference?.suffix && <span style={{ opacity: 0.5 }}>{dataPointReference.suffix}</span>}
            </Space>
          )
        },
        width: '50%'
      },
      {
        title: 'Date',
        render: (data) => dayjs(data.records[0]?.dateCreated).format('DD MMM YYYY - HH:mm'),
        width: '20%'
      },
      {
        title: 'Actions',
        render: (data) => (
          <Tooltip title='Make a correction'>
            <Button
              type='primary'
              onClick={(e) => {
                e.stopPropagation()
                const dataTemplateReference = dataPoints.find((dp) => dp.key === data.key)
                setDataCorrectionTemplate(dataTemplateReference)
                setDataCorrectionData(data.records)
                setCorrectingData(true)
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </Tooltip>
        ),
        width: '5%'
      }
    ],
    [dataPoints, getDataPointLabel]
  )

  const getDataPointColour = () => {
    switch (type) {
      case 'general':
        return '#0000FF' // blue
      case 'vitals':
        return '#008000' // green
      case 'rapid':
        return '#DAA520' // goldenrod
      case 'poc':
        return '#FFC0CB' // pink
      case 'lab':
        return '#FFA500' // orange
    }
  }

  const getTitle = () => {
    switch (type) {
      case 'general':
        return 'General Stats' // blue
      case 'vitals':
        return 'Vitals Signs' // green
      case 'rapid':
        return 'Blood Work - Rapid Tests' // yellow
      case 'poc':
        return 'Blood Work - Point Of Care' // pink
      case 'lab':
        return 'Blood Work - Labs' // orange
    }
  }

  return (
    <>
      <Card
        extra={
          <Space>
            {/* {type === 'lab' ? (
              <Button
                type='primary'
                style={{ background: '#FFA500' }}
                onClick={() => {
                  setLabOrderModalOpen(true)
                }}
              >
                Lab Order
              </Button>
            ) : undefined} */}
            {type === 'poc' ? (
              <Button
                type='primary'
                style={{ background: token.colorSecondary }}
                onClick={() => {
                  setLabReportModalOpen(true)
                }}
              >
                Lab Report
              </Button>
            ) : undefined}
            <Button
              type='primary'
              style={{ background: token.colorSuccess }}
              onClick={() => {
                setCaptureModalOpen(type)
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Space>
        }
        bodyStyle={{ padding: 0, overflowY: 'scroll', maxHeight: 450 }}
        headStyle={{ borderColor: getDataPointColour() }}
        style={{ background: hexToRGBA(getDataPointColour(), 0.2), borderColor: getDataPointColour() }}
        size='small'
        title={<h1>{getTitle().toUpperCase()}</h1>}
      >
        {/* {type === 'lab' ? (
          <Table
            size='small'
            bordered
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  size='small'
                  dataSource={record.selection.map((i) => JSON.parse(i))}
                  columns={[
                    { title: 'Test', dataIndex: 'title', key: 'title' },
                    { title: 'Price', dataIndex: 'approxPrice', key: 'approxPrice', render: (R) => `R${R}.00` }
                  ]}
                  pagination={false}
                  bordered={true}
                />
              ),
              rowExpandable: () => true
            }}
            columns={[
              {
                title: 'Ordered By',
                render: () => `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
              },
              {
                title: 'Price',
                render: (record) => {
                  let tmpPrice = 0
                  record.selection.forEach((i) => {
                    tmpPrice = tmpPrice + JSON.parse(i).approxPrice
                  })
                  return `R${tmpPrice}.00`
                }
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'
              }
            ]}
            dataSource={labOrders}
          />
        ) : ( */}
        <Table
          showHeader={false}
          style={{ marginTop: '1px' }}
          dataSource={groupData(patientDataEntries)}
          columns={columns}
          pagination={false}
          size='small'
          bordered
          rowKey='key'
          expandable={{
            expandRowByClick: true,
            expandedRowRender: (data) => {
              const dataTemplateReference = dataPoints.find((dp) => dp.key === data.key)
              return <NestedTable data={data} dataTemplateReference={dataTemplateReference} />
            }
          }}
        />
        {/* )} */}
      </Card>

      {correctingData && (
        <CorrectionsModal
          fetchPatientData={fetchPatientData}
          patientDataEntries={patientDataEntries}
          setPatientDataEntries={setPatientDataEntries}
          template={dataCorrectionTemplate}
          data={dataCorrectionData}
          setTemplate={setDataCorrectionTemplate}
          onClose={() => setCorrectingData(false)}
        />
      )}
      <Modal width={950} closable={false} footer={false} open={currentImage}>
        <div
          style={{
            zIndex: 100,
            width: '100%',
            padding: 8,
            background: hexToRGBA(token.colorSecondary, 0.2),
            position: 'absolute',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            left: 0
          }}
        >
          <h1>RADIOLOGY</h1>
          <Button
            onClick={() => {
              setCurrentImage(null)
            }}
            style={{ float: 'right', fontSize: 24, background: 'transparent', border: 'none' }}
          >
            <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
          </Button>
        </div>
        <img src={currentImage} alt='Current' style={{ position: 'absolute', left: 0, top: 48, width: '100%' }} />
      </Modal>
    </>
  )
}

export default DataPointTableDisplay
