import React, { useEffect, useState } from 'react'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, Form, Input, Row, Select, Space, Switch, message, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons'
import { handleError } from '../../lib/utils'
import { readClinicDataPoints } from '../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { createDataPointTemplate, updateDataPointTemplate } from '../utils'

const DataPointTemplateForm = ({ isNew, record, handleCloseForm }) => {
  const [loading, setLoading] = useState(false)
  const [dataPointsLoading, setDataPointsLoading] = useState(false)
  const [dataPoints, setDataPoints] = useState([])
  const { token } = theme.useToken()

  useEffect(() => {
    handleGetDataPoints()
  }, [])

  const handleGetDataPoints = async () => {
    let tmpData = null

    setDataPointsLoading(true)
    try {
      tmpData = await readClinicDataPoints({}, 'key label')
      setDataPoints(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setDataPointsLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      if (isNew) {
        await createDataPointTemplate(form.getFieldsValue())
      } else {
        await updateDataPointTemplate(record._id, { ...record, ...form.getFieldsValue() })
      }

      handleCloseForm(isNew, record)
      form.resetFields()
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const [form] = Form.useForm()

  return (
    <ContainerCard title={isNew ? 'New Data Point Template' : `Data Point Template: ${record.name}`}>
      <CustomRow justify='center'>
        <Col xs={24} sm={24} md={14} lg={12}>
          <Card>
            <Row>
              <Col xs={24} lg={12}>
                <Form layout='vertical' initialValues={record} form={form} onFinish={() => handleSave()}>
                  <Form.Item label='Is Active' name='isActive' valuePropName='checked'>
                    <Switch checkedChildren='Yes' unCheckedChildren='No' />
                  </Form.Item>
                  <Form.Item
                    label='Template Name'
                    name='name'
                    rules={[{ required: true, message: 'Please provide a name' }]}
                  >
                    <Input placeholder='Template Name' />
                  </Form.Item>
                  <Form.Item
                    label='Data Points'
                    name='keys'
                    rules={[{ required: true, message: 'Please select at least 1 Data Point' }]}
                  >
                    <Select
                      loading={dataPointsLoading}
                      placeholder='- Select Data Points -'
                      options={dataPoints.map((i) => {
                        return { label: i.label, value: i.key }
                      })}
                      mode='multiple'
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row justify='space-around'>
              <Col>
                <Button onClick={() => form.submit()} type='primary' disabled={loading} loading={loading}>
                  <Space>
                    <FontAwesomeIcon icon={faCheck} />
                    Save
                  </Space>
                </Button>
              </Col>
              <Col>
                <Button onClick={() => handleCloseForm(isNew, record)} danger disabled={loading}>
                  <Space>
                    <FontAwesomeIcon icon={faClose} color={token.colorError} />
                    Close
                  </Space>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default DataPointTemplateForm
