import { Button, Card, Col, Form, Row, Select, Space, message, theme, Popconfirm } from 'antd'
import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { handleError } from '../../../lib/utils'
import { createHealthTrackingEntry } from '../utils/utils'
import dayjs from 'dayjs'
import { trackers } from '../utils/trackers'

const cancel = (e) => {
  message.error('Clicked on No')
}

const HealthTrackingEntryForm = ({ data, isNewRecord, handleReadData }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { token } = theme.useToken()

  const [types, setTypes] = useState(data.types)
  const [loading, setLoading] = useState(false)

  const closeTab = (e) => {
    message.success('Clicked on Yes')
    // refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNewRecord
          ? `${CoreEnums.tabKeys.HEALTH_TRACKING_ENTRIES}_new}`
          : `${CoreEnums.tabKeys.HEALTH_TRACKING_ENTRIES}_${data._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const data = form.getFieldsValue(true)

      data.types = types

      await createHealthTrackingEntry(data)
      handleReadData()
      closeTab()
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const dependenciesMet = (entry) => {
    if (entry.dependencies) {
      let dependencyCount = entry.dependencies.length
      let dependenciesMet = 0
      entry.dependencies.forEach((dependency) => {
        if (types.includes(dependency)) {
          dependenciesMet++
        }
      })
      return dependenciesMet === dependencyCount
    } else {
      if (types.includes(entry.key)) {
        return true
      }
    }
  }
  const getTrackerOptions = () => {
    let trackerOptions = []
    trackers(isNewRecord).forEach((tracker) => {
      if (tracker.dependencies) {
        if (tracker.dependencies.length === 0) {
          trackerOptions.push({ label: tracker.label, value: tracker.key })
        }
      } else {
        trackerOptions.push({ label: tracker.label, value: tracker.key })
      }
    })
    return trackerOptions
  }
  return (
    <div style={{ marginTop: 10 }}>
      <Row justify='center'>
        <Col xs={23} sm={24} md={20} lg={16} xl={14} xxl={12}>
          <CustomRow className='basic-card'>
            <Col span={24}>
              <Card
                title={`Health Tracking - ${isNewRecord ? 'New' : dayjs(data.date).format('YYYY-MM-DD HH:mm:ss')}`}
                headStyle={{ backgroundColor: token.colorPrimary, color: '#fff' }}
              >
                <Row justify='center'>
                  <Col span={22}>
                    <Card
                      size='small'
                      title='Health Trackers'
                      headStyle={{ backgroundColor: token.colorSecondary, color: '#fff' }}
                    >
                      <Select
                        placeholder='- Select health trackers -'
                        mode='multiple'
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          setTypes(value)
                        }}
                        disabled={!isNewRecord}
                        defaultValue={data.types}
                        options={getTrackerOptions()}
                        allowClear
                      />
                    </Card>
                    <Form
                      form={form}
                      initialValues={data}
                      layout='vertical'
                      style={{ marginTop: 20 }}
                      onFieldsChange={(changed, all) => {
                        if (form.getFieldValue('height') && form.getFieldValue('weight')) {
                          const bmi = parseFloat(
                            form.getFieldValue('weight') / (form.getFieldValue('height') / 100) ** 2
                          ).toFixed(2)
                          form.setFieldsValue({ bmi })
                        } else {
                          form.setFieldsValue({ bmi: null })
                        }
                      }}
                    >
                      <Row justify='center' gutter={[16, 16]}>
                        <Col xs={24}>
                          <Space wrap>
                            {trackers(isNewRecord, types).map((entry) => {
                              if (dependenciesMet(entry)) {
                                return entry.render()
                              } else {
                                return undefined
                              }
                            })}
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row justify='center' style={{ marginTop: 10 }}>
                  <Col style={{ margin: 5 }}>
                    {/* ----------------------------Popconfirm------------------------- */}
                    <Popconfirm
                      title='Close this tab?'
                      description='Please confirm below'
                      okText='Yes'
                      cancelText='No'
                      onConfirm={closeTab}
                      onCancel={cancel}
                    >
                      <Button disabled={loading} type='primary' style={{ backgroundColor: token.colorError }}>
                        <Space>
                          <FontAwesomeIcon icon={faTimes} /> Close
                        </Space>
                      </Button>
                    </Popconfirm>
                    {/* --------------------------Popconfirm--------------------------- */}
                  </Col>
                  {types.length !== 0 && isNewRecord ? (
                    <Col style={{ margin: 5 }}>
                      <Button
                        loading={loading}
                        type='primary'
                        style={{ backgroundColor: token.colorSuccess }}
                        onClick={handleSubmit}
                      >
                        <Space>
                          <FontAwesomeIcon icon={faCheck} /> Submit
                        </Space>
                      </Button>
                    </Col>
                  ) : undefined}
                </Row>
              </Card>
            </Col>
          </CustomRow>
        </Col>
      </Row>
    </div>
  )
}

export default HealthTrackingEntryForm
