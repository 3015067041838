import React, { useEffect, useState } from 'react'

import { Row, Col, Card, Modal, Space, Avatar, theme, Input, Empty, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons'

// CSS
import '../utils/style.css'
import { handleError } from '../../lib/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { createTeamChat, readTeamChat, updateTeamChat } from '../utils/utils'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const TeamChatForm = ({ modalProps, setModalProps }) => {
  const state = useSelector((state) => state)
  const [data, setData] = useState({ userRef: modalProps.userRef, data: [] })
  const [chatExists, setChatExists] = useState(false)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')

  useEffect(() => {
    if (modalProps.isOpen) {
      handleGetData()
    }
    // eslint-disable-next-line
  }, [modalProps])

  const handleGetData = async () => {
    setLoading(true)
    try {
      const response = await readTeamChat(modalProps.userRef)

      if (response) {
        setData(response)
        setChatExists(true)
      } else {
        setData({ userRef: modalProps.userRef, data: [] })
        setChatExists(false)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleSendMessage = async () => {
    setLoading(true)
    try {
      const tmpData = JSON.parse(JSON.stringify(data))
      let response = null

      tmpData.data.push({
        createdBy: `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`,
        text,
        createdAt: new Date(),
        createdRef: state.auth.agiliteUser._id
      })

      setText('')
      setData(tmpData)

      if (!chatExists) {
        response = await createTeamChat(tmpData)
      } else {
        response = await updateTeamChat(tmpData)
      }

      setData(response)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  return (
    <>
      <Modal
        maskClosable={false}
        closable={false}
        open={modalProps.isOpen}
        onCancel={() => {
          setModalProps({ ...modalProps, isOpen: false })
          setText('')
          setData({ userRef: '', data: [] })
        }}
        title='Team Chat'
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ danger: true }}
        cancelText={
          <Space>
            <FontAwesomeIcon icon={faClose} /> Close Chat
          </Space>
        }
        destroyOnClose
      >
        <Row>
          <Col span={24}>
            <Card size='small'>
              {loading ? (
                <AgiliteSkeleton spinnerTip='Loading...' />
              ) : (
                <>
                  {data?.data?.length === 0 && !loading ? <Empty description='No History' /> : null}
                  {data?.data?.map((item, index) => {
                    return (
                      <Row style={{ margin: 10 }}>
                        <Col span={24}>
                          <Row style={{ marginBottom: 10 }} justify='space-between'>
                            <Col>
                              <Space>
                                <Avatar size='large' icon={<FontAwesomeIcon icon={faUser} />} />
                                <b style={{ color: token.colorPrimary }}>{item.createdBy}</b>
                              </Space>
                            </Col>
                            <Col>
                              <Space>
                                <small>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</small>
                              </Space>
                            </Col>
                          </Row>
                          <Row gutter={[12, 12]}>
                            <Col>{item.text}</Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  })}
                </>
              )}

              <Row style={{ marginTop: 10 }}>
                <Col span={24}>
                  <div
                    style={{
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: '1fr 40px',
                      alignItems: 'center',
                      gap: 5
                    }}
                  >
                    <Input.TextArea
                      style={{ height: 50 }}
                      value={text}
                      rows={2}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <button
                      onClick={handleSendMessage}
                      className='send_button'
                      style={{ backgroundColor: token.colorPrimary, border: 'none' }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} color='white' size='lg' />
                    </button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default TeamChatForm
