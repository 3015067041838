// src/index.js
import React from 'react'
import App from './App'
import BluetoothManager from './components/BluetoothManager'

const LinkTop = ({ userRef, bookingRef, setModalOpen }) => {
  return (
    <BluetoothManager>
      <App userRef={userRef} bookingRef={bookingRef} setModalOpen={setModalOpen} />
    </BluetoothManager>
  )
}

export default LinkTop
