import { Modal, Tag, message } from 'antd'
import { executeBooking, getBookingState } from '../Bookings/utils/bpm-utils'
import Store from '../../store'
import adminBookingsReducer from '../Admin/bookings/utils/reducer'
import todaysPatientsReducer from '../Dashboard/Employee/components/todays-patients/todays-patients-reducer'
import billingReducer from '../Bookings/utils/billing-reducer'
import EmployeeBookingsReducer from '../Bookings/utils/employee-bookings-reducer'
import { handleError } from './utils'
import CoreEnums from '../../core/utils/enums'
import { readAllBookings } from '../Admin/bookings/utils/utils'
import dayjs from 'dayjs'
import AppointmentsCalendarReducer from '../Appointments-Calendar/utils/appointments-calendar-reducer'

export const handleCancelBooking = (authState, record, updateBooking, onAfterCancel, token) => {
  let disabled = false
  Modal.confirm({
    title: 'Cancel Booking',
    className: token.themeControl,
    okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
    cancelButtonProps: { style: { background: token.colorError, color: 'white' } },
    onCancel: async () => {
      function delayCancel() {
        message.warning('Still processing cancellation, please wait.')
        return new Promise((resolve) => {
          if (!disabled) {
            return resolve
          }
        })
      }
      if (disabled) {
        await delayCancel()
      }
    },
    content: 'Are you sure you want to cancel this booking?',
    okText: 'Yes',
    cancelText: 'No',

    onOk: async () => {
      disabled = true
      try {
        let processRecord = await getBookingState([record.processRef])
        // BPM
        processRecord = await executeBooking(
          record.clinicRecord.bpmKey,
          record.processRef,
          'cancel',
          `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`,
          processRecord.key
        )

        let newRecord = await updateBooking({ status: processRecord.processStage }, { _id: record._id })
        updateAllBookingsState(newRecord)
        onAfterCancel()
        message.success('Booking Cancelled')
      } catch (e) {
        message.error(`Unable to cancel booking. ${handleError(e)}`)
      }
      disabled = false
    }
  })
}

export const handleWasNoShow = (record, updateBooking, handleGetData, token) => {
  Modal.confirm({
    title: 'Booking No Show',
    className: token.themeControl,
    okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
    cancelButtonProps: { style: { background: token.colorError, color: 'white' } },
    content: 'Are you sure you want to mark this booking as a No Show? This action can`t be reversed.',
    okText: 'Yes',
    cancelText: 'No',
    onOk: async () => {
      let newRecord = await updateBooking({ wasNoShow: true }, { _id: record._id })
      updateAllBookingsState(newRecord)
      // handleGetData()
    }
  })
}

export const generateStatus = (status, token, noShow, textOnly) => {
  let tagColor = null
  let statusText = null
  if (noShow) {
    tagColor = token.colorTextDisabled
    status = 'No Show'
  } else {
    switch (status) {
      case CoreEnums.bookingStatuses.checkin:
        statusText = 'Check-In'
        tagColor = token.colorPrimaryText
        break
      case CoreEnums.bookingStatuses.screening:
        statusText = 'Screening'
        tagColor = token.colorSecondary
        break
      case CoreEnums.bookingStatuses.procedure:
        statusText = 'Procedure'
        tagColor = '#FF69B4'
        break
      case CoreEnums.bookingStatuses.diagnosis:
        statusText = 'Diagnosis'
        tagColor = token.colorWarning
        break
      case CoreEnums.bookingStatuses.billing:
        statusText = 'Reception'
        tagColor = token.colorInfo
        break
      case CoreEnums.bookingStatuses.data_capture:
        statusText = 'Reception Data Capture'
        tagColor = token.colorInfo
        break
      case CoreEnums.bookingStatuses.completed:
        statusText = 'Completed'
        tagColor = token.colorSuccess
        break
      case CoreEnums.bookingStatuses.cancelled:
        statusText = 'Cancelled'
        tagColor = token.colorError
        break
      default:
        statusText = 'Unknown'
        tagColor = undefined
    }
  }
  if (textOnly) {
    return statusText
  } else {
    return <Tag color={tagColor}>{statusText}</Tag>
  }
}

export const updateAllBookingsState = (record) => {
  const userState = Store.getState().auth.agiliteUser
  let adminBookings = JSON.parse(JSON.stringify(Store.getState().adminBookings.data))
  let todaysBookings = JSON.parse(JSON.stringify(Store.getState().todaysPatients.data))
  let employeeBookings = JSON.parse(JSON.stringify(Store.getState().employeeBookings.data))
  let billingBookings = JSON.parse(JSON.stringify(Store.getState().billing.data))
  let calendarBookings = JSON.parse(JSON.stringify(Store.getState().appointmentsCalendar.data))

  let adminBookIndex = -1
  let todaysBookingsIndex = -1
  let employeeBookingsIndex = -1
  let billingIndex = -1
  let calendarIndex = -1

  adminBookIndex = adminBookings.findIndex((x) => x._id === record._id)
  todaysBookingsIndex = todaysBookings.findIndex((x) => x._id === record._id)
  employeeBookingsIndex = employeeBookings.findIndex((x) => x._id === record._id)
  billingIndex = billingBookings.findIndex((x) => x._id === record._id)
  calendarIndex = calendarBookings.findIndex((x) => x._id === record._id)

  // Handle Admin Bookings State Change
  if (adminBookIndex !== -1) {
    adminBookings[adminBookIndex] = { ...adminBookings[adminBookIndex], ...record }
    Store.dispatch(adminBookingsReducer.actions.setRecords(adminBookings))
  }

  // Handle Employee Bookings State Change
  if (employeeBookingsIndex !== -1) {
    employeeBookings[employeeBookingsIndex] = { ...employeeBookings[employeeBookingsIndex], ...record }
    Store.dispatch(EmployeeBookingsReducer.actions.setRecords(employeeBookings))
  }

  if (calendarIndex !== -1) {
    calendarBookings[calendarIndex] = { ...calendarBookings[calendarIndex], ...record }
    Store.dispatch(AppointmentsCalendarReducer.actions.setRecords(calendarBookings))
  }

  // TODO: Handle Billing State
  // Records not in the billing stage must be spliced
  // Records moved into the billing stage must be pushed into the billing state
  if (billingIndex !== -1) {
    if (record.status === CoreEnums.bookingStatuses.billing) {
      billingBookings[billingIndex] = { ...billingBookings[billingIndex], ...record }
    } else {
      billingBookings.splice(billingIndex, 1)
    }

    Store.dispatch(billingReducer.actions.setRecords(billingBookings))
  }

  // Handle Todays Patients Component
  // Todays patients component only shows records that are in the relevant stage for the user
  // This means that some records are instead spliced when they are no longer relevant
  if (todaysBookingsIndex !== -1) {
    if (
      record.status === CoreEnums.bookingStatuses.cancelled ||
      record.wasNoShow ||
      record.status === CoreEnums.bookingStatuses.completed
    ) {
      todaysBookings.splice(todaysBookingsIndex, 1)
    } else {
      if (userState.extraData.role.type === 'medical_professional') {
        if (userState.extraData.profession === 'nurse') {
          if (
            record.status !== CoreEnums.bookingStatuses.screening &&
            record.status !== CoreEnums.bookingStatuses.procedure
          ) {
            todaysBookings.splice(todaysBookingsIndex, 1)
          } else {
            todaysBookings[todaysBookingsIndex] = { ...todaysBookings[todaysBookingsIndex], ...record }
          }
        }
        if (userState.extraData.profession === 'doctor') {
          if (record.status !== CoreEnums.bookingStatuses.diagnosis) {
            todaysBookings.splice(todaysBookingsIndex, 1)
          } else {
            todaysBookings[todaysBookingsIndex] = { ...todaysBookings[todaysBookingsIndex], ...record }
          }
        }
      }
    }

    Store.dispatch(todaysPatientsReducer.actions.setRecords(todaysBookings))
  }
}

export const findPreviousLatestBooking = async (booking) => {
  let tmpData = []
  let qry = null

  try {
    qry = {
      userRef: booking.userRef,
      dateCreated: { $lt: dayjs(new Date(booking.dateCreated)).format() },
      status: 'completed'
    }
    tmpData = await readAllBookings(qry)

    tmpData.sort((a, b) => {
      return new Date(b.dateCreated) - new Date(a.dateCreated)
    })

    if (tmpData.length > 0) {
      return tmpData[0]
    } else {
      return null
    }
  } catch (e) {
    throw new Error(e)
  }
}
