import dayjs from 'dayjs'
import store from '../../../store'

export const BookingDataTemplate = (patient, clinic, data) => {
  return {
    bookingDate: dayjs(),
    checkinData: {
      ailment: data.ailment,
      patientSelection: data.patientSelection,
      patientInput: data.patientInput
    },
    chiefComplaint: data.chiefComplaint,
    clinicName: clinic.name,
    clinicRecord: clinic,
    clinicRef: clinic._id,
    dateCreated: dayjs().format(),
    endTime: dayjs().add(15, 'minutes').format('HH:mm'),
    isVirtualVisit: false,
    medicalProfRef: store.getState().auth.agiliteUser._id,
    nurseProcedureInstructions: '',
    nurseProcedureRequired: false,
    otp: '',
    patientAddress: patient.physicalAddress,
    patientName: patient.firstName + ' ' + patient.lastName,
    patientRecord: patient,
    paymentMethod: data.paymentMethod,
    processRef: '',
    profession: store.getState().auth.agiliteUser.extraData.profession,
    professionalName: store.getState().auth.agiliteUser.firstName + ' ' + store.getState().auth.agiliteUser.lastName,
    service: data.service,
    startTime: dayjs().format('HH:mm'),
    status: store.getState().auth.agiliteUser.extraData.profession === 'doctor' ? 'diagnosis' : 'screening',
    userRef: patient._id,
    wasNoShow: false
  }
}
