import { message } from 'antd'
import CoreEnums from '../../../core/utils/enums'
import coreReducer from '../../../core/utils/reducer'
import PatientExaminationForm from '../../Bookings/components/booking-examination-wrapper'
import { executeBooking, getBookingState } from '../../Bookings/utils/bpm-utils'
import { handleError } from '../../lib/utils'
import { updateAllBookingsState } from '../../lib/booking-utils'
import store from '../../../store'
import { updateBooking } from '../../Bookings/utils/utils'

export const handleDashboardExamination = async (
  dispatch,
  record,
  profession,
  refreshFunction,
  loading,
  setLoading,
  skipScreening
) => {
  // const filter = { _id: record._id, claimed: { $exists: true, $ne: '' } }
  let title = null
  let processRecord = null
  let workflowHistory = null
  let newRecord = null
  // let tmpBookings = []

  const authState = store.getState().auth

  setLoading(true)

  try {
    if (skipScreening) {
      workflowHistory = await getBookingState([record.processRef])
      processRecord = await executeBooking(
        record.clinicRecord.bpmKey,
        record.processRef,
        'submit',
        `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`,
        workflowHistory.key
      )
      newRecord = await updateBooking({ status: processRecord.processStage }, { _id: record._id })
      workflowHistory = processRecord
      workflowHistory.history.splice(0, 1)
      updateAllBookingsState(newRecord)
    } else {
      workflowHistory = await getBookingState([record.processRef])
      workflowHistory.history.splice(0, 1)
    }

    switch (record.status) {
      case CoreEnums.bookingStatuses.screening:
        title = `Screening: ${record.patientName}`
        break
      case CoreEnums.bookingStatuses.diagnosis:
        title = `Diagnosis: ${record.patientName}`
        break
      case CoreEnums.bookingStatuses.billing:
        title = `Billing: ${record.patientName}`
        break
      default:
        title = 'Patient Examination'
    }

    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
        closable: true,
        label: title,
        children: (
          <PatientExaminationForm
            data={{ ...record, ...newRecord }}
            profession={profession}
            refreshView={refreshFunction}
            workflowHistory={workflowHistory}
          />
        )
      })
    )
  } catch (e) {
    message.error(handleError(e, true))
  }

  setLoading(false)
}
