import React, { useCallback, useEffect, useState } from 'react'
import { Col, Empty, Input, Radio, Table, Tooltip, message, theme } from 'antd'
import { handleError } from '../../../lib/utils'
import { countBookings, readAllBookings } from '../utils/utils'
import Templates from '../utils/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import PatientExaminationForm from '../../../Bookings/components/booking-examination-wrapper'
import { getBookingState } from '../../../Bookings/utils/bpm-utils'
import { handleCancelBooking } from '../../../lib/booking-utils'
import { updateBooking } from '../../../Bookings/utils/utils'
import { readSystemUsers } from '../../system-users/utils/utils'
import CoreEnums from '../../../../core/utils/enums'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import adminBookingsReducer from '../utils/reducer'

const BookingsListView = () => {
  const authState = useSelector((state) => state.auth)
  const adminBookingsState = useSelector((state) => state.adminBookings.data)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [rangeFilter, setRangeFilter] = useState('today')
  const state = useSelector((state) => state)

  useEffect(() => {
    handleGetAllBookings()
    // eslint-disable-next-line
  }, [page, pageSize])

  useEffect(() => {
    handleGetAllBookings()
    // eslint-disable-next-line
  }, [searchQuery, rangeFilter, state.bookings.data, state.bookings.dashboardData])

  const handleGetAllBookings = async () => {
    let tmpBookings = []
    let tmpUsers = []
    let tmpUserIds = []
    let qry = null
    let userQuery = null
    let tmpTotal = 0

    setLoading(true)

    try {
      if (searchQuery) {
        const searchTerms = searchQuery.trim().toLowerCase().split(/\s+/)

        // Prepare regex patterns for partial matching
        const regexPatterns = searchTerms.map((term) => new RegExp(term, 'i'))
        userQuery = {
          $or: [
            // Match against concatenated firstName and lastName with partial regexes
            {
              $expr: {
                $regexMatch: {
                  input: {
                    $concat: [{ $toLower: '$firstName' }, ' ', { $toLower: '$lastName' }]
                  },
                  regex: searchTerms.map((term) => `.*${term}.*`).join(''),
                  options: 'i'
                }
              }
            },
            // Match individually against firstName and lastName with partial regexes
            {
              $or: [
                {
                  $expr: {
                    $regexMatch: {
                      input: { $toLower: '$firstName' },
                      regex: regexPatterns.join('|'),
                      options: 'i'
                    }
                  }
                },
                {
                  $expr: {
                    $regexMatch: {
                      input: { $toLower: '$lastName' },
                      regex: regexPatterns.join('|'),
                      options: 'i'
                    }
                  }
                }
              ]
            },

            { phoneNumber: { $regex: searchQuery, $options: 'i' } },
            { email: { $regex: searchQuery, $options: 'i' } },
            { physicalAddress: { $regex: searchQuery, $options: 'i' } },
            { idNo: { $regex: searchQuery, $options: 'i' } }
          ]
        }
      }

      tmpUsers = await readSystemUsers(userQuery, null, null, '_id', true)

      for (const user of tmpUsers) {
        tmpUserIds.push(user._id)
      }

      if (rangeFilter === 'upcoming') {
        qry = {
          bookingDate: {
            $gte: dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD')
          }
        }
      } else if (rangeFilter === 'previous') {
        qry = {
          bookingDate: {
            $lt: dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z')
          }
        }
      } else if (rangeFilter === 'today') {
        qry = {
          bookingDate: {
            $gt: dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z'),
            $lt: dayjs(new Date()).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z')
          }
        }
      } else {
        qry = {}
      }

      // Finalize Query for bookings based on user ids
      if (tmpUserIds.length > 0) {
        qry.$or = [{ userRef: { $in: tmpUserIds } }, { medicalProfRef: { $in: tmpUserIds } }]
      }

      tmpTotal = await countBookings(qry)
      tmpBookings = await readAllBookings(qry, page, pageSize, {
        sort: {
          bookingDate: -1
        }
      })
      dispatch(adminBookingsReducer.actions.setRecords(tmpBookings))
      setTotal(tmpTotal)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSearch = (value) => {
    setLoading(true)
    debouncedFilter(value)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      if (query) {
        setSearchQuery(query)
      } else {
        handleGetAllBookings()
      }
    }, 1000),
    []
  )

  const handleOpenBooking = async (record) => {
    let workflowHistory = {}

    setLoading(true)

    try {
      if (record.processRef) {
        workflowHistory = await getBookingState([record.processRef])
        workflowHistory.history.splice(0, 1)
      }

      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          label: `Booking: ${record.patientName}`,
          children: (
            <PatientExaminationForm
              data={record}
              refreshView={handleGetAllBookings}
              workflowHistory={workflowHistory}
            />
          )
        })
      )
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  return (
    <CustomRow>
      <Col span={24}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <Tooltip>
              <CustomButton
                size='small'
                type='primary'
                text='Refresh'
                icon={faRefresh}
                onClick={() => handleGetAllBookings()}
                disabled={loading}
              />
            </Tooltip>
          </Col>
          <Col span={12}>
            <Input
              placeholder='Search by Patient Name or Address'
              suffix={<FontAwesomeIcon icon={faSearch} />}
              onChange={(e) => handleSearch(e.target.value)}
              allowClear
            />
          </Col>
          <Col>
            <Radio.Group disabled={loading} value={rangeFilter} onChange={(e) => setRangeFilter(e.target.value)}>
              <Radio.Button value='previous'>Previous</Radio.Button>
              <Radio.Button value='today'>Today</Radio.Button>
              <Radio.Button value='upcoming'>Upcoming</Radio.Button>
              <Radio.Button value='all'>All</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={adminBookingsState}
              locale={{
                emptyText: <Empty description='No Bookings found' />
              }}
              columns={Templates.columnTemplate(
                token,
                adminBookingsState,
                false,
                (record) => handleCancelBooking(authState, record, updateBooking, handleGetAllBookings, token),
                authState
              ).filter((record) => record.hidden !== true)}
              pagination={{
                current: page,
                pageSize,
                total: total,
                onChange: (page, pageSize) => {
                  setPage(page)
                  setPageSize(pageSize)
                },
                showSizeChanger: true,
                hideOnSinglePage: true,
                position: ['bottomCenter']
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleOpenBooking(record)
                  }
                }
              }}
              scroll={{ x: 1000 }}
            />
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default BookingsListView
