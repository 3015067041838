import { Button, Checkbox, Col, Collapse, Form, Input, InputNumber, Radio, Row, Space, Switch } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import CustomButton from '../../../../reusable-components/CustomButton'
import FileUploadComponent from '../../../../lib/file-upload-component'
import { generateFormItem } from '../../../../lib/utils'
import { AdditionalScreeningDataModel } from './additional-screening-dataModel'
import CardiacMedsListing from './cardiac/cardiac-meds-listing'
import { handleGenerateDiabetesReport } from './additional-screening-lib'

const formItemContainerStyle = {
  width: 250
}

export const additionScreeningOptions = (
  data,
  record,
  setRecord,
  form,
  token,
  diagnosis,
  reportLoading,
  setReportLoading,
  handleSave,
  saving
) => {
  const generateSectionTitle = (title) => (
    <h2
      style={{ padding: 6, borderRadius: 6, background: token.colorSecondary, color: '#ffffff', textAlign: 'center' }}
    >
      {title}
    </h2>
  )

  return [
    {
      label: 'Renal Care',
      key: 'renalCare',
      content: () => {
        const subSectionHeading = (text) => {
          return (
            <div style={{ marginBottom: 12, width: '100%', paddingBottom: 4, borderBottom: '1px black solid' }}>
              {text}
            </div>
          )
        }
        return (
          <Row gutter={[0, 12]}>
            <Col span={24}>{generateSectionTitle('Renal Care Screening')}</Col>
            <Col span={24}>
              <Collapse style={{ width: '100%' }}>
                {/* Current RRT Prescription */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Current RRT Prescription</h2>}
                  key='1'
                >
                  <Row justify='space-between'>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>UF</p>
                      <Form.Item
                        // label={'UF'}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'currentRrtPrescription', 'uf']}
                      >
                        <InputNumber addonAfter='mL/hr' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Duration</p>
                      <Form.Item
                        // label={'Duration'}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'currentRrtPrescription', 'duration']}
                      >
                        <InputNumber addonAfter='hours' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Frequency</p>
                      <Form.Item
                        // label={'Frequency'}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'currentRrtPrescription', 'frequency']}
                      >
                        <InputNumber addonAfter='session per week' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Qb</p>
                      <Form.Item
                        // label={'Qb'}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'currentRrtPrescription', 'qb']}
                      >
                        <InputNumber addonAfter='mL/min' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Qf</p>
                      <Form.Item
                        // label={'Qf'}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'currentRrtPrescription', 'qf']}
                      >
                        <InputNumber addonAfter='mL/hr' />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Dialyzer</p>
                      <Form.Item
                        // label={'Dialyzer'}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'currentRrtPrescription', 'dialyzer']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Volume Status Assessed Bioimpedance </p>
                      <Form.Item
                        // label={'Volume Status Assessed Bioimpedance '}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={[
                          'checkinData',
                          'renalCare',
                          'currentRrtPrescription',
                          'volumeStatusAssessedBioimpedance'
                        ]}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Meds administered during RRT session </p>
                      <Form.Item
                        // label={'Meds administered during RRT session '}
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'currentRrtPrescription', 'medsAdministered']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* Cancer Screening */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Cancer Screening</h2>}
                  key='2'
                >
                  <Row justify='space-between'>
                    <div style={{ width: '100%' }}>
                      <Form.Item
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cancerScreening']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* Measurement of Dialysis Efficientcy */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Measurement of Dialysis Efficiency</h2>}
                  key='3'
                >
                  <Row justify='space-between'>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Kt/V</p>

                      <Form.Item
                        // label='Kt/V'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'dialysisEfficiency', 'ktOverV']}
                      >
                        <InputNumber />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>URR</p>

                      <Form.Item
                        // label='URR'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'dialysisEfficiency', 'urr']}
                      >
                        <InputNumber addonAfter='%' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>URR</p>

                      <Form.Item
                        // label='URR'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'dialysisEfficiency', 'phosphateClearance']}
                      >
                        <InputNumber addonAfter='mL/min' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Body Weight</p>

                      <Form.Item
                        // label='Body Weight'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'dialysisEfficiency', 'weight']}
                      >
                        <InputNumber addonAfter='Kg' />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* HB Iron & Metabolism  */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Hb & Iron Metabolism</h2>}
                  key='4'
                >
                  <Row justify='space-between'>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Ferritin</p>
                      <Form.Item
                        // label='Ferritin'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'hbIronMetabolism', 'ferritin']}
                      >
                        <InputNumber addonAfter='ng/mL' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Hb</p>

                      <Form.Item
                        // label='Hb'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'hbIronMetabolism', 'Hb']}
                      >
                        <InputNumber addonAfter='g/dL' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>MCV</p>

                      <Form.Item
                        // label='MCV'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'hbIronMetabolism', 'mcv']}
                      >
                        <InputNumber addonAfter='fL' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Platelets</p>

                      <Form.Item
                        // label='Platelets'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'hbIronMetabolism', 'platelets']}
                      >
                        <InputNumber addonAfter='thousands/µL' />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Haematinics</p>

                      <Form.Item
                        // label='Haematinics '
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'hbIronMetabolism', 'haematinics']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Use of Blood Products</p>

                      <Form.Item
                        // label='Use of Blood Products '
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'hbIronMetabolism', 'useOfBloodProducts']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>BM Stimulants</p>

                      <Form.Item
                        // label='BM Stimulants'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'hbIronMetabolism', 'bmStimulants']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* Renal Preservation Index  */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Renal Preservation Index </h2>}
                  key='5'
                >
                  <Row justify='space-between'>
                    <div style={{ width: '100%' }}>
                      <Form.Item
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'renalPreservationIndex']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* Endocrine Review */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Endocrine Review</h2>}
                  key='6'
                >
                  <Row justify='space-between'>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>HbA1C</p>

                      <Form.Item
                        // label='HbA1C'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'endocrineReview', 'hba1c']}
                      >
                        <InputNumber addonAfter='%' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>PTH</p>

                      <Form.Item
                        // label='PTH'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'endocrineReview', 'pth']}
                      >
                        <InputNumber addonAfter='pg/mL' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>EPO</p>

                      <Form.Item
                        // label='EPO'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'endocrineReview', 'epo']}
                      >
                        <InputNumber addonAfter='IU/mL' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Vit D</p>

                      <Form.Item
                        // label='Vit D'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'endocrineReview', 'vitD']}
                      >
                        <InputNumber addonAfter='nmol/mL' />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>

                {/* Dermatological Issues */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Dermatological Issues</h2>}
                  key='13'
                >
                  <Row justify='space-between'>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Information</p>

                      <Form.Item
                        // label='Information'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'dermatologicalIssues', 'info']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <FileUploadComponent
                        label='Image Upload'
                        placeholder=''
                        token={token}
                        fileName={record.checkinData.renalCare?.dermatologicalIssues?.dermatologicalIssuesName}
                        file={record.checkinData.renalCare?.dermatologicalIssues?.dermatologicalIssues}
                        setState={(name, image) => {
                          setRecord({
                            ...record,
                            checkinData: {
                              ...record.checkinData,
                              renalCare: {
                                ...record.checkinData.renalCare,
                                dermatologicalIssues: {
                                  ...record.checkinData.renalCare?.dermatologicalIssues,
                                  dermatologicalIssuesName: name,
                                  dermatologicalIssues: image
                                }
                              }
                            }
                          })
                        }}
                        form={form}
                        fileNameFieldKey={[
                          'checkinData',
                          'renalCare',
                          'dermatologicalIssues',
                          'dermatologicalIssuesName'
                        ]}
                        dataFieldKey={['checkinData', 'renalCare', 'dermatologicalIssues', 'dermatologicalIssues']}
                      />
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* Cardiovascular Risk Factors */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Cardiovascular Risk Factors </h2>}
                  key='9'
                >
                  <Row justify='space-between'>
                    {subSectionHeading('Lipogram')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Total Cholesterol</p>

                      <Form.Item
                        // label='Total Cholesterol'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'lipogram', 'totalCholesterol']}
                      >
                        <InputNumber addonAfter='mmol/L' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>LDL</p>

                      <Form.Item
                        // label='LDL'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'lipogram', 'ldl']}
                      >
                        <InputNumber addonAfter='mmol/L' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>HDL</p>

                      <Form.Item
                        // label='HDL'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'lipogram', 'hdl']}
                      >
                        <InputNumber addonAfter='mmol/L' />
                      </Form.Item>
                    </div>

                    {subSectionHeading('')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Ankle-Brachial-Index</p>
                      <Form.Item
                        // label='Ankle-Brachial-Index'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'ankleBrachialIndex']}
                      >
                        <InputNumber />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Carotid US</p>

                      <Form.Item
                        // label='Carotid US'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'carotidUs']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>

                    {subSectionHeading('IMT & Pulse Wave Velocity')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>IMT</p>

                      <Form.Item
                        // label='IMT'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'imtPulseWaveVelocity', 'imt']}
                      >
                        <InputNumber addonAfter='mm' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Wave Velocity</p>

                      <Form.Item
                        // label='Wave Velocity '
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={[
                          'checkinData',
                          'renalCare',
                          'cardioRiskFactors',
                          'imtPulseWaveVelocity',
                          'pulseWaveVelocity'
                        ]}
                      >
                        <InputNumber addonAfter='m/s' />
                      </Form.Item>
                    </div>
                    {subSectionHeading('Echo')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>LVEF</p>

                      <Form.Item
                        // label='LVEF'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'echo', 'lvef']}
                      >
                        <InputNumber addonAfter='%' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Diastology</p>

                      <Form.Item
                        // label='Diastology'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'echo', 'diastology']}
                      >
                        <InputNumber addonAfter='%' />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>MAC</p>

                      <Form.Item
                        // label='MAC'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'echo', 'mac']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>

                    {subSectionHeading('Digital Fundoscopy')}
                    <div style={{ width: '100%' }}>
                      <Form.Item
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'cardioRiskFactors', 'digitalFundoscopy']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* Neuropsychiatric Review */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Neuropsychiatric Review </h2>}
                  key='10'
                >
                  <center>
                    <a href='#downloadLink'>
                      <CustomButton type='primary' text='Download Questionairre' onClick={() => {}} />
                    </a>
                  </center>
                </Collapse.Panel>
                {/* Nutritional Index */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Nutritional Index</h2>}
                  key='11'
                >
                  <Row justify='space-between'>
                    {subSectionHeading('Albumin')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Blood Albumin Level</p>

                      <Form.Item
                        // label='Blood Albumin Level'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'nutritionalIndex', 'albumin', 'bloodLevel']}
                      >
                        <InputNumber addonAfter='g/l' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Urine Albumin Level</p>

                      <Form.Item
                        // label='Urine Albumin Level'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'nutritionalIndex', 'albumin', 'urineLevel']}
                      >
                        <InputNumber addonAfter='mg/g' />
                      </Form.Item>
                    </div>
                    {subSectionHeading('')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Phosphate Clearance</p>

                      <Form.Item
                        // label='Phosphate Clearance'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={[
                          'checkinData',
                          'renalCare',
                          'nutritionalIndex',
                          'nutritionalIndex',
                          'phosphateClearance'
                        ]}
                      >
                        <InputNumber addonAfter='mL/min' />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
                {/* Bone & MSK Health:  */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Bone & MSK Health</h2>}
                  key='12'
                >
                  <Row justify='space-between'>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Hand Grip Strength</p>

                      <Form.Item
                        // label='Hand Grip Strength'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'boneMskHealth', 'gripStrength']}
                      >
                        <InputNumber addonAfter='kg' />
                      </Form.Item>
                    </div>

                    {subSectionHeading('Calcium & Phosphate')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Serum Calcium</p>

                      <Form.Item
                        // label='Serum Calcium'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'boneMskHealth', 'serumCalcium']}
                      >
                        <InputNumber addonAfter='mmol/L' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>Serum Phosphate</p>

                      <Form.Item
                        // label='Serum Phosphate'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'boneMskHealth', 'serumPhosphate']}
                      >
                        <InputNumber addonAfter='mmol/L' />
                      </Form.Item>
                    </div>
                    {subSectionHeading('')}
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>ALP</p>

                      <Form.Item
                        // label='ALP'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'boneMskHealth', 'alp']}
                      >
                        <InputNumber addonAfter='U/L' />
                      </Form.Item>
                    </div>
                    <div style={formItemContainerStyle}>
                      <p style={{ fontSize: '15px' }}>PTH</p>

                      <Form.Item
                        // label='PTH'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'boneMskHealth', 'pth']}
                      >
                        <InputNumber addonAfter='pmol/L' />
                      </Form.Item>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: '15px' }}>Bone Mineral Density</p>

                      <Form.Item
                        // label='Bone Mineral Density'
                        rules={[{ required: false, message: 'Please provide a value' }]}
                        name={['checkinData', 'renalCare', 'boneMskHealth', 'boneMineralDensity']}
                      >
                        <TextArea cols={4} />
                      </Form.Item>
                    </div>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        )
      }
    },
    {
      label: 'Occupation Health',
      key: 'occHealth',
      content: () => {
        const calculateHearingStatus = (value) => {
          return value > 6.4 ? 'abnormal' : 'normal'
        }
        const handleCalculateAgeFEV1 = () => {
          const fcv = form.getFieldValue(['checkinData', 'occuHealth', 'lungFunction', 'fvc'])
          const fev = form.getFieldValue(['checkinData', 'occuHealth', 'lungFunction', 'fev'])
          if (fev && fcv) {
            form.setFieldValue(
              ['checkinData', 'occuHealth', 'lungFunction', 'fevPercent'],
              (Number(fcv) / Number(fev)).toFixed(2)
            )
          }
        }

        const drugItems = [
          {
            label: <h5>Marijuana/Tetrahydrocannabinol/Cannabinoids</h5>,
            name: 'marijuanaTetrahydrocannabinolCannabinoids'
          },
          { label: <h5>Opiates/Morphine</h5>, name: 'opiatesMorphine' },
          { label: <h5>Cocaine/Benzoylecgonine/Metabolites</h5>, name: 'cocaineBenzoylecgonineMetabolites' },
          { label: <h5>Amphetamine/Methamphetamine</h5>, name: 'amphetamineMethamphetamine' },
          { label: <h5>Benzodiazepine</h5>, name: 'benzodiazepine' },
          { label: <h5>Phencyclidine</h5>, name: 'phencyclidine' }
        ]

        const physicalExamItems = [
          { label: <h5>General Appearance</h5>, key: 'generalAppearance' },
          { label: <h5>Posture and Gait</h5>, key: 'postureAndGait' },
          { label: <h5>Skin</h5>, key: 'skin' },
          { label: <h5>Spine</h5>, key: 'spine' },
          { label: <h5>Upper extremities and hands</h5>, key: 'upperExtremitiesAndHands' },
          { label: <h5>Lower extremities</h5>, key: 'lowerExtremities' },
          { label: <h5>Neurological assessment</h5>, key: 'neurologicalAssessment' },
          { label: <h5>Romberg and Balance</h5>, key: 'rombergAndBalance' },
          { label: <h5>ENT</h5>, key: 'ent' },
          { label: <h5>Respiratory</h5>, key: 'respiratory' },
          { label: <h5>Abdominal Exam</h5>, key: 'abdominalExam' }
        ]
        return (
          <Row gutter={[0, 12]}>
            <Col span={24}>{generateSectionTitle('Occupational Health Screening')}</Col>
            <Col span={24}>
              <Collapse style={{ width: '100%' }}>
                {/* Physical Exam */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Physical Exam</h2>}
                  key='1'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    {physicalExamItems.map((item) => (
                      <Col span={24}>
                        <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                          <p>{item.label}</p>
                          <Form.Item
                            style={{ margin: 0 }}
                            name={['checkinData', 'occuHealth', 'physicalExam', item.key]}
                          >
                            <Radio.Group buttonStyle='solid'>
                              <Radio.Button value='normal'>Normal</Radio.Button>
                              <Radio.Button value='abnormal'>Abnormal</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Space>
                      </Col>
                    ))}
                  </Row>
                </Collapse.Panel>
                {/* ECG */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>ECG</h2>}
                  key='2'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <Col span={24}>
                      <Space style={{ justifyContent: 'space-between' }}>
                        <p>Required? </p>{' '}
                        <Form.Item name={['checkinData', 'occuHealth', 'ecg', 'required']} style={{ margin: 0 }}>
                          <Switch
                            checked={form.getFieldValue(['checkinData', 'occuHealth', 'ecg', 'required'])}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                          />
                        </Form.Item>
                      </Space>
                    </Col>
                    {form.getFieldValue(['checkinData', 'occuHealth', 'ecg', 'required']) ? (
                      <>
                        <Col span={24}>
                          <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>Status</p>
                            <Form.Item style={{ margin: 0 }} name={['checkinData', 'occuHealth', 'ecg', 'status']}>
                              <Radio.Group buttonStyle='solid'>
                                <Radio.Button value='normal'>Normal</Radio.Button>
                                <Radio.Button value='abnormal'>Abnormal</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={24}>
                          <FileUploadComponent
                            label='Image Upload'
                            placeholder=''
                            token={token}
                            fileName={record.checkinData.occuHealth?.ecg?.fileName}
                            file={record.checkinData.occuHealth?.ecg?.file}
                            setState={(name, image) => {
                              setRecord({
                                ...record,
                                checkinData: {
                                  ...record.checkinData,
                                  occuHealth: {
                                    ...record.checkinData.occuHealth,
                                    ecg: {
                                      ...record.checkinData.occuHealth?.ecg,
                                      fileName: name,
                                      file: image
                                    }
                                  }
                                }
                              })
                            }}
                            form={form}
                            fileNameFieldKey={['checkinData', 'occuHealth', 'ecg', 'fileName']}
                            dataFieldKey={['checkinData', 'occuHealth', 'ecg', 'file']}
                          />
                        </Col>
                      </>
                    ) : undefined}
                  </Row>
                </Collapse.Panel>
                {/* Visual Screening */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Vision Screening</h2>}
                  key='3'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <Col span={24}>
                      <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p>Near Vision</p>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={['checkinData', 'occuHealth', 'visionScreening', 'nearVision']}
                        >
                          <Radio.Group buttonStyle='solid'>
                            <Radio.Button value='pass'>P</Radio.Button>
                            <Radio.Button value='fail'>F</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p>Far nearVision</p>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={['checkinData', 'occuHealth', 'visionScreening', 'farVision']}
                        >
                          <Radio.Group buttonStyle='solid'>
                            <Radio.Button value='pass'>P</Radio.Button>
                            <Radio.Button value='fail'>F</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Space>
                    </Col>
                    <Col span={24}>
                      <FileUploadComponent
                        label='Image Upload'
                        placeholder=''
                        token={token}
                        fileName={record.checkinData.occuHealth?.visionScreening?.fileName}
                        file={record.checkinData.occuHealth?.visionScreening?.file}
                        setState={(name, image) => {
                          setRecord({
                            ...record,
                            checkinData: {
                              ...record.checkinData,
                              occuHealth: {
                                ...record.checkinData.occuHealth,
                                visionScreening: {
                                  ...record.checkinData.occuHealth?.visionScreening,
                                  fileName: name,
                                  file: image
                                }
                              }
                            }
                          })
                        }}
                        form={form}
                        fileNameFieldKey={['checkinData', 'occuHealth', 'visionScreening', 'fileName']}
                        dataFieldKey={['checkinData', 'occuHealth', 'visionScreening', 'file']}
                      />
                    </Col>
                  </Row>
                </Collapse.Panel>
                {/* Audiogram */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Hearing Test</h2>}
                  key='4'
                >
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={formItemContainerStyle}>
                          <p style={{ fontSize: '15px' }}>Range</p>
                          <Form.Item
                            style={{ margin: 0 }}
                            // label='Range'
                            name={['checkinData', 'occuHealth', 'hearingTest', 'range']}
                          >
                            <InputNumber
                              addonAfter='%'
                              placeholder='1.7'
                              onChange={(value) => {
                                form.setFieldValue(
                                  ['checkinData', 'occuHealth', 'hearingTest', 'status'],
                                  calculateHearingStatus(value)
                                )
                              }}
                            />
                          </Form.Item>
                        </div>

                        {form.getFieldValue(['checkinData', 'occuHealth', 'hearingTest', 'range']) ? (
                          <div style={formItemContainerStyle}>
                            <Form.Item
                              label='Status'
                              style={{ margin: 0 }}
                              name={['checkinData', 'occuHealth', 'hearingTest', 'status']}
                              initialValue='test'
                            >
                              <Input readOnly />
                            </Form.Item>
                          </div>
                        ) : undefined}
                      </Space>
                    </Col>
                    <Col span={24}>
                      <FileUploadComponent
                        label='Image Upload'
                        placeholder=''
                        token={token}
                        fileName={record.checkinData.occuHealth?.hearingTest?.fileName}
                        file={record.checkinData.occuHealth?.hearingTest?.file}
                        setState={(name, image) => {
                          setRecord({
                            ...record,
                            checkinData: {
                              ...record.checkinData,
                              occuHealth: {
                                ...record.checkinData.occuHealth,
                                hearingTest: {
                                  ...record.checkinData.occuHealth?.hearingTest,
                                  fileName: name,
                                  file: image
                                }
                              }
                            }
                          })
                        }}
                        form={form}
                        fileNameFieldKey={['checkinData', 'occuHealth', 'hearingTest', 'fileName']}
                        dataFieldKey={['checkinData', 'occuHealth', 'hearingTest', 'file']}
                      />
                    </Col>
                  </Row>
                </Collapse.Panel>
                {/* Lung Function */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Lung Function</h2>}
                  key='5'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <Col span={24}>
                      <Space wrap style={{ width: '100%', justifyContent: 'space-between' }}>
                        <div style={formItemContainerStyle}>
                          <p style={{ fontSize: '15px' }}>FVC</p>
                          <Form.Item
                            style={{ margin: 0 }}
                            // label='FVC'
                            name={['checkinData', 'occuHealth', 'lungFunction', 'fvc']}
                          >
                            <InputNumber
                              addonAfter='%'
                              placeholder=''
                              onChange={() => {
                                handleCalculateAgeFEV1()
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div style={formItemContainerStyle}>
                          <p style={{ fontSize: '15px' }}>FEV</p>
                          <Form.Item
                            style={{ margin: 0 }}
                            // label='FEV'
                            name={['checkinData', 'occuHealth', 'lungFunction', 'fev']}
                          >
                            <InputNumber
                              addonAfter='%'
                              placeholder=''
                              onChange={() => {
                                handleCalculateAgeFEV1()
                              }}
                            />
                          </Form.Item>
                        </div>
                        {form.getFieldValue(['checkinData', 'occuHealth', 'lungFunction', 'fvc']) &&
                        form.getFieldValue(['checkinData', 'occuHealth', 'lungFunction', 'fev']) ? (
                          <div style={formItemContainerStyle}>
                            <Form.Item
                              style={{ margin: 0 }}
                              label='FEV1%'
                              name={['checkinData', 'occuHealth', 'lungFunction', 'fevPercent']}
                            >
                              <InputNumber addonAfter='%' placeholder='' />
                            </Form.Item>
                          </div>
                        ) : undefined}
                      </Space>
                    </Col>
                    <Col span={24}>
                      <p style={{ fontSize: '15px' }}>Notes</p>

                      <Form.Item
                        style={{ margin: 0 }}
                        name={['checkinData', 'occuHealth', 'lungFunction', 'notes']}
                        // label='Notes'
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <FileUploadComponent
                        label='Image Upload'
                        placeholder=''
                        token={token}
                        fileName={record.checkinData.occuHealth?.lungFunction?.fileName}
                        file={record.checkinData.occuHealth?.lungFunction?.file}
                        setState={(name, image) => {
                          setRecord({
                            ...record,
                            checkinData: {
                              ...record.checkinData,
                              occuHealth: {
                                ...record.checkinData.occuHealth,
                                lungFunction: {
                                  ...record.checkinData.occuHealth?.lungFunction,
                                  fileName: name,
                                  file: image
                                }
                              }
                            }
                          })
                        }}
                        form={form}
                        fileNameFieldKey={['checkinData', 'occuHealth', 'lungFunction', 'fileName']}
                        dataFieldKey={['checkinData', 'occuHealth', 'lungFunction', 'file']}
                      />
                    </Col>
                  </Row>
                </Collapse.Panel>
                {/* Multi Panel Drug Test */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Multi Panel Drug Test</h2>}
                  key='6'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    {drugItems.map((item) => {
                      return (
                        <>
                          <Col span={24}>
                            <Form.Item
                              label={item.label}
                              style={{ margin: 0 }}
                              name={['checkinData', 'occuHealth', 'multiPanelDrugTest', item.name, 'result']}
                            >
                              <Radio.Group buttonStyle='solid'>
                                <Radio.Button value='negative'>Negative</Radio.Button>
                                <Radio.Button value='nonNegative'>Non-negative</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          {form.getFieldValue([
                            'checkinData',
                            'occuHealth',
                            'multiPanelDrugTest',
                            item.name,
                            'result'
                          ]) === 'nonNegative' ? (
                            <Col span={24}>
                              <Form.Item
                                label='Action Required'
                                style={{ margin: 0 }}
                                name={['checkinData', 'occuHealth', 'multiPanelDrugTest', item.name, 'action']}
                              >
                                <TextArea rows={4} />
                              </Form.Item>
                            </Col>
                          ) : undefined}
                        </>
                      )
                    })}
                  </Row>
                </Collapse.Panel>
                {/* Results */}
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Result</h2>}
                  key='7'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <Col span={24}>
                      <Form.Item
                        style={{ margin: 0 }}
                        rules={[{ required: true, message: 'Result entry required' }]}
                        name={['checkinData', 'occuHealth', 'result', 'status']}
                      >
                        <Radio.Group buttonStyle='solid'>
                          <Radio.Button value='fit'>Fit</Radio.Button>
                          <Radio.Button value='withCondition'>With Condition</Radio.Button>
                          <Radio.Button value='notFit'>Not Fit</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {form.getFieldValue(['checkinData', 'occuHealth', 'result', 'status']) === 'withCondition' ? (
                      <Col span={24}>
                        <Form.Item
                          label='Condition'
                          style={{ margin: 0 }}
                          rules={[{ required: true, message: 'Result condition required' }]}
                          name={['checkinData', 'occuHealth', 'result', 'condition']}
                        >
                          <TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    ) : undefined}
                    <Col span={24}>
                      <Space>
                        <Form.Item
                          style={{ margin: 0 }}
                          rules={[{ required: true, message: 'Result entry required' }]}
                          name={['checkinData', 'occuHealth', 'result', 'addInfoRequired']}
                          valuePropName='checked'
                        >
                          <Checkbox />
                        </Form.Item>
                        <p>Further Info Required</p>
                      </Space>
                    </Col>

                    {form.getFieldValue(['checkinData', 'occuHealth', 'result', 'addInfoRequired']) ? (
                      <Col span={24}>
                        <p style={{ fontSize: '15px' }}>Required Information</p>
                        <Form.Item
                          // label='Required Information'
                          style={{ margin: 0 }}
                          rules={[{ required: true, message: 'Result condition required' }]}
                          name={['checkinData', 'occuHealth', 'result', 'addInfo']}
                        >
                          <TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    ) : undefined}
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        )
      }
    },
    {
      label: 'Diabetic Screening',
      key: 'diabetesScreening',
      content: () => {
        return (
          <Row gutter={[0, 12]}>
            <Col span={24}>
              {generateSectionTitle(
                <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                  <>Diabetes Screening</>
                  <Button
                    disabled={saving}
                    loading={saving}
                    onClick={() => {
                      // TODO: Handle save
                      handleSave(false, {}, true, handleGenerateDiabetesReport)
                      // handleGenerateDiabetesReport(record, data, diagnosis, onFail, onSuccess)
                    }}
                  >
                    Print Report
                  </Button>
                </Space>
              )}
            </Col>
            <Col span={24}>
              <Collapse style={{ width: '100%' }}>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>OHA/Insulin</h2>}
                  key='ohaInsulin'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <CardiacMedsListing path={AdditionalScreeningDataModel().ohaInsulinList.key} form={form} />
                  </Row>
                </Collapse.Panel>

                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Lipid Meds</h2>}
                  key='lipids'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <CardiacMedsListing path={AdditionalScreeningDataModel().lipidMedsListed.key} form={form} />
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Aspirin Meds</h2>}
                  key='aspirin'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <CardiacMedsListing path={AdditionalScreeningDataModel().aspirinMedsListed.key} form={form} />
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>BP Meds</h2>}
                  key='bp'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <CardiacMedsListing path={AdditionalScreeningDataModel().bpMedsListed.key} form={form} />
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Other Meds</h2>}
                  key='otherMeds'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <CardiacMedsListing path={AdditionalScreeningDataModel().otherMedsListed.key} form={form} />
                  </Row>
                </Collapse.Panel>

                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>ECG</h2>}
                  key='ecg'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().ecgApplicable, form)}</Col>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().ecgDate, form)}</Col>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().ecgStatus, form)}</Col>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().ecgInfarct, form)}</Col>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().ecgAngina, form)}</Col>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().ecgArthy, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Neuro</h2>}
                  key='neuro'
                >
                  <Row justify='' gutter={[24, 12]}>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().neuroNeuropathy, form)}</Col>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().neuroPain, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Kidneys</h2>}
                  key='kidneys'
                >
                  <Row gutter={[0, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().kidneyNephropathy, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Eyes</h2>}
                  key='eyes'
                >
                  <Row gutter={[24, 12]}>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().eyesRetinopathyLeft, form)}</Col>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().eyesRetinopathyRight, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Feet</h2>}
                  key='feet'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>
                      <Space>
                        {generateFormItem(AdditionalScreeningDataModel().feetPulsesL, form)}
                        {generateFormItem(AdditionalScreeningDataModel().feetPulsesR, form)}
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Space>
                        {generateFormItem(AdditionalScreeningDataModel().feetSensationL, form)}
                        {generateFormItem(AdditionalScreeningDataModel().feetSensationR, form)}
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Space>
                        {generateFormItem(AdditionalScreeningDataModel().feetVibrationL, form)}
                        {generateFormItem(AdditionalScreeningDataModel().feetVibrationR, form)}
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Space>
                        {generateFormItem(AdditionalScreeningDataModel().feetCallusL, form)}
                        {generateFormItem(AdditionalScreeningDataModel().feetCallusR, form)}
                      </Space>
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>PVD</h2>}
                  key='pvd'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().pvd, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Lungs</h2>}
                  key='lungs'
                >
                  <Row justify='space-between' gutter={[0, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().lungsStatus, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Skin</h2>}
                  key='skin'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().skinStatus, form)}</Col>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().skinInsInjSightHealthy, form)}</Col>
                    <Col>{generateFormItem(AdditionalScreeningDataModel().skinOedema, form)}</Col>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().skinNotes, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Comorbidities/History</h2>}
                  key='comorbiditiesHistory'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().comorbiditiesHistory, form)}</Col>
                    <Col span={24}>
                      {generateFormItem(AdditionalScreeningDataModel().comorbiditiesHistoryVacc, form)}
                    </Col>
                    <Col span={24}>
                      {generateFormItem(AdditionalScreeningDataModel().comorbiditiesHistoryVaccList, form)}
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Autonomic Neuropath</h2>}
                  key='autonomicNeuropath'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().autonomicNeuropath, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Urinary</h2>}
                  key='urinary'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().urinary, form)}</Col>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().urineTesting, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Dental</h2>}
                  key='dental'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().dental, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Visits Attended</h2>}
                  key='visitsAttended'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().visitsAttended, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Assessment</h2>}
                  key='assessment'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().assessment, form)}</Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.225)' }}
                  header={<h2 style={{ fontWeight: 'normal' }}>Plan</h2>}
                  key='plan'
                >
                  <Row gutter={[24, 12]}>
                    <Col span={24}>{generateFormItem(AdditionalScreeningDataModel().plan, form)}</Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        )
      }
    }
  ]
}
