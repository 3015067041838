import React, { useCallback, useEffect, useRef, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Col, Input, message, Table, theme } from 'antd'
import { handleError } from '../../../lib/utils'
import SystemUsersTemplates from '../../../Admin/system-users/utils/templates'
import debounce from 'lodash/debounce'
import { readSystemUsers } from '../../../Admin/system-users/utils/utils'

const TeamSearch = ({ setProfile }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const searchFieldRef = useRef(null) // Create a reference for the input field

  useEffect(() => {
    handleTeamSearch()
    // eslint-disable-next-line
  }, [searchQuery])
  const [searching, setSearching] = useState(false)

  const handleTeamSearch = async () => {
    try {
      let tmpUsers = []
      let qry = { 'extraData.role.type': 'medical_professional' }
      setSearching(true)
      try {
        if (searchQuery) {
          const searchTerms = searchQuery.trim().toLowerCase().split(/\s+/)

          // Prepare regex patterns for partial matching
          const regexPatterns = searchTerms.map((term) => new RegExp(term, 'i'))
          qry = {
            ...qry,
            $or: [
              // Match against concatenated firstName and lastName with partial regexes
              {
                $expr: {
                  $regexMatch: {
                    input: {
                      $concat: [{ $toLower: '$firstName' }, ' ', { $toLower: '$lastName' }]
                    },
                    regex: searchTerms.map((term) => `.*${term}.*`).join(''),
                    options: 'i'
                  }
                }
              },
              // Match individually against firstName and lastName with partial regexes
              {
                $or: [
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$firstName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  },
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$lastName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  }
                ]
              },

              { phoneNumber: { $regex: searchQuery, $options: 'i' } },
              { email: { $regex: searchQuery, $options: 'i' } },
              { physicalAddress: { $regex: searchQuery, $options: 'i' } },
              { idNo: { $regex: searchQuery, $options: 'i' } }
            ]
          }
          tmpUsers = await readSystemUsers(qry)
        }
        setSearchResults(tmpUsers)
      } catch (e) {
        message.error(handleError(e))
      }
      setSearching(false)
    } catch (e) {}
  }

  const handleTeamQuery = (query) => {
    setSearching(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )
  const { token } = theme.useToken()
  return (
    <CustomRow>
      <Col span={24}>
        <>
          <Input ref={searchFieldRef} onChange={(e) => handleTeamQuery(e.target.value)} />
          <Table
            size='small'
            bordered
            onRow={(record) => {
              return {
                onClick: () => {
                  setSearchQuery('')
                  setSearchResults([])
                  setProfile(record)
                }
              }
            }}
            loading={searching}
            dataSource={searchResults}
            columns={SystemUsersTemplates.columnTemplate([], 'patient', [], token, null, null, true).filter(
              (i) => !i.hidden
            )}
          />
        </>
      </Col>
    </CustomRow>
  )
}

export default TeamSearch
