import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readFileArchiveRecords = (qry) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('file_archive', 'read', {
          filter: JSON.stringify(qry)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createFileArchiveRecord = (data) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('file_archive', 'create', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteFileArchiveRecord = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('file_archive', 'delete', {
          recordId
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
