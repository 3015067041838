import React, { useState } from 'react'
import InventoryManagement from './inventory-management'
import InventoryActivity from './inventory-activity'
import LocationActivity from './location-activity'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomRow from '../../reusable-components/CustomRow'
import { Col, Input, Select, Space } from 'antd'
import { useSelector } from 'react-redux'
import CustomButton from '../../reusable-components/CustomButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const InvetoryWrapper = () => {
  const clinicState = useSelector((state) => state.clinics.data)
  const [location, setLocation] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [clinicQuery, setClinicQuery] = useState('')
  const [filter, setFilter] = useState({
    searchQuery: '',
    clinicQuery: ''
  })
  const [currentInventoryItem, setCurrentInventoryItem] = useState(null)
  const handleBack = () => {
    if (location) {
      if (filter.clinicQuery) {
        setCurrentInventoryItem(null)
      }
      setLocation(null)
    } else {
      if (currentInventoryItem) {
        setCurrentInventoryItem(null)
      }
    }
  }
  const generateTitle = () => {
    if (location) {
      return `Activity for ${currentInventoryItem.description} at ${location.clinic.name}`
    } else {
      if (currentInventoryItem) {
        return `Activity for ${currentInventoryItem.description}`
      } else {
        return 'Inventory Management'
      }
    }
  }
  const handleSetFilters = (isClear) => {
    if (isClear) {
      setSearchQuery('')
      setClinicQuery('')
      setFilter({
        searchQuery: '',
        clinicQuery: ''
      })
    } else {
      setFilter({ searchQuery, clinicQuery })
    }
  }
  return (
    <>
      <ContainerCard backFunction={location || currentInventoryItem ? handleBack : undefined} title={generateTitle()}>
        <CustomRow>
          {!location && !currentInventoryItem ? (
            <Col xs={24} sm={24} md={24}>
              <CustomRow className='basic-card'>
                <Col span={24}>
                  <h2>Filters:</h2>
                </Col>
                <Col span={24}>
                  <Space wrap style={{ width: '100%' }}>
                    <Select
                      placeholder='Select Clinic'
                      value={clinicQuery ? clinicQuery : undefined}
                      onChange={(clinic) => {
                        setClinicQuery(clinic)
                      }}
                      style={{ width: 300, maxWidth: '100%' }}
                      options={clinicState.map((clinic) => {
                        return {
                          label: clinic.name,
                          value: clinic._id
                        }
                      })}
                    />
                    <Input
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value)
                      }}
                      style={{ width: 450, maxWidth: '100%' }}
                      placeholder='Search query...'
                      suffix={<FontAwesomeIcon icon={faSearch} />}
                    />
                    <CustomButton
                      type='danger'
                      text='Clear Filters'
                      size='small'
                      onClick={() => {
                        handleSetFilters(true)
                      }}
                    />
                    <CustomButton
                      type='success'
                      text='Apply'
                      size='small'
                      onClick={() => {
                        handleSetFilters()
                      }}
                    />
                  </Space>
                </Col>
              </CustomRow>
            </Col>
          ) : undefined}
          <Col span={24}>
            <CustomRow className='basic-card'>
              {currentInventoryItem ? (
                location ? (
                  <Col span={24}>
                    <LocationActivity item={currentInventoryItem} location={location} setLocation={setLocation} />
                  </Col>
                ) : (
                  <Col span={24}>
                    <InventoryActivity item={currentInventoryItem} setLocation={setLocation} />
                  </Col>
                )
              ) : (
                <Col span={24}>
                  <InventoryManagement
                    setCurrentInventoryItem={setCurrentInventoryItem}
                    setLocation={setLocation}
                    location={location}
                    filter={filter}
                  />
                </Col>
              )}
            </CustomRow>
          </Col>
        </CustomRow>
      </ContainerCard>
    </>
  )
}

export default InvetoryWrapper
