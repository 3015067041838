import React, { useEffect, useState } from 'react'
import './index.css'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Col, Divider, Space, message, theme } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import { confirmAfterHours, isPublicHoliday } from './search-engine-widget-utils'
import { handleError } from '../../../../lib/utils'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import { deviceDetect } from 'react-device-detect'

const BookingSummary = ({ booking, patient, serviceInfo, isVirtualVisit, isScheduledBooking }) => {
  const clinicState = useSelector((state) => state.clinics.data)
  const authState = useSelector((state) => state.auth)
  const [afterHourDetails, setAfterHoursDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const infoDisplayStyle = {
    // color: '#ffffff'
  }
  const generatePatientInfo = () => {
    if (patient) {
      return `${patient.firstName} ${patient.lastName}`
    } else {
      return `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
    }
  }
  useEffect(() => {
    handleConfirmAfterHours()
    // eslint-disable-next-line
  }, [])

  const handleConfirmAfterHours = async () => {
    let tmpAfterHourDetails = null
    setLoading(true)
    try {
      tmpAfterHourDetails = await confirmAfterHours(dayjs(booking.bookingDate).day(), booking.startTime)
      setAfterHoursDetails(tmpAfterHourDetails)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }
  const { token } = theme.useToken()
  const detailsSpaceStyle = {
    width: '100%'
  }
  const firstSpaceItemStyle = {
    width: 175
  }
  const dividerStyle = {
    margin: '8px 0'
  }

  return (
    <CustomRow style={{ boxShadow: '0 0 10px 0 rgba(0,0,0,0.225)' }} className='basic-card' gutter={[4, 4]}>
      {loading ? (
        <AgiliteSkeleton skActive spinnerTip='Loading booking deatils...' />
      ) : (
        <>
          <Col span={24}>
            <h1 style={{ background: token.colorPrimary, color: 'white', padding: 8, borderRadius: 6 }}>
              {isVirtualVisit
                ? 'SCHEDULE VIRTUAL CONSULT'
                : booking.clinicRef === 'Home Visit'
                ? 'BOOK HOME VISIT'
                : 'BOOK CLINIC VISIT'}
            </h1>
          </Col>
          <Col span={24}>
            <div className='booking-summary-container'>
              {deviceDetect().isMobile ? undefined : (
                <div className='booking-summary-date' style={{ background: token.colorPrimary }}>
                  <center style={{ textAlign: 'center' }}>
                    <h1>{dayjs(booking.bookingDate).format('DD').toUpperCase()}</h1>
                    <h1>{dayjs(booking.bookingDate).format('MMM').toUpperCase()}</h1>
                  </center>
                </div>
              )}
              <div style={infoDisplayStyle}>
                {deviceDetect().isMobile ? (
                  <Space style={detailsSpaceStyle}>
                    <p style={firstSpaceItemStyle}>Date:</p>
                    <b>
                      {dayjs(booking.bookingDate).format('DD').toUpperCase()}{' '}
                      {dayjs(booking.bookingDate).format('MMM').toUpperCase()}
                    </b>
                  </Space>
                ) : undefined}
                <Space style={detailsSpaceStyle}>
                  <p style={firstSpaceItemStyle}>Medical Professional:</p>
                  <b>
                    {' '}
                    {booking.medicalProf.firstName} {booking.medicalProf.lastName}{' '}
                  </b>
                </Space>

                <Space style={detailsSpaceStyle}>
                  <p style={firstSpaceItemStyle}>Appointment For:</p>
                  <b> {generatePatientInfo()} </b>
                </Space>
                <Divider style={dividerStyle} />
                <Space style={detailsSpaceStyle}>
                  <p style={firstSpaceItemStyle}>Timeslot:</p>
                  <b> {booking.startTime} </b>
                </Space>
                <Space style={detailsSpaceStyle}>
                  <p style={firstSpaceItemStyle}> Type: </p>
                  <b>{serviceInfo.name}</b>
                </Space>
                {booking.clinicRef !== 'Home Visit' && !isVirtualVisit ? (
                  <Space style={detailsSpaceStyle}>
                    <p style={firstSpaceItemStyle}>Location: </p>
                    <b>{clinicState.find((i) => i._id === booking.clinicRef).name}</b>
                  </Space>
                ) : undefined}

                <Divider style={dividerStyle} />
                <Space style={detailsSpaceStyle}>
                  <p style={firstSpaceItemStyle}>Appointment Fee: </p>
                  <b>R{serviceInfo.rate}</b>
                </Space>
                {afterHourDetails || isPublicHoliday(booking.bookingDate) ? (
                  <Space style={detailsSpaceStyle}>
                    <p style={firstSpaceItemStyle}>After Hours Fee:</p>
                    <b>R200</b>{' '}
                  </Space>
                ) : undefined}
                {!isScheduledBooking ? (
                  <>
                    {afterHourDetails || isPublicHoliday(booking.bookingDate) ? (
                      <Space style={detailsSpaceStyle}>
                        <p style={firstSpaceItemStyle}>Total Fee:</p>
                        <b>R{serviceInfo.rate + 200}</b>
                      </Space>
                    ) : (
                      <p>Please note: A public holiday fee of R200 may be levied.</p>
                    )}
                  </>
                ) : undefined}
              </div>
            </div>
          </Col>
        </>
      )}
    </CustomRow>
  )
}

export default BookingSummary
