import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Select, message, theme } from 'antd'
import dayjs from 'dayjs'
import { deviceDetect } from 'react-device-detect'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import CoreEnums from '../../../../core/utils/enums'

const BookingDetailsCapture = ({
  loading,
  setBooking,
  booking,
  setupServiceValue,
  setupServices,
  location,
  setLocation,
  setPatient,
  patient,
  dependants,
  isHomeVisit,
  isVirtualVisit,
  isReceptionBooking,
  handleSubmit,
  prevStep,
  clinics,
  noLocation,
  handleLocationChange
}) => {
  const [provinces, setProvinces] = useState([])
  const [provincesLoading, setProvincesLoading] = useState(false)
  const { token } = theme.useToken()
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day')
  }

  useEffect(() => {
    setUpProvinceOptions()
    // eslint-disable-next-line
  }, [clinics])

  const setUpProvinceOptions = () => {
    let tmpProvinces = [{ label: '- Select Province -', value: '' }]

    setProvincesLoading(true)
    Object.keys(CoreEnums.provinces).forEach((key) => {
      const province = CoreEnums.provinces[key]
      const hasProvince = clinics.find((clinic) => clinic.province === province)
      if (hasProvince) {
        tmpProvinces.push({ label: province, value: province })
      }
    })
    setProvinces(tmpProvinces)
    setProvincesLoading(false)
  }
  return (
    <>
      <CustomRow style={{ alignItems: 'center', background: token.colorPrimary }} className='basic-card'>
        <Col span={24}>
          <CustomRow style={{ color: 'white' }} gutter={[12, 8]}>
            <Col span={24}>
              <h2>
                {isHomeVisit ? 'BOOK HOME VISIT' : isVirtualVisit ? 'SCHEDULE VIRTUAL CONSULT' : 'BOOK CLINIC VISIT'}
              </h2>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12}>
              <p>Date</p>
              <DatePicker
                disabled={loading}
                value={booking.date}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setBooking({ ...booking, date: value })
                }}
                disabledDate={disabledDate}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <p>Appointment for</p>
              <Select
                style={{ width: '100%' }}
                disabled={loading}
                defaultValue=''
                onChange={(value) => {
                  if (value) {
                    setLocation(null)
                    setPatient(JSON.parse(value))
                  } else {
                    setLocation(null)
                    setPatient(null)
                  }
                }}
                options={
                  isReceptionBooking
                    ? [{ label: patient.firstName + ' ' + patient.lastName, value: '' }]
                    : [
                        ...dependants.map((dependant) => {
                          return { value: dependant.value, label: dependant.label }
                        })
                      ]
                }
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <p>Type</p>
              <Select
                disabled={loading}
                placeholder='Select a Booking Type'
                defaultValue={setupServiceValue()}
                value={booking.service}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setBooking({ ...booking, service: value })
                }}
                loading={provincesLoading}
                options={setupServices()}
              />
            </Col>
            {!isVirtualVisit ? (
              <Col xs={24} sm={24} md={24} lg={12}>
                <p>Province</p>
                <Select
                  disabled={loading}
                  placeholder='Select a Province'
                  defaultValue={booking.province || ''}
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setBooking({ ...booking, province: value })
                  }}
                  options={provinces}
                />
              </Col>
            ) : undefined}
            <Col span={24}>
              <p>Location</p>
              <GoogleAddress
                id='map'
                selectProps={{
                  placeholder: location?.address ? location.address : 'Search for an address',
                  value: location?.address ? location.address : 'Search for an address',
                  onChange: handleLocationChange
                }}
              />
            </Col>
            <Col span={24}>
              {deviceDetect().isMobile ? undefined : (
                <CustomButton
                  style={{ width: '100%', background: 'white', color: token.colorPrimary }}
                  loading={loading || !location}
                  onClick={() => {
                    if (!booking.date) return message.error('Please select a date.')
                    if (!booking.province && !isVirtualVisit) return message.error('Please select a province.')
                    handleSubmit()
                  }}
                  text={
                    loading ? (
                      <h2>SEARCHING</h2>
                    ) : location ? (
                      <h2>FIND AVAILABLE PRACTITIONERS</h2>
                    ) : (
                      <h2>DETERMINING LOCATION</h2>
                    )
                  }
                />
              )}
            </Col>
          </CustomRow>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={8}>
          {noLocation ? (
            <Empty description='No location specified. Please search for an Address' />
          ) : (
            <div style={{ height: 300, maxHeight: '100%', width: '100%', overflow: 'hidden', borderRadius: 16 }}>
              <GoogleMapReact
                center={{
                  lat: location?.coords?.latitude,
                  lng: location?.coords?.longitude
                }}
                zoom={17}
                bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
              >
                <div lat={location?.coords?.latitude} lng={location?.coords?.longitude} text='My Marker'>
                  <FontAwesomeIcon icon={faLocationDot} style={{ color: token.colorError, marginTop: 0 }} size='3x' />
                </div>
              </GoogleMapReact>
            </div>
          )}
        </Col> */}
      </CustomRow>
    </>
  )
}

export default BookingDetailsCapture
