const AdminSubscriptionEnums = {
  profileKeys: {
    SUBSCRIPTIONS: 'subscriptions'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update'
  }
}

export default AdminSubscriptionEnums
