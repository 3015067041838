import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryCard } from '../../../reusable-components/AgiliteCards'
import { Button, Col, Input, Row, Select, Space, Table, Upload, message } from 'antd'
import { handleError } from '../../../lib/utils'
import { processFile, readImports, uploadFile, writeImports } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import debounce from 'lodash/debounce'

const Import = () => {
  const [loading, setLoading] = useState({
    procedures: false,
    icd10: false,
    nappi: false
  })
  const [batch, setBatch] = useState({
    procedures: 0,
    icd10: 0,
    nappi: 0
  })
  const [batchTotal, setBatchTotal] = useState({
    procedures: 0,
    icd10: 0,
    nappi: 0
  })
  const [filters, setFilters] = useState({
    procedures: {
      searchFilter: '',
      page: 1,
      pageLimit: 5
    },
    icd10: {
      searchFilter: '',
      page: 1,
      pageLimit: 5
    },
    nappi: {
      searchFilter: '',
      page: 1,
      pageLimit: 5
    }
  })
  const [uploadType, setUploadType] = useState(null)
  const [procedureData, setProcedureData] = useState([])
  const [icd10Data, setIcd10Data] = useState([])
  const [nappiData, setNappiData] = useState([])

  useEffect(() => {
    handleFind('procedures', filters.procedures.searchFilter, filters.procedures.page, filters.procedures.pageLimit)
    handleFind('icd10', filters.icd10.searchFilter, filters.icd10.page, filters.icd10.pageLimit)
    handleFind('nappi', filters.nappi.searchFilter, filters.nappi.page, filters.nappi.pageLimit)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleFind('procedures', filters.procedures.searchFilter, filters.procedures.page, filters.procedures.pageLimit)
    // eslint-disable-next-line
  }, [filters.procedures.searchFilter])

  useEffect(() => {
    handleFind('icd10', filters.icd10.searchFilter, filters.icd10.page, filters.icd10.pageLimit)
    // eslint-disable-next-line
  }, [filters.icd10.searchFilter])

  useEffect(() => {
    handleFind('nappi', filters.nappi.searchFilter, filters.nappi.page, filters.nappi.pageLimit)
    // eslint-disable-next-line
  }, [filters.nappi.searchFilter])

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((type, query) => setFilters({ ...filters, [type]: { ...filters[type], searchFilter: query } }), 500),
    []
  )

  const handleSearch = (type, query) => {
    debouncedFilter(type, query)
  }

  const tableOptions = {
    pagination: false,
    type: 'small',
    style: {
      marginBottom: 20
    }
  }

  const tableColumns = [
    {
      title: 'Code',
      dataIndex: 'code'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    }
  ]

  const { Dragger } = Upload

  const uploadOptions = [
    { label: 'Procedures', value: 'procedures' },
    { label: 'ICD10', value: 'icd10' },
    { label: 'NAPPI', value: 'nappi' }
  ]

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        resolve(event.target.result)
      }

      reader.onerror = (err) => {
        reject(err)
      }

      reader.readAsArrayBuffer(file)
    })
  }

  const handleUpload = async (event) => {
    let bulkWriteArray = []
    let readResult = null
    let uploadResult = null
    let jsonResult = []
    let tmpJsonResult = []
    let tmpBatch = 0
    let tmpBatchTotal = 0

    setLoading({ ...loading, [uploadType]: true })

    window.addEventListener('beforeunload', (e) => {
      e.preventDefault()
      e.returnValue = 'Are you sure you want to leave this page? Doing so will cancel the import process.'
    })

    try {
      readResult = await readFile(event.file)
      uploadResult = await uploadFile(event.file.name, event.file.type, readResult)
      jsonResult = await processFile(uploadType, uploadResult._id)

      tmpBatchTotal = Math.ceil(jsonResult.length / 1000)
      setBatchTotal({ ...batchTotal, [uploadType]: tmpBatchTotal })

      for (tmpBatchTotal; tmpBatchTotal > 0; tmpBatchTotal--) {
        bulkWriteArray = []
        tmpJsonResult = jsonResult.splice(0, 1000)
        tmpBatch = tmpBatch + 1

        // eslint-disable-next-line
        tmpJsonResult.map((item) => {
          if (item.code && item.description) {
            bulkWriteArray.push({
              updateOne: {
                filter: { code: item.code },
                update: item,
                upsert: true
              }
            })
          }

          return null
        })

        setBatch({ ...batch, [uploadType]: tmpBatch })
        await writeImports(uploadType, bulkWriteArray)
      }

      handleFind(uploadType, filters[uploadType].searchFilter, filters[uploadType].page, filters[uploadType].pageLimit)

      event.onSuccess()
    } catch (e) {
      event.onError(e)
      message.error(handleError(e, true))
    }

    window.removeEventListener('beforeunload', (e) => {
      e.preventDefault()
      e.returnValue = 'Are you sure you want to leave this page? Doing so will cancel the import process.'
    })
    setLoading({ ...loading, [uploadType]: false })
    setBatch({ ...batch, [uploadType]: 0 })
  }

  const handleFind = async (type, searchFilter, page, pageLimit) => {
    let tmpData = []

    setLoading({ ...loading, [type]: true })

    try {
      tmpData = await readImports(type, searchFilter, page, pageLimit)

      switch (type) {
        case 'procedures':
          setProcedureData(tmpData)
          break
        case 'icd10':
          setIcd10Data(tmpData)
          break
        case 'nappi':
          setNappiData(tmpData)
          break

        default:
          break
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading({ ...loading, [type]: false })
  }

  return (
    <PrimaryCard title='Data Imports'>
      <Row>
        <Col span={24}>
          <PrimaryCard
            title='Procedures'
            extra={
              <Row>
                <Col>
                  <Input allowClear placeholder='Search' onChange={(e) => handleSearch('procedures', e.target.value)} />
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      handleFind(
                        'procedures',
                        filters.procedures.searchFilter,
                        filters.procedures.page,
                        filters.procedures.pageLimit
                      )
                    }
                    type='primary'
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </Col>
              </Row>
            }
          >
            <Table
              loading={{
                spinning: loading.procedures,
                tip:
                  batch.procedures === 0
                    ? 'Loading...'
                    : `Processing Batch ${batch.procedures} of ${batchTotal.procedures}`
              }}
              {...tableOptions}
              dataSource={procedureData}
              columns={tableColumns}
              pagination={{
                current: filters.procedures.page,
                pageSize: 20,
                onChange: (page, pageSize) => {
                  setFilters({
                    ...filters,
                    procedures: {
                      ...filters.procedures,
                      page,
                      pageLimit: pageSize
                    }
                  })
                  handleFind('procedures', filters.procedures.searchFilter, page, pageSize)
                }
              }}
              size='small'
            />
          </PrimaryCard>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PrimaryCard
            title='ICD10'
            extra={
              <Row>
                <Col>
                  <Input allowClear placeholder='Search' onChange={(e) => handleSearch('icd10', e.target.value)} />
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      handleFind('icd10', filters.icd10.searchFilter, filters.icd10.page, filters.icd10.pageLimit)
                    }
                    type='primary'
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </Col>
              </Row>
            }
          >
            <Table
              loading={{
                spinning: loading.icd10,
                tip: batch.icd10 === 0 ? 'Loading...' : `Processing Batch ${batch.icd10} of ${batchTotal.icd10}`
              }}
              {...tableOptions}
              dataSource={icd10Data}
              columns={tableColumns}
              pagination={{
                current: filters.icd10.page,
                pageSize: 20,
                onChange: (page, pageSize) => {
                  setFilters({
                    ...filters,
                    icd10: {
                      ...filters.icd10,
                      page,
                      pageLimit: pageSize
                    }
                  })
                  handleFind('icd10', filters.icd10.searchFilter, page, pageSize)
                }
              }}
              size='small'
            />
          </PrimaryCard>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PrimaryCard
            title='NAPPI'
            extra={
              <Row>
                <Col>
                  <Input allowClear placeholder='Search' onChange={(e) => handleSearch('nappi', e.target.value)} />
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      handleFind('nappi', filters.nappi.searchFilter, filters.nappi.page, filters.nappi.pageLimit)
                    }
                    type='primary'
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </Col>
              </Row>
            }
          >
            <Table
              loading={{
                spinning: loading.nappi,
                tip: batch.nappi === 0 ? 'Loading...' : `Processing Batch ${batch.nappi} of ${batchTotal.nappi}`
              }}
              {...tableOptions}
              dataSource={nappiData}
              columns={tableColumns}
              pagination={{
                current: filters.nappi.page,
                pageSize: 20,
                onChange: (page, pageSize) => {
                  setFilters({
                    ...filters,
                    nappi: {
                      ...filters.nappi,
                      page,
                      pageLimit: pageSize
                    }
                  })
                  handleFind('nappi', filters.nappi.searchFilter, page, pageSize)
                }
              }}
              size='small'
            />
          </PrimaryCard>
        </Col>
      </Row>
      <PrimaryCard title='Upload Data'>
        <Row justify='center' gutter={[12, 12]}>
          <Col>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Select
                style={{ width: '100%' }}
                placeholder='- Select upload type -'
                value={uploadType}
                options={uploadOptions}
                onChange={(value) => setUploadType(value)}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Dragger
              style={{ padding: 5 }}
              disabled={!uploadType || loading.procedures || loading.icd10 || loading.nappi}
              customRequest={async (event) => {
                handleUpload(event)
              }}
              accept='.xlsx, .xls'
            >
              <center>Click or drag file here to upload</center>
            </Dragger>
          </Col>
        </Row>
      </PrimaryCard>
    </PrimaryCard>
  )
}

export default Import
