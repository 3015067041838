// src/components/DeviceInfo.js
import React, { useContext, useEffect, useState } from 'react'
import BluetoothContext from '../BluetoothContext'

const DeviceInfo = () => {
  const { bleDevice } = useContext(BluetoothContext)
  const [deviceInfo, setDeviceInfo] = useState({})

  useEffect(() => {
    if (bleDevice) {
      getDeviceInfo()
    }
    // eslint-disable-next-line
  }, [bleDevice])

  const getDeviceInfo = async () => {
    if (!bleDevice) {
      console.warn('No device connected')
      return
    }
    try {
      console.log('Fetching Device Info...')
      const server = await bleDevice.gatt.connect()
      const service = await server.getPrimaryService('0000180a-0000-1000-8000-00805f9b34fb')
      const characteristics = await service.getCharacteristics()

      const info = {}

      for (const characteristic of characteristics) {
        const value = await characteristic.readValue()
        const decoder = new TextDecoder('utf-8')
        const data = decoder.decode(value)

        console.log('Characteristic UUID:', characteristic.uuid, 'Data:', data)

        switch (characteristic.uuid) {
          case '00002a26-0000-1000-8000-00805f9b34fb':
            info.firmwareRevision = data
            break
          case '00002a27-0000-1000-8000-00805f9b34fb':
            info.hardwareRevision = data
            break
          case '00002a28-0000-1000-8000-00805f9b34fb':
            info.softwareRevision = data
            break
          default:
            break
        }
      }

      setDeviceInfo(info)
    } catch (error) {
      console.error('Device Info Error:', error)
    }
  }

  return (
    <div>
      <h3>Device Information</h3>
      <div>
        <p>Firmware Revision: {deviceInfo.firmwareRevision || '--'}</p>
        <p>Hardware Revision: {deviceInfo.hardwareRevision || '--'}</p>
        <p>Software Revision: {deviceInfo.softwareRevision || '--'}</p>
      </div>
    </div>
  )
}

export default DeviceInfo
