import React, { useEffect, useState } from 'react'
import { getPatient } from '../utils/utils'
import { Col, Row, Space, theme, Card, message } from 'antd'
import templates from '../utils/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faExclamationTriangle, faEye, faPills } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import CustomButton from '../../../reusable-components/CustomButton'
import { handleError, hexToRGBA } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import dayjs from 'dayjs'
import { handleGenerateOldMedHist } from '../../../Bookings/components/Examination Components/utils/additional-screening-lib'
import { isDoctor, isNurse } from '../../../lib/profile-utils'

const PatientMedicalHistory = ({ userRef, data, setSummary, isPreviousBooking, editButton, wrapperLoading }) => {
  const [loading, setLoading] = useState()
  const [medicalHistory, setMedicalHistory] = useState({ ...templates.dataTemplate })
  const [oldMedHistLoading, setOldMedHistLoading] = useState(false)
  useEffect(() => {
    if (data) {
      getMedicalHistory()
    }
    // eslint-disable-next-line
  }, [data])

  const getMedicalHistory = async () => {
    setLoading(true)
    try {
      if (!data) {
        const tmpData = await getPatient(userRef)
        setMedicalHistory(tmpData)
      } else {
        setMedicalHistory({ ...templates.dataTemplate, ...data })
      }
    } catch (e) {
      message.error(handleError(e))
    }

    setLoading(false)
  }

  const { token } = theme.useToken()

  const hasAllergies = () => {
    if (
      medicalHistory?.allergies?.foodList?.length > 0 ||
      medicalHistory?.allergies?.medsList?.length > 0 ||
      medicalHistory?.allergies?.otherList?.length > 0 ||
      medicalHistory?.allergies?.foodAdd?.length > 0 ||
      medicalHistory?.allergies?.medsAdd?.length > 0 ||
      medicalHistory?.allergies?.otherAdd?.length > 0
    ) {
      return true // Patient has at least one allergy
    } else {
      return false // Patient has no allergies
    }
  }
  const isChronic = () => {
    if (medicalHistory?.chronicIllnesses?.list?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const columnSpan = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 8
  }

  return (
    <>
      {loading || wrapperLoading ? (
        <AgiliteSkeleton skActive={false} spinnerTip='Loading Medical History...' />
      ) : (
        <>
          <Row gutter={[12, 12]}>
            {isPreviousBooking && !(isNurse() || isDoctor()) ? undefined : (
              <Col span={24}>
                <Card
                  type='inner'
                  title='MEDICAL HISTORY SUMMARY'
                  bodyStyle={{ padding: 0 }}
                  headStyle={{ borderRadius: 0 }}
                  style={{ borderRadius: 0 }}
                  extra={
                    <Space>
                      <CustomButton
                        text='Edit Medical History'
                        type='primary'
                        size='small'
                        disabled={oldMedHistLoading}
                        icon={faEdit}
                        onClick={() => {
                          setSummary(false)
                        }}
                      />
                    </Space>
                  }
                >
                  <CustomRow gutter={[0, 0]}>
                    <Col {...columnSpan}>
                      <Card
                        title={
                          <center>
                            CHRONIC ILLNESSES & MEDS
                            <br />
                            <div style={{ marginTop: -5 }}>
                              <small>
                                {medicalHistory.chronicIllnesses.updatedAt
                                  ? `Updated: ${dayjs(medicalHistory.chronicIllnesses.updatedAt).format('DD MMM YYYY')}`
                                  : 'Never updated'}
                              </small>
                            </div>
                          </center>
                        }
                        size='small'
                        headStyle={{
                          borderRadius: 0,
                          background: hexToRGBA(token.colorWarning, 0.4),
                          paddingTop: 3,
                          paddingBottom: 3
                        }}
                        style={{ borderRadius: 0, minHeight: '100%' }}
                      >
                        {isChronic ? (
                          <>
                            {medicalHistory?.chronicIllnesses?.list?.map((illness) => {
                              return (
                                <>
                                  <p>
                                    <FontAwesomeIcon icon={faExclamationTriangle} color={token.colorWarning} />{' '}
                                    <b>{`${illness.description} ${illness.code}`.toUpperCase()}</b>
                                  </p>
                                  <p>
                                    <em>
                                      Diagnosed on{' '}
                                      {dayjs(illness.dateDiagnosed ? illness.dateDiagnosed : undefined).format(
                                        'DD MMM YYYY'
                                      )}
                                    </em>
                                  </p>
                                  <p>
                                    <b>Associated Medications:</b>
                                  </p>
                                  <ul style={{ marginTop: 0 }}>
                                    {illness.medications?.length > 0 ? (
                                      <>
                                        {illness.medications.map((med) => {
                                          return (
                                            <>
                                              <li>
                                                <FontAwesomeIcon icon={faPills} /> {med.med.name}{' '}
                                                {med.med.strengthMetric1} {med.med.unitOfMeasure1}{' '}
                                                {med.med.rxUnit !== med.med.unitOfMeasure1 ? med.med.rxUnit : undefined}
                                              </li>
                                              <ul style={{ marginTop: 0 }}>
                                                <li>
                                                  Started use on{' '}
                                                  <em>
                                                    {dayjs(med.startDate ? med.startDate : undefined).format(
                                                      'DD MMM YYYY'
                                                    )}
                                                  </em>
                                                </li>
                                                <li>
                                                  {med.endDate ? (
                                                    <>
                                                      Ended use on{' '}
                                                      <em>
                                                        {dayjs(med.endDate ? med.endDate : undefined).format(
                                                          'DD MMM YYYY'
                                                        )}
                                                      </em>
                                                    </>
                                                  ) : (
                                                    'Still currently using'
                                                  )}
                                                </li>
                                              </ul>
                                            </>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      <p>
                                        <FontAwesomeIcon icon={faExclamationTriangle} color={token.colorWarning} />{' '}
                                        Patient has not listed any chronic medications associated with this illness.
                                      </p>
                                    )}{' '}
                                  </ul>
                                </>
                              )
                            })}
                            {medicalHistory?.meds?.list?.length > 0 ? (
                              <>
                                <p style={{ marginTop: 12 }}>
                                  <b>Non Chronic Medications:</b>
                                </p>
                                <ul style={{ marginTop: 0 }}>
                                  {medicalHistory.meds.list.map((med) => {
                                    return (
                                      <li>
                                        <FontAwesomeIcon icon={faPills} /> {med.med.name} {med.med.strengthMetric1}{' '}
                                        {med.med.unitOfMeasure1}{' '}
                                        {med.med.rxUnit !== med.med.unitOfMeasure1 ? med.med.rxUnit : undefined}
                                      </li>
                                    )
                                  })}
                                </ul>
                              </>
                            ) : undefined}
                          </>
                        ) : undefined}
                      </Card>
                    </Col>
                    <Col {...columnSpan}>
                      <Card
                        size='small'
                        title={
                          <center>
                            ALLERGIES
                            <br />
                            <div style={{ marginTop: -5 }}>
                              <small>
                                {medicalHistory.allergies.updatedAt
                                  ? `Updated: ${dayjs(medicalHistory.allergies.updatedAt).format('DD MMM YYYY')}`
                                  : 'Never updated'}
                              </small>
                            </div>
                          </center>
                        }
                        headStyle={{
                          borderRadius: 0,
                          background: hexToRGBA(token.colorWarning, 0.4),
                          paddingTop: 3,
                          paddingBottom: 3
                        }}
                        style={{ borderRadius: 0, minHeight: '100%' }}
                      >
                        {hasAllergies() ? (
                          <>
                            {medicalHistory?.allergies?.foodList?.length > 0 ||
                            medicalHistory?.allergies?.foodAdd?.length > 0 ? (
                              <>
                                <p>
                                  <b>Food Allergies:</b>
                                </p>
                                <ul style={{ marginTop: 0 }}>
                                  {medicalHistory?.allergies?.foodList?.map((foodAllergy) => {
                                    return <li>{foodAllergy}</li>
                                  })}
                                  {medicalHistory?.allergies?.foodAdd?.map((foodAllergy) => {
                                    return <li>{foodAllergy.name}</li>
                                  })}
                                </ul>
                              </>
                            ) : undefined}
                            {medicalHistory?.allergies?.medsList?.length > 0 ||
                            medicalHistory?.allergies?.medsAdd?.length > 0 ? (
                              <>
                                <p>
                                  <b>Medication Allergies:</b>
                                </p>
                                <ul lis style={{ marginTop: 0 }}>
                                  {medicalHistory?.allergies?.medsList?.map((medAllergy) => {
                                    return (
                                      <li>
                                        <FontAwesomeIcon icon={faPills} /> {medAllergy}
                                      </li>
                                    )
                                  })}
                                  {medicalHistory?.allergies?.medsAdd?.map((medAllergy) => {
                                    return (
                                      <li>
                                        <FontAwesomeIcon icon={faPills} /> {medAllergy.name}
                                      </li>
                                    )
                                  })}
                                </ul>
                              </>
                            ) : undefined}
                            {medicalHistory?.allergies?.otherList?.length > 0 ||
                            medicalHistory?.allergies?.otherAdd?.length > 0 ? (
                              <>
                                <p>
                                  <b>Other Allergies:</b>
                                </p>
                                <ul style={{ marginTop: 0 }}>
                                  {medicalHistory?.allergies?.otherList?.map((otherAllergy) => {
                                    return <li>{otherAllergy}</li>
                                  })}
                                  {medicalHistory?.allergies?.otherAdd?.map((otherAllergy) => {
                                    return <li>{otherAllergy.name}</li>
                                  })}
                                </ul>
                              </>
                            ) : undefined}
                          </>
                        ) : (
                          <p>
                            <FontAwesomeIcon icon={faExclamationTriangle} color={token.colorWarning} /> Patient has
                            listed no existing allergies, confirm with patient.
                          </p>
                        )}
                      </Card>
                    </Col>

                    <Col {...columnSpan}>
                      <Card
                        size='small'
                        title={
                          <center>
                            LIFESTYLE & BACKGROUND
                            <br />
                            <div style={{ marginTop: -5 }}>
                              <small>
                                {medicalHistory.lifestyle.updatedAt
                                  ? `Updated: ${dayjs(medicalHistory.lifestyle.updatedAt).format('DD MMM YYYY')}`
                                  : 'Never updated'}
                              </small>
                            </div>
                          </center>
                        }
                        headStyle={{
                          borderRadius: 0,
                          background: hexToRGBA(token.colorWarning, 0.4),
                          paddingTop: 3,
                          paddingBottom: 3
                        }}
                        style={{ borderRadius: 0, minHeight: '100%' }}
                      >
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Alcohol Intake:</b>
                          </p>
                          <p>
                            {medicalHistory?.lifestyle?.alcoholConsumption
                              ? medicalHistory?.lifestyle?.alcoholConsumptionLevel
                              : 'None'}
                          </p>
                        </Space>
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Diet:</b>
                          </p>
                          <p>{medicalHistory?.lifestyle?.diet ? medicalHistory?.lifestyle?.diet : 'Not Captured'}</p>
                        </Space>
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Physical Activity:</b>
                          </p>
                          <p>
                            {medicalHistory?.lifestyle?.physicalActivity
                              ? medicalHistory?.lifestyle?.physicalActivity
                              : 'Not Captured'}
                          </p>
                        </Space>
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Screen Time:</b>
                          </p>
                          <p>
                            {medicalHistory?.lifestyle?.screenTime
                              ? medicalHistory?.lifestyle?.screenTime
                              : 'Not Captured'}
                          </p>
                        </Space>
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Smokes:</b>
                          </p>
                          <p>
                            {medicalHistory?.lifestyle?.smoking
                              ? medicalHistory?.lifestyle?.smokingLevel + ', ' + medicalHistory.lifestyle.smokingYears
                              : 'No'}
                          </p>
                        </Space>
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Vapes:</b>
                          </p>
                          <p>
                            {medicalHistory?.lifestyle?.vaping
                              ? medicalHistory?.lifestyle?.vapingLevel + ', ' + medicalHistory.lifestyle.vapingYears
                              : 'None'}
                          </p>
                        </Space>
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Water Intake:</b>
                          </p>
                          <p>
                            {medicalHistory?.lifestyle?.waterIntake
                              ? medicalHistory?.lifestyle?.waterIntake
                              : 'Not Captured'}
                          </p>
                        </Space>
                        <Space justifyContent='space-between' style={{ width: '100%' }}>
                          <p style={{ width: 150 }}>
                            <b>Stress:</b>
                          </p>
                          <p>
                            {medicalHistory?.lifestyle?.stress ? medicalHistory?.lifestyle?.stress : 'Not Captured'}
                          </p>
                        </Space>
                        <p style={{ marginTop: 12 }}>
                          <b>Family History</b>
                          <ul style={{ marginTop: 0 }}>
                            {medicalHistory?.familyHistory?.list?.length > 0 ? (
                              <>
                                {medicalHistory?.familyHistory?.list?.map((i) => {
                                  return <li>{i}</li>
                                })}
                              </>
                            ) : (
                              <li>No family history captured</li>
                            )}
                          </ul>
                        </p>
                        <p style={{ marginTop: 12 }}>
                          <b>Mental Health</b>
                          <ul style={{ marginTop: 0 }}>
                            {medicalHistory?.mentalHealth?.list?.length > 0 ? (
                              <>
                                {medicalHistory?.mentalHealth?.list?.map((i) => {
                                  return <li>{i}</li>
                                })}
                              </>
                            ) : (
                              <li>No mental health issues captured</li>
                            )}
                          </ul>
                        </p>
                        <p style={{ marginTop: 12 }}>
                          <b>Surgeries</b>
                          <ul style={{ marginTop: 0 }}>
                            {medicalHistory?.surgicalHistory?.list?.length > 0 ? (
                              <>
                                {medicalHistory?.surgicalHistory?.list?.map((i) => {
                                  return (
                                    <li>
                                      {i.description}
                                      <br />
                                      <div style={{ marginTop: -3, marginBottom: 3 }}>
                                        <em>
                                          <small>{dayjs(i.date).format('DD MMM YYYY')}</small>
                                        </em>
                                      </div>
                                    </li>
                                  )
                                })}
                              </>
                            ) : (
                              <li>No surgery history captured</li>
                            )}
                          </ul>
                        </p>
                      </Card>
                    </Col>
                  </CustomRow>
                </Card>
              </Col>
            )}
          </Row>
        </>
      )}{' '}
    </>
  )
}

export default PatientMedicalHistory
