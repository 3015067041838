import React, { useEffect, useState } from 'react'
import CoreEnums from '../../../core/utils/enums'
import CustomRow from '../../reusable-components/CustomRow'

import { ContainerCard } from '../../reusable-components/AgiliteCards'
import { Col, message } from 'antd'

import DataPointTemplateListView from './list-view'
import { handleError } from '../../lib/utils'
import { readDataPointTemplates } from '../utils'

const DataPointTemplateWrapper = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleGetData()
  }, [])

  const handleGetData = async () => {
    setLoading(true)
    try {
      const response = await readDataPointTemplates({})
      setData(response)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  return (
    <ContainerCard title={CoreEnums.tabTitles.DATA_POINT_TEMPLATES}>
      <CustomRow justify='center'>
        <Col xs={24} sm={24} md={18} lg={16}>
          <DataPointTemplateListView data={data} loading={loading} handleGetData={handleGetData} />
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default DataPointTemplateWrapper
