import React, { useEffect, useState } from 'react'
import { Col, Button, Table, Empty, Input, Space } from 'antd'
import { uniqBy } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'

import ContractFormTemplates from '../utils/templates'

import _ from 'lodash'
import CustomRow from '../../../../reusable-components/CustomRow'
import CustomButton from '../../../../reusable-components/CustomButton'
import CoreEnums from '../../../../../core/utils/enums'

const CompContrList = ({ data, refreshView, createRecord, editRecord, loading, handleSearch }) => {
  const [filters, setFilters] = useState({
    province: [],
    name: [],
    address: []
  })

  useEffect(() => {
    generateFilters()
    // eslint-disable-next-line
  }, [data])

  const generateFilters = () => {
    const tmpFilters = {
      province: [],
      clinicType: []
    }

    const provinceFilters = Object.keys(CoreEnums.provinces).map((key) => {
      return CoreEnums.provinces[key]
    })

    provinceFilters.forEach((item) => {
      tmpFilters.province.push({
        text: item,
        value: item
      })
    })

    data.forEach((item) => {
      tmpFilters.clinicType.push({ text: item.clinicType, value: item.clinicType })
    })

    tmpFilters.name = uniqBy(tmpFilters.name, 'text')
    tmpFilters.clinicType = uniqBy(tmpFilters.clinicType, 'text')

    setFilters(tmpFilters)
  }

  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <Space wrap>
              <CustomButton
                size='small'
                text='Refresh'
                icon={faRefresh}
                onClick={() => refreshView()}
                type='primary'
                disabled={loading}
              />
              <Button type='primary' onClick={createRecord} disabled={loading}>
                Add Company or Contractor
              </Button>
              <Input
                placeholder='Search for Company/Contractor name'
                suffix={<FontAwesomeIcon icon={faSearch} />}
                allowClear
                onChange={(e) => {
                  handleSearch(e.target.value)
                }}
                style={{ width: 500, maxWidth: '100%' }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Table
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={_.sortBy(data, 'name')}
              pagination={false}
              scroll={{ x: 950 }}
              columns={ContractFormTemplates.columnTemplate(editRecord, filters)}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => editRecord(record)
                }
              }}
              locale={{ emptyText: <Empty description='There are currently no registered clinics to show.' /> }}
            />
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default CompContrList
