import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Card, Col, Row, Space, Switch } from 'antd'
import React, { useEffect } from 'react'
import { deviceDetect } from 'react-device-detect'
import { useSelector } from 'react-redux'

import Individual from '../../../assets/images/icons/1.png'
import Couple from '../../../assets/images/icons/2.png'
import Family from '../../../assets/images/icons/3.png'
import { hexToRGBA } from '../../lib/utils'

const SubscriptionPlan = ({ setEnableNext, subscription, setSubscription, token }) => {
  const dependantState = useSelector((state) => state.dependents.data)
  const state = useSelector((state) => state)
  const isMobile = deviceDetect().isMobile
  const vitalLinkEntity = state.core.entity.vitalLink[state.core.entity.vitalLink.localVitalLink ? 'local' : 'tourist']

  useEffect(() => {
    if (subscription.extraData.plan) {
      setEnableNext(true)
    } else {
      setEnableNext(false)
    }
    // eslint-disable-next-line
  }, [])

  const iconStyle = {
    width: isMobile ? 24 : 120
  }

  const subscriptionPlans = [
    {
      name: 'individual',
      label: 'Individual',
      icon: <img src={Individual} alt='Individual' style={iconStyle} />,
      price: vitalLinkEntity.individualSubPrice,
      color: '#0DB1E1'
    },
    {
      name: 'couple',
      label: 'Couple',
      icon: <img src={Couple} alt='Couple' style={iconStyle} />,
      price: vitalLinkEntity.coupleSubPrice,
      color: '#F14F8A'
    },
    {
      name: 'family',
      label: 'Family',
      icon: <img src={Family} alt='Family' style={iconStyle} />,
      price: vitalLinkEntity.famSubPrice,
      color: '#51C539'
    }
  ]

  return (
    <>
      <Row justify='center' style={{ marginBottom: 20 }}>
        <Col span={24}>
          <center style={{ padding: 8, background: hexToRGBA(token.colorPrimary, 0.2) }}>
            <b>Choose Your Subscription Plan</b>
          </center>
        </Col>
      </Row>
      <Row justify='space-around'>
        <Col span={24}>
          {deviceDetect().isMobile ? (
            <Row gutter={[12, 12]}>
              {subscriptionPlans.map((plan) => {
                return (
                  <Col span={24}>
                    <div
                      onClick={() => {
                        setEnableNext(true)
                        setSubscription({
                          ...subscription,
                          extraData: { ...subscription.extraData, plan: plan.name }
                        })
                      }}
                      style={{
                        borderRadius: 12,
                        boxShadow:
                          subscription.extraData.plan === plan.name
                            ? `0px 0px 10px 0px ${plan.color}`
                            : '0px 0px 10px 0px #ccc',
                        overflow: 'hidden'
                      }}
                    >
                      <div style={{ display: 'grid', width: '100%', gridTemplateColumns: '50px 1fr' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 12,
                            background:
                              subscription.extraData.plan !== plan.name
                                ? hexToRGBA('#cccccc', 0.2)
                                : hexToRGBA(plan.color, 0.2)
                          }}
                        >
                          <Space>{plan.icon}</Space>
                        </div>
                        <div style={{ padding: 8 }}>
                          <h4>{plan.label} </h4>R{plan.price} per month {plan.name === 'family' ? '(3-5 Members)' : ''}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          ) : (
            <Space wrap style={{ justifyContent: 'center', width: '100%' }}>
              {subscriptionPlans.map((plan) => {
                return (
                  <Card
                    size='small'
                    hoverable
                    onClick={() => {
                      setEnableNext(true)
                      setSubscription({ ...subscription, extraData: { ...subscription.extraData, plan: plan.name } })
                    }}
                    style={{
                      border:
                        subscription.extraData.plan === plan.name
                          ? `4px solid ${token.colorPrimary}`
                          : '1px solid #ccc',
                      width: 300,
                      maxWidth: '100%'
                    }}
                  >
                    <center>
                      {plan.icon}
                      <h1 style={{ marginTop: 8 }}>{plan.label}</h1>
                      <small>
                        R{plan.price} per month {plan.name === 'family' ? '(3-5 Members)' : ''}
                      </small>
                    </center>
                  </Card>
                )
              })}
            </Space>
          )}
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Card size='small'>
            <Row justify={'center'}>
              <Col>
                <span>Include Mobile Panic Button?</span>
              </Col>
            </Row>
            <Row justify='center'>
              <Col>
                <small style={{ color: token.colorSuccess }}>R3390 Once Off</small>
              </Col>
            </Row>
            <Row justify='center' style={{ marginTop: 10 }}>
              <Col>
                <Switch
                  size='large'
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  defaultChecked={subscription.extraData.panicButton}
                  onChange={(value) => {
                    setSubscription({ ...subscription, extraData: { ...subscription.extraData, panicButton: value } })
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {subscription.extraData.plan && subscription.extraData.plan !== 'individual' && dependantState.length === 0 ? (
        <Row justify='center' style={{ marginTop: 20 }}>
          <Col>
            <Alert
              type='warning'
              message={
                <span>
                  <FontAwesomeIcon icon={faWarning} style={{ color: token.colorWarning }} /> Note: No Dependants or
                  Family Members have been added to your account.
                </span>
              }
            />
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export default SubscriptionPlan
