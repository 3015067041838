import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getBookingState = (bpmRecordIds = []) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.BPM.getRecordState(null, bpmRecordIds)

        if (response.data.length > 0) {
          resolve(response.data[0])
        } else {
          resolve(response.data)
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const registerBooking = (bpmKey = 'booking_process', currentUser) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.BPM.registerBPMRecord(bpmKey, currentUser)
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const executeBooking = (bpmKey = 'booking_process', bpmRecordId, optionSelected, currentUser, currentStep) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.BPM.execute(
          bpmKey,
          bpmRecordId,
          optionSelected,
          currentUser,
          currentStep,
          null, // Comments
          {
            responsibleuser: currentUser
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
