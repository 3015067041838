import { Col, message, Tabs, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { readConfig, updateConfig } from '../utils/utils'
import authReducer from '../../../Auth/utils/reducer'

// Components
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import BookingsConfig from './bookings'
import Checkin from './checkin'
import Import from './import'
import { allTrim, handleError } from '../../../lib/utils'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import CustomRow from '../../../reusable-components/CustomRow'

const Config = () => {
  const dispatch = useDispatch()
  const [configData, setConfigData] = useState({ bookings: { daysInAdvance: 2, searchRadius: 35000 } })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleGetConfig()
  }, [])

  const handleGetConfig = async () => {
    let tmpData = null

    setLoading(true)

    try {
      tmpData = await readConfig()

      if (tmpData.length > 0) {
        setConfigData(tmpData[0])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleUpdateConfig = async (data) => {
    let tmpData = null

    setLoading(true)

    try {
      tmpData = await updateConfig(allTrim(data))
      setConfigData(tmpData)
      dispatch(authReducer.actions.setConfig(tmpData))
      message.success('Changes saved successfully')
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const { token } = theme.useToken()

  return (
    <ContainerCard title='Configurations'>
      <CustomRow gutter={[12, 12]} justify='center'>
        <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={10}>
          {loading ? (
            <AgiliteSkeleton skActive={false} spinnerTip='Loading Configurations...' />
          ) : (
            <Tabs type='card'>
              <Tabs.TabPane tab='Bookings' key='bookings'>
                <BookingsConfig
                  token={token}
                  configData={configData}
                  handleUpdateConfig={handleUpdateConfig}
                  setConfigData={setConfigData}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Check In' key='checkin'>
                <Checkin
                  token={token}
                  configData={configData}
                  handleUpdateConfig={handleUpdateConfig}
                  setConfigData={setConfigData}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Data Imports' key='imports'>
                <Import token={token} />
              </Tabs.TabPane>
            </Tabs>
          )}
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default Config
