import Agilite from 'agilite'
import DataPointTemplateEnums from './enums'
import { isAdmin, isDoctor, isNurse } from '../lib/profile-utils'
import store from '../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readDataPointTemplates = (qry, capturing) => {
  const filter = {
    ...qry
  }
  return new Promise((resolve, reject) => {
    let response = []
    if (isAdmin()) {
      filter.entityRef = store.getState().core.entity._id
      filter.isGlobal = true
    }
    if (isDoctor() || isNurse()) {
      if (capturing) {
        filter.$or = [{ isGlobal: true }, { createdBy: store.getState().auth.agiliteUser._id }]
        filter.entityRef = store.getState().core.entity._id
      } else {
        filter.entityRef = store.getState().core.entity._id
        filter.createdBy = store.getState().auth.agiliteUser._id
      }
    }
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          DataPointTemplateEnums.profileKeys.DATA_POINT_TEMPLATES,
          DataPointTemplateEnums.routeKeys.READ,
          {
            filter: JSON.stringify({ ...filter, templateType: 'standard' })
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createDataPointTemplate = (data) => {
  return new Promise((resolve, reject) => {
    let response = []
    const responsibleUser = store.getState().auth.agiliteUser._id
    const date = new Date()

    if (isAdmin()) {
      data.isGlobal = true
    }

    if (isDoctor() || isNurse()) {
      data.isGlobal = false
    }

    data.entityRef = store.getState().core.entity._id
    data.createdBy = responsibleUser
    data.modifiedBy = responsibleUser
    data.createdAt = date
    data.modifiedAt = date
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          DataPointTemplateEnums.profileKeys.DATA_POINT_TEMPLATES,
          DataPointTemplateEnums.routeKeys.CREATE,
          {
            data: JSON.stringify({ ...data, templateType: 'standard' })
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateDataPointTemplate = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          DataPointTemplateEnums.profileKeys.DATA_POINT_TEMPLATES,
          DataPointTemplateEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteDataPointTemplate = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          DataPointTemplateEnums.profileKeys.DATA_POINT_TEMPLATES,
          DataPointTemplateEnums.routeKeys.DELETE,
          {
            recordId
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
