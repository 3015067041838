import React from 'react'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import PatientAnalytics from './patient-analytics'
import { Col } from 'antd'
import CustomRow from '../../reusable-components/CustomRow'

const PatientAnalLyticsWrapper = () => {
  return (
    <ContainerCard title='Analytics'>
      <CustomRow justify='center'>
        <Col span={24}>
          <PatientAnalytics />
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default PatientAnalLyticsWrapper
