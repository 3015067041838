import dayjs from 'dayjs'
import store from '../../../../../store'
import { readMedicalHistory, readPatients } from '../../../../Admin/patients/utils/utils'
import reportGenerationEnums from '../../../../Admin/Reporting/reporting-enums'
import { generateReport } from '../../../../Admin/Reporting/reporting-utils'
import { message } from 'antd'
import { handleError } from '../../../../lib/utils'
import { readClinics } from '../../../../Admin/clinics/utils/utils'

export const handleGenerateDiabetesReport = async (data) => {
  let payload = {}
  let medHistory = null
  // setReportLoading(true)

  try {
    medHistory = await readMedicalHistory({ userRef: data.userRef })

    medHistory = medHistory[0]?.medicalHistory

    // PREP REFERENCES
    const clinic = await readClinics({ _id: data.clinicRef })
    const doctorProfile = store.getState().auth.agiliteUser
    const vistDate = dayjs(data.bookingDate).format('DD MMM YYYY')
    const examinationData = data.examinationData
    const patientProfile = await readPatients({ _id: data.userRef })
    const diagnosis = examinationData?.billing?.diagnosis
    const diabetesScreeningData = examinationData?.checkinData?.diabetesScreening
    const doctorNotes = examinationData?.diagnosisData?.doctorsNotes || []
    if (!diabetesScreeningData) {
      throw new Error('Insufficient data has been captured for this report to be generated.')
    }

    // HEADER DETAILS

    payload.doctorsNotes = doctorNotes
    payload.docName = `${doctorProfile.firstName} ${doctorProfile.lastName}`
    payload.qualification = doctorProfile.extraData.qualification
    payload.practiceNo = clinic[0]?.practiceNumber
    payload.address = clinic[0]?.physicalAddress
    payload.practiceName = clinic[0]?.name
    payload.phone = clinic[0]?.phoneNumber
    payload.visitDate = vistDate
    payload.patientName = `${patientProfile[0].firstName} ${patientProfile[0].lastName}`
    payload.entityLogo = clinic[0].clinicLogo

    // MEDICAL HISTORY
    if (medHistory) {
      payload.smoking = medHistory?.alcoholSmoking?.smoking || medHistory.alcoholSmoking?.vaping ? 'Yes' : 'No'
      payload.alcohol = medHistory?.alcoholSmoking?.alcoholConsumption ? 'Yes' : 'No'
      payload.exercise = medHistory?.physicalActivity?.isPhyscallyActive
        ? medHistory?.physicalActivity?.physicalActivityFrequency
        : 'None'
      payload.diet = medHistory?.diet?.dietLevel
    }

    // GENERAL
    payload.dateOfBirth = patientProfile[0].dateOfBirth
    payload.diagnosis = diagnosis.length > 0 ? diagnosis.map((i) => ({ diag: i })) : []
    payload.weight = data.examinationData?.checkinData?.weight?.value
      ? data.examinationData?.checkinData?.weight?.value
      : 'N/A'
    payload.height = data.examinationData?.checkinData?.height?.value
      ? data.examinationData?.checkinData?.height?.value
      : 'N/A'
    payload.pulse = data.examinationData?.checkinData?.pulse
    payload.bloodPressure =
      data.examinationData?.checkinData?.bloodPressure &&
      data.examinationData?.checkinData?.bloodPressure?.diastolic &&
      data.examinationData?.checkinData?.bloodPressure?.systolic
        ? `${data.examinationData?.checkinData?.bloodPressure?.systolic} / ${data.examinationData?.checkinData?.bloodPressure?.diastolic}`
        : 'N/A'
    payload.ohaInsulin =
      diabetesScreeningData.ohaInsulin?.list?.map((i) => {
        return { medication: `${i.name}` }
      }) || []

    payload.lipidMeds =
      diabetesScreeningData.lipidMeds?.list?.map((i) => {
        return { medication: `${i.name}` }
      }) || []
    payload.aspirinMeds =
      diabetesScreeningData.aspirinMeds?.list?.map((i) => {
        return { medication: `${i.name}` }
      }) || []
    payload.bpMeds =
      diabetesScreeningData.bpMeds?.list.map((i) => {
        return { medication: `${i.name}` }
      }) || []

    payload.otherMeds =
      diabetesScreeningData.otherMeds?.list.map((i) => {
        return { medication: `${i.name}` }
      }) || []

    // ECG
    payload.status = diabetesScreeningData?.ecg?.status || 'N/A'
    payload.angina = diabetesScreeningData?.ecg?.angina ? 'Yes' : 'No'
    payload.arrthy = diabetesScreeningData?.ecg?.arthy ? 'Yes' : 'No'
    payload.infarct = diabetesScreeningData?.ecg?.infarct ? 'Yes' : 'No'
    payload.ecgDate = diabetesScreeningData?.ecg?.date
      ? dayjs(diabetesScreeningData.ecg.date).format('DD MMM YYYY')
      : 'N/A'

    // NEURO
    payload.neuroNeuropathy = diabetesScreeningData?.neuro?.neuropathy ? 'Yes' : 'No'
    payload.neuroPain = diabetesScreeningData?.neuro?.pain ? 'Yes' : 'No'

    // KIDNEYS
    payload.nephropathy = diabetesScreeningData.kidney?.nephropathy ? 'Yes' : 'No'

    // EYES
    payload.retinopathyLeft = diabetesScreeningData.eye?.retinopathy?.left ? 'Yes' : 'No'
    payload.retinopathyRight = diabetesScreeningData.eye?.retinopathy?.right ? 'Yes' : 'No'

    // VACCINATIONS
    payload.vaccList = diabetesScreeningData.comorbiditiesHistoryVacc?.list

    // VISITS ATTENDED
    payload.visits = diabetesScreeningData.visitsAttended

    // FEET
    payload.pulsesLeft = diabetesScreeningData.feet?.pulsesLeft ? 'Yes' : 'No'
    payload.pulsesRight = diabetesScreeningData.feet?.pulsesRight ? 'Yes' : 'No'
    payload.sensationsLeft = diabetesScreeningData.feet?.sensationsLeft ? 'Yes' : 'No'
    payload.sensationsRight = diabetesScreeningData.feet?.sensationsRight ? 'Yes' : 'No'
    payload.callusLeft = diabetesScreeningData.feet?.callusLeft ? 'Yes' : 'No'
    payload.callusRight = diabetesScreeningData.feet?.callusRight ? 'Yes' : 'No'
    payload.vibrationLeft = diabetesScreeningData.feet?.vibrationLeft ? 'Yes' : 'No'
    payload.vibrationRight = diabetesScreeningData.feet?.vibrationRight ? 'Yes' : 'No'

    // URINARY
    payload.urinary = diabetesScreeningData.urinary ? diabetesScreeningData.urinary.join(', ') : ''
    payload.urineTesting = diabetesScreeningData.urineTesting ? diabetesScreeningData.urineTesting.join(', ') : ''

    // DENTAL
    payload.dental = diabetesScreeningData.dental ? diabetesScreeningData.dental.join(', ') : ''

    // PVD
    payload.pvd = diabetesScreeningData.pvd ? 'Yes' : 'No'

    // LUNGS
    payload.lungsStatus = diabetesScreeningData.lungs?.status

    // SKIN
    payload.skinStatus = diabetesScreeningData.skin?.status
    payload.injSightHealth = diabetesScreeningData.skin?.injSightHealth ? 'Yes' : 'No'
    payload.oedema = diabetesScreeningData.skin?.oedema ? 'Yes' : 'No'
    payload.skinNotes = diabetesScreeningData.skin?.notes ? diabetesScreeningData.skin?.notes.join(', ') : ''

    // COMORBIDITIES/HISTORY
    payload.celiacDisease = diabetesScreeningData?.comorbiditiesHistory?.includes('Celiac Disease') ? 'Yes' : 'No'
    payload.pco = diabetesScreeningData?.comorbiditiesHistory?.includes('PCO') ? 'Yes' : 'No'
    payload.menstruation = diabetesScreeningData?.comorbiditiesHistory?.includes('Menstrutation') ? 'Yes' : 'No'
    payload.hormoneTherapy = diabetesScreeningData?.comorbiditiesHistory?.includes('Hormone Therapy') ? 'Yes' : 'No'
    payload.hypothyroidsm = diabetesScreeningData?.comorbiditiesHistory?.includes('Hypothyroidsm') ? 'Yes' : 'No'
    payload.hyperuricaemia = diabetesScreeningData?.comorbiditiesHistory?.includes('Hyperuricaemia') ? 'Yes' : 'No'
    payload.osa = diabetesScreeningData?.comorbiditiesHistory?.includes('OSA') ? 'Yes' : 'No'
    payload.ed = diabetesScreeningData?.comorbiditiesHistory?.includes('ED') ? 'Yes' : 'No'
    payload.ihd = diabetesScreeningData?.comorbiditiesHistory?.includes('IHD') ? 'Yes' : 'No'
    payload.pvd = diabetesScreeningData?.comorbiditiesHistory?.includes('PVD') ? 'Yes' : 'No'
    payload.cysticFibroses = diabetesScreeningData?.comorbiditiesHistory?.includes('Cystic Fibroses') ? 'Yes' : 'No'

    // AUTONOMIC NEUROPATH
    payload.autonomicNeuropath = diabetesScreeningData.autonomicNeuropath
      ? diabetesScreeningData.autonomicNeuropath.join(', ')
      : ''

    // FINALIZE
    payload.assessment = diabetesScreeningData.assessment
    payload.plan = diabetesScreeningData.plan

    await generateReport(payload, reportGenerationEnums.templates.DIABETES)
  } catch (e) {
    message.error(handleError(e))
  }
}

export const handleGenerateOldMedHist = async (userId, setLoading) => {
  let payload = {}
  let medHistory = null
  // setReportLoading(true)
  setLoading(true)
  try {
    medHistory = await readMedicalHistory({ userRef: userId })

    medHistory = medHistory[0]?.medicalHistory

    if (medHistory) {
      payload.allergies = medHistory.allergies?.allergies ? medHistory.allergies?.allergies : []
      payload.illnesses = medHistory.chronicIllnesses?.illnesses ? medHistory.chronicIllnesses?.illnesses : []
      payload.medications = medHistory.medications?.medications ? medHistory.medications.medications : []
      payload.surgeries = medHistory.surgericalHistory?.surgeries ? medHistory.surgericalHistory.surgeries : []

      payload.physicalActivityFrequency = medHistory.physicalActivity?.physicalActivityFrequency
        ? medHistory.physicalActivity.physicalActivityFrequency
        : 'None'

      payload.alcoholConsumptionLevel = medHistory.alcoholSmoking?.alcoholConsumptionLevel
        ? medHistory.alcoholSmoking?.alcoholConsumptionLevel
        : 'None'
      payload.smoking = medHistory.alcoholSmoking?.smoking ? 'Yes' : 'No'
      payload.smokingLevel = medHistory.alcoholSmoking?.smokingLevel ? medHistory.alcoholSmoking?.smokingLevel : 'N/A'
      payload.smokingYears = medHistory.alcoholSmoking?.smokingYears ? medHistory.alcoholSmoking?.smokingYears : 'N/A'
      payload.vaping = medHistory.alcoholSmoking?.vaping ? 'Yes' : 'No'
      payload.vapingLevel = medHistory.alcoholSmoking?.vapingLevel ? medHistory.alcoholSmoking?.vapingLevel : 'N/A'
      payload.vapingNicotene = medHistory.alcoholSmoking?.vapingNicotene
        ? medHistory.alcoholSmoking?.vapingNicotene
        : 'N/A'
      payload.vapingAmount = medHistory.alcoholSmoking?.vapingAmount ? medHistory.alcoholSmoking?.vapingAmount : 'N/A'
      payload.vapingYears = medHistory.alcoholSmoking?.vapingYears ? medHistory.alcoholSmoking?.vapingYears : 'N/A'
      payload.mentalHealth = medHistory.mentalHealth
      payload.sleepHours = medHistory.sleepStress?.sleepHours ? medHistory.sleepStress?.sleepHours : 'Not Captured'
      payload.stress = medHistory.sleepStress?.stress ? medHistory.sleepStress?.stress : 'Not Captured'
      payload.dietLevel = medHistory.diet?.dietLevel ? medHistory.diet?.dietLevel : 'No specific Diet'
      payload.familyHistory = medHistory.familyHistory
      payload.hadCovid = medHistory.covidInfo?.hadCovid ? 'Yes' : 'No'
      payload.fullyVaccinated = medHistory.covidInfo?.fullyVaccinated ? 'Yes' : 'No'
      payload.lastVaccinatedDate = medHistory.covidInfo?.lastVaccinatedDate
        ? medHistory.covidInfo?.lastVaccinatedDate
        : 'Not Vaccinated'
      await generateReport(payload, reportGenerationEnums.templates.OLD_MED_HIST)
    } else {
      throw new Error('No Old History to show.')
    }
  } catch (e) {
    message.error(handleError(e))
  }
  setLoading(false)
}
