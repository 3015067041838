import Agilite from 'agilite'
import store from '../../../store'
import ScriptEnums from './enums'
import CoreEnums from '../../../core/utils/enums'
import { readPatients } from '../../Admin/patients/utils/utils'
import { readMedicalProfessionalUsers } from '../../Admin/medical-professionals/utils/utils'
import axios from 'axios'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const countTotalScripts = (qry, patientQry) => {
  return new Promise((resolve, reject) => {
    let response = []
    let userResponse = null

    ;(async () => {
      try {
        if (patientQry) {
          // Query needs to be by patient first to only return script results for the specified patient
          userResponse = await readPatients(patientQry)
          response = await agilite.Connectors.execute(ScriptEnums.profileKeys.SCRIPTS, ScriptEnums.routeKeys.COUNT, {
            filter: JSON.stringify({ userRef: { $in: userResponse.map((i) => i._id) } })
          })
        } else {
          response = await agilite.Connectors.execute(ScriptEnums.profileKeys.SCRIPTS, ScriptEnums.routeKeys.COUNT, {
            filter: JSON.stringify(qry)
          })
        }
        resolve(response.data.result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
export const readScriptHistory = (qry, fetchMedProf, patientQry, pageSize, page) => {
  return new Promise((resolve, reject) => {
    let response = []
    let userResponse = null
    let scriptResponse = null
    let medProfResponse = null

    ;(async () => {
      try {
        if (patientQry) {
          // Query needs to be by patient first to only return script results for the specified patient
          userResponse = await readPatients(patientQry)
          scriptResponse = await agilite.Connectors.execute(
            ScriptEnums.profileKeys.SCRIPTS,
            ScriptEnums.routeKeys.READ,
            {
              filter: JSON.stringify({ ...qry, userRef: { $in: userResponse.map((i) => i._id) } }),
              page: page,
              pageLimit: pageSize
            }
          )
        } else {
          scriptResponse = await agilite.Connectors.execute(
            ScriptEnums.profileKeys.SCRIPTS,
            ScriptEnums.routeKeys.READ,
            {
              filter: JSON.stringify(qry),
              page: page,
              pageLimit: pageSize
            }
          )
          userResponse = await readPatients({ _id: { $in: scriptResponse.data.map((i) => i.userRef) } })
        }

        if (fetchMedProf) {
          medProfResponse = await readMedicalProfessionalUsers({}, 'firstName lastName')
        }
        scriptResponse.data.forEach((script) => {
          let tmpScript = { ...script }
          let associatedUser = userResponse.find((user) => user._id === script.userRef)
          tmpScript.patient = associatedUser
          if (fetchMedProf) {
            let associatedDoctor = medProfResponse.find((user) => user._id === script.medProfRef)
            tmpScript.medProf = associatedDoctor
          }

          response.push(tmpScript)
        })
        resolve(response)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readScripts = (filters) => {
  return new Promise((resolve, reject) => {
    let qry = {}
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        for (const key in filters) {
          if (filters[key] !== '') {
            qry[key] = filters[key]
          }
        }

        response = await agilite.Connectors.execute(ScriptEnums.profileKeys.SCRIPTS, ScriptEnums.routeKeys.READ, {
          filter: JSON.stringify(qry),
          page: null,
          pageLimit: null
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createScripts = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()

    ;(async () => {
      try {
        data.createdAt = timestamp
        data.modifiedAt = timestamp
        data.createdBy = store.getState().auth.agiliteUser._id
        data.modifiedBy = store.getState().auth.agiliteUser._id
        data.medProfRef = store.getState().auth.agiliteUser._id

        if (store.getState().auth.agiliteUser.extraData.role.type !== CoreEnums.userRoles.SUPERADMIN) {
          data.entityRef = store.getState().core.entity._id
        }

        response = await agilite.Connectors.execute(ScriptEnums.profileKeys.SCRIPTS, ScriptEnums.routeKeys.CREATE, {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const updateScript = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()
    ;(async () => {
      try {
        data.dateModified = timestamp
        data.modifiedBy = store.getState().auth.agiliteUser._id

        response = await agilite.Connectors.execute(ScriptEnums.profileKeys.SCRIPTS, ScriptEnums.routeKeys.UPDATE, {
          recordId,
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const removeScript = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(ScriptEnums.profileKeys.SCRIPTS, ScriptEnums.routeKeys.DELETE, {
          recordId: recordId
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const MedPraxProductSearch = async (searchQuery, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/medicines`, {
          body: {
            filters: [
              {
                propertyName: 'name',
                operation: 'StartsWith',
                value: searchQuery
              },
              {
                propertyName: 'Code',
                operation: 'StartsWith',
                value: searchQuery
              },
              {
                propertyName: 'NappiCode',
                operation: 'StartsWith',
                value: searchQuery
              },
              {
                propertyName: 'Name',
                operation: 'Contains',
                value: searchQuery
              }
            ],
            filterJoin: 'Or',
            ascendingOrder: true,
            sortKey: 'code',
            proj: 'name'
          },
          pageLimit: 100,
          page: 1
        })
        resolve(response.data.products.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const MedPraxMaterialSearch = async (searchQuery, page, pageLimit, filters) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/materials`, {
          body: {
            filters: filters
              ? filters
              : [
                  {
                    propertyName: 'Name',
                    operation: 'startsWith',
                    value: searchQuery
                  },
                  {
                    propertyName: 'Code',
                    operation: 'startsWith',
                    value: searchQuery
                  },
                  {
                    propertyName: 'NappiCode',
                    operation: 'startsWith',
                    value: searchQuery
                  },
                  {
                    propertyName: 'Name',
                    operation: 'contains',
                    value: searchQuery
                  }
                ],
            filterJoin: 'Or',
            ascendingOrder: true,
            sortKey: 'code'
          },
          pageLimit: 100,
          page: 1
        })
        resolve(response.data.products.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const MedPraxProcedureSearch = async (searchQuery, page, pageLimit, filters, filterJoin) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null
      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/procedures`, {
          body: {
            filters: filters
              ? filters
              : [
                  {
                    propertyName: 'Code',
                    operation: 'startsWith',
                    value: searchQuery
                  },
                  {
                    propertyName: 'Description',
                    operation: 'startsWith',
                    value: searchQuery
                  },
                  {
                    propertyName: 'Description',
                    operation: 'contains',
                    value: searchQuery
                  }
                ],
            filterJoin: filterJoin ? filterJoin : 'Or',
            ascendingOrder: true,
            sortKey: 'code'
          },
          pageLimit: 100,
          page: 1
        })
        resolve(response.data.tariffCodes.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const MedPraxICD10Search = async (searchQuery, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/icd10/search`, {
          body: {
            filters: [
              {
                propertyName: 'description',
                operation: 'StartsWith',
                value: searchQuery
              },
              {
                propertyName: 'code',
                operation: 'StartsWith',
                value: searchQuery
              },
              {
                propertyName: 'description',
                operation: 'Contains',
                value: searchQuery
              }
            ],
            filterJoin: 'Or',
            ascendingOrder: true,
            sortKey: 'code'
          },
          pageLimit: 100,
          page: 1
        })
        resolve(response.data.icd10s.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const MedPraxSchemeSearch = async (searchQuery, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/schemes`, {
          body: {
            filters: [
              {
                propertyName: 'name',
                operation: 'Contains',
                value: searchQuery ? searchQuery : ''
              }
            ],
            filterJoin: 'And',
            ascendingOrder: true,
            sortKey: 'Code'
          },
          pageLimit: 1000,
          page: 1
        })
        resolve(response.data.schemes.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const MedPraxSchemePlanSearch = async (searchQuery, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/scheme_plans`, {
          body: {
            filters: [
              {
                propertyName: 'schemeCode',
                operation: 'Equals',
                value: searchQuery
              }
            ],
            filterJoin: 'And',
            ascendingOrder: true,
            sortKey: 'Code'
          },
          pageLimit: 100,
          page: 1
        })
        resolve(response.data.plans.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const MedPraxSchemePlanOptionSearch = async (searchQuery, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/scheme_plan_options`, {
          body: {
            filters: [
              {
                propertyName: 'planCode',
                operation: 'Equals',
                value: searchQuery
              }
            ],
            filterJoin: 'And',
            ascendingOrder: true,
            sortKey: 'Code'
          },
          pageLimit: 100,
          page: 1
        })
        resolve(response.data.planOptions.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readThread = async (thread_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let assistant_response = null

      try {
        assistant_response = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/drug-interactions/response`,
          {
            thread_id
          }
        )
        resolve(assistant_response)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const AssistantDrugInteractionCheck = async (script) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/assistants/drug-interactions/query`, {
          drugs: script.map((i) => i.name).join(', ')
        })

        if (response.data.thread_id) {
          resolve(response.data.thread_id)
        } else {
          throw new Error('There was an issue contacting your assistant.')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readScriptTemplates = (filters) => {
  return new Promise((resolve, reject) => {
    let qry = { medProfRef: store.getState().auth.agiliteUser._id }
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        for (const key in filters) {
          if (filters[key] !== '') {
            qry[key] = filters[key]
          }
        }

        response = await agilite.Connectors.execute(
          ScriptEnums.profileKeys.SCRIPT_TEMPLATES,
          ScriptEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createScriptTemplateEntry = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()

    ;(async () => {
      try {
        data.createdAt = timestamp
        data.modifiedAt = timestamp
        data.createdBy = store.getState().auth.agiliteUser._id
        data.modifiedBy = store.getState().auth.agiliteUser._id
        data.medProfRef = store.getState().auth.agiliteUser._id
        response = await agilite.Connectors.execute(
          ScriptEnums.profileKeys.SCRIPT_TEMPLATES,
          ScriptEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const updateScriptTemplate = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = Date()
    ;(async () => {
      try {
        data.dateModified = timestamp
        data.modifiedBy = store.getState().auth.agiliteUser._id

        response = await agilite.Connectors.execute(
          ScriptEnums.profileKeys.SCRIPT_TEMPLATES,
          ScriptEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const removeScriptTemplate = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ScriptEnums.profileKeys.SCRIPT_TEMPLATES,
          ScriptEnums.routeKeys.DELETE,
          {
            recordId: recordId
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const ProductSearchAgilite = async (searchQuery, type) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      const qry = {
        $or: [
          { 'data.name': { $regex: searchQuery, $options: 'i' } },
          { 'data.code': { $regex: searchQuery, $options: 'i' } },
          { 'data.nappiCode': { $regex: searchQuery, $options: 'i' } }
        ]
      }
      let response = null

      try {
        if (type) {
          qry['type'] = type
        }

        response = await agilite.Connectors.execute('medprax_products', 'find', {
          filter: JSON.stringify(qry),
          projection:
            'type data.code data.name data.strengthMetric1 data.unitOfMeasure1 data.presentationCode data.scriptingDosage data.standardPacksize data.drugSchedule data.nappiCode'
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getDischems = () => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(ScriptEnums.profileKeys.DISCHEMS, ScriptEnums.routeKeys.READ)
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const sendDischemScript = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/dischem/new-order`, data, {
          headers: {
            'api-key': process.env.REACT_APP_AGILITE_API_KEY
          }
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
