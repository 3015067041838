import React, { useState } from 'react'
import { Button, Col, Input, message } from 'antd'
import CustomRow from '../../../reusable-components/CustomRow'

const BookingsConfig = ({ token, handleUpdateConfig, configData, setConfigData }) => {
  const [daysInAdvance, setDaysInAdvance] = useState(configData.bookings.daysInAdvance)
  const [searchRadius, setSearchRadius] = useState(configData.bookings.searchRadius)

  const [penaltyFee, setPenaltyFee] = useState({ ...configData.bookings.penaltyFee })

  const handleSubmit = () => {
    const tmpConfigData = JSON.parse(JSON.stringify(configData))

    if (!daysInAdvance) {
      return message.error('Please provide a value for Days In Advance')
    }

    if (!searchRadius) {
      return message.error('Please provide a value for Search Radius')
    }

    tmpConfigData.bookings.daysInAdvance = daysInAdvance
    tmpConfigData.bookings.searchRadius = searchRadius
    tmpConfigData.bookings.penaltyFee = penaltyFee

    setConfigData(tmpConfigData)
    handleUpdateConfig(tmpConfigData)
  }
  const sectionHeadingStyle = {
    padding: 6,
    borderRadius: 6,
    background: token.colorSecondary,
    color: '#ffffff'
  }
  return (
    <CustomRow gutter={[16, 16]}>
      <Col span={24}>
        <h2 style={sectionHeadingStyle}>Bookings</h2>
      </Col>
      <Col span={24}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <b>Days In Advance (Max: 10):</b>
            <br />
            <p style={{ margin: '4px 0' }}>Set how many days in advance the patient will see when making a booking.</p>
            <Input
              value={daysInAdvance}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  if (parseInt(e.target.value) <= 10 || isNaN(parseInt(e.target.value))) {
                    setDaysInAdvance(e.target.value)
                  }
                }
              }}
              style={{ width: 150 }}
              addonAfter='days'
            />
          </Col>
          <Col span={24}>
            <b>Search Radius:</b>
            <br />
            <p style={{ margin: '4px 0' }}>Set the Search Radius that Google maps will use to find Clinics.</p>
            <Input
              addonAfter='km'
              value={searchRadius}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  setSearchRadius(e.target.value)
                }
              }}
              style={{ width: 150 }}
            />
          </Col>

          <Col span={24}>
            <b>Penalty Fee:</b>
            <br />
            <p style={{ margin: '4px 0' }}>Set a penalty fee for late booking cancellations:</p>
            <Input
              addonBefore='R'
              value={penaltyFee.fee}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  if (parseInt(e.target.value) <= 10000 || isNaN(parseInt(e.target.value))) {
                    setPenaltyFee({ fee: e.target.value, time: penaltyFee.time })
                  }
                }
              }}
              style={{ width: 150 }}
            />
            <br />
            <p style={{ margin: '4px 0' }}>Set a grace period for cancellations:</p>
            <Input
              addonAfter='Hours'
              value={penaltyFee.time}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  if (parseInt(e.target.value) <= 24 || isNaN(parseInt(e.target.value))) {
                    setPenaltyFee({ fee: penaltyFee.fee, time: e.target.value })
                  }
                }
              }}
              style={{ width: 150 }}
            />
          </Col>
          <Col style={{ textAlign: 'center' }} span={24}>
            <Button
              type='primary'
              style={{ backgroundColor: token.colorSuccess }}
              onClick={() => {
                handleSubmit()
              }}
            >
              Save changes
            </Button>
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default BookingsConfig
