import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Input, Row, Space, message } from 'antd'
import GoogleAddress from '../../reusable-components/GoogleAddress'
import GoogleMapReact from 'google-map-react'
import dayjs from 'dayjs'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../reusable-components/CustomRow'
import { deviceDetect } from 'react-device-detect'

import './trip-details.css'

const TripDetails = ({ defaultFirstAccAddress, setEnableNext, subscription, setSubscription, token }) => {
  const [coords, setCoords] = useState(null)

  const coordsByAddress = async () => {
    try {
      let tmpCoords = null

      tmpCoords = await geocodeByAddress(subscription.extraData.location)

      if (tmpCoords && tmpCoords.length > 0) {
        setCoords({ lat: tmpCoords[0].geometry.location.lat(), lng: tmpCoords[0].geometry.location.lng() })
      }
    } catch (e) {
      message.error('Error getting coordinates from address')
    }
  }

  useEffect(() => {
    if (subscription.extraData.location) {
      coordsByAddress()
    } else {
      setCoords(null)
    }
    // eslint-disable-next-line
  }, [subscription.extraData.location])

  useEffect(() => {
    if (subscription.startDate && subscription.endDate && (subscription.extraData.location || defaultFirstAccAddress)) {
      setEnableNext(true)
    } else {
      setEnableNext(false)
    }
    // eslint-disable-next-line
  }, [subscription])

  const { RangePicker } = DatePicker

  return (
    <CustomRow justify='center'>
      <Col span={deviceDetect().isMobile ? 24 : 12}>
        <center>
          <b>Your Stay Details</b>
        </center>
        <center>
          <small>
            Please provide the initial accommodation address and the dates of your stay in South Africa. This
            information is vital for managing your subscription effectively and ensuring the prompt delivery of your
            mobile panic button device, if required.
          </small>
        </center>
      </Col>
      <Col span={24}>
        <Row justify='center' gutter={[12, 12]}>
          <Col xs={24} md={6}>
            <Space direction='vertical' style={{ width: '100%' }}>
              <p>Location:</p>
              {defaultFirstAccAddress ? undefined : <p>(Input your first accommodation address here)</p>}
              <div style={{ width: '100%' }}>
                {defaultFirstAccAddress ? (
                  <Input.TextArea rows={3} value={defaultFirstAccAddress} readOnly style={{ width: '100%' }} />
                ) : (
                  <GoogleAddress
                    selectProps={{
                      placeholder: subscription?.extraData?.location || 'Location',
                      onChange: (e) => {
                        setSubscription({
                          ...subscription,
                          extraData: { ...subscription.extraData, location: e.label }
                        })
                      }
                    }}
                  />
                )}
              </div>
              <p>Duration:</p>
              <p>(Select your stay period)</p>
              <RangePicker
                value={
                  subscription.startDate && subscription.endDate
                    ? [dayjs(subscription.startDate), dayjs(subscription.endDate)]
                    : []
                }
                onChange={(range) => {
                  setSubscription({ ...subscription, startDate: range[0], endDate: range[1] })
                }}
                allowClear={false}
                style={{ width: '100%' }}
              />
            </Space>
          </Col>
          <Col span={24}>
            {coords ? (
              <div style={{ height: 300, maxHeight: '100vw', width: '100%', marginTop: 24 }}>
                <GoogleMapReact
                  center={{
                    lat: coords.lat,
                    lng: coords.lng
                  }}
                  zoom={17}
                  bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
                >
                  <div lat={coords.lat} lng={coords.lng} text='My Marker'>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ color: token.colorError, marginTop: 0, marginLeft: -10 }}
                      size='2x'
                    />
                  </div>
                </GoogleMapReact>
              </div>
            ) : undefined}
          </Col>
        </Row>
      </Col>
    </CustomRow>
  )
}

export default TripDetails
