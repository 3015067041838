import bookingState from './booking-state'
import { createSlice, current } from '@reduxjs/toolkit'

const bookingsReducer = createSlice({
  name: 'bookings',
  initialState: bookingState,
  reducers: {
    setDashboardRecords: (state, action) => {
      state.dashboardData = action.payload
    },
    setRecords: (state, action) => {
      state.data = action.payload
    },
    submitRecord: (state, action) => {
      state.data.push(action.payload)
    },
    updateRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload.id)
      state.data.splice(tmpIndex, 1, action.payload)
    },
    deleteRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload)
      state.data.splice(tmpIndex, 1)
    },
    setNotes: (state, action) => {
      state.notes[action.payload.type] = action.payload.value
    }
  }
})

export default bookingsReducer
