import React, { useEffect, useState } from 'react'
import TemperatureGraph from './temperature-graph'

const OxygenSaturation = ({ data }) => {
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    let tmpData = data.concat()

    tmpData = tmpData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))

    setFormattedData(tmpData)
  }, [data])

  return (
    <>
      <TemperatureGraph data={formattedData} />
    </>
  )
}

export default OxygenSaturation
