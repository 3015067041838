import { message } from 'antd'
import CoreEnums from '../../../core/utils/enums'
import coreReducer from '../../../core/utils/reducer'
import store from '../../../store'
import PatientExaminationForm from '../../Bookings/components/booking-examination-wrapper'
import { executeBooking, getBookingState } from '../../Bookings/utils/bpm-utils'
import { updateBooking } from '../../Bookings/utils/utils'
import { updateAllBookingsState } from '../../lib/booking-utils'
import { handleError } from '../../lib/utils'

export function generateColorFromId(id) {
  // Convert the _id to a numeric value
  const numericId = Array.from(id).reduce((acc, char) => acc + char.charCodeAt(0), 0)

  // Use the numeric id as a seed for consistent random color generation
  const random = (seed) => {
    let x = Math.sin(seed) * 10000
    return x - Math.floor(x)
  }

  // Generate RGB values based on the seed
  const r = Math.floor(random(numericId) * 255)
  const g = Math.floor(random(numericId + 1) * 255)
  const b = Math.floor(random(numericId + 2) * 255)

  // Convert RGB values to hexadecimal format
  const hexColor = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)

  return hexColor
}

export const handleSkipScreening = async (data, dispatch, onFail, onSuccess, refreshFunction) => {
  let workflowHistory = {}
  let processRecord = null
  let newRecord = null

  const authState = store.getState().auth

  try {
    workflowHistory = await getBookingState([data.processRef])
    processRecord = await executeBooking(
      data.clinicRecord.bpmKey,
      data.processRef,
      'submit',
      `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`,
      workflowHistory.key
    )
    newRecord = await updateBooking({ status: processRecord.processStage }, { _id: data._id })
    workflowHistory = processRecord
    workflowHistory.history.splice(0, 1)
    updateAllBookingsState(newRecord)

    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${data._id}`,
        closable: true,
        label: `Booking: ${data.patientName}`,
        children: (
          <PatientExaminationForm
            data={{ ...data, ...newRecord }}
            refreshView={() => {
              refreshFunction()
            }}
            workflowHistory={workflowHistory}
          />
        )
      })
    )
    onSuccess()
  } catch (e) {
    message.error(handleError(e, true))
  }
  onFail(false)
}
export const handleOpenBooking = async (data, dispatch, onFail, onSuccess, refreshFunction) => {
  let workflowHistory = {}
  let newRecord = null

  try {
    if (data.processRef) {
      workflowHistory = await getBookingState([data.processRef])
      workflowHistory.history.splice(0, 1)
    }

    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${data._id}`,
        closable: true,
        label: `Booking: ${data.patientName}`,
        children: (
          <PatientExaminationForm
            data={{ ...data, ...newRecord }}
            refreshView={() => {
              refreshFunction()
            }}
            workflowHistory={workflowHistory}
          />
        )
      })
    )
    onSuccess()
  } catch (e) {
    onFail()
    message.error(handleError(e, true))
  }
}
