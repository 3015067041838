import TextArea from 'antd/es/input/TextArea'
import React from 'react'
import { Card, Col, Input, theme } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import { hexToRGBA } from '../../../../lib/utils'

const GeneralForm = ({ isMonitoring, data, setData }) => {
  const { token } = theme.useToken()
  return (
    <Card>
      <h1 style={{ color: token.colorPrimary }}>NEW {isMonitoring ? 'MONITORING' : 'CARE'} PLAN</h1>
      <p>Please provide basic details for your {isMonitoring ? 'monitoring' : 'care'} plan.</p>
      <Card
        size='small'
        headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
        style={{ marginTop: 12 }}
        title='General'
      >
        <CustomRow>
          <Col span={24}>
            <p>Name:</p>
            <Input
              value={data?.name}
              style={{ width: 500 }}
              placeholder={`- ${isMonitoring ? 'Monitoring' : 'Care'} Plan Name -`}
              onChange={(e) => {
                setData({ ...data, name: e.target.value })
              }}
            />
          </Col>
          <Col span={24}>
            <p>Description:</p>
            <TextArea
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value })
              }}
              rows={4}
              placeholder={`- ${isMonitoring ? 'Monitoring' : 'Care'} Plan Description -`}
            />
          </Col>
        </CustomRow>
      </Card>
    </Card>
  )
}

export default GeneralForm
