import dayjs from 'dayjs'
import { ScriptingFreq } from './templates'
import { uniqBy } from 'lodash'

export const handleFreqChange = (value) => {
  let tmpOptions = []

  if (value) {
    ScriptingFreq.forEach((option) => {
      let checkMatch = false
      let optionValue = ''
      if (option.label.toLocaleLowerCase().match(value.toLocaleLowerCase())) {
        checkMatch = true
        optionValue = option.value
      }
      if (checkMatch) {
        tmpOptions.push({
          value: optionValue,
          label: optionValue
        })
      }
    })
  } else {
    tmpOptions = ScriptingFreq
  }
  return tmpOptions
}

export const generateMedLabel = (med) => {
  let strParams = [med.name, med.strengthMetric1, med.unitOfMeasure1, med.presentationCode]
  if (med.scriptingDosage) {
    strParams.push(`- ${med.scriptingDosage}`)
  }
  let str = strParams.filter(Boolean).join(' ')
  return str
}

export const validateChronicIllnesses = (patientChronicDetails, script, action) => {
  let responseMessages = []
  let existingIllnesses = []
  let newIllnesses = []

  // EXISTING ILLNESSES NEW INSTANCE
  existingIllnesses = patientChronicDetails?.chronicIllnesses?.list
    ? JSON.parse(JSON.stringify(patientChronicDetails?.chronicIllnesses?.list))
    : []

  // UNIQUE ILLNESSES ON SCRIPT
  let illnessesOnScript = uniqBy(
    script.items
      .filter((i) => i.isChronic)
      .map((i) => {
        return { code: i.icd?.code, description: i.icd?.description, dateDiagnosed: dayjs() }
      }),
    'code'
  )

  // NEW ILLNESSES
  illnessesOnScript.forEach((illness) => {
    let existingIllnessMatchIndex = existingIllnesses.findIndex((i) => i.code === illness.code)
    // NOUT FOUND
    if (existingIllnessMatchIndex === -1) {
      let newIllness = illness
      let newIllnessMedications = []

      script.items.forEach((i) => {
        if (i?.icd?.code === illness.code) {
          newIllnessMedications.push({ med: i, startDate: dayjs(), endDate: undefined })
        }
      })
      newIllness.medications = newIllnessMedications
      newIllnesses.push(newIllness)
    }
  })

  // EXISTING ILLNESSES
  existingIllnesses.forEach((illness, index) => {
    let existingMedications = [...illness.medications]

    // ONLY CHRONIC MEDS FOR MATCHING ILLNESS
    let medicationsOnScript = script.items
      .filter((i) => i.isChronic && i.icd?.code === illness.code)
      .map((i) => ({ startDate: dayjs(), endDate: undefined, med: { ...i } }))

    // VALIDATE EXISTING MEDICATIONS
    medicationsOnScript.forEach((medication) => {
      const existingMedIndex = existingMedications.findIndex((i) => i?.med?.code === medication?.med?.code)
      if (existingMedIndex === -1) {
        // ADD NEW MEDICATION
        existingMedications.push({ ...medication })
      } else {
        // UPDATE EXISTING MEDICATION
        existingMedications[existingMedIndex] = {
          ...existingMedications[existingMedIndex],
          med: medication.med
        }
      }
    })
    // SET EXISTING ILLNESS MEDICATIONS
    existingIllnesses[index].medications = existingMedications
  })

  // CONFIRMATIO MESSAGES
  newIllnesses.forEach((illness) => {
    responseMessages.push(
      // COMPONENT
      <>
        <p>A new Chronic Illness has been captured</p>
        <p>
          <b>{illness.description?.toUpperCase() + ' ' + illness.code?.toUpperCase()}</b>
        </p>
        <p>With the following medications:</p>
        <ul style={{ marginTop: 0 }}>
          {illness.medications.map((med) => {
            return <li>{med.med.name}</li>
          })}
        </ul>
      </>
    )
  })

  // RESPONSE
  return action === 'confirmation' ? responseMessages : [...existingIllnesses, ...newIllnesses]
}

export const getNextDueDate = (date, scheduleInterval) => {
  let tmpNextDue = null
  // If date is undefined then that means that this is a new creation as there is no "nextDue" date.
  if (date) {
    // Here it is either due TODAY or it is PAST the due date either way we still add 30 days onto the previos "nextDue" date
    tmpNextDue = dayjs(date).add(scheduleInterval * 30, 'days')
  } else {
    // Here there was never a "nextDue" date as it is the first time the record is being created
    tmpNextDue = dayjs().add(30, 'days').format('YYYY-MM-DD')
  }
  return tmpNextDue
}

export const prepPatientQuery = (string) => {
  return {
    $or: [
      {
        $expr: {
          $regexMatch: {
            input: { $concat: ['$firstName', ' ', '$lastName'] },
            regex: string, //Your text search here
            options: 'i'
          }
        }
      },
      { phoneNumber: { $regex: string, $options: 'i' } },
      { email: { $regex: string, $options: 'i' } },
      { physicalAddress: { $regex: string, $options: 'i' } },
      { idNo: { $regex: string, $options: 'i' } }
    ]
  }
}

export const validateScript = (script, dischemApplicable) => {
  let errors = []
  // VALIDATE ICD IF CHRONIC
  script.items.forEach((scriptItem) => {
    if (dischemApplicable) {
      if (!scriptItem.daysSupply && scriptItem.daysSupply !== 0) {
        errors.push('Please provide a days supply')
      }
    }
    if (scriptItem.isChronic || dischemApplicable) {
      if (!scriptItem.icd) {
        errors.push(
          dischemApplicable
            ? 'Dischem requires ICD10 codes for all medications'
            : 'Chronic medications require an ICD Diagnosis'
        )
      }
    }
  })

  return errors
}
