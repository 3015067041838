import React from 'react'
import { Col } from 'antd'
import { generateActions } from '../../utils/lib'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import { useSelector } from 'react-redux'
import CoreEnums from '../../../../core/utils/enums'

const PatientExaminationActionsCard = ({
  loading,
  saving,
  completing,
  closeTab,
  data,
  handleSave,
  doctorVisitRequired,
  setModalOpen,
  finalizeBilling,
  noAppointment,
  isPreviousBooking,
  syncRequired
}) => {
  const authState = useSelector((state) => state.auth)
  return (
    <CustomRow
      justify='end'
      gutter={[12, 12]}
      // style={{ background: token.colorPrimary, padding: 24, borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}
    >
      <Col>
        <CustomButton
          size='small'
          type='danger'
          text='CLOSE'
          disabled={loading || saving || completing}
          popConfirmTitle='Confirmation'
          popConfirmDescription='Are you sure you want to close? Note: You will lose any unsaved changes.'
          hasPopconfirm={true}
          onClick={() => closeTab()}
          style={{ color: '#ffffff' }}
        />
      </Col>
      {/* {generateActions(data.status) &&
      data.status !== CoreEnums.bookingStatuses.completed &&
      data.status !== CoreEnums.bookingStatuses.procedure &&
      data.status !== CoreEnums.bookingStatuses.billing &&
      !noAppointment &&
      !isPreviousBooking ? (
        <Col>
          <CustomButton
            onClick={() => handleSave(false)}
            loading={saving}
            disabled={completing}
            type=''
            style={{ color: token.colorPrimary }}
            text='SAVE CHANGES'
          />
        </Col>
      ) : undefined} */}
      {generateActions(data.status) && !isPreviousBooking ? (
        <Col>
          <CustomButton
            size='small'
            loading={completing}
            onClick={() => {
              if (authState?.agiliteUser?.extraData?.role?.type === 'reception') {
                finalizeBilling()
              } else {
                if (doctorVisitRequired) {
                  setModalOpen(true)
                } else {
                  handleSave(true)
                }
              }
            }}
            disabled={saving || authState?.agiliteUser?.extraData?.role?.type === 'reception' ? syncRequired : false}
            tooltip={
              authState?.agiliteUser?.extraData?.role?.type === 'reception'
                ? syncRequired
                  ? 'Please Refresh before you can Finalize Billing'
                  : undefined
                : undefined
            }
            type='success'
            hasPopconfirm={true}
            popConfirmTitle='Confirmation'
            popConfirmDescription={`Are you sure you want to ${
              data.status === CoreEnums.bookingStatuses.billing
                ? 'finalize billing'
                : data.status === CoreEnums.bookingStatuses.data_capture
                ? 'complete the data capture'
                : data.status === CoreEnums.bookingStatuses.screening
                ? doctorVisitRequired
                  ? 'move on to the next step'
                  : data.medicalProfRef === authState.agiliteUser._id
                  ? 'complete the Examination without scheduling a virtual consult'
                  : 'complete the Examination'
                : data.status === CoreEnums.bookingStatuses.diagnosis
                ? 'complete the Diagnosis'
                : data.status === CoreEnums.bookingStatuses.procedure
                ? 'complete the Procedure'
                : 'submit updates'
            }?`}
            text={
              data.status === CoreEnums.bookingStatuses.billing
                ? 'FINALIZE BILLING'
                : data.status === CoreEnums.bookingStatuses.data_capture
                ? 'COMPLETE DATA CAPTURE'
                : data.status === CoreEnums.bookingStatuses.screening
                ? doctorVisitRequired
                  ? 'SCHEDULE CONSULT'
                  : 'COMPLETE EXAMINATION'
                : data.status === CoreEnums.bookingStatuses.completed
                ? 'SUBMIT UPDATES'
                : data.status === CoreEnums.bookingStatuses.procedure
                ? 'COMPLETE BOOKING'
                : 'COMPLETE DIAGNOSIS'
            }
          />
        </Col>
      ) : undefined}
    </CustomRow>
  )
}

export default PatientExaminationActionsCard
