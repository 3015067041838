import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Col, message, Table } from 'antd'
import { handleError } from '../../lib/utils'
import { readReferralLetter } from '../examination-utils/examination-lib'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { downloadReferralLetter } from '../../Medical Vault/diagnosis-history/utils/utils'
import { useSelector } from 'react-redux'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'

const ReferralLetterDisplay = ({ bookingData, userRef }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const [referralLoading, setReferralLoading] = useState(false)
  const authState = useSelector((state) => state.auth)
  useEffect(() => {
    handleFetchReferralLetters()
    // eslint-disable-next-line
  }, [])

  const handleFetchReferralLetters = async () => {
    let tmpData = []
    setLoading(true)
    try {
      tmpData = await readReferralLetter({ userRef })
      setData(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const handlePrintPdf = async (referralData) => {
    const signature = authState.agiliteUser.extraData.signature
    const mpNumber = authState.agiliteUser.extraData.mpNumber

    setReferralLoading(true)
    let patient = null

    if (!signature) {
      setReferralLoading(false)
      return message.error(
        'No Signature has been set. Please set your Signature in your Personal Details Medical Profile.'
      )
    }

    if (!mpNumber) {
      setReferralLoading(false)
      return message.error(
        'No MP Number has been set. Please set your MP Number in your Personal Details Medical Profile.'
      )
    }

    try {
      if (!bookingData) {
        patient = await readSystemUsers({ _id: userRef })
      }

      await downloadReferralLetter(
        bookingData,
        bookingData ? bookingData.patientRecord : patient,
        referralData.content,
        signature,
        'referral_letter'
      )
    } catch (e) {
      message.error(handleError(e))
    }
    setReferralLoading(false)
  }
  return (
    <CustomRow>
      <Col span={24}>
        <Table
          loading={loading || referralLoading}
          dataSource={data}
          columns={[
            {
              title: 'Date',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (date) => {
                return dayjs(date).format('DD MMM YYYY')
              }
            },
            {
              title: 'Actions',
              render: (record) => {
                return (
                  <Button
                    onClick={() => {
                      handlePrintPdf(record)
                    }}
                    type='primary'
                    style={{ background: 'red' }}
                  >
                    Print PDF <FontAwesomeIcon icon={faFilePdf} />
                  </Button>
                )
              }
            }
          ]}
        />
      </Col>
    </CustomRow>
  )
}

export default ReferralLetterDisplay
