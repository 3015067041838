import { BrowserRouter } from 'react-router-dom'
import './index.css'
import { createRoot } from 'react-dom/client'
import { Provider, useSelector } from 'react-redux'
import { initializeApp } from 'firebase/app'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
// import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

import Store from './store'
import Core from './core/components/core'
import AgoraRTC from 'agora-rtc-sdk-ng'

// Sentry
Sentry.init({
  dsn: 'https://8f5f1a1dd9e94282bfb55655171e75e8@o4504768129073152.ingest.sentry.io/4504768131629056',
  integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

// Firebase Authentication & Analytics
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const firebaseApp = initializeApp(firebaseConfig)
const firebaseAuth = getAuth(firebaseApp)
// const firebaseAnalytics = getAnalytics(firebaseApp)

firebaseAuth.useDeviceLanguage()

// Initialize App
const App = () => {
  const state = useSelector((state) => state.core)
  return <Core state={state} />
}

const container = document.getElementById('root')
const root = createRoot(container)

AgoraRTC.setLogLevel(3)

root.render(
  <Provider store={Store}>
    <BrowserRouter>
      <div style={{ position: 'relative', minHeight: '100vh', width: '100%' }}>
        <App />
      </div>
    </BrowserRouter>
  </Provider>
)

export { firebaseAuth }
