import { Col, Divider, Empty, Space, Tag, theme } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { deviceDetect } from 'react-device-detect'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import CustomRow from '../../../reusable-components/CustomRow'
import BloodSugarLegend from './blood-sugar-legend'
import { bloodSugarMethod, filterByDateRange } from '../utils'
import { generateXAxisLabel } from '../../lib'

const BloodSugarPlotting = ({ data, rangeFilter, setRangeFilter }) => {
  const [graphData, setGraphData] = useState([])
  // eslint-disable-next-line
  const [dateRange, setDateRange] = useState()

  useEffect(() => {
    handleSetGraphData()
    // eslint-disable-next-line
  }, [data, dateRange])

  const { token } = theme.useToken()

  const handleSetGraphData = () => {
    let tmpGraphData = []
    let tmpData = []
    if (dateRange) {
      tmpData = filterByDateRange(data, dateRange[0], dateRange[1])
    } else {
      tmpData = data.concat()
    }
    tmpData.forEach((entry) => {
      if (entry.examinationData?.checkinData?.sugarLevel) {
        tmpGraphData.push({
          name: dayjs(entry.bookingDate).format('DD MMM YYYY'),
          bloodSugar: entry.examinationData?.checkinData?.sugarLevel
        })
      }
    })
    setGraphData(tmpGraphData)
  }
  const customizedDot = (props) => {
    const { cx, cy } = props

    let fillColor = null
    const bloodSugar = props.payload.bloodSugar

    Object.keys(bloodSugarMethod(bloodSugar)).forEach((key) => {
      const object = bloodSugarMethod(bloodSugar)[key]
      if (object.statusTest) {
        fillColor = object.color
      }
    })
    return <circle cx={cx} cy={cy} r={5} strokeWidth={3} fill={fillColor} />
  }
  return (
    <CustomRow>
      {graphData.length > 0 ? (
        <Col span={24}>
          <ResponsiveContainer width={'100%'} height={300}>
            <LineChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 10,
                left: 0,
                bottom: 15
              }}
            >
              <CartesianGrid stroke={token.colorBorderSecondary} />

              <XAxis
                stroke={token.colorTextBase}
                dataKey='name'
                fontSize={14}
                label={{ value: generateXAxisLabel(graphData), dy: 20, style: { fontSize: 14 } }}
                tick={false}
              />
              <YAxis
                stroke={token.colorTextBase}
                label={{ value: 'mg / dL', angle: -90, dx: -20, style: { fontSize: 14 } }}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  const reading = []
                  return (
                    <>
                      {deviceDetect().isMobile ? (
                        <Space
                          style={{
                            width: '100%',
                            justifyContent: 'space-between',
                            background: 'rgba(255,255,255,1)',
                            boxShadow: '0 0 5px 0 rgba(0,0,0,0.155)',
                            padding: 4,
                            borderRadius: 8
                          }}
                        >
                          {/* eslint-disable-next-line */}
                          {payload.map((item) => {
                            reading.push(item.value)
                          })}
                          {reading}
                          <span style={{ opacity: 0.45 }}>mg/dL</span>
                        </Space>
                      ) : (
                        <CustomRow
                          className='basic-card'
                          style={{
                            background: 'rgba(255,255,255,0.95)',
                            boxShadow: '0 0 10px 0 rgba(0,0,0,0.25)',
                            width: 250
                          }}
                        >
                          <Col span={24}>
                            <center>
                              <p span={24}>{label}</p>
                            </center>
                            <Divider style={{ margin: '4px 0px' }} />
                            {payload.map((item) => {
                              reading.push(item.value)

                              return (
                                <>
                                  {' '}
                                  <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                    <p>Blood Sugar:</p> <p>{item.value}</p>
                                  </Space>
                                  <>
                                    {' '}
                                    {Object.keys(bloodSugarMethod(item.value)).map((key) => {
                                      const object = bloodSugarMethod(item.value)[key]
                                      if (object.statusTest) {
                                        return (
                                          <center style={{ marginTop: 8 }}>
                                            <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                                              {object.title}
                                            </Tag>
                                          </center>
                                        )
                                      } else {
                                        return null
                                      }
                                    })}
                                  </>
                                </>
                              )
                            })}
                          </Col>
                        </CustomRow>
                      )}
                    </>
                  )
                }}
                contentStyle={{
                  color: token.colorTextBase,
                  background: token.colorBgContainer,
                  border: `2px ${token.colorBorderSecondary} solid`
                }}
                itemStyle={{ color: token.colorTextBase }}
              />
              <Line
                type='monotone'
                dataKey='bloodSugar'
                stroke='#8884d8'
                activeDot={{ r: 8 }}
                color={['red', 'green']}
                dot={customizedDot}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
      ) : (
        <Col span={24}>
          <Empty description='No Blood Sugar readings captured to date.' />
        </Col>
      )}
      <Col span={24}>
        <BloodSugarLegend />
      </Col>
    </CustomRow>
  )
}

export default BloodSugarPlotting
