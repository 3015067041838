import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-regular-svg-icons'
import theme from '../../../../core/utils/theme'
import { Tooltip } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import ServicesEnums from './enums'

const ServicesTemplate = {
  dataModel: {
    isActive: {
      label: 'Status:',
      key: 'isActive',
      required: false
    },
    name: {
      label: 'Provide a service name:',
      key: 'name',
      required: true,
      placeholder: 'e.g. Doctor Consultation',
      validationMsg: 'Please provide the service`s name'
    },
    type: {
      label: 'Specify the service type:',
      key: 'type',
      required: true,
      options: [
        {
          label: ServicesEnums.labels.STANDARD_CONSULT,
          value: ServicesEnums.labels.STANDARD_CONSULT
        },
        {
          label: ServicesEnums.labels.VITALITY_HEALTH_CHECK,
          value: ServicesEnums.labels.VITALITY_HEALTH_CHECK
        }
      ],
      validationMsg: 'Please specify the service type',
      placeholder: '- Select a service type -'
    },
    rate: {
      label: 'Provide the service cost:',
      key: 'rate',
      required: true,
      placeholder: 'e.g. 450',
      validationMsg: 'Please provide the service cost'
    },
    timeslotInterval: {
      label: 'Provide the consultation duration:',
      key: 'timeslotInterval',
      required: true,
      placeholder: 'e.g. 15',
      validationMsg: 'Please provide the consultation duration'
    },
    screeningTemplate: {
      key: 'screeningTemplate',
      required: false
    }
  },

  dataTemplate: () => {
    return {
      isActive: true,
      rate: null,
      name: null,
      type: null,
      timeslotInterval: null
    }
  },

  columnTemplate: (filters) => {
    return [
      {
        title: 'Service Name',
        dataIndex: 'name',
        width: '20%',
        key: 'name'
      },
      {
        title: 'Rate (ZAR)',
        width: '10%',
        dataIndex: 'rate',
        key: 'rate'
      },
      // {
      //   title: 'Service Type',
      //   width: '20%',
      //   dataIndex: 'type',
      //   key: 'type',
      //   filterSearch: true,
      //   filters: filters.serviceType,
      //   onFilter: (value, record) => {
      //     return record.type.indexOf(value) === 0
      //   },
      //   filterIcon: (
      //     <Tooltip title='Filter by Name'>
      //       <FilterFilled />
      //     </Tooltip>
      //   ),
      //   sorter: (a, b) => {
      //     return a.type.localeCompare(b.type)
      //   }
      // },
      {
        title: 'Timeslot Interval (Minutes)',
        width: '20%',
        dataIndex: 'timeslotInterval',
        key: 'timeslotInterval'
      },
      {
        title: 'Status',
        width: '5%',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (status) => {
          if (status === true)
            return (
              <center>
                <Tooltip title='Active'>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.success }}
                  />
                </Tooltip>
              </center>
            )
          else
            return (
              <center>
                <Tooltip title='Deactivated'>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    style={{ fontSize: 20, color: theme.twitterBootstrap.danger }}
                  />
                </Tooltip>
              </center>
            )
        },
        filterMultiple: false,
        filters: [
          {
            text: 'Active',
            value: true
          },
          {
            text: 'Inactive',
            value: false
          }
        ],
        onFilter: (value, record) => {
          return record.isActive === value
        },
        filterIcon: (
          <Tooltip title='Filter by Status'>
            <FilterFilled />
          </Tooltip>
        )
      }
    ]
  }
}

export default ServicesTemplate
