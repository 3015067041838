import React, { memo, useEffect, useState } from 'react'
import { Col, Button, Table, Empty, Input, Space, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'
import CustomRow from '../../../reusable-components/CustomRow'
import TeamChatForm from '../../../TeamChat/components/team-chat-form'
import CustomLoadingIcon from '../../../reusable-components/CustomLoadingIcon'
import coreReducer from '../../../../core/utils/reducer'
import SystemUsersTemplates from '../utils/templates'

const _ListView = ({
  addPatient,
  createRecord,
  editRecord,
  loading,
  type,
  handleSearch,
  clinics,
  page,
  pageSize,
  refreshView,
  setPage,
  setPageSize,
  total
}) => {
  const [viewType, setViewType] = useState(type)
  const [data, setData] = useState([])
  const state = useSelector((state) => state)
  const coreState = useSelector((state) => state.core)
  const dispatch = useDispatch()

  const systemUsersData = useSelector((state) => state.systemUsers.data)
  const medicalProfessionalsData = useSelector((state) => state.medicalProfessionals.data)
  const patientsData = useSelector((state) => state.patients.data)
  const [tableData, setTableData] = useState()
  const [teamChatProps, setTeamChatProps] = useState({ isOpen: false, userRef: '' })

  const [userFilters, setUserFilters] = useState({
    firstName: [],
    lastName: [],
    email: []
  })

  useEffect(() => {
    switch (type) {
      case 'system':
        setViewType('System Users')
        setTableData(systemUsersData)
        setData(systemUsersData)
        break
      case 'medical':
        setViewType('Medical Professionals')
        setTableData(medicalProfessionalsData)
        setData(medicalProfessionalsData)
        break
      case 'patient':
        setViewType('Patients')

        setTableData(patientsData)
        setData(patientsData)
        break
      default:
        setViewType('System Users')
        setTableData(systemUsersData)
        setData(systemUsersData)
    }
    // eslint-disable-next-line
  }, [systemUsersData, medicalProfessionalsData, patientsData])

  useEffect(() => {
    generateFilters()
    // eslint-disable-next-line
  }, [data])

  const generateFilters = () => {
    let tmpRoles = JSON.parse(JSON.stringify(state.auth.roles))
    let tmpProfessions = JSON.parse(JSON.stringify(state.auth.professions))
    let tmpClinics = clinics
    let tmpServices = [...JSON.parse(JSON.stringify(state.services.data))]
    if (state.core.entity.virtualConsultations.enabled) {
      state.virtualServices.data.forEach((service) => {
        let tmpService = JSON.parse(JSON.stringify(service)) // create instance
        tmpService.name = `${service.name} (Virtual)`
        tmpServices.push(tmpService)
      })
    }

    tmpRoles = tmpRoles.filter((i) => i.value !== 'patient' && i.value !== 'medical_professional')
    const filters = {
      roles: tmpRoles.map((role) => {
        return {
          text: role.label,
          value: role.value
        }
      }),
      professions: tmpProfessions.map((role) => {
        return {
          text: role.label,
          value: role.value
        }
      }),
      services: tmpServices.map((service) => {
        return {
          text: service.name,
          value: service._id
        }
      }),
      clinics: tmpClinics.map((clinic) => {
        return {
          text: clinic.name,
          value: clinic._id
        }
      })
    }

    setUserFilters(filters)
  }

  const getInviteLabel = () => {
    switch (type) {
      case 'entity_admin':
        return 'Entity Admin'
      case 'system':
        return 'System User'
      case 'medical':
        return 'Medical Professional'
      case 'patient':
        return 'Patient'
      default:
        return 'User'
    }
  }

  const getInviteId = () => {
    switch (type) {
      case 'entity_admin':
        return 'entityAdmin_add_btn'
      case 'system':
        return 'systemUser_add_btn'
      case 'medical':
        return 'medicalProf_add_btn'
      case 'patient':
        return 'patient_add_btn'
      default:
        return 'User'
    }
  }

  const { token } = theme.useToken()

  return (
    <>
      {/* <PrimaryCard
          title={viewType}
          extra={
            <Button onClick={refreshView} type='primary' disabled={loading}>
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          }
        > */}
      <CustomRow gutter={[12, 12]} style={{ marginBottom: 20 }} className='basic-card'>
        <Col span={24}>
          <Space wrap>
            {type === 'patient' ? (
              <Button onClick={addPatient} type='primary' disabled={loading}>
                Add Patient
              </Button>
            ) : (
              <>
                {state.auth.agiliteUser?.extraData?.role?.type === 'admin' ||
                state.auth.agiliteUser?.extraData?.role?.type === 'reception' ||
                state.auth.agiliteUser?.extraData?.role?.type === 'super_admin' ? (
                  <Button
                    onClick={() => {
                      createRecord()

                      if (coreState.tourOpen) {
                        dispatch(coreReducer.actions.nextTourStep())
                      }
                    }}
                    type='primary'
                    disabled={loading}
                    id={getInviteId()}
                  >
                    Invite {getInviteLabel()}
                  </Button>
                ) : null}
              </>
            )}
            <Input
              style={{ width: 425 }}
              placeholder={
                type === 'system'
                  ? 'Search for First Name, Last Name or Email...'
                  : type === 'medical'
                  ? 'Search for First Name, Last Name or Email...'
                  : 'Search for Name, ID/Passport, Email, Address...'
              }
              suffix={<FontAwesomeIcon icon={faSearch} />}
              allowClear
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />{' '}
            <Button
              onClick={() => {
                refreshView()
              }}
              style={{ background: token.colorSuccess }}
              type='primary'
              disabled={loading}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          </Space>
        </Col>

        <Col span={24}>
          <CustomLoadingIcon
            loading={loading}
            loadingText={`Loading ${type} profiles...`}
            content={
              <Table
                className={loading ? 'custom-loading-mask' : ''}
                size='small'
                rowKey={(record) => record._id}
                dataSource={_.sortBy(tableData, ['firstName', 'lastName'])}
                pagination={{
                  current: page,
                  pageSize: pageSize,
                  total: total,
                  onChange: (p, ps) => {
                    setPage(p)
                    setPageSize(ps)
                  }
                }}
                columns={SystemUsersTemplates.columnTemplate(
                  userFilters,
                  type,
                  clinics,
                  token,
                  setTeamChatProps,
                  editRecord
                ).filter((i) => !i.hidden)}
                locale={{ emptyText: <Empty description={`There are currently no registered ${viewType} to show.`} /> }}
                scroll={{ x: 1000 }}
                bordered
              />
            }
          />
        </Col>
      </CustomRow>
      <TeamChatForm modalProps={teamChatProps} setModalProps={setTeamChatProps} />
      {/* </PrimaryCard> */}
    </>
  )
}

const ListView = memo(_ListView)

export default ListView
