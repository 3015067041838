import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'

export const DataComplianceTableTemplates = (token, hideCompliance) => {
  return {
    columnTemplate: [
      {
        title: 'Tracker',
        dataIndex: 'trackerLabel',
        key: 'trackerLabel'
      },
      {
        title: 'Due',
        dataIndex: 'dueDate',
        key: 'dueDate',
        render: (date) => dayjs(date).format('DD MMM YYYY')
      },
      {
        title: 'Compliant',
        dataIndex: 'value',
        hidden: hideCompliance,
        key: 'value',
        render: (boolean) => (
          <FontAwesomeIcon
            color={boolean ? token.colorSuccess : token.colorError}
            icon={boolean ? faCircleCheck : faCircleXmark}
          />
        )
      }
    ]
  }
}
